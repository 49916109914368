// saga
import { takeEvery, call, put } from "redux-saga/effects";

// endpoint from backend
import * as backend from "../../../helpers/backend";

// Action Type
import { CHANGE_PASSWORD_REQUEST } from "./actionTypes";

// Actioon
import {
  changePasswordSuccess,
  changePasswordFailure,
  changePasswordFailure1,
} from "./action";

// Notification Toast
import { toast } from "react-toastify";

// function Saga
function* changePassword({ payload }) {
  try {
    const response = yield call(backend.changePassword, payload);
    if (response.data.data == null) {
      toast.error("Password lama yang anda masukkan salah");
      yield put(changePasswordFailure1(response.data.data));
    } else if (response.data.code == 200) {
      yield put(changePasswordSuccess(response.data.data));
      toast.success("Password berhasil diubah");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(changePasswordFailure(message, error));
  }
}

// export function saga
function* notificationSaga() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changePassword);
}

export default notificationSaga;
