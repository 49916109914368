export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

export const DASHBOARD_IDENTITAS_DOSEN_REQUEST =
  "DASHBOARD_IDENTITAS_DOSEN_REQUEST";
export const DASHBOARD_IDENTITAS_DOSEN_SUCCESS =
  "DASHBOARD_IDENTITAS_DOSEN_SUCCESS";
export const DASHBOARD_IDENTITAS_DOSEN_FAILURE =
  "DASHBOARD_IDENTITAS_DOSEN_FAILURE";

export const DASHBOARD_SINTA_DOSEN_REQUEST = "DASHBOARD_SINTA_DOSEN_REQUEST";
export const DASHBOARD_SINTA_DOSEN_SUCCESS = "DASHBOARD_SINTA_DOSEN_SUCCESS";
export const DASHBOARD_SINTA_DOSEN_FAILURE = "DASHBOARD_SINTA_DOSEN_FAILURE";

export const TRACKING_USULAN_PENELITIAN_REQUEST =
  "TRACKING_USULAN_PENELITIAN_REQUEST";
export const TRACKING_USULAN_PENELITIAN_SUCCESS =
  "TRACKING_USULAN_PENELITIAN_SUCCESS";
export const TRACKING_USULAN_PENELITIAN_FAILURE =
  "TRACKING_USULAN_PENELITIAN_FAILURE";

export const LIST_USULAN_REQUEST = "LIST_USULAN_REQUEST";
export const LIST_USULAN_SUCCESS = "LIST_USULAN_SUCCESS";
export const LIST_USULAN_FAILURE = "LIST_USULAN_FAILURE";

export const DASHBOARD_UPDATE_IDENTITAS_REQUEST =
  "DASHBOARD_UPDATE_IDENTITAS_REQUEST";
export const DASHBOARD_UPDATE_IDENTITAS = "DASHBOARD_UPDATE_IDENTITAS";

export const DASHBOARD_SYNC_PDDIKTI_REQUEST = "DASHBOARD_SYNC_PDDIKTI_REQUEST";
export const DASHBOARD_SYNC_PDDIKTI_SUCCESS = "DASHBOARD_SYNC_PDDIKTI_SUCCESS";
export const DASHBOARD_SYNC_PDDIKTI_FAILURE = "DASHBOARD_SYNC_PDDIKTI_FAILURE";

export const DASHBOARD_SYNC_SINTA_REQUEST = "DASHBOARD_SYNC_SINTA_REQUEST";
export const DASHBOARD_SYNC_SINTA_SUCCESS = "DASHBOARD_SYNC_SINTA_SUCCESS";
export const DASHBOARD_SYNC_SINTA_FAILURE = "DASHBOARD_SYNC_SINTA_FAILURE";

export const ARTICLE_REQUEST = "ARTICLE_REQUEST";
export const ARTICLE_SUCCESS = "ARTICLE_SUCCESS";
export const ARTICLE_FAILURE = "ARTICLE_FAILURE";
export const ARTICLE_SAVE = "ARTICLE_SAVE";

export const GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_REQUEST =
  "GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_REQUEST";
export const GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_SUCCESS =
  "GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_SUCCESS";
export const GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_FAILURE =
  "GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_FAILURE";

export const GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_REQUEST =
  "GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_REQUEST";
export const GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_SUCCESS =
  "GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_SUCCESS";
export const GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_FAILURE =
  "GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_FAILURE";
