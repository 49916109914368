import { content, contentPersetujuan } from "./content";
import stylePDF from "./styles";

export const pdfCreate = item => {
  const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;

  return {
    content: [...content(item)],

    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};

export const pdfCreatePersetujuan = (item = {}) => {
  const { pageSize, styles, defaultStyle } = stylePDF;
  const itemPersetujuan = (item?.kegiatans || []).find(row => row.urutan === 1);
  return {
    content: [
      ...contentPersetujuan({
        ...itemPersetujuan,
        tgl_updated: item.tgl_updated,
      }),
    ],
    defaultStyle,
    pageMargins: [45, 32, 45, 45],
    pageSize,
    styles,
  };
};
