// ROOTS
export const PENGUMUMAN_REQUEST = "PENGUMUMAN_REQUEST";
export const PENGUMUMAN_SUCCESS = "PENGUMUMAN_SUCCESS";
export const PENGUMUMAN_FAILURE = "PENGUMUMAN_FAILURE";

// Get pengumuman landing page
export const PENGUMUMAN_LANDING_REQUEST = "PENGUMUMAN_LANDING_REQUEST";
export const PENGUMUMAN_LANDING_SUCCESS = "PENGUMUMAN_LANDING_SUCCESS";
export const PENGUMUMAN_LANDING_FAILURE = "PENGUMUMAN_LANDING_FAILURE";

// Get Pengumuman By id
export const PENGUMUMAN_BY_ID_REQUEST = "PENGUMUMAN_BY_ID_REQUEST";
export const PENGUMUMAN_BY_ID_SUCCESS = "PENGUMUMAN_BY_ID_SUCCESS";
export const PENGUMUMAN_BY_ID_FAILURE = "PENGUMUMAN_BY_ID_FAILURE";

// Create Pengumuman
export const CREATE_PENGUMUMAN_REQUEST = "CREATE_PENGUMUMAN_REQUEST";
export const CREATE_PENGUMUMAN_SUCCESS = "CREATE_PENGUMUMAN_SUCCES";

// Delete Pengumuman
export const DELETE_PENGUMUMAN_REQUEST = "DELETE_PENGUMUMAN_REQUEST";
export const DELETE_PENGUMUMAN_SUCCESS = "DELETE_PENGUMUMAN_SUCCESS";

// EDIT Pengumuman
export const UPDATE_PENGUMUMAN_REQUEST = "EDIT_PENGUMUMAN_REQUEST";
export const UPDATE_PENGUMUMAN_SUCCESS = "EDIT_PENGUMUMAN_SUCCESS";
export const UPDATE_PENGUMUMAN_FAILURE = "EDIT_PENGUMUMAN_FAILURE";

// Create Pengumuman Upload File
export const PENGUMUMAN_UPLOAD_REQUEST = "PENGUMUMAN_UPLOAD_REQUEST";
export const PENGUMUMAN_UPLOAD_SUCCESS = "PENGUMUMAN_UPLOAD_SUCCESS";

// Delete File
export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";
