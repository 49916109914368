import stylePDF from "./styles";
import header from "./header";
import utilities from "helpers/utilities";

const buildTblIdentitas = (data = {}) => {
    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: _i => 0.5,
            padding: () => 0,
        },
        table: {
            widths: ["30%", "15%", "15%", "15%", "25%"],
            body: [
                [
                    {
                        text: `Bidang Fokus Kosabangsa`,
                        style: ["center"],
                    },
                    {
                        text: `Tahun Pelaksanaan`,
                        style: ["center"],
                    },
                    {
                        text: `Lama Kegiatan`,
                        style: ["center"],
                    },
                    {
                        text: `Skema`,
                        style: ["center"],
                    },
                    {
                        text: `Rumpun Ilmu`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    layout.table.body.push([
        {
            text: data.nama_bidang_fokus,
            style: ["center"],
        },
        {
            text: data.tahun_pelaksanaan,
            style: ["center"],
        },
        {
            text: data.lama_kegiatan + "  Tahun",
            style: ["center"],
        },
        {
            text: 'Kosabangsa',
            style: ["center"],
        },
        {
            text: data?.nama_rumpun_ilmu_lvl_2,
            style: ["center"],
        },
    ]);

    return layout;
};

const buildTblAnggota = (personil = []) => {
    const layout = {
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["25%", "30%", "35%", "10%"],
            body: [
                [
                    {
                        text: `Nama, Peran, Institusi`,
                        style: ["center"],
                    },

                    {
                        text: `Program Studi/Bidan Keahlian`,
                        style: ["center"],
                    },
                    {
                        text: `Bidang Tugas`,
                        style: ["center"],
                    },
                    {
                        text: `ID Sinta`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    personil
        .sort((a, b) => (a.kode_peran > b.kode_peran ? 1 : b.kode_peran > a.kode_peran ? -1 : 0))
        .forEach(item => {
            layout.table.body.push([
                {
                    text: `${item.nama || item?.raw?.nama} \n\n\n ${item.nama_peran || "Anggota"} \n\n ${item?.raw?.nama_institusi || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.raw?.nama_program_studi || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.uraian_tugas || item?.raw?.uraian_tugas || "-"}`,
                },
                {
                    text: `${item?.id_sinta || ""}`,
                    link: `${item?.id_sinta ? `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}` : "#"}`,
                    style: ['link', 'center']
                },
            ]);
        });

    return layout;
};

const buildTblAnggotaMhs = (personil = []) => {
    const layout = {
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["15%", "20%", '20%', "20%", "25%"],
            body: [
                [
                    {
                        text: 'NIM',
                        style: ["center"],
                    },
                    {
                        text: `Nama`,
                        style: ["center"],
                    },

                    {
                        text: `Institusi`,
                        style: ["center"],
                    },
                    {
                        text: `Program Studi`,
                        style: ["center"],
                    },
                    {
                        text: `Bidang Tugas`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    personil
        .sort((a, b) => (a.raw.urutan > b.raw.urutan ? 1 : b.raw.urutan > a.raw.urutan ? -1 : 0))
        .forEach(item => {
            layout.table.body.push([
                {
                    text: item.raw?.nim,
                    style: ["center"],
                },
                {
                    text: `${item?.raw?.nama}`,
                    style: ["center"],
                },
                {
                    text: item?.raw?.institusi || "-",
                    style: ["center"],
                },
                {
                    text: `${item?.raw?.prodi || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.raw?.uraian_tugas || "-"}`,
                },

            ]);
        });

    return layout;
};

const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
    let thnAkhirUsulan = parseInt(thnPertamaUsulan);
    for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
        thnAkhirUsulan += tahun;
    }
    return thnAkhirUsulan;
};

const buildTblLuaran = (luaran = []) => {
    const layout = {
        style: ["center"],
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 1,
        },
        table: {
            widths: ["5%", "24%", "28%", "18%", "25%"],
            body: [
                [
                    {
                        text: "No.",
                        style: ["center"],
                    },
                    {
                        text: "Kategori Luaran",
                        style: ["center"],
                    },
                    {
                        text: "Jenis Luaran",
                        style: ["center"],
                    },
                    {
                        text: "Status target capaian",
                        style: ["center"],
                    },
                    {
                        text: "Keterangan",
                    },
                ],
            ],
        },
    };
    luaran.forEach((item, i) => {
        layout.table.body.push([
            {
                text: i + 1,
            },
            {
                text: item.raw?.nama_kategori_jenis_luaran,
            },
            {
                text: item.raw?.jenis_luaran,
            },
            {
                text: item.raw?.status_capaian,
            },
            {
                text: item?.keterangan || "-",
            },
        ]);
    });
    return layout;
};

const buildTblAnggaran = (data = []) => {
    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["23%", "15%", "20%", "10%", "5%", "12%", "15%"],
            body: [
                [
                    {
                        text: `Jenis Pembelanjaan`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Komponen`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Item`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Satuan`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Vol.`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Biaya Satuan`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Total`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                ],
            ],
        },
    };

    data.forEach(item => {
        const volume = parseInt(item.volume);
        const harga_satuan = utilities.unmaskMoney(item.harga_satuan);
        const total = volume * harga_satuan;

        layout.table.body.push([
            {
                text: `${item?.raw?.nama_kelompok_rab}`,
            },
            {
                text: `${item?.raw?.rab_komponen_belanja}`,
                style: ["center"],
            },
            {
                text: `${item.nama_item}`,
                style: ["center"],
            },
            {
                text: `${item.satuan || "-"}`,
                style: ["center"],
            },
            {
                text: `${volume || "-"}`,
                style: ["center"],
            },
            {
                text: `${utilities.toCurrency(harga_satuan || 0, ".", 0)}`,
                style: ["right"],
            },
            {
                text: `${utilities.toCurrency(total || 0, ".", 0)}`,
                style: ["right"],
            },
        ]);
    });

    return layout;
};

const buildTblMitra = (data, jenis_mitra) => {
    let header = [];
    let widths = [];

    if (jenis_mitra === "sasaran") {
        header = [
            {
                text: 'Nama Mitra',
                property: 'nama_mitra',
                style: ["left"],
            },
            {
                text: 'Nama Pimpinan',
                property: 'pimpinan_mitra',
                style: ["left"],
            },
            {
                text: 'Kelompok',
                property: 'kelompok_mitra',
                style: ["left"],
            },
            {
                text: 'Jenis',
                property: 'jenis_mitra',
                style: ["left"],
            },
        ];
        widths = ["25%", "25%", "25%", "25%"];
    } else if (jenis_mitra === "kegiatan") {
        header = [
            {
                text: 'Pimpinan Mitra Kegiatan',
                property: 'pimpinan_mitra',
                style: ["left"],
            },
            {
                text: 'Desa',
                property: 'desa_mitra_kagiatan',
                style: ["left"],
            },
            {
                text: 'Kabupaten/Kota',
                property: 'nama_kabupaten',
                style: ["left"],
            },
            {
                text: 'Provinsi',
                property: 'nama_provinsi',
                style: ["left"],
            },
            {
                text: 'Kedudukan Mitra',
                property: 'kedudukan_mitra',
                property2: 'nama_kedudukan_mitra',
                style: ["left"],
            },
        ];
        widths = ["25%", "15%", "15%", "15%", " 30%"];
    } else if (jenis_mitra === "kerjasama") {
        header = [
            {
                text: 'Jenis Mitra Kerjasama',
                property: 'jenis_mitra_kerjasama',
                style: ["left"],
            },
            {
                text: 'Nama Mitra',
                property: 'nama_mitra_kerjasama',
                style: ["left"],
            },
            {
                text: 'Dana in Kind',
                property: 'dana_in_kind',
                style: ["right"],
            },
            {
                text: 'Dana in Cash',
                property: 'dana_in_cash',
                style: ["right"],
            },

        ];
        widths = ["25%", "25%", "25%", "25%"];
    }

    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: widths,
            body: [
                header.map(h => ({
                    text: h.text,
                    style: ['center']
                }))
            ],
        },
    };

    data.forEach(item => {
        layout.table.body.push(header.map(h => ({
            text: `${h.property.includes('dana') ? (
                utilities.toCurrency(item[h.property], '.', 2, '')
            ) : (
                item[h.property]
            )}
            ${h?.property2 ? ' - ' + item[h.property2] : ''}`,
            style: h.style
        })));
    });


    return layout;

}

const content = (data, _pageBreak = false) => {
    const { hr } = stylePDF;
    const {

        raw: {
            identitas,

        }
    } = data;


    return [
        header(),
        hr(5, 0),
        {
            stack: [
                {
                    text: "PROTEKSI ISI PROPOSAL",
                    style: ["bold"],
                },
                {
                    text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
                    style: ["fontSizeXs"],
                },
                {
                    text: `PROPOSAL KOSABANGSA ${identitas?.tahun_pelaksanaan ?? ""
                        }`,
                    color: "#FFFFFF",
                    background: "#000",
                    style: ["center", "bold"],
                },
                {
                    text: `Rencana Pelaksanaan Kosabangsa: tahun ${identitas?.tahun_pelaksanaan ?? ""
                        } s.d. tahun ${getThnAkhirUsulan(
                            identitas.lama_kegiatan,
                            identitas.tahun_pelaksanaan
                        )}`,
                    style: ["fontSizeXs"],
                },
            ],
            style: ["center", "mb-5"],
        },
        ...contentKosabangsa(data)
    ];
};

export const contentKosabangsa = (data, dataRevisi = null) => {

    const {
        personil,
        personil_non_dosen,
        luaran,
        rab,
        raw: {
            identitas,
            mitra,
        }
    } = data;

    const anggotaPelaksana = (personil || []).filter(row => row.raw.jenis_anggota === "pelaksana" && row.raw.kode_program_pendidikan !== "2");
    const anggotaPelaksanaTambahan = (personil || []).filter(row => row.raw.jenis_anggota === "pelaksana" && row.raw.kode_program_pendidikan === "2");

    const anggotaPendamping = (personil || []).filter(row => row.raw.jenis_anggota === "pendamping" && row.raw.kode_program_pendidikan !== "2");
    const anggotaPendampingTambahan = (personil || []).filter(row => row.raw.jenis_anggota === "pendamping" && row.raw.kode_program_pendidikan === "2");

    const anggotaMhs = (personil_non_dosen || []).filter(item => item.kind === "mahasiswa");

    const { mitra_sasaran, mitra_kegiatan, mitra_kerjasama } = mitra;

    const totalAnggaran = (rab || []).reduce(
        (a, { harga_satuan, volume }) =>
            a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
        0
    );

    return [
        {
            stack: [
                {
                    text: "1. JUDUL",
                    style: ["bold"],
                },
                {
                    style: ["text", "mb-10"],
                    layout: {
                        hLineWidth: (_i, _node) => 0.3,
                        vLineWidth: _i => 0.3,
                        padding: () => 1,
                    },
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: identitas?.judul,
                                },
                            ],
                        ],
                    },
                },

                buildTblIdentitas(identitas),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "2. IDENTITAS TIM PELAKSANA",
                    style: ["bold"],
                },
                buildTblAnggota(anggotaPelaksana),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "3. IDENTITAS TIM PELAKSANA TAMBAHAN (VOKASI)",
                    style: ["bold"],
                },
                buildTblAnggota(anggotaPelaksanaTambahan),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "4. IDENTITAS TIM PENDAMPING",
                    style: ["bold"],
                },
                buildTblAnggota(anggotaPendamping),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "5. IDENTITAS TIM PENDAMPING TAMBAHAN (VOKASI)",
                    style: ["bold"],
                },
                buildTblAnggota(anggotaPendampingTambahan),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "6. IDENTITAS ANGGOTA MAHASISWA",
                    style: ["bold"],
                },
                buildTblAnggotaMhs(anggotaMhs),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "7. LUARAN DAN TARGET CAPAIAN",
                    style: ["bold"],
                },
                {
                    text: "Luaran Wajib",
                    style: ["bold", "mt-10"],
                },
                buildTblLuaran(luaran || []),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "8. ANGGARAN",
                    style: ["bold", "mb-10"],
                },
                {
                    text: `Total RAB 1 Tahun ${utilities.toCurrency(totalAnggaran, ".", 2, "Rp. ")}`,
                    style: ["bold"],
                },
                buildTblAnggaran(rab || []),
            ],
            style: ["mb-10"],
        },
        {

            style: ["mt-10"],
            stack: contentAnggaranRevisi((data?.rab_revisi || dataRevisi?.rab_revisi || null), (data?.dana_disetujui_thn_1 || dataRevisi?.dana_disetujui_thn_1))
        },
        {
            stack: [
                {
                    text: `${(data?.rab_revisi || dataRevisi?.rab_revisi) ? "10" : "9"}. MITRA`,
                    style: ["bold", "mb-10"],
                },
                {
                    text: `Mitra Sasaran`,
                    style: ["bold",],
                },
                buildTblMitra(mitra_sasaran || [], 'sasaran'),
                {
                    text: `Mitra Kegiatan`,
                    style: ["bold"],
                },
                buildTblMitra(mitra_kegiatan || [], 'kegiatan'),
                {
                    text: `Mitra Kerjasama`,
                    style: ["bold"],
                },
                buildTblMitra(mitra_kerjasama || [], 'kerjasama'),
            ],
            style: ["mb-10"],
        },

    ];

}

export const contentAnggaranRevisi = (rab_revisi, danaDisetujui = 0) => {


    if (rab_revisi === null || !rab_revisi || rab_revisi === undefined || rab_revisi?.length === 0) {
        return [];
    } else {
        const getTotalRab = () => {
            const totalAnggaran = rab_revisi?.reduce((x, y) => x + (y.harga_satuan * y.volume), 0);
            return totalAnggaran;
        }

        return [
            {
                text: "9. ANGGARAN PERBAIKAN",
                style: ["bold", "mb-10"],

            },

            {

                text: [
                    `Dana Tahun ke-1 : Direncanakan `,
                    {
                        text: `${utilities.toCurrency(getTotalRab(), '.', 2, 'Rp')}`,
                        style: ["bold"],
                    },
                    ' | Disetujui ',
                    {
                        text: `${utilities.toCurrency(danaDisetujui, ".", 2, 'Rp')}`,
                        style: ["bold"],
                    },

                ],
            },
            {
                ...buildTblAnggaran(rab_revisi.filter(row => row.harga_satuan !== 0))
            }
        ];
    }

}


const contentPersetujuan = (data = {}) => {
    let { approval_raw, approval_status } = data;
    approval_raw = approval_raw ?? {};

    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*"],
            body: [
                [
                    {
                        text: `Tanggal Pengiriman`,
                        style: ["center"],
                    },
                    {
                        text: `Tanggal Persetujuan`,
                        style: ["center"],
                    },
                    {
                        text: `Nama Pimpinan Pemberi Persetujuan`,
                        style: ["center"],
                    },
                    {
                        text: `Sebutan Jabatan Unit`,
                        style: ["center"],
                    },
                    {
                        text: `Nama Unit Lembaga Pengusul`,
                        style: ["center"],
                    },
                ],
            ],
        },
    };

    layout.table.body.push([
        {
            text: `${data?.tgl_updated
                ? utilities.convertDate(data?.tgl_updated, "DD/MM/YYYY")
                : "-"
                }`,
            style: ["center"],
        },
        {
            text: `${approval_raw?.approval_date
                ? utilities.convertDate(approval_raw?.approval_date, "DD/MM/YYYY")
                : "-"
                }`,
            style: ["center"],
        },
        {
            text: `${approval_raw?.full_name || "-"}`,
            style: ["center"],
        },
        {
            text: `${approval_raw?.nama_jabatan || "-"}`,
            style: ["center"],
        },
        {
            text: `${approval_raw?.nama_lembaga || "-"}`,
            style: ["center"],
        },
    ]);

    return [
        {
            stack: [
                {
                    text: "PERSETUJUAN PENGUSUL",
                    style: ["bold"],
                },
                {
                    ...layout,
                },
            ],
        },
        {
            style: ["mt-10"],
            stack: [
                {
                    text: `Komentar : ${approval_status === "approved" ? "Disetujui" : approval_status === "rejected" ? "Ditolak" : "-"}`,
                    style: ["bold", "mb-1"],
                },
                {
                    style: ["text"],
                    layout: {
                        hLineWidth: (_i, _node) => (approval_raw?.comment ? 0.3 : 0),
                        vLineWidth: _i => (approval_raw?.comment ? 0.3 : 0),
                        padding: () => 1,
                    },
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: approval_raw?.comment,
                                },
                            ],
                        ],
                    },
                },
            ],
        },
    ];
};


export { content, contentPersetujuan };
