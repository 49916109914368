import { Content, contentPersetujuan } from "./content";
import { ContentDikti } from "./contentDikti";
import stylePDF from "./styles";

export const PdfCreating = (data, direktorat) => {
  const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
  return {
    content:
      direktorat === "vokasi" ? [...Content(data)] : [...ContentDikti(data)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};
export const pdfCreatePersetujuan = ({ item = {} }) => {
  const { pageSize, styles, defaultStyle } = stylePDF;
  const itemPersetujuan = (item?.kegiatans || []).find(row => row.urutan === 1);
  return {
    content: [
      ...contentPersetujuan({
        ...itemPersetujuan,
        tgl_updated: item.tgl_updated,
      }),
    ],
    defaultStyle,
    pageMargins: [45, 32, 45, 45],
    pageSize,
    styles,
  };
};
