import {
  GET_LIST_MINAT_KATALIS_OPT_REQUEST,
  GET_LIST_MINAT_KATALIS_OPT_SUCCESS,
  GET_LIST_MINAT_KATALIS_OPT_FAILURE,
  UPDATE_MINAT_KATALIS_OPT_REQUEST,
  UPDATE_MINAT_KATALIS_OPT_SUCCESS,
  UPDATE_MINAT_KATALIS_OPT_FAILURE,
} from "./actionTypes";

export const getListMinatKatalisOpt = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_MINAT_KATALIS_OPT_REQUEST,
    payload: request,
    cb,
  };
};

export const getListMinatKatalisOptSuccess = response => {
  return {
    type: GET_LIST_MINAT_KATALIS_OPT_SUCCESS,
    payload: response,
  };
};

export const getListMinatKatalisOptFailure = (message, response) => {
  return {
    type: GET_LIST_MINAT_KATALIS_OPT_FAILURE,
    payload: { errors: response, message },
  };
};
export const updateStatusMinatKatalisOpt = (request = {}, cb = () => {}) => {
  return {
    type: UPDATE_MINAT_KATALIS_OPT_REQUEST,
    payload: request,
    cb,
  };
};

export const updateStatusMinatKatalisOptSuccess = response => {
  return {
    type: UPDATE_MINAT_KATALIS_OPT_SUCCESS,
    payload: response,
  };
};

export const updateStatusMinatKatalisOptFailure = (message, response) => {
  return {
    type: UPDATE_MINAT_KATALIS_OPT_FAILURE,
    payload: { errors: response, message },
  };
};
