// ROOTS
export const BERITA_REQUEST = "BERITA_REQUEST";
export const BERITA_SUCCESS = "BERITA_SUCCESS";
export const BERITA_FAILURE = "BERITA_FAILURE";

// GET BERITA 1:3 FOR LANDING PAGE
export const BERITA_LANDING_REQUEST = "BERITA_LANDING_REQUEST";
export const BERITA_LANDING_SUCCESS = "BERITA_LANDING_SUCCESS";
export const BERITA_LANDING_FAILURE = "BERITA_LANDING_FAILURE";

// GET BERITA BY ID
export const BERITA_BY_ID_REQUEST = "BERITA_BY_ID_REQUEST";
export const BERITA_BY_ID_SUCCESS = "BERITA_BY_ID_SUCCESS";
export const BERITA_BY_ID_FAILURE = "BERITA_BY_ID_FAILURE";

// CREATE BERITA
export const CREATE_BERITA_REQUEST = "CREATE_BERITA_REQUEST";
export const CREATE_BERITA_SUCCESS = "CREATE_BERITA_SUCCESS";
export const CREATE_BERITA_FAILURE = "CREATE_BERITA_FAILURE";

// UPLOAD FILE
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

// DELETE BERITA
export const DELETE_BERITA_REQUEST = "DELETE_BERITA_REQUEST";
export const DELETE_BERITA_SUCCESS = "DELETE_BERITA_SUCCESS";

// UPDATE BERITA
export const UPDATE_BERITA_REQUEST = "UPDATE_BERITA_REQUEST";
export const UPDATE_BERITA_SUCCESS = "UPDATE_BERITA_SUCCESS";
export const UPDATE_BERITA_FAILURE = "UPDATE_BERITA_FAILURE";

// DELETE IMG BERITA
export const DELETE_IMG_BERITA_REQUEST = "DELETE_IMG_BERITA_REQUEST";
export const DELETE_IMG_BERITA_SUCCESS = "DELETE_IMG_BERITA_SUCCESS";
export const DELETE_IMG_BERITA_FAILURE = "DELETE_IMG_BERITA_FAILURE";
