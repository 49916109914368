
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
// import * as backend from "../../helpers/penugasan";
import * as backend from "../../../helpers/penugasan";
// Action Types
import {
    CREATE_PENUGASAN_PEMBELAJARAN_REQUEST, GET_PENUGASAN_PEMBELAJARAN_BYID_REQUEST, GET_PENUGASAN_PEMBELAJARAN_REQUEST, UPDATE_PENUGASAN_PEMBELAJARAN_REQUEST
} from "../actionTypes";
// Action
import { toast } from "react-toastify";
import {
    createPenugasanPembelajaranFailure, createPenugasanPembelajaranSuccess,
    getPenugasanPembelajaranByIdFailure, getPenugasanPembelajaranByIdSuccess,
    getPenugasanPembelajaranFailure, getPenugasanPembelajaranSuccess,
    updatePenugasanPembelajaranFailure, updatePenugasanPembelajaranSuccess
} from "./action";


function* createPenugasanPembelajaran({ payload, cb }) {
    try {
        const res = yield call(backend.createPenugasanPembelajaran, payload);
        if (res.data.code === 200) {
            yield put(
                createPenugasanPembelajaranSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan");
            cb(res.data);
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(createPenugasanPembelajaranFailure(message.error));
        cb({
            code: 500,
            message: message.error
        })

    }
}

function* updatePenugasanPembelajaran({ payload, id, cb }) {
    try {
        const res = yield call(backend.updatePenugasanPembelajaran, { payload, id });
        if (res.data.code === 200) {
            yield put(
                updatePenugasanPembelajaranSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan di Draft");
            cb(res.data);
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(updatePenugasanPembelajaranFailure(message.error));
        cb({
            code: 500,
            message: message.error
        });
    }
}

function* getPenugasanPembelajaran() {
    try {
        const res = yield call(backend.getPenugasanPembelajaran);
        if (res.data.code === 200) {
            yield put(
                getPenugasanPembelajaranSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanPembelajaranFailure(message.error));
    }
}

function* getPenugasanPembelajaranById({ payload, cb }) {
    try {

        const res = yield call(backend.getPenugasanPembelajaranById, payload.id);
        if (res.data.code === 200) {
            yield put(
                getPenugasanPembelajaranByIdSuccess({ data: res.data.data })
            )
            cb(res.data)
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanPembelajaranByIdFailure(message.error));
        cb({
            code: 500,
            message: message.error
        })
    }
}


function* notificationSaga() {
    yield takeEvery(CREATE_PENUGASAN_PEMBELAJARAN_REQUEST, createPenugasanPembelajaran);
    yield takeEvery(GET_PENUGASAN_PEMBELAJARAN_REQUEST, getPenugasanPembelajaran);
    yield takeEvery(GET_PENUGASAN_PEMBELAJARAN_BYID_REQUEST, getPenugasanPembelajaranById);
    yield takeEvery(UPDATE_PENUGASAN_PEMBELAJARAN_REQUEST, updatePenugasanPembelajaran);
}

export default notificationSaga;
