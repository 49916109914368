import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";
// Action Types
import { GET_DETAIL_USULAN_PERBAIKAN_V2_REQUEST, GET_USULAN_PERBAIKAN_V2_REQUEST, MERGING_INTO_USULAN_PERBAIKAN_V2_REQUEST, UPDATE_USULAN_PERBAIKAN_V2_REQUEST, USULAN_PERBAIKAN_BYID_REQUEST, USULAN_PERBAIKAN_REQUEST, USULAN_PERBAIKAN_SUBMIT_REQUEST } from "./actionTypes";
// Action
import { getUsulanPerbaikanDetailV2Failure, getUsulanPerbaikanDetailV2Success, getUsulanPerbaikanFailure, getUsulanPerbaikanSuccess, getUsulanPerbaikanV2Failure, getUsulanPerbaikanV2Success, mergingIntoUsulanFailure, mergingIntoUsulanSuccess, submitUsulanPerbaikanFailure, submitUsulanPerbaikanSuccess, updateUsulanPerbaikanV2Failure, updateUsulanPerbaikanV2Success } from "./action";
import { toast } from "react-toastify";

function* updateUsulanPerbaikanV2({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.updateUsulanPerbaikanV2, payload);

    if (response.data.code == 200) {
      yield put(updateUsulanPerbaikanV2Success(response, response.data.data));
      cb(response.data.data);

    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    const message = error.message;

    cb(null);
    yield put(updateUsulanPerbaikanV2Failure(message, error));
  }
}

function* getUsulanPerbaikanV2({ payload, cb }) {
  try {
    const response = yield call(payload?.monitoring ? backend.getUsulanPerbaikanMonitoringV2 : backend.getUsulanPerbaikanV2, payload);
    if (response.data.code == 200) {
      yield put(getUsulanPerbaikanV2Success(response, response.data.data));
      cb(response.data.data)

    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    cb(null);
    const message = error.message;
    yield put(getUsulanPerbaikanV2Failure(message, error));
  }
}

function* mergingIntoUsulanPerbaikanV2({ payload }) {
  try {

    const itemsMapping = payload.map(item => {
      const usulanKegiatanSelected = item.usulan_kegiatan.find(usulan => usulan.id === item.id_usulan_kegiatan);
      const pengusul = (usulanKegiatanSelected?.personil || []).find(person => person.peran_personil.id === "A");

      return {
        "id_proposal_kegiatan": item.id_usulan_kegiatan,
        "id_proposal": item.id,
        "id_skema": item.id_skema,
        "judul": item.judul,
        "nama_skema": item.skema_kegiatan?.nama || "-",
        "thn_usulan": item.thn_pertama_usulan,
        "thn_pelaksanaan": item.thn_pelaksanaan_kegiatan,
        "nama_bidang_fokus": item.bidang_fokus.bidang_fokus,
        "dana_disetujui": item.pendanaan.dana_disetujui,
        "dana_diajukan": 0,
        "status": item?.kode_status_pelaksanaan === "1" ? "submitted" : ( item.kode_status_pelaksanaan === "0" ? "" : "draft"),
        "lama_kegiatan": item.lama_kegiatan,
        "tgl_perbaikan": item.tgl_updated,
        "jml_personil": usulanKegiatanSelected?.personil?.length || 0,
        "nidn": pengusul?.nidn,
        "nama_ketua": pengusul?.personal.nama,
        "is_old_version" : true
      }
    })
    yield put(mergingIntoUsulanSuccess(itemsMapping));
  } catch (error) {
 
    const message = error.message;
    yield put(mergingIntoUsulanFailure(message, error));
  }
}

function* getDetailUsulanPerbaikanV2({ payload, cb }) {
  try {
    const response = yield call(backend.getUsulanPerbaikanV2, payload);
    if (response.data.code == 200) {
      if (payload) {
        yield put(getUsulanPerbaikanDetailV2Success(response, response.data.data));
        cb(response.data.data)
      } else {
        cb(null);

      }
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    cb(null);
    const message = error.message;
    yield put(getUsulanPerbaikanDetailV2Failure(message, error));
  }
}

function* getUsulanPerbaikan({ payload, cb }) {
  try {
    const response = yield call(backend.getUsulanPerbaikan, payload);
    if (response.data.code == 200) {
      if (payload && payload.id) {
        yield put(getUsulanPerbaikanSuccess(null, response.data.data));
        cb(response.data.data)
      } else {
        yield put(getUsulanPerbaikanSuccess(response.data.data));
        cb(response.data.data)
      }
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    yield put(getUsulanPerbaikanFailure(message, error, payload.id));
  }
}

function* submitUsulanPerbaikan({ payload, history }) {
  try {
    const response = yield call(backend.submitUsulanPerbaikan, payload);
    // console.log(response)
    // console.log(history)
    if (response.data.code == 200) {
      yield put(submitUsulanPerbaikanSuccess(response.data.data));
      toast.success("Berhasil menyimpan data");
      history.push(`/${window.location.pathname.includes("/pengabdian") ? "pengabdian" : "penelitian"}/revisi`);
    } else {
      throw new Error(response.data.message)
    }
  } catch (error) {
    const message = error.message;
    yield put(submitUsulanPerbaikanFailure(message, error));
  }
}

function* notificationSaga() {
  yield takeEvery(USULAN_PERBAIKAN_REQUEST, getUsulanPerbaikan);
  yield takeEvery(USULAN_PERBAIKAN_BYID_REQUEST, getUsulanPerbaikan);
  yield takeEvery(GET_USULAN_PERBAIKAN_V2_REQUEST, getUsulanPerbaikanV2);
  yield takeEvery(GET_DETAIL_USULAN_PERBAIKAN_V2_REQUEST, getDetailUsulanPerbaikanV2);
  yield takeEvery(USULAN_PERBAIKAN_SUBMIT_REQUEST, submitUsulanPerbaikan);
  yield takeEvery(UPDATE_USULAN_PERBAIKAN_V2_REQUEST, updateUsulanPerbaikanV2);
  yield takeEvery(MERGING_INTO_USULAN_PERBAIKAN_V2_REQUEST, mergingIntoUsulanPerbaikanV2);

}

export default notificationSaga;
