
import {
    CREATE_PENDAFTARAN_REVIEWER_REQUEST,
    CREATE_PENDAFTARAN_REVIEWER_FAILURE,
    CREATE_PENDAFTARAN_REVIEWER_SUCCESS,
    GET_PENDAFTARAN_REVIEWER_REQUEST,
    GET_PENDAFTARAN_REVIEWER_FAILURE,
    GET_PENDAFTARAN_REVIEWER_SUCCESS,
    GET_PENDAFTARAN_REVIEWER_PERSONAL_REQUEST,
    GET_PENDAFTARAN_REVIEWER_PERSONAL_SUCCESS,
    GET_PENDAFTARAN_REVIEWER_PERSONAL_FAILURE,
    VERIFY_PENDAFTARAN_REVIEWER_REQUEST,
    VERIFY_PENDAFTARAN_REVIEWER_SUCCESS,
    VERIFY_PENDAFTARAN_REVIEWER_FAILURE
} from "./actionTypes";

const initialState = {
    listPendaftaran: {
        isFetching: false,
        items: [],
        errorMessage: "",
        errors: null,
    },
    pendaftaran: {
        isFetching: false,
        items: [],
        errorMessage: "",
        errors: null,
    },
    submit: {
        isFetching: false,
        item: {},
        errorMessage: "",
        errors: null,
    },
    verification: {
        isFetching: false,
        item: {},
        errorMessage: "",
    }

};

const pendaftaranReviewer = (state = initialState, action) => {
    switch (action.type) {

        case VERIFY_PENDAFTARAN_REVIEWER_REQUEST:
            return (state = {
                ...state,
                verification: {
                    ...state.verification,
                    isFetching: true,
                }
            })
        case VERIFY_PENDAFTARAN_REVIEWER_SUCCESS:
            return (state = {
                ...state,
                verification: {
                    ...state.verification,
                    isFetching: false,
                    item: action.payload.data,
                }
            })
        case VERIFY_PENDAFTARAN_REVIEWER_FAILURE:
            return (state = {
                ...state,
                verification: {
                    ...state.verification,
                    isFetching: false,
                    item: action.payload.data,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            })
        case CREATE_PENDAFTARAN_REVIEWER_REQUEST:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: true,
                }
            })
        case CREATE_PENDAFTARAN_REVIEWER_SUCCESS:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: false,
                    item: action.payload.data,
                }
            })
        case CREATE_PENDAFTARAN_REVIEWER_FAILURE:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            })
        case GET_PENDAFTARAN_REVIEWER_REQUEST:
            return (state = {
                ...state,
                listPendaftaran: {
                    ...state.listPendaftaran,
                    isFetching: true,
                }
            })
        case GET_PENDAFTARAN_REVIEWER_SUCCESS:
            return (state = {
                ...state,
                listPendaftaran: {
                    ...state.listPendaftaran,
                    isFetching: false,
                    items: action.payload.data,
                }
            })
        case GET_PENDAFTARAN_REVIEWER_FAILURE:
            return (state = {
                ...state,
                listPendaftaran: {
                    ...state.listPendaftaran,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            })
        case GET_PENDAFTARAN_REVIEWER_PERSONAL_REQUEST:
            return (state = {
                ...state,
                pendaftaran: {
                    ...state.pendaftaran,
                    isFetching: true,
                }
            })
        case GET_PENDAFTARAN_REVIEWER_PERSONAL_SUCCESS:
            return (state = {
                ...state,
                pendaftaran: {
                    ...state.pendaftaran,
                    isFetching: false,
                    items: action.payload.data,
                }
            })
        case GET_PENDAFTARAN_REVIEWER_PERSONAL_FAILURE:
            return (state = {
                ...state,
                pendaftaran: {
                    ...state.pendaftaran,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            })
        default:
            return state;
    }
};

export default pendaftaranReviewer;
