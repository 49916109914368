import {
    CREATE_PENUGASAN_JFS_FAILURE, CREATE_PENUGASAN_JFS_REQUEST,
    CREATE_PENUGASAN_JFS_SUCCESS, GET_PENUGASAN_JFS_BYID_FAILURE,
    GET_PENUGASAN_JFS_BYID_REQUEST, GET_PENUGASAN_JFS_BYID_SUCCESS, GET_PENUGASAN_JFS_FAILURE, GET_PENUGASAN_JFS_REQUEST,
    GET_PENUGASAN_JFS_SUCCESS, UPDATE_PENUGASAN_JFS_FAILURE, UPDATE_PENUGASAN_JFS_REQUEST,
    UPDATE_PENUGASAN_JFS_SUCCESS
} from "../actionTypes";

export const createPenugasanJFS = (request = {}, cb = () => { }) => {
    return {
        type: CREATE_PENUGASAN_JFS_REQUEST,
        payload: request,
        cb
    };
}

export const createPenugasanJFSSuccess = response => {
    return {
        type: CREATE_PENUGASAN_JFS_SUCCESS,
        payload: response,
    };
}

export const createPenugasanJFSFailure = (message, response) => {
    return {
        type: CREATE_PENUGASAN_JFS_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanJFS = (request = {}) => {
    return {
        type: GET_PENUGASAN_JFS_REQUEST,
        payload: request,
    };
}

export const getPenugasanJFSSuccess = response => {
    return {
        type: GET_PENUGASAN_JFS_SUCCESS,
        payload: response,
    };
}

export const getPenugasanJFSFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_JFS_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanJFSById = (id, cb = () => { }) => {

    return {
        type: GET_PENUGASAN_JFS_BYID_REQUEST,
        payload: { id },
        cb
    };
}

export const getPenugasanJFSByIdSuccess = response => {
    return {
        type: GET_PENUGASAN_JFS_BYID_SUCCESS,
        payload: response,
    };
}

export const getPenugasanJFSByIdFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_JFS_BYID_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const updatePenugasanJFS = (request = {}, id, cb = () => { }) => {
    return {
        type: UPDATE_PENUGASAN_JFS_REQUEST,
        payload: request,
        cb,
        id
    };
}

export const updatePenugasanJFSSuccess = response => {
    return {
        type: UPDATE_PENUGASAN_JFS_SUCCESS,
        payload: response,
    };
}

export const updatePenugasanJFSFailure = (message, response) => {
    return {
        type: UPDATE_PENUGASAN_JFS_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}