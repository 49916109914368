
import LogoTutwuri from "../../../settings/tutwuriBase64";
export default () => {
    return {
        layout: {
            hLineWidth: (_i, _node) => 0,
            vLineWidth: (_i) => 0,
            paddingLeft: (_i) => 2,
            paddingTop: (_i) => 0,
        },
        table: {
            widths: [48, "auto"],
            body: [
                [
                    {
                        image: LogoTutwuri,
                        width: 48
                    },
                    {
                        text: `Kementrian Pendidikan, Kebudayaan, Riset dan Teknologi \n Jalan Jendral Sudirman, Senayan, Jakarta Pusat 10270 \n https://bima.kemdikbud.go.id`,
                        marginTop : 10,
                        style : ['bold']
                    }
                ]
            ]
        }
    }
}