

import { CLEAN_LIST_PROPOSAL_BIMTEK, GET_LIST_PROPOSAL_BIMTEK_FAILURE, GET_LIST_PROPOSAL_BIMTEK_REQUEST, GET_LIST_PROPOSAL_BIMTEK_SUCCESS, GET_PROPOSAL_BIMTEK_FAILURE, GET_PROPOSAL_BIMTEK_REQUEST, GET_PROPOSAL_BIMTEK_SUCCESS, UPDATE_PROPOSAL_BIMTEK_FAILURE, UPDATE_PROPOSAL_BIMTEK_REQUEST, UPDATE_PROPOSAL_BIMTEK_SUCCESS } from "./actionTypes";

export const getListBimtekProposal = (request = {}) => {
   return {
      type: GET_LIST_PROPOSAL_BIMTEK_REQUEST,
      payload: request
   };
}
export const getListBimtekProposalSuccess = (response = {}) => {
   return {
      type: GET_LIST_PROPOSAL_BIMTEK_SUCCESS,
      payload: response
   };
}
export const getListBimtekProposalFailure = (message, response) => {
   return {
      type: GET_LIST_PROPOSAL_BIMTEK_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}
export const cleanBimtekProposal = () => {
   return {
      type: CLEAN_LIST_PROPOSAL_BIMTEK,

   };
}

export const getBimtekProposal = (request = {}, cb = () => {}) => {
   return {
      type: GET_PROPOSAL_BIMTEK_REQUEST,
      payload: request,
      cb
   };
}
export const getBimtekProposalSuccess = (response = {}) => {
   return {
      type: GET_PROPOSAL_BIMTEK_SUCCESS,
      payload: response
   };
}
export const getBimtekProposalFailure = (message, response) => {
   return {
      type: GET_PROPOSAL_BIMTEK_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const updateBimtekProposal = (request = {}, cb = () => {}) => {
   return {
      type: UPDATE_PROPOSAL_BIMTEK_REQUEST,
      payload: request,
      cb
   };
}
export const updateBimtekProposalSuccess = (response = {}) => {
   return {
      type: UPDATE_PROPOSAL_BIMTEK_SUCCESS,
      payload: response
   };
}
export const updateBimtekProposalFailure = (message, response) => {
   return {
      type: UPDATE_PROPOSAL_BIMTEK_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}