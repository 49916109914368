import { instances } from "../../api_helper";
const API_V2_URL =
  process.env.REACT_APP_API_V2_URL || "http://localhost:8088/api/v2";
export const crudPendaftaranAkunOperatorPt = payload => {
  if (payload.method === "GET") {
    if (payload.options.type === "get-list") {
      return instances.request({
        baseURL: API_V2_URL,
        method: "GET",
        url: `/user-account/operator/request?page=${payload.queryParams.page}&limit=${payload.queryParams.limit}`,
      });
    } else if (payload.options.type === "check-kode-pt") {
      return instances.request({
        baseURL: API_V2_URL,
        method: "GET",
        url: `/user-account/operator/request/find-pt/${payload.queryParams.kd_pt}`,
      });
    }
  } else if (payload.method === "POST") {
    return instances.request({
      baseURL: API_V2_URL,
      url: `/user-account/operator/request`,
      method: "POST",
      data: payload.data,
    });
  }
};
export const getListKlasterPt = () => {
  return instances.request(`/enumeration?criteria=group:klaster-pt`, {
    method: "GET",
  });
};
export const processPendaftaranAkunPt = payload => {
  if (payload.method === "POST") {
    if (payload.options.type === "generate") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/user-account/operator/request/generate/${payload.queryParams.id}`,
        method: "POST",
      });
    } else if (payload.options.type === "send-account") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/user-account/operator/request/send/${payload.queryParams.id}`,
        method: "POST",
      });
    }
  } else if (payload.method === "PATCH") {
    if (payload.options.type === "set-klaster-koperstis") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/user-account/operator/request/${payload.queryParams.id}/set-attribute`,
        method: "PATCH",
        data: payload.data,
      });
    }
  }
};
