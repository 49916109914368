
import { content } from "./content";
import stylePDF from "./styles";

export const pdfCreate = (item) => {
    const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
    return {
        content: [
            ...content(item)
        ],
        defaultStyle,
        pageMargins,
        pageSize,
        styles,
    };
}


