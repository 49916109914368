import { toast } from "react-toastify";
const handleToastWarning = (idToast, message = "") => {
  toast.update(idToast, {
    render: message,
    type: "warning",
    isLoading: false,
    autoClose: 3000,
  });
};

export default handleToastWarning;
