/* eslint-disable no-unused-vars */
import { buildTblAnggaran } from "../../usulanPenelitian/partials/content";
import headers from "./headers";
import stylePDF from "./styles";
import utilities from "helpers/utilities";

export const Content = data => {
  
  const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
    let thnAkhirUsulan = parseInt(thnPertamaUsulan);
    for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
      thnAkhirUsulan += tahun;
    }
    return thnAkhirUsulan;
  };

  const { hr } = stylePDF;

  return [
    // * Header
    headers(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI PROPOSAL",
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `PROPOSAL PENGABDIAN ${data.identitas?.thn_pertama_usulan}`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `ID Proposal: ${data.identitas?.id_usulan}`,
          style: ["fontSizeXs"],
        },
        {
          text: `Rencana Pelaksanaan Pengabdian : tahun ${data.identitas?.thn_pertama_usulan ?? ""
            } s.d. tahun ${getThnAkhirUsulan(
              data.identitas.lama_kegiatan,
              data.identitas.thn_pertama_usulan
            )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    ...contentUsulanPengabdian(data),
    {

      style: ["mt-10"],
      stack: contentAnggaranRevisi(data?.perbaikan_usulan, data?.support_data),
    }
  ];
};

export const contentUsulanPengabdian = (data,) => {
  const buildTblPengusul = (identitasPengusul = []) => {
    const layout = {
      layout: {
        hLineWidth: (_i, _node) => 0.2,
        vLineWidth: _i => 0.2,
        padding: () => 0,
      },
      table: {
        widths: [
          "17.68%",
          "16.68%",
          "16.68%",
          "16.68%",
          "10.68%",
          "10.66%",
          "10.68%",
        ],
        body: [
          [
            {
              text: `Nama, Peran`,
              style: ["center"],
            },
            {
              text: `Perguruan Tinggi/ Institusi`,
              style: ["center"],
            },
            {
              text: `Program Studi/Bagian `,
              style: ["center"],
            },
            {
              text: `Bidang Tugas`,
              style: ["center"],
            },
            {
              text: `ID Sinta`,
              style: ["center"],
            },
            {
              text: `H-Index`,
              style: ["center"],
            },
            {
              text: `Rumpun Ilmu`,
              style: ["center"],
            },
          ],
        ],
      },
    };
    identitasPengusul.forEach(item => {
      if (item.kode_peran === "A") {
        item.nama_rumpun_ilmu_2 = data.identitas.nama_rumpun_ilmu_2;
      }
    });
    identitasPengusul
      .sort((a, b) => {
        if (a.kode_peran === "A") return -1;
        if (b.kode_peran === "A") return 1;
        return 0;
      })
      .forEach(item => {
        layout.table.body.push([
          {
            text: `${item.nama} \n\n ${item.nama_peran}`,
            style: ["center"],
          },
          {
            text: `${item?.nama_institusi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.nama_program_studi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.uraian_tugas || "-"}`,
          },
          {
            text: `${item?.id_sinta || "-"}`,
            link: `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}`,
            style: ["center", { color: "blue", decoration: "underline" }],
          },
          {
            text: `${item?.hindex_scopus || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.nama_rumpun_ilmu_2 || "-"}`,
            style: ["center"],
          },
        ]);
      });

    return layout;
  };

  // Identitas Anggota MAHASISWA
  const buildTblMahasiswa = (identitasPengusulMhs = []) => {
    const layout = {
      layout: {
        hLineWidth: (_i, _node) => 0.2,
        vLineWidth: _i => 0.2,
        padding: () => 0,
      },
      table: {
        widths: ["18.68%", "18.68%", "19.68%", "19.68%", "20.68%"],
        body: [
          [
            {
              text: `Nama, Peran`,
              style: ["center"],
            },
            {
              text: `NIM `,
              style: ["center"],
            },
            {
              text: `Perguruan Tinggi/ Institusi`,
              style: ["center"],
            },
            {
              text: `Program Studi/Bagian `,
              style: ["center"],
            },
            {
              text: `Bidang Tugas `,
              style: ["center"],
            },
          ],
        ],
      },
    };

    identitasPengusulMhs.forEach(item => {
      layout.table.body.push([
        {
          text: `${item.nama} \n\n ${item.nama_peran}`,
          style: ["center"],
        },
        {
          text: `${item?.nim || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.nama_institusi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.nama_prodi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.uraian_tugas || "-"}`,
          style: ["center"],
        },
      ]);
    });

    return layout;
  };

  // Luaran
  const buildTblLuaran = (luaran = []) => {
    const layout = {
      style: ["center"],
      layout: {
        hLineWidth: (_i, _node) => 0.5,
        vLineWidth: _i => 0.5,
        padding: () => 1,
      },
      table: {
        widths: ["10%", "30%", "25%", "35%"],
        body: [
          [
            {
              text: "Tahun Luaran",
              style: ["center"],
            },
            {
              text: "Jenis Luaran",
              style: ["center"],
            },
            {
              text: "Status target capaian",
              style: ["center"],
            },
            {
              text: "Keterangan",
            },
          ],
        ],
      },
    };
    luaran.forEach(item => {
      layout.table.body.push([
        {
          text: item.urutan_thn_usulan_kegiatan || "-",
        },
        {
          text: item.nama_jenis_luaran || "-",
        },
        {
          text: item.nama_target_capaian_luaran || "-",
        },
        {
          text: item.keterangan || "-",
        },
      ]);
    });
    return layout;
  };

  const detailBidangFokus = data => {
    let detailBidangFokus;
    if (data.bidang_fokus_type === "tematik") {
      return (detailBidangFokus = data.bidang_fokus_tematik);
    } else if (data.bidang_fokus_type === "rirn") {
      return (detailBidangFokus = data.bidang_fokus_rirn);
    }
  };

  const buildTblIdentitas1 = (data = {}) => {
    const layout = {
      style: ["center", "mb-10"],
      layout: {
        hLineWidth: (_i, _node) => 0.5,
        vLineWidth: _i => 0.5,
        padding: () => 0,
      },
      table: {
        widths: ["22.5%", "22.5%", "20.00%", "15.00%", "20.00%"],
        body: [
          [
            {
              text: `Kelompok Skema`,
              style: ["center"],
            },
            {
              text: `Ruang Lingkup`,
              style: ["center"],
            },
            {
              text: `Bidang Fokus`,
              style: ["center"],
            },
            {
              text: `Lama Kegiatan`,
              style: ["center"],
            },
            {
              text: `Tahun Pertama Usulan`,
              style: ["center"],
            },
          ],
        ],
      },
    };

    layout.table.body.push([
      {
        text: data.nama_kategori_penelitian,
      },
      {
        text: `${data.nama_skema}`,
      },
      {
        text: `${data.bidang_fokus_type} - ${detailBidangFokus(data)}`,
      },
      {
        text: data.lama_kegiatan || "-",
        style: ["center"],
      },
      {
        text: data.thn_pertama_usulan || "-",
        style: ["center"],
      },
    ]);

    return layout;
  };

  const buildtableRAB = tahun => {
    const rabByTahun = data.rab.filter(
      item => item.urutan_thn_usulan === tahun
    );
    // const rabTahun2 = data.rab.filter(item => item.urutan_thn_usulan === 2);
    // const rabTahun3 = data.rab.filter(item => item.urutan_thn_usulan === 3);
    const sectionByTahun = () => {
      const layout = {
        style: ["center"],
        layout: {
          hLineWidth: (_i, _node) => 0.5,
          vLineWidth: _i => 0.5,
          padding: () => 1,
        },
        table: {
          widths: ["25%", "15%", "25%", "10%", "5%", "15%", "15%"],
          body: [
            [
              {
                text: "Jenis Pembelanjaan",
                style: ["center"],
              },
              {
                text: "Komponen",
                style: ["center"],
              },
              {
                text: "Item",
                style: ["center"],
              },
              {
                text: "Satuan",
                style: ["center"],
              },
              {
                text: "Vol.",
              },
              {
                text: "Biaya Satuan",
              },
              {
                text: "Total",
              },
            ],
          ],
        },
      };
      rabByTahun.forEach(item => {
        layout.table.body.push([
          {
            text: item.nama_rab_kelompok_biaya,
          },
          {
            text: item.nama_rab_komponen_belanja,
          },
          {
            text: item.nama_item,
          },
          {
            text: item.satuan,
          },
          {
            text: item.volume,
          },
          {
            text: utilities.maskMoney(item.harga_satuan),
          },
          {
            text: utilities.maskMoney(
              item.harga_satuan * item.volume,
            ),
          },
        ]);
      });
      return layout;
    };

    return {
      sectionByTahun,
    };
  };

  const sumRAB = (thn_urutan = 1) => {
    const totalRAB = data.rab
      .filter(item => item.urutan_thn_usulan === thn_urutan)
      .reduce((acc, current) => acc + current.harga_satuan * current.volume, 0);
    return utilities.toCurrency(totalRAB, ".", 2, "Rp. ");
  };


  const getRab = () => {
    let rab = [];
    for (let i = 0; i < data.identitas.lama_kegiatan; i++) {
      rab.push({
        text: `Total RAB Tahun ${i + 1} : ${sumRAB(i + 1)}`,
        style: ["mb-5"],
      });
    }
    return {
      stack: [rab],
    };
  };

  const getTableRab = () => {
    let rab = [];
    for (let i = 0; i < data.identitas.lama_kegiatan; i++) {
      rab.push(
        {
          text: `Tahun ${i + 1} Total : ${sumRAB(i + 1)}`,
          style: ["mb-5", "mt-10"],
        },
        buildtableRAB(i + 1).sectionByTahun()
      );
    }
    return {
      stack: [rab],
    };
  };

  return [
    {
      stack: [
        {
          text: "1. JUDUL PENGABDIAN",
          style: ["bold", "mb-5"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: _i => 0.5,
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: data.identitas.judul,
                },
              ],
            ],
          },
        },

        buildTblIdentitas1(data.identitas),
        // buildTblIdentitas2(data.identitas),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "2. IDENTITAS PENGUSUL",
          style: ["bold", "mb-5"],
        },
        buildTblPengusul(data.identitas.personil_dosen),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "3. IDENTITAS MAHASISWA",
          style: ["bold", "mb-5"],
        },
        buildTblMahasiswa(data.identitas.personil_non_dosen_mahasiswa),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "4. MITRA KERJASAMA",
          style: ["bold", "mb-5"],
        },
        {
          text: `Pelaksanaan pengabdian kepada masyarakat dapat melibatkan mitra, yaitu mitra sasaran, mitra pemerintah/pemda, mitra DUDI/CSR/LSM atau mitra perguruan tinggi`,
          style: ["fontSizeXs", "mb-5"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
          },
          table: {
            widths: ["30%", "35%", "35%"],
            body: [
              [
                {
                  text: `Jenis Mitra`,
                  style: ["center"],
                },
                {
                  text: `Nama Mitra`,
                  style: ["center"],
                },

                {
                  text: `Dana`,
                  style: ["center"],
                },
              ],
              ...data.mitra.map(row => {
                let danas = ``;

                if (
                  data.identitas.id_skema === 109 ||
                  data.identitas.id_skema === 111 ||
                  data.identitas.id_skema === 112
                ) {
                  // just for 109, 111, 112 (mono tahun)

                  let justOneDana = []; // []
                  let getFirstIndex = row.dana[0]; // [{},{},{}]

                  justOneDana.push(getFirstIndex); // [{}]
                  if (row.dana.length === 0) {
                    danas += `-`;
                  } else {
                    justOneDana.forEach(dana => {
                      if (dana === undefined) {
                        danas += `-`;
                      } else {
                        danas += `Tahun ${dana?.urutan_tahun_usulan
                          }: ${utilities.toCurrency(
                            dana?.dana,
                            ".",
                            2,
                            "Rp. "
                          )} \n`;
                      }
                    });
                  }
                } else {
                  if (row.dana.length === 0) {
                    danas += `-`;
                  } else {
                    row.dana.forEach(dana => {
                      if (dana === undefined) {
                        danas += `-`;
                      } else {
                        danas += `Tahun ${dana?.urutan_tahun_usulan
                          }: ${utilities.toCurrency(
                            dana?.dana,
                            ".",
                            2,
                            "Rp. "
                          )} \n`;
                      }
                    });
                  }
                }

                return [
                  {
                    text: `${row.jenis_mitra}`,
                  },
                  {
                    text: `${row.nama_mitra}`,
                  },
                  {
                    text: danas || "-",
                  },
                ];
              }),
            ],
          },
        },
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "5. LUARAN DIJANJIKAN",
          style: ["bold", "mb-5"],
        },
        buildTblLuaran(data.substansi?.luaran_dijanjikan),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "6. ANGGARAN",
          style: ["bold", "mb-5"],
        },
        {
          text: "Rencana Anggaran Biaya Pengabdian mengacu pada PMK dan buku Panduan Penelitian dan Pengabdian kepada Masyarakat yang berlaku.",
          style: ["justify", "mb-10"],
        },
      ],
    },
    // total anggaran rab per tahun
    {
      ...getRab(),
    },
    // table anggaran rab per tahun
    {
      ...getTableRab(),
    },
  ]
}

export const contentAnggaranRevisi = (data = {}, supportData = {}) => {

  if (JSON.stringify(data) === "{}" || data === null || !data || data === undefined) {
    return [];
  } else {

    const { rab_komponen_belanja_revisi } = data;

    const anggaranByYear = (rab_komponen_belanja_revisi || []).filter(item => item.urutan_thn_usulan === data?.urutan_thn_usulan_kegiatan);

    const getTotalRab = () => {
      const totalAnggaran = anggaranByYear.reduce((x, y) => x + (y.harga_satuan * y.volume), 0);
      return totalAnggaran;
    }

    return [
      {
        text: "7. ANGGARAN PERBAIKAN",
        style: ["bold"],

      },
      {
        text: "Rencana Anggaran Biaya penelitian mengacu pada PMK dan buku Panduan Penelitian dan Pengabdian kepada Masyarakat yang berlaku.",
        style: ["justify", "mb-10"],
      },
      {

        text: `Dana Tahun ke-${data?.urutan_thn_usulan_kegiatan} : Disetujui ${utilities.toCurrency(data?.dana_disetujui, ".", 2, 'Rp')} | Direncanakan : ${utilities.toCurrency(getTotalRab(), '.', 2, 'Rp')}`
      },
      {
        ...buildTblAnggaran(anggaranByYear.filter(row => row.harga_satuan !== 0).map(rab => ({
          ...rab,
          nama_rab_komponen_belanja: rab.komponen_belanja,
          nama_rab_kelompok_biaya: (supportData.kelompok_biaya || []).find(itm => rab.id_rab_kelompok_biaya === itm.id)?.kelompok_biaya,
        })), 2)
      }
    ];
  }

}

export const contentPersetujuan = (data = {}) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*", "*"],
      body: [
        [
          {
            text: `Tanggal Pengiriman`,
            style: ["center"],
          },
          {
            text: `Tanggal Persetujuan`,
            style: ["center"],
          },
          {
            text: `Nama Pimpinan Pemberi Persetujuan`,
            style: ["center"],
          },
          {
            text: `Sebutan Jabatan Unit`,
            style: ["center"],
          },
          {
            text: `Nama Unit Lembaga Pengusul`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  layout.table.body.push([
    {
      text: `${data?.tanggal_pengiriman
        ? utilities.convertDate(data?.tanggal_pengiriman, "DD/MM/YYYY")
        : "-"
        }`,
      style: ["center"],
    },
    {
      text: `${data?.tgl_created
        ? utilities.convertDate(data?.tgl_created, "DD/MM/YYYY")
        : "-"
        }`,
      style: ["center"],
    },
    {
      text: `${data?.approval_by || "-"}`,
      style: ["center"],
    },
    {
      text: `${data?.approval_peran || "-"}`,
      style: ["center"],
    },
    {
      text: `${data?.lembaga?.nama_lembaga || "-"}`,
      style: ["center"],
    },
  ]);

  return [
    {
      stack: [
        {
          text: "PERSETUJUAN PENGUSUL",
          style: ["bold"],
        },
        {
          ...layout,
        },
      ],
    },
    {
      style: ["mt-10"],
      stack: [
        {
          text: `Komentar : ${data?.kd_sts_approvel?.toString() === "0"
            ? "Tidak Disetujui"
            : data?.kd_sts_approvel?.toString() === "1"
              ? "Disetujui"
              : "-"
            } `,
          style: ["bold", "mb-1"],
        },
        {
          style: ["text"],
          layout: {
            hLineWidth: (_i, _node) => (data?.komentar ? 0.3 : 0),
            vLineWidth: _i => (data?.komentar ? 0.3 : 0),
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: data?.komentar,
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
