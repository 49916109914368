import Content, { contentSptbPenelitian } from "./content";
import { contentSptbPengabdian } from "./content";
import { contentPengabdian } from "./content_pengabdian";
// import Header from "./headers";
import stylePDF, { stylePDFSptbPenelitian } from "./styles";

export default function PdfCreating(data, kode_jenis_kegiatan, additionalData) {
  const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
  return {
    // header: [...Header()],
    content: [...Content(data, kode_jenis_kegiatan, additionalData)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
}

export function PdfCreatingLapKemajuan(data) {
  const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
  return {
    content: [...contentPengabdian(data)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
}

export const pdfCreateSptbPengabdian = item => {
  const { pageSize, pageMargins, styles, defaultStyle } = stylePDF;
  return {
    content: [...contentSptbPengabdian(item)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};
export const pdfCreateSptbPenelitian = item => {
  const { pageSize, pageMargins, styles, defaultStyle } =
    stylePDFSptbPenelitian;
  return {
    content: [...contentSptbPenelitian(item)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};
