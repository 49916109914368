export const OPERATOR_PT_DASHBOARD_TKT_REQUEST =
  "OPERATOR_PT_DASHBOARD_TKT_REQUEST";
export const OPERATOR_PT_DASHBOARD_TKT_SUCCESS =
  "OPERATOR_PT_DASHBOARD_TKT_SUCCESS";
export const OPERATOR_PT_DASHBOARD_TKT_FAILURE =
  "OPERATOR_PT_DASHBOARD_TKT_FAILURE";

export const CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST =
  "CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST";
export const CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS =
  "CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS";
export const CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE =
  "CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE";

export const CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST =
  "CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST";
export const CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS =
  "CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS";
export const CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE =
  "CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE";

export const GET_LIST_KLASTER_PT_REQUEST = "GET_LIST_KLASTER_PT_REQUEST";
export const GET_LIST_KLASTER_PT_SUCCESS = "GET_LIST_KLASTER_PT_SUCCESS";
export const GET_LIST_KLASTER_PT_FAILURE = "GET_LIST_KLASTER_PT_FAILURE";

export const PROCESS_PENDAFTARAN_AKUN_PT_REQUEST =
  "PROCESS_PENDAFTARAN_AKUN_PT_REQUEST";
export const PROCESS_PENDAFTARAN_AKUN_PT_SUCCESS =
  "PROCESS_PENDAFTARAN_AKUN_PT_SUCCESS";
export const PROCESS_PENDAFTARAN_AKUN_PT_FAILURE =
  "PROCESS_PENDAFTARAN_AKUN_PT_FAILURE";
