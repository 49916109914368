import { getSingleFileFromBucket } from "helpers/getFileFromBucket";
import PDFMerger from "pdf-merger-js/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toast } from "react-toastify";
import utilities from "helpers/utilities";
import engine from "./partials";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Proposal {
    constructor(data) {
        this.data = data;
    }

    async generate() {

        return await new Promise((resolve, _reject) => {
            try {
                const pdfDoc = pdfMake.createPdf(engine(this.data));
                // const originFilename = `LAPORAN_KEMAJUAN.pdf`;
                pdfDoc.getBlob(async blob => {
                    resolve(blob);
                })
            } catch (error) {
                _reject(error);
            }
        })
    }

    async merging(docs = []) {
        const merger = new PDFMerger();
        for (const file of docs) {
            await merger.add(file);
        }
        const mergerPdf = await merger.saveAsBlob();
        return mergerPdf;
    }

    forceDownload(file, fileName) {
        // * Force Download

        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(file);
       
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);

    }

    async getSingleFile(fileUrl = "") {
        
        const friendlyURL = utilities.getFriendlyURL(fileUrl);
        const response = await getSingleFileFromBucket(friendlyURL);
        if (response.error) {
            toast.error(response.message);
        } else {
            return response.data;
        }
    }

}

export default Proposal;
