import {
    CREATE_PENUGASAN_GOOGLE_FAILURE, CREATE_PENUGASAN_GOOGLE_REQUEST,
    CREATE_PENUGASAN_GOOGLE_SUCCESS, GET_PENUGASAN_GOOGLE_BYID_FAILURE,
    GET_PENUGASAN_GOOGLE_BYID_REQUEST, GET_PENUGASAN_GOOGLE_BYID_SUCCESS, GET_PENUGASAN_GOOGLE_FAILURE, GET_PENUGASAN_GOOGLE_REQUEST,
    GET_PENUGASAN_GOOGLE_SUCCESS, UPDATE_PENUGASAN_GOOGLE_FAILURE, UPDATE_PENUGASAN_GOOGLE_REQUEST,
    UPDATE_PENUGASAN_GOOGLE_SUCCESS
} from "../actionTypes";

export const createPenugasanGoogle = (request = {}, cb = () => { }) => {
    return {
        type: CREATE_PENUGASAN_GOOGLE_REQUEST,
        payload: request,
        cb
    };
}

export const createPenugasanGoogleSuccess = response => {
    return {
        type: CREATE_PENUGASAN_GOOGLE_SUCCESS,
        payload: response,
    };
}

export const createPenugasanGoogleFailure = (message, response) => {
    return {
        type: CREATE_PENUGASAN_GOOGLE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanGoogle = (request = {}) => {
    return {
        type: GET_PENUGASAN_GOOGLE_REQUEST,
        payload: request,
    };
}

export const getPenugasanGoogleSuccess = response => {
    return {
        type: GET_PENUGASAN_GOOGLE_SUCCESS,
        payload: response,
    };
}

export const getPenugasanGoogleFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_GOOGLE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanGoogleById = (id, cb = () => { }) => {

    return {
        type: GET_PENUGASAN_GOOGLE_BYID_REQUEST,
        payload: { id },
        cb
    };
}

export const getPenugasanGoogleByIdSuccess = response => {
    return {
        type: GET_PENUGASAN_GOOGLE_BYID_SUCCESS,
        payload: response,
    };
}

export const getPenugasanGoogleByIdFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_GOOGLE_BYID_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const updatePenugasanGoogle = (request = {}, id, cb = () => { }) => {
    return {
        type: UPDATE_PENUGASAN_GOOGLE_REQUEST,
        payload: request,
        cb,
        id
    };
}

export const updatePenugasanGoogleSuccess = response => {
    return {
        type: UPDATE_PENUGASAN_GOOGLE_SUCCESS,
        payload: response,
    };
}

export const updatePenugasanGoogleFailure = (message, response) => {
    return {
        type: UPDATE_PENUGASAN_GOOGLE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}