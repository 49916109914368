// import utilities from "helpers/utilities";
import stylePDF from "./styles";
import header from "./header";
import utilities from "helpers/utilities";


const buildTblLuaran = (luaran = []) => {
    const layout = {
        style: ['center'],
        layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: (_i) => 0.5,
            padding: () => 1
        },
        table: {
            widths: ["10%", "30%", "25%", "35%"],
            body: [
                [
                    {
                        text: "Tahun Luaran",
                        style: ['center'],
                    },
                    {
                        text: "Jenis Luaran",
                        style: ['center'],
                    },
                    {
                        text: "Status target capaian",
                        style: ['center'],
                    },
                    {
                        text: "Keterangan"
                    }
                ]
            ]
        },
    }
    luaran.forEach(item => {
        layout.table.body.push([
            {
                text: item.thn_luaran
            },
            {
                text: item.jenis_luaran
            },
            {
                text: item.status
            },
            {
                text: item.keterangan
            }
        ])
    });
    return layout;
}

const buildTblBidangFokus = (data = {}) => {
    const layout = {
        style: ["center"],
        layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: (_i) => 0.5,
            padding: () => 0
        },
        table: {
            widths: ["22%", "33%", "25%", "20%"],
            body: [
                [
                    {
                        text: `Bidang Fokus`,
                        style: ['center']
                    },
                    {
                        text: `Kategori (Kompetitif Nasional/Desentralisasi/Penugasan) `,
                        style: ['center']
                    },
                    {
                        text: `Skema`,
                        style: ['center']
                    },
                    {
                        text: `Lama Kegiatan Jumlah Keterlibatan`,
                        style: ['center']
                    }
                ],

            ]
        }

    }

    layout.table.body.push([
        {
            text: data.bidang_fokus
        },
        {
            text: data.kategori
        },
        {
            text: data.skema
        },
        {
            text: `${data.lama_kegiatan} tahun \n ${data.keterlibatan_mhs} mahasiswa`
        },
    ])

    return layout;
}

const buildTblPengusul = (identitasPengusul = []) => {
    const layout = {

        layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: (_i) => 0.5,
            padding: () => 0
        },
        table: {
            widths: ["17.68%", "19.68%", "18.68%", "20.68%", "11.68%", "10.66%"],
            body: [
                [
                    {
                        text: `Nama, Peran`,
                        style: ['center']
                    },
                    {
                        text: `Perguruan Tinggi/ Institusi`,
                        style: ['center']
                    },
                    {
                        text: `Program Studi/Bagian `,
                        style: ['center']
                    },
                    {
                        text: `Bidang Tugas`,
                        style: ['center']
                    },
                    {
                        text: `ID Sinta`,
                        style: ['center']
                    },
                    {
                        text: `H-Index`,
                        style: ['center']
                    },
                ],

            ]
        }
    }

    identitasPengusul.forEach(item => {
        layout.table.body.push([
            {
                text: `${item.nama} \n\n ${item.peran}`,
                style: ['center']
            },
            {
                text: `${item.perguruan_tinggi}`,
                style: ['center']
            },
            {
                text: `${item?.program_studi || "-"}`,
                style: ['center']
            },
            {
                text: `${item.bidang_tugas || "-"}`
            },
            {
                text: `${item.id_sinta}`,
                style: ['center']
            },
            {
                text: `${item.h_index}`,
                style: ['center']
            }
        ])
    })

    return layout;
}

const buildTblAnggaran = (data = []) => {
    const layout = {

        style: ['mb-10'],
        layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: (_i) => 0.5,
            padding: () => 0
        },
        table: {
            headerRows: 1,
            widths: ["25%", "30%", "10%", "5%", "15%", "15%"],
            body: [
                [
                    {
                        text: `Jenis Pembelanjaan`,
                        style: ['center', 'bgColorHeaderTable']
                    },
                    {
                        text: `Item`,
                        style: ['center', 'bgColorHeaderTable']
                    },
                    {
                        text: `Satuan`,
                        style: ['center', 'bgColorHeaderTable']
                    },
                    {
                        text: `Vol.`,
                        style: ['center', 'bgColorHeaderTable']
                    },
                    {
                        text: `Biaya Satuan`,
                        style: ['center', 'bgColorHeaderTable']
                    },
                    {
                        text: `Total`,
                        style: ['center', 'bgColorHeaderTable']
                    },
                ],

            ]
        }
    }

    data.forEach(item => {
        layout.table.body.push([
            {
                text: `${item.jenis_pembelanjaan}`,
            },
            {
                text: `${item.item_belanja}`,
                style: ['center']
            },
            {
                text: `${item?.satuan || "-"}`,
                style: ['center']
            },
            {
                text: `${item.volume || "-"}`,
                style: ['center']
            },
            {
                text: `${utilities.toCurrency(item?.biaya_satuan || 0, ".", 0)}`,
                style: ['right']
            },
            {
                text: `${utilities.toCurrency(item?.total || 0, ".", 0)}`,
                style: ['right']
            },

        ])
    })

    return layout;
}

export default function Content(data, _pageBreak = false) {
    const { hr } = stylePDF;

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    let anggaran = groupBy(data.anggaran.detail, 'thn_urutan') || [];

    return [
        header(),
        hr(5, 0),
        {
            stack: [
                {
                    text: "PROTEKSI ISI PROPOSAL",
                    style: ['bold']
                },
                {
                    text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
                    style: ['fontSizeXs']
                },
                {

                    text: `PROPOSAL ${data?.jenis_kegiatan.toUpperCase()} ${data.thn_mulai_kegiatan ?? ""}`,
                    color: "#FFFFFF",
                    background: "#000",
                    style: ['center', 'bold'],
                },
                {
                    text: `Rencana Pelaksanaan PPM: tahun ${data?.thn_mulai_kegiatan ?? ""} s.d. tahun ${data?.thn_akhir_kegiatan ?? ""}`,
                    style: ['fontSizeXs']
                }
            ],
            style: ['center', 'mb-5'],
        },
        {
            stack: [
                {
                    text: "1. JUDUL PPM",
                    style: ['bold']
                },
                {
                    style: ["text", 'mb-10'],
                    layout: {
                        hLineWidth: (_i, _node) => 0.5,
                        vLineWidth: (_i) => 0.5,
                        padding: () => 1
                    },
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: data.title
                                }
                            ]
                        ]
                    }
                },
                buildTblBidangFokus({
                    ...data.bidang,
                    kategori: data.kategori.kategori,
                    skema: data.kategori.skema,
                    lama_kegiatan: data.lama_kegiatan,
                    keterlibatan_mhs: data.keterlibatan_mahasiswa
                })
            ],
            style: ['mb-10']
        },
        {
            stack: [
                {
                    text: "2. IDENTITAS PENGUSUL",
                    style: ['bold']
                },
                buildTblPengusul(data.identitas_pengusul)
            ],
            style: ['mb-10']
        },
        {
            stack: [
                {
                    text: "3. MITRA PPM",
                    style: ['bold']
                },
                {
                    style: ["text", 'mb-10'],
                    layout: {
                        hLineWidth: (_i, _node) => 0.5,
                        vLineWidth: (_i) => 0.5,
                        padding: () => 0
                    },
                    table: {
                        widths: ["30%", "35%", "35%"],
                        body: [
                            [
                                {
                                    text: `Kategori Mitra, Tipe Mitra`,
                                    style: ['center']
                                },
                                {
                                    text: `Mitra`,
                                    style: ['center']
                                },

                                {
                                    text: `Dana`,
                                    style: ['center']
                                },

                            ],
                            ...data.mitra.map(mitra => ([
                                {
                                    text: `${mitra.mitra} \n ${mitra.jenis_mitra}`
                                },
                                {
                                    text: `${mitra.nama_mitra}\n${mitra.nama_institusi_mitra}`
                                },
                                {
                                    text: mitra.dana.sort((a, b) => parseInt(b.urutan_thn_usulan) - parseInt(a.urutan_thn_usulan)).map(dana => `Tahun ${dana.urutan_thn_usulan}: ${utilities.toCurrency(dana.dana, ".", 2, "Rp ")}\n`)
                                }
                            ]))

                        ]
                    }
                }
            ],
            style: ['mb-10']
        },
        {
            stack: [
                {
                    text: "4. LUARAN DAN TARGET CAPAIAN",
                    style: ['bold']
                },
                {
                    text: "Pada bagian ini, pengusul wajib mengisi luaran wajib dan tambahan, tahun capaian, dan status pencapaiannya. Luaran PPM berupa artikel diwajibkan menyebutkan nama jurnal yang dituju dan untuk luaran berupa buku harus mencantumkan nama penerbit yang dituju.",
                    style: ['justify']
                },
                {
                    text: "Luaran Wajib",
                    style: ['bold', 'mt-10']
                },
                buildTblLuaran(data.luaran_wajib),
                {
                    text: "Luaran Tambahan",
                    style: ['bold', 'mt-10']
                },
                buildTblLuaran(data.luaran_tambahan),
            ],
            style: ['mb-10']
        },
        {
            stack: [
                {
                    text: "5. ANGGARAN",
                    style: ['bold']
                },
                {
                    text: "Rencana anggaran biaya penelitian mengacu pada PMK yang berlaku dengan besaran minimum dan maksimum sebagaimana diatur pada buku Panduan Penelitian dan Pengabdian kepada Masyarakat Edisi 12.",
                    style: ['justify', 'mb-10']
                },
                {
                    text: `Total RAB ${Object.keys(anggaran)?.length || 0} Tahun ${utilities.toCurrency(data?.anggaran?.total_rab || 0, ".", 2, "Rp ")}`,
                    style: ['bold', 'mb-10']
                },


            ],

        },
        {
            stack: [
                ...(Object.entries(anggaran) || []).map(([key, anggaranItem]) => {

                    const totalAnggaranPertahun = anggaranItem.reduce((a, { total }) => parseInt(a) + parseInt(total), 0) || 0;
                    return {
                        style: ["mb-10"],
                        stack: [
                            {
                                text: `Tahun ${key} Total ${utilities.toCurrency(totalAnggaranPertahun, ".", 2, "Rp ")}`,
                                style: ['bold']
                            },
                            buildTblAnggaran(anggaranItem)
                        ]
                    }
                })
            ]
        }
    ];
}