import {
  LIST_LAPORAN_KEMAJUAN_FAILURE,
  LIST_LAPORAN_KEMAJUAN_REQUEST,
  LIST_LAPORAN_KEMAJUAN_SUCCESS,
  LAPORAN_KEMAJUAN_DETAIL_FAILURE,
  LAPORAN_KEMAJUAN_DETAIL_REQUEST,
  LAPORAN_KEMAJUAN_DETAIL_SUCCESS,
  POST_LAPORAN_KEMAJUAN_REQUEST,
  POST_LAPORAN_KEMAJUAN_FAILURE,
  POST_LAPORAN_KEMAJUAN_SUCCESS,
  UPDATE_LAPORAN_KEMAJUAN_FAILURE,
  UPDATE_LAPORAN_KEMAJUAN_REQUEST,
  UPDATE_LAPORAN_KEMAJUAN_SUCCESS,
  UPLOAD_DOKUMEN_FAILURE,
  UPLOAD_DOKUMEN_REQUEST,
  UPLOAD_DOKUMEN_SUCCESS,
  UPLOAD_DOKUMEN_SPTB_FAILURE,
  UPLOAD_DOKUMEN_SPTB_REQUEST,
  UPLOAD_DOKUMEN_SPTB_SUCCESS,
  CREATE_LAPORAN_KEMAJUAN_FAILURE,
  CREATE_LAPORAN_KEMAJUAN_REQUEST,
  CREATE_LAPORAN_KEMAJUAN_SUCCESS,
  GET_CRITERIA_FAILURE,
  GET_CRITERIA_REQUEST,
  GET_CRITERIA_SUCCESS,
  GET_TARGET_JENIS_LUARAN_FAILURE,
  GET_TARGET_JENIS_LUARAN_REQUEST,
  GET_TARGET_JENIS_LUARAN_SUCCESS,
  GET_DOKUMEN_LUARAN_FAILURE,
  GET_DOKUMEN_LUARAN_REQUEST,
  GET_DOKUMEN_LUARAN_SUCCESS,
  RESET_LAPORAN_KEMAJUAN_DETAIL,
  UPLOAD_DOKUMEN_MITRA_REQUEST,
  UPLOAD_DOKUMEN_MITRA_SUCCESS,
  UPLOAD_DOKUMEN_MITRA_FAILURE,
  GET_DOKUMEN_MITRA_REQUEST,
  GET_DOKUMEN_MITRA_SUCCESS,
  GET_DOKUMEN_MITRA_FAILURE,
  GET_BERKAS_LAPORAN_KEMAJUAN_REQUEST,
  GET_BERKAS_LAPORAN_KEMAJUAN_SUCCESS,
  GET_BERKAS_LAPORAN_KEMAJUAN_FAILURE,
  GET_LIST_LAPORAN_KEMAJUAN_V2_REQUEST,
  GET_LIST_LAPORAN_KEMAJUAN_V2_SUCCESS,
  GET_LIST_LAPORAN_KEMAJUAN_V2_FAILURE,
  GET_DETAIL_LAPORAN_KEMAJUAN_V2_REQUEST,
  GET_DETAIL_LAPORAN_KEMAJUAN_V2_SUCCESS,
  GET_DETAIL_LAPORAN_KEMAJUAN_V2_FAILURE,
  UPDATE_LAPORAN_KEMAJUAN_V2_REQUEST,
  UPDATE_LAPORAN_KEMAJUAN_V2_SUCCESS,
  UPDATE_LAPORAN_KEMAJUAN_V2_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  errors: null,
  listLaporanKemajuan: [], // * menampilkan list di table
  laporanKemajuanDetail: [], // * detail laporan kemajuan
  createLaporanKemajuan: {
    // * create laporan kemajuan, seperti action simpan

    isFetching: false,
    item: [],
  },
  criteria: {
    isFetching: false,
    item: [],
  },
  uploaded_dokumen: {
    isFetching: false,
    item: {},
  },
  dokumen_mitra: {
    isFetching: false,
    items: [],
    item: {},
  },
  uploaded_dokumen_sptb: {
    isFetching: false,
    item: {},
  },
  target_jenis_luaran: {
    isFetching: false,
    item: [],
  },
  dokumen_luaran: {
    isFetching: false,
    item: [],
  },
  berkas_laporan_kemajuan: {
    isFetching: false,
    item: {},
  },
  // * Yg bawah skip dulu
  postLaporanKemajuan: {},
  updateLaporanKemajuan: {},

  // Laporan kemajuan v2
  list_laporan_kemajuan_v2: {
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  detail_laporan_kemajuan_v2: {
    item: {},
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  update_laporan_kemajuan_v2: {
    item: {},
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
};

const LaporanKemajuan = (state = initialState, action) => {
  switch (action.type) {
    // * Upload File Laporan Kemajuan
    case UPLOAD_DOKUMEN_REQUEST:
      return (state = {
        ...state,
        uploaded_dokumen: {
          ...state.uploaded_dokumen,
          isFetching: true,
        },
      });
    case UPLOAD_DOKUMEN_SUCCESS:
      return (state = {
        ...state,
        uploaded_dokumen: {
          ...state.uploaded_dokumen,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case UPLOAD_DOKUMEN_FAILURE:
      return (state = {
        ...state,
        uploaded_dokumen: {
          ...state.uploaded_dokumen,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // * Upload File SPTB
    case UPLOAD_DOKUMEN_SPTB_REQUEST:
      return (state = {
        ...state,
        uploaded_dokumen_sptb: {
          ...state.uploaded_dokumen_sptb,
          isFetching: true,
        },
      });
    case UPLOAD_DOKUMEN_SPTB_SUCCESS:
      return (state = {
        ...state,
        uploaded_dokumen_sptb: {
          ...state.uploaded_dokumen_sptb,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case UPLOAD_DOKUMEN_SPTB_FAILURE:
      return (state = {
        ...state,
        uploaded_dokumen_sptb: {
          ...state.uploaded_dokumen_sptb,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case LIST_LAPORAN_KEMAJUAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LIST_LAPORAN_KEMAJUAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listLaporanKemajuan: action.payload,
      };
    case LIST_LAPORAN_KEMAJUAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    //* Create Laporan Kemajuan
    case CREATE_LAPORAN_KEMAJUAN_REQUEST:
      return (state = {
        ...state,
        createLaporanKemajuan: {
          ...state.createLaporanKemajuan,
          isFetching: true,
        },
      });
    case CREATE_LAPORAN_KEMAJUAN_SUCCESS:
      return (state = {
        ...state,
        createLaporanKemajuan: {
          ...state.createLaporanKemajuan,
          isFetching: false,
          item: action.payload,
        },
      });
    case CREATE_LAPORAN_KEMAJUAN_FAILURE:
      return (state = {
        ...state,
        createLaporanKemajuan: {
          ...state.createLaporanKemajuan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // * reset list laporan kemajuan, setiap fetching
    case RESET_LAPORAN_KEMAJUAN_DETAIL:
      return {
        ...state,
        laporanKemajuanDetail: [],
      };
    // * Laporan Kemajuan Detail
    case LAPORAN_KEMAJUAN_DETAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        laporanKemajuanDetail: [],
      };
    case LAPORAN_KEMAJUAN_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        laporanKemajuanDetail: action.payload,
      };
    case LAPORAN_KEMAJUAN_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // * Criteria
    case GET_CRITERIA_REQUEST:
      return (state = {
        ...state,
        criteria: {
          ...state.criteria,
          isFetching: true,
        },
      });
    case GET_CRITERIA_SUCCESS:
      return (state = {
        ...state,
        criteria: {
          ...state.criteria,
          isFetching: false,
          item: action.payload,
        },
      });
    case GET_CRITERIA_FAILURE:
      return (state = {
        ...state,
        criteria: {
          ...state.criteria,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // * Target jenis luaran
    case GET_TARGET_JENIS_LUARAN_REQUEST:
      return (state = {
        ...state,
        target_jenis_luaran: {
          ...state.target_jenis_luaran,
          isFetching: true,
          item: [],
        },
      });
    case GET_TARGET_JENIS_LUARAN_SUCCESS:
      return (state = {
        ...state,
        target_jenis_luaran: {
          ...state.target_jenis_luaran,
          isFetching: false,
          item: action.payload,
        },
      });
    case GET_TARGET_JENIS_LUARAN_FAILURE:
      return (state = {
        ...state,
        target_jenis_luaran: {
          ...state.target_jenis_luaran,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // * Dokumen luaran
    case GET_DOKUMEN_LUARAN_REQUEST:
      return (state = {
        ...state,
        dokumen_luaran: {
          ...state.dokumen_luaran,
          isFetching: true,
          item: [],
        },
      });
    case GET_DOKUMEN_LUARAN_SUCCESS:
      return (state = {
        ...state,
        dokumen_luaran: {
          ...state.dokumen_luaran,
          isFetching: false,
          item: action.payload,
        },
      });
    case GET_DOKUMEN_LUARAN_FAILURE:
      return (state = {
        ...state,
        dokumen_luaran: {
          ...state.dokumen_luaran,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // * YG Bawah skip dulu
    case POST_LAPORAN_KEMAJUAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case POST_LAPORAN_KEMAJUAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        postLaporanKemajuan: action.payload,
      };
    case POST_LAPORAN_KEMAJUAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case UPDATE_LAPORAN_KEMAJUAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_LAPORAN_KEMAJUAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        updateLaporanKemajuan: action.payload,
      };
    case UPDATE_LAPORAN_KEMAJUAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    //Dokumen Mitra
    case UPLOAD_DOKUMEN_MITRA_REQUEST:
      return (state = {
        ...state,
        dokumen_mitra: {
          ...state.dokumen_mitra,
          isFetching: true,
        },
      });
    case UPLOAD_DOKUMEN_MITRA_SUCCESS:
      return (state = {
        ...state,
        dokumen_mitra: {
          ...state.dokumen_mitra,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case UPLOAD_DOKUMEN_MITRA_FAILURE:
      return (state = {
        ...state,
        dokumen_mitra: {
          ...state.dokumen_mitra,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_DOKUMEN_MITRA_REQUEST:
      return (state = {
        ...state,
        dokumen_mitra: {
          ...state.dokumen_mitra,
          isFetching: true,
        },
      });
    case GET_DOKUMEN_MITRA_SUCCESS:
      return (state = {
        ...state,
        dokumen_mitra: {
          ...state.dokumen_mitra,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_DOKUMEN_MITRA_FAILURE:
      return (state = {
        ...state,
        dokumen_mitra: {
          ...state.dokumen_mitra,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_BERKAS_LAPORAN_KEMAJUAN_REQUEST:
      return (state = {
        ...state,
        berkas_laporan_kemajuan: {
          ...state.berkas_laporan_kemajuan,
          isFetching: true,
          item: {}, // Reset
        },
      });
    case GET_BERKAS_LAPORAN_KEMAJUAN_SUCCESS:
      return (state = {
        ...state,
        berkas_laporan_kemajuan: {
          ...state.berkas_laporan_kemajuan,
          isFetching: false,
          item: action.payload,
        },
      });
    case GET_BERKAS_LAPORAN_KEMAJUAN_FAILURE:
      return (state = {
        ...state,
        berkas_laporan_kemajuan: {
          ...state.berkas_laporan_kemajuan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // laporan kemajuan v2
    case GET_LIST_LAPORAN_KEMAJUAN_V2_REQUEST:
      return (state = {
        ...state,
        list_laporan_kemajuan_v2: {
          ...state.list_laporan_kemajuan_v2,
          isFetching: true,
          items: [],
        },
      });
    case GET_LIST_LAPORAN_KEMAJUAN_V2_SUCCESS:
      return (state = {
        ...state,
        list_laporan_kemajuan_v2: {
          ...state.list_laporan_kemajuan_v2,
          isFetching: false,
          items: action.payload.data || [],
        },
      });
    case GET_LIST_LAPORAN_KEMAJUAN_V2_FAILURE:
      return (state = {
        ...state,
        list_laporan_kemajuan_v2: {
          ...state.list_laporan_kemajuan_v2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case GET_DETAIL_LAPORAN_KEMAJUAN_V2_REQUEST:
      return (state = {
        ...state,
        detail_laporan_kemajuan_v2: {
          ...state.detail_laporan_kemajuan_v2,
          isFetching: true,
          item: {},
        },
      });
    case GET_DETAIL_LAPORAN_KEMAJUAN_V2_SUCCESS:
      return (state = {
        ...state,
        detail_laporan_kemajuan_v2: {
          ...state.detail_laporan_kemajuan_v2,
          isFetching: false,
          item: action.payload.data || {},
        },
      });
    case GET_DETAIL_LAPORAN_KEMAJUAN_V2_FAILURE:
      return (state = {
        ...state,
        detail_laporan_kemajuan_v2: {
          ...state.detail_laporan_kemajuan_v2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case UPDATE_LAPORAN_KEMAJUAN_V2_REQUEST:
      return (state = {
        ...state,
        update_laporan_kemajuan_v2: {
          ...state.update_laporan_kemajuan_v2,
          isFetching: true,
          item: {},
        },
      });
    case UPDATE_LAPORAN_KEMAJUAN_V2_SUCCESS:
      return (state = {
        ...state,
        update_laporan_kemajuan_v2: {
          ...state.update_laporan_kemajuan_v2,
          isFetching: false,
          item: action.payload.data || {},
        },
      });
    case UPDATE_LAPORAN_KEMAJUAN_V2_FAILURE:
      return (state = {
        ...state,
        update_laporan_kemajuan_v2: {
          ...state.update_laporan_kemajuan_v2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    default:
      return state;
  }
};

export default LaporanKemajuan;
