import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, apiError } from "./actions";

import encStorage from "helpers/encStorage";
import * as backend from "helpers/backend";
import utilities from "helpers/utilities";
import { decode } from "helpers/jwt";
import { redirectFirstPage } from "routes";

/* 
  All Function 
 */

function* loginUser({ payload: { user } }) {
  try {
    if (user.rememberMe) {
      const remember = { ...user, password: undefined };
      localStorage.setItem("remember", JSON.stringify(remember));
    } else {
      localStorage.removeItem("remember");
    }

    const response = yield call(backend.login, {
      username: user.username,
      password: user.password,
    });

    if (response.data.code === 200) {
      const data = decode(response.data.data);
      const authUser = {
        data,
        isAuthenticated: true,
        username: user.username,
        token: response.data.data,
        authenticatedDate: utilities.today(),
      };
	const isSuspendVokasi = process.env.REACT_APP_SUSPEND_VOKASI==="1";
	
	if (data?.kd_program_pendidikan === "2" && isSuspendVokasi) {
		throw new Error("Akses BIMA vokasi ditangguhkan untuk sementara waktu. Keterangan lebih lanjut hubungi administrator");
	}

      encStorage.setItem("authUser", JSON.stringify(authUser));
      yield put(loginSuccess(user));

      const roles = authUser.data.roles;
      if (roles) {
        redirectFirstPage(roles);
      }
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    // const response = yield call(backend.logout);

    // if (response.status === 200) {
    encStorage.removeItem("authUser");
    localStorage.removeItem("persist:usulan_perbaikan");
    localStorage.removeItem("persist:laporan_kemajuan");
    history.push("/login");
    // }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
