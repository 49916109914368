import { instances } from "../api_helper";
import * as url from "../url_helper";

const putDataPersonalDosen = payload => {
  return instances.request(
    `${url.ENDPOINT_PERSONAL_DATA_DOSEN}/${payload.id}`,
    {
      method: "PUT",
      data: payload,
    }
  );
};

const syncDataDosen = nidn => {
  return instances.request(`${url.ENDPOINT_SYNC_PT_DOSEN}/${nidn}`, {
    method: "PUT",
  });
};

const daftarProgramStudi = ({
  id_institusi,
  operatorLldikti,
  id_institusi_dropdown,
  operatorPusat,
}) => {
  if (operatorPusat || operatorLldikti) {
    return instances.request(
      `${url.ENDPOINT_DAFTAR_PRODI}/${id_institusi_dropdown}`,
      {
        method: "GET",
      }
    );
  } else {
    return instances.request(`${url.ENDPOINT_DAFTAR_PRODI}/${id_institusi}`, {
      method: "GET",
    });
  }
};

const syncDataProdi = payload => {
  return instances.request(
    `${url.ENDPOINT_SINKRONISASI_PRODI}/${payload.id_institusi}/${payload.kd_program_studi}`,
    {
      method: `${payload.method}`,
    }
  );
};

const searchProdiByKode = payload => {
  return instances.request(
    `${url.ENDPOINT_SEARCH_PRODI_TO_PDDIKTI}/${payload.id_institusi}/${payload.kd_program_studi}`,
    {
      method: "GET",
    }
  );
};

const getPtDosen = ({
  limit,
  count,
  query,
  choose,
  id_institusi,
  operatorPusat,
  operatorLldikti,
}) => {
  if (operatorPusat || operatorLldikti) {
    return instances.request(
      `${url.ENDPOINT_PT_DOSEN}/${id_institusi}?limit=${limit}&page=${count}&param=${query}&choose=${choose}`,
      {
        method: "GET",
      }
    );
  } else {
    return instances.request(
      `${url.ENDPOINT_PT_DOSEN}?limit=${limit}&page=${count}&param=${query}&choose=${choose}`,
      {
        method: "GET",
      }
    );
  }
};

const getPtById = nidn => {
  return instances.request(`${url.ENDPOINT_PT_DOSEN_BY_NIDN}?nidn=${nidn}`, {
    method: "GET",
  });
};

const searchDosenByNidn = payload => {
  return instances.request(
    `${url.ENDPOINT_SEARCH_DOSEN_TO_BIMA}/${payload.query.replaceAll(
      /%20|\s/g,
      ""
    )}?source=BIMA&mode=sync`,
    {
      method: "GET",
    }
  );
};

const getPersonalSearch = params => {
  let URL = url.ENDPOINT_PERSONAL_SEARCH;
  let queryParams = '';
  if (JSON.stringify(params) !== "{}") {
    queryParams += "?";
    Object.entries(params).forEach(([key, value], i) => {
      if (value) {
        queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
      }
    });
  }

  URL += queryParams;
  return instances.request(URL,
    {
      method: "GET",
    }
  );
};

const getDosenToPddikti = payload => {
  return instances.request(
    `${url.ENDPOINT_SEARCH_DOSEN_TO_BIMA}/${payload.query.replaceAll(
      /%20|\s/g,
      ""
    )}?source=PDDIKTI`,
    {
      method: "GET",
    }
  );
};

const tambahDataDosen = payload => {
  return instances.request(
    `${url.ENDPOINT_SEARCH_DOSEN_TO_BIMA}/${payload.nidn.replaceAll(
      /%20|\s/g,
      ""
    )}?source=BIMA&mode=create`,
    {
      method: "GET",
    }
  );
};

const getPtNidn = ({
  limit,
  count,
  query,
  choose,
  operatorLldikti,
  operatorPusat,
  idInstitusi,
}) => {
  if (operatorLldikti || operatorPusat) {
    return instances.request(
      `${url.ENDPOINT_PT_DOSEN}/${idInstitusi}?limit=${limit}&page=${count}&param=${query}&choose=${choose}`,
      {
        method: "GET",
      }
    );
  } else {
    return instances.request(
      `${url.ENDPOINT_PT_DOSEN}?limit=${limit}&page=${count}&param=${query}&choose=${choose}`,
      {
        method: "GET",
      }
    );
  }
};

const getInstitusi = payload => {
  return instances.request(
    `${url.ENDPOINT_LEMBAGA}/${payload.id}/${payload.kd_jenis_kegiatan}`,
    {
      method: "GET",
    }
  );
};

const putInstitusi = payload => {
  return instances.request(
    `${url.ENDPOINT_LEMBAGA}/${payload.id}/${payload.kd_jenis_kegiatan}`,
    {
      method: "PUT",
      data: payload,
    }
  );
};
const putInstitusiEditNamaJabatan = payload => {
  return instances.request(`${url.ENDPOINT_LEMBAGA}/profile-pimpinan`, {
    method: "PUT",
    data: payload,
  });
};

const resetPwd = nidn => {
  return instances.request(`${url.ENDPOINT_RESET_PWD}/${nidn}`, {
    method: "PUT",
  });
};

const ketuaLppm = payload => {
  return instances.request(`${url.ENDPOINT_KETUA_LPPM}`, {
    method: "POST",
    data: payload,
  });
};

const getAllStatusDosen = () => {
  return instances.request(`${url.ENDPOINT_STATUS_DOSEN}`, {
    method: "GET",
  });
};

const putStatusDosen = secondPayload => {
  return instances.request(
    `${url.ENDPOINT_PUT_STATUS_DOSEN}/${secondPayload.nidn}/${secondPayload.status}`,
    { method: "PUT" }
  );
};

export {
  resetPwd,
  getPtNidn,
  ketuaLppm,
  getPtById,
  getPtDosen,
  getInstitusi,
  putInstitusi,
  syncDataDosen,
  syncDataProdi,
  putStatusDosen,
  tambahDataDosen,
  getPersonalSearch,
  searchDosenByNidn,
  searchProdiByKode,
  getDosenToPddikti,
  getAllStatusDosen,
  daftarProgramStudi,
  putDataPersonalDosen,
  putInstitusiEditNamaJabatan,
};
