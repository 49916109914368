import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import {
   actionBatchKIFailure,
   actionBatchKISuccess,
   createBatchKIFailure,
   createBatchKISuccess,
   deleteBatchKIFailure,
   deleteBatchKISuccess,
   getDetailsBatchKIFailure,
   getDetailsBatchKISuccess,
   getListBatchKIFailure,
   getListBatchKISuccess,
   getListWhitelistKIFailure,
   getListWhitelistKISuccess,
   updateBatchKIFailure,
   updateBatchKISuccess
} from "./action";
import {
   ACTION_BATCH_KI_REQUEST,
   CREATE_BATCH_KI_REQUEST,
   DELETE_BATCH_KI_REQUEST,
   GET_DETAILS_BATCH_KI_REQUEST,
   GET_LIST_BATCH_KI_REQUEST,
   GET_LIST_WHITELIST_KI_REQUEST,
   UPDATE_BATCH_KI_REQUEST
} from "./actionTypes";


function* getListWhitelistKI({ payload = {} }) {
   try {
      const response = yield call(backend.getWhitlistUsulanKI, payload);
      if (response.data.code == 200) {
         yield put(getListWhitelistKISuccess(response.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
    
      toast.error(message);
      yield put(getListWhitelistKIFailure(message, { ...error }));
   }
}


function* getListBatchKI({ payload = {} }) {
   try {
      const response = yield call(backend.getBatchKI, payload);
      if (response.data.code == 200) {
         yield put(getListBatchKISuccess(response.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      console.log(message);
      toast.error(message);
      yield put(getListBatchKIFailure(message, { ...error }));
   }
}

function* getDetailsBatchKI({ payload = {}, cb = () => { } }) {
   try {
      const response = yield call(backend.getBatchKI, payload);
      if (response.data.code == 200) {
         yield put(getDetailsBatchKISuccess(response.data.data));
         cb(response.data.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      cb(null);
      toast.error(message);
      yield put(getDetailsBatchKIFailure(message, { ...error }));
   }
}

function* createBatchKI({ payload, cb = () => { } }) {
   try {
      const response = yield call(backend.createBatchKI, payload);
      if (response.data.code == 200) {
         yield put(createBatchKISuccess({ ...response.data.data }));
         cb(response.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;

      cb(null);
      toast.error(message);
      yield put(createBatchKIFailure(message, { ...error }));
   }
}

function* upadetBatchKI({ payload, cb = () => { } }) {
   try {
      const response = yield call(backend.updateBatchKI, payload);
      if (response.data.code == 200) {
         yield put(updateBatchKISuccess({ ...response.data.data }));
         cb(response.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;

      cb(null);
      toast.error(message);
      yield put(updateBatchKIFailure(message, { ...error }));
   }
}

function* deleteBatchKI({ payload, cb = () => { } }) {
   try {
      const response = yield call(backend.deleteBatchKI, payload);
      if (response.data.code == 200) {
         yield put(deleteBatchKISuccess({
            id: payload.id
         }));
         cb(response.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      console.log(message);
      cb(null);
      toast.error(message);
      yield put(deleteBatchKIFailure(message, { ...error }));
   }
}

function* actionBatchKI({ payload, cb = () => { } }) {
   try {
      const response = yield call(backend.actionBatchKI, payload);
      if (response.data.code == 200) {
         yield put(actionBatchKISuccess({
            id: payload.id
         }));
         cb(response.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      console.log(message);
      cb(null);
      toast.error(message);
      yield put(actionBatchKIFailure(message, { ...error }));
   }
}

function* konfigurasi() {
   yield takeEvery(CREATE_BATCH_KI_REQUEST, createBatchKI);
   yield takeEvery(UPDATE_BATCH_KI_REQUEST, upadetBatchKI);
   yield takeEvery(DELETE_BATCH_KI_REQUEST, deleteBatchKI);
   yield takeEvery(ACTION_BATCH_KI_REQUEST, actionBatchKI);
   yield takeEvery(GET_LIST_BATCH_KI_REQUEST, getListBatchKI);
   yield takeEvery(GET_DETAILS_BATCH_KI_REQUEST, getDetailsBatchKI);
   yield takeEvery(GET_LIST_WHITELIST_KI_REQUEST, getListWhitelistKI);
}

export default konfigurasi;
