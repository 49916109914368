import { getSingleFileFromBucket } from "helpers/getFileFromBucket";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import utilities from "helpers/utilities";
import { PDFDocument } from "pdf-lib";
import { pdfCreate as engine } from "./partials";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class KosabangsaPendampingPdf {
    constructor(data) {
        this.data = data;
    }

    async generate() {

        return await new Promise((resolve, _reject) => {
            try {
                const pdfDoc = pdfMake.createPdf(engine(this.data));

                pdfDoc.getBlob(async blob => {
                    resolve(blob);
                })
            } catch (error) {
                _reject(error);
            }
        })
    }

    async merging(docs = []) {

        const pdf = await PDFDocument.create();

        let i = 0;
        for (const file of docs) {

            const bufferFile = await utilities.blobToArrayBuffer(file);
            const doc = await PDFDocument.load(bufferFile, { ignoreEncryption: true })
            console.log("i ", i);
            const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
            for (const page of contentPage) {
                pdf.addPage(page);
            }
            i++;
        }
        const pdfMerged = await pdf.saveAsBase64()
        const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, 'application/pdf');

        return pdfMergedAsBlob;
    }

    forceDownload(file, fileName) {
        // * Force Download

        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(file);

        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);

    }

    async getMultiplefile() {
        const data = this.data;
        let files = [];
        let dataBlob = [];
        if (data?.raw?.teknologi?.url_file_substansi) {
            files.push(
                {
                    URLDokumen: data?.raw?.teknologi?.url_file_substansi,
                },
            );
        }
        if (data?.raw?.pendampingan?.url_file_substansi) {
            files.push(
                {
                    URLDokumen: data?.raw?.pendampingan?.url_file_substansi,
                },
            );
        }
      
        if (data?.raw?.is_mou && data?.raw?.mou?.url_file_dokumen) {
            files.push(
                {
                    URLDokumen: data?.raw?.mou?.url_file_dokumen,
                },
            );
        }

        if (data?.raw?.dokumen_pendukung) {
            const { dokumen_pendukung } = data.raw;
            if (dokumen_pendukung?.url_file_keputusan_lppm) {
                files.push(
                    {
                        URLDokumen: dokumen_pendukung?.url_file_keputusan_lppm
                    },
                );
            }
            if (dokumen_pendukung?.url_file_ketersediaan) {
                files.push(
                    {
                        URLDokumen: dokumen_pendukung?.url_file_ketersediaan
                    },
                );
            }
            if (dokumen_pendukung?.url_file_pernyataan_kepemilikan_teknologi) {
                files.push(
                    {
                        URLDokumen: dokumen_pendukung?.url_file_pernyataan_kepemilikan_teknologi
                    },
                );
            }
        }



        for (const file of files) {
            const res = await getSingleFileFromBucket(
                utilities.getFriendlyURL(file.URLDokumen)
            );
            if (!res.error) {
                dataBlob.push(res.data);
            }
        }
        return dataBlob;
    }

}

export default KosabangsaPendampingPdf;
