export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_REQUEST =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_REQUEST";
export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_SUCCESS =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_SUCCESS";
export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_FAILURE =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_FAILURE";

export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_REQUEST =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_REQUEST";
export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_SUCCESS =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_SUCCESS";
export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_FAILURE =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_FAILURE";

export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_REQUEST =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_REQUEST";
export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_SUCCESS =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_SUCCESS";
export const GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_FAILURE =
  "GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_FAILURE";

export const SAVE_PENILAIAN_LUARAN_EVALUASI_REQUEST =
  "SAVE_PENILAIAN_LUARAN_EVALUASI_REQUEST";
export const SAVE_PENILAIAN_LUARAN_EVALUASI_SUCCESS =
  "SAVE_PENILAIAN_LUARAN_EVALUASI_SUCCESS";
export const SAVE_PENILAIAN_LUARAN_EVALUASI_FAILURE =
  "SAVE_PENILAIAN_LUARAN_EVALUASI_FAILURE";

export const SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_REQUEST =
  "SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_REQUEST";
export const SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_SUCCESS =
  "SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_SUCCESS";
export const SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_FAILURE =
  "SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_FAILURE";

export const CLEAN_LIST_PENILAIAN_LUARAN = "CLEAN_LIST_PENILAIAN_LUARAN";
