import { all, fork } from "redux-saga/effects";

//public
import LayoutSaga from "./layout/saga";
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import AccountSaga from "./auth/register/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ChangeSaga from "./auth/changepwd/saga";

import dashboardSaga from "./dashboard/saga";
import notificationSaga from "./notification/saga";
import catatanHarianSaga from "./catatanHarian/saga";
import usulanPerbaikanSaga from "./usulanPerbaikan/saga";
import usulanPenelitianSaga from "./usulanPenelitian/saga";
import usulanPengabdianSaga from "./usulanPengabdian/saga";
import laporanKemajuanSaga from "./laporanKemajuan/saga";
import laporanAkhirSaga from "./laporanAkhir/saga";

import pengumumanSaga from "./pengumuman/saga";
import ptDosenSaga from "./dataPendukung/saga";
import beritaSaga from "./berita/saga";

import operatorMonitoring from "./operatorMonitoring/saga";
import operatorPtDashboard from "./operatorPtDashboard/saga";
import operatorPengelolaanReviewer from "./operatorPengelolaanReviewer/saga";

import penugasan from "./penugasan/saga";
import penugasanPrototipe from "./penugasan/prototipe/saga";
import penugasanJFS from "./penugasan/jfs/saga";
import penugasanGoogle from "./penugasan/googleBangkit/saga";
import penugasanPembelajaran from "./penugasan/modulPembelajaran/saga";
import penugasanKosaBangsa from "./penugasan/kosabangsa/saga";

import monevSaga from "./monev/saga";

import reviewerMonev from "./reviewer/saga";

import PersetujuanUsulan from "./persetujuanUsulan/saga";
import BimtekProposal from "./bimtek/saga";

import EvaluasiAdministrasi from "./evaluasiAdministrasi/saga";
import EvaluasiSubtansi from "./evaluasiSubtansi/saga";
import Evaluasi from "./evaluasi/saga";
import Kosabangsa from "./kosabangsa/saga";
import SiteVisit from "./siteVisit/saga";
import PenilaianLuaran from "./penilaianLuaran/saga";
import PrototipeUsulan from "./prototipe/saga";
import Enumerations from "./enumerations/saga";
import KekayaanIntelektual from "./kekayaanIntelektual/saga";
import konfigurasi from "./konfigurasi/saga";
import operatorCommon from "./operatorCommon/saga";
import eCatalog from "./eCatalog/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(notificationSaga),
    fork(catatanHarianSaga),
    fork(usulanPerbaikanSaga),
    fork(usulanPenelitianSaga),
    fork(usulanPengabdianSaga),
    fork(laporanKemajuanSaga),
    fork(laporanAkhirSaga),
    fork(pengumumanSaga),
    fork(operatorMonitoring),
    fork(operatorPtDashboard),
    fork(operatorPengelolaanReviewer),
    fork(penugasan),
    fork(penugasanPrototipe),
    fork(penugasanJFS),
    fork(penugasanGoogle),
    fork(penugasanPembelajaran),
    fork(ptDosenSaga),
    fork(penugasanKosaBangsa),
    fork(beritaSaga),
    fork(ChangeSaga),
    fork(monevSaga),
    fork(reviewerMonev),
    fork(PersetujuanUsulan),
    fork(EvaluasiAdministrasi),
    fork(EvaluasiSubtansi),
    fork(Evaluasi),
    fork(BimtekProposal),
    fork(Kosabangsa),
    fork(SiteVisit),
    fork(PenilaianLuaran),
    fork(PrototipeUsulan),
    fork(Enumerations),
    fork(KekayaanIntelektual),
    fork(konfigurasi),
    fork(operatorCommon),
    fork(eCatalog),
  ]);
}
