import {
   GET_LIST_KI_REQUEST,
   GET_LIST_KI_SUCCESS,
   GET_LIST_KI_FAILURE,
   GET_DETAILS_KI_REQUEST,
   GET_DETAILS_KI_SUCCESS,
   GET_DETAILS_KI_FAILURE,
   DELETE_PENGAJUAN_KI_REQUEST,
   DELETE_PENGAJUAN_KI_FAILURE,
   DELETE_PENGAJUAN_KI_SUCCESS,
   CLEAN_DETAILS_KI,
   CREATE_PENGAJUAN_KI_REQUEST,
   UPDATE_PENGAJUAN_KI_REQUEST,
   CREATE_PENGAJUAN_KI_FAILURE,
   UPDATE_PENGAJUAN_KI_SUCCESS,
   UPDATE_PENGAJUAN_KI_FAILURE,
   CREATE_PENGAJUAN_KI_SUCCESS,
   GET_LIST_KI_PUSAT_REQUEST,
   GET_LIST_KI_PUSAT_SUCCESS,
   GET_LIST_KI_PUSAT_FAILURE,
   GET_COUNTING_KI_REQUEST,
   GET_COUNTING_KI_SUCCESS,
   GET_COUNTING_KI_FAILURE,
   GET_COUNTING_PLOT_KI_REQUEST,
   GET_COUNTING_PLOT_KI_SUCCESS,
   GET_COUNTING_PLOT_KI_FAILURE,
   GET_LIST_PLOT_KI_REQUEST,
   GET_LIST_PLOT_KI_SUCCESS,
   GET_LIST_PLOT_KI_FAILURE,
   GET_PLOT_KI_REQUEST,
   GET_PLOT_KI_SUCCESS,
   GET_PLOT_KI_FAILURE,
   GET_LIST_PENUGASAN_REVIEWER_KI_REQUEST,
   GET_LIST_PENUGASAN_REVIEWER_KI_SUCCESS,
   GET_LIST_PENUGASAN_REVIEWER_KI_FAILURE,
   GET_ELIGIBLITY_DOSEN_KI_REQUEST,
   GET_ELIGIBLITY_DOSEN_KI_SUCCESS,
   GET_ELIGIBLITY_DOSEN_KI_FAILURE,
   SAVE_PENILAIAN_KI_REQUEST,
   SAVE_PENILAIAN_KI_SUCCESS,
   GET_LIST_KEGIATAN_BIMTEK_KI_REQUEST,
   GET_LIST_KEGIATAN_BIMTEK_KI_SUCCESS,
   GET_LIST_KEGIATAN_BIMTEK_KI_FAILURE
} from "./actionTypes";

const initialState = {
   counting_pengajuan_ki: {
      draft: 0,
      submitted: 0,
      approved: 0,
      rejected: 0,
      isFetcing: false
   },
   list_pengajuan_ki: {
      items: [],
      isFetching: false,
   },
   list_pengajuan_ki_pusat: {
      items: [],
      isFetching: false,
      metaData: {
         "total_data": 0,
         "page": 0,
         "limit": 0,
         "prev_page": null,
         "next_page": 0,
         "last_page": 0
      }
   },
   list_plotting_reviewer_ki: {
      items: [],
      isFetching: false,
      metaData: {
         "total_data": 0,
         "page": 0,
         "limit": 0,
         "prev_page": null,
         "next_page": 0,
         "last_page": 0
      }
   },
   plotting_reviewer_ki: {
      item: null,
      isFetching: false,
   },
   penilaian_evaluasi_ki: {
      isFetching: false,
   },
   list_penugasan_reviewer_ki: {
      items: [],
      isFetching: false,
      metaData: {
         "total_data": 0,
         "page": 0,
         "limit": 0,
         "prev_page": null,
         "next_page": 0,
         "last_page": 0
      }
   },
   list_kegiatan_bimtek_ki: {
      items: [],
      isFetching : false
   },
   counting_plotting_reviewer_ki: {
      "total": 0,
      "approved": 0,
      "rejected": 0,
      "waiting_approval": 0,
      isFetching: false
   },
   details_pengajuan_ki: {
      item: null,
      isFetching: false,
   },
   eligibilty_dosen_ki: {
      isFetching: false,
      item: null,
      message: null
   },
   isProcessSaving: false
}

const kekayaanIntelektual = (state = initialState, action) => {

   switch (action.type) {
      case GET_ELIGIBLITY_DOSEN_KI_REQUEST:
         return (state = {
            ...state,
            eligibilty_dosen_ki: {
               ...state.eligibilty_dosen_ki,
               isFetching: true,
            }
         });
      case GET_ELIGIBLITY_DOSEN_KI_SUCCESS:
         return (state = {
            ...state,
            eligibilty_dosen_ki: {
               ...state.eligibilty_dosen_ki,
               isFetching: false,
               item: action.payload.data.data,
               message: action.payload.data.message
            },
         });
      case GET_ELIGIBLITY_DOSEN_KI_FAILURE:
         return (state = {
            ...state,
            eligibilty_dosen_ki: {
               ...state.eligibilty_dosen_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            }
         })
      case GET_LIST_PENUGASAN_REVIEWER_KI_REQUEST:
         return (state = {
            ...state,
            list_penugasan_reviewer_ki: {
               ...state.list_penugasan_reviewer_ki,
               isFetching: true,
            }
         });
      case GET_LIST_PENUGASAN_REVIEWER_KI_SUCCESS:
         return (state = {
            ...state,
            list_penugasan_reviewer_ki: {
               ...state.list_penugasan_reviewer_ki,
               isFetching: false,
               items: action.payload.data,
               metaData: action.payload.meta
            },
         });
      case GET_LIST_PENUGASAN_REVIEWER_KI_FAILURE:
         return (state = {
            ...state,
            list_penugasan_reviewer_ki: {
               ...state.list_penugasan_reviewer_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            }
         })
      case GET_LIST_KEGIATAN_BIMTEK_KI_REQUEST:
         return (state = {
            ...state,
            list_kegiatan_bimtek_ki: {
               ...state.list_kegiatan_bimtek_ki,
               isFetching: true,
            }
         });
      case GET_LIST_KEGIATAN_BIMTEK_KI_SUCCESS:
         return (state = {
            ...state,
            list_kegiatan_bimtek_ki: {
               ...state.list_kegiatan_bimtek_ki,
               isFetching: false,
               items: action.payload.data,
               metaData: action.payload.meta
            },
         });
      case GET_LIST_KEGIATAN_BIMTEK_KI_FAILURE:
         return (state = {
            ...state,
            list_kegiatan_bimtek_ki: {
               ...state.list_kegiatan_bimtek_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            }
         })
      case SAVE_PENILAIAN_KI_REQUEST:
         return (state = {
            ...state,
            penilaian_evaluasi_ki: {
               ...state.penilaian_evaluasi_ki,
               isFetching: true,
            }
         });
      case SAVE_PENILAIAN_KI_SUCCESS:
         return (state = {
            ...state,
            penilaian_evaluasi_ki: {
               ...state.penilaian_evaluasi_ki,
               isFetching: false,
            },
         });
      case GET_LIST_PLOT_KI_REQUEST:
         return (state = {
            ...state,
            list_plotting_reviewer_ki: {
               ...state.list_plotting_reviewer_ki,
               isFetching: true,
            }
         });
      case GET_LIST_PLOT_KI_SUCCESS:
         return (state = {
            ...state,
            list_plotting_reviewer_ki: {
               ...state.list_plotting_reviewer_ki,
               isFetching: false,
               items: action.payload.data,
               metaData: action.payload.meta
            },
         });
      case GET_LIST_PLOT_KI_FAILURE:
         return (state = {
            ...state,
            list_plotting_reviewer_ki: {
               ...state.list_plotting_reviewer_ki,
               isFetching: false,
               items : [],
               errors: action.payload.errors,
               errorMessage: action.payload.message,
               metaData: {
                  "total_data": 0,
                  "page": 0,
                  "limit": 0,
                  "prev_page": null,
                  "next_page": 0,
                  "last_page": 0
               }
            }
         })
      case GET_PLOT_KI_REQUEST:
         return (state = {
            ...state,
            plotting_reviewer_ki: {
               ...state.plotting_reviewer_ki,
               isFetching: true,
            }
         });
      case GET_PLOT_KI_SUCCESS:
         return (state = {
            ...state,
            plotting_reviewer_ki: {
               ...state.plotting_reviewer_ki,
               isFetching: false,
               item: action.payload.data,

            },
         });
      case GET_PLOT_KI_FAILURE:
         return (state = {
            ...state,
            plotting_reviewer_ki: {
               ...state.plotting_reviewer_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            }
         })
      case GET_COUNTING_PLOT_KI_REQUEST:
         return (state = {
            ...state,
            counting_plotting_reviewer_ki: {
               ...state.counting_plotting_reviewer_ki,
               isFetching: true,
            }
         });
      case GET_COUNTING_PLOT_KI_SUCCESS:
         return (state = {
            ...state,
            counting_plotting_reviewer_ki: {
               ...action.payload.data,
               isFetching: false,
            }
         });
      case GET_COUNTING_PLOT_KI_FAILURE:
         return (state = {
            ...state,
            counting_plotting_reviewer_ki: {
               ...state.counting_plotting_reviewer_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            }
         });
      case GET_COUNTING_KI_REQUEST:
         return (state = {
            ...state,
            counting_pengajuan_ki: {
               ...state.counting_pengajuan_ki,
               isFetcing: true,
            }
         });
      case GET_COUNTING_KI_SUCCESS:
         return (state = {
            ...state,
            counting_pengajuan_ki: {
               ...action.payload.data,
               isFetcing: false,
            }
         });
      case GET_COUNTING_KI_FAILURE:
         return (state = {
            ...state,
            counting_pengajuan_ki: {
               ...state.counting_pengajuan_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            }
         });
      case CREATE_PENGAJUAN_KI_REQUEST:
      case UPDATE_PENGAJUAN_KI_REQUEST:
         return (state = {
            ...state,
            isProcessSaving: true,
         });
      case CREATE_PENGAJUAN_KI_FAILURE:
      case UPDATE_PENGAJUAN_KI_FAILURE:
         return (state = {
            ...state,
            isProcessSaving: false,
         });
      case UPDATE_PENGAJUAN_KI_SUCCESS:
      case CREATE_PENGAJUAN_KI_SUCCESS:
         return (state = {
            ...state,
            details_pengajuan_ki: {
               ...state.details_pengajuan_ki,
               item: action.payload
            },
            isProcessSaving: false,
         });
      case GET_LIST_KI_PUSAT_REQUEST:
         return (state = {
            ...state,
            list_pengajuan_ki_pusat: {
               ...state.list_pengajuan_ki_pusat,
               isFetching: true,
            },
         });
      case GET_LIST_KI_REQUEST:
         return (state = {
            ...state,
            list_pengajuan_ki: {
               ...state.list_pengajuan_ki,
               isFetching: true,
            },
         });
      case GET_LIST_KI_PUSAT_SUCCESS:
         return (state = {
            ...state,
            list_pengajuan_ki_pusat: {
               isFetching: false,
               items: action.payload.data,
               metaData: action.payload.meta
            },
         });
      case GET_LIST_KI_SUCCESS:
         return (state = {
            ...state,
            list_pengajuan_ki: {
               isFetching: false,
               items: action.payload
            },
         });
      case GET_LIST_KI_PUSAT_FAILURE:
         return (state = {
            ...state,
            list_pengajuan_ki_pusat: {
               ...state.list_pengajuan_ki_pusat,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case GET_LIST_KI_FAILURE:
         return (state = {
            ...state,
            list_pengajuan_ki: {
               ...state.list_pengajuan_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case GET_DETAILS_KI_REQUEST:
         return (state = {
            ...state,
            details_pengajuan_ki: {
               ...state.details_pengajuan_ki,
               isFetching: true,
            },
         });
      case GET_DETAILS_KI_SUCCESS:
         return (state = {
            ...state,
            details_pengajuan_ki: {
               isFetching: false,
               item: action.payload
            },
         });
      case GET_DETAILS_KI_FAILURE:
         return (state = {
            ...state,
            details_pengajuan_ki: {
               ...state.details_pengajuan_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case CLEAN_DETAILS_KI:
         return (state = {
            ...state,
            details_pengajuan_ki: {
               isFetching: false,
               item: null
            },
         });

      case DELETE_PENGAJUAN_KI_REQUEST:
         return (state = {
            ...state,
            list_pengajuan_ki: {
               ...state.list_pengajuan_ki,
               isFetching: true,
            },
         });
      case DELETE_PENGAJUAN_KI_SUCCESS: {
         const id = action.payload.id;
         const copyListPengajuan = [...state.list_pengajuan_ki.items];
         const index = copyListPengajuan.findIndex(row => row.id === id);
         if (index > -1) {
            copyListPengajuan.splice(index, 1);
         }
         return (state = {
            ...state,
            list_pengajuan_ki: {
               isFetching: false,
               items: [...copyListPengajuan]
            },
         });
      }
      case DELETE_PENGAJUAN_KI_FAILURE:
         return (state = {
            ...state,
            list_pengajuan_ki: {
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      default:
         return state;
   }
}

export default kekayaanIntelektual;