import CryptoJS from "crypto-js";

const NODE = process.env.REACT_APP_NODE;
const SECRET_KEY = process.env.REACT_APP_SEC_KEY;
const hash = {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);

    return key.toString();
  },

  encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();

    return data;
  },

  decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  },
};

export default {
  setItem(key, value) {
    if (NODE === "development") {
      localStorage.setItem(key, value);
    } else {
      const secKey = hash.hash(key);
      const data = hash.encrypt(value);

      localStorage.setItem(secKey, data);
    }
  },
  getItem(key) {
    if (NODE === "development") {
      const data = localStorage.getItem(key);
      return data;
    } else {
      const secKey = hash.hash(key);
      const data = localStorage.getItem(secKey);

      if (data) return hash.decrypt(data);

      return null;
    }
  },
  removeItem(key) {
    if (NODE !== "development") key = hash.hash(key);

    localStorage.removeItem(key);
  },
};
