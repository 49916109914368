export default {
  text: {
    lineHeight: 1.5,
    alignment: "justify",
  },
  bold: {
    bold: true,
  },
  italics: {
    italics: true,
  },
  center: {
    alignment: "center",
  },
  justify : {
    alignment : "justify"
  },
  right: {
    alignment: "right",
  },
  left: {
    alignment: "left",
  },
  fontSizeXl: {
    fontSize: 15,
  },
  fontSizeL: {
    fontSize: 12,
  },
  fontSizeM: {
    fontSize: 10,
  },
  fontSizeS: {
    fontSize: 9,
  },
  fontSizeXs: {
    fontSize: 7.5,
  },
  underline: {
    decoration: "underline",
  },
  "mb-5" : {
    marginBottom: 5
  },
  "mb-10" : {
    marginBottom: 10
  },
  "mb-15" : {
    marginBottom: 15
  },
  "mb-20" : {
    marginBottom: 20
  },
  "mt-5" : {
    marginTop: 5
  },
  "mt-10" : {
    marginTop: 10
  },
  "mt-15" : {
    marginTop: 15
  },
  "mt-20" : {
    marginTop: 20
  },
  "link" : {
    'color' : '#2C50B5',
    'decoration' : 'underline'
  }
};
