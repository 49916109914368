import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import { getTempalteDocsFailure, getTempalteDocsSuccess } from "./action";
import { GET_TEMPLATES_DOCS_REQUEST } from "./actionTypes";

function* getTempalteDocs({ payload = {} }) {
   try {
      const response = yield call(backend.getEnumeration, payload);
      if (response.data.code == 200) {
         yield put(getTempalteDocsSuccess(response.data.data ));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      console.log(message);
      toast.error(message);
      yield put(getTempalteDocsFailure(message, { ...error }));
   }
}

function* enumerations() {
   yield takeEvery(GET_TEMPLATES_DOCS_REQUEST, getTempalteDocs);
}

export default enumerations;