export const LIST_SKEMA_REQUEST = "LIST_SKEMA_REQUEST";
export const LIST_SKEMA_SUCCESS = "LIST_SKEMA_SUCCESS";
export const LIST_SKEMA_FAILURE = "LIST_SKEMA_FAILURE";

export const GET_KELOMPOK_PROPOSAL_BY_SKEMA_REQUEST = "GET_KELOMPOK_PROPOSAL_BY_SKEMA_REQUEST";
export const GET_KELOMPOK_PROPOSAL_BY_SKEMA_SUCCESS = "GET_KELOMPOK_PROPOSAL_BY_SKEMA_SUCCESS";
export const GET_KELOMPOK_PROPOSAL_BY_SKEMA_FAILURE = "GET_KELOMPOK_PROPOSAL_BY_SKEMA_FAILURE";
export const CLEAN_KELOMPOK_PROPOSAL_BY_SKEMA = "CLEAN_KELOMPOK_PROPOSAL_BY_SKEMA";

export const GET_SKEMA_BYID_REQUEST = "GET_SKEMA_BYID_REQUEST";
export const GET_SKEMA_BYID_SUCCESS = "GET_SKEMA_BYID_SUCCESS";
export const GET_SKEMA_BYID_FAILURE = "GET_SKEMA_BYID_FAILURE";

export const GET_USULAN_BY_SKEMA_REQUEST = "GET_USULAN_BY_SKEMA_REQUEST";
export const GET_USULAN_BY_SKEMA_SUCCESS = "GET_USULAN_BY_SKEMA_SUCCESS";
export const GET_USULAN_BY_SKEMA_FAILURE = "GET_USULAN_BY_SKEMA_FAILURE";

export const GET_USULAN_BYID_REQUEST = "GET_USULAN_BYID_REQUEST";
export const GET_USULAN_BYID_SUCCESS = "GET_USULAN_BYID_SUCCESS";
export const GET_USULAN_BYID_FAILURE = "GET_USULAN_BYID_FAILURE";

export const GET_HASIL_REVIEWER_TERAKHIR_REQUEST =
  "GET_HASIL_REVIEWER_TERAKHIR_REQUEST";
export const GET_HASIL_REVIEWER_TERAKHIR_SUCCESS =
  "GET_HASIL_REVIEWER_TERAKHIR_SUCCESS";
export const GET_HASIL_REVIEWER_TERAKHIR_FAILURE =
  "GET_HASIL_REVIEWER_TERAKHIR_FAILURE";

export const LIST_INSTITUSI_REQUEST = "LIST_INSTITUSI_REQUEST";
export const LIST_INSTITUSI_SUCCESS = "LIST_INSTITUSI_SUCCESS";
export const LIST_INSTITUSI_FAILURE = "LIST_INSTITUSI_FAILURE";

export const GET_KOMPONEN_PENILIAN_REQUEST = "GET_KOMPONEN_PENILIAN_REQUEST";
export const GET_KOMPONEN_PENILIAN_SUCCESS = "GET_KOMPONEN_PENILIAN_SUCCESS";
export const GET_KOMPONEN_PENILIAN_FAILURE = "GET_KOMPONEN_PENILIAN_FAILURE";

export const SAVE_MONEV_REQUEST = "SAVE_MONEV_REQUEST";
export const SAVE_MONEV_SUCCESS = "SAVE_MONEV_SUCCESS";
export const SAVE_MONEV_FAILURE = "SAVE_MONEV_FAILURE";

export const PERMANENT_MONEV_PENGABDIAN_REQUEST =
  "PERMANENT_MONEV_PENGABDIAN_REQUEST";
export const PERMANENT_MONEV_PENGABDIAN_SUCCESS =
  "PERMANENT_MONEV_PENGABDIAN_SUCCESS";
export const PERMANENT_MONEV_PENGABDIAN_FAILURE =
  "PERMANENT_MONEV_PENGABDIAN_FAILURE";

export const GET_LINK_BERKAS_MONEV_REQUEST = "GET_LINK_BERKAS_MONEV_REQUEST";
export const GET_LINK_BERKAS_MONEV_SUCCESS = "GET_LINK_BERKAS_MONEV_SUCCESS";
export const GET_LINK_BERKAS_MONEV_FAILURE = "GET_LINK_BERKAS_MONEV_FAILURE";

export const GET_POST_PUT_DEL_MONEV_INTERNAL_REQUEST =
  "GET_POST_PUT_DEL_MONEV_INTERNAL_REQUEST";
export const GET_POST_PUT_DEL_MONEV_INTERNAL_SUCCESS =
  "GET_POST_PUT_DEL_MONEV_INTERNAL_SUCCESS";
export const GET_POST_PUT_DEL_MONEV_INTERNAL_FAILURE =
  "GET_POST_PUT_DEL_MONEV_INTERNAL_FAILURE";

export const GET_LIST_INSTITUSI_REVIEWER_REQUEST = "GET_LIST_INSTITUSI_REVIEWER_REQUEST";
export const GET_LIST_INSTITUSI_REVIEWER_SUCCESS = "GET_LIST_INSTITUSI_REVIEWER_SUCCESS";
export const GET_LIST_INSTITUSI_REVIEWER_FAILURE = "GET_LIST_INSTITUSI_REVIEWER_FAILURE";
