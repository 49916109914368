import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_FAILURE1,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  isLoading: false,
  oldPassword: false,
  errorMessage: "",
  errors: null,
  items: [],
  itemSelected: {},
  msg: "",
};

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    // CHANGE PASSWORD START
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "CHANGE_PASSWORD_REQUEST",
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        items: action.payload ?? [],
        msg: "CHANGE_PASSWORD_SUCCESS",
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "CHANGE_PASSWORD_FAILURE",
      };
    case CHANGE_PASSWORD_FAILURE1:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        oldPassword: action.payload ?? true,
        msg: "OLD_PASSWORD_WRONG",
      };
    // CHANGE PASSWORD END
    default:
      return state;
  }
};

export default changePasswordReducer;
