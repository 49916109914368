import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";

import {
  GET_SKEMA_EVALUASI_SUBTANSI_REQUEST,
  GET_LIST_USULAN_EVALUASI_SUBTANSI_REQUEST,
  GET_DETAIL_USULAN_EVALUASI_SUBTANSI_REQUEST,
  GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_REQUEST,
  SAVE_PENILAIAN_EVALUASI_SUBTANSI_REQUEST,
  SAVE_PENILAIAN_PERMANEN_EVALUASI_SUBTANSI_REQUEST,
  GET_RAB_EVALUASI_SUBTANSI_REQUEST,
  GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
  SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
  GET_KELOMPOK_EVALUASI_SUBTANSI_V2_REQUEST,
  GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
  GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_REQUEST,
  GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
} from "./actionTypes";
// Action
import {
  getKomponenNilaiEvaluasiSubtansiFailure,
  getKomponenNilaiEvaluasiSubtansiSuccess,
  getListUsulanEvaluasiSubtansiSuccess,
  getListUsulanEvaluasiSubtansiFailure,
  getDetailUsulanEvaluasiSubtansiSuccess,
  getDetailUsulanEvaluasiSubtansiFailure,
  getSkemaEvaluasiSubtansiSuccess,
  getSkemaEvaluasiSubtansiFailure,
  getRABEvaluasiSubtansiSuccess,
  getRABEvaluasiSubtansiFailure,
  getListRABRekomenEvaluasiSubtansiSuccess,
  getListRABRekomenEvaluasiSubtansiFailure,
  saveRABRekomendasiEvaluasiSubtansiSuccess,
  saveRABRekomendasiEvaluasiSubtansiFailure,
  getListUsulanEvaluasiSubtansiv2Success,
  getListUsulanEvaluasiSubtansiv2Failure,
  getKomponenNilaiSubtansiEvaluasiv2Success,
  getKomponenNilaiSubtansiEvaluasiv2Failure,
  getDetailUsulanSubtansiEvaluasiv2Success,
  getDetailUsulanSubtansiEvaluasiv2Failure,
} from "./action";

function* getSkemaEvaluasiSubtansi({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getSkemaEvaluasiSubtansi, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getSkemaEvaluasiSubtansiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getSkemaEvaluasiSubtansiFailure(message, error));
  }
}

function* getKelompokEvaluasiSubtansi({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getKelompokEvaluasi, {
      ...payload,
      kode_tahapan: 20,
    });
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getSkemaEvaluasiSubtansiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getSkemaEvaluasiSubtansiFailure(message, error));
  }
}

function* getListUsulanEvaluasiSubtansi({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getListUsulanEvaluasiSubtansi, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getListUsulanEvaluasiSubtansiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListUsulanEvaluasiSubtansiFailure(message, error));
  }
}

function* getListUsulanEvaluasiSubtansiv2({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getListUsulanEvaluasiV2, {
      ...payload,
      kode_tahapan: 22,
    });
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getListUsulanEvaluasiSubtansiv2Success({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListUsulanEvaluasiSubtansiv2Failure(message, error));
  }
}

function* getDetailUsulanEvaluasiSubtansi({ payload, cb = () => { } }) {
  try {
    const response = yield call(
      backend.getDetailUsulanByTransaksiKegiatanId,
      payload
    );
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getDetailUsulanEvaluasiSubtansiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getDetailUsulanEvaluasiSubtansiFailure(message, error));
  }
}

function* getDetailUsulanEvaluasiSubtansiv2({ payload, cb = () => { } }) {
  try {
    const response = yield call(
      backend.getDetailPenilaianEvaluasiV2,
      payload
    );
    if (response.data.code == 200) {
      let state = "";
     
      cb(response.data.data);
      yield put(
        getDetailUsulanSubtansiEvaluasiv2Success({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getDetailUsulanSubtansiEvaluasiv2Failure(message, error));
  }
}

function* getKomponenNilaiEvaluasiSubtansi({ payload, cb = () => { } }) {
  try {
    const response = yield call(
      backend.getKomponenNilaiEvaluasiSubtansi,
      payload
    );
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getKomponenNilaiEvaluasiSubtansiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getKomponenNilaiEvaluasiSubtansiFailure(message, error));
  }
}

function* getKomponenNilaiEvaluasiSubtansiv2({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getKomponenNilaiEvaluasiV2, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getKomponenNilaiSubtansiEvaluasiv2Success({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getKomponenNilaiSubtansiEvaluasiv2Failure(message, error));
  }
}

function* getRABEvaluasiSubtansi({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getRABByUsulanId, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getRABEvaluasiSubtansiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getRABEvaluasiSubtansiFailure(message, error));
  }
}

function* getRABRekomendasiEvaluasiSubtansi({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getListRabRekomendasi, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
      yield put(
        getListRABRekomenEvaluasiSubtansiSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListRABRekomenEvaluasiSubtansiFailure(message, error));
  }
}

function* saveRABRekomendasiEvaluasiSubtansi({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.saveRabRekomendasi, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
      yield put(
        saveRABRekomendasiEvaluasiSubtansiSuccess({
          data: response.data.data,
        })
      );
    } else {
      cb(null);
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(saveRABRekomendasiEvaluasiSubtansiFailure(message, error));
  }
}

function* savePenilaianEvaluasi({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.savePenilaianEvaluasiSubtansi, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
    } else {
      cb();
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
  }
}

function* savePenilaianPermanenEvaluasi({ payload, cb = () => { } }) {
  try {
    const response = yield call(
      backend.savePenilaianPermanenEvaluasiSubtansi,
      payload
    );
    if (response.data.code == 200) {
      cb(response.data.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
  }
}

function* evaluasiAdministrasi() {
  yield takeEvery(
    GET_SKEMA_EVALUASI_SUBTANSI_REQUEST,
    getSkemaEvaluasiSubtansi
  );
  yield takeEvery(
    GET_LIST_USULAN_EVALUASI_SUBTANSI_REQUEST,
    getListUsulanEvaluasiSubtansi
  );
  yield takeEvery(
    GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_REQUEST,
    getKomponenNilaiEvaluasiSubtansi
  );
  yield takeEvery(
    GET_DETAIL_USULAN_EVALUASI_SUBTANSI_REQUEST,
    getDetailUsulanEvaluasiSubtansi
  );
  yield takeEvery(
    GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
    getDetailUsulanEvaluasiSubtansiv2
  );
  yield takeEvery(
    SAVE_PENILAIAN_EVALUASI_SUBTANSI_REQUEST,
    savePenilaianEvaluasi
  );
  yield takeEvery(
    SAVE_PENILAIAN_PERMANEN_EVALUASI_SUBTANSI_REQUEST,
    savePenilaianPermanenEvaluasi
  );
  yield takeEvery(GET_RAB_EVALUASI_SUBTANSI_REQUEST, getRABEvaluasiSubtansi);
  yield takeEvery(
    GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
    getRABRekomendasiEvaluasiSubtansi
  );
  yield takeEvery(
    SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
    saveRABRekomendasiEvaluasiSubtansi
  );
  yield takeEvery(
    GET_KELOMPOK_EVALUASI_SUBTANSI_V2_REQUEST,
    getKelompokEvaluasiSubtansi
  );
  yield takeEvery(
    GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
    getListUsulanEvaluasiSubtansiv2
  );
  yield takeEvery(
    GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_REQUEST,
    getKomponenNilaiEvaluasiSubtansiv2
  );
}

export default evaluasiAdministrasi;
