import encStorage from "helpers/encStorage";
import { decode } from "helpers/jwt";
import {
  instances,
  instancesV2,
  instancesReport,
  instancesReportV2,
} from "../../api_helper";
import * as url from "../../url_helper";
import utilities from "helpers/utilities";
const storage = JSON.parse(encStorage.getItem("authUser"));
export const getOperatorListUsulanPerbaikan = ({
  tahunPelaksanaan,
  jenisKegiatan,
  operatorPusat,
  operatorLldikti,
  id_institusi,
}) => {
  if (operatorPusat || operatorLldikti) {
    return instances.request(
      `${url.ENDPOINT_OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_PUSAT}?kodeJenisKegiatan=${jenisKegiatan}&tahunUsulan=${tahunPelaksanaan}&institusi=${id_institusi}`,
      {
        method: "GET",
      }
    );
  } else {
    return instances.request(
      `${url.ENDPOINT_OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN}?kodeJenisKegiatan=${jenisKegiatan}&tahunUsulan=${tahunPelaksanaan}`,
      {
        method: "GET",
      }
    );
  }
};
// * List option select untuk list perbaikan usulan kompetitif nasional, desentralsi, dll
export const getFilterReferensiProgram = async jenisKegiatan => {
  const res = await instances.request(
    `/referensi/program-hibah?criteria=kd_jenis_kegiatan:${jenisKegiatan}`, // 1 penelitian, 2 pengabdian
    {
      method: "GET",
    }
  );
  return res;
};

export const getOperatorListCatatanHarian = payload => {
  if (payload.operatorPusat || payload.operatorLldikti) {
    return instances.request(
      `${url.ENDPOINT_OPERATOR_MONITORING_LIST_CATATAN_HARIAN}/${payload.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun=${payload.tahun}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.ENDPOINT_OPERATOR_MONITORING_LIST_CATATAN_HARIAN}/${jwtResult.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun=${payload.tahun}`,
      {
        method: "GET",
      }
    );
  }
};

export const getOperatorListCatatanHarianV2 = ({
  id_institusi,
  id_skema,
  limit = 10,
  page = 1,
  param,
}) => {
  return instancesV2.request(
    `${
      url.ENDPOINT_OPERATOR_MONITORING_LIST_CATATAN_HARIAN_V2
    }?id_institusi=${id_institusi}&id_skema=${id_skema}&limit=${limit}&page=${page}${
      param ? `&param=${param}` : ""
    }`,
    {
      method: "GET",
    }
  );
};

export const getOperatorListLaporanKemajuan = async payload => {
  if (payload.operatorPusat || payload.operatorLldikti) {
    return instances.request(
      `${url.ENDPOINT_OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN}/${payload.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun-pelaksanaan=${payload.tahun_pelaksanaan}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.ENDPOINT_OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN}/${jwtResult.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun-pelaksanaan=${payload.tahun_pelaksanaan}`,
      {
        method: "GET",
      }
    );
  }
};
export const getOperatorLaporanKemajuanDetail = async payload => {
  if (payload.operatorPusat || payload.operatorLldikti) {
    return instances.request(
      `${url.ENDPOINT_OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL}/${payload.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun-pelaksanaan=${payload.tahun_pelaksanaan}&skema=${payload.skema}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.ENDPOINT_OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL}/${jwtResult.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun-pelaksanaan=${payload.tahun_pelaksanaan}&skema=${payload.skema}`,
      {
        method: "GET",
      }
    );
  }
};

export const getAllInstitusi = search => {
  return instances.request(
    url.ENDPOINT_LIST_ALL_INSTITUSI + `?search=${search ?? ""}`,
    {
      method: "GET",
    }
  );
};

// Monitoring Lap Akhir START
// 1. Get All List Lap Akhir : {{URL}}/laporan-akhir/global/a47a5087-cbb7-44ae-b85e-0bf2af3799df?jenis-kegiatan=1&tahun=2015   " Get All Pertama "
// 2. Get Detail Lap Akhir : {{URL}}/laporan-akhir/global/by-id-usulan-kegiatan/0a79e84a-730b-4b2c-8700-51e76027cbf5/1/0022056003 "Minta tambah Skema buat bisa hit detailnya"
// {{URL}}/laporan-akhir/global/a47a5087-cbb7-44ae-b85e-0bf2af3799df?jenis-kegiatan=1&tahun=2015&skema=4
export const getOperatorListLaporanAkhir = payload => {
  if (payload.operatorPusat || payload.operatorLldikti) {
    return instances.request(
      `${url.ENDPOINT_MONITORING_LIST_LAPORAN_AKHIR}/${payload.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun=${payload.tahun_pelaksanaan}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.ENDPOINT_MONITORING_LIST_LAPORAN_AKHIR}/${jwtResult.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun=${payload.tahun_pelaksanaan}`,
      {
        method: "GET",
      }
    );
  }
};

export const getOperatorLaporanAkhirDetail = payload => {
  if (payload.operatorPusat || payload.operatorLldikti) {
    console.log("helper");
    return instances.request(
      `${url.ENDPOINT_MONITORING_LIST_LAPORAN_AKHIR}/${payload.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun=${payload.tahun_pelaksanaan}&skema=${payload.skema}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.ENDPOINT_MONITORING_LIST_LAPORAN_AKHIR}/${jwtResult.id_institusi}?jenis-kegiatan=${payload.jenis_kegiatan}&tahun=${payload.tahun_pelaksanaan}&skema=${payload.skema}`,
      {
        method: "GET",
      }
    );
  }
};
// Monitoring Lap Akhir END

// Monitoring Monev at OPT start
export const listOptMonev = () => {
  return instances.request(`${url.ENDPOINT_OPT_LIST_MONEV}`, {
    method: "GET",
  });
};

// Monitoring Monev at OPT end

// report penilaian usulan
export const getReportPenilaianUsulan = async queryParams => {
  return await instancesReport.request(
    `/download/excel/vokasi-penilaian?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(queryParams)
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getReportLaporanKemajuanAndAkhirV2 = async payload => {
  return await instancesReportV2.request(
    `/proposal/report/excel/kemajuan-akhir?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload.queryParams)
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getListOperatorLaporanKemajuanV2 = payload => {
  return instancesV2.request(
    `/proposal/monitoring-laporan/search?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload.queryParams)
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const getSummaryOperatorLaporanKemajuanV2 = payload => {
  return instancesV2.request(
    `/proposal/monitoring-laporan-group/search?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload.queryParams)
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
