export const USULAN_PERBAIKAN_REQUEST = "USULAN_PERBAIKAN_REQUEST";
export const USULAN_PERBAIKAN_SUCCESS = "USULAN_PERBAIKAN_SUCCESS";
export const USULAN_PERBAIKAN_FAILURE = "USULAN_PERBAIKAN_FAILURE";

export const USULAN_PERBAIKAN_BYID_REQUEST = "USULAN_PERBAIKAN_BYID_REQUEST";
export const USULAN_PERBAIKAN_BYID_SUCCESS = "USULAN_PERBAIKAN_BYID_SUCCESS";
export const USULAN_PERBAIKAN_BYID_FAILURE = "USULAN_PERBAIKAN_BYID_FAILURE";

export const USULAN_PERBAIKAN_SUBMIT_REQUEST = "USULAN_PERBAIKAN_SUBMIT_REQUEST";
export const USULAN_PERBAIKAN_SUBMIT_SUCCESS = "USULAN_PERBAIKAN_SUBMIT_SUCCESS";
export const USULAN_PERBAIKAN_SUBMIT_FAILURE = "USULAN_PERBAIKAN_SUBMIT_FAILURE";

export const UPDATE_ISIAN_ITEM_USULAN_PERBAIKAN = "UPDATE_ISIAN_ITEM_USULAN_PERBAIKAN";

export const MERGING_INTO_USULAN_PERBAIKAN_V2_REQUEST = "MERGING_INTO_USULAN_PERBAIKAN_V2_REQUEST";
export const MERGING_INTO_USULAN_PERBAIKAN_V2_SUCCESS = "MERGING_INTO_USULAN_PERBAIKAN_V2_SUCCESS";
export const MERGING_INTO_USULAN_PERBAIKAN_V2_FAILURE = "MERGING_INTO_USULAN_PERBAIKAN_V2_FAILURE";

export const GET_USULAN_PERBAIKAN_V2_REQUEST = "GET_USULAN_PERBAIKAN_V2_REQUEST";
export const GET_USULAN_PERBAIKAN_V2_SUCCESS = "GET_USULAN_PERBAIKAN_V2_SUCCESS";
export const GET_USULAN_PERBAIKAN_V2_FAILURE = "GET_USULAN_PERBAIKAN_V2_FAILURE";

export const CLEAN_USULAN_PERBAIKAN_V2 = "CLEAN_USULAN_PERBAIKAN_V2";

export const GET_DETAIL_USULAN_PERBAIKAN_V2_REQUEST = "GET_DETAIL_USULAN_PERBAIKAN_V2_REQUEST";
export const GET_DETAIL_USULAN_PERBAIKAN_V2_SUCCESS = "GET_DETAIL_USULAN_PERBAIKAN_V2_SUCCESS";
export const GET_DETAIL_USULAN_PERBAIKAN_V2_FAILURE = "GET_DETAIL_USULAN_PERBAIKAN_V2_FAILURE";

export const UPDATE_USULAN_PERBAIKAN_V2_REQUEST = "UPDATE_USULAN_PERBAIKAN_V2_REQUEST";
export const UPDATE_USULAN_PERBAIKAN_V2_SUCCESS = "UPDATE_USULAN_PERBAIKAN_V2_SUCCESS";
export const UPDATE_USULAN_PERBAIKAN_V2_FAILURE = "UPDATE_USULAN_PERBAIKAN_V2_FAILURE";