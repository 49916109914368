const roles = {
  DOSEN: 37,
  SUPER_ADMIN: 53,
  REVIEWER_NASIONAL: 4,
  REVIEWER_PT: 7,
  OPERATOR_PT_PENELITIAN: 6, // -> Opt PT Penelitian
  OPERATOR_PT_PENGABDIAN: 40, // -> Opt PT Pengabdian
  OPERATOR_PT_PENELITIAN_VOKASI: 8, // -> Opt PT Penelitian
  OPERATOR_PT_PENGABDIAN_VOKASI: 41, // -> Opt PT Pengabdian
  OPERATOR_DIKTI: 76,
  OPERATOR_LLDIKTI_PENELITIAN: 50, // -> Operator LLDIKTI Penelitian 50
  OPERATOR_LLDIKTI_PENGABDIAN: 51, // -> Operator LLDIKTI Pengabdian 51
  OPERATOR_MANAGEMENT_LLDIKTI: 70, // -> Operator Management LLDIKTI 70
  OPERATOR_PUSAT_ADMINISTRATOR: 1, // -> Operator pusat (Administrator)
  OPERATOR_PUSAT_RISBANG_PENELITIAN: 55, // -> Operator pusat penelitian
  OPERATOR_PUSAT_RISBANG_ABDIMAS: 56, // -> Operator pusat pengabdian
  OPERATOR_PUSAT_IT_SUPPORT: 57, // -> Operator pusat It Support
  KEPALA_LEMBAGA_PENELITIAN: 9, // -> Kepala LLPM Penelitian
  KEPALA_LEMBAGA_PENGABDIAN: 10, // -> Kepala LPPM Pengabdian
  REVIEWER_DUDI: 96, // -> Sementara
  OPERATOR_KEMENTRIAN_DIT_APTV: 95, //
  ADMIN_PROTOTIPE: 100, // Admin Prototipe,
  OPERATOR_PUSAT_KI: 101, //Operator PUSAT KEKAYAAN INTELEKTUAL,
  REVIEWER_KI_NON_DOSEN: 102, //REVIEWER PENILAIAN USULAN KI NON DOSEN
  OPERATOR_E_CATALOG: 103, //OPT ECATALOGE
  OPERATOR_BPK: 104, //OPT BPK
};
export default roles;

export function getRoleName(roleId) {
  switch (roleId) {
    case roles.SUPER_ADMIN:
      return "Super Admin";
    case roles.REVIEWER_NASIONAL:
      return "Reviewer Nasional";
    case roles.OPERATOR_PT_PENELITIAN:
      return "Operator PT Penelitian";
    case roles.OPERATOR_PT_PENGABDIAN:
      return "Operator PT Pengabdian";
    case roles.DOSEN:
      return "Dosen";
    case roles.OPERATOR_DIKTI:
      return "Operator Dikti";
    case roles.OPERATOR_LLDIKTI_PENELITIAN:
      return "Operator LLDIKTI Penelitian";
    case roles.OPERATOR_LLDIKTI_PENGABDIAN:
      return "Operator LLDIKTI Pengabdian";
    case roles.OPERATOR_MANAGEMENT_LLDIKTI:
      return "Operator Management LLDIKTI";
    case roles.OPERATOR_PUSAT_ADMINISTRATOR:
      return "Operator Pusat Administrator";
    case roles.OPERATOR_PUSAT_RISBANG_PENELITIAN:
      return "Operator Pusat Risbang Penelitian";
    case roles.OPERATOR_PUSAT_RISBANG_ABDIMAS:
      return "Operator Pusat Risbang Abdimas";
    case roles.OPERATOR_PUSAT_IT_SUPPORT:
      return "Operator Pusat IT Support";
    case roles.KEPALA_LEMBAGA_PENELITIAN:
      return "Kepala Lembaga Penelitian";
    case roles.KEPALA_LEMBAGA_PENGABDIAN:
      return "Kepala Lembaga Pengabdian";
    case roles.REVIEWER_DUDI:
      return "Reviewer DUDI";
    case roles.REVIEWER_PT:
      return "Reviewer PT/Kopertis";
    case roles.OPERATOR_KEMENTRIAN_DIT_APTV:
      return "Operator Kementrian DIT APTV";
    case roles.ADMIN_PROTOTIPE:
      return "Admin Prototipe";
    case roles.OPERATOR_E_CATALOG:
      return "Operator E-Catalog";
    default:
      return "-";
  }
}
