import { content, contentSptb, contentSptbPenelitian } from "./content";
import stylePDF from "./styles";
import { stylePDFSptbPenelitian } from "./styles";

export const pdfCreateMain = (item, flagJenisKegiatan, jenisKegiatan) => {
  const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
  return {
    content: [...content(item, flagJenisKegiatan, jenisKegiatan)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};

export const pdfCreateSptb = item => {
  const { pageSize, pageMargins, styles, defaultStyle } = stylePDF;
  return {
    content: [...contentSptb(item)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};
export const pdfCreateSptbPenelitian = item => {
  const { pageSize, pageMargins, styles, defaultStyle } =
    stylePDFSptbPenelitian;
  return {
    content: [...contentSptbPenelitian(item)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};
