import React from "react";

const DashboardECatalog = React.lazy(() =>
  import("../pages/eCatalog/Dashboard/index")
);
const MapsLLDIKTIDetail = React.lazy(() =>
  import("../pages/eCatalog/Maps/DetailLLDIKTI")
);
const MapsLLDIKTIDetail2 = React.lazy(() =>
  import("../pages/eCatalog/Maps/DetailLLDIKTI/Detail")
);

// e-Catalog (Search Page)
const SearchEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Search/index")
);
const SearchEKatalogSpesifik = React.lazy(() =>
  import("../pages/eCatalog/Search/Spesifik/index")
);
const SearchResultEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Search/SearchResult/index.js")
);
const SearchResultEKatalogDetail = React.lazy(() =>
  import("../pages/eCatalog/Search/SearchResult/Detail")
);

// e-Catalog perguruan tinggi
const PerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/index.js")
);
const DetailChartPerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/DetailChart")
);

// e-Catalog Bidang Fokus
const BidangFokusEKatalog = React.lazy(() =>
  import("../pages/eCatalog/BidangFokus/index.js")
);
const BidangFokusDetail1EKatalog = React.lazy(() =>
  import("../pages/eCatalog/BidangFokus/Detail/index.js")
);
const BidangFokusDetail2EKatalog = React.lazy(() =>
  import("../pages/eCatalog/BidangFokus/Detail2/index.js")
);
const BidangFokusDetail3EKatalog = React.lazy(() =>
  import("../pages/eCatalog/BidangFokus/Detail3/index.js")
);

// e-catalog klaster
const KlasterEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Klaster/index.js")
);
const KlasterDetailEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Klaster/Detail/index.js")
);
const KlasterDetail2EKatalog = React.lazy(() =>
  import("../pages/eCatalog/Klaster/Detail2/index.js")
);
const KlasterDetail3EKatalog = React.lazy(() =>
  import("../pages/eCatalog/Klaster/Detail3/index.js")
);

// e-catalog benchmark
const BenchmarkEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Benchmark/index.js")
);

//e-Catalog list proposal dosen bersangkutan
const ListProposalDosen = React.lazy(() =>
  import("../components/ECatalog/ListUsulanDosen/Detail")
);
export default [
  // * E-Catalog Menu Home
  {
    id: "home-e-catalog-public",
    label: "Home",
    sequence: 1,
    path: "/e-catalog/public/home",
    component: DashboardECatalog,
  },
  {
    id: "maps-lldikti-detail",
    path: "/e-catalog/public/maps/lldikti-detail",
    component: MapsLLDIKTIDetail,
  },
  {
    id: "maps-lldikti-detail",
    path: "/e-catalog/public/maps/lldikti-detail2/:id",
    component: MapsLLDIKTIDetail2,
  },
  // * E-Catalog Menu Pencarian
  {
    id: "e-catalog-pencarian",
    label: "Pencarian",
    sequence: 2,
    path: "/e-catalog/public/search",
    icon: "bx bx-search-alt-2",
    component: SearchEKatalog,
  },
  {
    id: "e-catalog-pencarian-spesifik",
    path: "/e-catalog/public/search/spesifik",
    component: SearchEKatalogSpesifik,
  },
  {
    id: "e-catalog-pencarian-search-result",
    path: "/e-catalog/public/search/search-result",
    component: SearchResultEKatalog,
  },
  {
    id: "e-catalog-pencarian-detail-search-result",
    path: "/e-catalog/public/search/search-result/detail/:id",
    component: SearchResultEKatalogDetail,
  },
  // =====================================================

  // * E-Catalog Menu Perguruan Tinggi
  {
    id: "e-catalog-perguruan-tinggi",
    label: "Perguruan Tinggi",
    path: "/e-catalog/public/perguruan-tinggi",
    component: PerguruanTinggiEKatalog,
    sequence: 2,
    icon: "bx bx-buildings",
  },
  // sub menu chart
  {
    id: "e-catalog-perguruan-tinggi-detail-chart",
    path: "/e-catalog/public/perguruan-tinggi/detail-chart",
    component: DetailChartPerguruanTinggiEKatalog,
  },

  // * =====================================================

  // * E-Catalog Bidang Fokus
  {
    id: "e-catalog-bidang-fokus",
    label: "Bidang Fokus",
    path: "/e-catalog/public/bidang-fokus",
    sequence: 3,
    icon: "bx bx-book-bookmark",
    component: BidangFokusEKatalog,
  },
  {
    id: "e-catalog-bidang-fokus-detail",
    path: "/e-catalog/public/bidang-fokus/detail",
    component: BidangFokusDetail1EKatalog,
  },
  {
    id: "e-catalog-bidang-fokus-detail",
    path: "/e-catalog/public/bidang-fokus/detail2",
    component: BidangFokusDetail2EKatalog,
  },
  {
    id: "e-catalog-bidang-fokus-detail",
    path: "/e-catalog/public/bidang-fokus/detail3/:id",
    component: BidangFokusDetail3EKatalog,
  },

  // * =====================================================

  // * E-Catalog Klaster
  {
    id: "e-catalog-klaster",
    label: "Klaster",
    path: "/e-catalog/public/klaster",
    component: KlasterEKatalog,
    sequence: 4,
    icon: "bx bx-layer",
  },
  {
    id: "e-catalog-klaster-detail",
    path: "/e-catalog/public/klaster/detail/:kd_klaster",
    component: KlasterDetailEKatalog,
  },
  {
    id: "e-catalog-klaster-detail",
    path: "/e-catalog/public/klaster/detail2",
    component: KlasterDetail2EKatalog,
  },
  {
    id: "e-catalog-klaster-detail",
    path: "/e-catalog/public/klaster/detail3/:id",
    component: KlasterDetail3EKatalog,
  },
  // * ======================================================

  // * E-Catalog benchmark
  {
    id: "e-catalog-benchmark",
    label: "Benchmark",
    path: "/e-catalog/public/benchmark",
    component: BenchmarkEKatalog,
    sequence: 5,
    icon: "bx bx-line-chart",
  },

  // * E-Catalog list proposal dosen bersangkutan
  {
    id: "e-catalog-list-proposal-dosen",
    path: "/e-catalog/public/dosen/list-proposal",
    component: ListProposalDosen,
  },
];
