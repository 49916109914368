/* eslint-disable no-unused-vars */
import {
  getMultiFileFromBucket,
  getSingleFileFromBucket,
} from "helpers/getFileFromBucket";
import PDFMerger from "pdf-merger-js/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toast } from "react-toastify";
import util from "helpers/utilities";
import { pdfCreateMain } from "./partials";
import { pdfCreateSptb as engine2 } from "./partials/index";
import { pdfCreateSptbPenelitian as engine3 } from "./partials/index";
import { PDFDocument, rgb, PageSizes } from "pdf-lib";
import utilities from "helpers/utilities";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CetakLaporanKemajuanV2 {
  constructor(data) {
    this.data = data;
    this.indexBlobSkiped = [];
  }

  async getMultiplefile() {
    const data = this.data;
    let files = [];
    let dataBlob = [];

    const { laporan_kemajuan, files_luaran } = data;

    if (laporan_kemajuan?.raw?.url_file_substansi) {
      files.push(laporan_kemajuan?.raw?.url_file_substansi);
    }

    if (laporan_kemajuan?.raw?.url_file_mitra) {
      files.push(laporan_kemajuan?.raw?.url_file_mitra);
    }

    if (files_luaran) {
      files = files.concat(files_luaran);
    }
    if (laporan_kemajuan?.raw?.url_file_penggunaan_anggaran_laporan_kemajuan) {
      files.push(
        laporan_kemajuan?.raw?.url_file_penggunaan_anggaran_laporan_kemajuan
      );
    }
    if (laporan_kemajuan?.raw?.url_file_sptb_laporan_kemajuan) {
      files.push(laporan_kemajuan?.raw?.url_file_sptb_laporan_kemajuan);
    }
    for (const file of files) {
      if (file !== "" && file !== null && file !== undefined) {
        const res = await getSingleFileFromBucket(
          utilities.getFriendlyURL(file)
        );
        if (!res.error) {
          dataBlob.push(res.data);
        }
      }
    }
    return dataBlob;
  }

  // biasa
  async getMultiplefileSptb(files = []) {
    let dataBlob = [];
    for (const file of files) {
      const res = await getMultiFileFromBucket(
        util.getFriendlyURL(file.URLDokumen)
      );
      if (!res.error) {
        dataBlob.push(res.data);
      }
    }
    return dataBlob;
  }
  // ngecek file terenkripsi, file yang terenkripsi akan ditampilkan di user.
  async getMultiplefileSptbWithFileWithEncryptionCheck(files = []) {
    let dataBlob = [];
    let encryptedFileErrors = [];
    for (const file of files) {
      const res = await getMultiFileFromBucket(
        util.getFriendlyURL(file.URLDokumen)
      );
      if (!res.error) {
        dataBlob.push(res.data);
        const isEncrypted = await utilities.checkIfPDFEncrypted(res.data);
        const fileName = utilities.extractFileNameFromURL(file.URLDokumen);
        if (isEncrypted) {
          encryptedFileErrors.push(
            `${fileName} is encrypted (silakan unggah ulang file tersebut pada menu catatan harian)`
          );
        }
      }
    }
    return { blobFileFromServer: dataBlob, encryptedFileErrors };
  }
  // ngecek file terenkripsi, file yang terenkripsi akan diskip.
  async getMultiplefileSptbWithFilesSkippingEncrypted(files = []) {
    let dataBlob = [];
    for (const file of files) {
      const res = await getMultiFileFromBucket(
        util.getFriendlyURL(file.URLDokumen)
      );
      if (!res.error) {
        const isEncrypted = await utilities.checkIfPDFEncrypted(res.data);
        const fileName = utilities.extractFileNameFromURL(file.URLDokumen);
        if (!isEncrypted) {
          dataBlob.push(res.data);
        } else {
          console.log(`File ${fileName} is encrypted, skipping...`);
        }
      }
    }
    return dataBlob;
  }
  generate(jenisKegiatan, flagJenisKegiatan) {
    return new Promise((resolve, _reject) => {
      try {
        let pdfDoc;
        pdfDoc = pdfMake.createPdf(
          pdfCreateMain(this.data, flagJenisKegiatan, jenisKegiatan)
        );

        pdfDoc.getBlob(async blob => {
          resolve(blob);
        });
      } catch (error) {
        _reject(error);
      }
    });
  }

  async generateSptbPengabdian(data) {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine2(data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }

  async generateSptbPenelitian(data) {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine3(data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }

  async mergingv3(docs = [], cb) {
    const pdf = await PDFDocument.create();
    let i = 0;

    try {
      for (const file of docs) {
        const bufferFile = await utilities.blobToUint8Array(file);

        if (!this.indexBlobSkiped.includes(i)) {
          const doc = await PDFDocument.load(bufferFile, {
            ignoreEncryption: true,
            throwOnInvalidObject: true,
          });

          if (doc) {
            const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
            for (const page of contentPage) {
              pdf.addPage(page);
            }
          }
        }
        i++;
      }
      const pdfMerged = await pdf.saveAsBase64();
      const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, "application/pdf");
      cb({ res: pdfMergedAsBlob, error: false });
    } catch (error) {
      if (error.message.includes("invalid object")) {
        this.indexBlobSkiped.push(i);
        cb({ res: undefined, error: true, indexBlobSkipped: i });
      } else {
        cb({ res: undefined, error: true, errMsg: error.message });
      }
    }
  }
  async mergingWithImage(docs = []) {
    const pdf = await PDFDocument.create();
    for (const file of docs) {
      const bufferFile = await utilities.blobToUint8Array(file);
      if (file.type === "image/jpeg" || file.type === "image/png") {
        const page = pdf.addPage(PageSizes.Letter);
        let imageEmbed;
        if (file.type === "image/jpeg") {
          imageEmbed = await pdf.embedJpg(bufferFile);
        } else {
          imageEmbed = await pdf.embedPng(bufferFile);
        }
        const { width, height } = imageEmbed.scaleToFit(
          page.getWidth(),
          page.getHeight()
        );
        // Draw the image on the PDF page.
        page.drawImage(imageEmbed, {
          x: page.getWidth() / 2 - width / 2, // Center the image horizontally.
          y: page.getHeight() / 2 - height / 2, // Center the image vertically.
          width,
          height,
          color: rgb(0, 0, 0), // Set the image color to black.
        });
      } else {
        const doc = await PDFDocument.load(bufferFile, {
          ignoreEncryption: true,
        });
        const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
        for (const page of contentPage) {
          pdf.addPage(page);
        }
      }
    }
    const pdfMerged = await pdf.saveAsBase64();
    const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, "application/pdf");
    return pdfMergedAsBlob;
  }
  async merging(docs = []) {
    const merger = new PDFMerger();
    for (const file of docs) {
      await merger.add(file);
    }
    const mergerPdf = await merger.saveAsBlob();
    return mergerPdf;
  }
  forceDownload(file, fileName, includeDocx) {
    if (includeDocx) {
      // * Force Download
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(file);
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    } else {
      // * Force Download
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(file);
      a.setAttribute("download", `${fileName?.split(".")?.join("_")}`);
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    }
  }
  openAsWindow(file, _filename, type) {
    let binaryData = [];
    binaryData.push(file);

    window.open(
      URL.createObjectURL(new Blob(binaryData, { type: type })),
      "_blank"
    );
  }
}

export default CetakLaporanKemajuanV2;
