export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAILURE = "NOTIFICATION_FAILURE";

export const NOTIFICATION_TYPE_REQUEST = "NOTIFICATION_TYPE_REQUEST";
export const NOTIFICATION_TYPE_SUCCESS = "NOTIFICATION_TYPE_SUCCESS";
export const NOTIFICATION_TYPE_FAILURE = "NOTIFICATION_TYPE_FAILURE";

export const PERSONEL_INVITATION_DETAIL_REQUEST =
  "PERSONEL_INVITATION_DETAIL_REQUEST";
export const PERSONEL_INVITATION_DETAIL_SUCCESS =
  "PERSONEL_INVITATION_DETAIL_SUCCESS";
export const PERSONEL_INVITATION_DETAIL_FAILURE =
  "PERSONEL_INVITATION_DETAIL_FAILURE";

export const NOTIFICATION_COMPLETE_LIST_REQUEST =
  "NOTIFICATION_COMPLETE_LIST_REQUEST";
export const NOTIFICATION_COMPLETE_LIST_SUCCESS =
  "NOTIFICATION_COMPLETE_LIST_SUCCESS";
export const NOTIFICATION_COMPLETE_LIST_FAILURE =
  "NOTIFICATION_COMPLETE_LIST_FAILURE";

export const PATCH_NOTIFICATION_REQUEST = "PATCH_NOTIFICATION_REQUEST";
export const PATCH_NOTIFICATION_SUCCESS = "PATCH_NOTIFICATION_SUCCESS";
export const PATCH_NOTIFICATION_FAILURE = "PATCH_NOTIFICATION_FAILURE";

export const PATCH_PERSONEL_INVITATION_REQUEST =
  "PATCH_PERSONEL_INVITATION_REQUEST";
export const PATCH_PERSONEL_INVITATION_SUCCESS =
  "PATCH_PERSONEL_INVITATION_SUCCESS";
export const PATCH_PERSONEL_INVITATION_FAILURE =
  "PATCH_PERSONEL_INVITATION_FAILURE";

export const PATCH_PERSONEL_INVITATION_V2_REQUEST =
  "PATCH_PERSONEL_INVITATION_V2_REQUEST";
export const PATCH_PERSONEL_INVITATION_V2_SUCCESS =
  "PATCH_PERSONEL_INVITATION_V2_SUCCESS";
export const PATCH_PERSONEL_INVITATION_V2_FAILURE =
  "PATCH_PERSONEL_INVITATION_V2_FAILURE";
