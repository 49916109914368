import {
  CREATE_PENDAFTARAN_REVIEWER_REQUEST,
  CREATE_PENDAFTARAN_REVIEWER_SUCCESS,
  CREATE_PENDAFTARAN_REVIEWER_FAILURE,
  GET_PENDAFTARAN_REVIEWER_FAILURE,
  GET_PENDAFTARAN_REVIEWER_SUCCESS,
  GET_PENDAFTARAN_REVIEWER_REQUEST,
  GET_PENDAFTARAN_REVIEWER_PERSONAL_REQUEST,
  GET_PENDAFTARAN_REVIEWER_PERSONAL_SUCCESS,
  GET_PENDAFTARAN_REVIEWER_PERSONAL_FAILURE,
  VERIFY_PENDAFTARAN_REVIEWER_REQUEST,
  VERIFY_PENDAFTARAN_REVIEWER_FAILURE,
  VERIFY_PENDAFTARAN_REVIEWER_SUCCESS,
} from "./actionTypes";

export const createPendafataranReviewer = (request = {}, cb = () => {}) => {
  return {
    type: CREATE_PENDAFTARAN_REVIEWER_REQUEST,
    payload: request,
    cb,
  };
};

export const createPendafataranReviewerSuccess = response => {
  return {
    type: CREATE_PENDAFTARAN_REVIEWER_SUCCESS,
    payload: response,
  };
};

export const createPendafataranReviewerFailure = (message, response) => {
  return {
    type: CREATE_PENDAFTARAN_REVIEWER_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getPendaftaranReviewer = (request = {}) => {
  return {
    type: GET_PENDAFTARAN_REVIEWER_REQUEST,
    payload: request,
  };
};

export const getPendaftaranReviewerSuccess = response => {
  return {
    type: GET_PENDAFTARAN_REVIEWER_SUCCESS,
    payload: response,
  };
};

export const getPendaftaranReviewerFailure = (message, response) => {
  return {
    type: GET_PENDAFTARAN_REVIEWER_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getPendaftaranPersonalReviewer = (request = {}, cb = () => {}) => {
  return {
    type: GET_PENDAFTARAN_REVIEWER_PERSONAL_REQUEST,
    payload: request,
    cb,
  };
};

export const getPendaftaranPersonalReviewerSuccess = response => {
  return {
    type: GET_PENDAFTARAN_REVIEWER_PERSONAL_SUCCESS,
    payload: response,
  };
};

export const getPendaftaranPersonalReviewerFailure = (message, response) => {
  return {
    type: GET_PENDAFTARAN_REVIEWER_PERSONAL_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getVerifyPendaftaranReviwer = (request = {}, cb = () => {}) => {
  return {
    type: VERIFY_PENDAFTARAN_REVIEWER_REQUEST,
    payload: request,
    cb,
  };
};

export const getVerifyPendaftaranReviwerSuccess = response => {
  return {
    type: VERIFY_PENDAFTARAN_REVIEWER_SUCCESS,
    payload: response,
  };
};

export const getVerifyPendaftaranReviwerFailure = (message, response) => {
  return {
    type: VERIFY_PENDAFTARAN_REVIEWER_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
