import React, { useState } from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table, Button, Modal, Alert } from "reactstrap";

function TableBima({
  crud,
  onAdd,
  title,
  onEdit,
  Form,
  columns,
  onDelete,
  loading = false,
  detailPath = null,
  data = [],
  detailRefId = "id",
  supportFn = {
    toggle: () => {},
  },
  supportData = {},
  formSize = "xl",
  history: historyProps,
  infoLabel = "",
  isGenerateFile = false,
  generateFileRefId,
  handleGenerateFile,
  disabledAdd = false,
  alertMessage = "",
  alertColor = "info",
  hideAddButton = false,
  hideDeleteButton = false,
  hideEditButton = false,
}) {
  const [isShowForm, setIsShowForm] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [selectedItem, setselectedItem] = useState({});
  const [mode, setMode] = useState("add");

  const clickAdd = () => {
    setselectedItem({});
    setIsShowForm(true);
    setMode("add");
  };

  const clickEdit = item => {
    setselectedItem(item);
    setIsShowForm(true);
    setMode("edit");
  };

  const clickDelete = item => {
    setselectedItem(item);
    setIsShowAlert(true);
  };

  return (
    <div>
      {crud &&
        (hideAddButton ? null : (
          <Button
            color="primary"
            size="md"
            disabled={disabledAdd}
            onClick={clickAdd}
          >
            <i className="fa fa-plus" /> Tambah
          </Button>
        ))}
      {alertMessage && (
        <Alert className="mb-0 mt-2" color={alertColor}>
          <span className="fa fa-info-circle" /> {alertMessage}
        </Alert>
      )}
      <div className="table-responsive">
        {infoLabel !== "" && (
          <div className="alert alert-info fade show mt-3" role="alert">
            {infoLabel}
          </div>
        )}
        <Table className="table table-bordered table-striped mt-3">
          <thead>
            <tr>
              <th className="text-center" style={{ width: 40 }}>
                No
              </th>
              {columns &&
                columns.map((col, index) => (
                  <th
                    key={`col-${index}`}
                    style={col.style || {}}
                    className={col.addClass || ""}
                  >
                    {col.label}
                  </th>
                ))}
              {isGenerateFile && <th>Berkas</th>}
              {(crud || detailPath) && <th className="text-center">Aksi</th>}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={columns.length + 3} className="text-center">
                  <i className="fa fa-spinner fa-spin" />
                </td>
              </tr>
            )}
            {data &&
              !loading &&
              data.map((item, index) => (
                <tr
                  key={`tr-${index}`}
                  className={item?.bg ? "table-" + item?.bg : ""}
                >
                  <td className="text-center">{index + 1}</td>
                  {columns &&
                    columns.map((col, indexCol) => (
                      <td
                        style={col.style || {}}
                        key={`td-col-${indexCol}`}
                        className={col.addClass || ""}
                      >
                        {col.customValue
                          ? col.customValue(item, index)
                          : item[col.property] || "-"}
                      </td>
                    ))}
                  {isGenerateFile && (
                    <td>
                      <Button
                        style={{ display: "block", margin: "auto" }}
                        onClick={() =>
                          handleGenerateFile(item[generateFileRefId])
                        }
                        disabled={
                          item.status_unggah === "Sudah Unggah" ||
                          item.status === "Sudah Unggah"
                            ? false
                            : true
                        } // berlaku untuk laporan kemajuan akhir reguler dan prototipe
                        color="primary"
                        size="sm"
                      >
                        <i className="fa fa-download" />
                      </Button>
                    </td>
                  )}
                  {(crud || detailPath) && (
                    <td className="text-center">
                      {crud && !item?.hide_action && (
                        <>
                          {!hideEditButton && (
                            <Button
                              color="warning"
                              outline
                              size="sm"
                              onClick={() => clickEdit(item)}
                            >
                              <i className="fa fa-pen" />
                            </Button>
                          )}{" "}
                          {hideDeleteButton ? null : (
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => clickDelete(item)}
                            >
                              <i className="fa fa-trash" />
                            </Button>
                          )}
                        </>
                      )}
                      {detailPath && (
                        <Button
                          color="primary"
                          outline
                          size="sm"
                          onClick={() =>
                            historyProps.push(
                              `${detailPath}${item[detailRefId]}`
                            )
                          }
                        >
                          <i className="fa fa-pen" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              ))}

            {data && !loading && data.length === 0 && (
              <tr>
                <td colSpan={columns.length + 3} className="text-center">
                  Data tidak tersedia!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <Modal
        size={formSize}
        scrollable
        backdrop={"static"}
        isOpen={isShowForm}
        toggle={() => {
          setIsShowForm(!isShowForm);
          supportFn?.toggle ? supportFn?.toggle() : null;
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0"> {title} - Form </h5>
          <button
            onClick={() => {
              setIsShowForm(false);
              supportFn?.toggle ? supportFn?.toggle() : null;
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {Form && (
            <Form
              item={selectedItem}
              afterSave={() => setIsShowForm(false)}
              supportFn={supportFn}
              supportData={supportData}
              onAdd={onAdd}
              onEdit={onEdit}
              mode={mode}
            />
          )}
        </div>
      </Modal>

      {isShowAlert && (
        <SweetAlert
          title="Apa anda yakin?"
          warning
          showCancel
          confirmButtonText="Ya, hapus data ini!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setIsShowAlert(false);
            onDelete(selectedItem);
          }}
          onCancel={() => setIsShowAlert(false)}
        >
          Data yang sudah dihapus tidak dapat dikembalikan.
        </SweetAlert>
      )}
    </div>
  );
}

TableBima.propTypes = {
  columns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  supportData: PropTypes.object,
  detailPath: PropTypes.string,
  supportFn: PropTypes.object,
  formSize: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  crud: PropTypes.bool,
  Form: PropTypes.any,
  detailRefId: PropTypes.string,
  loading: PropTypes.bool,
  history: PropTypes.any,
  disabledAdd: PropTypes.bool,
  infoLabel: PropTypes.string,
  isGenerateFile: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  handleGenerateFile: PropTypes.func,
  generateFileRefId: PropTypes.string,
  alertMessage: PropTypes.string,
  alertColor: PropTypes.string,
};

export default TableBima;
