import React from 'react';
import { Button } from 'reactstrap';
import Modal from "../Core/Modal";
import PropTypes from "prop-types";

export const AlertConfirm = ({
    isOpen = false,
    toggle,
    handleConfirm,
    title,
    unmountOnClose = true
}) => {
    return (
        <Modal
            isModalOpen={isOpen}
            toggle={toggle}
            shownBtnDeny={false}
            shownBtnOk={false}
            title={title}
            unmountOnClose={unmountOnClose}
        >
            <Button onClick={handleConfirm} value={1} name="confirm" color="primary">
                Ya
            </Button>{' '}
            <Button color="secondary" name="confirm" value={0} onClick={handleConfirm}>
                Tidak
            </Button>

        </Modal>
    )
}

AlertConfirm.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    handleConfirm: PropTypes.func,
    title: PropTypes.string,
    unmountOnClose: PropTypes.bool
}
