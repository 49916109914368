import {
  SEARCH_PROPOSAL_E_CATALOG_REQUEST,
  SEARCH_PROPOSAL_E_CATALOG_SUCCESS,
  SEARCH_PROPOSAL_E_CATALOG_FAILURE,
  GET_DETAIL_PROPOSAL_E_CATALOG_REQUEST,
  GET_DETAIL_PROPOSAL_E_CATALOG_SUCCESS,
  GET_DETAIL_PROPOSAL_E_CATALOG_FAILURE,
  GET_REPORT_PROPOSAL_E_CATALOG_REQUEST,
  GET_REPORT_PROPOSAL_E_CATALOG_SUCCESS,
  GET_REPORT_PROPOSAL_E_CATALOG_FAILURE,
  SEARCH_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  SEARCH_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  SEARCH_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  CREATE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  CREATE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  CREATE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  DELETE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  DELETE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  DELETE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_SUCCESS,
  GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_FAILURE,
  GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_DANA_PPM_PER_YEAR_E_CATALOG_REQUEST,
  GET_DANA_PPM_PER_YEAR_E_CATALOG_SUCCESS,
  GET_DANA_PPM_PER_YEAR_E_CATALOG_FAILURE,
  RESET_PROPOSAL_E_CATALOG,
  SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_REQUEST,
  SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_SUCCESS,
  SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_FAILURE,
  GET_REPORT_PPM_E_CATALOG_REQUEST,
  GET_REPORT_PPM_E_CATALOG_SUCCESS,
  GET_REPORT_PPM_E_CATALOG_FAILURE,
  GET_JABATAN_DOSEN_E_CATALOG_REQUEST,
  GET_JABATAN_DOSEN_E_CATALOG_SUCCESS,
  GET_JABATAN_DOSEN_E_CATALOG_FAILURE,
  GET_LIST_DOSEN_E_CATALOG_REQUEST,
  GET_LIST_DOSEN_E_CATALOG_SUCCESS,
  GET_LIST_DOSEN_E_CATALOG_FAILURE,
  GET_LIST_JUMLAH_LUARAN_E_CATALOG_REQUEST,
  GET_LIST_JUMLAH_LUARAN_E_CATALOG_SUCCESS,
  GET_LIST_JUMLAH_LUARAN_E_CATALOG_FAILURE,
  GET_REPORT_LIST_DOSEN_E_CATALOG_REQUEST,
  GET_REPORT_LIST_DOSEN_E_CATALOG_SUCCESS,
  GET_REPORT_LIST_DOSEN_E_CATALOG_FAILURE,
  RESET_PROPOSAL_SPESIFIK_E_CATALOG,
  GET_LIST_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_LIST_BIDANG_FOKUS_E_CATALOG_SUCCESS,
  GET_LIST_BIDANG_FOKUS_E_CATALOG_FAILURE,
  GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_SUCCESS,
  GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_FAILURE,
  GET_TOTAL_KLASTER_E_CATALOG_REQUEST,
  GET_TOTAL_KLASTER_E_CATALOG_SUCCESS,
  GET_TOTAL_KLASTER_E_CATALOG_FAILURE,
  GET_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
  GET_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS,
  GET_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE,
  REPORT_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
  REPORT_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS,
  REPORT_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE,
  GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  GET_LIST_BENCHMARK_PPM_E_CATALOG_REQUEST,
  GET_LIST_BENCHMARK_PPM_E_CATALOG_SUCCESS,
  GET_LIST_BENCHMARK_PPM_E_CATALOG_FAILURE,
  GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_COMPARE_BENCHMARK_E_CATALOG_REQUEST,
  GET_COMPARE_BENCHMARK_E_CATALOG_SUCCESS,
  GET_COMPARE_BENCHMARK_E_CATALOG_FAILURE,
  GET_LLDIKTI_MAPS_E_CATALOG_REQUEST,
  GET_LLDIKTI_MAPS_E_CATALOG_SUCCESS,
  GET_LLDIKTI_MAPS_E_CATALOG_FAILURE,
  EXPORT_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  EXPORT_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  EXPORT_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_REQUEST,
  GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_SUCCESS,
  GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_FAILURE,
  RESET_REPORT_PROPOSAL_E_CATALOG,
} from "./actionTypes";

const initialState = {
  searchProposalECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  searchSpesifikProposalECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  reportProposalECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  detailProposalECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  searchPerguruanTinggiECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  exportPerguruanTinggiECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  totalPpmPerguruanTinggiECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listKeywordManagementECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  dropdownKeywordManagementECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  createKeywordManagementECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  detailKeywordManagementECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  deleteKeywordManagementECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  danaPpmPerBidangFokusECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  danaPpmPerYearECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listPpmPerguruanTinggiECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  reportPpmECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listJabatanDosenECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listDosenECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listRepotDosenECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listJumlahLuaranECatalog: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listBidangFokus: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  danaPpmBidangFokus: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  totalKlaster: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listPtByKlaster: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  reportListPtByKlaster: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listBenchmarkPpm: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  compareBenchmark: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  listDropdownPerguruanTinggi: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  lldiktiMaps: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  detailProposalForPdf: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
};

const eCatalog = (state = initialState, action) => {
  switch (action.type) {
    // Search proposal e katalog
    case SEARCH_PROPOSAL_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        searchProposalECatalog: {
          ...state.searchProposalECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case SEARCH_PROPOSAL_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        searchProposalECatalog: {
          ...state.searchProposalECatalog,
          isFetching: false,
          items: action.payload,
        },
      });
    case SEARCH_PROPOSAL_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        searchProposalECatalog: {
          ...state.searchProposalECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // Search proposal e katalog
    case SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        searchSpesifikProposalECatalog: {
          ...state.searchSpesifikProposalECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        searchSpesifikProposalECatalog: {
          ...state.searchSpesifikProposalECatalog,
          isFetching: false,
          items: action.payload,
        },
      });
    case SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        searchSpesifikProposalECatalog: {
          ...state.searchSpesifikProposalECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // reset spesifik proposal
    case RESET_PROPOSAL_SPESIFIK_E_CATALOG:
      return (state = {
        ...state,
        searchSpesifikProposalECatalog: {
          ...state.searchSpesifikProposalECatalog,
          isFetching: false,
          item: {},
          items: [],
        },
      });
    // reset proposal e katalog
    case RESET_PROPOSAL_E_CATALOG:
      return (state = {
        ...state,
        searchProposalECatalog: {
          ...state.searchProposalECatalog,
          isFetching: false,
          item: {},
          items: [],
        },
      });
    // reset repot
    case RESET_REPORT_PROPOSAL_E_CATALOG:
      return (state = {
        ...state,
        reportProposalECatalog: {
          ...state.reportProposalECatalog,
          isFetching: false,
          item: {},
          items: [],
        },
      });
    // report proposal e katalog
    case GET_REPORT_PROPOSAL_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        reportProposalECatalog: {
          ...state.reportProposalECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_REPORT_PROPOSAL_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        reportProposalECatalog: {
          ...state.reportProposalECatalog,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_REPORT_PROPOSAL_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        reportProposalECatalog: {
          ...state.reportProposalECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // detail proposal e katalog
    case GET_DETAIL_PROPOSAL_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        detailProposalECatalog: {
          ...state.detailProposalECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_DETAIL_PROPOSAL_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        detailProposalECatalog: {
          ...state.detailProposalECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_DETAIL_PROPOSAL_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        detailProposalECatalog: {
          ...state.detailProposalECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // search perguruan tinggi e katalog
    case SEARCH_PERGURUAN_TINGGI_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        searchPerguruanTinggiECatalog: {
          ...state.searchPerguruanTinggiECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case SEARCH_PERGURUAN_TINGGI_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        searchPerguruanTinggiECatalog: {
          ...state.searchPerguruanTinggiECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case SEARCH_PERGURUAN_TINGGI_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        searchPerguruanTinggiECatalog: {
          ...state.searchPerguruanTinggiECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // export perguruan tinggi e katalog
    case EXPORT_PERGURUAN_TINGGI_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        exportPerguruanTinggiECatalog: {
          ...state.exportPerguruanTinggiECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case EXPORT_PERGURUAN_TINGGI_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        exportPerguruanTinggiECatalog: {
          ...state.exportPerguruanTinggiECatalog,
          isFetching: false,
          items: action.payload,
        },
      });
    case EXPORT_PERGURUAN_TINGGI_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        exportPerguruanTinggiECatalog: {
          ...state.exportPerguruanTinggiECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // total ppm perguruan tinggi e katalog
    case GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        totalPpmPerguruanTinggiECatalog: {
          ...state.totalPpmPerguruanTinggiECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        totalPpmPerguruanTinggiECatalog: {
          ...state.totalPpmPerguruanTinggiECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        totalPpmPerguruanTinggiECatalog: {
          ...state.totalPpmPerguruanTinggiECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // list keyword management
    case GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listKeywordManagementECatalog: {
          ...state.listKeywordManagementECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listKeywordManagementECatalog: {
          ...state.listKeywordManagementECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listKeywordManagementECatalog: {
          ...state.listKeywordManagementECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // dropdown keyword management
    case GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        dropdownKeywordManagementECatalog: {
          ...state.dropdownKeywordManagementECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        dropdownKeywordManagementECatalog: {
          ...state.dropdownKeywordManagementECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        dropdownKeywordManagementECatalog: {
          ...state.dropdownKeywordManagementECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // create keyword management
    case CREATE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        createKeywordManagementECatalog: {
          ...state.createKeywordManagementECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case CREATE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        createKeywordManagementECatalog: {
          ...state.createKeywordManagementECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case CREATE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        createKeywordManagementECatalog: {
          ...state.createKeywordManagementECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // detail keyword management
    case GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        detailKeywordManagementECatalog: {
          ...state.detailKeywordManagementECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        detailKeywordManagementECatalog: {
          ...state.detailKeywordManagementECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        detailKeywordManagementECatalog: {
          ...state.detailKeywordManagementECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // delete keyword management
    case DELETE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        deleteKeywordManagementECatalog: {
          ...state.deleteKeywordManagementECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case DELETE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        deleteKeywordManagementECatalog: {
          ...state.deleteKeywordManagementECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case DELETE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        deleteKeywordManagementECatalog: {
          ...state.deleteKeywordManagementECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // get dana ppm per bidang fokus
    case GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        danaPpmPerBidangFokusECatalog: {
          ...state.danaPpmPerBidangFokusECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        danaPpmPerBidangFokusECatalog: {
          ...state.danaPpmPerBidangFokusECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        danaPpmPerBidangFokusECatalog: {
          ...state.danaPpmPerBidangFokusECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // list ppm perguruan tinggi
    case GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listPpmPerguruanTinggiECatalog: {
          ...state.listPpmPerguruanTinggiECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listPpmPerguruanTinggiECatalog: {
          ...state.listPpmPerguruanTinggiECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listPpmPerguruanTinggiECatalog: {
          ...state.listPpmPerguruanTinggiECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // dana ppm per year
    case GET_DANA_PPM_PER_YEAR_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        danaPpmPerYearECatalog: {
          ...state.danaPpmPerYearECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_DANA_PPM_PER_YEAR_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        danaPpmPerYearECatalog: {
          ...state.danaPpmPerYearECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_DANA_PPM_PER_YEAR_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        danaPpmPerYearECatalog: {
          ...state.danaPpmPerYearECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // report ppm
    case GET_REPORT_PPM_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        reportPpmECatalog: {
          ...state.reportPpmECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_REPORT_PPM_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        reportPpmECatalog: {
          ...state.reportPpmECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_REPORT_PPM_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        reportPpmECatalog: {
          ...state.reportPpmECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // list jabatan dosen
    case GET_JABATAN_DOSEN_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listJabatanDosenECatalog: {
          ...state.listJabatanDosenECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_JABATAN_DOSEN_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listJabatanDosenECatalog: {
          ...state.listJabatanDosenECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_JABATAN_DOSEN_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listJabatanDosenECatalog: {
          ...state.listJabatanDosenECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // list  dosen
    case GET_LIST_DOSEN_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listDosenECatalog: {
          ...state.listDosenECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_DOSEN_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listDosenECatalog: {
          ...state.listDosenECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_LIST_DOSEN_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listDosenECatalog: {
          ...state.listDosenECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // list  report dosen
    case GET_REPORT_LIST_DOSEN_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listRepotDosenECatalog: {
          ...state.listRepotDosenECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_REPORT_LIST_DOSEN_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listRepotDosenECatalog: {
          ...state.listRepotDosenECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_REPORT_LIST_DOSEN_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listRepotDosenECatalog: {
          ...state.listRepotDosenECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // list  jumlah luaran
    case GET_LIST_JUMLAH_LUARAN_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listJumlahLuaranECatalog: {
          ...state.listJumlahLuaranECatalog,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_JUMLAH_LUARAN_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listJumlahLuaranECatalog: {
          ...state.listJumlahLuaranECatalog,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_LIST_JUMLAH_LUARAN_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listJumlahLuaranECatalog: {
          ...state.listJumlahLuaranECatalog,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // get list bidang fokus
    case GET_LIST_BIDANG_FOKUS_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listBidangFokus: {
          ...state.listBidangFokus,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_BIDANG_FOKUS_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listBidangFokus: {
          ...state.listBidangFokus,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_LIST_BIDANG_FOKUS_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listBidangFokus: {
          ...state.listBidangFokus,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // dana ppm bidang fokus
    case GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        danaPpmBidangFokus: {
          ...state.danaPpmBidangFokus,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        danaPpmBidangFokus: {
          ...state.danaPpmBidangFokus,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        danaPpmBidangFokus: {
          ...state.danaPpmBidangFokus,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // total kklaster
    case GET_TOTAL_KLASTER_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        totalKlaster: {
          ...state.totalKlaster,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_TOTAL_KLASTER_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        totalKlaster: {
          ...state.totalKlaster,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_TOTAL_KLASTER_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        totalKlaster: {
          ...state.totalKlaster,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });

    // list pt by klaster
    case GET_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listPtByKlaster: {
          ...state.listPtByKlaster,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listPtByKlaster: {
          ...state.listPtByKlaster,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case GET_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listPtByKlaster: {
          ...state.listPtByKlaster,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // report list kklaster
    case REPORT_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        reportListPtByKlaster: {
          ...state.reportListPtByKlaster,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case REPORT_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        reportListPtByKlaster: {
          ...state.reportListPtByKlaster,
          isFetching: false,
          items: action.payload,
          item: action.payload,
        },
      });
    case REPORT_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        reportListPtByKlaster: {
          ...state.reportListPtByKlaster,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    // list benchmark
    case GET_LIST_BENCHMARK_PPM_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listBenchmarkPpm: {
          ...state.listBenchmarkPpm,
          isFetching: true,
          items: [],
        },
      });
    case GET_LIST_BENCHMARK_PPM_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listBenchmarkPpm: {
          ...state.listBenchmarkPpm,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_LIST_BENCHMARK_PPM_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listBenchmarkPpm: {
          ...state.listBenchmarkPpm,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //compare benchmark ppm
    case GET_COMPARE_BENCHMARK_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        compareBenchmark: {
          ...state.compareBenchmark,
          isFetching: true,
          items: [],
        },
      });
    case GET_COMPARE_BENCHMARK_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        compareBenchmark: {
          ...state.compareBenchmark,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_COMPARE_BENCHMARK_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        compareBenchmark: {
          ...state.compareBenchmark,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //get dropdown perguruan tinggi
    case GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        listDropdownPerguruanTinggi: {
          ...state.listDropdownPerguruanTinggi,
          isFetching: true,
          items: [],
        },
      });
    case GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        listDropdownPerguruanTinggi: {
          ...state.listDropdownPerguruanTinggi,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        listDropdownPerguruanTinggi: {
          ...state.listDropdownPerguruanTinggi,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //get lldikti maps
    case GET_LLDIKTI_MAPS_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        lldiktiMaps: {
          ...state.lldiktiMaps,
          isFetching: true,
          items: [],
        },
      });
    case GET_LLDIKTI_MAPS_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        lldiktiMaps: {
          ...state.lldiktiMaps,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_LLDIKTI_MAPS_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        lldiktiMaps: {
          ...state.lldiktiMaps,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //get detail propsal for pdf
    case GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_REQUEST:
      return (state = {
        ...state,
        detailProposalForPdf: {
          ...state.detailProposalForPdf,
          isFetching: true,
          items: [],
        },
      });
    case GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_SUCCESS:
      return (state = {
        ...state,
        detailProposalForPdf: {
          ...state.detailProposalForPdf,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_FAILURE:
      return (state = {
        ...state,
        detailProposalForPdf: {
          ...state.detailProposalForPdf,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    default:
      return state;
  }
};

export default eCatalog;
