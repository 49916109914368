import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

function TooltipItem(props) {
    const { placement = "top", id, title } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <Tooltip
            placement={placement}
            isOpen={tooltipOpen}
            target={'tooltip-' + id}
            toggle={toggle}
        >
            {title}
        </Tooltip>

    );
}

TooltipItem.propTypes = {
    placement: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string
};

export default TooltipItem;