import { call, put, takeEvery } from "redux-saga/effects";
import {
  getListInstitusiPenilaianLuaranDetail1Failure,
  getListInstitusiPenilaianLuaranDetail1Success,
  getListInstitusiPenilaianLuaranDetail2Failure,
  getListInstitusiPenilaianLuaranDetail2Success,
  getListInstitusiPenilaianLuaranFailure,
  getListInstitusiPenilaianLuaranSuccess,
  savePenilaianLuaranEvaluasiFailure,
  savePenilaianLuaranEvaluasiSuccess,
  savePenilaianLuaranToPermanenFailure,
  savePenilaianLuaranToPermanenSuccess,
} from "./action";
import {
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_REQUEST,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_REQUEST,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_REQUEST,
  SAVE_PENILAIAN_LUARAN_EVALUASI_REQUEST,
  SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_REQUEST,
} from "./actionTypes";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";
function* getListInstitusiPenilaianLuaran({ payload }) {
  try {
    const response = yield call(
      backend.getListInstitusiPenilaianLuaran,
      payload
    );
    if (response.data.code == 200) {
      yield put(getListInstitusiPenilaianLuaranSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getListInstitusiPenilaianLuaranFailure(message, error));
  }
}
function* getListInstitusiPenilaianLuaranDetail1({ payload }) {
  try {
    const response = yield call(
      backend.getListInstitusiPenilaianLuaranDetail1,
      payload
    );
    if (response.data.code == 200) {
      yield put(
        getListInstitusiPenilaianLuaranDetail1Success(response.data.data)
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getListInstitusiPenilaianLuaranDetail1Failure(message, error));
  }
}
function* getListInstitusiPenilaianLuaranDetail2({ payload }) {
  try {
    const response = yield call(
      backend.getListInstitusiPenilaianLuaranDetail2,
      payload
    );
    if (response.data.code == 200) {
      yield put(
        getListInstitusiPenilaianLuaranDetail2Success(response.data.data)
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getListInstitusiPenilaianLuaranDetail2Failure(message, error));
  }
}
function* savePenilaianLuaranEvaluasi({ payload, cb }) {
  try {
    const response = yield call(backend.savePenilaianLuaranEvaluasi, payload);
    if (response.data.code == 200) {
      yield put(savePenilaianLuaranEvaluasiSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(savePenilaianLuaranEvaluasiFailure(message, error));
    cb(null);
  }
}
function* savePenilaianLuaranToPermanen({ payload, cb }) {
  try {
    const response = yield call(backend.savePenilaianLuaranToPermanen, payload);
    if (response.data.code == 200) {
      yield put(savePenilaianLuaranToPermanenSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(savePenilaianLuaranToPermanenFailure(message, error));
    cb(null);
  }
}
function* penilaianLuaranSaga() {
  yield takeEvery(
    GET_LIST_INSTITUSI_PENILAIAN_LUARAN_REQUEST,
    getListInstitusiPenilaianLuaran
  );
  yield takeEvery(
    GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_REQUEST,
    getListInstitusiPenilaianLuaranDetail1
  );
  yield takeEvery(
    GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_REQUEST,
    getListInstitusiPenilaianLuaranDetail2
  );
  yield takeEvery(
    SAVE_PENILAIAN_LUARAN_EVALUASI_REQUEST,
    savePenilaianLuaranEvaluasi
  );
  yield takeEvery(
    SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_REQUEST,
    savePenilaianLuaranToPermanen
  );
}

export default penilaianLuaranSaga;
