import {
    CREATE_PENUGASAN_PEMBELAJARAN_FAILURE,
    CREATE_PENUGASAN_PEMBELAJARAN_REQUEST,
    CREATE_PENUGASAN_PEMBELAJARAN_SUCCESS,
    GET_PENUGASAN_PEMBELAJARAN_BYID_FAILURE,
    GET_PENUGASAN_PEMBELAJARAN_BYID_REQUEST,
    GET_PENUGASAN_PEMBELAJARAN_BYID_SUCCESS,
    GET_PENUGASAN_PEMBELAJARAN_FAILURE,
    GET_PENUGASAN_PEMBELAJARAN_REQUEST,
    GET_PENUGASAN_PEMBELAJARAN_SUCCESS,
    UPDATE_PENUGASAN_PEMBELAJARAN_FAILURE,
    UPDATE_PENUGASAN_PEMBELAJARAN_REQUEST,
    UPDATE_PENUGASAN_PEMBELAJARAN_SUCCESS
} from "../actionTypes";

export const createPenugasanPembelajaran = (request = {}, cb = () => { }) => {
    return {
        type: CREATE_PENUGASAN_PEMBELAJARAN_REQUEST,
        payload: request,
        cb
    };
}

export const createPenugasanPembelajaranSuccess = response => {
    return {
        type: CREATE_PENUGASAN_PEMBELAJARAN_SUCCESS,
        payload: response,
    };
}

export const createPenugasanPembelajaranFailure = (message, response) => {
    return {
        type: CREATE_PENUGASAN_PEMBELAJARAN_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanPembelajaran = (request = {}) => {
    return {
        type: GET_PENUGASAN_PEMBELAJARAN_REQUEST,
        payload: request,
    };
}

export const getPenugasanPembelajaranSuccess = response => {
    return {
        type: GET_PENUGASAN_PEMBELAJARAN_SUCCESS,
        payload: response,
    };
}

export const getPenugasanPembelajaranFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_PEMBELAJARAN_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanPembelajaranById = (id, cb = () => { }) => {

    return {
        type: GET_PENUGASAN_PEMBELAJARAN_BYID_REQUEST,
        payload: { id },
        cb
    };
}

export const getPenugasanPembelajaranByIdSuccess = response => {
    return {
        type: GET_PENUGASAN_PEMBELAJARAN_BYID_SUCCESS,
        payload: response,
    };
}

export const getPenugasanPembelajaranByIdFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_PEMBELAJARAN_BYID_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const updatePenugasanPembelajaran = (request = {}, id, cb = () => { }) => {
    return {
        type: UPDATE_PENUGASAN_PEMBELAJARAN_REQUEST,
        payload: request,
        cb,
        id
    };
}

export const updatePenugasanPembelajaranSuccess = response => {
    return {
        type: UPDATE_PENUGASAN_PEMBELAJARAN_SUCCESS,
        payload: response,
    };
}

export const updatePenugasanPembelajaranFailure = (message, response) => {
    return {
        type: UPDATE_PENUGASAN_PEMBELAJARAN_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}