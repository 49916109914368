import {
  BERITA_REQUEST,
  BERITA_SUCCESS,
  BERITA_FAILURE,
  BERITA_LANDING_REQUEST,
  BERITA_LANDING_SUCCESS,
  BERITA_LANDING_FAILURE,
  BERITA_BY_ID_REQUEST,
  BERITA_BY_ID_SUCCESS,
  BERITA_BY_ID_FAILURE,
  CREATE_BERITA_REQUEST,
  CREATE_BERITA_SUCCESS,
  CREATE_BERITA_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  DELETE_BERITA_REQUEST,
  DELETE_BERITA_SUCCESS,
  UPDATE_BERITA_REQUEST,
  UPDATE_BERITA_SUCCESS,
  UPDATE_BERITA_FAILURE,
  DELETE_IMG_BERITA_REQUEST,
  DELETE_IMG_BERITA_SUCCESS,
  DELETE_IMG_BERITA_FAILURE,
} from "./actionType";

// GET BERITA PAGES START
export const getBerita = response => {
  return {
    type: BERITA_REQUEST,
    payload: response,
  };
};

export const getBeritaSuccess = (response, state) => {
  return {
    type: BERITA_SUCCESS,
    payload: response,
    state,
  };
};

export const getBeritaFailure = (response, message) => {
  return {
    type: BERITA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// GET BERITA PAGES END

// GET BERITA FOR LANDING PAGES START
export const getBeritaLanding = response => {
  return {
    type: BERITA_LANDING_REQUEST,
    payload: response,
  };
};

export const getBeritaLandingSuccess = (response, state) => {
  return {
    type: BERITA_LANDING_SUCCESS,
    payload: response,
    state,
  };
};

export const getBeritaLandingFailure = (response, message) => {
  return {
    type: BERITA_LANDING_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// GET BERITA FOR LANDING PAGES END

// GET BERITA BY ID START
export const getBeritaById = response => {
  return {
    type: BERITA_BY_ID_REQUEST,
    payload: response,
  };
};

export const getBeritaByIdSuccess = (response, state) => {
  return {
    type: BERITA_BY_ID_SUCCESS,
    payload: response,
    state,
  };
};

export const getBeritaByIdFailure = (response, message) => {
  return {
    type: BERITA_BY_ID_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// GET BERITA BY ID END

// CREATE BERITA START
export const createBerita = (request, cb) => {
  return {
    type: CREATE_BERITA_REQUEST,
    payload: request,
    cb,
  };
};

export const createBeritaSuccess = response => {
  return {
    type: CREATE_BERITA_SUCCESS,
    payload: response,
  };
};

export const createBeritaFailure = (response, message) => {
  return {
    type: CREATE_BERITA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// CREATE BERITA END

// UPLOAD FILE START
export const uploadBerita = request => {
  return {
    type: UPLOAD_FILE_REQUEST,
    payload: request,
  };
};

export const uploadBeritaSuccess = response => {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload: response,
  };
};

export const uploadBeritaFailure = (response, message) => {
  return {
    type: UPLOAD_FILE_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// UPLOAD FILR END

// DELETE BERITA START
export const deleteBerita = (request, cb) => {
  return {
    type: DELETE_BERITA_REQUEST,
    payload: request,
    cb,
  };
};

export const deleteBeritaSuccess = (response, state) => {
  return {
    type: DELETE_BERITA_SUCCESS,
    payload: response,
    state,
  };
};
// DELETE BERITA END

// UPDATE BERITA START
export const updateBerita = (request, cb) => {
  return {
    type: UPDATE_BERITA_REQUEST,
    payload: request,
    cb,
  };
};

export const updateBeritaSuccess = (response, state) => {
  return {
    type: UPDATE_BERITA_SUCCESS,
    payload: response,
    state,
  };
};

export const updateBeritaFailure = (response, message) => {
  return {
    type: UPDATE_BERITA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// UPDATE BERITA END

// DELETE IMG BERITA START

export const deleteImgBerita = (request, cb) => {
  return {
    type: DELETE_IMG_BERITA_REQUEST,
    payload: request,
    cb,
  };
};

export const deleteImgBeritaSuccess = (response, state) => {
  return {
    type: DELETE_IMG_BERITA_SUCCESS,
    payload: response,
    state,
  };
};

export const deleteImgBeritaFailure = (response, message) => {
  return {
    type: DELETE_IMG_BERITA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// DELETE IMG BERITA END
