const { GET_TEMPLATES_DOCS_REQUEST, GET_TEMPLATES_DOCS_SUCCESS, GET_TEMPLATES_DOCS_FAILURE, CLEAN_TEMPLATES_DOCS } = require("./actionTypes");

const initialState = {
   template_docs: {
      items: [],
      isFetching: false,
   },
}

const enumerations = (state = initialState, action) => {
   switch (action.type) {
      case GET_TEMPLATES_DOCS_REQUEST:
         return (state = {
            ...state,
            template_docs: {
               ...state.template_docs,
               isFetching: true
            },
         });
      case GET_TEMPLATES_DOCS_SUCCESS:
         return (state = {
            ...state,
            template_docs: {
               ...state.template_docs,
               isFetching: false,
               items: action.payload
            },
         });
      case GET_TEMPLATES_DOCS_FAILURE:
         return (state = {
            ...state,
            template_docs: {
               ...state.template_docs,
               isFetching: false,
               items: action.payload,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case CLEAN_TEMPLATES_DOCS:
         return (state = {
            ...state,
            template_docs: {
               items: [],
               isFetching: false,
            },
         });

      default:
         return state;
   }
}

export default enumerations;