import {
   CLEAN_LIST_PROPOSAL_BIMTEK,
   GET_LIST_PROPOSAL_BIMTEK_FAILURE,
   GET_LIST_PROPOSAL_BIMTEK_REQUEST,
   GET_LIST_PROPOSAL_BIMTEK_SUCCESS,
   GET_PROPOSAL_BIMTEK_FAILURE,
   GET_PROPOSAL_BIMTEK_REQUEST,
   GET_PROPOSAL_BIMTEK_SUCCESS,
   UPDATE_PROPOSAL_BIMTEK_FAILURE,
   UPDATE_PROPOSAL_BIMTEK_REQUEST,
   UPDATE_PROPOSAL_BIMTEK_SUCCESS
} from "./actionTypes";

const initialState = {
   list_bimtek_proposal: {
      items: [],
   
      isFetching: false,
      metaData: {
         "total_data": 0,
         "page": 0,
         "limit": 0,
         "prev_page": null,
         "next_page": 0,
         "last_page": 0
      }
   },
   bimtek_proposal: {
      item: {},
      isFetching: false,
   },
   isProcessSaving: false
}

const bimtekProposal = (state = initialState, action) => {

   switch (action.type) {
      case GET_LIST_PROPOSAL_BIMTEK_REQUEST:
         return (state = {
            ...state,
            list_bimtek_proposal: {
               ...state.list_bimtek_proposal,
               isFetching: true,
            }
         });
      case GET_LIST_PROPOSAL_BIMTEK_SUCCESS:
         return (state = {
            ...state,
            list_bimtek_proposal: {
               ...state.list_bimtek_proposal,
               isFetching: false,
               items: action.payload.data,
               metaData: action.payload.meta
            },
         });
      case GET_LIST_PROPOSAL_BIMTEK_FAILURE:
         return (state = {
            ...state,
            list_bimtek_proposal: {
               ...state.list_bimtek_proposal,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            }
         })
      case CLEAN_LIST_PROPOSAL_BIMTEK:
         return (state = {
            ...state,
            list_bimtek_proposal: {
               ...state.list_bimtek_proposal,
               isFetching: false,
               errors: null,
               errorMessage: '',
               items: [],
            }
         })
      case GET_PROPOSAL_BIMTEK_REQUEST:
         return (state = {
            ...state,
            bimtek_proposal: {
               ...state.bimtek_proposal,
               isFetching: true,
            }
         });
      case GET_PROPOSAL_BIMTEK_SUCCESS:
         return (state = {
            ...state,
            bimtek_proposal: {
               ...state.bimtek_proposal,
               item: action.payload.data,
               isFetching: false,
            }
         });
      case GET_PROPOSAL_BIMTEK_FAILURE:
         return (state = {
            ...state,
            bimtek_proposal: {
               ...state.bimtek_proposal,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            }
         });
      case UPDATE_PROPOSAL_BIMTEK_REQUEST:
         return (state = {
            ...state,
            isProcessSaving: true,
         });
      case UPDATE_PROPOSAL_BIMTEK_SUCCESS:
         return (state = {
            ...state,
            bimtek_proposal: {
               ...state.bimtek_proposal,
               item: action.payload.data
            },
            isProcessSaving: false,
         });
      case UPDATE_PROPOSAL_BIMTEK_FAILURE:
         return (state = {
            ...state,
            isProcessSaving: false,
         });
      default:
         return state;
   }
}

export default bimtekProposal;