/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";
import {
  getOperatorPtDashboardTktSuccess,
  getOperatorPtDashboardTktFailure,
  crudPendaftaranAkunOperatorPtSuccess,
  crudPendaftaranAkunOperatorPtFailure,
  checkAkunPendaftaranAkunOperatorPtSuccess,
  checkAkunPendaftaranAkunOperatorPtFailure,
  getListKlasterPtSuccess,
  getListKlasterPtFailure,
  processPendaftaranAkunPtSuccess,
  processPendaftaranAkunPtFailure,
} from "./action";
import {
  CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
  CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
  GET_LIST_KLASTER_PT_REQUEST,
  OPERATOR_PT_DASHBOARD_TKT_REQUEST,
  PROCESS_PENDAFTARAN_AKUN_PT_REQUEST,
} from "./actionTypes";
import { toast } from "react-toastify";
function* getOperatorPtDashboardTkt({ payload }) {
  try {
    const params = payload;
    const response = yield call(backend.getOperatorPtDashboardTkt, params);
    if (response.data.code == 200) {
      yield put(getOperatorPtDashboardTktSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getOperatorPtDashboardTktFailure(message, error));
  }
}
function* crudPendaftaranAkunOperatorPt({ payload, cb }) {
  try {
    const res = yield call(backend.crudPendaftaranAkunOperatorPt, payload);
    if (res.data.code === 200) {
      yield put(crudPendaftaranAkunOperatorPtSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(crudPendaftaranAkunOperatorPtFailure(message?.error));
  }
}
function* checkAkunPendaftaranAkunOperatorPt({ payload, cb }) {
  try {
    const res = yield call(backend.crudPendaftaranAkunOperatorPt, payload);
    if (res.data.code === 200) {
      yield put(
        checkAkunPendaftaranAkunOperatorPtSuccess({ data: res.data.data })
      );
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(checkAkunPendaftaranAkunOperatorPtFailure(message?.error));
  }
}
function* getListKlasterPt({ payload, cb }) {
  try {
    const res = yield call(backend.getListKlasterPt, payload);
    if (res.data.code === 200) {
      yield put(getListKlasterPtSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListKlasterPtFailure(message?.error));
  }
}
function* processPendaftaranAkunPt({ payload, cb }) {
  try {
    const res = yield call(backend.processPendaftaranAkunPt, payload);
    if (res.data.code === 200) {
      yield put(processPendaftaranAkunPtSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    yield put(processPendaftaranAkunPtFailure(message?.error));
    cb({
      success: false,
      message,
    });
  }
}
function* operatorPtDashboardSaga() {
  yield takeEvery(OPERATOR_PT_DASHBOARD_TKT_REQUEST, getOperatorPtDashboardTkt);
  yield takeEvery(
    CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
    crudPendaftaranAkunOperatorPt
  );
  yield takeEvery(
    CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
    checkAkunPendaftaranAkunOperatorPt
  );
  yield takeEvery(GET_LIST_KLASTER_PT_REQUEST, getListKlasterPt);
  yield takeEvery(
    PROCESS_PENDAFTARAN_AKUN_PT_REQUEST,
    processPendaftaranAkunPt
  );
}

export default operatorPtDashboardSaga;
