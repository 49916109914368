export const GET_LIST_PROTOTIPE_REQUEST = "GET_LIST_PROTOTIPE_REQUEST";
export const GET_LIST_PROTOTIPE_SUCCESS = "GET_LIST_PROTOTIPE_SUCCESS";
export const GET_LIST_PROTOTIPE_FAILURE = "GET_LIST_PROTOTIPE_FAILURE";

export const GET_BYID_PROTOTIPE_REQUEST = "GET_BYID_PROTOTIPE_REQUEST";
export const GET_BYID_PROTOTIPE_SUCCESS = "GET_BYID_PROTOTIPE_SUCCESS";
export const GET_BYID_PROTOTIPE_FAILURE = "GET_BYID_PROTOTIPE_FAILURE";
export const CLEAN_DETAILS_PROPOSAL = 'CLEAN_DETAILS_PROPOSAL';

export const SAVE_PROTOTIPE_REQUEST = "SAVE_PROTOTIPE_REQUEST";
export const SAVE_PROTOTIPE_SUCCESS = "SAVE_PROTOTIPE_SUCCESS";
export const SAVE_PROTOTIPE_FAILURE = "SAVE_PROTOTIPE_FAILURE";

export const UPDATE_PROTOTIPE_REQUEST = "UPDATE_PROTOTIPE_REQUEST";
export const UPDATE_PROTOTIPE_SUCCESS = "UPDATE_PROTOTIPE_SUCCESS";
export const UPDATE_PROTOTIPE_FAILURE = "UPDATE_PROTOTIPE_FAILURE";

export const DELETE_PROTOTIPE_REQUEST = "DELETE_PROTOTIPE_REQUEST";
export const DELETE_PROTOTIPE_SUCCESS = "DELETE_PROTOTIPE_SUCCESS";
export const DELETE_PROTOTIPE_FAILURE = "DELETE_PROTOTIPE_FAILURE";

export const GET_ELIGIBILITY_PROTOTIPE_REQUEST = "GET_ELIGIBILITY_PROTOTIPE_REQUEST";
export const GET_ELIGIBILITY_PROTOTIPE_SUCCESS = "GET_ELIGIBILITY_PROTOTIPE_SUCCESS";
export const GET_ELIGIBILITY_PROTOTIPE_FAILURE = "GET_ELIGIBILITY_PROTOTIPE_FAILURE";

export const APPROVAL_LPPM_PROTOTIPE_REQUEST = "APPROVAL_LPPM_PROTOTIPE_REQUEST";
export const APPROVAL_LPPM_PROTOTIPE_SUCCESS = "APPROVAL_LPPM_PROTOTIPE_SUCCESS";
export const APPROVAL_LPPM_PROTOTIPE_FAILURE = "APPROVAL_LPPM_PROTOTIPE_FAILURE";

export const GET_COUNTING_USULAN_PROTOTIPE_REQUEST = "GET_COUNTING_USULAN_PROTOTIPE_REQUEST";
export const GET_COUNTING_USULAN_PROTOTIPE_SUCCESS = "GET_COUNTING_USULAN_PROTOTIPE_SUCCESS";
export const GET_COUNTING_USULAN_PROTOTIPE_FAILURE = "GET_COUNTING_USULAN_PROTOTIPE_FAILURE";