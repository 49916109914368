// saga
import { takeEvery, call, put } from "redux-saga/effects";

// endpoitn from backend
import * as backend from "../../helpers/backend";

// Action Types
import {
  BERITA_REQUEST,
  BERITA_LANDING_REQUEST,
  BERITA_BY_ID_REQUEST,
  CREATE_BERITA_REQUEST,
  DELETE_BERITA_REQUEST,
  UPLOAD_FILE_REQUEST,
  UPDATE_BERITA_REQUEST,
  DELETE_IMG_BERITA_REQUEST,
} from "./actionType";

// Action
import {
  getBeritaSuccess,
  getBeritaFailure,
  getBeritaLandingSuccess,
  getBeritaLandingFailure,
  getBeritaByIdSuccess,
  getBeritaByIdFailure,
  createBeritaSuccess,
  createBeritaFailure,
  uploadBeritaSuccess,
  uploadBeritaFailure,
  deleteBeritaSuccess,
  updateBeritaSuccess,
  updateBeritaFailure,
  deleteImgBeritaSuccess,
  deleteImgBeritaFailure,
} from "./action";

// Notification Toast
import { toast } from "react-toastify";

// function saga getBerita Page
function* getBerita({ payload }) {
  try {
    const response = yield call(backend.getBerita, payload);
    if (response.data.code == 200) {
      yield put(getBeritaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getBeritaFailure(message, error));
  }
}

// function saga getBerita Landing Page
function* getBeritaLanding({ payload }) {
  try {
    const response = yield call(backend.getBeritaLanding, payload);
    if (response.data.code == 200) {
      yield put(getBeritaLandingSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getBeritaLandingFailure(message, error));
  }
}

// fuction saga getBeritaById
function* getBeritaById({ payload }) {
  try {
    const response = yield call(backend.getBeritaById, payload);
    if (response.data.code == 200) {
      yield put(getBeritaByIdSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getBeritaByIdFailure(message, error));
  }
}

// function saga upload image
function* uploadBerita({ payload }) {
  try {
    const response = yield call(backend.uploadBerita, payload);
    if (response.status == 200) {
      yield put(uploadBeritaSuccess(response.data.data));
      toast.success("Berhasil Menambahkan Thumbnail Berita");
      // return response.data.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(uploadBeritaFailure(message, error));
  }
}

// function saga createBerita
function* createBerita({ payload }) {
  try {
    const response = yield call(backend.createBerita, payload);
    if (response.status == 200) {
      yield put(createBeritaSuccess(response.data.data));
      toast.success("Berhasil Membuat Berita");
      window.location.href = "/operator-berita";
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(createBeritaFailure(message, error));
  }
}

// Function saga Delete Berita
function* deleteBerita({ payload }) {
  try {
    const response = yield call(backend.deleteBerita, payload);
    if (response.data.code == 200) {
      yield put(deleteBeritaSuccess(payload));
      toast.success("Berhasil Menghapus Berita");
      window.location.href = "/operator-berita";
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getPengumumanFailure(message, error));
  }
}

// Function saga Update Berita
function* updateBerita({ payload }) {
  try {
    const response = yield call(backend.updateBerita, payload);
    if (response.status == 200) {
      yield put(updateBeritaSuccess(response.data.data));
      toast.success("Berhasil Mengubah Berita");
      window.location.href = "/operator-berita";
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(updateBeritaFailure(message, error));
  }
}

// Function saga Delete Img Berita
function* deleteImgBerita({ payload }) {
  try {
    const response = yield call(backend.deleteImgBerita, payload);
    if (response.status == 200) {
      yield put(deleteImgBeritaSuccess(response.data.data));
      toast.success("Berhasil Mengubah Berita");
      // window.location.href = "/admin-berita";
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(deleteImgBeritaFailure(message, error));
  }
}

// export function saga
function* notificationSaga() {
  yield takeEvery(BERITA_REQUEST, getBerita);
  yield takeEvery(BERITA_LANDING_REQUEST, getBeritaLanding);
  yield takeEvery(BERITA_BY_ID_REQUEST, getBeritaById);
  yield takeEvery(CREATE_BERITA_REQUEST, createBerita);
  yield takeEvery(UPLOAD_FILE_REQUEST, uploadBerita);
  yield takeEvery(DELETE_BERITA_REQUEST, deleteBerita);
  yield takeEvery(UPDATE_BERITA_REQUEST, updateBerita);
  yield takeEvery(DELETE_IMG_BERITA_REQUEST, deleteImgBerita);
}

export default notificationSaga;
