import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import {
   GET_LIST_PROPOSAL_BIMTEK_REQUEST,
   GET_PROPOSAL_BIMTEK_REQUEST,
   UPDATE_PROPOSAL_BIMTEK_REQUEST,

} from "./actionTypes";
import {
   getBimtekProposalFailure,
   getBimtekProposalSuccess,
   getListBimtekProposalFailure,
   getListBimtekProposalSuccess,
   updateBimtekProposalFailure,
   updateBimtekProposalSuccess
} from "./action";


function* getListBimtekProposal({ payload = {} }) {
   try {
      const response = yield call(backend.getBimtekProposal, { params: payload });
      if (response.data.code == 200) {
         yield put(getListBimtekProposalSuccess(response.data));

      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;

      toast.error(message);
      yield put(getListBimtekProposalFailure(message, { ...error }));
   }
}

function* getDetailBimtekProposal({ payload = {}, cb }) {
   try {
      const response = yield call(backend.getBimtekProposal, { id: payload.id });
      if (response.data.code == 200) {
         yield put(getBimtekProposalSuccess(response.data));
         cb(response.data.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      cb(null);
      toast.error(message);
      yield put(getBimtekProposalFailure(message, { ...error }));
   }
}


function* updateBimtekProposal({ payload, cb }) {
   try {
      const response = yield call(backend.updateBimtekProposal, payload);
      if (response.data.code == 200) {
         cb(response.data.data);
         yield put(updateBimtekProposalSuccess(response.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      cb(null);
      toast.error(message);
      yield put(updateBimtekProposalFailure(message, { ...error }));
   }
}

function* bimtekProposal() {
   yield takeEvery(GET_LIST_PROPOSAL_BIMTEK_REQUEST, getListBimtekProposal);
   yield takeEvery(GET_PROPOSAL_BIMTEK_REQUEST, getDetailBimtekProposal);
   yield takeEvery(UPDATE_PROPOSAL_BIMTEK_REQUEST, updateBimtekProposal);
}

export default bimtekProposal;
