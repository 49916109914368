import {
  CATATAN_HARIAN_REQUEST,
  CATATAN_HARIAN_SUCCESS,
  CATATAN_HARIAN_FAILURE,
  CATATAN_HARIAN_CREATE_REQUEST,
  CATATAN_HARIAN_DELETE_REQUEST,
  CATATAN_HARIAN_EDIT_REQUEST,
  CATATAN_HARIAN_EDIT_SUCCESS,
  CATATAN_HARIAN_CREATE_SUCCESS,
  CATATAN_HARIAN_DELETE_SUCCESS,
  CATATAN_HARIAN_UPLOAD_SUCCESS,
  CATATAN_HARIAN_UPLOAD_REQUEST,
  CATATAN_HARIAN_DELETE_BERKAS_REQUEST,
  CATATAN_HARIAN_DELETE_BERKAS_SUCCESS,
  CRUD_CATATAN_HARIAN_V2_REQUEST,
  CRUD_CATATAN_HARIAN_V2_SUCCESS,
  CRUD_CATATAN_HARIAN_V2_FAILURE,
} from "./actionTypes";

// Get
export const getCatatanHarian = (request, cb = () => {}) => {
  return {
    type: CATATAN_HARIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getCatatanHarianSuccess = (response, state) => {
  return {
    type: CATATAN_HARIAN_SUCCESS,
    payload: response,
    state,
  };
};

export const getCatatanHarianFailure = (message, response) => {
  return {
    type: CATATAN_HARIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Create
export const createCatatanHarian = (request, cb) => {
  return {
    type: CATATAN_HARIAN_CREATE_REQUEST,
    payload: request,
    cb,
  };
};

// Delete
export const deleteCatatanHarian = (request, cb) => {
  return {
    type: CATATAN_HARIAN_DELETE_REQUEST,
    payload: request,
    cb,
  };
};

// Edit
export const editCatatanHarian = (request, cb) => {
  return {
    type: CATATAN_HARIAN_EDIT_REQUEST,
    payload: request,
    cb,
  };
};

export const uploadCatatanHarian = (request, cb) => {
  return {
    type: CATATAN_HARIAN_UPLOAD_REQUEST,
    payload: request,
    cb,
  };
};

export const editCatatanHarianSuccess = response => {
  return {
    type: CATATAN_HARIAN_EDIT_SUCCESS,
    payload: response,
  };
};

export const createCatatanHarianSuccess = response => {
  return {
    type: CATATAN_HARIAN_CREATE_SUCCESS,
    payload: response,
  };
};

export const deleteCatatanHarianSuccess = response => {
  return {
    type: CATATAN_HARIAN_DELETE_SUCCESS,
    payload: response,
  };
};

export const uploadCatatanHarianSuccess = response => {
  return {
    type: CATATAN_HARIAN_UPLOAD_SUCCESS,
    payload: response,
  };
};

export const deleteBerkasCatatanHarian = (request, cb) => {
  return {
    type: CATATAN_HARIAN_DELETE_BERKAS_REQUEST,
    payload: request,
    cb,
  };
};

export const deleteBerkasCatatanHarianSuccess = response => {
  return {
    type: CATATAN_HARIAN_DELETE_BERKAS_SUCCESS,
    payload: response,
  };
};
// Catatan harian v2
export const crudCatatanHarianV2 = (request = {}, cb = () => {}) => {
  return {
    type: CRUD_CATATAN_HARIAN_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const crudCatatanHarianV2Success = response => {
  return {
    type: CRUD_CATATAN_HARIAN_V2_SUCCESS,
    payload: response,
  };
};

export const crudCatatanHarianV2Failure = (message, response) => {
  return {
    type: CRUD_CATATAN_HARIAN_V2_FAILURE,
    payload: { errors: response, message },
  };
};
