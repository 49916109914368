/* eslint-disable no-unused-vars */
import UsulanBaruPengabdianPdf from "helpers/workers/pdf/usulanBaruPengabdian";
import UsulanBaruPengabdianPdfV2 from "helpers/workers/pdf/usulanBaruPengabdianV2";
import { instances } from "../api_helper";
import * as url from "../url_helper";
import { toast } from "react-toastify";
const getPengabdian = ({ year, id }) =>
  instances.request(
    `${url.ENDPOINT_PENGABDIAN}${
      year ? `?criteria=thn_pelaksanaan_kegiatan:${year}` : ""
    }${id ? `/${id}` : ""}`,
    {
      method: "GET",
    }
  );

const handleDownloadProposalPengabdian = async (
  item,
  cb = () => {},
  forceDownload = true
) => {
  try {
    const fileName = `${item?.identitas?.judul
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .trim()}`;
    // Generate Pdf From Client
    const proposalPDF = new UsulanBaruPengabdianPdf(item);
    const blobFileFromClient = await proposalPDF.generate();
    const blobFilePersetujuanFromClient =
      await proposalPDF.generatePersetujuan();

    // Get Multi Pdf File From Server
    const blobFileFromServer = await proposalPDF.getMultiplefilev2(item);

    const contentMerge = [
      { file: blobFileFromClient, fileType: "pdf" },
      ...blobFileFromServer,
      { file: blobFilePersetujuanFromClient, fileType: "pdf" },
    ];

    const pdfDocs = contentMerge.filter(item => item.fileType === "pdf");
    const nonPdfDocs = contentMerge.filter(item => item.fileType !== "pdf");
    // Start Merger

    const mergingFun = () => {
      //merging version 1 for merge common files
      //merging version 2 for merge files ignoring encrypted file
      //merging version 3 for merge files ignoring encrypted file & lost merged the file
      proposalPDF.mergingv3(
        pdfDocs.map(itm => itm.file),
        result => {
          const resultMerged = result.res;
          // console.log(result);
          if (result.error === false) {
            if (forceDownload)
              proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
            cb({
              success: true,
              result: resultMerged,
              fileName,
            });
          } else {
            // mergingFun();
            console.log(result);
            mergingFun();
            toast.warn(
              "Terjadi kesalahan saat penggabungan dokumen, silahkan periksa dokumen anda!"
            );
            if (result?.indexBlobSkipped) {
              proposalPDF.forceDownload(
                contentMerge[result?.indexBlobSkipped],
                `doc_${result?.indexBlobSkipped - 1}.pdf`
              );
            }
          }
        }
      );
    };

    mergingFun();
    if (nonPdfDocs.length > 0) {
      toast.warn("file doc/docx tidak dapat digabung");
      nonPdfDocs.forEach((doc, i) => {
        proposalPDF.forceDownload(doc.file, `${doc.fileName}`);
      });
    }
  } catch (err) {
    console.log(err);
    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};
const handleDownloadProposalPengabdianV2 = async (
  item,
  cb = () => {},
  flag = 1,
  direktorat = "vokasi"
) => {
  //flag 1 = force download
  //flag 2 = without force download
  try {
    const fileName = item.raw.identitas.judul;
    const proposalPDF = new UsulanBaruPengabdianPdfV2({
      item,
    });
    const blobFromClient = await proposalPDF.generate(direktorat);
    const blobFromServer = await proposalPDF.getMultiplefile(item, direktorat);
    const blobFilePersetujuanFromClient =
      await proposalPDF.generatePersetujuan();
    const contentMerge = [
      blobFromClient,
      ...blobFromServer,
      blobFilePersetujuanFromClient,
    ];

    const mergingFun = () => {
      proposalPDF.mergingv3(contentMerge, result => {
        const resultMerged = result.res;
        if (result.error === false) {
          if (flag !== 2) {
            proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
          }
          cb({
            success: true,
            data: resultMerged,
          });
        } else {
          mergingFun();
          toast.warn("Beberapa file tidak dapat di gabung!");
          proposalPDF.forceDownload(
            contentMerge[result.indexBlobSkipped],
            `file_${result.indexBlobSkipped - 1}.pdf`
          );
        }
      });
    };

    mergingFun();
  } catch (error) {
    console.log(error);
    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};
export {
  getPengabdian,
  handleDownloadProposalPengabdian,
  handleDownloadProposalPengabdianV2,
  handleDownloadProposalPengabdian as downloadProposalPengabdianV1,
  handleDownloadProposalPengabdianV2 as downloadProposalPengabdianV2,
};
