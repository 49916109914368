export const CATATAN_HARIAN_REQUEST = "CATATAN_HARIAN_REQUEST";
export const CATATAN_HARIAN_SUCCESS = "CATATAN_HARIAN_SUCCESS";
export const CATATAN_HARIAN_FAILURE = "CATATAN_HARIAN_FAILURE";

export const CATATAN_HARIAN_CREATE_REQUEST = "CATATAN_HARIAN_CREATE_REQUEST";
export const CATATAN_HARIAN_DELETE_REQUEST = "CATATAN_HARIAN_DELETE_REQUEST";
export const CATATAN_HARIAN_EDIT_REQUEST = "CATATAN_HARIAN_EDIT_REQUEST";
export const CATATAN_HARIAN_CREATE_SUCCESS = "CATATAN_HARIAN_CREATE_SUCCESS";
export const CATATAN_HARIAN_EDIT_SUCCESS = "CATATAN_HARIAN_EDIT_SUCCESS";
export const CATATAN_HARIAN_DELETE_SUCCESS = "CATATAN_HARIAN_DELETE_SUCCESS";

export const CATATAN_HARIAN_UPLOAD_REQUEST = "CATATAN_HARIAN_UPLOAD_REQUEST";
export const CATATAN_HARIAN_UPLOAD_SUCCESS = "CATATAN_HARIAN_UPLOAD_SUCCESS";

export const CATATAN_HARIAN_DELETE_BERKAS_REQUEST =
  "CATATAN_HARIAN_DELETE_BERKAS_REQUEST";
export const CATATAN_HARIAN_DELETE_BERKAS_SUCCESS =
  "CATATAN_HARIAN_DELETE_BERKAS_SUCCESS";

// catatan harian v2
export const CRUD_CATATAN_HARIAN_V2_REQUEST = "CRUD_CATATAN_HARIAN_V2_REQUEST";
export const CRUD_CATATAN_HARIAN_V2_SUCCESS = "CRUD_CATATAN_HARIAN_V2_SUCCESS";
export const CRUD_CATATAN_HARIAN_V2_FAILURE = "CRUD_CATATAN_HARIAN_V2_FAILURE";
