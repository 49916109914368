import NoData from "components/Common/NoData";
import TooltipItem from "components/Core/Tooltip";
import handleToast from "components/handleToast/handleToast";
import { AlertConfirm } from "components/Modals/AlertConfirm";
import ProposalPDF from "../../../helpers/workers/pdf/monev/proposal";
import CardHeaderListUsulan from "components/ReviewerEvaluasi/CardHeader/CardHeaderListUsulan";
import TableBima from "components/Table";
import utilities from "helpers/utilities";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { getKompPenilaianMonev, getUsulanById, getUsulanBySkema, savePermanentMonev, getBerkasLaporanKemajuan } from "store/actions";


class ListUsulanMonev extends Component {
    constructor(props) {
        super(props);
        this.skemaId = this.props.match.params.skemaId;
        this.tahun_usulan = this.props.location.state?.tahun_usulan;
        this.id_institusi = this.props.location.state?.id_institusi;
        this.thn_pelaksanaan = this.props.location.state?.tahun_pelaksanaan;
        this.state = {
            columns: [
                {
                    label: "Usulan",
                    property: "usulan",
                    customValue: (cell, i) => {
                        return (<>
                            <p className="text-primary card-title m-0">
                                {cell.judul}
                            </p>
                            <TooltipItem title="Bidang Fokus" id={"bidang-fokus-" + i} />
                            <Badge id={"tooltip-bidang-fokus-" + i} color="success" className="p-1 rounded-pill rounded" >
                                <span className="font-weight-light h6 text-white" >{cell.bidang_fokus}</span>
                            </Badge>
                            <p style={{ fontStyle: "italic" }} className="text-success m-0 font-size-14">
                            </p>
                            <p className="m-0">
                                Ketua : <strong> {cell.nama_pengusul} </strong>

                            </p>

                            <p className="m-0">
                                Lama Kegiatan : {cell.lama_kegiatan}
                            </p>
                            <p className="m-0">
                                Jumlah Anggota : {cell.jumlah_anggota}
                            </p>

                        </>)
                    }
                },
                {
                    label: "Proposal",
                    property: "proposal",
                    width: "120",
                    addClass: "text-center",
                    customValue: (_cell) => {
                        return (
                            <>
                                <a onClick={() => this.fetchBerkasLapKemajuan(_cell?.id_usulan_kegiatan)} className="text-danger">
                                    <i className="fa fa-file-pdf" style={{ fontSize: "30px" }}></i>
                                    <br />
                                    <span className="text-center text-primary">Unduh</span>
                                </a>
                            </>
                        )
                    }
                },
                {
                    label: "Aksi",
                    property: "action",
                    addClass: "text-center",
                    customValue: (cell) => {
                        const { monev: {
                            listUsulan,
                        } } = this.props;
                        return (<div>

                            <div className="mt-2">
                                <p className="m-0" ><b>Nilai {cell.nilai?.nilai?.toFixed(2) || 0}</b></p>
                                <p className="text-danger m-0" >Komponen dinilai <strong>{cell.nilai?.jml_komponen_sudah_dinilai || 0}</strong> dari <strong>{cell.nilai?.jml_komponen || 0}</strong></p>
                                <Button onClick={() => this.props.history.push({
                                    pathname: `/monev-penelitian/penilaian/${this.skemaId}/${cell.id_transaksi_kegiatan}`,
                                    state: {
                                        tahun_usulan: this.tahun_usulan,
                                        tahun_pelaksanaan: this.thn_pelaksanaan,
                                        is_skema_permanent: listUsulan.item?.permanen,
                                        id_institusi: this.id_institusi,
                                        level_tkt: cell.level_tkt_target || 5 //todo sementara
                                    }
                                })} color="primary" className="btn-sm mt-2" >
                                    <i className="fa fa-pen me-2"></i>
                                    Penilaian
                                </Button>
                            </div>
                        </div>)
                    }
                },

            ],
            isOpenModalConfirm: false,
            downloadingProposal: false
        };
    }

    fetchUsulan() {
        this.props.getUsulanBySkema({
            skemaId: this.skemaId,
            jenis: "penelitian",
            thn_pelaksanaan: this.thn_pelaksanaan,
            thn_usulan: this.tahun_usulan,
            id_institusi: this.id_institusi
        })
    }

    handleClickPermanent = () => {
        const { monev: {
            listUsulan,
        } } = this.props;

        //checking if all usuluan have been measured
        const areTrue = Object.values(listUsulan.item.usulan).every(
            item => item.nilai?.jml_komponen?.toString() === item.nilai?.jml_komponen_sudah_dinilai?.toString()
        );

        if (areTrue) {
            this.props.savePermanentMonev({
                id_skema: this.skemaId,
                id_institusi: this.id_institusi,
                jenis: "penelitian",
                thn_usulan: this.tahun_usulan,
                thn_pelaksanaan: this.thn_pelaksanaan,
            }, res => {
                if (res.code === 200) {
                    toast.success("Berhasil Menyimpan Data Permanen")
                    this.fetchUsulan()
                }
            })
        } else {
            toast.warning("Tidak dapat menyimpan data menjadi permanen, terdapat data usulan yang belum dinilai");
        }

    }

    handleConfirm = (e) => {
        e.preventDefault();
        const { value } = e.target;

        if (parseInt(value) === 1) {
            this.handleClickPermanent();
        }
        this.setState({
            isOpenModalConfirm: false
        })
    }

    fetchBerkasLapKemajuan = (id_usulan) => {
        this.props.getBerkasLaporanKemajuan({
            id: id_usulan
        }, res => {
            if (res.code === 200) {
                let idToast = toast.loading("Generating...");
                this.downloadPDFProposal(idToast, res.data);
            }
        });
    }


    downloadPDFProposal = async (idToast, item) => {

        const extension = utilities
            .getFriendlyURL(item?.url_file_proposal_revisi || item?.url_file_substansi)
            .split(".")
            .pop();
        this.setState({
            downloadingProposal: true
        })

        const fileName = `${item.title.replace(/[^a-zA-Z0-9 ]/g, '').trim()}`;

        if (extension === "docx" || extension === "doc") {
            // * Generate Pdf From Client
            const proposalPDF = new ProposalPDF(item);
            const blobFileFromClient = await proposalPDF.generate();

            proposalPDF.forceDownload(blobFileFromClient, fileName+".pdf");

            // * Force Download Docx
            const blobFileFromServerDocx = await proposalPDF.getSingleFile(
                item?.url_file_proposal_revisi || item?.url_file_substansi || ""
            );

            // * Force Download
            proposalPDF.forceDownload(
                blobFileFromServerDocx,
                `${fileName}.${extension}`
            );
            handleToast(
                idToast,
                false,
                "Success : silahkan cek folder download default"
            );
            this.setState({
                downloadingProposal: false
            })
            return;
        }


        // * Generate Pdf From Client
        const proposalPDF = new ProposalPDF(item);
        const blobFileFromClient = await proposalPDF.generate();

        if (extension === "" || extension === null) {
            proposalPDF.forceDownload(blobFileFromClient, fileName+".pdf");
            handleToast(idToast, false, "Success");
            this.setState({
                downloadingProposal: false
            })
            return;
        }

        // * Get Pdf File From Server
        const blobFileFromServer = await proposalPDF.getSingleFile(
            item?.url_file_proposal_revisi || item?.url_file_substansi || ""
        );
        try {
            // * Start Merger
            const mergedPdf = await proposalPDF.merging([
                blobFileFromClient,
                blobFileFromServer,
            ]);

          
            // * Force Download
            proposalPDF.forceDownload(mergedPdf, fileName+".pdf");
            handleToast(idToast, false, "Success");
            this.setState({
                downloadingProposal: false
            })
        } catch (err) {
            handleToast(idToast, true, err.message);
            this.setState({
                downloadingProposal: false
            })
        }
    };

    componentDidMount() {
        if (this.tahun_usulan && this.thn_pelaksanaan && this.id_institusi) {
            this.fetchUsulan();
        } else {
            this.props.history.push("/monev-penelitian");
        }
    }

    render() {
        const { columns, isOpenModalConfirm } = this.state;
        const { monev: {
            savePermanentSkema,
            listUsulan,
        } } = this.props;


        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Evaluasi Keberlanjutan Penelitian | BIMA - Kemdikbudristek</title>
                    </MetaTags>
                    <Container fluid >
                        <div className="d-flex justify-content-between" >
                            <div>
                                <h4 className="m-0">Evaluasi Keberlanjutan Penelitian</h4>
                            </div>
                            <Button
                                onClick={() => this.props.history.push({
                                    pathname: `/monev-penelitian`,
                                    state: {
                                        thn_usulan: this.tahun_usulan,
                                        id_institusi: this.id_institusi,
                                        thn_pelaksanaan: this.thn_pelaksanaan
                                    }
                                })}
                                className="mb-2" color="info" >
                                <i className="fas fa-arrow-left"></i> Kembali
                            </Button>
                        </div>
                        <Row>
                            <Col md="12">
                                <Card className="overflow-hidden">
                                    <CardHeaderListUsulan
                                        shownDownloadPenilaian={false}
                                        skema={listUsulan.item?.skema}
                                        status={listUsulan.item?.permanen ? (<span id="tooltip-status-permanen" className="badge bg-danger p-2" > <i className="fas fa-lock" ></i> Kunci</span>) : (<span id="tooltip-status-permanen" className="badge bg-success p-2" > <i className="fas fa-unlock" ></i> Buka</span>)}
                                        thnUsulan={this.tahun_usulan}
                                        thnPelaksanaan={this.thn_pelaksanaan}
                                        onClickPermanent={() => this.setState(prev => ({
                                            isOpenModalConfirm: !prev.isOpenModalConfirm
                                        }))}
                                        loading={{
                                            btnPermanent: savePermanentSkema.isFetching,
                                        }}
                                        permanent={listUsulan.item?.permanen}
                                    />
                                    <CardBody >
                                        <Row>
                                            <Col sm="12">
                                                {!listUsulan.isFetching && listUsulan.item?.usulan?.length === 0 ? (
                                                    <NoData />
                                                ) : (
                                                    <TableBima
                                                        history={history}
                                                        supportFn={{}}
                                                        supportData={{}}
                                                        columns={columns}
                                                        title={`List Usulan`}
                                                        data={listUsulan.item?.usulan || []}
                                                        loading={listUsulan.isFetching}
                                                        detailRefId={'id'}
                                                    />

                                                )}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <AlertConfirm
                            isOpen={isOpenModalConfirm}
                            toggle={() => this.setState({ isOpenModalConfirm: !this.state.isOpenModalConfirm })}
                            title="Yakin ingin menyimpan data menjadi permanen ?"
                            handleConfirm={this.handleConfirm}
                        />

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ListUsulanMonev.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    monev: PropTypes.object,
    laporanKemajuan: PropTypes.object,
    getUsulanBySkema: PropTypes.func,
    savePermanentMonev: PropTypes.func,
    getUsulanById: PropTypes.func,
    getBerkasLaporanKemajuan: PropTypes.func,
    getKompPenilaianMonev: PropTypes.func
};

const mapStatetoProps = state => {
    const monev = state.Monev;
    return { monev };
};

export default connect(mapStatetoProps, {
    getUsulanBySkema, savePermanentMonev, getUsulanById, getKompPenilaianMonev, getBerkasLaporanKemajuan
})(withTranslation()(ListUsulanMonev));
