import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";

import {
  KOSABANGSA_BIDANG_FOKUS_LAINNYA_REQUEST,
  KOSABANGSA_BIDANG_FOKUS_PRIORITAS_REQUEST,
  KOSABANGSA_DOSEN_REQUEST,
  KOSABANGSA_GET_PROVINSI_REQUEST,
  KOSABANGSA_KABUPATEN_KOTA_REQUEST,
  KOSABANGSA_KELOMPOK_MITRA_REQUEST,
  KOSABANGSA_JENIS_PIMPINAN_PENETAP_REQUEST,
  KOSABANGSA_UPLOAD_FILE_REQUEST,
  KOSABANGSA_SAVE_REQUEST,
  KOSABANGSA_PENDAMPING_BYID_REQUEST,
  KOSABANGSA_LIST_PELAKSANA_REQUEST,
  KOSABANGSA_PELAKSANA_BYID_REQUEST,
  KOSABANGSA_PUT_REQUEST,
  KOSABANGSA_DELETE_REQUEST,
  KOSABANGSA_PELAKSANA_LIST_PENDAMPING_REQUEST,
  KOSABANGSA_LIST_PENDAMPING_REQUEST,
  GET_ELIGIBILITY_KOSABANGSA_REQUEST,
  GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_REQUEST,
  APPROVAL_OP_PUSAT_KOSBANGSA_REQUEST,
  GET_KOSABANGSA_MATCHED_REQUEST
} from "./actionTypes";
// Action
import {
  getBidangFokusLainnyaKosabangsaFailure,
  getBidangFokusLainnyaKosabangsaSuccess,
  getBidangFokusPrioritasKosabangsaFailure,
  getBidangFokusPrioritasKosabangsaSuccess,
  getDosenKosabangsaFailure,
  getDosenKosabangsaSuccess,
  getKabupatenKotaKosabangsaFailure,
  getKabupatenKotaKosabangsaSuccess,
  getKelompokMitraKosabangsaFailure,
  getKelompokMitraKosabangsaSuccess,
  getProvinsiKosabangsaFailure,
  getProvinsiKosabangsaSuccess,
  getJenisPimpinanPenetapSuccess,
  getJenisPimpinanPenetapFailure,
  uploadDokumenKosabangsaSuccess,
  uploadDokumenKosabangsaFailure,
  saveKosabangsaSuccess,
  saveKosabangsaFailure,
  getKosabangsaPendampingByIdSuccess,
  getKosabangsaPendampingByIdFailure,
  getKosabangsaListPelaksanaSuccess,
  getKosabangsaListPelaksanaFailure,
  getKosabangsaPelaksanaByIdSuccess,
  getKosabangsaPelaksanaByIdFailure,
  putKosabangsaSuccess,
  putKosabangsaFailure,
  deleteKosabangsaSuccess,
  deleteKosabangsaFailure,
  getKosabangsaPelaksanaListPendampingSuccess,
  getKosabangsaPelaksanaListPendampingFailure,
  getKosabangsaListPendampingSuccess,
  getKosabangsaListPendampingFailure,
  getEligibilityKosabangsaSuccess,
  getEligibilityKosabangsaFailure,
  approvalOPPusatKosabangsaSuccess,
  approvalOPPusatKosabangsaFailure,
  getKosabangsaMatchedSuccess,
  getKosabangsaMatchedFailure,
} from "./action";

function* getKosabangsaMatched({cb = () => {} }) {
  try {
    const response = yield call(backend.getKosabangsaMatched);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getKosabangsaMatchedSuccess({ ...response.data.data }));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    console.log(message);
    cb(null);
    yield put(getKosabangsaMatchedFailure(message, { ...error }));
  }
}

function* approvalOPPusatKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.approvalOPPusatKosabangsa, {
      ...payload,
    });
    if (response.data.code == 200) {
      cb(response.data);
      yield put(approvalOPPusatKosabangsaSuccess({ ...response.data.data }));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    console.log(message);
    cb(null);
    yield put(approvalOPPusatKosabangsaFailure(message, { ...error }));
  }
}

function* getEligibilityKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getEligibilityKosabangsa, {
      ...payload,
    });
    if (response.data.code == 200) {
      cb(response.data);
      yield put(
        getEligibilityKosabangsaSuccess({
          ...response.data.data,
          is_anggota: payload?.anggota ? true : false,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    console.log(message);
    cb(null);
    yield put(
      getEligibilityKosabangsaFailure(message, {
        ...error,
        is_anggota: payload?.anggota ? true : false,
      })
    );
  }
}

function* getKosabangsaListPendamping({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getKosabangsa, {
      ...payload,
    });
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getKosabangsaListPendampingSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(getKosabangsaListPendampingFailure(message, error));
  }
}

function* getKosabangsaPendampingById({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getKosabangsa, {
      ...payload,
      type: "pendamping",
    });
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getKosabangsaPendampingByIdSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(getKosabangsaPendampingByIdFailure(message, error));
  }
}

function* saveKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.saveKosabangsa, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(saveKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(saveKosabangsaFailure(message, error));
  }
}
function* putKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.putKosabangsa, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(putKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(putKosabangsaFailure(message, error));
  }
}
function* deleteKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.deleteKosabangsa, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(deleteKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(deleteKosabangsaFailure(message, error));
  }
}

function* uploadDokumenKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.uploadDokumenKosabangsa, payload);
   
    if (response.data.code == 200) {
      cb(response.data);
      yield put(uploadDokumenKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(uploadDokumenKosabangsaFailure(message, error));
  }
}

function* getJenisPimpinanPenetap({ payload }) {
  try {
    const response = yield call(backend.getJenisPiminanPenetap, payload);
    if (response.data.code == 200) {
      yield put(getJenisPimpinanPenetapSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getJenisPimpinanPenetapFailure(message, error));
  }
}

function* getDosenKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getDosenKosabangsa, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getDosenKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getDosenKosabangsaFailure(message, error));
  }
}

function* getBidangFokusPrioritasKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(
      backend.getBidangFokusPrioritasKosabangsa,
      payload
    );
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getBidangFokusPrioritasKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    toast.error(message);
    yield put(getBidangFokusPrioritasKosabangsaFailure(message, error));
  }
}

function* getBidangFokusLainnyaKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(
      backend.getBidangFokusLainnyaKosabangsa,
      payload
    );
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getBidangFokusLainnyaKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getBidangFokusLainnyaKosabangsaFailure(message, error));
  }
}
function* getProvinsiKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getProvinsiKosabangsa, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getProvinsiKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getProvinsiKosabangsaFailure(message, error));
  }
}
function* getKabupatenKotaKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getKabupatenKotaKosabangsa, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getKabupatenKotaKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getKabupatenKotaKosabangsaFailure(message, error));
  }
}
function* getKelompokMitraKosabangsa({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getKelompokMitraKosabangsa, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getKelompokMitraKosabangsaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getKelompokMitraKosabangsaFailure(message, error));
  }
}
function* getKosabangsaListPelaksana({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getKosabangsa, {
      ...payload,
      type: "pelaksana",
    });
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getKosabangsaListPelaksanaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(getKosabangsaListPelaksanaFailure(message, error));
  }
}
function* getKosabangsaPelaksanaById({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getKosabangsa, {
      ...payload,
      type: "pelaksana",
    });
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getKosabangsaPelaksanaByIdSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(getKosabangsaPelaksanaByIdFailure(message, error));
  }
}
function* getKosabangsaPelaksanaListPendamping({ payload, cb = () => {} }) {
  try {
    const response = yield call(
      backend.getKosabangsaPelaksanaListPendamping,
      payload
    );
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getKosabangsaPelaksanaListPendampingSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(getKosabangsaPelaksanaListPendampingFailure(message, error));
  }
}
function* kosabangsa() {
  yield takeEvery(KOSABANGSA_DOSEN_REQUEST, getDosenKosabangsa);
  yield takeEvery(
    KOSABANGSA_BIDANG_FOKUS_PRIORITAS_REQUEST,
    getBidangFokusPrioritasKosabangsa
  );
  yield takeEvery(
    KOSABANGSA_BIDANG_FOKUS_LAINNYA_REQUEST,
    getBidangFokusLainnyaKosabangsa
  );
  yield takeEvery(KOSABANGSA_GET_PROVINSI_REQUEST, getProvinsiKosabangsa);
  yield takeEvery(
    KOSABANGSA_KABUPATEN_KOTA_REQUEST,
    getKabupatenKotaKosabangsa
  );
  yield takeEvery(
    KOSABANGSA_KELOMPOK_MITRA_REQUEST,
    getKelompokMitraKosabangsa
  );
  yield takeEvery(
    KOSABANGSA_JENIS_PIMPINAN_PENETAP_REQUEST,
    getJenisPimpinanPenetap
  );
  yield takeEvery(KOSABANGSA_UPLOAD_FILE_REQUEST, uploadDokumenKosabangsa);
  yield takeEvery(KOSABANGSA_SAVE_REQUEST, saveKosabangsa);
  yield takeEvery(KOSABANGSA_PUT_REQUEST, putKosabangsa);
  yield takeEvery(
    KOSABANGSA_PENDAMPING_BYID_REQUEST,
    getKosabangsaPendampingById
  );
  yield takeEvery(
    KOSABANGSA_LIST_PELAKSANA_REQUEST,
    getKosabangsaListPelaksana
  );
  yield takeEvery(
    KOSABANGSA_PELAKSANA_BYID_REQUEST,
    getKosabangsaPelaksanaById
  );
  yield takeEvery(KOSABANGSA_DELETE_REQUEST, deleteKosabangsa);
  yield takeEvery(
    KOSABANGSA_PELAKSANA_LIST_PENDAMPING_REQUEST,
    getKosabangsaPelaksanaListPendamping
  );
  yield takeEvery(
    KOSABANGSA_LIST_PENDAMPING_REQUEST,
    getKosabangsaListPendamping
  );
  yield takeEvery(GET_ELIGIBILITY_KOSABANGSA_REQUEST, getEligibilityKosabangsa);
  yield takeEvery(
    GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_REQUEST,
    getEligibilityKosabangsa
  );
  yield takeEvery(
    APPROVAL_OP_PUSAT_KOSBANGSA_REQUEST,
    approvalOPPusatKosabangsa
  );
  yield takeEvery(
    GET_KOSABANGSA_MATCHED_REQUEST,
    getKosabangsaMatched
  );
}

export default kosabangsa;
