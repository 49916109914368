import { Content, contentPersetujuan } from "./content";
import stylePDF from "./styles";

export const PdfCreating = data => {
  
  const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
  return {
    content: [...Content(data)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};
export const pdfCreatePersetujuan = item => {
  const { pageSize, styles, defaultStyle } = stylePDF;
  return {
    content: [
      ...contentPersetujuan(
       (item?.status_approval || []).length > 0 ? item?.status_approval[0] : {}
      ),
    ],
    defaultStyle,
    pageMargins: [45, 32, 45, 45],
    pageSize,
    styles,
  };
};
