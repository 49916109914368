/* eslint-disable no-unused-vars */
import { object } from "prop-types";
import { instances } from "../../api_helper";
import * as url from "../../url_helper";
import encStorage from "helpers/encStorage";
import { decode } from "helpers/jwt";
const storage = JSON.parse(encStorage.getItem("authUser"));
const API_V2_URL =
  process.env.REACT_APP_API_V2_URL || "http://localhost:8088/api/v2";
// * Operator Pengelolaan Reviewer (List Daftar Reviewer)
export const getListDaftarReviewer = ({
  idInstitusi,
  query = "",
  limit,
  page = 1,
  filter,
}) => {
  const jwtResult = decode(storage?.token);
  let URL;
  if (
    window.location.pathname.includes(
      "/operator/pengelolaan-reviewer/daftar-reviewer"
    )
  ) {
    URL = `${url.ENDPOINT_LIST_DAFTAR_REVIEWER}/${jwtResult.id_institusi}`;
  } else if (
    window.location.pathname.includes(
      "/operator/pengelolaan-reviewer/eksternal-daftar-reviewer"
    )
  ) {
    let queryParams = `?limit=${limit}&page=${page}`;

    if (query !== "") {
      queryParams = `?nidn=${query}&limit=${limit}&page=${page}`;
    }

    if (filter.field) {
      queryParams = `?limit=${limit}&page=${page}`;
      queryParams += `&${filter.field}=${filter.value}`;
    }
    URL = `${url.ENDPOINT_LIST_DAFTAR_REVIEWER}/nasional-dev${queryParams}`;
  } else {
    URL = `${url.ENDPOINT_LIST_DAFTAR_REVIEWER}/${idInstitusi}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

// * Search Reviewer by nama
export const getDaftarReviewerByName = ({ nama }) => {
  const jwtResult = decode(storage?.token);
  return instances.request(
    `${url.ENDPOINT_LIST_DAFTAR_REVIEWER}/nama/${nama}/${jwtResult.id_institusi}`,
    {
      method: "GET",
    }
  );
};
// * Toggle Status active or not active
export const putStatusDaftarReviewer = ({ id }) => {
  return instances.request(`/hibah/reviewer/${id}`, {
    method: "PUT",
  });
};
// * Create data reviwer baru
export const postDaftarReviewer = payload => {
  return instances.request(`${url.ENDPOINT_LIST_DAFTAR_REVIEWER}`, {
    method: "POST",
    data: payload,
  });
};
// Penugasan reviwer get dropdown tahapan
export const getDropdownTahapan = async ({
  tahun_pelaksanaan,
  id_institusi,
}) => {
  const jwtResult = decode(storage?.token);
  let URL;
  if (window.location.pathname.includes("/operator/pengelolaan-reviewer")) {
    URL = `${url.ENDPOINT_DROPDOWN_TAHAPAN_PENGELOLAAN_REVIEWER}/${jwtResult.id_institusi}/${tahun_pelaksanaan}`;
  } else {
    URL = `${url.ENDPOINT_DROPDOWN_TAHAPAN_PENGELOLAAN_REVIEWER}/${id_institusi}/${tahun_pelaksanaan}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

// Dropdown tahapan Usulan dan Tahun Pelaksanaan
export const getDropdownTahunKegiatan = async () => {
  return instances.request(
    `${url.ENDPOINT_DROPDOWN_TAHUN_KEGIATAN}?criteria:kd_sts_aktif=1&page=1:100`,
    {
      method: "GET",
    }
  );
};

export const getSkemaReviewer = async payload => {
  const jwtResult = decode(storage?.token);
  let URL;
  if (window.location.pathname.includes("/operator/pengelolaan-reviewer")) {
    URL = `${url.ENDPOINT_GET_SKEMA_REVIEWER}/${jwtResult.id_institusi}/${payload.thn_pelaksanaan}/${payload.tahapan}`;
  } else {
    URL = `${url.ENDPOINT_GET_SKEMA_REVIEWER}/${payload.id_institusi}/${payload.thn_pelaksanaan}/${payload.tahapan}?kodeJenisKegiatan=${payload.kodeJenisKegiatan}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

export const getSkemaReviewerById = async payload => {
  const jwtResult = decode(storage?.token);
  let URL;
  if (window.location.pathname.includes("/operator/pengelolaan-reviewer")) {
    URL = `${url.ENDPOINT_GET_SKEMA_REVIEWER_BY_ID}/${jwtResult.id_institusi}/${payload.thn_pelaksanaan}/${payload.tahapan}/${payload.skema_id}`;
  } else {
    URL = `${url.ENDPOINT_GET_SKEMA_REVIEWER_BY_ID}/${payload.id_institusi}/${payload.thn_pelaksanaan}/${payload.tahapan}/${payload.skema_id}?kodeJenisKegiatan=${payload.kodeJenisKegiatan}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

// search reviewer lepas filter bisa cari reviewer nasional, internal dan eksternal (ini di OPT PT)
export const searchReviewerPT1 = async payload => {
  const jwtResult = decode(storage?.token);
  let URL;
  if (window.location.pathname.includes("/operator/pengelolaan-reviewer")) {
    URL = `${url.ENDPOINT_SEARCH_REVIEWER_PT}?nidn=${payload.nidn}&institusi=${jwtResult.id_institusi}`;
  } else {
    URL = `${url.ENDPOINT_SEARCH_REVIEWER_PT}?nidn=${payload.nidn}&institusi=${payload.id_institusi}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

// search reviewer dengan filter bisa cari reviewer hanya nasional (ini di OPT PUSAT)
export const searchReviewerPenelitianPusat = async payload => {
  let URL;
  if (window.location.pathname.includes("/operator/pengelolaan-reviewer")) {
    URL = `${url.ENDPOINT_SEARCH_REVIEWER_PUSAT}?nidn=${payload.nidn}`;
  } else {
    URL = `${url.ENDPOINT_SEARCH_REVIEWER_PUSAT}?nidn=${payload.nidn}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

export const searchReviewerPengabdianPusat = async payload => {
  let URL;
  if (window.location.pathname.includes("/operator/pengelolaan-reviewer")) {
    URL = `${url.ENDPOINT_SEARCH_REVIEWER_PENGABDIAN_PUSAT}?nidn=${payload.nidn}`;
  } else {
    URL = `${url.ENDPOINT_SEARCH_REVIEWER_PENGABDIAN_PUSAT}?nidn=${payload.nidn}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

// Plotting reviewer penelitian
export const postPlottingReviewer = async payload => {
  return instances.request(`/hibah/reviewer-penelitian/plotting`, {
    method: "POST",
    data: payload,
  });
};
export const checkVokasiDiktiDaftarReviewer = async id => {
  const res = await instances.request(`/vokasi/prodi/${id}`, {
    method: "GET",
  });
  return res;
};
// Plotting Reviewer V2
function removeNullOrUndefinedProperties(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
}

export const crudPlottingReviewerV2 = payload => {
  if (payload.method === "GET") {
    if (payload.options.type === "get-list-rekap") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/proposal/operator/rekap?thn_pelaksanaan=${payload.queryParams.thn_pelaksanaan}&kode_jenis_kegiatan=${payload.queryParams.kode_jenis_kegiatan}&id_institusi=${payload.queryParams.id_institusi}&thn_usulan=${payload.queryParams.thn_usulan}&kd_tahapan=${payload.queryParams.kd_tahapan}`,
        method: "GET",
      });
    } else if (payload.options.type === "get-plotting-detail") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/proposal/operator/protting-detail?${new URLSearchParams(
          removeNullOrUndefinedProperties(payload.queryParams)
        ).toString()}`,
        method: "GET",
      });
    } else if (payload.options.type === "get-detail-reviewer") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/review/detail?${new URLSearchParams(
          removeNullOrUndefinedProperties(payload.queryParams)
        ).toString()}`,
        method: "GET",
      });
    } else if (payload.options.type === "search-reviewer-by-nidn") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/review/detail?nidn=${payload.queryParams.nidn}`,
        method: "GET",
      });
    } else if (payload.options.type === "get-plotting-detail-by-id-personal") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/proposal/operator/protting-detail?thn_pelaksanaan=${payload.queryParams.thn_pelaksanaan}&kode_jenis_kegiatan=${payload.queryParams.kode_jenis_kegiatan}&kd_tahapan=${payload.queryParams.kd_tahapan}&is_pusat=${payload.queryParams.is_pusat}&id_personal_rev1=${payload.queryParams.id_personal_rev1}`,
        method: "GET",
      });
    } else if (payload.options.type === "monitoring-penilaian") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/proposal/operator/monitoring-plotting-reviewer?${new URLSearchParams(
          removeNullOrUndefinedProperties(payload.queryParams)
        ).toString()}`,
        method: "GET",
      });
    } else if (payload.options.type === "monitoring-penilaian-detail") {
      return instances.request({
        baseURL: API_V2_URL,
        url: `/proposal/operator/monitoring-plotting-reviewer-detail?${new URLSearchParams(
          removeNullOrUndefinedProperties(payload.queryParams)
        ).toString()}`,
        method: "GET",
      });
    }
  } else if (payload.method === "POST") {
    return instances.request({
      baseURL: API_V2_URL,
      url: `/review/plotting-reviewer`,
      method: "POST",
      data: payload.data,
    });
  }
};
export const getConfigTahapanPlottingReviewerV2 = async ({
  tipe_operator = "pusat",
}) => {
  let url = "";
  if (tipe_operator === "pusat") {
    url = `/enumeration?criteria=group(exact):config_plotting_proposal`;
  } else if (tipe_operator === "pt") {
    url = `/enumeration?key=config-plotting-proposal-v1&criteria=group(exact):config_plotting_proposal`;
  }
  const res = await instances.request(url, {
    method: "GET",
  });
  return res;
};
