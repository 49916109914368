import {
    CREATE_PENUGASAN_KOSABANGSA_FAILURE, CREATE_PENUGASAN_KOSABANGSA_REQUEST,
    CREATE_PENUGASAN_KOSABANGSA_SUCCESS, GET_PENUGASAN_KOSABANGSA_BYID_FAILURE,
    GET_PENUGASAN_KOSABANGSA_BYID_REQUEST, GET_PENUGASAN_KOSABANGSA_BYID_SUCCESS, 
    GET_PENUGASAN_KOSABANGSA_FAILURE, GET_PENUGASAN_KOSABANGSA_REQUEST,
    GET_PENUGASAN_KOSABANGSA_SUCCESS, UPDATE_PENUGASAN_KOSABANGSA_FAILURE, UPDATE_PENUGASAN_KOSABANGSA_REQUEST,
    UPDATE_PENUGASAN_KOSABANGSA_SUCCESS
} from "../actionTypes";

export const createPenugasanKosaBangsa = (request = {}, cb = () => { }) => {
    return {
        type: CREATE_PENUGASAN_KOSABANGSA_REQUEST,
        payload: request,
        cb
    };
}

export const createPenugasanKosaBangsaSuccess = response => {
    return {
        type: CREATE_PENUGASAN_KOSABANGSA_SUCCESS,
        payload: response,
    };
}

export const createPenugasanKosaBangsaFailure = (message, response) => {
    return {
        type: CREATE_PENUGASAN_KOSABANGSA_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanKosaBangsa = (request = {}) => {
    return {
        type: GET_PENUGASAN_KOSABANGSA_REQUEST,
        payload: request,
    };
}

export const getPenugasanKosaBangsaSuccess = response => {
    return {
        type: GET_PENUGASAN_KOSABANGSA_SUCCESS,
        payload: response,
    };
}

export const getPenugasanKosaBangsaFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_KOSABANGSA_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanKosaBangsaById = (id, cb = () => { }) => {

    return {
        type: GET_PENUGASAN_KOSABANGSA_BYID_REQUEST,
        payload: { id },
        cb
    };
}

export const getPenugasanKosaBangsaByIdSuccess = response => {
    return {
        type: GET_PENUGASAN_KOSABANGSA_BYID_SUCCESS,
        payload: response,
    };
}

export const getPenugasanKosaBangsaByIdFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_KOSABANGSA_BYID_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const updatePenugasanKosaBangsa = (request = {}, id, cb = () => { }) => {
    return {
        type: UPDATE_PENUGASAN_KOSABANGSA_REQUEST,
        payload: request,
        cb,
        id
    };
}

export const updatePenugasanKosaBangsaSuccess = response => {
    return {
        type: UPDATE_PENUGASAN_KOSABANGSA_SUCCESS,
        payload: response,
    };
}

export const updatePenugasanKosaBangsaFailure = (message, response) => {
    return {
        type: UPDATE_PENUGASAN_KOSABANGSA_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}