import {
	GET_SKEMA_SITE_VISIT_FAILURE,
	GET_SKEMA_SITE_VISIT_REQUEST,
	GET_SKEMA_SITE_VISIT_SUCCESS,
	SET_CLEAR_SKEMA_SITE_VISIT,
	GET_LIST_USULAN_SITE_VISIT_FAILURE,
	GET_LIST_USULAN_SITE_VISIT_REQUEST,
	GET_LIST_USULAN_SITE_VISIT_SUCCESS,
	GET_DETAIL_USULAN_SITE_VISIT_FAILURE,
	GET_DETAIL_USULAN_SITE_VISIT_REQUEST,
	GET_DETAIL_USULAN_SITE_VISIT_SUCCESS,
	GET_KOMPONEN_NILAI_SITE_VISIT_FAILURE,
	GET_KOMPONEN_NILAI_SITE_VISIT_REQUEST,
	GET_KOMPONEN_NILAI_SITE_VISIT_SUCCESS,
	SAVE_PENILAIAN_SITE_VISIT_REQUEST,
	SAVE_PENILAIAN_PERMANEN_SITE_VISIT_REQUEST,
} from "./actionTypes";

// SkemaEvaluasi
export const setClearSkemaSiteVisit = (request = {}, cb) => {
	return {
		type: SET_CLEAR_SKEMA_SITE_VISIT,
		payload: request,
		cb
	};
};

export const getSkemaSiteVisit = (request = {}, cb) => {
	return {
		type: GET_SKEMA_SITE_VISIT_REQUEST,
		payload: request,
		cb
	};
};

export const getSkemaSiteVisitSuccess = response => {
	return {
		type: GET_SKEMA_SITE_VISIT_SUCCESS,
		payload: response,
	};
};

export const getSkemaSiteVisitFailure = (message, response) => {
	return {
		type: GET_SKEMA_SITE_VISIT_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

//list usulan
export const getListUsulanSiteVisit = (request = {}, cb) => {
	return {
		type: GET_LIST_USULAN_SITE_VISIT_REQUEST,
		payload: request,
		cb
	};
};

export const getListUsulanSiteVisitSuccess = response => {
	return {
		type: GET_LIST_USULAN_SITE_VISIT_SUCCESS,
		payload: response,
	};
};

export const getListUsulanSiteVisitFailure = (message, response) => {
	return {
		type: GET_LIST_USULAN_SITE_VISIT_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

//detail usulan
export const getDetailUsulanSiteVisit = (request = {}, cb) => {
	return {
		type: GET_DETAIL_USULAN_SITE_VISIT_REQUEST,
		payload: request,
		cb
	};
};

export const getDetailUsulanSiteVisitSuccess = response => {
	return {
		type: GET_DETAIL_USULAN_SITE_VISIT_SUCCESS,
		payload: response,
	};
};

export const getDetailUsulanSiteVisitFailure = (message, response) => {
	return {
		type: GET_DETAIL_USULAN_SITE_VISIT_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

// Komponen NIlai
export const getKomponenNilaiSiteVisit = (request = {}, cb) => {
	return {
		type: GET_KOMPONEN_NILAI_SITE_VISIT_REQUEST,
		payload: request,
		cb
	};
};

export const getKomponenNilaiSiteVisitSuccess = response => {
	return {
		type: GET_KOMPONEN_NILAI_SITE_VISIT_SUCCESS,
		payload: response,
	};
};

export const getKomponenNilaiSiteVisitFailure = (message, response) => {
	return {
		type: GET_KOMPONEN_NILAI_SITE_VISIT_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

export const savePenilaianSiteVisit = (request = {}, cb) => {
	return {
		type: SAVE_PENILAIAN_SITE_VISIT_REQUEST,
		payload: request,
		cb
	};
};

export const savePenilaianPermanenSiteVisit = (request = {}, cb) => {
	return {
		type: SAVE_PENILAIAN_PERMANEN_SITE_VISIT_REQUEST,
		payload: request,
		cb
	};
};