import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAILURE,
  NOTIFICATION_COMPLETE_LIST_REQUEST,
  NOTIFICATION_COMPLETE_LIST_SUCCESS,
  NOTIFICATION_COMPLETE_LIST_FAILURE,
  NOTIFICATION_TYPE_REQUEST,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_FAILURE,
  PERSONEL_INVITATION_DETAIL_REQUEST,
  PERSONEL_INVITATION_DETAIL_SUCCESS,
  PERSONEL_INVITATION_DETAIL_FAILURE,
  PATCH_NOTIFICATION_REQUEST,
  PATCH_NOTIFICATION_SUCCESS,
  PATCH_NOTIFICATION_FAILURE,
  PATCH_PERSONEL_INVITATION_REQUEST,
  PATCH_PERSONEL_INVITATION_SUCCESS,
  PATCH_PERSONEL_INVITATION_FAILURE,
  PATCH_PERSONEL_INVITATION_V2_REQUEST,
  PATCH_PERSONEL_INVITATION_V2_SUCCESS,
  PATCH_PERSONEL_INVITATION_V2_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  notificationCompleteListIsFetching: false,
  notificationTypeIsFetching: false,
  invitationDetailIsFetching: false,
  patchNotificationIsFetching: false,
  patchPersonelInvitationIsFetching: false,
  errorMessage: "",
  errors: null,
  items: [],
  notificationCompleteList: [],
  notificationType: [],
  invitationDetail: {},
  patchNotification: [],
  patchPersonelInvitation: [],
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case NOTIFICATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // notification complete list
    case NOTIFICATION_COMPLETE_LIST_REQUEST:
      return {
        ...state,
        notificationCompleteListIsFetching: true,
        notificationCompleteList: [],
      };
    case NOTIFICATION_COMPLETE_LIST_SUCCESS:
      return {
        ...state,
        notificationCompleteListIsFetching: false,
        notificationCompleteList: action.payload,
      };
    case NOTIFICATION_COMPLETE_LIST_FAILURE:
      return {
        ...state,
        notificationCompleteListIsFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // notification type
    case NOTIFICATION_TYPE_REQUEST:
      return {
        ...state,
        notificationTypeIsFetching: true,
      };
    case NOTIFICATION_TYPE_SUCCESS:
      return {
        ...state,
        notificationTypeIsFetching: false,
        notificationType: action.payload,
      };
    case NOTIFICATION_TYPE_FAILURE:
      return {
        ...state,
        notificationTypeIsFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // invitation detail
    case PERSONEL_INVITATION_DETAIL_REQUEST:
      return {
        ...state,
        invitationDetailIsFetching: true,
        invitationDetail: {},
      };
    case PERSONEL_INVITATION_DETAIL_SUCCESS:
      return {
        ...state,
        invitationDetailIsFetching: false,
        invitationDetail: action.payload || {},
      };
    case PERSONEL_INVITATION_DETAIL_FAILURE:
      return {
        ...state,
        invitationDetailIsFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // patch notification
    case PATCH_NOTIFICATION_REQUEST:
      return {
        ...state,
        patchNotificationIsFetching: true,
      };
    case PATCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        patchNotificationIsFetching: false,
        patchNotification: action.payload || [],
      };
    case PATCH_NOTIFICATION_FAILURE:
      return {
        ...state,
        patchNotificationIsFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // patch personel invitation
    case PATCH_PERSONEL_INVITATION_REQUEST:
      return {
        ...state,
        patchPersonelInvitationIsFetching: true,
      };
    case PATCH_PERSONEL_INVITATION_SUCCESS:
      return {
        ...state,
        patchPersonelInvitationIsFetching: false,
        patchPersonelInvitation: action.payload || [],
      };
    case PATCH_PERSONEL_INVITATION_FAILURE:
      return {
        ...state,
        patchPersonelInvitationIsFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // patch personel invitation v2 (state yang digunakan sama)
    case PATCH_PERSONEL_INVITATION_V2_REQUEST:
      return {
        ...state,
        patchPersonelInvitationIsFetching: true,
      };
    case PATCH_PERSONEL_INVITATION_V2_SUCCESS:
      return {
        ...state,
        patchPersonelInvitationIsFetching: false,
        patchPersonelInvitation: action.payload || [],
      };
    case PATCH_PERSONEL_INVITATION_V2_FAILURE:
      return {
        ...state,
        patchPersonelInvitationIsFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    default:
      return state;
  }
};

export default notification;
