import {
  GET_PENGABDIAN_REQUEST,
  GET_PENGABDIAN_SUCCESS,
  GET_PENGABDIAN_FAILURE
} from "./actionTypes";

// Pengabdian
export const getPengabdian = (request = {},cb) => {
  return {
    type: GET_PENGABDIAN_REQUEST,
    payload: request,
    cb
  };
};

export const getPengabdianSuccess = response => {
  return {
    type: GET_PENGABDIAN_SUCCESS,
    payload: response,
  };
};

export const getPengabdianFailure = (message, response) => {
  return {
    type: GET_PENGABDIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
