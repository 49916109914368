export const UPLOAD_DOKUMEN_REQUEST = "UPLOAD_DOKUMEN_REQUEST";
export const UPLOAD_DOKUMEN_SUCCESS = "UPLOAD_DOKUMEN_SUCCESS";
export const UPLOAD_DOKUMEN_FAILURE = "UPLOAD_DOKUMEN_FAILURE";

export const UPLOAD_DOKUMEN_SPTB_REQUEST = "UPLOAD_DOKUMEN_SPTB_REQUEST";
export const UPLOAD_DOKUMEN_SPTB_SUCCESS = "UPLOAD_DOKUMEN_SPTB_SUCCESS";
export const UPLOAD_DOKUMEN_SPTB_FAILURE = "UPLOAD_DOKUMEN_SPTB_FAILURE";

export const LIST_LAPORAN_KEMAJUAN_REQUEST = "LIST_LAPORAN_KEMAJUAN_REQUEST";
export const LIST_LAPORAN_KEMAJUAN_SUCCESS = "LIST_LAPORAN_KEMAJUAN_SUCCESS";
export const LIST_LAPORAN_KEMAJUAN_FAILURE = "LIST_LAPORAN_KEMAJUAN_FAILURE";

export const LAPORAN_KEMAJUAN_DETAIL_REQUEST =
  "LAPORAN_KEMAJUAN_DETAIL_REQUEST";
export const LAPORAN_KEMAJUAN_DETAIL_SUCCESS =
  "LAPORAN_KEMAJUAN_DETAIL_SUCCESS";
export const LAPORAN_KEMAJUAN_DETAIL_FAILURE =
  "LAPORAN_KEMAJUAN_DETAIL_FAILURE";

export const POST_LAPORAN_KEMAJUAN_REQUEST = "POST_LAPORAN_KEMAJUAN_REQUEST";
export const POST_LAPORAN_KEMAJUAN_SUCCESS = "POST_LAPORAN_KEMAJUAN_SUCCESS";
export const POST_LAPORAN_KEMAJUAN_FAILURE = "POST_LAPORAN_KEMAJUAN_FAILURE";

export const UPDATE_LAPORAN_KEMAJUAN_REQUEST =
  "UPDATE_LAPORAN_KEMAJUAN_REQUEST";
export const UPDATE_LAPORAN_KEMAJUAN_SUCCESS =
  "UPDATE_LAPORAN_KEMAJUAN_SUCCESS";
export const UPDATE_LAPORAN_KEMAJUAN_FAILURE =
  "UPDATE_LAPORAN_KEMAJUAN_FAILURE";

export const CREATE_LAPORAN_KEMAJUAN_REQUEST =
  "CREATE_LAPORAN_KEMAJUAN_REQUEST";
export const CREATE_LAPORAN_KEMAJUAN_SUCCESS =
  "CREATE_LAPORAN_KEMAJUAN_SUCCESS";
export const CREATE_LAPORAN_KEMAJUAN_FAILURE =
  "CREATE_LAPORAN_KEMAJUAN_FAILURE";

export const GET_CRITERIA_REQUEST = "GET_CRITERIA_REQUEST";
export const GET_CRITERIA_SUCCESS = "GET_CRITERIA_SUCCESS";
export const GET_CRITERIA_FAILURE = "GET_CRITERIA_FAILURE";

export const GET_TARGET_JENIS_LUARAN_REQUEST =
  "GET_TARGET_JENIS_LUARAN_REQUEST";
export const GET_TARGET_JENIS_LUARAN_SUCCESS =
  "GET_TARGET_JENIS_LUARAN_SUCCESS";
export const GET_TARGET_JENIS_LUARAN_FAILURE =
  "GET_TARGET_JENIS_LUARAN_FAILURE";

export const GET_DOKUMEN_LUARAN_REQUEST = "GET_DOKUMEN_LUARAN_REQUEST";
export const GET_DOKUMEN_LUARAN_SUCCESS = "GET_DOKUMEN_LUARAN_SUCCESS";
export const GET_DOKUMEN_LUARAN_FAILURE = "GET_DOKUMEN_LUARAN_FAILURE";

export const RESET_LAPORAN_KEMAJUAN_DETAIL = "RESET_LAPORAN_KEMAJUAN_DETAIL";

export const UPLOAD_DOKUMEN_MITRA_REQUEST = "UPLOAD_DOKUMEN_MITRA_REQUEST";
export const UPLOAD_DOKUMEN_MITRA_SUCCESS = "UPLOAD_DOKUMEN_MITRA_SUCCESS";
export const UPLOAD_DOKUMEN_MITRA_FAILURE = "UPLOAD_DOKUMEN_MITRA_FAILURE";

export const GET_DOKUMEN_MITRA_REQUEST = "GET_DOKUMEN_MITRA_REQUEST";
export const GET_DOKUMEN_MITRA_SUCCESS = "GET_DOKUMEN_MITRA_SUCCESS";
export const GET_DOKUMEN_MITRA_FAILURE = "GET_DOKUMEN_MITRA_FAILURE";

export const GET_BERKAS_LAPORAN_KEMAJUAN_REQUEST =
  "GET_BERKAS_LAPORAN_KEMAJUAN_REQUEST";
export const GET_BERKAS_LAPORAN_KEMAJUAN_SUCCESS =
  "GET_BERKAS_LAPORAN_KEMAJUAN_SUCCESS";
export const GET_BERKAS_LAPORAN_KEMAJUAN_FAILURE =
  "GET_BERKAS_LAPORAN_KEMAJUAN_FAILURE";

// Laporan kemajuan v2
export const GET_LIST_LAPORAN_KEMAJUAN_V2_REQUEST =
  "GET_LIST_LAPORAN_KEMAJUAN_V2_REQUEST";
export const GET_LIST_LAPORAN_KEMAJUAN_V2_SUCCESS =
  "GET_LIST_LAPORAN_KEMAJUAN_V2_SUCCESS";
export const GET_LIST_LAPORAN_KEMAJUAN_V2_FAILURE =
  "GET_LIST_LAPORAN_KEMAJUAN_V2_FAILURE";

export const GET_DETAIL_LAPORAN_KEMAJUAN_V2_REQUEST =
  "GET_DETAIL_LAPORAN_KEMAJUAN_V2_REQUEST";
export const GET_DETAIL_LAPORAN_KEMAJUAN_V2_SUCCESS =
  "GET_DETAIL_LAPORAN_KEMAJUAN_V2_SUCCESS";
export const GET_DETAIL_LAPORAN_KEMAJUAN_V2_FAILURE =
  "GET_DETAIL_LAPORAN_KEMAJUAN_V2_FAILURE";

export const UPDATE_LAPORAN_KEMAJUAN_V2_REQUEST =
  "UPDATE_LAPORAN_KEMAJUAN_V2_REQUEST";
export const UPDATE_LAPORAN_KEMAJUAN_V2_SUCCESS =
  "UPDATE_LAPORAN_KEMAJUAN_V2_SUCCESS";
export const UPDATE_LAPORAN_KEMAJUAN_V2_FAILURE =
  "UPDATE_LAPORAN_KEMAJUAN_V2_FAILURE";
