import utilities from "helpers/utilities";
import { instances } from "../api_helper";
import * as url from "../url_helper";
const API_V2_URL =
  process.env.REACT_APP_API_V2_URL || "http://localhost:8088/api/v2";
const getCatatanHarian = ({ id_usulan_kegiatan }) => {
  // let filterDate = ''
  // if (id_usulan_kegiatan) {
  //   filterDate = `,year=${year},month=${month}`
  // } else {
  //   filterDate = `?criteria=year=${year},month=${month}`
  // }

  return instances.request(
    `${url.ENDPOINT_CATATAN_HARIAN}${
      id_usulan_kegiatan
        ? `?criteria=id_usulan_kegiatan:${id_usulan_kegiatan}&page=1:1000&sort=tgl_pelaksanaan:desc`
        : ""
    }`,
    {
      method: "GET",
    }
  );
};

const createCatatanHarian = ({
  persentase_capaian,
  tgl_pelaksanaan,
  kegiatan_yg_dilakukan,
  id_usulan_kegiatan,
  rab_kelompok_biaya_id,
  no_berkas,
  biaya_yg_terpakai,
}) => {
  const payload = {
    persentase_capaian,
    tgl_pelaksanaan,
    kegiatan_yg_dilakukan,
    id_usulan_kegiatan,
    rab_kelompok_biaya_id,
    no_berkas,
    biaya_yg_terpakai,
  };

  return instances.request(`${url.ENDPOINT_CATATAN_HARIAN}`, {
    method: "POST",
    data: payload,
  });
};

const deleteCatatanHarian = ({ id }) => {
  return instances.request(`${url.ENDPOINT_CATATAN_HARIAN}/${id}`, {
    method: "DELETE",
  });
};

const deleteBerkasCatatanHarian = ({ id }) => {
  return instances.request(`${url.ENDPOINT_UPLOAD_CATATAN_HARIAN}/${id}`, {
    method: "DELETE",
  });
};

const editCatatanHarian = ({
  id,
  persentase_capaian,
  tgl_pelaksanaan,
  kegiatan_yg_dilakukan,
  id_usulan_kegiatan,
  rab_kelompok_biaya_id,
  no_berkas,
  biaya_yg_terpakai,
}) => {
  return instances.request(`${url.ENDPOINT_CATATAN_HARIAN}/${id}`, {
    method: "PUT",
    data: {
      id,
      persentase_capaian,
      tgl_pelaksanaan,
      kegiatan_yg_dilakukan,
      id_usulan_kegiatan,
      rab_kelompok_biaya_id,
      no_berkas,
      biaya_yg_terpakai,
    },
  });
};

const uploadCatatanHarian = ({ file, id_catatan_harian }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("id_catatan_harian", id_catatan_harian);
  return instances.request(`${url.ENDPOINT_UPLOAD_CATATAN_HARIAN}`, {
    method: "POST",
    data: formData,
  });
};
// Catatan Harian v2
const crudCatatanHarianV2 = payload => {
  if (payload.method === "GET") {
    if (payload.options.type === "get-list") {
      return instances.request({
        baseURL: API_V2_URL,
        method: "GET",
        url: `/catatan-harian/proposal-list?${new URLSearchParams(
          utilities.removeNullOrUndefinedProperties(payload.queryParams)
        )}`,
      });
    } else if (payload.options.type === "get-by-id") {
      return instances.request({
        baseURL: API_V2_URL,
        method: "GET",
        url: `/catatan-harian/by-proposal-kegiatan/${payload.queryParams.id_proposal_kegiatan}`,
      });
    }
  } else if (payload.method === "POST") {
    return instances.request({
      baseURL: API_V2_URL,
      url: `/catatan-harian`,
      method: "POST",
      data: payload.data,
    });
  } else if (payload.method === "PUT") {
    return instances.request({
      baseURL: API_V2_URL,
      url: `/catatan-harian/${payload.queryParams.id_catatan_harian}`,
      method: "PUT",
      data: payload.data,
    });
  } else if (payload.method === "DELETE") {
    return instances.request({
      baseURL: API_V2_URL,
      url: `/catatan-harian/${payload.queryParams.id_catatan_harian}`,
      method: "DELETE",
    });
  }
};
// For (No Saga)
export const uploadFileCatatanHarianV2 = async payload => {
  const formData = new FormData();
  formData.append("path", payload.path);
  formData.append("file", payload.file);
  const res = await instances.request("/file", {
    method: "POST",
    data: formData,
  });
  return res;
};
export {
  getCatatanHarian,
  createCatatanHarian,
  deleteCatatanHarian,
  editCatatanHarian,
  uploadCatatanHarian,
  deleteBerkasCatatanHarian,
  crudCatatanHarianV2,
};
