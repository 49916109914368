import {
  GET_INSTITUSI_FAILURE,
  GET_INSTITUSI_REQUEST,
  GET_INSTITUSI_SUCCESS,
  GET_SKEMA_EVALUASI_FAILURE,
  GET_SKEMA_EVALUASI_REQUEST,
  GET_SKEMA_EVALUASI_SUCCESS,
  SET_CLEAR_SKEMA_EVALUASI,
  GET_LIST_USULAN_EVALUASI_FAILURE,
  GET_LIST_USULAN_EVALUASI_REQUEST,
  GET_LIST_USULAN_EVALUASI_SUCCESS,
  GET_DETAIL_USULAN_EVALUASI_FAILURE,
  GET_DETAIL_USULAN_EVALUASI_REQUEST,
  GET_DETAIL_USULAN_EVALUASI_SUCCESS,
  GET_KOMPONEN_NILAI_EVALUASI_FAILURE,
  GET_KOMPONEN_NILAI_EVALUASI_REQUEST,
  GET_KOMPONEN_NILAI_EVALUASI_SUCCESS,
  SAVE_PENILAIAN_EVALUASI_REQUEST,
  SAVE_PENILAIAN_PERMANEN_EVALUASI_REQUEST,
  GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_REQUEST,
  GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_SUCCESS,
  GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_FAILURE,
  GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
  GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
  GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
  GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
  SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
  SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
  SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_REQUEST,
  SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_SUCCESS,
  SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_FAILURE,
} from "./actionTypes";

// InstitusiByYears
export const getInstitusiByYears = (request = {}, cb) => {
  return {
    type: GET_INSTITUSI_REQUEST,
    payload: request,
    cb,
  };
};

export const getInstitusiByYearsSuccess = response => {
  return {
    type: GET_INSTITUSI_SUCCESS,
    payload: response,
  };
};

export const getInstitusiByYearsFailure = (message, response) => {
  return {
    type: GET_INSTITUSI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// SkemaEvaluasi
export const setClearSkemaEvaluasi = (request = {}, cb) => {
  return {
    type: SET_CLEAR_SKEMA_EVALUASI,
    payload: request,
    cb,
  };
};

export const getSkemaEvaluasi = (request = {}, cb) => {
  return {
    type: GET_SKEMA_EVALUASI_REQUEST,
    payload: request,
    cb,
  };
};

export const getSkemaEvaluasiSuccess = response => {
  return {
    type: GET_SKEMA_EVALUASI_SUCCESS,
    payload: response,
  };
};

export const getSkemaEvaluasiFailure = (message, response) => {
  return {
    type: GET_SKEMA_EVALUASI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//list usulan
export const getListUsulanEvaluasi = (request = {}, cb) => {
  return {
    type: GET_LIST_USULAN_EVALUASI_REQUEST,
    payload: request,
    cb,
  };
};

export const getListUsulanEvaluasiSuccess = response => {
  return {
    type: GET_LIST_USULAN_EVALUASI_SUCCESS,
    payload: response,
  };
};

export const getListUsulanEvaluasiFailure = (message, response) => {
  return {
    type: GET_LIST_USULAN_EVALUASI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//detail usulan
export const getDetailUsulanEvaluasi = (request = {}, cb) => {
  return {
    type: GET_DETAIL_USULAN_EVALUASI_REQUEST,
    payload: request,
    cb,
  };
};

export const getDetailUsulanEvaluasiSuccess = response => {
  return {
    type: GET_DETAIL_USULAN_EVALUASI_SUCCESS,
    payload: response,
  };
};

export const getDetailUsulanEvaluasiFailure = (message, response) => {
  return {
    type: GET_DETAIL_USULAN_EVALUASI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Komponen NIlai
export const getKomponenNilaiEvaluasi = (request = {}, cb) => {
  return {
    type: GET_KOMPONEN_NILAI_EVALUASI_REQUEST,
    payload: request,
    cb,
  };
};

export const getKomponenNilaiEvaluasiSuccess = response => {
  return {
    type: GET_KOMPONEN_NILAI_EVALUASI_SUCCESS,
    payload: response,
  };
};

export const getKomponenNilaiEvaluasiFailure = (message, response) => {
  return {
    type: GET_KOMPONEN_NILAI_EVALUASI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const savePenilaianEvaluasi = (request = {}, cb) => {
  return {
    type: SAVE_PENILAIAN_EVALUASI_REQUEST,
    payload: request,
    cb,
  };
};

export const savePenilaianPermanenEvaluasi = (request = {}, cb) => {
  return {
    type: SAVE_PENILAIAN_PERMANEN_EVALUASI_REQUEST,
    payload: request,
    cb,
  };
};

// Evaluasi prototipe admistrasi
export const getlistUsulanEvaluasiV2 = (
  request = {},
  cb
) => {
  return {
    type: GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_REQUEST,
    payload: request,
    cb,
  };
};
export const getlistUsulanEvaluasiV2Success = response => {
  return {
    type: GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_SUCCESS,
    payload: response,
  };
};

export const getlistUsulanEvaluasiV2Failure = (
  message,
  response
) => {
  return {
    type: GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const getKomponenNilaiEvalusiAdministrasiPrototipeV2 = (
  request = {},
  cb
) => {
  return {
    type: GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
    payload: request,
    cb,
  };
};
export const getKomponenNilaiEvalusiAdministrasiPrototipeV2Success =
  response => {
    return {
      type: GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
      payload: response,
    };
  };

export const getKomponenNilaiEvalusiAdministrasiPrototipeV2Failure = (
  message,
  response
) => {
  return {
    type: GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const getDetailEvalusiAdministrasiPrototipeV2 = (request = {}, cb) => {
  return {
    type: GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
    payload: request,
    cb,
  };
};
export const getDetailEvalusiAdministrasiPrototipeV2Success = response => {
  return {
    type: GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
    payload: response,
  };
};

export const getDetailEvalusiAdministrasiPrototipeV2Failure = (
  message,
  response
) => {
  return {
    type: GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const saveEvaluasiAdministrasiPrototipeV2 = (
  request = {},
  cb,
  methodHttp
) => {
  return {
    type: SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
    payload: request,
    cb,
    methodHttp,
  };
};
export const saveEvaluasiAdministrasiPrototipeV2Success = response => {
  return {
    type: SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
    payload: response,
  };
};

export const saveEvaluasiAdministrasiPrototipeV2Failure = (
  message,
  response
) => {
  return {
    type: SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const savePermanentEvaluasiPrototipeV2 = (request = {}, cb) => {
  return {
    type: SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_REQUEST,
    payload: request,
    cb,
  };
};
export const savePermanentEvaluasiPrototipeV2Success = response => {
  return {
    type: SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_SUCCESS,
    payload: response,
  };
};

export const savePermanentEvaluasiPrototipeV2Failure = (message, response) => {
  return {
    type: SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
