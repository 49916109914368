import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";

import {
  GET_PENGABDIAN_REQUEST
} from "./actionTypes";
// Action
import {
  getPengabdianSuccess,
  getPengabdianFailure
} from "./action";

function* getPengabdian({ payload, cb=()=>{} }) {
  try {
    const response = yield call(backend.getPengabdian, payload);
    if(response.data.code == 200){
      let state =''
      const isGetById = response.config.url.split('/usulan/').length
      if(isGetById > 1){
        state = 'detail'
      }
      cb(response.data.data)
      yield put(
        getPengabdianSuccess({
          data: response.data.data,
          state
        })
      );
    }else{
      throw new Error(response.data.message)
    }
  } catch (error) {
    const message = error.message;
    toast.error(message)
    yield put(getPengabdianFailure(message, error));
  }
}

function* usulanPenelitian() {
  yield takeEvery(GET_PENGABDIAN_REQUEST, getPengabdian);
}

export default usulanPenelitian;
