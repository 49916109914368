import { instances, instancesV2 } from "helpers/api_helper";
import * as url from "../url_helper";

export const getNotifications = ({
  limit,
  sortBy,
  isNotifDropdown,
  isRead,
}) => {
  let URL = "";
  if (isNotifDropdown) {
    URL = `${url.ENDPOINT_NOTIFICATION_LIST}?page=1:${limit}&sort=tgl_created:${sortBy}&criteria=read:${isRead}`;
  } else {
    URL = `${url.ENDPOINT_NOTIFICATION_LIST}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};
export const getNotificationCompleteList = ({
  sortBy,
  isRead,
  notificationType,
}) => {
  let URL = "";
  URL = `${url.ENDPOINT_NOTIFICATION_LIST}?page=1:1000&sort=tgl_created:${sortBy}&criteria=read:${isRead},type:${notificationType}`;
  return instances.request(URL, {
    method: "GET",
  });
};
export const getNotificationType = () => {
  return instances.request("/enumeration?criteria=group:notification-type", {
    method: "GET",
  });
};

export const getPersonelInvitationDetail = ({ id_usulan_kegiatan }) => {
  let URL = "";
  if (window.location.pathname.includes("/penelitian/")) {
    URL = url.ENDPOINT_PERSONEL_INVITATION_DETAIL;
  } else if (window.location.pathname.includes("/pengabdian/")) {
    URL = url.ENDPOINT_PERSONEL_INVITATION_PENGABDIAN_DETAIL;
  }
  return instances.request(`${URL}/${id_usulan_kegiatan}`, {
    method: "GET",
  });
};
// Baca notifikasi
export const patchNotification = payload => {
  return instances.request(
    `${url.ENDPOINT_PATCH_NOTIFICATION}/${payload.id_notifikasi}`,
    {
      method: "PATCH",
      data: payload.data,
    }
  );
};

// handle tolak atau setuju
export const patchPersonelInvitation = payload => {
  let URL = "";
  if (
    window.location.pathname.includes(
      "/kosabangsa/pendamping/personel-invitation"
    )
  ) {
    URL = `/kosabangsa/pendamping/invitation-approval/${payload.id}`;
  } else if (
    window.location.pathname.includes(
      "/kosabangsa/pelaksana/personel-invitation"
    )
  ) {
    URL = `/kosabangsa/pelaksana/invitation-approval/${payload.id}`;
  } else {
    URL = `${url.ENDPOINT_PATCH_INVITATION}/${payload.id_usulan_kegiatan}`;
  }
  return instances.request(URL, {
    method: "PATCH",
    data: payload.data,
  });
};
export const patchPersonelInvitationV2 = payload => {

  let URL = `/proposal/invitation-approval/${payload.id}`;
  return instancesV2.request({
    url: URL,
    method: "PATCH",
    data: payload.data,
  });
};
