export const GET_LIST_MINAT_KATALIS_OPT_REQUEST =
  "GET_LIST_MINAT_KATALIS_OPT_REQUEST";
export const GET_LIST_MINAT_KATALIS_OPT_SUCCESS =
  "GET_LIST_MINAT_KATALIS_OPT_SUCCESS";
export const GET_LIST_MINAT_KATALIS_OPT_FAILURE =
  "GET_LIST_MINAT_KATALIS_OPT_FAILURE";

export const UPDATE_MINAT_KATALIS_OPT_REQUEST =
  "UPDATE_MINAT_KATALIS_OPT_REQUEST";
export const UPDATE_MINAT_KATALIS_OPT_SUCCESS =
  "UPDATE_MINAT_KATALIS_OPT_SUCCESS";
export const UPDATE_MINAT_KATALIS_OPT_FAILURE =
  "UPDATE_MINAT_KATALIS_OPT_FAILURE";
