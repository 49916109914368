
import { content, contentPersetujuan } from "./content";
import { contentPebraikanUsulan } from "./contentPerbaikanUsulan";
import stylePDF from "./styles";

export const pdfCreate = (item) => {
    const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
    return {
        content: [
            ...content(item)
        ],
        defaultStyle,
        pageMargins,
        pageSize,
        styles,
    };
}

export const pdfCreatePersetujuan = (item) => {
    const { pageSize, styles, defaultStyle } = stylePDF;
    return {
        content: [
            ...contentPersetujuan((item?.status_approval || []).length > 0 ? item.status_approval[0] : {})
        ],
        defaultStyle,
        pageMargins: [45, 32, 45, 45],
        pageSize,
        styles,
    };
}

export const pdfCreatePerbaikanUsulan = ({item, supportData}) => {
    const { pageSize, styles, defaultStyle } = stylePDF;
    return {
        content: [
            ...contentPebraikanUsulan((item || {}), supportData)
        ],
        defaultStyle,
        pageMargins: [45, 32, 45, 45],
        pageSize,
        styles,
    };
}



