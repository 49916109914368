import {
  KOSABANGSA_DOSEN_REQUEST,
  KOSABANGSA_DOSEN_SUCCESS,
  KOSABANGSA_DOSEN_FAILURE,
  KOSABANGSA_BIDANG_FOKUS_PRIORITAS_REQUEST,
  KOSABANGSA_BIDANG_FOKUS_PRIORITAS_FAILURE,
  KOSABANGSA_BIDANG_FOKUS_PRIORITAS_SUCCESS,
  KOSABANGSA_BIDANG_FOKUS_LAINNYA_REQUEST,
  KOSABANGSA_BIDANG_FOKUS_LAINNYA_SUCCESS,
  KOSABANGSA_BIDANG_FOKUS_LAINNYA_FAILURE,
  KOSABANGSA_GET_PROVINSI_REQUEST,
  KOSABANGSA_GET_PROVINSI_SUCCESS,
  KOSABANGSA_GET_PROVINSI_FAILURE,
  KOSABANGSA_KABUPATEN_KOTA_REQUEST,
  KOSABANGSA_KABUPATEN_KOTA_SUCCESS,
  KOSABANGSA_KABUPATEN_KOTA_FAILURE,
  KOSABANGSA_KELOMPOK_MITRA_REQUEST,
  KOSABANGSA_KELOMPOK_MITRA_SUCCESS,
  KOSABANGSA_KELOMPOK_MITRA_FAILURE,
  KOSABANGSA_JENIS_PIMPINAN_PENETAP_REQUEST,
  KOSABANGSA_JENIS_PIMPINAN_PENETAP_SUCCESS,
  KOSABANGSA_JENIS_PIMPINAN_PENETAP_FAILURE,
  KOSABANGSA_SAVE_REQUEST,
  KOSABANGSA_SAVE_SUCCESS,
  KOSABANGSA_SAVE_FAILURE,
  KOSABANGSA_PENDAMPING_BYID_REQUEST,
  KOSABANGSA_PENDAMPING_BYID_SUCCESS,
  KOSABANGSA_PENDAMPING_BYID_FAILURE,
  KOSABANGSA_LIST_PENDAMPING_REQUEST,
  KOSABANGSA_LIST_PENDAMPING_SUCCESS,
  KOSABANGSA_LIST_PENDAMPING_FAILURE,
  KOSABANGSA_LIST_PELAKSANA_REQUEST,
  KOSABANGSA_LIST_PELAKSANA_SUCCESS,
  KOSABANGSA_LIST_PELAKSANA_FAILURE,
  KOSABANGSA_PELAKSANA_BYID_REQUEST,
  KOSABANGSA_PELAKSANA_BYID_SUCCESS,
  KOSABANGSA_PELAKSANA_BYID_FAILURE,
  KOSABANGSA_PUT_REQUEST,
  KOSABANGSA_PUT_SUCCESS,
  KOSABANGSA_PUT_FAILURE,
  KOSABANGSA_DELETE_REQUEST,
  KOSABANGSA_DELETE_SUCCESS,
  KOSABANGSA_DELETE_FAILURE,
  RESET_KOSABANGSA_PELAKSANA_BY_ID,
  KOSABANGSA_PELAKSANA_LIST_PENDAMPING_REQUEST,
  KOSABANGSA_PELAKSANA_LIST_PENDAMPING_SUCCESS,
  KOSABANGSA_PELAKSANA_LIST_PENDAMPING_FAILURE,
  RESET_KOSABANGSA_PELAKSANA_GET_PENDAMPING_BY_ID,
  GET_ELIGIBILITY_KOSABANGSA_REQUEST,
  GET_ELIGIBILITY_KOSABANGSA_SUCCESS,
  GET_ELIGIBILITY_KOSABANGSA_FAILURE,
  GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_REQUEST,
  GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_FAILURE,
  GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_SUCCESS,
  CLEAN_ELIGIBILITY_ANGGOTA_KOSABANGSA,
  APPROVAL_OP_PUSAT_KOSBANGSA_REQUEST,
  APPROVAL_OP_PUSAT_KOSBANGSA_SUCCESS,
  APPROVAL_OP_PUSAT_KOSBANGSA_FAILURE,
  GET_KOSABANGSA_MATCHED_REQUEST,
  GET_KOSABANGSA_MATCHED_SUCCESS,
  GET_KOSABANGSA_MATCHED_FAILURE
} from "./actionTypes";

const initialState = {
  dosen: {
    isFetching: false,
    items: [],
  },
  bidang_fokus_prioritas: {
    isFetching: false,
    items: [],
  },
  bidang_fokus_lainnya: {
    isFetching: false,
    items: [],
  },
  option_provinsi: {
    isFetching: false,
    items: [],
  },
  option_kabupaten_kota: {
    isFetching: false,
    items: [],
  },
  option_kelompok_mitra: {
    isFetching: false,
    items: [],
  },
  jenis_pimpinan_penetap: {
    isFetching: false,
    items: [],
  },
  submited_kosabangsa: {
    isFetching: false,
    item: {},
  },
  pendamping: {
    isFetching: false,
    item: {},
  },
  pendamping_list: {
    isFetching: false,
    items: [],
  },
  pelaksana: {
    isFetching: false,
    items: [],
  },
  pelaksana_list: {
    isFetching: false,
    items: [],
  },
  pelaksana_list_pendamping: {
    isFetching: false,
    items: [],
  },
  approval_op_pusat_kosabangsa: {
    isFetching: false,
    item: {},
  },
  eligibility_kosabangsa: {
    isFetching: false,
    item: {},
  },
  eligibility_anggota_kosabangsa: {
    isFetching: false,
    item: {},
  },
  kosabangsa_matched: {
    isFetching: false,
    item: {},
  },
};

const kosabangsa = (state = initialState, action) => {
  switch (action.type) {
    case GET_KOSABANGSA_MATCHED_REQUEST:
      return (state = {
        ...state,
        kosabangsa_matched: {
          isFetching: false,
          item: {},

        },
      });
    case GET_KOSABANGSA_MATCHED_SUCCESS:
      return (state = {
        ...state,
        kosabangsa_matched: {
          ...state.kosabangsa_matched,
          isFetching: false,
          item: action.payload.kosabangsa_pelaksana === null ? null : action.payload,
        },
      });
    case GET_KOSABANGSA_MATCHED_FAILURE:
      return (state = {
        ...state,
        kosabangsa_matched: {
          ...state.kosabangsa_matched,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case APPROVAL_OP_PUSAT_KOSBANGSA_REQUEST:
      return (state = {
        ...state,
        approval_op_pusat_kosabangsa: {
          isFetching: false,
          item: {},
          type: action.payload.type,
        },
      });
    case APPROVAL_OP_PUSAT_KOSBANGSA_SUCCESS:
      return (state = {
        ...state,
        approval_op_pusat_kosabangsa: {
          ...state.approval_op_pusat_kosabangsa,
          isFetching: false,
          type: action.payload.type,
          item: action.payload,
        },
      });
    case APPROVAL_OP_PUSAT_KOSBANGSA_FAILURE:
      return (state = {
        ...state,
        approval_op_pusat_kosabangsa: {
          ...state.approval_op_pusat_kosabangsa,
          isFetching: false,
          type: action.payload.type,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case CLEAN_ELIGIBILITY_ANGGOTA_KOSABANGSA:
      return (state = {
        ...state,
        eligibility_anggota_kosabangsa: {
          isFetching: false,
          item: {},
        },
      });
    case GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_REQUEST:
      return (state = {
        ...state,
        eligibility_anggota_kosabangsa: {
          ...state.eligibility_anggota_kosabangsa,
          isFetching: true,
          type: action.payload.type,
        },
      });
    case GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_SUCCESS:
      return (state = {
        ...state,
        eligibility_anggota_kosabangsa: {
          ...state.eligibility_anggota_kosabangsa,
          isFetching: false,
          type: action.payload.type,
          item: action.payload,
        },
      });
    case GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_FAILURE:
      return (state = {
        ...state,
        eligibility_anggota_kosabangsa: {
          ...state.eligibility_anggota_kosabangsa,
          isFetching: false,
          type: action.payload.type,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_ELIGIBILITY_KOSABANGSA_REQUEST:
      return (state = {
        ...state,
        eligibility_kosabangsa: {
          ...state.eligibility_kosabangsa,
          isFetching: true,
          type: action.payload.type,
        },
      });
    case GET_ELIGIBILITY_KOSABANGSA_SUCCESS:
      return (state = {
        ...state,
        eligibility_kosabangsa: {
          ...state.eligibility_kosabangsa,
          isFetching: false,
          type: action.payload.type,
          item: action.payload,
        },
      });
    case GET_ELIGIBILITY_KOSABANGSA_FAILURE:
      return (state = {
        ...state,
        eligibility_kosabangsa: {
          ...state.eligibility_kosabangsa,
          isFetching: false,
          type: action.payload.type,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_LIST_PENDAMPING_REQUEST:
      return (state = {
        ...state,
        pendamping_list: {
          ...state.pendamping_list,
          isFetching: true,
        },
      });
    case KOSABANGSA_LIST_PENDAMPING_SUCCESS:
      return (state = {
        ...state,
        pendamping_list: {
          ...state.pendamping_list,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_LIST_PENDAMPING_FAILURE:
      return (state = {
        ...state,
        pendamping_list: {
          ...state.pendamping_list,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_PENDAMPING_BYID_REQUEST:
      return (state = {
        ...state,
        pendamping: {
          ...state.pendamping,
          isFetching: true,
        },
      });
    case KOSABANGSA_PENDAMPING_BYID_SUCCESS:
      return (state = {
        ...state,
        pendamping: {
          ...state.pendamping,
          isFetching: false,
          item: action.payload,
        },
      });
    case KOSABANGSA_PENDAMPING_BYID_FAILURE:
      return (state = {
        ...state,
        pendamping: {
          ...state.pendamping,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_SAVE_REQUEST:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          type: action.payload.jenis,
          isFetching: true,
        },
      });
    case KOSABANGSA_SAVE_SUCCESS:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          isFetching: false,
          type: action.payload.jenis,
          item: action.payload,
        },
      });
    case KOSABANGSA_SAVE_FAILURE:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          isFetching: false,
          type: action.payload.jenis,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_PUT_REQUEST:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          type: action.payload.jenis,
          isFetching: true,
        },
      });
    case KOSABANGSA_PUT_SUCCESS:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          isFetching: false,
          type: action.payload.jenis,
          item: action.payload,
        },
      });
    case KOSABANGSA_PUT_FAILURE:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          isFetching: false,
          type: action.payload.jenis,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_DELETE_REQUEST:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          type: action.payload,
          isFetching: true,
        },
      });
    case KOSABANGSA_DELETE_SUCCESS:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          isFetching: false,
          type: action.payload,
          item: action.payload,
        },
      });
    case KOSABANGSA_DELETE_FAILURE:
      return (state = {
        ...state,
        submited_kosabangsa: {
          ...state.submited_kosabangsa,
          isFetching: false,
          type: action.payload,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_JENIS_PIMPINAN_PENETAP_REQUEST:
      return (state = {
        ...state,
        jenis_pimpinan_penetap: {
          ...state.jenis_pimpinan_penetap,
          isFetching: true,
        },
      });
    case KOSABANGSA_JENIS_PIMPINAN_PENETAP_SUCCESS:
      return (state = {
        ...state,
        jenis_pimpinan_penetap: {
          ...state.jenis_pimpinan_penetap,
          isFetching: false,
          items: action.payload,
        },
      });

    case KOSABANGSA_JENIS_PIMPINAN_PENETAP_FAILURE:
      return (state = {
        ...state,
        jenis_pimpinan_penetap: {
          ...state.jenis_pimpinan_penetap,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case KOSABANGSA_DOSEN_REQUEST:
      return (state = {
        ...state,
        dosen: {
          ...state.dosen,
          isFetching: true,
        },
      });
    case KOSABANGSA_DOSEN_SUCCESS:
      return (state = {
        ...state,
        dosen: {
          ...state.dosen,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_DOSEN_FAILURE:
      return (state = {
        ...state,
        dosen: {
          ...state.dosen,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_BIDANG_FOKUS_PRIORITAS_REQUEST:
      return (state = {
        ...state,
        bidang_fokus_prioritas: {
          ...state.bidang_fokus_prioritas,
          isFetching: true,
        },
      });
    case KOSABANGSA_BIDANG_FOKUS_PRIORITAS_SUCCESS:
      return (state = {
        ...state,
        bidang_fokus_prioritas: {
          ...state.bidang_fokus_prioritas,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_BIDANG_FOKUS_PRIORITAS_FAILURE:
      return (state = {
        ...state,
        bidang_fokus_prioritas: {
          ...state.bidang_fokus_prioritas,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_BIDANG_FOKUS_LAINNYA_REQUEST:
      return (state = {
        ...state,
        bidang_fokus_lainnya: {
          ...state.bidang_fokus_lainnya,
          isFetching: true,
        },
      });
    case KOSABANGSA_BIDANG_FOKUS_LAINNYA_SUCCESS:
      return (state = {
        ...state,
        bidang_fokus_lainnya: {
          ...state.bidang_fokus_lainnya,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_BIDANG_FOKUS_LAINNYA_FAILURE:
      return (state = {
        ...state,
        bidang_fokus_lainnya: {
          ...state.bidang_fokus_lainnya,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_GET_PROVINSI_REQUEST:
      return (state = {
        ...state,
        option_provinsi: {
          ...state.option_provinsi,
          isFetching: true,
        },
      });
    case KOSABANGSA_GET_PROVINSI_SUCCESS:
      return (state = {
        ...state,
        option_provinsi: {
          ...state.option_provinsi,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_GET_PROVINSI_FAILURE:
      return (state = {
        ...state,
        option_provinsi: {
          ...state.option_provinsi,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_KABUPATEN_KOTA_REQUEST:
      return (state = {
        ...state,
        option_kabupaten_kota: {
          ...state.option_kabupaten_kota,
          isFetching: true,
        },
      });
    case KOSABANGSA_KABUPATEN_KOTA_SUCCESS:
      return (state = {
        ...state,
        option_kabupaten_kota: {
          ...state.option_kabupaten_kota,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_KABUPATEN_KOTA_FAILURE:
      return (state = {
        ...state,
        option_kabupaten_kota: {
          ...state.option_kabupaten_kota,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_KELOMPOK_MITRA_REQUEST:
      return (state = {
        ...state,
        option_kelompok_mitra: {
          ...state.option_kelompok_mitra,
          isFetching: true,
        },
      });
    case KOSABANGSA_KELOMPOK_MITRA_SUCCESS:
      return (state = {
        ...state,
        option_kelompok_mitra: {
          ...state.option_kelompok_mitra,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_KELOMPOK_MITRA_FAILURE:
      return (state = {
        ...state,
        option_kelompok_mitra: {
          ...state.option_kelompok_mitra,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_LIST_PELAKSANA_REQUEST:
      return (state = {
        ...state,
        pelaksana_list: {
          ...state.pelaksana_list,
          isFetching: true,
        },
      });
    case KOSABANGSA_LIST_PELAKSANA_SUCCESS:
      return (state = {
        ...state,
        pelaksana_list: {
          ...state.pelaksana_list,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_LIST_PELAKSANA_FAILURE:
      return (state = {
        ...state,
        pelaksana_list: {
          ...state.pelaksana_list,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KOSABANGSA_PELAKSANA_BYID_REQUEST:
      return (state = {
        ...state,
        pelaksana: {
          ...state.pelaksana,
          isFetching: true,
          items: [],
        },
      });
    case KOSABANGSA_PELAKSANA_BYID_SUCCESS:
      return (state = {
        ...state,
        pelaksana: {
          ...state.pelaksana,
          isFetching: false,
          items: action.payload,
        },
      });
    case KOSABANGSA_PELAKSANA_BYID_FAILURE:
      return (state = {
        ...state,
        pelaksana: {
          ...state.pelaksana,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case RESET_KOSABANGSA_PELAKSANA_BY_ID:
      return (state = {
        ...state,
        pelaksana: {
          ...state.pelaksana,
          items: [],
        },
      });
    case RESET_KOSABANGSA_PELAKSANA_GET_PENDAMPING_BY_ID:
      return (state = {
        ...state,
        pendamping: {
          ...state.pendamping,
          item: {},
        },
      });
    case KOSABANGSA_PELAKSANA_LIST_PENDAMPING_REQUEST:
      return (state = {
        ...state,
        pelaksana_list_pendamping: {
          ...state.pelaksana_list_pendamping,
          isFetching: true,
          items: [],
          meta: {},
        },
      });
    case KOSABANGSA_PELAKSANA_LIST_PENDAMPING_SUCCESS:
      return (state = {
        ...state,
        pelaksana_list_pendamping: {
          ...state.pelaksana_list_pendamping,
          isFetching: false,
          items: action.payload.data,
          meta: action.payload.meta,
        },
      });
    case KOSABANGSA_PELAKSANA_LIST_PENDAMPING_FAILURE:
      return (state = {
        ...state,
        pelaksana_list_pendamping: {
          ...state.pelaksana_list_pendamping,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    default:
      return state;
  }
};

export default kosabangsa;
