import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_FAILURE1,
} from "./actionTypes";

export const changePassword = (request, cb) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: request,
    cb,
  };
};

export const changePasswordSuccess = (response, state) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: response,
    state,
  };
};

export const changePasswordFailure = (response, message) => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const changePasswordFailure1 = (response, state) => {
  return {
    type: CHANGE_PASSWORD_FAILURE1,
    payload: response,
    state,
  };
};
