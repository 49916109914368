
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
// import * as backend from "../../helpers/penugasan";
import * as backend from "../../../helpers/penugasan";
// Action Types
import {
    CREATE_PENUGASAN_GOOGLE_REQUEST, GET_PENUGASAN_GOOGLE_BYID_REQUEST, GET_PENUGASAN_GOOGLE_REQUEST, UPDATE_PENUGASAN_GOOGLE_REQUEST
} from "../actionTypes";
// Action
import { toast } from "react-toastify";
import {
    createPenugasanGoogleFailure, createPenugasanGoogleSuccess,
    getPenugasanGoogleByIdFailure, getPenugasanGoogleByIdSuccess,
    getPenugasanGoogleFailure, getPenugasanGoogleSuccess,
    updatePenugasanGoogleFailure, updatePenugasanGoogleSuccess
} from "./action";


function* createPenugasanGoogle({ payload, cb }) {
    try {
        const res = yield call(backend.createPenugasanGoogle, payload);
        if (res.data.code === 200) {
            yield put(
                createPenugasanGoogleSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan");
            cb(res.data);
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(createPenugasanGoogleFailure(message.error));
        cb(null)

    }
}

function* updatePenugasanGoogle({ payload, id, cb }) {
    try {
        const res = yield call(backend.updatePenugasanGoogle, { payload, id });
        if (res.data.code === 200) {
            yield put(
                updatePenugasanGoogleSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan di Draft");
            cb(res.data);
        } else {
            throw new Error(res.data.message);

        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(updatePenugasanGoogleFailure(message.error));
        cb(null)

    }
}

function* getPenugasanGoogle() {
    try {
        const res = yield call(backend.getPenugasanGoogle);
        if (res.data.code === 200) {
            yield put(
                getPenugasanGoogleSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanGoogleFailure(message.error));
    }
}

function* getPenugasanGoogleById({ payload, cb }) {
    try {

        const res = yield call(backend.getPenugasanGoogleById, payload.id);
        if (res.data.code === 200) {
            yield put(
                getPenugasanGoogleByIdSuccess({ data: res.data.data })
            )
            cb(res.data)
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanGoogleByIdFailure(message.error));
        cb(null)
    }
}


function* notificationSaga() {
    yield takeEvery(CREATE_PENUGASAN_GOOGLE_REQUEST, createPenugasanGoogle);
    yield takeEvery(GET_PENUGASAN_GOOGLE_REQUEST, getPenugasanGoogle);
    yield takeEvery(GET_PENUGASAN_GOOGLE_BYID_REQUEST, getPenugasanGoogleById);
    yield takeEvery(UPDATE_PENUGASAN_GOOGLE_REQUEST, updatePenugasanGoogle);
}

export default notificationSaga;
