export const GET_LIST_BATCH_KI_REQUEST = "GET_LIST_BATCH_KI_REQUEST";
export const GET_LIST_BATCH_KI_SUCCESS = "GET_LIST_BATCH_KI_SUCCESS";
export const GET_LIST_BATCH_KI_FAILURE = "GET_LIST_BATCH_KI_FAILURE";

export const GET_DETAILS_BATCH_KI_REQUEST = "GET_DETAILS_BATCH_KI_REQUEST";
export const GET_DETAILS_BATCH_KI_SUCCESS = "GET_DETAILS_BATCH_KI_SUCCESS";
export const GET_DETAILS_BATCH_KI_FAILURE = "GET_DETAILS_BATCH_KI_FAILURE";
export const CLEAN_DETAILS_BATCH_KI = "CLEAN_DETAILS_BATCH_KI";

export const CREATE_BATCH_KI_REQUEST = "CREATE_BATCH_KI_REQUEST";
export const CREATE_BATCH_KI_SUCCESS = "CREATE_BATCH_KI_SUCCESS";
export const CREATE_BATCH_KI_FAILURE = "CREATE_BATCH_KI_FAILURE";

export const UPDATE_BATCH_KI_REQUEST = "UPDATE_BATCH_KI_REQUEST";
export const UPDATE_BATCH_KI_SUCCESS = "UPDATE_BATCH_KI_SUCCESS";
export const UPDATE_BATCH_KI_FAILURE = "UPDATE_BATCH_KI_FAILURE";

export const DELETE_BATCH_KI_REQUEST = "DELETE_BATCH_KI_REQUEST";
export const DELETE_BATCH_KI_SUCCESS = "DELETE_BATCH_KI_SUCCESS";
export const DELETE_BATCH_KI_FAILURE = "DELETE_BATCH_KI_FAILURE";

export const ACTION_BATCH_KI_REQUEST = "ACTION_BATCH_KI_REQUEST";
export const ACTION_BATCH_KI_SUCCESS = "ACTION_BATCH_KI_SUCCESS";
export const ACTION_BATCH_KI_FAILURE = "ACTION_BATCH_KI_FAILURE";

export const GET_LIST_WHITELIST_KI_REQUEST = "GET_LIST_WHITELIST_KI_REQUEST";
export const GET_LIST_WHITELIST_KI_SUCCESS = "GET_LIST_WHITELIST_KI_SUCCESS";
export const GET_LIST_WHITELIST_KI_FAILURE = "GET_LIST_WHITELIST_KI_FAILURE";

export const GET_DETAILS_WHITELIST_KI = "GET_DETAILS_WHITELIST_KI";
export const CLEAN_DETAILS_WHITELIST_KI = "CLEAN_DETAILS_WHITELIST_KI";