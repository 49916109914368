import {
	GET_SKEMA_EVALUASI_SUBTANSI_FAILURE,
	GET_SKEMA_EVALUASI_SUBTANSI_REQUEST,
	GET_SKEMA_EVALUASI_SUBTANSI_SUCCESS,
	SET_CLEAR_SKEMA_EVALUASI_SUBTANSI,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_FAILURE,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_REQUEST,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_SUCCESS,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_FAILURE,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_REQUEST,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_SUCCESS,
	GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_FAILURE,
	GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_REQUEST,
	GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_SUCCESS,
	GET_RAB_EVALUASI_SUBTANSI_FAILURE,
	GET_RAB_EVALUASI_SUBTANSI_REQUEST,
	GET_RAB_EVALUASI_SUBTANSI_SUCCESS,
	GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
	GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS,
	GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE,
	SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
	SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE,
	SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS,
	GET_KELOMPOK_EVALUASI_SUBTANSI_V2_REQUEST,
	GET_KELOMPOK_EVALUASI_SUBTANSI_V2_SUCCESS,
	GET_KELOMPOK_EVALUASI_SUBTANSI_V2_FAILURE,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_FAILURE,
	GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_REQUEST,
	GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_SUCCESS,
	GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_FAILURE,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_FAILURE,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
} from "./actionTypes";

const initialState = {
	skema: {
		isFetching: false,
		items: [],
		item: {}
	},
	usulan: {
		isFetching: false,
		items: [],
		item: {}
	},
	komponenNilai: {
		isFetching: false,
		items: [],
		item: {}
	},
	rab: {
		isFetching: false,
		items: {},
		item: {}
	},
	rabRekomendasi: {
		isFetching: false,
		items: [],
	},
	saveRabRekomendasi: {
		isFetching: false,
		data: {},
		errorMessage: "",
		errors: null,
	}
};

const evaluasiSubtansi = (state = initialState, action) => {
	switch (action.type) {
		case SET_CLEAR_SKEMA_EVALUASI_SUBTANSI:
			return (state = {
				...state,
				skema: {
					...state.skema,
					items: [],
					item: {},
					isFetching: false,
				},
			});
		case GET_KELOMPOK_EVALUASI_SUBTANSI_V2_REQUEST:
		case GET_SKEMA_EVALUASI_SUBTANSI_REQUEST:
			return (state = {
				...state,
				skema: {
					...state.skema,
					isFetching: true,
				},
			});
		case GET_KELOMPOK_EVALUASI_SUBTANSI_V2_SUCCESS:
		case GET_SKEMA_EVALUASI_SUBTANSI_SUCCESS:
			if (action.payload.state == 'detail') {
				return (state = {
					...state,
					skema: {
						...state.skema,
						isFetching: false,
						item: action.payload.data ?? {},
					},
				});
			} else {
				return (state = {
					...state,
					skema: {
						...state.skema,
						isFetching: false,
						items: action.payload.data ?? [],
						item: action.payload.data.length > 0 ? action.payload.data[0] : {},
					},
				});
			}
		case GET_KELOMPOK_EVALUASI_SUBTANSI_V2_FAILURE:
		case GET_SKEMA_EVALUASI_SUBTANSI_FAILURE:
			return (state = {
				...state,
				skema: {
					...state.skema,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		case GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_REQUEST:
		case GET_LIST_USULAN_EVALUASI_SUBTANSI_REQUEST:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: true,
				},
			});
		case GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS:
		case GET_LIST_USULAN_EVALUASI_SUBTANSI_SUCCESS:
			if (action.payload.state == 'detail') {
				return (state = {
					...state,
					usulan: {
						...state.usulan,
						isFetching: false,
						item: action.payload.data ?? {},
					},
				});
			} else {
				return (state = {
					...state,
					usulan: {
						...state.usulan,
						isFetching: false,
						items: action.payload.data ?? [],
						item: action.payload.data.length > 0 ? action.payload.data[0] : {},
					},
				});
			}
		case GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_FAILURE:
		case GET_LIST_USULAN_EVALUASI_SUBTANSI_FAILURE:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		case GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_REQUEST:
		case GET_DETAIL_USULAN_EVALUASI_SUBTANSI_REQUEST:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: true,
				},
			});
		case GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS:
		case GET_DETAIL_USULAN_EVALUASI_SUBTANSI_SUCCESS:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: false,
					item: action.payload.data,
				},
			});
		case GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_FAILURE:
		case GET_DETAIL_USULAN_EVALUASI_SUBTANSI_FAILURE:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		case GET_RAB_EVALUASI_SUBTANSI_REQUEST:
			return (state = {
				...state,
				rab: {
					...state.rab,
					isFetching: true,
				},
			});
		case GET_RAB_EVALUASI_SUBTANSI_SUCCESS:
			return (state = {
				...state,
				rab: {
					...state.rab,
					isFetching: false,
					items: action.payload.data,
				},
			});
		case GET_RAB_EVALUASI_SUBTANSI_FAILURE:
			return (state = {
				...state,
				rab: {
					...state.rab,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		case GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_REQUEST:
		case GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_REQUEST:
			return (state = {
				...state,
				komponenNilai: {
					...state.komponenNilai,
					isFetching: true,
				},
			});
		case GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_SUCCESS:
		case GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_SUCCESS:
			if (action.payload.state == 'detail') {
				return (state = {
					...state,
					komponenNilai: {
						...state.komponenNilai,
						isFetching: false,
						items: action.payload.data ?? {},
					},
				});
			} else {
				return (state = {
					...state,
					komponenNilai: {
						...state.komponenNilai,
						isFetching: false,
						items: action.payload.data ?? [],
						item: action.payload.data.length > 0 ? action.payload.data[0] : {},
					},
				});
			}
		case GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_FAILURE:
		case GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_FAILURE:
			return (state = {
				...state,
				komponenNilai: {
					...state.komponenNilai,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		case GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST:
			return (state = {
				...state,
				rabRekomendasi: {
					...state.rabRekomendasi,
					isFetching: true,
				},
			});
		case GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS:
			return (state = {
				...state,
				rabRekomendasi: {
					...state.rabRekomendasi,
					isFetching: false,
					items: action.payload.data ?? [],
				},
			});
		case GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE:
			return (state = {
				...state,
				rabRekomendasi: {
					...state.rabRekomendasi,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		case SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST:
			return (state = {
				...state,
				saveRabRekomendasi: {
					...state.saveRabRekomendasi,
					isFetching: true,
				},
			});
		case SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS:
			return (state = {
				...state,
				saveRabRekomendasi: {
					...state.saveRabRekomendasi,
					isFetching: false,
					DATA: action.payload.data ?? {}
				},
			});
		case SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE:
			return (state = {
				...state,
				saveRabRekomendasi: {
					...state.saveRabRekomendasi,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		default:
			return state;
	}
};

export default evaluasiSubtansi;
