import direktoratTypes from "constants/direktorat-types";
import roleTypeIds from "constants/role-type-ids";
// import utilities from "helpers/utilities";
import ListUsulanMonev from "pages/Monev/Penelitian/ListUsulanMonev";

import React from "react";

// Reviewer
const DashboardReviewer = React.lazy(() =>
  import("../pages/Reviewer/DashboardReviewer")
);
const EvaluasiUsulan = React.lazy(() =>
  import("../pages/Reviewer/EvaluasiUsulan")
);
const EvaluasiUsulanDetail = React.lazy(() =>
  import("../pages/Reviewer/EvaluasiUsulan/ListUsulan")
);

const EvaluasiUsulanPenilaian = React.lazy(() =>
  import("../pages/Reviewer/EvaluasiUsulan/KriteriaPenilaian")
);

const EvaluasiAdministrasi = React.lazy(() =>
  import("../pages/Reviewer/EvaluasiAdministrasi")
);
const EvaluasiAdministrasiListUsulan = React.lazy(() =>
  import("../pages/Reviewer/EvaluasiAdministrasi/ListUsulan")
);
const EvaluasiAdmPenilaian = React.lazy(() =>
  import("../pages/Reviewer/EvaluasiAdministrasi/KriteriaPenilaian")
);

const SiteVisit = React.lazy(() => import("../pages/Reviewer/SiteVisit"));
const SiteVisitDetail = React.lazy(() =>
  import("../pages/Reviewer/SiteVisit/ListUsulan")
);
const SiteVisitPenilaian = React.lazy(() =>
  import("../pages/Reviewer/SiteVisit/KriteriaPenilaian")
);
//Penilaian Luaran
const PenilaianLuaran = React.lazy(() =>
  import("../pages/Reviewer/PenilaianLuaran")
);
const PenilaianLuaranDetail1 = React.lazy(() =>
  import("../pages/Reviewer/PenilaianLuaran/Detail1")
);
const PenilaianLuaranDetail2 = React.lazy(() =>
  import("../pages/Reviewer/PenilaianLuaran/Detail2")
);
//Penilaian Luaran V2
const PenilaianLuaranListProposal = React.lazy(() => import("../pages/Reviewer/PenilaianLuaranV2/ListProposal.jsx"))
const PenilaianLuaranEvaluasi = React.lazy(() => import("../pages/Reviewer/PenilaianLuaranV2/EvaluasiLuaran"))
// Evaluasi Administrasi prototipe
const EvaluasiProposalListUsulan = React.lazy(() =>
  import("../pages/Reviewer/Proposal/EvaluasiAdministrasi")
);

const EvaluasiAdministrasiPenilaian = React.lazy(() =>
  import("../pages/Reviewer/Proposal/EvaluasiAdministrasi/KriteriaPenilaian")
);
const EvaluasiSubstansiPenilaian = React.lazy(() =>
  import("../pages/Reviewer/Proposal/EvaluasiSubstansi/KriteriaPenilaian")
);
const EvaluasiSiteVisitPenilaian = React.lazy(() =>
  import("../pages/Reviewer/Proposal/EvaluasiSitevisit/KriteriaPenilaian")
);
const MonevPenelitan = React.lazy(() =>
  import("../pages/Monev/Penelitian/index")
);
const MonevPengabdian = React.lazy(() =>
  import("../pages/Monev/Pengabdian/index")
);
const MonevPengabdianListUsulan = React.lazy(() =>
  import("../pages/Monev/Pengabdian/ListUsulanMonev")
);
const KriteriaPenilaianMonevPengabdian = React.lazy(() =>
  import("../pages/Monev/Pengabdian/KriteriaPenilaianMonev")
);
const KriteriaPenilaianMonev = React.lazy(() =>
  import("../pages/Monev/Penelitian/KriteriaPenilaianMonev")
);
const CetakCatatanHarian = React.lazy(() =>
  import("../pages/Monev/Pengabdian/CetakCatatanHarian")
);
//Evaluasi Seminar Hasil
const KriteriaPenilaianSemHas = React.lazy(() => import("pages/Reviewer/Proposal/SeminarHasil/KriteriaPenilaianSemHas"));

//Monev v2
const ListKelompokMonevV2 = React.lazy(() => import('pages/MonevV2'));
const ListUsulanBySkemaMonevV2 = React.lazy(() => import('pages/MonevV2/ListUsulanMonev.js'));
// const KriteriaPenilaianMonevV2 = React.lazy(() => import('pages/MonevV2/KriteriaPenilaianMonev'));
const KriteriaPenilaianMonevPenelitianV2 = React.lazy(() => import('pages/MonevV2/KriteriaPenilaianMonevPenelitian'));
const KriteriaPenilaianMonevPengabdianV2 = React.lazy(() => import('pages/MonevV2/KriteriaPenilaianMonevPengabdian'));

//common : component untuk get list proposal group by skema. (bisa digunakan untuk evaluasi substansi, administrasi, monev, site visit dan semhasil)
const EvaluasiUsulanListGroupBySkema = React.lazy(() =>
  import("../pages/Reviewer/Proposal/ListGroupBySkema")
);

//Kekayaan Intelektual
const ReviewerKIDashAdm = React.lazy(() => import("../pages/KI/ReviewerAdministrasi/ReviewerDashAdm"));
const ReviewerKIDashBimtek = React.lazy(() => import("../pages/KI/ReviewerBimtek/ReviewerDashBimtek"));
const InputPendaftaranKI = React.lazy(() => import("../pages/KI/PendaftaranKI/InputPendaftaranKI"));
const ReviewerKIPenilaianBimtek = React.lazy(() => import("../pages/KI/ReviewerBimtek/ReviewerPenilaianBimtek"));

export default [
  {
    id: "dashboard-reviewer",
    label: "Dashboard",
    hide: true,
    sequence: 1,
    path: "/reviewer/dashboard",
    component: DashboardReviewer,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-nasional",
    label: "Reviewer",
    icon: "bx bxs-file-find me",
    sequence: 5,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "monev",
    label: "Monev",
    icon: "bx bxs-file-find me",
    sequence: 4,
    hide: false,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-penelitian",
    label: "Evaluasi Keberlanjutan Penelitian",
    hide: false,
    parent: "monev",
    path: "/monev-penelitian",
    component: MonevPenelitan,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-penelitian-skema",
    path: "/monev-penelitian/skema/:skemaId",
    component: ListUsulanMonev,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-penelitian-penilaian",
    path: "/monev-penelitian/penilaian/:skemaId/:trxUsulanId",
    component: KriteriaPenilaianMonev,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-pengabdian",
    label: "Monev Pengabdian",
    parent: "monev",
    hide: true,
    path: "/monev-pengabdian",
    component: MonevPengabdian,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-pengabdian-usulan",
    path: "/monev-pengabdian/usulan/:skemaId",
    component: MonevPengabdianListUsulan,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-pengabdian-usulan-penilaian",
    path: "/monev-pengabdian/usulan/penilaian/:skemaId/:usulanId",
    component: KriteriaPenilaianMonevPengabdian,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
      roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN,
      roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2",
    hide: false,
    path: "/monitoring-evaluasi/:jenis_kegiatan",
    component: ListKelompokMonevV2,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-penelitian",
    label: "Evaluasi Keberlanjutan Penelitian v2",
    parent: "monev",
    hide: false,
    path: "/monitoring-evaluasi/penelitian",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],

  },
  {
    id: "monev-v2-pengabdian",
    label: "Monev Pengabdian",
    parent: "monev",
    hide: false,
    path: "/monitoring-evaluasi/pengabdian",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],

  },
  {
    id: "monev-v2-prototipe",
    label: "Monev Prototipe",
    parent: "monev",

    path: "/monitoring-evaluasi/prototipe/list-usulan/141",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI],

  },
  {
    id: "monev-v2-kosabangsa",
    label: "Monev Kosabangsa",
    parent: "monev",
    path: "/monitoring-evaluasi/kosabangsa/list-usulan/143",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI],

  },
  {
    id: "monev-v2-list-usulan-penilaian",
    path: "/monitoring-evaluasi/:jenis_kegiatan/list-usulan/:skemaId",
    component: ListUsulanBySkemaMonevV2,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-list-usulan-prototipe-penilaian",
    path: "/monitoring-evaluasi/prototipe/list-usulan/:skemaId",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-list-usulan-kosabangsa-penilaian",
    path: "/monitoring-evaluasi/kosabangsa/list-usulan/:skemaId",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-list-usulan-penelitian-penilaian",
    path: "/monitoring-evaluasi/penelitain/list-usulan/:skemaId",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-list-usulan-pengabdian-penilaian",
    path: "/monitoring-evaluasi/pengabdian/list-usulan/:skemaId",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-penilaian-prototipe",
    path: "/monitoring-evaluasi/prototipe/penilaian/:plottingId/:proposalId",
    component: KriteriaPenilaianMonevPengabdianV2,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-penilaian-kosabangsa",
    path: "/monitoring-evaluasi/kosabangsa/penilaian/:plottingId/:proposalId",
    component: KriteriaPenilaianMonevPengabdianV2,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-penilaian-penelitian",
    path: "/monitoring-evaluasi/penelitian/penilaian/:plottingId/:proposalId",
    component: KriteriaPenilaianMonevPenelitianV2,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-v2-penilaian-pengabdian",
    path: "/monitoring-evaluasi/pengabdian/penilaian/:plottingId/:proposalId",
    component: KriteriaPenilaianMonevPengabdianV2,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },
  {
    id: "monev-pengabdian-catatan-harian",
    path: "/monev-pengabdian/cetak-catatan-harian",
    component: CetakCatatanHarian,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
    direktorat: [direktoratTypes.DIKTI, direktoratTypes.DIKSI],
  },

  {
    id: "reviewer-evaluasi-administrasi",
    label: "Evaluasi Administrasi",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/evaluasi-administrasi",
    parent: "reviewer-nasional",
    component: EvaluasiAdministrasi,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT],
  },
  {
    id: "reviewer-evaluasi-administrasi-bimtek",
    label: "Evaluasi Administrasi Bimtek",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/evaluasi-administrasi-bimtek",
    parent: "reviewer-nasional",
    component: EvaluasiAdministrasi,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT],
  },
  {
    id: "reviewer-evaluasi-usulan",
    label: "Evaluasi Substansi",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/evaluasi-usulan",
    parent: "reviewer-nasional",
    component: EvaluasiUsulan,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-evaluasi-usulan-bimtek",
    label: "Evaluasi Substansi Bimtek",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/evaluasi-usulan-bimtek",
    parent: "reviewer-nasional",
    component: EvaluasiUsulan,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  // {
  //   id: "reviewer-evaluasi-penelitian-administrasi-usulan-by-skema",
  //   path: "/reviewer-penelitian/evaluasi-usulan/skema/:skemaId",
  //   component: EvaluasiPenelitianUsulanDetail,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  // {
  //   id: "reviewer-evaluasi-penelitian-usulan-kriteria-penilaian",
  //   path: "/reviewer-penelitian/evaluasi-usulan/penilaian/:plottingReviewerId/:transaksiKegiatanId",
  //   component: EvaluasiPenelitianUsulanPenilaian,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  // {
  //   id: "reviewer-evaluasi-penelitian-administrasi-usulan-by-skema",
  //   path: "/reviewer-penelitian/evaluasi-administrasi/skema/:skemaId",
  //   component: EvaluasiPenelitianAdministrasiListUsulan,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  // {
  //   id: "reviewer-evaluasi-penelitian-administrasi-kriteria-penilaian",
  //   path: "/reviewer-penelitian/evaluasi-administrasi/penilaian/:usulanId",
  //   component: EvaluasiPenelitianAdmPenilaian,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  {
    id: "reviewer-evaluasi-usulan-skema",
    path: "/reviewer/evaluasi-usulan/skema/:skemaId/:institusiId",
    component: EvaluasiUsulanDetail,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-evaluasi-usulan-kriteria-penilaian",
    path: "/reviewer/evaluasi-usulan/penilaian/:plottingReviewerId/:usulanId",
    component: EvaluasiUsulanPenilaian,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-evaluasi-administrasi-usulan-by-skema",
    path: "/reviewer/evaluasi-administrasi/skema/:skemaId",
    component: EvaluasiAdministrasiListUsulan,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-evaluasi-administrasi-kriteria-penilaian",
    path: "/reviewer/evaluasi-administrasi/penilaian/:plottingReviewerId/:transaksiKegiatanId",
    component: EvaluasiAdmPenilaian,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },

  // {
  //   id: "reviewer-evaluasi-administrasi-pengabdian",
  //   label: "Evaluasi Administrasi",
  //   hide: process.env.REACT_APP_NODE === "production",
  //   path: "/reviewer/pengabdian/evaluasi-administrasi",
  //   parent: "reviewer-nasional",
  //   component: EvaluasiAdministrasiPengabdian,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  // {
  //   id: "reviewer-evaluasi-usulani-pengabdian",
  //   label: "Evaluasi Usulan",
  //   hide: process.env.REACT_APP_NODE === "production",
  //   path: "/reviewer/pengabdian/evaluasi-usulan",
  //   parent: "reviewer-nasional",
  //   component: EvaluasiUsulanPengabdian,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  // {
  //   id: "reviewer-evaluasi-usulan-skemai-pengabdian",
  //   path: "/reviewer/pengabdian/evaluasi-usulan/skema/:skemaId/:institusiId",
  //   component: EvaluasiUsulanDetailPengabdian,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  // {
  //   id: "reviewer-evaluasi-usulan-kriteria-penilaiani-pengabdian",
  //   path: "/reviewer/pengabdian/evaluasi-usulan/penilaian/:plottingReviewerId/:usulanId",
  //   component: EvaluasiUsulanPenilaianPengabdian,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  // {
  //   id: "reviewer-evaluasi-administrasi-usulan-by-skemai-pengabdian",
  //   path: "/reviewer/pengabdian/evaluasi-administrasi/skema/:skemaId",
  //   component: EvaluasiAdministrasiListUsulanPengabdian,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  // {
  //   id: "reviewer-evaluasi-administrasi-kriteria-penilaiani-pengabdian",
  //   path: "/reviewer/pengabdian/evaluasi-administrasi/penilaian/:plottingReviewerId/:transaksiKegiatanId",
  //   component: EvaluasiAdmPenilaianPengabdian,
  //   roles: [roleTypeIds.REVIEWER_NASIONAL],
  // },
  {
    id: "reviewer-site-visit",
    label: "Site Visit",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/site-visit",
    parent: "reviewer-nasional",
    component: SiteVisit,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-site-visit-skema",
    path: "/reviewer/site-visit/skema/:skemaId/:institusiId",
    component: SiteVisitDetail,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT
    ],
  },
  {
    id: "reviewer-site-visit-kriteria-penilaian",
    path: "/reviewer/site-visit/penilaian/:plottingReviewerId/:usulanId",
    component: SiteVisitPenilaian,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      // roleTypeIds.REVIEWER_DUDI,
      // roleTypeIds.REVIEWER_PT
    ],
  },
  {
    id: "reviewer-penilaian-luaran",
    label: "Penilaian Luaran",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/penilaian-luaran",
    parent: "reviewer-nasional",
    component: PenilaianLuaran,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_DUDI, roleTypeIds.REVIEWER_PT,],
  },
  {
    id: "reviewer-penilaian-luaran-v2",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/luaran/:kegiatan/list-usulan",
    component: PenilaianLuaranListProposal,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-penilaian-luaran-nilai-kosabansa-v2",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/luaran/:kegiatan/nilai/:idProposal/:idUsulanKegiatan/:idPlotting",
    component: PenilaianLuaranEvaluasi,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-penilaian-luaran-prototipe",
    label: "Penilaian Luaran Prototipe",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/luaran/prototipe/list-usulan?tahun_pelaksanaan=2023",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-penilaian-luaran-kosabangsa",
    label: "Penilaian Luaran Kosabangsa",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/luaran/kosabangsa/list-usulan?tahun_pelaksanaan=2023",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },

  {
    id: "reviewer-penilaian-luaran",
    path: "/reviewer/penilaian-luaran/detail1/:id_institusi",
    component: PenilaianLuaranDetail1,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-penilaian-luaran",
    path: "/reviewer/penilaian-luaran/detail1/:id_institusi/detail2/:id_usulan_kegiatan",
    component: PenilaianLuaranDetail2,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  // Reviewer Proposal v2
  {
    id: "reviewer-proposal-list-usulan",
    path: "/reviewer/:kegiatan/:type",
    component: EvaluasiProposalListUsulan,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-proposal-administrasi-penilaian",
    path: "/reviewer/evaluasi-administrasi/:kegiatan/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    component: EvaluasiAdministrasiPenilaian,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-proposal-administrasi-bimtek-penilaian",
    path: "/reviewer/evaluasi-administrasi-bimtek/:kegiatan/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    component: EvaluasiAdministrasiPenilaian,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-proposal-substansi-penilaian",
    path: "/reviewer/evaluasi-substansi/:kegiatan/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    component: EvaluasiSubstansiPenilaian,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-proposal-substansi-penilaian",
    path: "/reviewer/evaluasi-substansi-bimtek/:kegiatan/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    component: EvaluasiSubstansiPenilaian,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  //Reviewer Prototipe
  {
    id: "reviewer-prototipe-list-usulan-administrasi",
    label: "Evaluasi Administrasi Prototipe",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/prototipe/administrasi",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },

  {
    id: "reviewer-prototipe-list-usulan-susbtansi",
    label: "Evaluasi Substansi Prototipe",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/prototipe/substansi",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },

  {
    id: "reviewer-prototipe-administrasi-penilaian",
    path: "/reviewer/evaluasi-administrasi/prototipe/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-prototipe-substansi-penilaian",
    path: "/reviewer/evaluasi-substansi/prototipe/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  // Reviewer kosabangsa
  {
    id: "reviewer-kosabangsa-list-usulan-administrasi",
    label: "Evaluasi Administrasi Kosabangsa",
    path: "/reviewer/kosabangsa/administrasi",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-kosabangsa-list-usulan-susbtansi",
    label: "Evaluasi Substansi Kosabangsa",
    path: "/reviewer/kosabangsa/substansi",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-kosabangsa-administrasi-penilaian",
    path: "/reviewer/evaluasi-administrasi/kosabangsa/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-kosabangsa-substansi-penilaian",
    path: "/reviewer/evaluasi-substansi/kosabangsa/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },

  // Site Visit kosabangsa

  {
    id: "site-visit-kosabangsa-list-usulan",
    label: "Site Visit Kosabangsa",
    path: "/reviewer/kosabangsa/site-visit",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-kosabangsa-site-visit-penilaian",
    path: "/reviewer/evaluasi-site-visit/:kegiatan/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    component: EvaluasiSiteVisitPenilaian,
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },
  {
    id: "reviewer-kosabangsa-site-visit-penilaian",
    path: "/reviewer/evaluasi-site-visit/kosabangsa/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    roles: [roleTypeIds.REVIEWER_NASIONAL, roleTypeIds.REVIEWER_PT, roleTypeIds.REVIEWER_DUDI],
  },

  //Evaluasi Seminar Hasil
  {
    id: "reviewer-seminar-hasil",
    label: "Seminar Hasil",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/seminar-hasil",
    parent: "reviewer-nasional",
    component: EvaluasiUsulanListGroupBySkema,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-seminar-hasil-kosabangsa",
    label: "Seminar Hasil Kosabangsa",
    // hide: process.env.REACT_APP_NODE === "production",
    path: "/reviewer/kosabangsa/seminar-hasil?tahun_pelaksanaan=2023&kode_jenis_kegiatan=2&id_skema=143&direktorat=dikti",
    parent: "reviewer-nasional",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "seminar-hasil-list-usulan",
    path: "/reviewer/regular/seminar-hasil",
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_DUDI,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-kosabangsa-site-visit-penilaian",
    path: "/reviewer/evaluasi-seminar-hasil/:kegiatan/penilaian/:plottingReviewerId",
    parent: "reviewer-nasional",
    component: KriteriaPenilaianSemHas,
    roles: [roleTypeIds.REVIEWER_NASIONAL,
    roleTypeIds.REVIEWER_DUDI,
    roleTypeIds.REVIEWER_PT,],
  },

  //Kekayaan Intelektual
  {
    id: "reviewer-ki",
    label: "Reviewer KI",
    icon: "bx bxs-file-find me",
    sequence: 1,
    roles: [
      roleTypeIds.REVIEWER_KI_NON_DOSEN,
    ],
  },
  {
    id: "reviewer-ki-administrasi",
    path: "/reviewer-ki/adm",
    parent: 'reviewer-ki',
    label: 'Penilaian Administrasi',
    component: ReviewerKIDashAdm,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_KI_NON_DOSEN,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  
  {
    id: "reviewer-ki-bimtek",
    path: "/reviewer-ki/bimtek",
    parent: 'reviewer-ki',
    label: 'Penilaian Bimtek',
    component: ReviewerKIDashBimtek,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_KI_NON_DOSEN,
      roleTypeIds.REVIEWER_PT,
    ],
  },
 
  {
    id: "reviewer-ki-pendaftaran-detail",
    path: "/reviewer-ki/pendaftaran/:jenis/detail/:id/:idPlotting",
    component: InputPendaftaranKI,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_KI_NON_DOSEN,
      roleTypeIds.REVIEWER_PT,
    ],
  },
  {
    id: "reviewer-ki-pendaftaran-detail",
    path: "/reviewer-ki/bimtek/penilaian/:id/:idPlotting",
    component: ReviewerKIPenilaianBimtek,
    roles: [
      roleTypeIds.REVIEWER_NASIONAL,
      roleTypeIds.REVIEWER_KI_NON_DOSEN,
      roleTypeIds.REVIEWER_PT,
    ],
  },
];
