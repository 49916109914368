import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";

import {
  GET_INSTITUSI_REQUEST,
  GET_SKEMA_EVALUASI_REQUEST,
  GET_LIST_USULAN_EVALUASI_REQUEST,
  GET_DETAIL_USULAN_EVALUASI_REQUEST,
  GET_KOMPONEN_NILAI_EVALUASI_REQUEST,
  SAVE_PENILAIAN_EVALUASI_REQUEST,
  SAVE_PENILAIAN_PERMANEN_EVALUASI_REQUEST,
  GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_REQUEST,
  GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_REQUEST,
} from "./actionTypes";
// Action
import {
  getKomponenNilaiEvaluasiFailure,
  getKomponenNilaiEvaluasiSuccess,
  getListUsulanEvaluasiSuccess,
  getListUsulanEvaluasiFailure,
  getInstitusiByYearsSuccess,
  getInstitusiByYearsFailure,
  getDetailUsulanEvaluasiSuccess,
  getDetailUsulanEvaluasiFailure,
  getSkemaEvaluasiSuccess,
  getSkemaEvaluasiFailure,
  getKomponenNilaiEvalusiAdministrasiPrototipeV2Success,
  getKomponenNilaiEvalusiAdministrasiPrototipeV2Failure,
  getDetailEvalusiAdministrasiPrototipeV2Success,
  getDetailEvalusiAdministrasiPrototipeV2Failure,
  saveEvaluasiAdministrasiPrototipeV2Success,
  saveEvaluasiAdministrasiPrototipeV2Failure,
  savePermanentEvaluasiPrototipeV2Success,
  savePermanentEvaluasiPrototipeV2Failure,
  getlistUsulanEvaluasiV2Success,
  getlistUsulanEvaluasiV2Failure,
} from "./action";

function* getInstitusiByYear({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getInstitusiByYear, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getInstitusiByYearsSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getInstitusiByYearsFailure(message, error));
  }
}

function* getSkemaEvaluasi({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getSkemaEvaluasi, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getSkemaEvaluasiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getSkemaEvaluasiFailure(message, error));
  }
}

function* getListUsulanEvaluasi({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getListUsulanEvaluasi, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getListUsulanEvaluasiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListUsulanEvaluasiFailure(message, error));
  }
}

function* getDetailUsulanEvaluasi({ payload, cb = () => {} }) {
  try {
    const response = yield call(
      backend.getDetailUsulanByTransaksiKegiatanId,
      payload
    );
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getDetailUsulanEvaluasiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getDetailUsulanEvaluasiFailure(message, error));
  }
}

function* getKomponenNilaiEvaluasi({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getKomponenNilaiEvaluasi, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getKomponenNilaiEvaluasiSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getKomponenNilaiEvaluasiFailure(message, error));
  }
}

function* savePenilaianEvaluasi({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.savePenilaianEvaluasi, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
  }
}

function* savePenilaianPermanenEvaluasi({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.savePenilaianPermanenEvaluasi, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
  }
}
// Evaluasi prototipe admistrasi

function* getlistUsulanEvaluasiV2({
  payload,
  cb = () => {},
}) {
  try {
    const response = yield call(backend.getListUsulanEvaluasiV2, {
      ...payload,
    });
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getlistUsulanEvaluasiV2Success({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(
      getlistUsulanEvaluasiV2Failure(message, error)
    );
  }
}
//
function* getKomponenNilaiEvalusiAdministrasiPrototipeV2({
  payload,
  cb = () => {},
}) {
  try {
    const response = yield call(backend.getKomponenNilaiEvaluasiV2, {
      ...payload,
      kode_tahapan: 20,
    });
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getKomponenNilaiEvalusiAdministrasiPrototipeV2Success({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(
      getKomponenNilaiEvalusiAdministrasiPrototipeV2Failure(message, error)
    );
  }
}
function* getDetailEvalusiAdministrasiPrototipeV2({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.getDetailPenilaianEvaluasiV2, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data.data);
      yield put(
        getDetailEvalusiAdministrasiPrototipeV2Success({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getDetailEvalusiAdministrasiPrototipeV2Failure(message, error));
  }
}
function* saveEvaluasiAdministrasiPrototipeV2({
  payload,
  cb = () => {},
  methodHttp,
}) {
  try {
    let response;
    if (methodHttp === "POST") {
      response = yield call(backend.saveUsulanEvaluasiV2, payload);
    } else if (methodHttp === "PUT") {
      response = yield call(backend.updateUsulanEvaluasiV2, payload);
    }
    if (response.data.code == 200) {
      let state = "";
      cb(response.data);
      yield put(
        saveEvaluasiAdministrasiPrototipeV2Success({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(saveEvaluasiAdministrasiPrototipeV2Failure(message, error));
  }
}
function* savePermanentEvaluasiPrototipeV2({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.savePermanentUsulanEvaluasi2, payload);
    if (response.data.code == 200) {
      let state = "";
      cb(response.data);
      yield put(
        savePermanentEvaluasiPrototipeV2Success({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(savePermanentEvaluasiPrototipeV2Failure(message, error));
  }
}
function* evaluasiAdministrasi() {
  yield takeEvery(GET_INSTITUSI_REQUEST, getInstitusiByYear);
  yield takeEvery(GET_SKEMA_EVALUASI_REQUEST, getSkemaEvaluasi);
  yield takeEvery(GET_LIST_USULAN_EVALUASI_REQUEST, getListUsulanEvaluasi);
  yield takeEvery(
    GET_KOMPONEN_NILAI_EVALUASI_REQUEST,
    getKomponenNilaiEvaluasi
  );
  yield takeEvery(GET_DETAIL_USULAN_EVALUASI_REQUEST, getDetailUsulanEvaluasi);
  yield takeEvery(SAVE_PENILAIAN_EVALUASI_REQUEST, savePenilaianEvaluasi);
  yield takeEvery(
    SAVE_PENILAIAN_PERMANEN_EVALUASI_REQUEST,
    savePenilaianPermanenEvaluasi
  );
  yield takeEvery(
    GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_REQUEST,
    getlistUsulanEvaluasiV2
  );
  yield takeEvery(
    GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
    getKomponenNilaiEvalusiAdministrasiPrototipeV2
  );
  yield takeEvery(
    GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
    getDetailEvalusiAdministrasiPrototipeV2
  );
  yield takeEvery(
    SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
    saveEvaluasiAdministrasiPrototipeV2
  );
  yield takeEvery(
    SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_REQUEST,
    savePermanentEvaluasiPrototipeV2
  );
}

export default evaluasiAdministrasi;
