import { call, put, takeEvery } from "redux-saga/effects";
import {
  getFormMitraDetailLaporanAkhirPengabdianSuccess,
  getFormMitraDetailLaporanAkhirPengabdianFailure,
  postFormMitraLaporanAkhirPengabdianSuccess,
  postFormMitraLaporanAkhirPengabdianFailure,
  putFormMitraLaporanAkhirPengabdianSuccess,
  putFormMitraLaporanAkhirPengabdianFailure,
  getListLaporanAkhirSuccess,
  getListLaporanAkhirFailure,
  getDetailLaporanAkhirSuccess,
  getDetailLaporanAkhirFailure,
  createLaporanAkhirSuccess,
  createLaporanAkhirFailure,
  uploadDokumenLaporanAkhirSuccess,
  uploadDokumenLaporanAkhirFailure,
  uploadDokumenSptbLaporanAkhirSuccess,
  uploadDokumenSptbLaporanAkhirFailure,
  getDokumenLuaranLaporanAkhirSuccess,
  getDokumenLuaranLaporanAkhirFailure,
  getSptbLaporanAkhirSuccess,
  getSptbLaporanAkhirFailure,
  getListLaporanAkhirV2Success,
  getListLaporanAkhirV2Failure,
  getDetailLaporanAkhirV2Success,
  getDetailLaporanAkhirV2Failure,
  updateLaporanAkhirV2Success,
  updateLaporanAkhirV2Failure,
  getLaporanAkhirLuaranAktualSuccess,
  getLaporanAkhirLuaranAktualFailure,
  createLaporanAkhirLuaranAktualSuccess,
  createLaporanAkhirLuaranAktualFailure,
} from "./action";
import {
  GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_REQUEST,
  GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_REQUEST,
  UPLOAD_DOKUMEN_LAPORAN_AKHIR_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_REQUEST,
  GET_LIST_LAPORAN_AKHIR_REQUEST,
  GET_SPTB_LAPORAN_AKHIR_REQUEST,
  CREATE_LAPORAN_AKHIR_REQUEST,
  GET_LIST_LAPORAN_AKHIR_V2_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_V2_REQUEST,
  UPDATE_LAPORAN_AKHIR_V2_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
  CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
} from "./actionTypes";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";

// get sptb laporan akhir
function* getSptbLaporanAkhir({ payload }) {
  try {
    const response = yield call(backend.getSptbLaporanAkhir, payload);
    if (response.data.code == 200) {
      yield put(getSptbLaporanAkhirSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getSptbLaporanAkhirFailure(message, error));
  }
}
// dokumen luaran
function* getDokumenLuaranLaporanAkhir({ payload }) {
  try {
    const response = yield call(backend.getDokumenLuaranLaporanAkhir, payload);
    if (response.data.code == 200) {
      yield put(getDokumenLuaranLaporanAkhirSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDokumenLuaranLaporanAkhirFailure(message, error));
  }
}
// upload laporan akhir
function* uploadDokumenLaporanAkhir({ payload, cb }) {
  try {
    const response = yield call(backend.uploadDokumenLaporanAkhir, payload);
    if (response.data.code == 200) {
      yield put(uploadDokumenLaporanAkhirSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(uploadDokumenLaporanAkhirFailure(message, error));
    cb(null);
  }
}
function* uploadDokumenSptbLaporanAkhir({ payload, cb }) {
  try {
    const response = yield call(backend.uploadDokumenSptbLaporanAkhir, payload);
    if (response.data.code == 200) {
      yield put(uploadDokumenSptbLaporanAkhirSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(uploadDokumenSptbLaporanAkhirFailure(message, error));
    cb(null);
  }
}
// list laporan akhir
function* getListLaporanAkhir({ payload }) {
  try {
    const response = yield call(backend.getListLaporanAkhir, payload);
    if (response.data.code == 200) {
      yield put(getListLaporanAkhirSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getListLaporanAkhirFailure(message, error));
  }
}
// detail laporan akhir
function* getDetailLaporanAkhir({ payload, cb }) {
  try {
    const response = yield call(backend.getDetailLaporanAkhir, payload);
    if (response.data.code == 200) {
      yield put(getDetailLaporanAkhirSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDetailLaporanAkhirFailure(message, error));
    cb(null);
  }
}
// luaran aktual laporan akhir
function* getLaporanAkhirLuaranAktual({ payload, cb }) {
  try {
    const response = yield call(backend.getLaporanAkhirLuaranAktual, payload);
    if (response.data.code == 200) {
      yield put(getLaporanAkhirLuaranAktualSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getLaporanAkhirLuaranAktualFailure(message, error));
    cb(null);
  }
}
function* createLaporanAkhirLuaranAktual({ payload, cb }) {
  try {
    const response = yield call(
      backend.createLaporanAkhirLuaranAktual,
      payload
    );
    if (response.data.code == 200) {
      yield put(createLaporanAkhirLuaranAktualSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(createLaporanAkhirLuaranAktualFailure(message, error));
    cb(null);
  }
}
function* createLaporanAkhir({ payload, cb }) {
  // console.log(payload);
  try {
    const response = yield call(backend.createLaporanAkhir, payload);
    if (response.data.code == 200) {
      yield put(createLaporanAkhirSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(createLaporanAkhirFailure(message, error));
    cb(null);
  }
}
// start form mitra pengabdian
function* getFormMitraDetailLaporanAkhirPengabdian({ payload }) {
  try {
    const response = yield call(
      backend.getFormMitraDetailLaporanAkhirPengabdian,
      payload
    );
    if (response.data.code == 200) {
      yield put(
        getFormMitraDetailLaporanAkhirPengabdianSuccess(response.data.data)
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getFormMitraDetailLaporanAkhirPengabdianFailure(message, error));
  }
}

function* postFormMitraLaporanAkhirPengabdian({ payload, cb }) {
  try {
    const response = yield call(
      backend.postFormMitraLaporanAkhirPengabdian,
      payload
    );
    if (response.data.code == 200) {
      yield put(postFormMitraLaporanAkhirPengabdianSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(postFormMitraLaporanAkhirPengabdianFailure(message, error));
    cb(null);
  }
}
function* putFormMitraLaporanAkhirPengabdian({ payload, cb }) {
  try {
    const response = yield call(
      backend.putFormMitraLaporanAkhirPengabdian,
      payload
    );
    if (response.data.code == 200) {
      yield put(putFormMitraLaporanAkhirPengabdianSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(putFormMitraLaporanAkhirPengabdianFailure(message, error));
    cb(null);
  }
}
// end of form mitra pengabdian

// laporan akhir v2
function* getListLaporanAkhirV2({ payload, cb }) {
  try {
    const response = yield call(backend.getListLaporanAkhirV2, payload);
    if (response.data.code == 200) {
      yield put(getListLaporanAkhirV2Success({ data: response.data.data }));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListLaporanAkhirV2Failure(message, error));
  }
}
function* getDetailLaporanAkhirV2({ payload, cb }) {
  try {
    const response = yield call(backend.getDetailLaporanAkhirV2, payload);
    if (response.data.code == 200) {
      yield put(getDetailLaporanAkhirV2Success({ data: response.data.data }));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    toast.error(message);
    yield put(getDetailLaporanAkhirV2Failure(message, error));
  }
}
function* updateLaporanAkhirV2({ payload, cb }) {
  try {
    const response = yield call(backend.updateLaporanAkhirV2, payload);
    if (response.data.code == 200) {
      yield put(updateLaporanAkhirV2Success({ data: response.data.data }));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(updateLaporanAkhirV2Failure(message, error));
    cb(null);
  }
}
function* laporanAkhirSaga() {
  yield takeEvery(GET_SPTB_LAPORAN_AKHIR_REQUEST, getSptbLaporanAkhir);
  yield takeEvery(
    GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
    getLaporanAkhirLuaranAktual
  );
  yield takeEvery(
    CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
    createLaporanAkhirLuaranAktual
  );
  yield takeEvery(
    GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_REQUEST,
    getDokumenLuaranLaporanAkhir
  );
  yield takeEvery(
    UPLOAD_DOKUMEN_LAPORAN_AKHIR_REQUEST,
    uploadDokumenLaporanAkhir
  );
  yield takeEvery(
    UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_REQUEST,
    uploadDokumenSptbLaporanAkhir
  );
  yield takeEvery(GET_LIST_LAPORAN_AKHIR_REQUEST, getListLaporanAkhir);
  yield takeEvery(GET_DETAIL_LAPORAN_AKHIR_REQUEST, getDetailLaporanAkhir);
  yield takeEvery(CREATE_LAPORAN_AKHIR_REQUEST, createLaporanAkhir);
  yield takeEvery(
    GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
    getFormMitraDetailLaporanAkhirPengabdian
  );
  yield takeEvery(
    POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
    postFormMitraLaporanAkhirPengabdian
  );
  yield takeEvery(
    PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
    putFormMitraLaporanAkhirPengabdian
  );
  // Laporan akhir v2
  yield takeEvery(GET_LIST_LAPORAN_AKHIR_V2_REQUEST, getListLaporanAkhirV2);
  yield takeEvery(GET_DETAIL_LAPORAN_AKHIR_V2_REQUEST, getDetailLaporanAkhirV2);
  yield takeEvery(UPDATE_LAPORAN_AKHIR_V2_REQUEST, updateLaporanAkhirV2);
}

export default laporanAkhirSaga;
