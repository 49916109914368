import {
    CREATE_PENUGASAN_NUSANTARA_FAILURE, CREATE_PENUGASAN_NUSANTARA_REQUEST,
    CREATE_PENUGASAN_NUSANTARA_SUCCESS, GET_BIDANG_FOKUS_FAILURE, GET_BIDANG_FOKUS_REQUEST,
    GET_BIDANG_FOKUS_SUCCESS, 
    GET_PENUGASAN_NUSANTARA_BYID_FAILURE, 
    GET_PENUGASAN_NUSANTARA_BYID_REQUEST,
    GET_PENUGASAN_NUSANTARA_BYID_SUCCESS, GET_PENUGASAN_NUSANTARA_FAILURE,
    GET_PENUGASAN_NUSANTARA_REQUEST,
    GET_INSTITUSI_BYID_REQUEST, 
    GET_INSTITUSI_BYID_SUCCESS,
    GET_INSTITUSI_BYID_FAILURE,
    GET_PENUGASAN_NUSANTARA_SUCCESS, GET_SBK_UTAMA_FAILURE, GET_SBK_UTAMA_REQUEST,
    GET_SBK_UTAMA_SUCCESS, GET_SKEMA_FAILURE, GET_SKEMA_REQUEST,
    GET_SKEMA_SUCCESS, JENIS_BANTUAN_FAILURE, JENIS_BANTUAN_REQUEST,
    JENIS_BANTUAN_SUCCESS, PENUGASAN_DOC_FAILURE, PENUGASAN_DOC_REQUEST,
    PENUGASAN_DOC_SUCCESS, UPDATE_PENUGASAN_NUSANTARA_FAILURE,
    UPDATE_PENUGASAN_NUSANTARA_REQUEST,
    UPDATE_PENUGASAN_NUSANTARA_SUCCESS
} from "./actionTypes";

const initialState = {
    proposalDoc: {
        isFetching: false,
        items: {},
        errorMessage: "",
        errors: null
    },
    jenis_bantuan: {
        isFetching: false,
        items: [],
        errorMessage: "",
        errors: null
    },
    submit: {
        isFetching: false,
        item: {},
        errorMessage: "",
        errors: null,
    },
    draft: {
        isFetching: false,
        item: {},
        errorMessage: "",
        errors: null,
    },
    listPenugasanNusantara: {
        isFetching: false,
        items: [],
        errorMessage: "",
        errors: null
    },
    penugasanNusantara: {
        isFetching: false,
        item: {},
        errorMessage: "",
        errors: null
    },
    bidangFokus: {
        isFetching: false,
        items: [],
        errorMessage: "",
        errors: null
    },
    sbkUtama: {
        isFetching: false,
        items: [],
        errorMessage: "",
        errors: null
    },
    skemaKegiatan: {
        isFetching: false,
        items: [],
        errorMessage: "",
        errors: null
    },
    institusi: {
        isFetching: false,
        item: {},
        errorMessage: "",
        errors: null
    }
};

const penugasan = (state = initialState, action) => {

    switch (action.type) {
        case JENIS_BANTUAN_REQUEST:
            return (state = {
                ...state,
                jenis_bantuan: {
                    ...state.jenis_bantuan,
                    isFetching: true
                }
            })

        case JENIS_BANTUAN_SUCCESS:
            return (state = {
                ...state,
                jenis_bantuan: {
                    ...state.jenis_bantuan,
                    isFetching: false,
                    items: action.payload.data
                }
            })

        case JENIS_BANTUAN_FAILURE:
            return (state = {
                ...state,
                jenis_bantuan: {
                    ...state.jenis_bantuan,
                    isFetching: false,
                    errorMessage: action.payload
                }
            })

        case GET_INSTITUSI_BYID_REQUEST:
            return (state = {
                ...state,
                institusi: {
                    ...state.institusi,
                    isFetching: true
                }
            })

        case GET_INSTITUSI_BYID_SUCCESS:
            return (state = {
                ...state,
                institusi: {
                    ...state.institusi,
                    isFetching: false,
                    item: action.payload.data
                }
            })

        case GET_INSTITUSI_BYID_FAILURE:
            return (state = {
                ...state,
                institusi: {
                    ...state.institusi,
                    isFetching: false,
                    errorMessage: action.payload
                }
            })

        case PENUGASAN_DOC_REQUEST:
            return (state = {
                ...state,
                proposalDoc: {
                    ...state.proposalDoc,
                    isFetching: true,
                }
            })
        case PENUGASAN_DOC_SUCCESS:
            return (state = {
                ...state,
                proposalDoc: {
                    ...state.proposalDoc,
                    isFetching: false,
                    items: {
                        ...state.proposalDoc.items,
                        [action.payload.name]: action.payload.data.url
                    },

                }
            })
        case PENUGASAN_DOC_FAILURE:
            return (state = {
                ...state,
                proposalDoc: {
                    ...state.proposalDoc,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            });

        case CREATE_PENUGASAN_NUSANTARA_REQUEST:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: true,
                }
            })
        case CREATE_PENUGASAN_NUSANTARA_SUCCESS:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: false,
                    item: action.payload.data,
                }
            })
        case CREATE_PENUGASAN_NUSANTARA_FAILURE:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            })
        case UPDATE_PENUGASAN_NUSANTARA_REQUEST:
            return (state = {
                ...state,
                draft: {
                    ...state.draft,
                    isFetching: true,

                }
            })
        case UPDATE_PENUGASAN_NUSANTARA_SUCCESS:
            return (state = {
                ...state,
                draft: {
                    ...state.draft,
                    isFetching: false,
                    item: action.payload.data,

                }
            })
        case UPDATE_PENUGASAN_NUSANTARA_FAILURE:
            return (state = {
                ...state,
                draft: {
                    ...state.draft,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            })
        case GET_PENUGASAN_NUSANTARA_REQUEST:
            return (state = {
                ...state,
                listPenugasanNusantara: {
                    ...state.listPenugasanNusantara,
                    isFetching: true
                }
            })
        case GET_PENUGASAN_NUSANTARA_SUCCESS:
            return (state = {
                ...state,
                listPenugasanNusantara: {
                    ...state.listPenugasanNusantara,
                    isFetching: false,
                    items: action.payload.data
                }
            })
        case GET_PENUGASAN_NUSANTARA_FAILURE:
            return (state = {
                ...state,
                listPenugasanNusantara: {
                    ...state.listPenugasanNusantara,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors
                }
            })
        case GET_PENUGASAN_NUSANTARA_BYID_REQUEST:
            return (state = {
                ...state,
                penugasanNusantara: {
                    ...state.penugasanNusantara,
                    isFetching: true
                }

            })
        case GET_PENUGASAN_NUSANTARA_BYID_SUCCESS:
            return (state = {
                ...state,
                penugasanNusantara: {
                    ...state.penugasanNusantara,
                    isFetching: false,
                    item: action.payload.data
                }
            })
        case GET_PENUGASAN_NUSANTARA_BYID_FAILURE:
            return (state = {
                ...state,
                penugasanNusantara: {

                    ...state.penugasanNusantara,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors
                }
            })
        case GET_BIDANG_FOKUS_REQUEST:
            return (state = {
                ...state,
                bidangFokus: {
                    ...state.bidangFokus,
                    isFetching: true
                }
            })
        case GET_BIDANG_FOKUS_SUCCESS:
            return (state = {
                ...state,
                bidangFokus: {
                    ...state.bidangFokus,
                    isFetching: false,
                    items: action.payload.data.filter(item => item.id_bidang_fokus !== 13 && item.id_bidang_fokus !== 22 && item.id_bidang_fokus !== 21)
                }
            })
        case GET_BIDANG_FOKUS_FAILURE:
            return (state = {
                ...state,
                bidangFokus: {
                    ...state.bidangFokus,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors
                }
            })
        case GET_SBK_UTAMA_REQUEST:
            return (state = {
                ...state,
                sbkUtama: {
                    ...state.sbkUtama,
                    isFetching: true
                }
            })
        case GET_SBK_UTAMA_SUCCESS:
            return (state = {
                ...state,
                sbkUtama: {
                    ...state.sbkUtama,
                    isFetching: false,
                    items: action.payload.data
                }
            })
        case GET_SBK_UTAMA_FAILURE:
            return (state = {
                ...state,
                sbkUtama: {
                    ...state.sbkUtama,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors
                }
            })
        case GET_SKEMA_REQUEST:
            return (state = {
                ...state,
                skemaKegiatan: {
                    ...state.skemaKegiatan,
                    isFetching: true
                }
            })
        case GET_SKEMA_SUCCESS:
            return (state = {
                ...state,
                skemaKegiatan: {
                    ...state.skemaKegiatan,
                    isFetching: false,
                    items: action.payload.data
                }
            })
        case GET_SKEMA_FAILURE:
            return (state = {
                ...state,
                skemaKegiatan: {
                    ...state.skemaKegiatan,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors
                }
            })

        default:
            return state;
    }
};

export default penugasan;
