/* eslint-disable no-unused-vars */
import { instances, instancesV2 } from "../../api_helper";
function removeNullOrUndefinedProperties(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
}
export const getListMinatKatalisOpt = payload => {
  return instancesV2.request(
    `/minat-katalis/minat-katalis?${new URLSearchParams(
      removeNullOrUndefinedProperties(payload?.queryParams || {})
    ).toString()}`,
    {
      method: "GET",
    }
  );
};
export const updateStatusMinatKatalisOpt = payload => {
  return instancesV2.request(
    `/minat-katalis/minat-katalis/status-pusat/${payload.queryParams.id}`,
    {
      method: "PUT",
      data: payload.data,
    }
  );
};
export const getEnumerationMinatKatalisOpt = async () => {
  const res = await instances.request(
    `/enumeration?criteria=group:master_tema_minat_katalis`,
    {
      method: "GET",
    }
  );
  return res;
};
