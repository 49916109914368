
import Content from "./content";
import stylePDF from "./styles";

export default function PdfCreating(item) {
    const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
    return {
        content: [
            ...Content(item)
        ],
        defaultStyle,
        pageMargins,
        pageSize,
        styles,
    };
}
