import { instances } from "helpers/api_helper";
import encStorage from "helpers/encStorage";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = encStorage.getItem("userAuth");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const login = payload => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  };
  const formData = new FormData();

  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key]);
  });

  //const instances = instance();

  return instances.request(url.POST_LOGIN, {
    ...config,
    method: "POST",
    data: formData,
  });
};

const logout = () => {
  //const instances = instance();

  return instances.request(url.URL_LOGOUT, {
    method: "GET",
  });
};

export { login, logout, isUserAuthenticated };
