import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
  DASHBOARD_SYNC_PDDIKTI_REQUEST,
  DASHBOARD_SYNC_PDDIKTI_SUCCESS,
  DASHBOARD_SYNC_PDDIKTI_FAILURE,
  DASHBOARD_SYNC_SINTA_REQUEST,
  DASHBOARD_SYNC_SINTA_SUCCESS,
  DASHBOARD_SYNC_SINTA_FAILURE,
  DASHBOARD_UPDATE_IDENTITAS,
  DASHBOARD_UPDATE_IDENTITAS_REQUEST,
  ARTICLE_REQUEST,
  ARTICLE_SUCCESS,
  ARTICLE_FAILURE,
  ARTICLE_SAVE,
  TRACKING_USULAN_PENELITIAN_REQUEST,
  TRACKING_USULAN_PENELITIAN_SUCCESS,
  TRACKING_USULAN_PENELITIAN_FAILURE,
  LIST_USULAN_FAILURE,
  LIST_USULAN_SUCCESS,
  LIST_USULAN_REQUEST,
  GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_FAILURE,
  GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_REQUEST,
  GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_SUCCESS,
  GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_REQUEST,
  GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_SUCCESS,
  GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_FAILURE,
  DASHBOARD_IDENTITAS_DOSEN_REQUEST,
  DASHBOARD_IDENTITAS_DOSEN_SUCCESS,
  DASHBOARD_IDENTITAS_DOSEN_FAILURE,
  DASHBOARD_SINTA_DOSEN_REQUEST,
  DASHBOARD_SINTA_DOSEN_SUCCESS,
  DASHBOARD_SINTA_DOSEN_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  isSync: false,
  isSyncSinta: false,
  errorMessage: "",
  errors: null,
  identitas: {
    id: "",
    photo: "",
    nidn: "",
    nama: "",
    institusi: "",
    prodi: "",
    jenjang: "",
    jabatan_akademik: "",
    alamat: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    nik: "",
    no_telepon: "",
    no_hp: "",
    email: "",
    website: "",
  },
  identitasPddikti: {
    id: "",
    photo: "",
    nidn: "",
    nama: "",
    institusi: "",
    prodi: "",
    jenjang: "",
    jabatan_akademik: "",
    alamat: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    nik: "",
    no_telepon: "",
    no_hp: "",
    email: "",
    website: "",
  },
  sinta: {
    google_scholar: {},
    id_sinta: "",
    scopus: {},
    skor_sinta: 0,
  },
  isLoadingTracking: false,
  tracking_usulan_penelitian: null,
  riwayatPenelitian: [],
  riwayatPengabdian: [],
  penelitian: [
    // {
    //   title:
    //     "Pemetaan dan Monitoring Lingkungan Radiasi Nuklir Alam Dalam Menunjang Evaluasi Tapak Pembangkit Listrik Tenaga Nuklir",
    //   tahun: "2019",
    //   peran: "Ketua Pengusul",
    //   sumber_dana: "Ristekdikti",
    //   sumber: "Penelitian Terapan Unggulan Perguruan Tinggi",
    // },
    // {
    //   title:
    //     "SISTEM PENDETEKSIAN SERANGAN CYBER DALAM MELINDUNGI ASET INFRASTRUKTUR SINTA KEMENRISTEKDIKTI DENGAN PENDEKATAN DEEP PACKET INSPECTION",
    //   tahun: "2019",
    //   peran: "Ketua Pengusul",
    //   sumber_dana: "Ristekdikti",
    //   sumber: "PRN - Riset Konsorsium",
    // },
    // {
    //   title:
    //     "REKAYASA INSTRUMEN OCEAN ACOUSTICS WAVEGUIDE REMOTE SENSING UNTUK DETEKSI DAN PEMETAAN SUMBERDAYA LAUT DALAM UPAYA PENGEMBANGAN TEKNOLOGI INFORMASI MARITIM INDONESIA",
    //   tahun: "2019",
    //   peran: "Ketua Pengusul",
    //   sumber_dana: "Ristekdikti",
    //   sumber: "PRN - Riset Konsorsium",
    // },
    // {
    //   title:
    //     "Pengembangan Produk Banana Bar Prebiotik untuk Meningkatkan Fungsi Saluran Cerna Anak Balita",
    //   tahun: "2019",
    //   peran: "Ketua Pengusul",
    //   sumber_dana: "Ristekdikti",
    //   sumber: "PRN - Riset Konsorsium",
    // },
    // {
    //   title:
    //     "MODEL INKUBATOR WIRAUSAHA BERBASIS TEKNOLOGI (TECHNOPRENEUR INCUBATOR) DALAM MENDORONG HILIRISASI HASIL LITBANG PANGAN FUNGSIONAL",
    //   tahun: "2019",
    //   peran: "Ketua Pengusul",
    //   sumber_dana: "Ristekdikti",
    //   sumber: "PRN - Riset Konsorsium",
    // },
  ],
  pengabdian: [
    // {
    //   title: "Produksi Kripik Singkong Berprotein Tinggi",
    //   tahun: "2018",
    //   peran: "Ketua Pengusul",
    //   sumber_dana: "Ristekdikti",
    //   sumber: "Program Pengembangan Kewirausahaan",
    // },
    // {
    //   title: "OJS",
    //   tahun: "2016",
    //   peran: "Ketua Pengusul",
    //   sumber_dana: "Internal PT",
    //   sumber: "Kegiatan Insidensial (Kurang dari 1 Bulan)",
    // },
  ],

  /* Article */
  isFetchingArticle: false,
  listUsulan: {},
  itemsArticles: [
    // {
    //   id: "1",
    //   judul: "Test 123",
    //   jurnal: "Test ",
    //   tahun: "2022",
    //   volume: "3",
    //   nomor: 123,
    //   jenis_publikasi: 1,
    //   peran_penulis: 2,
    //   issn: 123,
    //   url: "https://inkandcanvas.com",
    //   lampiran: "",
    // },
  ],
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [action.payload.tab]: action.payload.response,
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case DASHBOARD_IDENTITAS_DOSEN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DASHBOARD_IDENTITAS_DOSEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [action.payload.tab]: action.payload.response,
      };
    case DASHBOARD_IDENTITAS_DOSEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case DASHBOARD_SINTA_DOSEN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DASHBOARD_SINTA_DOSEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [action.payload.tab]: action.payload.response,
      };
    case DASHBOARD_SINTA_DOSEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };

    case TRACKING_USULAN_PENELITIAN_REQUEST:
      return {
        ...state,
        isLoadingTracking: true,
      };
    case TRACKING_USULAN_PENELITIAN_SUCCESS:
      return {
        ...state,
        isLoadingTracking: false,
        tracking_usulan_penelitian: action.payload,
      };
    case TRACKING_USULAN_PENELITIAN_FAILURE:
      return {
        ...state,
        isLoadingTracking: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // riwayat penelitian
    case GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        riwayatPenelitian: action.payload,
      };
    case GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // riwayat pengabdian
    case GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        riwayatPengabdian: action.payload,
      };
    case GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case LIST_USULAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LIST_USULAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listUsulan: action.payload,
      };
    case LIST_USULAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };

    case DASHBOARD_SYNC_PDDIKTI_REQUEST:
      return {
        ...state,
        isSync: true,
      };

    case DASHBOARD_SYNC_PDDIKTI_SUCCESS:
      return {
        ...state,
        isSync: false,
        identitasPddikti: {
          nidn: action.payload?.nidn || "-",
          nama: action.payload?.nama || "-",
          institusi: action.payload?.institusi || "-",
          program_studi: {
            nama: action.payload?.program_studi?.nama || "-",
          },
          jenjang_pendidikan_tertinggi: {
            nama: action.payload?.jenjang_pendidikan_tertinggi?.nama || "-",
          },
          jabatan_fungsional: {
            jabatan: action.payload?.jabatan_fungsional?.jabatan || "-",
          },
          alamat: action.payload?.alamat || "-",
          tempat_lahir: action.payload?.tempat_lahir || "-",
          tanggal_lahir: action.payload?.tanggal_lahir || "-",
          nomor_ktp: action.payload?.nomor_ktp || "-",
          nomor_telepon: action.payload?.nomor_telepon || "-",
          nomor_hp: action.payload?.nomor_hp || "-",
          surel: action.payload?.surel || "-",
          website_personal: action.payload?.website_personal || "-",
        } /* ,
        identitas: {
          nidn: action.payload.current_data.nidn,
          nama: action.payload.current_data.nama,
          institusi: action.payload.current_data.institusi,
          program_studi: {
            nama_program_studi:action.payload.current_data.nm_prodi
          },
          jenjang_pendidikan_tertinggi: {
            nama_jenjang_pendidikan_tertinggi:action.payload.current_data.pendidikan_terakhir
          },
          jabatan_fungsional: {
            jabatan_fungsional:action.payload.current_data.jabatan_fungsional
          },
          alamat: action.payload.current_data.alamat,
          tempat_lahir: action.payload.current_data.tempat_lahir,
          tanggal_lahir: action.payload.current_data.tgl_lahir,
          nomor_ktp: action.payload.current_data.nik,
          nomor_telepon: action.payload.current_data.telepon,
          nomor_hp: action.payload.current_data.handphone,
          surel: action.payload.current_data.email,
          website_personal: action.payload.pd_dikti_data.website
        }, */,
      };
    case DASHBOARD_SYNC_PDDIKTI_FAILURE:
      return {
        ...state,
        isSync: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case DASHBOARD_SYNC_SINTA_REQUEST:
      return {
        ...state,
        isSyncSinta: true,
      };

    case DASHBOARD_SYNC_SINTA_SUCCESS:
      return {
        ...state,
        isSyncSinta: false,
        sinta: action.payload,
      };
    case DASHBOARD_SYNC_SINTA_FAILURE:
      return {
        ...state,
        isSyncSinta: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case DASHBOARD_UPDATE_IDENTITAS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DASHBOARD_UPDATE_IDENTITAS:
      return {
        ...state,
        isFetching: false,
        identitas: {
          ...state.identitas,
          ...action.payload,
        },
      };
    case ARTICLE_REQUEST:
      return {
        ...state,
        isFetchingArticle: true,
      };
    case ARTICLE_SUCCESS:
      return {
        ...state,
        isFetchingArticle: false,
        itemsArticles: action.payload,
      };
    case ARTICLE_FAILURE:
      return {
        ...state,
        isFetchingArticle: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case ARTICLE_SAVE:
      return {
        ...state,
        isFetchingArticle: false,
        itemsArticles: [...state.itemsArticles, action.payload],
      };
    default:
      return state;
  }
};

export default notification;
