/* eslint-disable no-unused-vars */
import utilities from "helpers/utilities";
import { Content as ContentPengabdian } from "../../usulanBaruPengabdian/partials/content";
import { content as ContentPenelitian } from "../../usulanPenelitian/partials/content";
const sumNilai = (data = [], property) => {
  return data.reduce((accumulator, currentExpense) => {
    return accumulator + currentExpense[property];
  }, 0);
};
const sumNilaiRab = (data = [], property) => {
  return data
    .filter(item => [11, 12, 13, 14, 15].includes(item.id_rab_kelompok_biaya))
    .reduce((accumulator, currentExpense) => {
      return accumulator + currentExpense[property] * currentExpense.volume;
    }, 0);
};
export default function Content(data, kode_jenis_kegiatan, additionalData) {
  const dataProposal = additionalData.proposalUsulan;
  let mainContent = [];
  if (kode_jenis_kegiatan === 2) {
    dataProposal.rab = data.anggaran.detail.map(item => ({
      urutan_thn_usulan: parseInt(item.thn_urutan),
      id_rab_komponen_belanja: "?",
      nama_rab_komponen_belanja: item.komponen,
      id_rab_kelompok_biaya: item.id_rab_kelompok_biaya,
      nama_rab_kelompok_biaya: item.jenis_pembelanjaan,
      nama_item: item.item_belanja,
      satuan: item.satuan,
      volume: item.volume,
      harga_satuan: item.biaya_satuan,
    }));
    mainContent = [
      ...ContentPengabdian(dataProposal),
      {
        marginTop: 5,
        text: ["7. KEMAJUAN PENGABDIAN"],
        style: ["bold"],
      },
    ];
  } else if (kode_jenis_kegiatan === 1) {
    dataProposal.jenis_kegiatan = "penelitian";
    dataProposal.rab = data.anggaran.detail.map(item => ({
      urutan_thn_usulan: parseInt(item.thn_urutan),
      id_rab_komponen_belanja: "?",
      nama_rab_komponen_belanja: item.komponen,
      id_rab_kelompok_biaya: item.id_rab_kelompok_biaya,
      nama_rab_kelompok_biaya: item.jenis_pembelanjaan,
      nama_item: item.item_belanja,
      satuan: item.satuan,
      volume: item.volume,
      harga_satuan: item.biaya_satuan,
    }));
    mainContent = [
      ...ContentPenelitian(dataProposal),
      {
        marginTop: 5,
        text: ["6. KEMAJUAN PENELITIAN"],
        style: ["bold"],
      },
    ];
  }
  return [
    ...mainContent,
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t A. RINGKASAN",
        preserveLeadingSpaces: true,
      },
      style: ["bold"],
    },
    {
      marginTop: 5,
      text: `\u200B\t\t ${data.ringkasan}`,
      style: ["text", "fontSizeM"],
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t B. KATA KUNCI",
        preserveLeadingSpaces: true,
      },
      style: ["bold", "text"],
    },
    {
      marginTop: 5,
      text: `\u200B\t\t ${data.keyword}`,
      style: ["text"],
    },
  ];
}
export const contentSptbPengabdian = (data = {}) => {
  const catatanHarian = data.dataCatatanHarian;
  const laporanKemajuan = data.dataLaporanKemajuan;
  const reMapped = catatanHarian.map(item => ({
    ...item,
    kelompok_biaya: item.rab_kelompok_biaya.kelompok_biaya,
  }));
  const grouppedCatatanHarian = utilities.groupBy(reMapped, "kelompok_biaya");
  const grouppedRab = utilities.groupBy(
    catatanHarian[0]?.rab_komponen_belanja_revisi || [],
    "id_rab_kelompok_biaya"
  );
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["5%", "23.75%", "23.75%", "23.75%", "23.75%"],
      body: [
        [
          {
            text: `No`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Komponen Biaya`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `No. Berkas`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `RAB`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Jumlah Terealisasi`,
            style: ["center", "bgColorHeaderTable"],
          },
        ],
      ],
    },
  };
  Object.keys(grouppedCatatanHarian).forEach((property, indexProperty) => {
    grouppedCatatanHarian[property].forEach((item, indexItem) => {
      if (indexItem === 0) {
        layout.table.body.push([
          {
            text: `${indexProperty + 1}`,
            style: ["center", "bold"],
          },
          {
            text: `${property}`,
            style: ["center", "bold"],
          },
          {
            text: ``,
            style: ["center"],
          },
          {
            text: `${utilities.toCurrency(
              sumNilaiRab(
                grouppedRab[item.rab_kelompok_biaya_id],
                "harga_satuan"
              ),
              ".",
              0,
              "Rp. "
            )}`,
            style: ["center", "bold"],
          },
          {
            text: `${utilities.toCurrency(
              sumNilai(grouppedCatatanHarian[property], "biaya_yg_terpakai"),
              ".",
              0,
              "Rp. "
            )}`,
            style: ["center", "bold"],
          },
        ]);
      }
      layout.table.body.push([
        {
          text: ``,
          style: ["center"],
        },
        {
          text: `${item.kegiatan_yg_dilakukan}`,
          style: ["center"],
        },
        {
          text: `${item.no_berkas}`,
          style: ["center"],
        },
        {
          text: ``,
          style: ["center"],
        },
        {
          text: `${utilities.toCurrency(
            item.biaya_yg_terpakai,
            ".",
            0,
            "Rp. "
          )}`,
          style: ["center"],
        },
      ]);
    });
  });
  layout.table.body.push([
    {
      text: `Total`,
      style: ["center", "bold"],
      colSpan: 3,
    },
    {
      text: ``,
      style: ["center"],
    },
    {
      text: ``,
      style: ["center"],
    },
    {
      text: `${utilities.toCurrency(
        sumNilaiRab(
          catatanHarian[0]?.rab_komponen_belanja_revisi || [],
          "harga_satuan"
        ),
        ".",
        0,
        "Rp. "
      )}`,
      style: ["center", "bold"],
    },
    {
      text: `${utilities.toCurrency(
        sumNilai(catatanHarian, "biaya_yg_terpakai"),
        ".",
        0,
        "Rp. "
      )}`,
      style: ["center", "bold"],
    },
  ]);
  const nameKetuaLembaga =
    laporanKemajuan.dosen_ketua.institusi?.additional?.find(
      item => item.peran.id === 10
    )?.personal?.nama;
  const nidnKetuaLembaga =
    laporanKemajuan.dosen_ketua.institusi?.additional?.find(
      item => item.peran.id === 10
    )?.nidn;
  return [
    {
      stack: [
        {
          text: "Penggunaan Anggaran",
          style: ["bold", "center", "mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: `Nama Ketua : ${laporanKemajuan.dosen_ketua.nama}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Judul : ${laporanKemajuan.judul}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Tahun Pelaksanaan : ${
            laporanKemajuan.usulan.usulan_kegiatan.find(
              item => item.id == laporanKemajuan.id_usulan_kegiatan
            )?.thn_pelaksanaan_kegiatan
          }`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Dana Disetujui : ${utilities.toCurrency(
            laporanKemajuan.usulan_di_danai.dana_disetujui,
            ".",
            0,
            "Rp. "
          )}`,
          style: ["bold", "mb-5"],
        },
      ],
    },
    {
      stack: [
        {
          ...layout,
        },
      ],
    },
    {
      stack: [
        {
          text: `Sisa Dana : ${utilities.toCurrency(
            sumNilaiRab(
              catatanHarian[0]?.rab_komponen_belanja_revisi || [],
              "harga_satuan"
            ) - sumNilai(catatanHarian, "biaya_yg_terpakai"),
            ".",
            0,
            "Rp. "
          )}`,
          style: ["mt-10", "bold"],
        },
        {
          text: `Dana yang sudah digunakan : ${(
            (sumNilai(catatanHarian, "biaya_yg_terpakai") /
              sumNilaiRab(
                catatanHarian[0]?.rab_komponen_belanja_revisi || [],
                "harga_satuan"
              )) *
            100
          ).toFixed()} %
          `,
          style: ["mt-5", "bold"],
        },
      ],
    },
    {
      style: ["mt-20"],
      stack: [
        {
          style: ["text"],
          layout: "noBorders", // Menghilangkan border tabel,
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "Mengetahui, Ketua LPPM",
                  style: ["left"],
                },
                {
                  text: "Ketua Pelaksana",
                  style: ["right"],
                },
              ],
              [
                {
                  text: `\n${nameKetuaLembaga}`,
                  style: ["left", "mt-20"],
                },
                {
                  text: `\n${laporanKemajuan.dosen_ketua.nama}`,
                  style: ["right", "mt-20"],
                },
              ],
              [
                {
                  text: `${nidnKetuaLembaga}`,
                  style: ["left", "mt-2"],
                },
                {
                  text: `${laporanKemajuan.dosen_ketua.nidn}`,
                  style: ["right", "mt-2"],
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
export const contentSptbPenelitian = (data = {}) => {
  const { sptbItems, data_dosen, dataForm, rab_revisi } = data.data;
  const { dosen_ketua } = data_dosen;
  const sumNilai = (data = [], property) => {
    return data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.realisasi;
    }, 0);
  };
  const sumRabByKelompok = data => {
    const persentase = 100;
    const nilai_awal = data?.reduce(
      (a, { harga_satuan, volume }) =>
        a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
      0
    );
    const hasil = (persentase / 100) * nilai_awal;
    return hasil;
  };
  const tableSptb = () => {
    const layout = {
      style: ["mb-10"],
      layout: {
        hLineWidth: (_i, _node) => 0.3,
        vLineWidth: _i => 0.3,
        padding: () => 0,
      },
      table: {
        headerRows: 1,
        widths: ["5%", "59%", "18%", "18%"],
        body: [
          [
            {
              text: `No`,
              style: ["center", "bgColorHeaderTable"],
            },
            {
              text: `Uraian`,
              style: ["center", "bgColorHeaderTable"],
            },
            {
              text: `RAB 100%`,
              style: ["center", "bgColorHeaderTable"],
            },
            {
              text: `Realisasi`,
              style: ["center", "bgColorHeaderTable"],
            },
          ],
        ],
      },
    };
    sptbItems.forEach(item => {
      layout.table.body.push([
        {
          text: `${item.no}`,
          style: ["center"],
        },
        {
          text: [
            { text: `${item.nama_kelompok_rab}\n`, bold: true },
            `${item.uraian}`,
          ],
          style: ["left"],
        },
        {
          text: `${utilities.toCurrency(
            sumRabByKelompok(
              rab_revisi.filter(x =>
                item.id_kelompok_rab.includes(x.id_rab_kelompok_biaya)
              )
            ),
            ".",
            0,
            "Rp."
          )}`,
          style: ["left"],
        },
        {
          text: `${utilities.toCurrency(item.realisasi, ".", 0, "Rp.")}`,
          style: ["left"],
        },
      ]);
    });
    layout.table.body.push([
      {
        text: `Realisasi ( 100 % )`,
        style: ["center", "bold"],
        colSpan: 3,
      },
      {
        text: ``,
      },
      {
        text: ``,
      },
      {
        text: `${utilities.toCurrency(sumNilai(sptbItems), ".", 0, "Rp.")}`,
      },
    ]);
    return layout;
  };
  return [
    {
      stack: [
        {
          text: "SURAT PERNYATAAN TANGGUNG JAWAB BELANJA",
          style: ["bold", "center", "mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: "Yang bertanda tangan di bawah ini :",
          style: ["mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: `Nama   : ${dosen_ketua.gelar_akademik_depan} ${dosen_ketua.nama} ${dosen_ketua.gelar_akademik_belakang}`,
          style: ["mb-10"],
        },
        {
          text: `Alamat : ${dosen_ketua.alamat}`,
          style: ["mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: `berdasarkan Surat Keputusan Nomor ${
            dataForm.nomor_surat_keputusan
          } dan Perjanjian / Kontrak Nomor ${
            dataForm.nomor_perjanjian_kontrak
          } mendapatkan Anggaran Penelitian ${
            data_dosen.usulan.judul
          } Sebesar ${utilities.toCurrency(
            data_dosen.usulan_di_danai.dana_disetujui,
            ".",
            0,
            "Rp."
          )}`,
          style: ["mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: "Dengan ini menyatakan bahwa :",
          style: ["mb-5"],
        },
      ],
    },
    {
      stack: [
        {
          text: " 1. Biaya kegiatan Penelitian di bawah ini meliputi :",
          style: ["mb-10"],
        },
      ],
    },
    {
      stack: [tableSptb()],
    },
    {
      stack: [
        {
          text: " 2. Jumlah uang tersebut pada angka 1, benar-benar dikeluarkan untuk pelaksanaan kegiatan Penelitian dimaksud.",
          style: ["mb-10"],
        },
        {
          text: "Demikian surat pernyataan ini dibuat dengan sebenarnya.",
          style: ["mb-10"],
          // pageBreak: "after",
        },
      ],
    },
    {
      style: ["mt-20"],
      stack: [
        {
          style: ["text"],
          layout: "noBorders", // Menghilangkan border tabel,
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "",
                  style: ["left"],
                },
                {
                  text: `${dataForm.tempat_tanggal}, Ketua\n\n`,
                  style: ["right"],
                },
              ],
              [
                {
                  text: ``,
                  style: ["left", "mt-20"],
                },
                {
                  text: `${dosen_ketua.gelar_akademik_depan} ${dosen_ketua.nama} ${dosen_ketua.gelar_akademik_belakang}`,
                  style: ["right", "mt-20"],
                },
              ],
              [
                {
                  text: ``,
                  style: ["left", "mt-2"],
                },
                {
                  text: `NIP/NIPK ${dataForm.nip_nipk}`,
                  style: ["right", "mt-2"],
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
