import { instances } from "../api_helper";
import * as url from "../url_helper";

//ENDPOINT_PENGUMUMAN?criteria=type:pengumuman
//ENDPOINT_PENGUMUMAN?criteria=type:berita

const getPengumuman = ({ sort, judul, direktorat, count }) => {
  return instances.request(
    `${url.ENDPOINT_PENGUMUMAN}?sort=tgl_created:${sort}${
      judul != "" || direktorat !== ""
        ? `&criteria=judul:${judul},is_deleted:false,type:pengumuman,direktorat:${direktorat}`
        : `&criteria=is_deleted:false,type:pengumuman`
    }${count != 1 ? `&page=${count}:10` : `&page=1:10`} `,
    {
      method: "GET",
    }
    //console.log("helper", sort, query, count)
  );
};

const getPengumumanLanding = payload => {
  return instances.request(
    `${url.ENDPOINT_PENGUMUMAN}?sort=tgl_created:desc&page=1:3&criteria=is_deleted:false,type:pengumuman,direktorat:${payload}`,
    {
      method: "GET",
    }
  );
};

const getPengumumanById = id => {
  return instances.request(`${url.ENDPOINT_PENGUMUMAN}/${id}`, {
    method: "GET",
  });
};

const deletePengumuman = props => {
  return instances.request(`${url.ENDPOINT_PENGUMUMAN}/${props.id}`, {
    method: "DELETE",
  });
};

const deleteFilePengumuman = props => {
  return instances.request(`${url.ENDPOINT_PENGUMUMAN_FILE}/${props.id}`, {
    method: "DELETE",
  });
};

const createPengumuman = ({
  persentase_capaian,
  tgl_pelaksanaan,
  kegiatan_yg_dilakukan,
  id_usulan_kegiatan,
}) => {
  const payload = {
    persentase_capaian,
    tgl_pelaksanaan,
    kegiatan_yg_dilakukan,
    id_usulan_kegiatan,
  };

  return instances.request(`${url.ENDPOINT_PENGUMUMAN}`, {
    method: "POST",
    data: payload,
  });
};

const uploadPengumuman = ({ file, id_catatan_harian }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("id_catatan_harian", id_catatan_harian);
  return instances.request(`${url.ENDPOINT_PENGUMUMAN}`, {
    method: "POST",
    data: formData,
  });
};

const updatePengumuman = payload => {
  return instances.request(`${url.ENDPOINT_PENGUMUMAN}/${payload.id}`, {
    method: "PUT",
    data: payload,
  });
};

const getPupupAnnocement = () =>
  instances.request(`${url.ENDPOINT_ENUMERATION}/103`, {
    method: "GET",
  });

const updatePopupAnnouncement = (payload) => {
  return instances.request(`${url.ENDPOINT_ENUMERATION}/103`, {
    method: "PUT",
    data: payload
  })
}

const getFilePanduan = () =>
  instances.request(`${url.ENDPOINT_ENUMERATION}/1351`, {
    method: "GET",
  });

export {
  getPengumuman,
  getPengumumanLanding,
  getPengumumanById,
  deletePengumuman,
  createPengumuman,
  uploadPengumuman,
  updatePengumuman,
  deleteFilePengumuman,
  getPupupAnnocement,
  updatePopupAnnouncement,
  getFilePanduan,
};
