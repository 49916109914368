import { instances } from "../../api_helper";
import encStorage from "helpers/encStorage";
import * as url from "../../url_helper";
const storage = JSON.parse(encStorage.getItem("authUser"));
import { decode } from "helpers/jwt";
export const getOperatorPtDashboardTkt = () => {
  let URL = "";
  const jwtResult = decode(storage?.token);
  URL = `${url.ENDPOINT_DASHBOARD_OPERATOR_PT_TKT}/${jwtResult.id_institusi}`;
  return instances.request(URL, {
    method: "GET",
  });
};
