import { instances, instancesV2 } from "../api_helper";
import * as url from "../url_helper";

const updateUsulanPerbaikanV2 = payload => {
  return instancesV2.request(
    `${url.ENDPOINT_USULAN_PERBAIKAN_V2}/${payload?.id_proposal_kegiatan}`,
    {
      method: "PUT",
      data: payload.data,
    }
  );
};

const getUsulanPerbaikanV2 = payload => {
  if (payload?.id_proposal_kegiatan) {
    return instancesV2.request(
      `${url.ENDPOINT_USULAN_PERBAIKAN_V2}/${payload?.id_proposal_kegiatan}`,
      {
        method: "GET",
      }
    );
  } else {
    let params = "";
    Object.entries(payload).forEach(([key, value], index) => {
      params += `${key}=${value}${
        index + 1 === Object.entries(payload).length ? "" : "&"
      }`;
    });
    const URL = `${url.ENDPOINT_USULAN_PERBAIKAN_V2}${
      params ? "?" + params : ""
    }`;
    return instancesV2.request(URL, {
      method: "GET",
    });
  }
};

const getUsulanPerbaikanMonitoringV2 = ({
  id_skema = "",
  id_institusi,
  thn_pelaksanaan,
  jenis_kegiatan,
}) => {
  return instancesV2.request(
    `${url.ENDPOINT_USULAN_PERBAIKAN_V2}/search?id_skema=${id_skema}&id_institusi=${id_institusi}&thn_pelaksanaan=${thn_pelaksanaan}&jenis_kegiatan=${jenis_kegiatan}`,
    {
      method: "GET",
    }
  );
};

const getUsulanPerbaikan = (payload = { jenis_usulan: "penelitian" }) => {
  if (payload.id) {
    return instances.request(
      `/${payload.jenis_usulan}/${payload.jenis_usulan}${url.ENDPOINT_USULAN_PERBAIKAN}/by-id-usulan-kegiatan/${payload.id}`,
      {
        method: "GET",
      }
    );
  } else {
    return instances.request(
      `/${payload.jenis_usulan}/${payload.jenis_usulan}${url.ENDPOINT_USULAN_PERBAIKAN}?orderBy=thn_pelaksanaan_kegiatan:desc`,
      {
        method: "GET",
      }
    );
  }
};

const submitUsulanPerbaikan = payload => {
  return instances.request(`${url.ENDPOINT_USULAN_PERBAIKAN_SUBMIT}`, {
    method: "PUT",
    data: payload,
  });
};

export {
  getUsulanPerbaikan,
  submitUsulanPerbaikan,
  getUsulanPerbaikanV2,
  updateUsulanPerbaikanV2,
  getUsulanPerbaikanMonitoringV2,
};
