import stylePDF from "./styles";
import header from "./header";
import utilities from "helpers/utilities";
import { buildTblAnggaran } from "./content";

const contentPebraikanUsulan = (data, supportData) => {
    const { hr } = stylePDF;
    const { rab_komponen_belanja_revisi } = data;

    const anggaranByYear = rab_komponen_belanja_revisi.filter(item => item.urutan_thn_usulan === data?.urutan_thn_usulan_kegiatan);

    const getTotalRab = () => {
        const totalAnggaran = anggaranByYear.reduce((x, y) => x + (y.harga_satuan * y.volume), 0);
        return totalAnggaran;
    }

    return [
        header(),
        hr(5, 0),
        {
            stack: [
                {
                    text: "USULAN PERBAIKAN " + data?.jenis_kegiatan.toUpperCase(),
                    style: ["bold"],
                },
                {
                    text: `${data?.skema_kegiatan?.nama} (Tahun ke-${data?.urutan_thn_usulan_kegiatan} dari ${data?.lama_kegiatan} tahun)`,
                    style: ["fontSizeXs"],
                },
            ],
            style: ["center", "mb-5"],
        },
        {
            stack: [
                {
                    text: "ANGGARAN",
                    style: ["bold"],
                },
                {
                    text: "Rencana Anggaran Biaya penelitian mengacu pada PMK dan buku Panduan Penelitian dan Pengabdian kepada Masyarakat yang berlaku.",
                    style: ["justify", "mb-10"],
                },
                {
                    text: `Dana Tahun ke-${data?.urutan_thn_usulan_kegiatan} : Disetujui ${utilities.toCurrency(data?.dana_disetujui, ".", 2, 'Rp')} | Direncanakan : ${utilities.toCurrency(getTotalRab(), '.', 2, 'Rp')}`
                },
                {
                    ...buildTblAnggaran(anggaranByYear.filter(row => row.harga_satuan !== 0).map(rab => ({
                        ...rab,
                        nama_rab_komponen_belanja : rab.komponen_belanja,
                        nama_rab_kelompok_biaya : (supportData.kelompok_biaya || []).find(itm => rab.id_rab_kelompok_biaya === itm.id )?.kelompok_biaya,
                    })), 2)
                }
            ],
        }
    ];
};

export { contentPebraikanUsulan };