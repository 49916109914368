import {
  CLEAN_PERSONAL_SEARCH,
  DAFTAR_PROGRAM_STUDI_FAILURE,
  DAFTAR_PROGRAM_STUDI_REQUEST,
  DAFTAR_PROGRAM_STUDI_SUCCESS,
  DATA_PERSONAL_DOSEN_FAILURE,
  DATA_PERSONAL_DOSEN_REQUEST,
  DATA_PERSONAL_DOSEN_SUCCESS,
  GET_DOSEN_TO_PDDIKTI_FAILURE,
  GET_DOSEN_TO_PDDIKTI_REQUEST,
  GET_DOSEN_TO_PDDIKTI_SUCCESS,
  GET_PERSONAL_SEARCH_FAILURE,
  GET_PERSONAL_SEARCH_REQUEST,
  GET_PERSONAL_SEARCH_SUCCESS,
  GET_STATUS_DOSEN_FAILURE,
  GET_STATUS_DOSEN_REQUEST,
  GET_STATUS_DOSEN_SUCCESS,
  INSTITUSI_FAILURE,
  INSTITUSI_REQUEST,
  INSTITUSI_SUCCESS,
  KETUA_LPPM_FAILURE,
  KETUA_LPPM_REQUEST,
  KETUA_LPPM_SUCCESS,
  PT_DOSEN_BY_ID_FAILURE,
  PT_DOSEN_BY_ID_REQUEST,
  PT_DOSEN_BY_ID_SUCCESS,
  PT_DOSEN_FAILURE,
  PT_DOSEN_REQUEST,
  PT_DOSEN_SUCCESS,
  PT_NIDN_FAILURE,
  PT_NIDN_REQUEST,
  PT_NIDN_RESTART,
  PT_NIDN_SUCCESS,
  PUT_INSTITUSI_EDIT_NAMA_JABATAN_FAILURE,
  PUT_INSTITUSI_EDIT_NAMA_JABATAN_REQUEST,
  PUT_INSTITUSI_EDIT_NAMA_JABATAN_SUCCESS,
  PUT_INSTITUSI_FAILURE,
  PUT_INSTITUSI_REQUEST,
  PUT_INSTITUSI_SUCCESS,
  PUT_STATUS_DOSEN_FAILURE,
  PUT_STATUS_DOSEN_REQUEST,
  PUT_STATUS_DOSEN_SUCCESS,
  RESET_PWD_FAILURE,
  RESET_PWD_REQUEST,
  RESET_PWD_SUCCESS,
  SEARCH_DOSEN_BY_NIDN_FAILURE,
  SEARCH_DOSEN_BY_NIDN_REQUEST,
  SEARCH_DOSEN_BY_NIDN_SUCCESS,
  SEARCH_PRODI_BY_KODE_FAILURE,
  SEARCH_PRODI_BY_KODE_REQUEST,
  SEARCH_PRODI_BY_KODE_SUCCESS,
  SYNC_DATA_DOSEN_FAILURE,
  SYNC_DATA_DOSEN_REQUEST,
  SYNC_DATA_DOSEN_SUCCESS,
  SYNC_DATA_PRODI_FAILURE,
  SYNC_DATA_PRODI_REQUEST,
  SYNC_DATA_PRODI_SUCCESS,
  TAMBAH_DATA_DOSEN_FAILURE,
  TAMBAH_DATA_DOSEN_REQUEST,
  TAMBAH_DATA_DOSEN_SUCCESS
} from "./actionTypes";

export const getPersonalSearch = (request = {}, cb = () => {}) => {
  return {
    type: GET_PERSONAL_SEARCH_REQUEST,
    payload: request,
    cb
  };
}

export const getPersonalSearchSuccess = (response) => {
  return {
    type: GET_PERSONAL_SEARCH_SUCCESS,
    payload: response
  };
}

export const getPersonalSearchFailure = (message, response) => {
  return {
    type: GET_PERSONAL_SEARCH_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
}

export const cleanPersonalSearch = () => {
  return {
    type: CLEAN_PERSONAL_SEARCH,

  };
}


export const ketuaLppm = (request, cb) => {
  return {
    type: KETUA_LPPM_REQUEST,
    payload: request,
    cb,
  };
};

export const ketuaLppmSuccess = (response, state) => {
  return {
    type: KETUA_LPPM_SUCCESS,
    payload: response,
    state,
  };
};

export const ketuaLppmFailure = (response, message) => {
  return {
    type: KETUA_LPPM_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const tambahDataDosen = (request, cb) => {
  return {
    type: TAMBAH_DATA_DOSEN_REQUEST,
    payload: request,
    cb,
  };
};

export const tambahDataDosenSuccess = (response, state) => {
  return {
    type: TAMBAH_DATA_DOSEN_SUCCESS,
    payload: response,
    state,
  };
};

export const tambahDataDosenFailure = (response, message) => {
  return {
    type: TAMBAH_DATA_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const searchProdiByKode = (request, cb) => {
  return {
    type: SEARCH_PRODI_BY_KODE_REQUEST,
    payload: request,
    cb,
  };
};

export const searchProdiByKodeSuccess = (response, state) => {
  return {
    type: SEARCH_PRODI_BY_KODE_SUCCESS,
    payload: response,
    state,
  };
};

export const searchProdiByKodeFailure = (response, message) => {
  return {
    type: SEARCH_PRODI_BY_KODE_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const daftarProgramStudi = (request, cb) => {
  return {
    type: DAFTAR_PROGRAM_STUDI_REQUEST,
    payload: request,
    cb,
  };
};

export const daftarProgramStudiSuccess = (response, state) => {
  return {
    type: DAFTAR_PROGRAM_STUDI_SUCCESS,
    payload: response,
    state,
  };
};

export const daftarProgramStudiFailure = (response, message) => {
  return {
    type: DAFTAR_PROGRAM_STUDI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const putInstitusi = (request, cb) => {
  return {
    type: PUT_INSTITUSI_REQUEST,
    payload: request,
    cb,
  };
};

export const putInstitusiSuccess = (response, state) => {
  return {
    type: PUT_INSTITUSI_SUCCESS,
    payload: response,
    state,
  };
};

export const putInstitusiFailure = (response, message) => {
  return {
    type: PUT_INSTITUSI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const putInstitusiEditNamaJabatan = (request, cb) => {
  return {
    type: PUT_INSTITUSI_EDIT_NAMA_JABATAN_REQUEST,
    payload: request,
    cb,
  };
};

export const putInstitusiEditNamaJabatanSuccess = (response, state) => {
  return {
    type: PUT_INSTITUSI_EDIT_NAMA_JABATAN_SUCCESS,
    payload: response,
    state,
  };
};

export const putInstitusiEditNamaJabatanFailure = (response, message) => {
  return {
    type: PUT_INSTITUSI_EDIT_NAMA_JABATAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const putDataPersonalDosen = (request, cb) => {
  return {
    type: DATA_PERSONAL_DOSEN_REQUEST,
    payload: request,
    cb,
  };
};

export const putDataPersonalDosenSuccess = (response, state) => {
  return {
    type: DATA_PERSONAL_DOSEN_SUCCESS,
    payload: response,
    state,
  };
};

export const putDataPersonalDosenFailure = (response, message) => {
  return {
    type: DATA_PERSONAL_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const searchDosenByNidn = (request, cb) => {
  return {
    type: SEARCH_DOSEN_BY_NIDN_REQUEST,
    payload: request,
    cb,
  };
};

export const searchDosenByNidnSuccess = (response, state) => {
  return {
    type: SEARCH_DOSEN_BY_NIDN_SUCCESS,
    payload: response,
    state,
  };
};

export const searchDosenByNidnFailure = (message, response) => {
  return {
    type: SEARCH_DOSEN_BY_NIDN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getDosenToPddikti = request => {
  return {
    type: GET_DOSEN_TO_PDDIKTI_REQUEST,
    payload: request,
  };
};

export const getDosenToPddiktiSuccess = (response, state) => {
  return {
    type: GET_DOSEN_TO_PDDIKTI_SUCCESS,
    payload: response,
    state,
  };
};

export const getDosenToPddiktiFailure = (message, response) => {
  return {
    type: GET_DOSEN_TO_PDDIKTI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const syncDataDosen = (request = "") => {
  return {
    type: SYNC_DATA_DOSEN_REQUEST,
    payload: request,
  };
};

export const syncDataDosenSuccess = response => {
  return {
    type: SYNC_DATA_DOSEN_SUCCESS,
    payload: response,
  };
};

export const syncDataDosenFailure = (message, response) => {
  return {
    type: SYNC_DATA_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getPtDosen = request => {
  return {
    type: PT_DOSEN_REQUEST,
    payload: request,
  };
};

export const getPtDosenSuccess = (response, state) => {
  return {
    type: PT_DOSEN_SUCCESS,
    payload: response,
    state,
  };
};

export const getPtDosenFailure = (response, message) => {
  return {
    type: PT_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getPtById = request => {
  return {
    type: PT_DOSEN_BY_ID_REQUEST,
    payload: request,
  };
};

export const getPtByIdSuccess = (response, state) => {
  return {
    type: PT_DOSEN_BY_ID_SUCCESS,
    payload: response,
    state,
  };
};

export const getPtByIdFailure = (response, message) => {
  return {
    type: PT_DOSEN_BY_ID_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const restartPtNidn = () => {
  return {
    type: PT_NIDN_RESTART,
  };
};
export const getPtNidn = request => {
  return {
    type: PT_NIDN_REQUEST,
    payload: request,
  };
};

export const getPtNidnSuccess = (response, state) => {
  return {
    type: PT_NIDN_SUCCESS,
    payload: response,
    state,
  };
};

export const getPtNidnFailure = (response, message) => {
  return {
    type: PT_NIDN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getInstitusi = request => {
  return {
    type: INSTITUSI_REQUEST,
    payload: request,
  };
};

export const getInstitusiSuccess = (response, state) => {
  return {
    type: INSTITUSI_SUCCESS,
    payload: response,
    state,
  };
};

export const getInstitusiFailure = (response, message) => {
  return {
    type: INSTITUSI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const resetPwd = request => {
  return {
    type: RESET_PWD_REQUEST,
    payload: request,
  };
};

export const resetPwdSuccess = (response, state) => {
  return {
    type: RESET_PWD_SUCCESS,
    payload: response,
    state,
  };
};

export const resetPwdFailure = (response, message) => {
  return {
    type: RESET_PWD_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const syncDataProdi = (request, cb) => {
  return {
    type: SYNC_DATA_PRODI_REQUEST,
    payload: request,
    cb,
  };
};

export const syncDataProdiSuccess = (response, state) => {
  return {
    type: SYNC_DATA_PRODI_SUCCESS,
    payload: response,
    state,
  };
};

export const syncDataProdiFailure = (response, message) => {
  return {
    type: SYNC_DATA_PRODI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getAllStatusDosen = request => {
  return {
    type: GET_STATUS_DOSEN_REQUEST,
    payload: request,
  };
};

export const getAllStatusDosenSuccess = (response, state) => {
  return {
    type: GET_STATUS_DOSEN_SUCCESS,
    payload: response,
    state,
  };
};

export const getAllStatusDosenFailure = (response, message) => {
  return {
    type: GET_STATUS_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const putStatusDosen = request => {
  return {
    type: PUT_STATUS_DOSEN_REQUEST,
    payload: request,
  };
};

export const putStatusDosenSuccess = (response, state) => {
  return {
    type: PUT_STATUS_DOSEN_SUCCESS,
    payload: response,
    state,
  };
};

export const pusStatusDosenFailure = (response, message) => {
  return {
    type: PUT_STATUS_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
