export const JENIS_BANTUAN_REQUEST = "JENIS_BANTUAN_REQUEST";
export const JENIS_BANTUAN_SUCCESS = "JENIS_BANTUAN_SUCCESS";
export const JENIS_BANTUAN_FAILURE = "JENIS_BANTUAN_FAILURE";

export const GET_INSTITUSI_BYID_REQUEST = "GET_INSTITUSI_BYID_REQUEST";
export const GET_INSTITUSI_BYID_SUCCESS = "GET_INSTITUSI_BYID_SUCCESS";
export const GET_INSTITUSI_BYID_FAILURE = "GET_INSTITUSI_BYID_FAILURE";

export const PENUGASAN_DOC_REQUEST = "PENUGASAN_DOC_REQUEST";
export const PENUGASAN_DOC_SUCCESS = "PENUGASAN_DOC_SUCCESS";
export const PENUGASAN_DOC_FAILURE = "PENUGASAN_DOC_FAILURE";

export const CREATE_PENUGASAN_NUSANTARA_REQUEST = "CREATE_PENUGASAN_NUSANTARA_REQUEST";
export const CREATE_PENUGASAN_NUSANTARA_SUCCESS = "CREATE_PENUGASAN_NUSANTARA_SUCCESS";
export const CREATE_PENUGASAN_NUSANTARA_FAILURE = "CREATE_PENUGASAN_NUSANTARA_FAILURE";

export const GET_PENUGASAN_NUSANTARA_REQUEST = "GET_PENUGASAN_NUSANTARA_REQUEST";
export const GET_PENUGASAN_NUSANTARA_SUCCESS = "GET_PENUGASAN_NUSANTARA_SUCCESS";
export const GET_PENUGASAN_NUSANTARA_FAILURE = "GET_PENUGASAN_NUSANTARA_FAILURE";

export const GET_PENUGASAN_NUSANTARA_BYID_REQUEST = "GET_PENUGASAN_NUSANTARA_BYID_REQUEST";
export const GET_PENUGASAN_NUSANTARA_BYID_SUCCESS = "GET_PENUGASAN_NUSANTARA_BYID_SUCCESS";
export const GET_PENUGASAN_NUSANTARA_BYID_FAILURE = "GET_PENUGASAN_NUSANTARA_BYID_FAILURE";

export const UPDATE_PENUGASAN_NUSANTARA_REQUEST = "UPDATE_PENUGASAN_NUSANTARA_REQUEST";
export const UPDATE_PENUGASAN_NUSANTARA_SUCCESS = "UPDATE_PENUGASAN_NUSANTARA_SUCCESS";
export const UPDATE_PENUGASAN_NUSANTARA_FAILURE = "UPDATE_PENUGASAN_NUSANTARA_BYID_FAILURE";

export const GET_PENUGASAN_JFS_REQUEST = "GET_PENUGASAN_JFS_REQUEST";
export const GET_PENUGASAN_JFS_SUCCESS = "GET_PENUGASAN_JFS_SUCCESS";
export const GET_PENUGASAN_JFS_FAILURE = "GET_PENUGASAN_JFS_FAILURE";

export const CREATE_PENUGASAN_JFS_REQUEST = "CREATE_PENUGASAN_JFS_REQUEST";
export const CREATE_PENUGASAN_JFS_SUCCESS = "CREATE_PENUGASAN_JFS_SUCCESS";
export const CREATE_PENUGASAN_JFS_FAILURE = "CREATE_PENUGASAN_JFS_FAILURE";

export const GET_PENUGASAN_JFS_BYID_REQUEST = "GET_PENUGASAN_JFS_BYID_REQUEST";
export const GET_PENUGASAN_JFS_BYID_SUCCESS = "GET_PENUGASAN_JFS_BYID_SUCCESS";
export const GET_PENUGASAN_JFS_BYID_FAILURE = "GET_PENUGASAN_JFS_BYID_FAILURE";

export const UPDATE_PENUGASAN_JFS_REQUEST = "UPDATE_PENUGASAN_JFS_REQUEST";
export const UPDATE_PENUGASAN_JFS_SUCCESS = "UPDATE_PENUGASAN_JFS_SUCCESS";
export const UPDATE_PENUGASAN_JFS_FAILURE = "UPDATE_PENUGASAN_JFS_FAILURE";

export const GET_PENUGASAN_KOSABANGSA_REQUEST = "GET_PENUGASAN_KOSABANGSA_REQUEST";
export const GET_PENUGASAN_KOSABANGSA_SUCCESS = "GET_PENUGASAN_KOSABANGSA_SUCCESS";
export const GET_PENUGASAN_KOSABANGSA_FAILURE = "GET_PENUGASAN_KOSABANGSA_FAILURE";

export const CREATE_PENUGASAN_KOSABANGSA_REQUEST = "CREATE_PENUGASAN_KOSABANGSA_REQUEST";
export const CREATE_PENUGASAN_KOSABANGSA_SUCCESS = "CREATE_PENUGASAN_KOSABANGSA_SUCCESS";
export const CREATE_PENUGASAN_KOSABANGSA_FAILURE = "CREATE_PENUGASAN_KOSABANGSA_FAILURE";

export const GET_PENUGASAN_KOSABANGSA_BYID_REQUEST = "GET_PENUGASAN_KOSABANGSA_BYID_REQUEST";
export const GET_PENUGASAN_KOSABANGSA_BYID_SUCCESS = "GET_PENUGASAN_KOSABANGSA_BYID_SUCCESS";
export const GET_PENUGASAN_KOSABANGSA_BYID_FAILURE = "GET_PENUGASAN_KOSABANGSA_BYID_FAILURE";

export const UPDATE_PENUGASAN_KOSABANGSA_REQUEST = "UPDATE_PENUGASAN_KOSABANGSA_REQUEST";
export const UPDATE_PENUGASAN_KOSABANGSA_SUCCESS = "UPDATE_PENUGASAN_KOSABANGSA_SUCCESS";
export const UPDATE_PENUGASAN_KOSABANGSA_FAILURE = "UPDATE_PENUGASAN_KOSABANGSA_FAILURE";

export const GET_PENUGASAN_PROTOTIPE_REQUEST = "GET_PENUGASAN_PROTOTIPE_REQUEST";
export const GET_PENUGASAN_PROTOTIPE_SUCCESS = "GET_PENUGASAN_PROTOTIPE_SUCCESS";
export const GET_PENUGASAN_PROTOTIPE_FAILURE = "GET_PENUGASAN_PROTOTIPE_FAILURE";

export const VALIDATION_PENUGASAN_PROTOTIPE_REQUEST = "VALIDATION_PENUGASAN_PROTOTIPE_REQUEST";
export const VALIDATION_PENUGASAN_PROTOTIPE_SUCCESS = "VALIDATION_PENUGASAN_PROTOTIPE_SUCCESS";
export const VALIDATION_PENUGASAN_PROTOTIPE_FAILURE = "VALIDATION_PENUGASAN_PROTOTIPE_FAILURE";

export const GET_PENUGASAN_PROTOTIPE_BYID_REQUEST = "GET_PENUGASAN_PROTOTIPE_BYID_REQUEST";
export const GET_PENUGASAN_PROTOTIPE_BYID_SUCCESS = "GET_PENUGASAN_PROTOTIPE_BYID_SUCCESS";
export const GET_PENUGASAN_PROTOTIPE_BYID_FAILURE = "GET_PENUGASAN_PROTOTIPE_BYID_FAILURE";

export const CREATE_PENUGASAN_PROTOTIPE_REQUEST = "CREATE_PENUGASAN_PROTOTIPE_REQUEST";
export const CREATE_PENUGASAN_PROTOTIPE_SUCCESS = "CREATE_PENUGASAN_PROTOTIPE_SUCCESS";
export const CREATE_PENUGASAN_PROTOTIPE_FAILURE = "CREATE_PENUGASAN_PROTOTIPE_FAILURE";

export const UPDATE_PENUGASAN_PROTOTIPE_REQUEST = "UPDATE_PENUGASAN_PROTOTIPE_REQUEST";
export const UPDATE_PENUGASAN_PROTOTIPE_SUCCESS = "UPDATE_PENUGASAN_PROTOTIPE_SUCCESS";
export const UPDATE_PENUGASAN_PROTOTIPE_FAILURE = "UPDATE_PENUGASAN_PROTOTIPE_FAILURE";

export const GET_PENUGASAN_GOOGLE_REQUEST = "GET_PENUGASAN_GOOGLE_REQUEST";
export const GET_PENUGASAN_GOOGLE_SUCCESS = "GET_PENUGASAN_GOOGLE_SUCCESS";
export const GET_PENUGASAN_GOOGLE_FAILURE = "GET_PENUGASAN_GOOGLE_FAILURE";

export const GET_PENUGASAN_GOOGLE_BYID_REQUEST = "GET_PENUGASAN_GOOGLE_BYID_REQUEST";
export const GET_PENUGASAN_GOOGLE_BYID_SUCCESS = "GET_PENUGASAN_GOOGLE_BYID_SUCCESS";
export const GET_PENUGASAN_GOOGLE_BYID_FAILURE = "GET_PENUGASAN_GOOGLE_BYID_FAILURE";

export const CREATE_PENUGASAN_GOOGLE_REQUEST = "CREATE_PENUGASAN_GOOGLE_REQUEST";
export const CREATE_PENUGASAN_GOOGLE_SUCCESS = "CREATE_PENUGASAN_GOOGLE_SUCCESS";
export const CREATE_PENUGASAN_GOOGLE_FAILURE = "CREATE_PENUGASAN_GOOGLE_FAILURE";

export const UPDATE_PENUGASAN_GOOGLE_REQUEST = "UPDATE_PENUGASAN_GOOGLE_REQUEST";
export const UPDATE_PENUGASAN_GOOGLE_SUCCESS = "UPDATE_PENUGASAN_GOOGLE_SUCCESS";
export const UPDATE_PENUGASAN_GOOGLE_FAILURE = "UPDATE_PENUGASAN_GOOGLE_FAILURE";

export const GET_PENUGASAN_PEMBELAJARAN_REQUEST = "GET_PENUGASAN_PEMBELAJARAN_REQUEST";
export const GET_PENUGASAN_PEMBELAJARAN_SUCCESS = "GET_PENUGASAN_PEMBELAJARAN_SUCCESS";
export const GET_PENUGASAN_PEMBELAJARAN_FAILURE = "GET_PENUGASAN_PEMBELAJARAN_FAILURE";

export const GET_PENUGASAN_PEMBELAJARAN_BYID_REQUEST = "GET_PENUGASAN_PEMBELAJARAN_BYID_REQUEST";
export const GET_PENUGASAN_PEMBELAJARAN_BYID_SUCCESS = "GET_PENUGASAN_PEMBELAJARAN_BYID_SUCCESS";
export const GET_PENUGASAN_PEMBELAJARAN_BYID_FAILURE = "GET_PENUGASAN_PEMBELAJARAN_BYID_FAILURE";

export const CREATE_PENUGASAN_PEMBELAJARAN_REQUEST = "CREATE_PENUGASAN_PEMBELAJARAN_REQUEST";
export const CREATE_PENUGASAN_PEMBELAJARAN_SUCCESS = "CREATE_PENUGASAN_PEMBELAJARAN_SUCCESS";
export const CREATE_PENUGASAN_PEMBELAJARAN_FAILURE = "CREATE_PENUGASAN_PEMBELAJARAN_FAILURE";

export const UPDATE_PENUGASAN_PEMBELAJARAN_REQUEST = "UPDATE_PENUGASAN_PEMBELAJARAN_REQUEST";
export const UPDATE_PENUGASAN_PEMBELAJARAN_SUCCESS = "UPDATE_PENUGASAN_PEMBELAJARAN_SUCCESS";
export const UPDATE_PENUGASAN_PEMBELAJARAN_FAILURE = "UPDATE_PENUGASAN_PEMBELAJARAN_FAILURE";

export const GET_BIDANG_FOKUS_REQUEST = "GET_BIDANG_FOKUS_REQUEST";
export const GET_BIDANG_FOKUS_SUCCESS = "GET_BIDANG_FOKUS_SUCCESS";
export const GET_BIDANG_FOKUS_FAILURE = "GET_BIDANG_FOKUS_FAILURE";

export const GET_SKEMA_REQUEST = "GET_SKEMA_REQUEST";
export const GET_SKEMA_SUCCESS = "GET_SKEMA_SUCCESS";
export const GET_SKEMA_FAILURE = "GET_SKEMA_FAILURE";

export const GET_SBK_UTAMA_REQUEST = "GET_SBK_UTAMA_REQUEST";
export const GET_SBK_UTAMA_SUCCESS = "GET_SBK_UTAMA_SUCCESS";
export const GET_SBK_UTAMA_FAILURE = "GET_SBK_UTAMA_FAILURE";


