import {
  CLEAN_PERSONAL_SEARCH,
  DAFTAR_PROGRAM_STUDI_FAILURE,
  DAFTAR_PROGRAM_STUDI_REQUEST,
  DAFTAR_PROGRAM_STUDI_SUCCESS,
  DATA_PERSONAL_DOSEN_FAILURE,
  DATA_PERSONAL_DOSEN_REQUEST,
  DATA_PERSONAL_DOSEN_SUCCESS,
  GET_DOSEN_TO_PDDIKTI_FAILURE,
  GET_DOSEN_TO_PDDIKTI_REQUEST,
  GET_DOSEN_TO_PDDIKTI_SUCCESS,
  GET_PERSONAL_SEARCH_FAILURE,
  GET_PERSONAL_SEARCH_REQUEST,
  GET_PERSONAL_SEARCH_SUCCESS,
  GET_STATUS_DOSEN_FAILURE,
  GET_STATUS_DOSEN_REQUEST,
  GET_STATUS_DOSEN_SUCCESS,
  INSTITUSI_FAILURE,
  INSTITUSI_REQUEST,
  INSTITUSI_SUCCESS,
  KETUA_LPPM_FAILURE,
  KETUA_LPPM_REQUEST,
  KETUA_LPPM_SUCCESS,
  PT_DOSEN_BY_ID_FAILURE,
  PT_DOSEN_BY_ID_REQUEST,
  PT_DOSEN_BY_ID_SUCCESS,
  PT_DOSEN_FAILURE,
  PT_DOSEN_REQUEST,
  PT_DOSEN_SUCCESS,
  PT_NIDN_FAILURE,
  PT_NIDN_REQUEST,
  PT_NIDN_RESTART,
  PT_NIDN_SUCCESS,
  PUT_INSTITUSI_EDIT_NAMA_JABATAN_FAILURE,
  PUT_INSTITUSI_EDIT_NAMA_JABATAN_REQUEST,
  PUT_INSTITUSI_EDIT_NAMA_JABATAN_SUCCESS,
  PUT_INSTITUSI_FAILURE,
  PUT_INSTITUSI_REQUEST,
  PUT_INSTITUSI_SUCCESS,
  PUT_STATUS_DOSEN_FAILURE,
  PUT_STATUS_DOSEN_REQUEST,
  PUT_STATUS_DOSEN_SUCCESS,
  RESET_PWD_FAILURE,
  RESET_PWD_REQUEST,
  RESET_PWD_SUCCESS,
  SEARCH_DOSEN_BY_NIDN_FAILURE,
  SEARCH_DOSEN_BY_NIDN_REQUEST,
  SEARCH_DOSEN_BY_NIDN_SUCCESS,
  SEARCH_PRODI_BY_KODE_FAILURE,
  SEARCH_PRODI_BY_KODE_REQUEST,
  SEARCH_PRODI_BY_KODE_SUCCESS,
  SYNC_DATA_DOSEN_FAILURE,
  SYNC_DATA_DOSEN_REQUEST,
  SYNC_DATA_DOSEN_SUCCESS,
  SYNC_DATA_PRODI_FAILURE,
  SYNC_DATA_PRODI_REQUEST,
  SYNC_DATA_PRODI_SUCCESS,
  TAMBAH_DATA_DOSEN_FAILURE,
  TAMBAH_DATA_DOSEN_REQUEST,
  TAMBAH_DATA_DOSEN_SUCCESS
} from "./actionTypes";

const initialState = {
  isFetching: false,
  isLoading: false,
  error: null,
  items: [],
  itemSelected: {},
  itemsNidn: {},
  institusi: {
    items: [],
    itemSelected: {},
    isFetching: false,
    isLoading: false,
  },
  searchNidn: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: [],
    itemSelected: {},
  },
  resetPwd: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: [],
    itemSelected: {},
  },
  searchDosenByNidn: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: {},
    itemSelected: {},
  },
  daftarProdi: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: [],
    itemSelected: {},
  },
  searchDaftarProdiByNidn: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: [],
    itemSelected: {},
  },
  tambahDataDosen: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: [],
    itemSelected: {},
  },
  ketuaLppm: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: [],
    itemSelected: {},
  },
  personalSearch: {
    isFetching: false,
    error: null,
    item: {},
    items: [],
  },
  getDosenToPddikti: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: {},
    itemSelected: {},
  },
  getAllStatusDosen: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: [],
  },
  putStatusDosen: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: {},
  },
  putInstitusiEditNamaJabatan: {
    isFetching: false,
    isLoading: false,
    error: null,
    items: {},
  },
};

const ptDosenReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_PERSONAL_SEARCH:
      return (state = {
        ...state,
        personalSearch: {
          ...state.personalSearch,
          isFetching: false,
          items : []

        },
      });
    case GET_PERSONAL_SEARCH_REQUEST:
      return (state = {
        ...state,
        personalSearch: {
          ...state.personalSearch,
          isFetching: true,

        },
      });
    case GET_PERSONAL_SEARCH_SUCCESS:
      return (state = {
        ...state,
        personalSearch: {
          ...state.personalSearch,
          isFetching: false,
          items: action.payload ?? [],
        },
      });
    case GET_PERSONAL_SEARCH_FAILURE:
      return (state = {
        ...state,
        personalSearch: {
          ...state.personalSearch,
          isFetching: false,
          error: action.payload.error,
        },
      });
    case KETUA_LPPM_REQUEST:
      return (state = {
        ...state,
        ketuaLppm: {
          ...state.ketuaLppm,
          isFetching: true,
          isLoading: true,
        },
      });
    case KETUA_LPPM_SUCCESS:
      return (state = {
        ...state,
        ketuaLppm: {
          ...state.ketuaLppm,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? [],
        },
      });
    case KETUA_LPPM_FAILURE:
      return (state = {
        ...state,
        ketuaLppm: {
          ...state.ketuaLppm,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
        },
      });
    case DATA_PERSONAL_DOSEN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        error: null,
        msg: "request data personal dosen",
      };
    case DATA_PERSONAL_DOSEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: null,
        itemSelected: action.payload ?? {},
        msg: "success request data personal dosen",
      };
    case DATA_PERSONAL_DOSEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "failure request data personal dosen",
      };
    case PT_DOSEN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "PT_DOSEN_REQUEST",
      };
    case PT_DOSEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        items: action.payload ?? [],
        msg: "PT_DOSEN_SUCCESS",
      };
    case PT_DOSEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
        msg: "PT_DOSEN_FAILURE",
      };
    case PT_DOSEN_BY_ID_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "PT_DOSEN_BY_ID_REQUEST",
      };
    case PT_DOSEN_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        itemSelected: action.payload ?? {},
        msg: "PT_DOSEN_BY_ID_SUCCESS",
      };
    case PT_DOSEN_BY_ID_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload.error,
        msg: "PT_DOSEN_BY_ID_FAILURE",
      };

    case PT_NIDN_REQUEST:
      return (state = {
        ...state,
        searchNidn: {
          ...state.searchNidn,
          isFetching: true,
          isLoading: true,
        },
      });
    case PT_NIDN_SUCCESS:
      return (state = {
        ...state,
        searchNidn: {
          ...state.searchNidn,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? [],
        },
      });
    case PT_NIDN_FAILURE:
      return (state = {
        ...state,
        searchNidn: {
          ...state.searchNidn,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
        },
      });
    case PT_NIDN_RESTART:
      return (state = {
        ...state,
        searchNidn: {
          ...state.searchNidn,
          isFetching: false,
          isLoading: false,
          items: [],
          itemSelected: {},
          error: null,
        },
      });
    case SEARCH_DOSEN_BY_NIDN_REQUEST:
      return (state = {
        ...state,
        searchDosenByNidn: {
          ...state.searchDosenByNidn,
          isFetching: true,
          isLoading: true,
          items: {},
        },
      });
    case SEARCH_DOSEN_BY_NIDN_SUCCESS:
      return (state = {
        ...state,
        searchDosenByNidn: {
          ...state.searchDosenByNidn,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? {},
        },
      });
    case SEARCH_DOSEN_BY_NIDN_FAILURE:
      return (state = {
        ...state,
        searchDosenByNidn: {
          ...state.searchDosenByNidn,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
          items: {},
        },
      });
    case GET_DOSEN_TO_PDDIKTI_REQUEST:
      return (state = {
        ...state,
        getDosenToPddikti: {
          ...state.getDosenToPddikti,
          isFetching: true,
          isLoading: true,
          error: action.payload.error,
          items: {},
        },
      });
    case GET_DOSEN_TO_PDDIKTI_SUCCESS:
      return (state = {
        ...state,
        getDosenToPddikti: {
          ...state.getDosenToPddikti,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? {},
        },
      });
    case GET_DOSEN_TO_PDDIKTI_FAILURE:
      return (state = {
        ...state,
        getDosenToPddikti: {
          ...state.getDosenToPddikti,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
          items: {},
        },
      });
    case INSTITUSI_REQUEST:
      return (state = {
        ...state,
        institusi: {
          ...state.institusi,
          isFetching: true,
          isLoading: true,
        },
      });
    case INSTITUSI_SUCCESS:
      return (state = {
        ...state,
        institusi: {
          ...state.institusi,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? [],
        },
      });
    case INSTITUSI_FAILURE:
      return (state = {
        ...state,
        institusi: {
          ...state.institusi,
          isFetching: false,
          error: action.payload.error,
          items: [],
        },
      });
    case PUT_INSTITUSI_REQUEST:
      return (state = {
        ...state,
        institusi: {
          ...state.institusi,
          isFetching: true,
          isLoading: true,
        },
      });
    case PUT_INSTITUSI_SUCCESS:
      return (state = {
        ...state,
        institusi: {
          ...state.institusi,
          isFetching: false,
          isLoading: false,
          itemSelected: action.payload ?? {},
        },
      });
    case PUT_INSTITUSI_FAILURE:
      return (state = {
        ...state,
        institusi: {
          ...state.institusi,
          isFetching: false,
          error: action.payload.error,
        },
      });
    case RESET_PWD_REQUEST:
      return (state = {
        ...state,
        resetPwd: {
          ...state.resetPwd,
          isFetching: true,
          isLoading: true,
        },
      });
    case RESET_PWD_SUCCESS:
      return (state = {
        ...state,
        resetPwd: {
          ...state.resetPwd,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? [],
        },
      });
    case RESET_PWD_FAILURE:
      return (state = {
        ...state,
        resetPwd: {
          ...state.resetPwd,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
        },
      });
    case SYNC_DATA_DOSEN_REQUEST:
      return (state = {
        ...state,
        isFetching: true,
        isLoading: true,
        msg: "SYNC_DATA_DOSEN_REQUEST",
      });
    case SYNC_DATA_DOSEN_SUCCESS:
      return (state = {
        ...state,
        isFetching: false,
        isLoading: false,
        msg: "SYNC_DATA_DOSEN_SUCCESS",
      });
    case SYNC_DATA_DOSEN_FAILURE:
      return (state = {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload.error,
        msg: "SYNC_DATA_DOSEN_FAILURE",
      });
    case DAFTAR_PROGRAM_STUDI_REQUEST:
      return (state = {
        ...state,
        daftarProdi: {
          ...state.daftarProdi,
          isFetching: true,
          isLoading: true,
        },
      });
    case DAFTAR_PROGRAM_STUDI_SUCCESS:
      return (state = {
        ...state,
        daftarProdi: {
          ...state.daftarProdi,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? [],
        },
      });
    case DAFTAR_PROGRAM_STUDI_FAILURE:
      return (state = {
        ...state,
        daftarProdi: {
          ...state.daftarProdi,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
          items: [],
        },
      });
    case SYNC_DATA_PRODI_REQUEST:
      return (state = {
        ...state,
        daftarProdi: {
          ...state.daftarProdi,
          isFetching: true,
          isLoading: true,
          msg: "SYNC_DATA_PRODI_REQUEST",
        },
      });
    case SYNC_DATA_PRODI_SUCCESS:
      return (state = {
        ...state,
        daftarProdi: {
          ...state.daftarProdi,
          isFetching: false,
          isLoading: false,
          msg: "SYNC_DATA_DOSEN_SUCCESS",
        },
      });
    case SYNC_DATA_PRODI_FAILURE:
      return (state = {
        ...state,
        daftarProdi: {
          ...state.daftarProdi,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
          msg: "SYNC_DATA_DOSEN_FAILURE",
        },
      });
    case SEARCH_PRODI_BY_KODE_REQUEST:
      return (state = {
        ...state,
        searchDaftarProdiByNidn: {
          ...state.searchDaftarProdiByNidn,
          isFetching: true,
          isLoading: true,
        },
      });
    case SEARCH_PRODI_BY_KODE_SUCCESS:
      return (state = {
        ...state,
        searchDaftarProdiByNidn: {
          ...state.searchDaftarProdiByNidn,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? [],
        },
      });
    case SEARCH_PRODI_BY_KODE_FAILURE:
      return (state = {
        ...state,
        searchDaftarProdiByNidn: {
          ...state.searchDaftarProdiByNidn,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
        },
      });
    case TAMBAH_DATA_DOSEN_REQUEST:
      return (state = {
        ...state,
        tambahDataDosen: {
          ...state.tambahDataDosen,
          isFetching: true,
          isLoading: true,
        },
      });
    case TAMBAH_DATA_DOSEN_SUCCESS:
      return (state = {
        ...state,
        tambahDataDosen: {
          ...state.tambahDataDosen,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? [],
        },
      });
    case TAMBAH_DATA_DOSEN_FAILURE:
      return (state = {
        ...state,
        tambahDataDosen: {
          ...state.tambahDataDosen,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
        },
      });

    case GET_STATUS_DOSEN_REQUEST:
      return (state = {
        ...state,
        getAllStatusDosen: {
          ...state.getAllStatusDosen,
          isFetching: true,
          isLoading: true,
          error: null,
          items: [],
        },
      });
    case GET_STATUS_DOSEN_SUCCESS:
      return (state = {
        ...state,
        getAllStatusDosen: {
          ...state.getAllStatusDosen,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? [],
          error: null,
        },
      });
    case GET_STATUS_DOSEN_FAILURE:
      return (state = {
        ...state,
        getAllStatusDosen: {
          ...state.getAllStatusDosen,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
          items: [],
        },
      });

    case PUT_STATUS_DOSEN_REQUEST:
      return (state = {
        ...state,
        putStatusDosen: {
          ...state.putStatusDosen,
          isFetching: true,
          isLoading: true,
          error: null,
          items: {},
        },
      });
    case PUT_STATUS_DOSEN_SUCCESS:
      return (state = {
        ...state,
        putStatusDosen: {
          ...state.putStatusDosen,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? {},
          error: null,
        },
      });
    case PUT_STATUS_DOSEN_FAILURE:
      return (state = {
        ...state,
        putStatusDosen: {
          ...state.putStatusDosen,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
          items: {},
        },
      });
    case PUT_INSTITUSI_EDIT_NAMA_JABATAN_REQUEST:
      return (state = {
        ...state,
        putInstitusiEditNamaJabatan: {
          ...state.putInstitusiEditNamaJabatan,
          isFetching: true,
          isLoading: true,
          error: null,
          items: {},
        },
      });
    case PUT_INSTITUSI_EDIT_NAMA_JABATAN_SUCCESS:
      return (state = {
        ...state,
        putInstitusiEditNamaJabatan: {
          ...state.putInstitusiEditNamaJabatan,
          isFetching: false,
          isLoading: false,
          items: action.payload ?? {},
          error: null,
        },
      });
    case PUT_INSTITUSI_EDIT_NAMA_JABATAN_FAILURE:
      return (state = {
        ...state,
        putInstitusiEditNamaJabatan: {
          ...state.putInstitusiEditNamaJabatan,
          isFetching: false,
          isLoading: false,
          error: action.payload.error,
          items: {},
        },
      });

    default:
      return state;
  }
};

export default ptDosenReducer;
