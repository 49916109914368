export const file_rules_mitra = [
  {
    label: "Surat Pernyataan Mitra Kerja sama",
    key_name_file: "name_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    key_url_file: "url_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    id_skema: [109],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kerjasama Mitra Sasaran__241d4fde.docx",
  },
  {
    label: "Dokumen Bukti Jumlah Keanggotaan Kelompok/Karyawan Mitra Sasaran",
    key_name_file:
      "name_file_mitra_sasaran_surat_pernyataan_jumlah_keanggotaan",
    key_url_file: "url_file_mitra_sasaran_surat_pernyataan_jumlah_keanggotaan",
    id_skema: [109],
    template_url: "",
  },
  {
    label:
      "Tangkapan Layar Google Maps yang Menggambarkan Jarak Perguruan Tinggi ke Lokasi Mitra Sasaran",
    key_name_file: "name_file_mitra_sasaran_tangkapan_layar_google_maps",
    key_url_file: "url_file_mitra_sasaran_tangkapan_layar_google_maps",
    id_skema: [109],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Contoh Tangkapan Layar Google Maps yang menggambarkan Jarak PT ke Lokasi Mitra Sasaran__a749c39d.docx",
  },
  {
    label: "Surat Pernyataan Mitra Kerja sama",
    key_name_file: "name_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    key_url_file: "url_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    id_skema: [111],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kerjasama Mitra Sasaran__241d4fde.docx",
  },
  {
    label: "Dokumen Bukti Jumlah Keanggotaan Kelompok/Karyawan Mitra Sasaran",
    key_name_file:
      "name_file_mitra_sasaran_surat_pernyataan_jumlah_keanggotaan",
    key_url_file: "url_file_mitra_sasaran_surat_pernyataan_jumlah_keanggotaan",
    id_skema: [111],
    template_url: "",
  },
  {
    label:
      "Tangkapan Layar Google Maps yang Menggambarkan Jarak Perguruan Tinggi ke Lokasi Mitra Sasaran",
    key_name_file: "name_file_mitra_sasaran_tangkapan_layar_google_maps",
    key_url_file: "url_file_mitra_sasaran_tangkapan_layar_google_maps",
    id_skema: [111],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Contoh Tangkapan Layar Google Maps yang menggambarkan Jarak PT ke Lokasi Mitra Sasaran__a749c39d.docx",
  },
  {
    label: "Surat Pernyataan Mitra Kerja sama",
    key_name_file: "name_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    key_url_file: "url_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    id_skema: [114],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kerjasama Mitra Sasaran__241d4fde.docx",
  },
  {
    label: "Bukti Pembentukan/Pendirian Kelompok Mitra Sasaran",
    key_name_file: "name_file_mitra_sasaran_bukti_pembentukan_kelompok",
    key_url_file: "url_file_mitra_sasaran_bukti_pembentukan_kelompok",
    id_skema: [114],
    template_url: "",
  },
  {
    label:
      "Surat pernyataan kepemilikan aset dan omset yang disertakan dengan bukti kepemilikan aset dan omset",
    key_name_file:
      "name_file_mitra_sasaran_surat_pernyataan_kepemilikan_aset_dan_omset",
    key_url_file:
      "url_file_mitra_sasaran_surat_pernyataan_kepemilikan_aset_dan_omset",
    id_skema: [114],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Pernyataan Kepemilikan Aset dan Omset Disertakan Bukti__fb82d0a0.docx",
  },
  {
    label:
      "Surat Pernyataan Kesediaan Sharing dana padaanan minimal Rp 10.000.000,00 setiap tahunnya",
    key_name_file: "name_file_mitra_sasaran_surat_pernyataan_sharing_dana",
    key_url_file: "url_file_mitra_sasaran_surat_pernyataan_sharing_dana",
    id_skema: [114],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kesediaan Sharing Dana Padanaan Setiap Tahunnya__ba1f853f.docx",
  },
  {
    label:
      "Tangkapan Layar Google Maps yang Menggambarkan Jarak Perguruan Tinggi ke Lokasi Mitra Sasaran",
    key_name_file: "name_file_mitra_sasaran_tangkapan_layar_google_maps",
    key_url_file: "url_file_mitra_sasaran_tangkapan_layar_google_maps",
    id_skema: [114],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Contoh Tangkapan Layar Google Maps yang menggambarkan Jarak PT ke Lokasi Mitra Sasaran__a749c39d.docx",
  },
  {
    label: "Surat Pernyataan Mitra Kerja sama",
    key_name_file: "name_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    key_url_file: "url_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    id_skema: [116],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kerjasama Mitra Sasaran__241d4fde.docx",
  },
  {
    label: "Dokumen Bukti Jumlah Keanggotaan Kelompok",
    key_name_file: "name_file_mitra_sasaran_dokumen_jumlah_keanggotaan",
    key_url_file: "url_file_mitra_sasaran_dokumen_jumlah_keanggotaan",
    id_skema: [116],
    template_url: "",
  },
  {
    label: "Bukti Pembentukan/Pendirian Kelompok Mitra Sasaran",
    key_name_file: "name_file_mitra_sasaran_bukti_pembentukan_kelompok",
    key_url_file: "url_file_mitra_sasaran_bukti_pembentukan_kelompok",
    id_skema: [116],
    template_url: "",
  },
  {
    label:
      "Tangkapan Layar Google Maps yang Menggambarkan Jarak Perguruan Tinggi ke Lokasi Mitra Sasaran",
    key_name_file: "name_file_mitra_sasaran_tangkapan_layar_google_maps",
    key_url_file: "url_file_mitra_sasaran_tangkapan_layar_google_maps",
    id_skema: [116],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Contoh Tangkapan Layar Google Maps yang menggambarkan Jarak PT ke Lokasi Mitra Sasaran__a749c39d.docx",
  },
  {
    label: "Surat Pernyataan Mitra Kerja sama",
    key_name_file: "name_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    key_url_file: "url_file_mitra_sasaran_surat_pernyataan_mitra_kerja_sama",
    id_skema: [117],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kerjasama Mitra Sasaran__241d4fde.docx",
  },
  {
    label: "Dokumen Bukti Jumlah Keanggotaan Kelompok",
    key_name_file: "name_file_mitra_sasaran_dokumen_jumlah_keanggotaan",
    key_url_file: "url_file_mitra_sasaran_dokumen_jumlah_keanggotaan",
    id_skema: [117],
    template_url: "",
  },
  {
    label: "Bukti Pembentukan/Pendirian Kelompok Mitra Sasaran",
    key_name_file: "name_file_mitra_sasaran_bukti_pembentukan_kelompok",
    key_url_file: "url_file_mitra_sasaran_bukti_pembentukan_kelompok",
    id_skema: [117],
    template_url: "",
  },
  {
    label:
      "Tangkapan Layar Google Maps yang Menggambarkan Jarak Perguruan Tinggi ke Lokasi Mitra Sasaran",
    key_name_file: "name_file_mitra_sasaran_tangkapan_layar_google_maps",
    key_url_file: "url_file_mitra_sasaran_tangkapan_layar_google_maps",
    id_skema: [117],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Contoh Tangkapan Layar Google Maps yang menggambarkan Jarak PT ke Lokasi Mitra Sasaran__a749c39d.docx",
  },
  // perguruan tinggi
  {
    label:
      "Surat Pernyataan Kerjasama Perguruan Tinggi dan pernyataan bahwa hanya ada satu usulan pada setiap desa",
    key_name_file:
      "name_file_mitra_perguruan_tinggi_surat_pernyataan_kerjasama_perguruan_tinggi_dan_desa",
    key_url_file:
      "url_file_mitra_perguruan_tinggi_surat_pernyataan_kerjasama_perguruan_tinggi_dan_desa",
    id_skema: [112],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kerjasama Mitra Perguruan Tinggi dan Pernyataan Bahwa hanya ada Satu Usulan pada Setiap Desa__c22a5606.docx",
  },
  {
    label:
      "Surat pernyataan Mitra Perguruan Tinggi bahwa mitra pemerintah adalah science techno park / Desa Binaan perguruan tinggi dan hanya diusulkan oleh satu pelaksana",
    key_name_file:
      "name_file_mitra_perguruan_tinggi_surat_pernyataan_kerjasama_perguruan_tinggi_science",
    key_url_file:
      "url_file_mitra_perguruan_tinggi_surat_pernyataan_kerjasama_perguruan_tinggi_science",
    id_skema: [117],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Pernyataan Mitra Perguruan Tinggi bahwa Mitra Pemerintah adalah Science Techno Park Desa Binaan Perguruan Tinggi__92513e67.docx",
  },
  {
    label: "Surat Pernyataan Kerjasama Perguruan Tinggi",
    key_name_file:
      "name_file_mitra_perguruan_tinggi_surat_pernyataan_kerjasama_perguruan_tinggi",
    key_url_file:
      "url_file_mitra_perguruan_tinggi_surat_pernyataan_kerjasama_perguruan_tinggi",
    id_skema: [117],
    template_url: "",
  },
  {
    label:
      "Surat pernyataan kerjasama dan kesediaan sharing dana Rp 15.000.000/tahun",
    key_name_file:
      "name_file_mitra_perguruan_tinggi_surat_pernyataan_mitra_kerja_sama_dan_kesediaan",
    key_url_file:
      "url_file_mitra_perguruan_tinggi_surat_pernyataan_mitra_kerja_sama_dan_kesediaan",
    id_skema: [117],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kesediaan Sharing Dana Padanaan Setiap Tahunnya__f7cf3797.docx",
  },
  {
    label: "Surat Rekomendasi Usulan PUK dari Ketua LPPM",
    key_name_file: "name_file_mitra_perguruan_tinggi_surat_rekomendasi_usulan",
    key_url_file: "url_file_mitra_perguruan_tinggi_surat_rekomendasi_usulan",
    id_skema: [115],
    template_url: "",
  },
  {
    label:
      "SK Rektor yang berlaku tentang perguruan tinggi homebase ketua pelaksana menyelenggaran program KKN",
    key_name_file:
      "name_file_mitra_perguruan_tinggi_sk_rektor_penyelenggaraan_program_kkn",
    key_url_file:
      "url_file_mitra_perguruan_tinggi_sk_rektor_penyelenggaraan_program_kkn",
    id_skema: [112],
    template_url: "",
  },
  {
    label:
      "SK Rektor yang berlaku/Surat Ketetapan dosen pembimbing lapangan kuliah kerja nyata yang ditandatangani oleh pejabat yang berwenang",
    key_name_file: "name_file_mitra_perguruan_tinggi_sk_rektor_yg_berlaku",
    key_url_file: "url_file_mitra_perguruan_tinggi_sk_rektor_yg_berlaku",
    id_skema: [112],
    template_url: "",
  },
  // pemerintah
  {
    label: "Surat Pernyataan Kerjasama Mitra Pemerintah",
    key_name_file:
      "name_file_mitra_pemerintah_surat_kerjasama_mitra_pemerintah",
    key_url_file: "url_file_mitra_pemerintah_surat_kerjasama_mitra_pemerintah",
    id_skema: [114, 116, 117],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kerjasama Mitra Pemerintah dan Izin Pelaksanaan Program__8194f43a.docx",
  },
  {
    label:
      "Surat Pernyataan permasalahan yang diangkat sesuai arah pemgembangan desa",
    key_name_file:
      "name_file_mitra_pemerintah_surat_pernyataan_yg_diangkat_sesuai_arah_pengembang_desa",
    key_url_file:
      "url_file_mitra_pemerintah_surat_pernyataan_yg_diangkat_sesuai_arah_pengembang_desa",
    id_skema: [117],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Surat Pernyataan Permasalahan yang Diangkat Sesuai Arah Pengembangan Desa__57e313ef.docx",
  },
  {
    label:
      "Surat Pernyataan Desa/Organisasi Perangkat Daerah (OPD) terkait Unit Usaha mengelola produk unggulan daerah",
    key_name_file: "name_file_mitra_pemerintah_surat_pernyataan_desa",
    key_url_file: "url_file_mitra_pemerintah_surat_pernyataan_desa",
    id_skema: [114],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Pernyataan Desa-Organisasi Perangkat Daerah (OPD) terkait Unit Usaha mengelola produk unggulan daerah disertakan RPJMD-RPJMP__afbc07af.docx",
  },
  {
    label: "Surat Pernyataan Permasalahan Wilayah",
    key_name_file: "name_file_mitra_pemerintah_surat_pernyataan_wilayah",
    key_url_file: "url_file_mitra_pemerintah_surat_pernyataan_wilayah",
    id_skema: [116],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Pernyataan Permasalahan Wilayah__78b9d46b.docx",
  },
  {
    label: "Bukti Dokumen RPJMD/RPJMP",
    key_name_file: "name_file_mitra_pemerintah_bukti_rpjmd",
    key_url_file: "url_file_mitra_pemerintah_bukti_rpjmd",
    id_skema: [114, 116],
    template_url: "",
  },
  {
    label:
      "Surat Pernyataan Kerjasama Mitra Pemerintah dan izin pelaksanaan program KKN dan PMM",
    key_name_file:
      "name_file_mitra_pemerintah_surat_pernyataan_mitra_kerja_sama",
    key_url_file: "url_file_mitra_pemerintah_surat_pernyataan_mitra_kerja_sama",
    id_skema: [112],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Kerjasama Mitra Sasaran__241d4fde.docx",
  },
  {
    label:
      "Dokumen Bukti Jumlah Keanggotaan Kelompok Masyarakat yang menjadi Sasaran",
    key_name_file:
      "name_file_mitra_pemerintah_surat_pernyataan_jumlah_keanggotaan",
    key_url_file:
      "url_file_mitra_pemerintah_surat_pernyataan_jumlah_keanggotaan",
    id_skema: [112],
    template_url: "",
  },
  {
    label:
      "Surat Pernyataan Kedua Kelompok yang menjadi Mitra Sasaran Searah dengan Pengembangan Desa dan Program KKN",
    key_name_file:
      "name_file_mitra_pemerintah_surat_pernyataan_kelompok_sesuai_arah_pengembangan_desa_kkn",
    key_url_file:
      "url_file_mitra_pemerintah_surat_pernyataan_kelompok_sesuai_arah_pengembangan_desa_kkn",
    id_skema: [112],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Pernyataan Kelompok Sesuai Dengan Arah Pengembangan Desa dan Program KKN__a2d50cd5.docx",
  },
  {
    label:
      "Tangkapan Layar Google Maps yang Menggambarkan Jarak Perguruan Tinggi ke Lokasi Mitra",
    key_name_file: "name_file_mitra_pemerintah_tangkapan_layar_google_maps",
    key_url_file: "url_file_mitra_pemerintah_tangkapan_layar_google_maps",
    id_skema: [112],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Contoh Tangkapan Layar Google Maps yang menggambarkan Jarak PT ke Lokasi Mitra Sasaran__a749c39d.docx",
  },
  {
    label: "Surat Pernyataan kesediaan sharing dana Rp 100.000.000/tahun",
    key_name_file: "name_file_mitra_pemerintah_sharing_dana",
    key_url_file: "url_file_mitra_pemerintah_sharing_dana",
    id_skema: [116],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/commonFormat Surat Kesediaan Sharing Dana Padanaan Setiap Tahunnya__7f6f8ab7.docx",
  },
  // DUDI
  {
    label: "Surat Pernyataan Mitra Kerja sama",
    key_name_file:
      "name_file_mitra_perguruan_tinggi_surat_pernyataan_mitra_kerja_sama",
    key_url_file:
      "url_file_mitra_perguruan_tinggi_surat_pernyataan_mitra_kerja_sama",
    id_skema: [],
    template_url:
      "https://storage.googleapis.com/sipp-be-files/document/common/Format Surat Pernyataan Kerjasama Mitra DuDi__04ff9124.docx",
  },
];
