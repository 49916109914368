/* eslint-disable no-unused-vars */
import utilities from "helpers/utilities";
import { instances, instancesV2 } from "../api_helper";
import * as url from "../url_helper";
import encStorage from "helpers/encStorage";
import { decode } from "helpers/jwt";
const storage = JSON.parse(encStorage.getItem("authUser"));

export const getSummaryUsulanGroupBySkema = (payload) => {
  const params = utilities.parsePayloadToParamsStr(payload);
  let URL = `${url.ENDPOINT_SUMMARY_USULAN_GORUP_SKEMA}`;

  return instancesV2.request(URL+params, {
    method: "GET",
  });
}

export const getAllUsulan = async ({
  tahun,
  tahun_usulan,
  kd_program_hibah,
  id_institusi,
  kd_jenis_kegiatan,
  usulan_baru,
  usulan_dikirim,
  usulan_ditolak,
  usulan_disetujui,
}) => {
  let response;
  let error = false;
  // for opt pusat, lldikti, (yg ngirim id_institusi dari dropdwon)
  if (id_institusi) {
    response = await Promise.all([
      // endpoint 1 usulan ditinjau
      instances.request(
        `${url.ENDPOINT_USULAN_DITINJAU}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
      // endpoint 2 usulan_baru
      instances.request(
        `${url.EDNPOINT_USULAN_BARU_DAN_DIKIRIM}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/${usulan_baru}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
      // endpoint 3 usulan_dikirim
      instances.request(
        `${url.EDNPOINT_USULAN_BARU_DAN_DIKIRIM}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/${usulan_dikirim}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
      // endpoint 4 usulan_ditolak 0
      instances.request(
        `${url.EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/${usulan_ditolak}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
      // endpoint 5 usulan_disetujui 1
      instances.request(
        `${url.EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/${usulan_disetujui}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
    ]).catch(err => {
      if (err) {
        error = true;
      }
    });
  } else {
    const jwtResult = decode(storage?.token);
    response = await Promise.all([
      // endpoint 1 usulan ditinjau
      instances.request(
        `${url.ENDPOINT_USULAN_DITINJAU}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
      // endpoint 2 usulan_baru
      instances.request(
        `${url.EDNPOINT_USULAN_BARU_DAN_DIKIRIM}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/${usulan_baru}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
      // endpoint 3 usulan_dikirim
      instances.request(
        `${url.EDNPOINT_USULAN_BARU_DAN_DIKIRIM}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/${usulan_dikirim}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
      // endpoint 4 usulan_ditolak 0
      instances.request(
        `${url.EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/${usulan_ditolak}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
      // endpoint 5 usulan_disetujui 1
      instances.request(
        `${url.EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/${usulan_disetujui}/${tahun_usulan}`,
        {
          method: "GET",
        }
      ),
    ]).catch(err => {
      if (err) {
        error = true;
      }
    });
  }
  if (error) {
    return {
      http_error: true,
      usulanDitinjau: [],
      usulanBaru: [],
      usulanDikirim: [],
      usulanDitolak: [],
      usulanDisetujui: [],
    };
  } else {
    return {
      http_error: false,
      usulanDitinjau: response[0].data.data || [],
      usulanBaru: response[1].data.data || [],
      usulanDikirim: response[2].data.data || [],
      usulanDitolak: response[3].data.data || [],
      usulanDisetujui: response[4].data.data || [],
    };
  }
};

// /hibah/usulan/dashboard-usulan-status-approval/2018/2/3fea100d-fc25-41a9-b799-ed9aab32a65c/1/1
// /hibah/usulan/dashboard-usulan-ditinjau/2018/2/3fea100d-fc25-41a9-b799-ed9aab32a65c/1
// /hibah/usulan/dashboard-usulan/2016/2/3fea100d-fc25-41a9-b799-ed9aab32a65c/1/0



// beda di payload kd_sts_approvel = 1 (ditolak) dan kd_sts_usulan = 0 (ditolak)
const approvalUsulan = payload => {
  const dataReject = {
    alasan_penolakan: payload.alasan_penolakan,
    // id_hasil_approvel_usulan: payload.id_hasil_approvel_usulan,
    id_personal: payload.id_personal,
    id_transaksi_kegiatan: payload.id_transaksi_kegiatan,
    kd_sts_approvel: payload.kd_sts_approvel,
    kd_sts_permanen: payload.kd_sts_permanen,
    komentar: payload.komentar,
    tempat: payload.tempat,
  };

  const dataApprove = {
    alasan_penolakan: payload.alasan_penolakan,
    // id_hasil_approvel_usulan: payload.id_hasil_approvel_usulan,
    id_personal: payload.id_personal,
    id_transaksi_kegiatan: payload.id_transaksi_kegiatan,
    kd_sts_approvel: payload.kd_sts_approvel,
    kd_sts_permanen: payload.kd_sts_permanen,
    komentar: payload.komentar,
    tempat: payload.tempat,
  };

  if (payload.kd_sts_approvel === 1 || payload.kd_sts_approvel === "1") {
    return instances.request(`${url.EDNPOINT_SETUJUI_ATAU_TOLAK_USULAN}`, {
      method: "POST",
      data: dataApprove,
    });
  } else {
    return instances.request(`${url.EDNPOINT_SETUJUI_ATAU_TOLAK_USULAN}`, {
      method: "POST",
      data: dataReject,
    });
  }
};

// Batalkan Usulan ( Batalkan Usulan Disetujui / Batalkan Usulan Tidak Disetujui ) posisinya ada di halaman usulan disetujui dan usulan tidak disetujui
const batalkanUsulan = singleData => {
  const payload = {
    id: singleData.id_usulan_kegiatan,
  };
  return instances.request(`${url.EDNPOINT_BATALKAN_USULAN}`, {
    method: "POST",
    data: payload,
  });
};

const getUsulanDisetujui = ({
  tahun,
  tahun_usulan,
  kd_program_hibah,
  id_institusi,
  kd_jenis_kegiatan,
}) => {
  if (id_institusi) {
    return instances.request(
      `${url.EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/1/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/1/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  }
};

const UsulanTidakDisetujui = ({
  tahun,
  tahun_usulan,
  kd_program_hibah,
  id_institusi,
  kd_jenis_kegiatan,
}) => {
  if (id_institusi) {
    return instances.request(
      `${url.EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/0/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/0/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  }
};

const getUsulanBaru = ({
  tahun,
  tahun_usulan,
  kd_program_hibah,
  id_institusi,
  kd_jenis_kegiatan,
}) => {
  if (id_institusi) {
    return instances.request(
      `${url.EDNPOINT_USULAN_BARU_DAN_DIKIRIM}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/0/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.EDNPOINT_USULAN_BARU_DAN_DIKIRIM}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/0/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  }
};

const getUsulanDikirim = ({
  tahun,
  tahun_usulan,
  kd_program_hibah,
  id_institusi,
  kd_jenis_kegiatan,
}) => {
  if (id_institusi) {
    return instances.request(
      `${url.EDNPOINT_USULAN_BARU_DAN_DIKIRIM}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/1/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.EDNPOINT_USULAN_BARU_DAN_DIKIRIM}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/1/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  }
};

const getUsulanDitinjau = ({
  tahun,
  tahun_usulan,
  kd_program_hibah,
  id_institusi,
  kd_jenis_kegiatan,
}) => {
  if (id_institusi) {
    return instances.request(
      `${url.ENDPOINT_USULAN_DITINJAU}/${tahun}/${kd_program_hibah}/${id_institusi}/${kd_jenis_kegiatan}/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  } else {
    const jwtResult = decode(storage?.token);
    return instances.request(
      `${url.ENDPOINT_USULAN_DITINJAU}/${tahun}/${kd_program_hibah}/${jwtResult.id_institusi}/${kd_jenis_kegiatan}/${tahun_usulan}`,
      {
        method: "GET",
      }
    );
  }
};

// /api/v1/referensi/alasan-penolakan-approval
const alasanPenolakan = () => {
  return instances.request(`${url.ENDPOINT_ALASAN_PENOLAKAN}`, {
    method: "GET",
  });
};

export {
  alasanPenolakan,
  approvalUsulan,
  batalkanUsulan,
  getUsulanDisetujui,
  getUsulanBaru,
  getUsulanDikirim,
  UsulanTidakDisetujui,
  getUsulanDitinjau,
};
