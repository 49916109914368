import { instancesV2 } from "../api_helper";
import * as url from "../url_helper";

export const getBimtekProposal = ({
   id,
   params = {}
}) => {
   let URL = url.ENDPOINT_BIMTEK_PROPOSAL;
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
      URL += queryParams;
   }

   if (id) {
      URL += `/${id}`;
   }

   return instancesV2.request({
      url: URL,
      method: "GET"
   });
}

export const updateBimtekProposal = ({
   id,
   payload = {}
}) => {
   let URL = url.ENDPOINT_BIMTEK_PROPOSAL+"/"+id;

   return instancesV2.request({
      url: URL,
      data : payload,
      method: "PUT"
   });
}