import {
  CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE,
  CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
  CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS,
  CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE,
  CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
  CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS,
  GET_LIST_KLASTER_PT_FAILURE,
  GET_LIST_KLASTER_PT_REQUEST,
  GET_LIST_KLASTER_PT_SUCCESS,
  OPERATOR_PT_DASHBOARD_TKT_FAILURE,
  OPERATOR_PT_DASHBOARD_TKT_REQUEST,
  OPERATOR_PT_DASHBOARD_TKT_SUCCESS,
  PROCESS_PENDAFTARAN_AKUN_PT_FAILURE,
  PROCESS_PENDAFTARAN_AKUN_PT_REQUEST,
  PROCESS_PENDAFTARAN_AKUN_PT_SUCCESS,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  errors: null,
  dataOperatorPtTkt: [],
  pendaftaranAkunOperatorPt: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  pendaftaranAkunOperatorPtCheckAkun: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  klasterPt: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  processPendaftaranAkunPt: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
};

const operatorPtDashboard = (state = initialState, action) => {
  switch (action.type) {
    // * data Operator Pt
    case OPERATOR_PT_DASHBOARD_TKT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case OPERATOR_PT_DASHBOARD_TKT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataOperatorPtTkt: action.payload,
      };
    case OPERATOR_PT_DASHBOARD_TKT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    //
    case CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST:
      return (state = {
        ...state,
        pendaftaranAkunOperatorPt: {
          ...state.pendaftaranAkunOperatorPt,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS:
      return (state = {
        ...state,
        pendaftaranAkunOperatorPt: {
          ...state.pendaftaranAkunOperatorPt,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE:
      return (state = {
        ...state,
        pendaftaranAkunOperatorPt: {
          ...state.pendaftaranAkunOperatorPt,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //
    case CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST:
      return (state = {
        ...state,
        pendaftaranAkunOperatorPtCheckAkun: {
          ...state.pendaftaranAkunOperatorPtCheckAkun,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS:
      return (state = {
        ...state,
        pendaftaranAkunOperatorPtCheckAkun: {
          ...state.pendaftaranAkunOperatorPtCheckAkun,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE:
      return (state = {
        ...state,
        pendaftaranAkunOperatorPtCheckAkun: {
          ...state.pendaftaranAkunOperatorPtCheckAkun,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //
    case GET_LIST_KLASTER_PT_REQUEST:
      return (state = {
        ...state,
        klasterPt: {
          ...state.klasterPt,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_KLASTER_PT_SUCCESS:
      return (state = {
        ...state,
        klasterPt: {
          ...state.klasterPt,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case GET_LIST_KLASTER_PT_FAILURE:
      return (state = {
        ...state,
        klasterPt: {
          ...state.klasterPt,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //
    case PROCESS_PENDAFTARAN_AKUN_PT_REQUEST:
      return (state = {
        ...state,
        processPendaftaranAkunPt: {
          ...state.processPendaftaranAkunPt,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case PROCESS_PENDAFTARAN_AKUN_PT_SUCCESS:
      return (state = {
        ...state,
        processPendaftaranAkunPt: {
          ...state.processPendaftaranAkunPt,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case PROCESS_PENDAFTARAN_AKUN_PT_FAILURE:
      return (state = {
        ...state,
        processPendaftaranAkunPt: {
          ...state.processPendaftaranAkunPt,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    default:
      return state;
  }
};

export default operatorPtDashboard;
