import {
  LIST_DAFTAR_REVIEWER_FAILURE,
  LIST_DAFTAR_REVIEWER_REQUEST,
  LIST_DAFTAR_REVIEWER_SUCCESS,
  PUT_STATUS_DAFTAR_REVIEWER_FAILURE,
  PUT_STATUS_DAFTAR_REVIEWER_REQUEST,
  PUT_STATUS_DAFTAR_REVIEWER_SUCCESS,
  GET_REVIEWER_BY_NAME_FAILURE,
  GET_REVIEWER_BY_NAME_REQUEST,
  GET_REVIEWER_BY_NAME_SUCCESS,
  POST_DAFTAR_REVIEWER_REQUEST,
  POST_DAFTAR_REVIEWER_SUCCESS,
  POST_DAFTAR_REVIEWER_FAILURE,
  GET_DROPDOWN_TAHAPAN_FAILURE,
  GET_DROPDOWN_TAHAPAN_REQUEST,
  GET_DROPDOWN_TAHAPAN_SUCCESS,
  GET_DROPDOWN_TAHUN_KEGIATAN_FAILURE,
  GET_DROPDOWN_TAHUN_KEGIATAN_REQUEST,
  GET_DROPDOWN_TAHUN_KEGIATAN_SUCCESS,
  GET_SKEMA_REVIEWER_REQUEST,
  GET_SKEMA_REVIEWER_SUCCESS,
  GET_SKEMA_REVIEWER_FAILURE,
  GET_SKEMA_REVIEWER_BY_ID_REQUEST,
  GET_SKEMA_REVIEWER_BY_ID_SUCCESS,
  GET_SKEMA_REVIEWER_BY_ID_FAILURE,
  SEARCH_REVIEWER_PT_REQUEST,
  SEARCH_REVIEWER_PT_SUCCESS,
  SEARCH_REVIEWER_PT_FAILURE,
  SEARCH_REVIEWER_PT2_REQUEST,
  SEARCH_REVIEWER_PT2_SUCCESS,
  SEARCH_REVIEWER_PT2_FAILURE,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_REQUEST,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_SUCCESS,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_FAILURE,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_REQUEST,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_SUCCESS,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_FAILURE,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_REQUEST,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_SUCCESS,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_FAILURE,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_REQUEST,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_SUCCESS,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_FAILURE,
  POST_PLOTTING_REVIEWER_PENELITIAN_REQUEST,
  POST_PLOTTING_REVIEWER_PENELITIAN_SUCCESS,
  POST_PLOTTING_REVIEWER_PENELITIAN_FAILURE,
  GET_LIST_REKAP_PLOTTING_REVIEWER_V2_REQUEST,
  GET_LIST_REKAP_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_LIST_REKAP_PLOTTING_REVIEWER_V2_FAILURE,
  GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
  GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_FAILURE,
  RESET_LIST_REKAP_PLOTTING_REVIEWER_V2,
  GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_REQUEST,
  GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_FAILURE,
  CREATE_PLOTTING_PLOTTING_REVIEWER_V2_REQUEST,
  CREATE_PLOTTING_PLOTTING_REVIEWER_V2_SUCCESS,
  CREATE_PLOTTING_PLOTTING_REVIEWER_V2_FAILURE,
  GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_REQUEST,
  GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_FAILURE,
  GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
  GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  errors: null,
  listDaftarReviewer: [],
  statusReviewer: "",
  responseCreateReviewer: {},
  dropdownTahapan: [],
  dropdownTahunKegiatan: [],
  skemaReviewer: {
    data: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  skemaReviewerById: {
    data: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  searchReviewerPt1Reducer: {
    data: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  searchReviewerPt2Reducer: {
    data: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  ReviewerPenelitianSatu: {
    data: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  ReviewerPenelitianDua: {
    data: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  ReviewerPengabdianSatu: {
    data: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  ReviewerPengabdianDua: {
    data: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },

  PlottingReviewerPenelitianReducer: {},
  listRekapPlottingReviewerV2: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  plottingDetailPlottingReviewerV2: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  detailReviewerPlottingReviewerV2: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  createPlottingPlottingReviewerV2: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  monitoring_penilaian: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  monitoring_penilaian_detail: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
};

const operatorPengelolaanReviewer = (state = initialState, action) => {
  switch (action.type) {
    // * Pengelolaan reviewer list daftar reviewer
    case LIST_DAFTAR_REVIEWER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LIST_DAFTAR_REVIEWER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listDaftarReviewer: action.payload,
      };
    case LIST_DAFTAR_REVIEWER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case GET_REVIEWER_BY_NAME_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_REVIEWER_BY_NAME_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listDaftarReviewer: action.payload,
      };
    case GET_REVIEWER_BY_NAME_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        listDaftarReviewer: [],
      };
    case PUT_STATUS_DAFTAR_REVIEWER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PUT_STATUS_DAFTAR_REVIEWER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        statusReviewer: action.payload,
      };
    case PUT_STATUS_DAFTAR_REVIEWER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case POST_DAFTAR_REVIEWER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case POST_DAFTAR_REVIEWER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        responseCreateReviewer: action.payload,
      };
    case POST_DAFTAR_REVIEWER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case GET_DROPDOWN_TAHAPAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        dropdownTahapan: [],
      };
    case GET_DROPDOWN_TAHAPAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dropdownTahapan: action.payload || [],
      };
    case GET_DROPDOWN_TAHAPAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        dropdownTahapan: [],
      };
    case GET_DROPDOWN_TAHUN_KEGIATAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        dropdownTahunKegiatan: [],
      };
    case GET_DROPDOWN_TAHUN_KEGIATAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dropdownTahunKegiatan: action.payload ?? [],
      };
    case GET_DROPDOWN_TAHUN_KEGIATAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        dropdownTahunKegiatan: [],
      };
    case GET_SKEMA_REVIEWER_REQUEST:
      return {
        ...state,
        skemaReviewer: {
          isFetching: true,
          data: [],
        },
      };
    case GET_SKEMA_REVIEWER_SUCCESS:
      return {
        ...state,
        skemaReviewer: {
          isFetching: false,
          data: action.payload ?? [],
        },
      };
    case GET_SKEMA_REVIEWER_FAILURE:
      return {
        ...state,
        skemaReviewer: {
          isFetching: false,
          data: [],
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      };
    case GET_SKEMA_REVIEWER_BY_ID_REQUEST:
      return {
        ...state,
        skemaReviewerById: {
          isFetching: true,
          data: [],
        },
      };
    case GET_SKEMA_REVIEWER_BY_ID_SUCCESS:
      return {
        ...state,
        skemaReviewerById: {
          isFetching: false,
          data: action.payload ?? [],
        },
      };
    case GET_SKEMA_REVIEWER_BY_ID_FAILURE:
      return {
        ...state,
        skemaReviewerById: {
          isFetching: false,
          data: [],
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      };

    case SEARCH_REVIEWER_PT_REQUEST:
      return {
        ...state,
        isFetching: true,
        searchReviewerPt1Reducer: {
          isFetching: true,
          data: [],
        },
      };
    case SEARCH_REVIEWER_PT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        searchReviewerPt1Reducer: {
          isFetching: false,
          data: action.payload ?? [],
        },
      };
    case SEARCH_REVIEWER_PT_FAILURE:
      return {
        ...state,
        isFetching: false,
        searchReviewerPt1Reducer: {
          isFetching: false,
          data: [],
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      };
    case SEARCH_REVIEWER_PT2_REQUEST:
      return {
        ...state,
        isFetching: true,
        searchReviewerPt2Reducer: {
          isFetching: true,
          data: [],
        },
      };
    case SEARCH_REVIEWER_PT2_SUCCESS:
      return {
        ...state,
        isFetching: false,
        searchReviewerPt2Reducer: {
          isFetching: false,
          data: action.payload ?? [],
        },
      };
    case SEARCH_REVIEWER_PT2_FAILURE:
      return {
        ...state,
        isFetching: false,
        searchReviewerPt2Reducer: {
          isFetching: false,
          data: [],
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      };

    case SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_REQUEST:
      return {
        ...state,
        isFetching: true,
        ReviewerPenelitianSatu: {
          isFetching: true,
          data: [],
        },
      };
    case SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ReviewerPenelitianSatu: {
          isFetching: false,
          data: action.payload ?? [],
        },
      };
    case SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        ReviewerPenelitianSatu: {
          isFetching: false,
          data: [],
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      };
    case SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_REQUEST:
      return {
        ...state,
        isFetching: true,
        ReviewerPenelitianDua: {
          isFetching: true,
          data: [],
        },
      };
    case SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ReviewerPenelitianDua: {
          isFetching: false,
          data: action.payload ?? [],
        },
      };
    case SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        ReviewerPenelitianDua: {
          isFetching: false,
          data: [],
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      };

    case SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_REQUEST:
      return {
        ...state,
        isFetching: true,
        ReviewerPengabdianSatu: {
          isFetching: true,
          data: [],
        },
      };
    case SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ReviewerPengabdianSatu: {
          isFetching: false,
          data: action.payload ?? [],
        },
      };
    case SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        ReviewerPengabdianSatu: {
          isFetching: false,
          data: [],
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      };

    case SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_REQUEST:
      return {
        ...state,
        isFetching: true,
        ReviewerPengabdianDua: {
          isFetching: true,
          data: [],
        },
      };
    case SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ReviewerPengabdianDua: {
          isFetching: false,
          data: action.payload ?? [],
        },
      };
    case SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        ReviewerPengabdianDua: {
          isFetching: false,
          data: [],
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      };

    case POST_PLOTTING_REVIEWER_PENELITIAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        PlottingReviewerPenelitianReducer: {},
      };
    case POST_PLOTTING_REVIEWER_PENELITIAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        PlottingReviewerPenelitianReducer: action.payload ?? {},
      };
    case POST_PLOTTING_REVIEWER_PENELITIAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        PlottingReviewerPenelitianReducer: {},
      };
    // plotting reviewer v2
    case GET_LIST_REKAP_PLOTTING_REVIEWER_V2_REQUEST:
      return (state = {
        ...state,
        listRekapPlottingReviewerV2: {
          ...state.listRekapPlottingReviewerV2,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_REKAP_PLOTTING_REVIEWER_V2_SUCCESS:
      return (state = {
        ...state,
        listRekapPlottingReviewerV2: {
          ...state.listRekapPlottingReviewerV2,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case GET_LIST_REKAP_PLOTTING_REVIEWER_V2_FAILURE:
      return (state = {
        ...state,
        listRekapPlottingReviewerV2: {
          ...state.listRekapPlottingReviewerV2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case RESET_LIST_REKAP_PLOTTING_REVIEWER_V2:
      return (state = {
        ...state,
        listRekapPlottingReviewerV2: {
          ...state.listRekapPlottingReviewerV2,
          item: {},
          items: [],
        },
      });
    //
    case GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_REQUEST:
      return (state = {
        ...state,
        plottingDetailPlottingReviewerV2: {
          ...state.plottingDetailPlottingReviewerV2,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS:
      return (state = {
        ...state,
        plottingDetailPlottingReviewerV2: {
          ...state.plottingDetailPlottingReviewerV2,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_FAILURE:
      return (state = {
        ...state,
        plottingDetailPlottingReviewerV2: {
          ...state.plottingDetailPlottingReviewerV2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //
    case GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_REQUEST:
      return (state = {
        ...state,
        detailReviewerPlottingReviewerV2: {
          ...state.detailReviewerPlottingReviewerV2,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_SUCCESS:
      return (state = {
        ...state,
        detailReviewerPlottingReviewerV2: {
          ...state.detailReviewerPlottingReviewerV2,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_FAILURE:
      return (state = {
        ...state,
        detailReviewerPlottingReviewerV2: {
          ...state.detailReviewerPlottingReviewerV2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //
    case CREATE_PLOTTING_PLOTTING_REVIEWER_V2_REQUEST:
      return (state = {
        ...state,
        createPlottingPlottingReviewerV2: {
          ...state.createPlottingPlottingReviewerV2,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case CREATE_PLOTTING_PLOTTING_REVIEWER_V2_SUCCESS:
      return (state = {
        ...state,
        createPlottingPlottingReviewerV2: {
          ...state.createPlottingPlottingReviewerV2,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case CREATE_PLOTTING_PLOTTING_REVIEWER_V2_FAILURE:
      return (state = {
        ...state,
        createPlottingPlottingReviewerV2: {
          ...state.createPlottingPlottingReviewerV2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //
    case GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_REQUEST:
      return (state = {
        ...state,
        monitoring_penilaian: {
          ...state.monitoring_penilaian,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_SUCCESS:
      return (state = {
        ...state,
        monitoring_penilaian: {
          ...state.monitoring_penilaian,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_FAILURE:
      return (state = {
        ...state,
        monitoring_penilaian: {
          ...state.monitoring_penilaian,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //
    case GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_REQUEST:
      return (state = {
        ...state,
        monitoring_penilaian_detail: {
          ...state.monitoring_penilaian_detail,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS:
      return (state = {
        ...state,
        monitoring_penilaian_detail: {
          ...state.monitoring_penilaian_detail,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_FAILURE:
      return (state = {
        ...state,
        monitoring_penilaian_detail: {
          ...state.monitoring_penilaian_detail,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    default:
      return state;
  }
};

export default operatorPengelolaanReviewer;
