import { instances } from "../api_helper";
import * as url from "../url_helper";

const getSkemaSiteVisit = ({ tahun_usulan, tahun_pelaksanaan }) =>
	instances.request(`${url.ENDPOINT_SKEMA_SITE_VISIT}?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}`, {
		method: "GET",
	});

const getListUsulanSiteVisit = ({ tahun_usulan, tahun_pelaksanaan, id_skema, id_institusi }) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_SITE_VISIT}?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}&id_skema=${id_skema}&id_institusi=${id_institusi}`, {
		method: "GET",
	});

const getKomponenNilaiSiteVisit = ({ id_plotting_reviewer }) =>
	instances.request(`${url.ENDPOINT_KOMPONEN_NILAI_SITE_VISIT}?id_plotting_reviewer=${id_plotting_reviewer}`, {
		method: "GET",
	});

const getDetailUsulanByTransaksiKeSiteVisit = ({ id_transaksi_kegiatan }) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_SITE_VISIT}/${id_transaksi_kegiatan}`, {
		method: "GET",
	});

const savePenilaianSiteVisit = (payload) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_SITE_VISIT}`, {
		method: "POST",
		data: payload
	});

const savePenilaianPermanenSiteVisit = (payload) =>
	instances.request(`${url.ENDPOINT_SET_PERMANEN_SITE_VISIT}`, {
		method: "POST",
		data: payload
	});

export {
	getDetailUsulanByTransaksiKeSiteVisit,
	getKomponenNilaiSiteVisit,
	getListUsulanSiteVisit,
	getSkemaSiteVisit,
	savePenilaianSiteVisit,
	savePenilaianPermanenSiteVisit
};
