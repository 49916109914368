import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backendMonev from "../../helpers/monev";
import * as backend from "../../helpers/backend";
// Action Types
import {
  GET_USULAN_BYID_REQUEST,
  GET_USULAN_BY_SKEMA_REQUEST,
  LIST_SKEMA_REQUEST,
  LIST_INSTITUSI_REQUEST,
  GET_KOMPONEN_PENILIAN_REQUEST,
  SAVE_MONEV_REQUEST,
  PERMANENT_MONEV_PENGABDIAN_REQUEST,
  GET_HASIL_REVIEWER_TERAKHIR_REQUEST,
  GET_LINK_BERKAS_MONEV_REQUEST,
  GET_POST_PUT_DEL_MONEV_INTERNAL_REQUEST,
  GET_KELOMPOK_PROPOSAL_BY_SKEMA_REQUEST,
  GET_LIST_INSTITUSI_REVIEWER_REQUEST
} from "./actionTypes";
// Action
import { toast } from "react-toastify";
import {
  getSkemaMonevFailure,
  getSkemaMonevSuccess,
  getUsulanBySkemaSuccess,
  getUsulanBySkemaFailure,
  getUsulanByIdSuccess,
  getUsulanByIdFailure,
  getInstitusiMonevSuccess,
  getInstitusiMonevFailure,
  getKompPenilaianMonevFailure,
  getKompPenilaianMonevSuccess,
  getHasilReviewerTerakhirFail,
  getHasilReviewerTerakhirSuccess,
  saveMonevFailure,
  saveMonevSuccess,
  savePermanentSuccess,
  savePermanentFailure,
  getLinkBerkasMonevSuccess,
  getLinkBerkasMonevFailure,
  getPostPutDelMonevInternalSuccess,
  getPostPutDelMonevInternalFailure,
  getKelompokProposalBySkemaSuccess,
  getKelompokProposalBySkemaFailure,
  getListInstitusiReviewerSuccess,
  getListInstitusiReviewerFailure
} from "./action";

function* getListInstitusiReviewer({payload, cb}) {
  try {
    const res = yield call(backend.getListInstitusiReviewer, payload);

    if (res.data.code === 200) {
      yield put(getListInstitusiReviewerSuccess({ data: res.data.data }));
      cb(res.data.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    cb(null);
    toast.error(message);
    yield put(getListInstitusiReviewerFailure(message.error));
  }

}

function* getKelompokProposalBySkema({payload}) {
  try {
    const res = yield call(backend.getKelompokEvaluasi, payload);

    if (res.data.code === 200) {
      yield put(getKelompokProposalBySkemaSuccess({ data: res.data.data }));
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    yield put(getKelompokProposalBySkemaFailure(message.error));
  }

}

function* getLinkBerkasMonev({ payload, cb }) {
  try {
    const res = yield call(backendMonev.getLinkBerkasMonev, payload);

    if (res.data.code === 200) {
      yield put(getLinkBerkasMonevSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getLinkBerkasMonevFailure(message.error));
  }
}

function* saveMonev({ payload, cb }) {
  try {
    const res = yield call(backendMonev.saveMonev, payload);

    if (res.data.code === 200) {
      yield put(saveMonevSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    cb({ success: false, message });
    toast.error(message);
    yield put(saveMonevFailure(message.error));
  }
}

function* savePermanentMonev({ payload, cb }) {
  try {
    const res = yield call(backendMonev.savepPermanentMonev, payload);

    if (res.data.code === 200) {
      yield put(savePermanentSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    cb({ success: false, message });
    toast.error(message);
    yield put(savePermanentFailure(message.error));
  }
}

function* getKomponenPenialaian({ payload, cb }) {
  try {
    const res = yield call(backendMonev.getKomponenPenialaian, payload);

    if (res.data.code === 200) {
      yield put(getKompPenilaianMonevSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getKompPenilaianMonevFailure(message.error));
  }
}

function* getHasilReviewTerakhirMonev({ payload }) {
  try {
    const res = yield call(backendMonev.getHasilReviewerTerakhir, payload);

    if (res.data.code === 200) {
      yield put(getHasilReviewerTerakhirSuccess({ data: res.data.data }));
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    yield put(getHasilReviewerTerakhirFail(message.error));
  }
}

function* getInstitusiMonev({ payload, cb }) {
  try {
    const res = yield call(backendMonev.getInstitusiMonev, payload);

    if (res.data.code === 200) {
      yield put(getInstitusiMonevSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      status: false,
      message,
    });
    yield put(getInstitusiMonevFailure(message.error));
  }
}

function* getSkemaMonev({ payload }) {
  try {
    const res = yield call(backendMonev.getSkemaMonev, payload);
    if (res.data.code === 200) {
      yield put(getSkemaMonevSuccess({ data: res.data.data }));
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;

    toast.error(message);
    yield put(getSkemaMonevFailure(message.error));
  }
}

function* getUsulanBySkema({ payload, cb }) {
  try {
    // const res = yield call(backendMonev.getSkemaMonev, payload.jenis);
    const res = yield call(backendMonev.getUsulanBySkema, payload);
    if (res.data.code === 200) {
      yield put(getUsulanBySkemaSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({ success: false, message });
    yield put(getUsulanBySkemaFailure(message.error));
  }
}

function* getUsulanById({ payload, cb }) {
  const { jenis, usulanId } = payload;

  try {
    // const res = yield call(backendMonev.getSkemaMonev, payload.jenis);
    const res = yield call(backendMonev.getUsulanById, { jenis, usulanId });

    if (res.data.code === 200) {
      yield put(getUsulanByIdSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getUsulanByIdFailure(message.error));
  }
}
function* getPostPutDelMonevInternal({ payload, cb }) {
  try {
    const res = yield call(backendMonev.getPostPutDelMonevInternal, payload);

    if (res.data.code === 200) {
      yield put(getPostPutDelMonevInternalSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getPostPutDelMonevInternalFailure(message.error));
  }
}

function* notificationSaga() {
  yield takeEvery(LIST_SKEMA_REQUEST, getSkemaMonev);
  yield takeEvery(GET_USULAN_BY_SKEMA_REQUEST, getUsulanBySkema);
  yield takeEvery(GET_USULAN_BYID_REQUEST, getUsulanById);
  yield takeEvery(LIST_INSTITUSI_REQUEST, getInstitusiMonev);
  yield takeEvery(GET_KOMPONEN_PENILIAN_REQUEST, getKomponenPenialaian);
  yield takeEvery(SAVE_MONEV_REQUEST, saveMonev);
  yield takeEvery(PERMANENT_MONEV_PENGABDIAN_REQUEST, savePermanentMonev);
  yield takeEvery(
    GET_HASIL_REVIEWER_TERAKHIR_REQUEST,
    getHasilReviewTerakhirMonev
  );
  yield takeEvery(GET_LINK_BERKAS_MONEV_REQUEST, getLinkBerkasMonev);
  yield takeEvery(
    GET_POST_PUT_DEL_MONEV_INTERNAL_REQUEST,
    getPostPutDelMonevInternal
  );
  yield takeEvery(
    GET_KELOMPOK_PROPOSAL_BY_SKEMA_REQUEST,
    getKelompokProposalBySkema
  );
  yield takeEvery(
    GET_LIST_INSTITUSI_REVIEWER_REQUEST,
    getListInstitusiReviewer
  );
}

export default notificationSaga;
