import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";
// Action Types
import {
  NOTIFICATION_COMPLETE_LIST_REQUEST,
  NOTIFICATION_REQUEST,
  NOTIFICATION_TYPE_REQUEST,
  PATCH_NOTIFICATION_REQUEST,
  PATCH_PERSONEL_INVITATION_REQUEST,
  PATCH_PERSONEL_INVITATION_V2_REQUEST,
  PERSONEL_INVITATION_DETAIL_REQUEST,
} from "./actionTypes";
// Action
import {
  getNotificationCompleteListFailure,
  getNotificationCompleteListSuccess,
  getNotificationFailure,
  getNotificationSuccess,
  getNotificationTypeFailure,
  getNotificationTypeSuccess,
  getPersonelInvitationDetailFailure,
  getPersonelInvitationDetailSuccess,
  patchNotificationFailure,
  patchNotificationSuccess,
  patchPersonelInvitationFailure,
  patchPersonelInvitationSuccess,
  patchPersonelInvitationV2Failure,
  patchPersonelInvitationV2Success,
} from "./action";
import { toast } from "react-toastify";

function* getNotifications({ payload }) {
  try {
    const response = yield call(backend.getNotifications, payload);
    yield put(getNotificationSuccess(response.data.data));
  } catch (error) {
    const message = error.message;
    yield put(getNotificationFailure(message, error));
  }
}
function* getNotificationCompleteList({ payload }) {
  try {
    const response = yield call(backend.getNotificationCompleteList, payload);
    yield put(getNotificationCompleteListSuccess(response.data.data));
  } catch (error) {
    const message = error.message;
    yield put(getNotificationCompleteListFailure(message, error));
  }
}
function* getNotificationType({ payload, cb }) {
  try {
    const response = yield call(backend.getNotificationType, payload);
    yield put(getNotificationTypeSuccess(response.data.data));
    cb(response.data);
  } catch (error) {
    const message = error.message;
    yield put(getNotificationTypeFailure(message, error));
    cb(null);
  }
}

function* getPersonelInvitationDetail({ payload, cb }) {
  try {
    const response = yield call(backend.getPersonelInvitationDetail, payload);
    yield put(getPersonelInvitationDetailSuccess(response.data.data));
    cb(response.data);
  } catch (error) {
    const message = error.message;
    yield put(getPersonelInvitationDetailFailure(message, error));
    cb(null);
  }
}
function* patchNotification({ payload, cb }) {
  try {
    const response = yield call(backend.patchNotification, payload);
    yield put(patchNotificationSuccess(response.data.data));
    cb(response.data);
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(patchNotificationFailure(message, error));
    cb(null);
  }
}

function* patchPersonelInvitation({ payload, cb }) {
  try {
    const response = yield call(backend.patchPersonelInvitation, payload);
    yield put(patchPersonelInvitationSuccess(response.data.data));
    cb(response.data);
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(patchPersonelInvitationFailure(message, error));
    cb(null);
  }
}
function* patchPersonelInvitationV2({ payload, cb }) {
  try {
    const response = yield call(backend.patchPersonelInvitationV2, payload);
    yield put(patchPersonelInvitationV2Success(response.data.data));
    cb(response.data);
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(patchPersonelInvitationV2Failure(message, error));
    cb(null);
  }
}

function* notificationSaga() {
  yield takeEvery(NOTIFICATION_REQUEST, getNotifications);
  yield takeEvery(
    NOTIFICATION_COMPLETE_LIST_REQUEST,
    getNotificationCompleteList
  );
  yield takeEvery(NOTIFICATION_TYPE_REQUEST, getNotificationType);
  yield takeEvery(
    PERSONEL_INVITATION_DETAIL_REQUEST,
    getPersonelInvitationDetail
  );
  yield takeEvery(PATCH_NOTIFICATION_REQUEST, patchNotification);
  yield takeEvery(PATCH_PERSONEL_INVITATION_REQUEST, patchPersonelInvitation);
  yield takeEvery(
    PATCH_PERSONEL_INVITATION_V2_REQUEST,
    patchPersonelInvitationV2
  );
}

export default notificationSaga;
