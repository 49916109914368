export const APPROVAL_USULAN_REQUEST = "APPROVAL_USULAN_REQUEST";
export const APPROVAL_USULAN_SUCCESS = "APPROVAL_USULAN_SUCCESS";
export const APPROVAL_USULAN_FAILURE = "APPROVAL_USULAN_FAILURE";

export const BATALKAN_USULAN_REQUEST = "BATALKAN_USULAN_REQUEST";
export const BATALKAN_USULAN_SUCCESS = "BATALKAN_USULAN_SUCCESS";
export const BATALKAN_USULAN_FAILURE = "BATALKAN_USULAN_FAILURE";

export const GET_USULAN_DISETUJUI_REQUEST = "GET_USULAN_DISETUJUI_REQUEST";
export const GET_USULAN_DISETUJUI_SUCCESS = "GET_USULAN_DISETUJUI_SUCCESS";
export const GET_USULAN_DISETUJUI_FAILURE = "GET_USULAN_DISETUJUI_FAILURE";

export const GET_USULAN_DITOLAK_REQUEST = "GET_USULAN_DITOLAK_REQUEST";
export const GET_USULAN_DITOLAK_SUCCESS = "GET_USULAN_DITOLAK_SUCCESS";
export const GET_USULAN_DITOLAK_FAILURE = "GET_USULAN_DITOLAK_FAILURE";

export const GET_USULAN_BARU_REQUEST = "GET_USULAN_BARU_REQUEST";
export const GET_USULAN_BARU_SUCCESS = "GET_USULAN_BARU_SUCCESS";
export const GET_USULAN_BARU_FAILURE = "GET_USULAN_BARU_FAILURE";

export const GET_USULAN_DIKIRIM_REQUEST = "GET_USULAN_DIKIRIM_REQUEST";
export const GET_USULAN_DIKIRIM_SUCCESS = "GET_USULAN_DIKIRIM_SUCCESS";
export const GET_USULAN_DIKIRIM_FAILURE = "GET_USULAN_DIKIRIM_FAILURE";

export const GET_USULAN_DITINJAU_REQUEST = "GET_USULAN_DITINJAU_REQUEST";
export const GET_USULAN_DITINJAU_SUCCESS = "GET_USULAN_DITINJAU_SUCCESS";
export const GET_USULAN_DITINJAU_FAILURE = "GET_USULAN_DITINJAU_FAILURE";

export const GET_ALASAN_PENOLAKAN_REQUEST = "GET_ALASAN_PENOLAKAN_REQUEST";
export const GET_ALASAN_PENOLAKAN_SUCCESS = "GET_ALASAN_PENOLAKAN_SUCCESS";
export const GET_ALASAN_PENOLAKAN_FAILURE = "GET_ALASAN_PENOLAKAN_FAILURE";

export const GET_SUMMARY_USULAN_GROUPBY_SKEMA_REQUEST = "GET_SUMMARY_USULAN_GROUPBY_SKEMA_REQUEST";
export const GET_SUMMARY_USULAN_GROUPBY_SKEMA_SUCCESS = "GET_SUMMARY_USULAN_GROUPBY_SKEMA_SUCCESS";
export const GET_SUMMARY_USULAN_GROUPBY_SKEMA_FAILURE = "GET_SUMMARY_USULAN_GROUPBY_SKEMA_FAILURE";
export const CLEAN_SUMMARY_USULAN_GROUPBY_SKEMA = "CLEAN_SUMMARY_USULAN_GROUPBY_SKEMA";