export const GET_PENDAFTARAN_REVIEWER_REQUEST = "GET_PENDAFTARAN_REVIEWER_REQUEST";
export const GET_PENDAFTARAN_REVIEWER_SUCCESS = "GET_PENDAFTARAN_REVIEWER_SUCCESS";
export const GET_PENDAFTARAN_REVIEWER_FAILURE = "GET_PENDAFTARAN_REVIEWER_FAILURE";

export const GET_PENDAFTARAN_REVIEWER_PERSONAL_REQUEST = "GET_PENDAFTARAN_REVIEWER_PERSONAL_REQUEST";
export const GET_PENDAFTARAN_REVIEWER_PERSONAL_SUCCESS = "GET_PENDAFTARAN_REVIEWER_PERSONAL_SUCCESS";
export const GET_PENDAFTARAN_REVIEWER_PERSONAL_FAILURE = "GET_PENDAFTARAN_REVIEWER_PERSONAL_FAILURE";

export const CREATE_PENDAFTARAN_REVIEWER_REQUEST = "CREATE_PENDAFTARAN_REVIEWER_REQUEST";
export const CREATE_PENDAFTARAN_REVIEWER_SUCCESS = "CREATE_PENDAFTARAN_REVIEWER_SUCCESS";
export const CREATE_PENDAFTARAN_REVIEWER_FAILURE = "CREATE_PENDAFTARAN_REVIEWER_FAILURE";

export const VERIFY_PENDAFTARAN_REVIEWER_REQUEST = "VERIFY_PENDAFTARAN_REVIEWER_REQUEST";
export const VERIFY_PENDAFTARAN_REVIEWER_SUCCESS = "VERIFY_PENDAFTARAN_REVIEWER_SUCCESS";
export const VERIFY_PENDAFTARAN_REVIEWER_FAILURE = "VERIFY_PENDAFTARAN_REVIEWER_FAILURE";
