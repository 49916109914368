/* eslint-disable no-unused-vars */
import {
  getMultiFileFromBucket,
  getSingleFileFromBucket,
} from "helpers/getFileFromBucket";
import PDFMerger from "pdf-merger-js/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toast } from "react-toastify";
import util from "helpers/utilities";
import { PDFDocument } from "pdf-lib";
import {
  PdfCreating as engine,
  pdfCreatePersetujuan as engine2,
} from "./partials";
import utilities from "helpers/utilities";
// import { pdfCreatePersetujuan as engine2 } from "./partials/approval";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CetakKosabangsaPelaksana {
  constructor(data) {
    this.data = data;
  }
  async getSingleFile(fileUrl = "") {
    const friendlyURL = util.getFriendlyURL(fileUrl);
    const response = await getSingleFileFromBucket(friendlyURL);

    if (response.error) {
      toast.error(response.message);
    } else {
      return response.data;
    }
  }

  async getMultiplefileWithDocx(dataUrl) {
    let files = [];
    let dataBlob = [];
    files.push({
      NamaJenisLuaran: "Substansi",
      URLDokumen: dataUrl?.url_file_substansi || "",
    });
    const luaranArtikel =
      dataUrl?.luaran_artikel?.filter(item => item.URLDokumen !== "") || [];
    files.push(...luaranArtikel);
    for (const file of files) {
      const extension = util.getFriendlyURL(file.URLDokumen).split(".").pop();
      if (extension === "docx") {
        const res = await getMultiFileFromBucket(
          util.getFriendlyURL(file.URLDokumen)
        );
        if (!res.error) {
          this.forceDownload(res.data, dataUrl.title);
        }
      } else {
        const res = await getMultiFileFromBucket(
          util.getFriendlyURL(file.URLDokumen)
        );
        if (!res.error) {
          dataBlob.push(res.data);
        }
      }
    }
    return dataBlob;
  }

  async getMultiplefile(dataUrl) {
    let files = [];
    let dataBlob = [];
    if (dataUrl?.raw?.program_sasaran || dataUrl?.raw?.solusi_ditawarkan) {
      files.push(
        {
          URLDokumen: dataUrl?.raw?.program_sasaran?.url_file_substansi,
        },
        {
          URLDokumen: dataUrl?.raw?.solusi_ditawarkan?.url_file_substansi,
        }
      );
    }
    dataUrl?.raw?.mitra_sasaran?.forEach(item => {
      if (item.url_file_dokumen !== "") {
        files.push({
          URLDokumen: item.url_file_dokumen,
        });
      }
    });
    if (dataUrl?.raw?.is_mou && dataUrl?.raw?.mou?.url_file_dokumen) {
      files.push({
        URLDokumen: dataUrl?.raw?.mou?.url_file_dokumen,
      });
    }
    if (dataUrl?.raw?.dokumen_pendukung) {
      const { dokumen_pendukung } = dataUrl.raw;
      if (dokumen_pendukung?.url_file_keputusan_lppm) {
        files.push({
          URLDokumen: dokumen_pendukung?.url_file_keputusan_lppm,
        });
      }
      if (dokumen_pendukung?.url_file_ketersediaan) {
        files.push({
          URLDokumen: dokumen_pendukung?.url_file_ketersediaan,
        });
      }
    }
    for (const file of files) {
      const res = await getMultiFileFromBucket(
        util.getFriendlyURL(file.URLDokumen)
      );
      if (!res.error) {
        dataBlob.push(res.data);
      }
    }
    return dataBlob;
  }

  async generate() {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine(this.data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }
  async generatePersetujuan() {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine2(this.data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }

  // async generatePersetujuan() {
  //   return await new Promise((resolve, _reject) => {
  //     try {
  //       const pdfDoc = pdfMake.createPdf(engine2(this.data));

  //       pdfDoc.getBlob(async blob => {
  //         resolve(blob);
  //       });
  //     } catch (error) {
  //       _reject(error);
  //     }
  //   });
  // }

  async merging(docs = []) {
    // const merger = new PDFMerger();
    // for (const file of docs) {
    //   await merger.add(file);
    // }
    // const mergerPdf = await merger.saveAsBlob();
    // return mergerPdf;
    const merger = await PDFDocument.create();

    let i = 0;

    for (const file of docs) {
      const bufferFile = await utilities.blobToArrayBuffer(file);

      const doc = await PDFDocument.load(bufferFile, {
        ignoreEncryption: true,
      });
      // await merger.add(file);
      const contentPage = await merger.copyPages(doc, doc.getPageIndices());
      for (const page of contentPage) {
        merger.addPage(page);
      }
      i++;
    }
    const mergerPdf = await merger.saveAsBase64();
    const mergerPDFAsBlob = utilities.b64toBlob(mergerPdf, "application/pdf");

    return mergerPDFAsBlob;
  }

  async mergingv2(docs = []) {
    const merger = await PDFDocument.create();

    let i = 0;

    for (const file of docs) {
      const bufferFile = await utilities.blobToArrayBuffer(file);
      const doc = await PDFDocument.load(bufferFile, {
        ignoreEncryption: true,
      });

      const contentPage = await merger.copyPages(doc, doc.getPageIndices());
      for (const page of contentPage) {
        merger.addPage(page);
      }
      i++;
    }
    const mergerPdf = await merger.saveAsBase64();

    const mergerPDFAsBlob = utilities.b64toBlob(mergerPdf, "application/pdf");

    return mergerPDFAsBlob;
  }

  forceDownload(file, fileName) {
    // * Force Download
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", `${fileName}`);
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  }

  openAsWindow(file, _filename, type) {
    let binaryData = [];
    binaryData.push(file);

    window.open(
      URL.createObjectURL(new Blob(binaryData, { type: type })),
      "_blank"
    );
  }
}

export default CetakKosabangsaPelaksana;
