/* eslint-disable no-unused-vars */
import {
  getMultiFileFromBucket,
  getSingleFileFromBucket,
} from "helpers/getFileFromBucket";
import PDFMerger from "pdf-merger-js/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toast } from "react-toastify";
import util from "helpers/utilities";
import { PDFDocument } from "pdf-lib";
import {
  PdfCreating as engine,
  pdfCreatePersetujuan as engine2,
} from "./partials";
import utilities from "helpers/utilities";
// import { pdfCreatePersetujuan as engine2 } from "./partials/approval";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CetakUsulanBaruPengabdian {
  constructor(data) {
    this.data = data;
    this.indexBlobSkiped = [];
  }
  async getSingleFile(fileUrl = "") {
    const friendlyURL = util.getFriendlyURL(fileUrl);
    const response = await getSingleFileFromBucket(friendlyURL);

    if (response.error) {
      toast.error(response.message);
    } else {
      return response.data;
    }
  }

  async getMultiplefileWithDocx(dataUrl) {
    let files = [];
    let dataBlob = [];
    files.push({
      NamaJenisLuaran: "Substansi",
      URLDokumen: dataUrl?.url_file_substansi || "",
    });
    const luaranArtikel =
      dataUrl?.luaran_artikel?.filter(item => item.URLDokumen !== "") || [];
    files.push(...luaranArtikel);
    for (const file of files) {
      const extension = util.getFriendlyURL(file.URLDokumen).split(".").pop();
      if (extension === "docx") {
        const res = await getMultiFileFromBucket(
          util.getFriendlyURL(file.URLDokumen)
        );
        if (!res.error) {
          this.forceDownload(res.data, dataUrl.title);
        }
      } else {
        const res = await getMultiFileFromBucket(
          util.getFriendlyURL(file.URLDokumen)
        );
        if (!res.error) {
          dataBlob.push(res.data);
        }
      }
    }
    return dataBlob;
  }

  async getMultiplefile(data) {
    let files = [];
    let dataBlob = [];
    if (data?.substansi?.file_url_substansi) {
      files.push({
        URLDokumen: data?.substansi?.file_url_substansi,
      });
    }
    const fileMitra =
      data?.mitra?.filter(item => item.url_surat_kesanggupan_mitra !== "") ||
      [];
    const filePendukungMitra =
      data?.file_pendukung_mitra?.filter(item => item.url_file !== "") || [];

    fileMitra.forEach(item => {
      files.push({
        URLDokumen: item.url_surat_kesanggupan_mitra,
      });
    });

    filePendukungMitra.forEach(item => {
      files.push({
        URLDokumen: item.url_file,
      });
    });

    if (data?.perbaikan_usulan) {
      if (data.perbaikan_usulan?.file_kesanggupan?.url) {
        files.push({
          URLDokumen: data.perbaikan_usulan?.file_kesanggupan?.url,
        });
      }
    }
    for (const file of files) {
      const res = await getMultiFileFromBucket(
        util.getFriendlyURL(file.URLDokumen)
      );
      if (!res.error) {
        dataBlob.push(res.data);
      }
    }
    return dataBlob;
  }

  async getMultiplefilev2(data) {
    let files = [];
    let dataBlob = [];

    let extractExtension = '';
    let extractFileName = '';

    if (data?.substansi?.file_url_substansi) {
      extractExtension = data?.substansi?.file_url_substansi?.split(".")?.pop() || "";
      extractFileName = data?.substansi?.file_url_substansi?.split("/")?.pop() || "no-name";
      files.push({
        URLDokumen: data?.substansi?.file_url_substansi,
        fileType: extractExtension,
        fileName: extractFileName
      });
    }
    const fileMitra =
      data?.mitra?.filter(item => item.url_surat_kesanggupan_mitra !== "") ||
      [];
    const filePendukungMitra =
      data?.file_pendukung_mitra?.filter(item => item.url_file !== "") || [];

    fileMitra.forEach(item => {
      extractExtension = item.url_surat_kesanggupan_mitra?.split(".")?.pop() || "";
      extractFileName = item.url_surat_kesanggupan_mitra?.split("/")?.pop() || "no-name";


      files.push({
        URLDokumen: item.url_surat_kesanggupan_mitra,
        fileType: extractExtension,
        fileName: extractFileName
      });
    });

    filePendukungMitra.forEach(item => {

      extractExtension = item?.url_file?.split(".")?.pop() || "";
      extractFileName = item?.url_file?.split("/")?.pop() || "no-name";

      files.push({
        URLDokumen: item.url_file,
        fileType: extractExtension,
        fileName: extractFileName
      });
    });

    if (data?.perbaikan_usulan) {
      if (data.perbaikan_usulan?.file_kesanggupan?.url) {

        extractExtension = data.perbaikan_usulan?.file_kesanggupan?.url?.split(".")?.pop() || "";
        extractFileName = data.perbaikan_usulan?.file_kesanggupan?.url?.split("/")?.pop() || "no-name";

        files.push({
          URLDokumen: data.perbaikan_usulan?.file_kesanggupan?.url,
          fileType: extractExtension,
          fileName: extractFileName
        });
      }
    }
    for (const file of files) {
      const res = await getMultiFileFromBucket(
        util.getFriendlyURL(file.URLDokumen)
      );
      if (!res.error) {
        dataBlob.push({
          file: res.data,
          ...file
        });
      }
    }

    return dataBlob;
  }

  async generate() {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine(this.data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }

  async generatePersetujuan() {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine2(this.data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }

  async merging(docs = []) {
    // const merger = new PDFMerger();
    // for (const file of docs) {
    //   await merger.add(file);
    // }
    // const mergerPdf = await merger.saveAsBlob();
    // return mergerPdf;
    const merger = await PDFDocument.create();

    let i = 0;

    for (const file of docs) {
      console.log("file", i);

      const bufferFile = await utilities.blobToArrayBuffer(file);

      const doc = await PDFDocument.load(bufferFile, {
        ignoreEncryption: true,
      });
      // await merger.add(file);
      const contentPage = await merger.copyPages(doc, doc.getPageIndices());
      for (const page of contentPage) {
        merger.addPage(page);
      }
      i++;
    }
    const mergerPdf = await merger.saveAsBase64();

    const mergerPDFAsBlob = utilities.b64toBlob(mergerPdf, "application/pdf");

    return mergerPDFAsBlob;
  }

  async mergingv2(docs = []) {
    const merger = await PDFDocument.create();

    let i = 0;

    for (const file of docs) {
      console.log("file ", i);
      const bufferFile = await utilities.blobToArrayBuffer(file);
      const doc = await PDFDocument.load(bufferFile, {
        ignoreEncryption: true,
      });

      const contentPage = await merger.copyPages(doc, doc.getPageIndices());
      for (const page of contentPage) {
        merger.addPage(page);
      }
      i++;
    }
    const mergerPdf = await merger.saveAsBase64();

    const mergerPDFAsBlob = utilities.b64toBlob(mergerPdf, "application/pdf");

    return mergerPDFAsBlob;
  }

  async mergingv3(docs = [], cb) {

    const pdf = await PDFDocument.create();
    let i = 0;
    try {
      for (const file of docs) {

        const uint8Arr = await utilities.blobToUint8Array(file);

        if (!this.indexBlobSkiped.includes(i)) {

          const doc = await PDFDocument.load(uint8Arr, { ignoreEncryption: true, throwOnInvalidObject: true })

          if (doc) {
            const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
            for (const page of contentPage) {
              pdf.addPage(page);
            }
          }
        }
        i++;
      }
      const pdfMerged = await pdf.saveAsBase64()
      const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, 'application/pdf');

      cb({ res: pdfMergedAsBlob, error: false, })

    } catch (error) {
      if (error.message.includes('invalid object')) {
        this.indexBlobSkiped.push(i);
        cb({ res: undefined, error: true, indexBlobSkipped: i })
      } else {
        cb({ res: undefined, error: true, errMsg: error.message })
      }
    }
  }

  forceDownload(file, fileName) {
    // * Force Download
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", `${fileName}`);
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  }

  openAsWindow(file, _filename, type) {
    let binaryData = [];
    binaryData.push(file);

    window.open(
      URL.createObjectURL(new Blob(binaryData, { type: type })),
      "_blank"
    );
  }
}

export default CetakUsulanBaruPengabdian;
