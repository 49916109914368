
import { content, contentPrototipeImage, contentPersetujuan } from "./content";
import stylePDF from "./styles";

export const getBase64ImageFromURL = (url) => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
        };

        img.onerror = error => {
            reject(error);
        };

        img.src = url;
    });
}

export const pdfCreate = (item) => {
    const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;

    return {
        content: [
            ...content(item)
        ],
       
        defaultStyle,
        pageMargins,
        pageSize,
        styles,
    };
}

export const pdfCreateImagePrototipe = async (urlImage) => {
    const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
    return {
        content: [
            await contentPrototipeImage(urlImage)
        ],
        defaultStyle,
        pageMargins,
        pageSize,
        styles,
    };
}

export const pdfCreatePersetujuan = (item = {}) => {
    const { pageSize, styles, defaultStyle } = stylePDF;
    const itemPersetujuan = (item?.kegiatans || []).find(row => row.urutan === 1);
    return {
        content: [
            ...contentPersetujuan({...itemPersetujuan, tgl_updated : item.tgl_updated })
        ],
        defaultStyle,
        pageMargins: [45, 32, 45, 45],
        pageSize,
        styles,
    };
}


