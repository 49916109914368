/**
 * Configure routes for the application.
 * @date August 06, 2022
 */
import roleTypeIds, { getRoleName } from "constants/role-type-ids";
import operatorRoute from "./operator.route";
import reviewerRoute from "./reviewer.route";
import publicRoutes from "./public.route";
import dosenRoute from "./dosen.route";
import eCatalogRoute from "./ecatalog.route";
import eCatalogPublicRoute from "./ecatalog.public.route";
import eCatalogOperatorPtRoute from "./ecatalog.operator-pt.route";
import eCatalogDosenRoute from "./ecatalog.dosen.route";
import direktoratTypes from "constants/direktorat-types";

const allProtectedRoutes = [
  ...operatorRoute,
  ...reviewerRoute,
  ...dosenRoute,
  ...eCatalogRoute,
  ...(process.env.REACT_APP_NODE === "production"
    ? []
    : eCatalogOperatorPtRoute),
  ...(process.env.REACT_APP_NODE === "production" ? [] : eCatalogDosenRoute),
  // ...eCatalogOperatorPtRoute,
  // ...eCatalogDosenRoute,
];

function authProtectedRoutes(data = { roles: [], kd_program_pendidikan: "1" }) {
  const routes = [];
  const roles = [...new Set(data.roles)];
  const defaultDirektorat = [direktoratTypes.DIKSI, direktoratTypes.DIKTI];

  allProtectedRoutes.forEach(route => {
    if (route.path && route.component && route.roles) {
      if (roles.includes(roleTypeIds.SUPER_ADMIN)) {
        routes.push(route);
      } else {
        route.roles.map(role => {
          if (roles.includes(role)) {
            const direktorats = route.direktorat ?? defaultDirektorat;
            const activeDirektorat =
              data.kd_program_pendidikan !== direktoratTypes.DIKSI
                ? direktoratTypes.DIKTI
                : direktoratTypes.DIKSI;
            if (direktorats.includes(activeDirektorat)) {
              routes.push(route);
            }
          }
        });
      }
    }
  });

  return routes;
}

function buildMenu(route) {
  return {
    id: route.id,
    url: route.path,
    label: route.label,
    roles: route.roles ?? [],
    sequence: route.sequence ?? 0,
    hidden: route.hidden ?? false,
    icon: route.icon ?? "bx bx-home-circle me",
    children: [],
  };
}

function getNavbarMenues(rolesParam = [], direktorat = "1") {
  const menues = [];
  const roles = [...new Set(rolesParam)];
  const defaultDirektorat = [direktoratTypes.DIKSI, direktoratTypes.DIKTI];

  allProtectedRoutes.forEach(route => {
    if (route.label && route.roles && !route.hide) {
      const direktorats = route.direktorat ?? defaultDirektorat;
      const activeDirektorat =
        direktorat !== direktoratTypes.DIKSI
          ? direktoratTypes.DIKTI
          : direktoratTypes.DIKSI;

      route.roles.map(role => {
        // if (roles.includes(role) || roles.includes(roleTypeIds.SUPER_ADMIN)) {
        if (roles.includes(role) && direktorats.includes(activeDirektorat)) {
          if (route.parent) {
            const parent = menues.find(menu => menu.id === route.parent);
            if (parent) {
              parent.children.push(buildMenu(route));
            }
          } else {
            const menu = menues.find(menu => menu.label === route.label);
            if (!menu) {
              menues.push(buildMenu(route));
            } else {
              if (menu.children.length === 0) {
                // menu.label +=` ${getRoleName(menu.roles[0])}`;
                menu.children.unshift({
                  ...menu,
                  label: `${menu.label} ${getRoleName(menu.roles[0])}`,
                });
              }
              menu.children.push(
                buildMenu({
                  ...route,
                  label: `${route.label} ${getRoleName(route.roles[0])}`,
                })
              );
            }
          }
        }
      });
    }
  });

  // Remove Duplicate Children
  menues.forEach(menu => {
    menu.children = menu.children.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  });

  return menues;
}

function routePublicECatalog() {
  const routes = [];
  eCatalogPublicRoute.forEach(route => {
    if (route.path && route.component) {
      routes.push(route);
    }
  });
  return routes;
}
function getNavbarMenuesECatalogPublic() {
  const menues = [];
  eCatalogPublicRoute.forEach(route => {
    if (route.label) {
      if (route.parent) {
        const parent = menues.find(menu => menu.id === route.parent);
        if (parent) {
          parent.children.push(buildMenu(route));
        }
      } else {
        const menu = menues.find(menu => menu.label === route.label);
        if (!menu) {
          menues.push(buildMenu(route));
        } else {
          if (menu.children.length === 0) {
            menu.children.unshift({
              ...menu,
              label: `${menu.label} ${getRoleName(menu.roles[0])}`,
            });
          }
          menu.children.push(
            buildMenu({
              ...route,
              label: `${route.label} ${getRoleName(route.roles[0])}`,
            })
          );
        }
      }
    }
  });

  // Remove Duplicate Children
  menues.forEach(menu => {
    menu.children = menu.children.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  });

  return menues;
}
function redirectFirstPage(roles = []) {
  if (roles.includes(roleTypeIds.DOSEN)) {
    window.location.href = "/dashboard";
  } else if (roles.includes(roleTypeIds.OPERATOR_PT_PENELITIAN)) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.OPERATOR_PT_PENGABDIAN)) {
    window.location.href = "/operator/dashboard";
  } else if (
    roles.includes(roleTypeIds.OPERATOR_PUSAT_RISBANG_PENELITIAN) ||
    roles.includes(roleTypeIds.OPERATOR_PUSAT_RISBANG_ABDIMAS)
  ) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.OPERATOR_PUSAT_ADMINISTRATOR)) {
    window.location.href = "/operator/dashboard";
  } else if (
    roles.includes(roleTypeIds.REVIEWER_NASIONAL) ||
    roles.includes(roleTypeIds.REVIEWER_DUDI)
  ) {
    window.location.href = "/reviewer/dashboard";
  } else if (roles.includes(roleTypeIds.OPERATOR_LLDIKTI_PENELITIAN)) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.OPERATOR_LLDIKTI_PENGABDIAN)) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.OPERATOR_MANAGEMENT_LLDIKTI)) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.KEPALA_LEMBAGA_PENELITIAN)) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.KEPALA_LEMBAGA_PENGABDIAN)) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.OPERATOR_KEMENTRIAN_DIT_APTV)) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.OPERATOR_BPK)) {
    window.location.href = "/operator/dashboard";
  } else if (roles.includes(roleTypeIds.ADMIN_PROTOTIPE)) {
    window.location.href = "/operator/dashboard";
  } else if (
    roles.includes(roleTypeIds.SUPER_ADMIN || roleTypeIds.OPERATOR_DIKTI)
  ) {
    window.location.href = "/operator-pengumuman";
  } else if (roles.includes(roleTypeIds.REVIEWER_KI_NON_DOSEN)) {
    window.location.href = "/reviewer-ki/adm";
  } else if (roles.includes(roleTypeIds.OPERATOR_E_CATALOG)) {
    window.location.href = "/e-catalog/home";
  } else {
    window.location.href = "/dashboard";
  }
}

export {
  publicRoutes,
  getNavbarMenues,
  redirectFirstPage,
  authProtectedRoutes,
  getNavbarMenuesECatalogPublic,
  routePublicECatalog,
};
