import React from "react";

// Auth
const Login = React.lazy(() => import("../pages/Authentication/Login"));
const Logout = React.lazy(() => import("../pages/Authentication/Logout"));
const Register = React.lazy(() => import("../pages/Authentication/Register"));
const ForgetPwd = React.lazy(() =>
  import("../pages/Authentication/ForgetPassword")
);
// Landing Page
const News = React.lazy(() => import("../pages/LandingPage/News"));
const Faq = React.lazy(() => import("../pages/LandingPage/Faq.js"));
const LandingPage = React.lazy(() => import("../pages/LandingPage/index"));
const Contact = React.lazy(() => import("../pages/LandingPage/Contact.js"));
const BacaBerita = React.lazy(() => import("../components/Berita/BacaBerita"));
const FormMitraLapAkhirPengabdianTest = React.lazy(() =>
  import("../components/LaporanAkhirPengabdian/JenisFormMitra")
);
const TentangBima = React.lazy(() =>
  import("../pages/LandingPage/TentangBima.js")
);
const AnnouncementsPages = React.lazy(() =>
  import("../pages/LandingPage/AnnouncementsPages")
);
const Panduan = React.lazy(() => import("../pages/LandingPage/Panduan.js"));
// Pendaftaran akun operator pt
const PendaftaranAkunOperatorPublic = React.lazy(() =>
  import("../pages/LandingPage/PendaftaranAkunPt")
);
export default [
  // Test Form
  { path: "/test/mitra", component: FormMitraLapAkhirPengabdianTest },
  // Auth
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // Landing Page
  { path: "/pengumuman", component: AnnouncementsPages },
  { path: "/TentangBima", component: TentangBima },
  { path: "/news/:id", component: BacaBerita },
  { path: "/contact", component: Contact },
  { path: "/news", component: News },
  { path: "/faq", component: Faq },
  { path: "/pendaftaran-akun-pt", component: PendaftaranAkunOperatorPublic },
  // Keep code below at the end
  { path: "/", exact: true, component: LandingPage },
  {
    path: "/panduan",
    component: Panduan,
    hide: window.location.hostname === "bima.kemdikbud.go.id",
  },
];
