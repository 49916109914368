import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";

import {
	GET_SKEMA_SITE_VISIT_REQUEST,
	GET_LIST_USULAN_SITE_VISIT_REQUEST,
	GET_DETAIL_USULAN_SITE_VISIT_REQUEST,
	GET_KOMPONEN_NILAI_SITE_VISIT_REQUEST,
	SAVE_PENILAIAN_SITE_VISIT_REQUEST,
	SAVE_PENILAIAN_PERMANEN_SITE_VISIT_REQUEST,
} from "./actionTypes";
// Action
import {
	getKomponenNilaiSiteVisitFailure,
	getKomponenNilaiSiteVisitSuccess,
	getListUsulanSiteVisitSuccess,
	getListUsulanSiteVisitFailure,
	getDetailUsulanSiteVisitSuccess,
	getDetailUsulanSiteVisitFailure,
	getSkemaSiteVisitSuccess,
	getSkemaSiteVisitFailure
} from "./action";

function* getSkemaSiteVisit({ payload, cb = () => { } }) {
	try {
		const response = yield call(backend.getSkemaSiteVisit, payload);
		if (response.data.code == 200) {
			let state = ''
			cb(response.data.data)
			yield put(
				getSkemaSiteVisitSuccess({
					data: response.data.data,
					state
				})
			);
		} else {
			throw new Error(response.data.message)
		}
	} catch (error) {
		const message = error.message;
		toast.error(message)
		yield put(getSkemaSiteVisitFailure(message, error));
	}
}

function* getListUsulanSiteVisit({ payload, cb = () => { } }) {
	try {
		const response = yield call(backend.getListUsulanSiteVisit, payload);
		if (response.data.code == 200) {
			let state = ''
			cb(response.data.data)
			yield put(
				getListUsulanSiteVisitSuccess({
					data: response.data.data,
					state
				})
			);
		} else {
			throw new Error(response.data.message)
		}
	} catch (error) {
		const message = error.message;
		toast.error(message)
		yield put(getListUsulanSiteVisitFailure(message, error));
	}
}

function* getDetailUsulanSiteVisit({ payload, cb = () => { } }) {
	try {
		const response = yield call(backend.getDetailUsulanByTransaksiKegiatanId, payload);
		if (response.data.code == 200) {
			let state = ''
			cb(response.data.data)
			yield put(
				getDetailUsulanSiteVisitSuccess({
					data: response.data.data,
					state
				})
			);
		} else {
			throw new Error(response.data.message)
		}
	} catch (error) {
		const message = error.message;
		toast.error(message)
		yield put(getDetailUsulanSiteVisitFailure(message, error));
	}
}

function* getKomponenNilaiSiteVisit({ payload, cb = () => { } }) {
	try {
		const response = yield call(backend.getKomponenNilaiSiteVisit, payload);
		if (response.data.code == 200) {
			let state = ''
			cb(response.data.data)
			yield put(
				getKomponenNilaiSiteVisitSuccess({
					data: response.data.data,
					state
				})
			);
		} else {
			throw new Error(response.data.message)
		}
	} catch (error) {
		const message = error.message;
		toast.error(message)
		yield put(getKomponenNilaiSiteVisitFailure(message, error));
	}
}

function* savePenilaianEvaluasi({ payload, cb = () => { } }) {
	try {
		const response = yield call(backend.savePenilaianSiteVisit, payload);
		if (response.data.code == 200) {
			cb(response.data.data)
		} else {
			cb()
			throw new Error(response.data.message)
		}
	} catch (error) {
		const message = error.message;
		toast.error(message)
	}
}

function* savePenilaianPermanenSiteVisit({ payload, cb = () => { } }) {
	try {
		const response = yield call(backend.savePenilaianPermanenSiteVisit, payload);
		if (response.data.code == 200) {
			cb(response.data.data)
		} else {
			throw new Error(response.data.message)
		}
	} catch (error) {
		const message = error.message;
		toast.error(message)
	}
}

function* siteVisit() {
	yield takeEvery(GET_SKEMA_SITE_VISIT_REQUEST, getSkemaSiteVisit);
	yield takeEvery(GET_LIST_USULAN_SITE_VISIT_REQUEST, getListUsulanSiteVisit);
	yield takeEvery(GET_KOMPONEN_NILAI_SITE_VISIT_REQUEST, getKomponenNilaiSiteVisit);
	yield takeEvery(GET_DETAIL_USULAN_SITE_VISIT_REQUEST, getDetailUsulanSiteVisit);
	yield takeEvery(SAVE_PENILAIAN_SITE_VISIT_REQUEST, savePenilaianEvaluasi);
	yield takeEvery(SAVE_PENILAIAN_PERMANEN_SITE_VISIT_REQUEST, savePenilaianPermanenSiteVisit);
}

export default siteVisit;
