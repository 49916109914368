import {
  LIST_SKEMA_REQUEST,
  LIST_SKEMA_FAILURE,
  LIST_SKEMA_SUCCESS,
  GET_USULAN_BY_SKEMA_REQUEST,
  GET_USULAN_BY_SKEMA_SUCCESS,
  GET_USULAN_BY_SKEMA_FAILURE,
  GET_USULAN_BYID_REQUEST,
  GET_USULAN_BYID_SUCCESS,
  GET_USULAN_BYID_FAILURE,
  GET_HASIL_REVIEWER_TERAKHIR_REQUEST,
  GET_HASIL_REVIEWER_TERAKHIR_SUCCESS,
  GET_HASIL_REVIEWER_TERAKHIR_FAILURE,
  LIST_INSTITUSI_REQUEST,
  LIST_INSTITUSI_FAILURE,
  LIST_INSTITUSI_SUCCESS,
  GET_KOMPONEN_PENILIAN_REQUEST,
  GET_KOMPONEN_PENILIAN_FAILURE,
  GET_KOMPONEN_PENILIAN_SUCCESS,
  SAVE_MONEV_REQUEST,
  SAVE_MONEV_FAILURE,
  SAVE_MONEV_SUCCESS,
  PERMANENT_MONEV_PENGABDIAN_REQUEST,
  PERMANENT_MONEV_PENGABDIAN_FAILURE,
  PERMANENT_MONEV_PENGABDIAN_SUCCESS,
  GET_LINK_BERKAS_MONEV_FAILURE,
  GET_LINK_BERKAS_MONEV_SUCCESS,
  GET_LINK_BERKAS_MONEV_REQUEST,
  GET_POST_PUT_DEL_MONEV_INTERNAL_REQUEST,
  GET_POST_PUT_DEL_MONEV_INTERNAL_SUCCESS,
  GET_POST_PUT_DEL_MONEV_INTERNAL_FAILURE,
  GET_KELOMPOK_PROPOSAL_BY_SKEMA_REQUEST,
  GET_KELOMPOK_PROPOSAL_BY_SKEMA_SUCCESS,
  GET_KELOMPOK_PROPOSAL_BY_SKEMA_FAILURE,
  GET_LIST_INSTITUSI_REVIEWER_REQUEST,
  GET_LIST_INSTITUSI_REVIEWER_SUCCESS,
  GET_LIST_INSTITUSI_REVIEWER_FAILURE,
  CLEAN_KELOMPOK_PROPOSAL_BY_SKEMA,
} from "./actionTypes";

const initialState = {
  save: {
    isFetching: false,
    item: {},
    errorMessage: "",
    errors: null,
  },
  savePermanentSkema: {
    isFetching: false,
    item: {},
    errorMessage: "",
    errors: null,
  },
  hasilReviewerTerakhir: {
    isFetching: false,
    item: {},
    errorMessage: "",
    errors: null,
  },
  listSkema: {
    isFetching: false,
    items: [],
    errorMessage: "",
    errors: null,
  },

  listInstitusi: {
    isFetching: false,
    items: [],
    selected: {},
    errorMessage: "",
    errors: null,
  },
  listKomponenPenilaian: {
    isFetching: false,
    items: [],
    errorMessage: "",
    errors: null,
  },
  listUsulan: {
    isFetching: false,
    item: {},
    errorMessage: "",
    errors: null,
  },
  usulan: {
    item: {},
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  berkasMonev: {
    item: {},
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  monevInternal: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  id_institusi: "",
  id_tahapan: "",
  id_skema: "",
  thn_usulan: "",
};

const Monev = (state = initialState, action) => {
  switch (action.type) {
    case GET_LINK_BERKAS_MONEV_REQUEST:
      return (state = {
        ...state,
        berkasMonev: {
          ...state.berkasMonev,
          isFetching: true,
        },
      });

    case GET_LINK_BERKAS_MONEV_SUCCESS:
      return (state = {
        ...state,
        berkasMonev: {
          ...state.berkasMonev,
          item: action.payload.data,
          isFetching: false,
        },
      });

    case GET_LINK_BERKAS_MONEV_FAILURE:
      return (state = {
        ...state,
        berkasMonev: {
          ...state.berkasMonev,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });

    case GET_HASIL_REVIEWER_TERAKHIR_REQUEST:
      return (state = {
        ...state,
        hasilReviewerTerakhir: {
          ...state.hasilReviewerTerakhir,
          isFetching: true,
        },
      });

    case GET_HASIL_REVIEWER_TERAKHIR_SUCCESS:
      return (state = {
        ...state,
        hasilReviewerTerakhir: {
          ...state.hasilReviewerTerakhir,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case GET_HASIL_REVIEWER_TERAKHIR_FAILURE:
      return (state = {
        ...state,
        hasilReviewerTerakhir: {
          ...state.hasilReviewerTerakhir,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case PERMANENT_MONEV_PENGABDIAN_REQUEST:
      return (state = {
        ...state,
        savePermanentSkema: {
          ...state.savePermanentSkema,
          isFetching: true,
        },
      });
    case PERMANENT_MONEV_PENGABDIAN_SUCCESS:
      return (state = {
        ...state,
        savePermanentSkema: {
          ...state.savePermanentSkema,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case PERMANENT_MONEV_PENGABDIAN_FAILURE:
      return (state = {
        ...state,
        savePermanentSkema: {
          ...state.savePermanentSkema,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case SAVE_MONEV_REQUEST:
      return (state = {
        ...state,
        save: {
          ...state.save,
          isFetching: true,
        },
      });

    case SAVE_MONEV_SUCCESS:
      return (state = {
        ...state,
        save: {
          ...state.save,
          isFetching: false,
          items: action.payload.data,
        },
      });

    case SAVE_MONEV_FAILURE:
      return (state = {
        ...state,
        save: {
          ...state.save,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });

    case GET_KOMPONEN_PENILIAN_REQUEST:
      return (state = {
        ...state,
        listKomponenPenilaian: {
          ...state.listKomponenPenilaian,
          isFetching: true,
        },
      });
    case GET_KOMPONEN_PENILIAN_SUCCESS:
      return (state = {
        ...state,
        listKomponenPenilaian: {
          ...state.listKomponenPenilaian,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_KOMPONEN_PENILIAN_FAILURE:
      return (state = {
        ...state,
        listKomponenPenilaian: {
          ...state.listKomponenPenilaian,
          isFetching: false,
        },
      });
    case GET_LIST_INSTITUSI_REVIEWER_REQUEST:
    case LIST_INSTITUSI_REQUEST:
      return (state = {
        ...state,
        listInstitusi: {
          ...state.listInstitusi,
          isFetching: true,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });

    case GET_LIST_INSTITUSI_REVIEWER_SUCCESS:
    case LIST_INSTITUSI_SUCCESS:
      return (state = {
        ...state,
        listInstitusi: {
          ...state.listInstitusi,
          isFetching: false,
          items: action.payload.data,
          selected:
            action.payload.data.length > 0 ? action.payload.data[0] : null,
        },
      });
    case GET_LIST_INSTITUSI_REVIEWER_FAILURE:
    case LIST_INSTITUSI_FAILURE:
      return (state = {
        ...state,
        listInstitusi: {
          ...state.listInstitusi,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case GET_KELOMPOK_PROPOSAL_BY_SKEMA_REQUEST:
    case LIST_SKEMA_REQUEST:
      return (state = {
        ...state,
        listSkema: {
          ...state.listSkema,
          isFetching: true,
        },
        thn_usulan: action.payload.thn_usulan,
        id_tahapan: action.payload.id_tahapan,
        id_institusi: action.payload.institusi_id,
      });
    case GET_KELOMPOK_PROPOSAL_BY_SKEMA_SUCCESS:
    case LIST_SKEMA_SUCCESS:
      return (state = {
        ...state,
        listSkema: {
          ...state.listSkema,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_KELOMPOK_PROPOSAL_BY_SKEMA_FAILURE:
    case LIST_SKEMA_FAILURE:
      return (state = {
        ...state,
        listSkema: {
          ...state.listSkema,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case CLEAN_KELOMPOK_PROPOSAL_BY_SKEMA:
      return (state = {
        ...state,
        listSkema: {
          ...state.listSkema,
          isFetching: false,
          items: [],
        },
      });
    case GET_USULAN_BY_SKEMA_REQUEST:
      return (state = {
        ...state,
        listUsulan: {
          ...state.listUsulan,
          isFetching: true,
        },
        id_skema: action.payload.skemaId,
      });
    case GET_USULAN_BY_SKEMA_SUCCESS:
      return (state = {
        ...state,
        listUsulan: {
          ...state.listUsulan,
          isFetching: false,
          item: {
            ...action.payload.data,
            usulan: action.payload.data.usulan.sort((a, b) =>
              a.nama_pengusul > b.nama_pengusul
                ? 1
                : b.nama_pengusul > a.nama_pengusul
                  ? -1
                  : 0
            ),
          },
        },
      });
    case GET_USULAN_BY_SKEMA_FAILURE:
      return (state = {
        ...state,
        listUsulan: {
          ...state.listUsulan,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case GET_USULAN_BYID_REQUEST:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: true,
        },
      });
    case GET_USULAN_BYID_SUCCESS:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case GET_USULAN_BYID_FAILURE:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case GET_POST_PUT_DEL_MONEV_INTERNAL_REQUEST:
      return (state = {
        ...state,
        monevInternal: {
          ...state.monevInternal,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_POST_PUT_DEL_MONEV_INTERNAL_SUCCESS:
      return (state = {
        ...state,
        monevInternal: {
          ...state.monevInternal,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case GET_POST_PUT_DEL_MONEV_INTERNAL_FAILURE:
      return (state = {
        ...state,
        monevInternal: {
          ...state.monevInternal,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    default:
      return state;
  }
};

export default Monev;
