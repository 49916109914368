import { getSingleFileFromBucket } from "helpers/getFileFromBucket";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import utilities from "helpers/utilities";
import { PDFDocument } from "pdf-lib";
import { pdfCreate as engine, pdfCreatePersetujuan } from "./partials";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class KosabangsaProposal2024Pdf {
    constructor(data) {
        this.data = data;
        this.indexBlobSkiped = [];
    }

    async generate() {

        return await new Promise((resolve, _reject) => {
            try {
                const pdfDoc = pdfMake.createPdf(engine(this.data));

                pdfDoc.getBlob(async blob => {
                    resolve(blob);
                })
            } catch (error) {
                _reject(error);
            }
        })
    }

    async mergingv3(docs = [], cb) {

        const pdf = await PDFDocument.create();
        let i = 0;
        // console.log({})
        try {
            for (const file of docs) {

                const bufferFile = await utilities.blobToUint8Array(file);

                if (!this.indexBlobSkiped.includes(i)) {

                    const doc = await PDFDocument.load(bufferFile, { ignoreEncryption: true, throwOnInvalidObject: true })
                    console.log({ doc });

                    if (doc) {
                        const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
                        for (const page of contentPage) {
                            pdf.addPage(page);
                        }
                    }
                }
                i++;
            }
            const pdfMerged = await pdf.saveAsBase64()
            const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, 'application/pdf');
            cb({ res: pdfMergedAsBlob, error: false })

        } catch (error) {
            console.log({ error });
            if (error.message.includes('invalid object') || error.message.includes("Failed to parse PDF document")) {
                this.indexBlobSkiped.push(i);
                cb({ res: undefined, error: true, indexBlobSkipped: i })
            } else {
                cb({ res: undefined, error: true, errMsg: error.message })
            }
        }

    }

    forceDownload(file, fileName) {
        // * Force Download

        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(file);

        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);

    }

    async getMultiplefile() {
        const data = this.data;
        let files = [];
        let dataBlob = [];
        if (data?.raw?.substansi?.file_url_substansi) {
            files.push(
                data?.raw?.substansi?.file_url_substansi,
            );
        }
        if (data?.raw?.mitra) {

            (data.raw.mitra?.mitra_sasaran || []).forEach(m => {
                files.push(
                    m.url_file_dokumen
                );
            });
            (data.raw.mitra?.mitra_kegiatan || []).forEach(m => {
                files.push(
                    m.url_file_dokumen
                );
            });
            (data.raw.mitra?.mitra_kerjasama || []).forEach(m => {
                files.push(
                    m.url_file_dokumen
                );
            });
        }

        if (data?.revisi) {
            if (data.revisi?.url_file_surat_kesanggupan) {
                files.push(
                    data.revisi?.url_file_surat_kesanggupan
                );
            }
        }

        for (const file of files) {
            const res = await getSingleFileFromBucket(
                utilities.getFriendlyURL(file)
            );
            if (!res.error) {
                dataBlob.push(res.data);
            }
        }
        return dataBlob;
    }

    async getMultiplefileKosbang2024() {
        const data = this.data;
        let files = [];
        let dataBlob = [];

        function checkEkstension(url) {
            if (url) {
                return url.split("/").pop().split(".").pop();
            }
            return null;
        }


        if (data?.raw?.substansi?.subtansi_laporan) {
            files.push(
                data?.raw?.substansi?.subtansi_laporan,
            );
        }
        if (data?.raw?.identitas?.file_pembentukan_tim_pendamping) {
            files.push(
                data?.raw?.identitas?.file_pembentukan_tim_pendamping,
            );
        }


        //Teknologi Mitra
        if (data?.raw?.teknologi_mitra
            ?.gambar_teknologi) {
            const ekstensionFile =
                checkEkstension(data?.raw?.teknologi_mitra
                    ?.gambar_teknologi);
            if (ekstensionFile === "pdf") {
                files.push(
                    data?.raw?.teknologi_mitra
                        ?.gambar_teknologi
                );
            }
        }

        if (data?.raw?.teknologi_mitra
            ?.sertifikat_kepemilikan) {
            const ekstensionFile = checkEkstension(data?.raw?.teknologi_mitra
                ?.sertifikat_kepemilikan)
            if (ekstensionFile === "pdf") {
                files.push(
                    data?.raw?.teknologi_mitra
                        ?.sertifikat_kepemilikan
                );
            }
        }

        //Mitra
        (data.raw?.teknologi_mitra?.mitra_sasaran || []).forEach(m => {
            if (m.surat_pernyataan_kerjasama) {
                const ekstensionFile = checkEkstension(m.surat_pernyataan_kerjasama);
                if (ekstensionFile === "pdf") {
                    files.push(
                        m.surat_pernyataan_kerjasama
                    );
                }
            }
            if (m.tangkapan_layar_gmap) {
                const ekstensionFile = checkEkstension(m.tangkapan_layar_gmap);
                if (ekstensionFile === "pdf") {
                    files.push(
                        m.tangkapan_layar_gmap
                    );
                }

            }
        });
        (data.raw?.teknologi_mitra?.mitra_pemerintah || []).forEach(m => {
            if (m.surat_pernyataan_kerjasama) {
                const ekstensionFile = checkEkstension(m.surat_pernyataan_kerjasama);
                if (ekstensionFile === "pdf") {
                    files.push(
                        m.surat_pernyataan_kerjasama
                    );
                }
            }
        });
        (data.raw?.teknologi_mitra?.mitra_kerjasama || []).forEach(m => {
            if (m.surat_pernyataan_sharing_dana) {
                const ekstensionFile = checkEkstension(m.surat_pernyataan_sharing_dana);
                if (ekstensionFile === "pdf") {
                    files.push(
                        m.surat_pernyataan_sharing_dana
                    );
                }
            }
        });

        if (data?.raw?.teknologi_mitra
            ?.mou_kerjasama) {
            const ekstensionFile = checkEkstension(data?.raw?.teknologi_mitra?.mou_kerjasama);
            if (ekstensionFile === "pdf") {

                files.push(
                    data?.raw?.teknologi_mitra?.mou_kerjasama,
                );
            }
        }

        if (data?.raw?.teknologi_mitra
            ?.surat_pernyataan_orisinalitas) {
            const ekstensionFile = checkEkstension(data?.raw?.teknologi_mitra?.surat_pernyataan_orisinalitas);
            if (ekstensionFile === "pdf") {

                files.push(
                    data?.raw?.teknologi_mitra?.surat_pernyataan_orisinalitas,
                );
            }
        }

        //TODO:Coming SOON
        // if (data?.revisi) {
        //     if (data.revisi?.url_file_surat_kesanggupan) {
        //         files.push(
        //             data.revisi?.url_file_surat_kesanggupan
        //         );
        //     }
        // }

        for (const file of files) {
            const res = await getSingleFileFromBucket(
                utilities.getFriendlyURL(file)
            );
            if (!res.error) {
                dataBlob.push(res.data);
            }
        }
        return dataBlob;
    }

    async generatePersetujuan() {

        return await new Promise((resolve, _reject) => {
            try {
                const pdfDoc = pdfMake.createPdf(pdfCreatePersetujuan(this.data));

                pdfDoc.getBlob(async blob => {
                    resolve(blob);
                })
            } catch (error) {
                _reject(error);
            }
        })
    }

}

export default KosabangsaProposal2024Pdf;
