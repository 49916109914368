import {
  CATATAN_HARIAN_REQUEST,
  CATATAN_HARIAN_SUCCESS,
  CATATAN_HARIAN_FAILURE,
  CATATAN_HARIAN_CREATE_REQUEST,
  CATATAN_HARIAN_DELETE_REQUEST,
  CATATAN_HARIAN_EDIT_REQUEST,
  CATATAN_HARIAN_EDIT_SUCCESS,
  CATATAN_HARIAN_CREATE_SUCCESS,
  CATATAN_HARIAN_DELETE_SUCCESS,
  CATATAN_HARIAN_UPLOAD_SUCCESS,
  CATATAN_HARIAN_UPLOAD_REQUEST,
  CATATAN_HARIAN_DELETE_BERKAS_REQUEST,
  CATATAN_HARIAN_DELETE_BERKAS_SUCCESS,
  CRUD_CATATAN_HARIAN_V2_REQUEST,
  CRUD_CATATAN_HARIAN_V2_SUCCESS,
  CRUD_CATATAN_HARIAN_V2_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  errors: null,
  items: [],
  itemSelected: {},
  catatanharianV2: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
};

const catatanharian = (state = initialState, action) => {
  switch (action.type) {
    case CATATAN_HARIAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case CATATAN_HARIAN_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case CATATAN_HARIAN_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case CATATAN_HARIAN_EDIT_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case CATATAN_HARIAN_UPLOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CATATAN_HARIAN_DELETE_BERKAS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CATATAN_HARIAN_EDIT_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id
      );
      if (selectedIndex !== -1) items[selectedIndex] = action.payload;
      return {
        ...state,
        isFetching: false,
        items,
      };
    }
    case CATATAN_HARIAN_DELETE_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id
      );
      if (selectedIndex !== -1) items.splice(selectedIndex, 1);
      return {
        ...state,
        isFetching: false,
        items,
      };
    }
    case CATATAN_HARIAN_CREATE_SUCCESS: {
      const items = state.items;
      if (action.payload) items.unshift(action.payload);

      return {
        ...state,
        isFetching: false,
        items,
        itemSelected: action.payload,
      };
    }
    case CATATAN_HARIAN_UPLOAD_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id_catatan_harian
      );
      if (selectedIndex !== -1) {
        if (items[selectedIndex].berkas_catatan_harian) {
          items[selectedIndex].berkas_catatan_harian.unshift(action.payload);
        } else {
          items[selectedIndex].berkas_catatan_harian = [action.payload];
        }
      }
      return {
        ...state,
        isFetching: false,
        items,
      };
    }
    case CATATAN_HARIAN_DELETE_BERKAS_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id_catatan_harian
      );
      if (selectedIndex !== -1) {
        const selectedIndexBerkas = items[
          selectedIndex
        ].berkas_catatan_harian.findIndex(
          item => item.id === action.payload.id
        );
        if (selectedIndex !== -1) {
          items[selectedIndex].berkas_catatan_harian.splice(
            selectedIndexBerkas,
            1
          );
        }
      }
      return {
        ...state,
        isFetching: false,
        items,
      };
    }
    case CATATAN_HARIAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload ?? [],
      };
    case CATATAN_HARIAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // catatan harian v2
    case CRUD_CATATAN_HARIAN_V2_REQUEST:
      return (state = {
        ...state,
        catatanharianV2: {
          ...state.catatanharianV2,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case CRUD_CATATAN_HARIAN_V2_SUCCESS:
      return (state = {
        ...state,
        catatanharianV2: {
          ...state.catatanharianV2,
          isFetching: false,
          items: action.payload.data?.length > 0 ? action.payload.data : [],
          item:
            Object.keys(action.payload.data || {})?.length > 0
              ? action.payload.data
              : {},
        },
      });
    case CRUD_CATATAN_HARIAN_V2_FAILURE:
      return (state = {
        ...state,
        catatanharianV2: {
          ...state.catatanharianV2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    default:
      return state;
  }
};

export default catatanharian;
