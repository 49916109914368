import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";

import {
  SBK_REQUEST,
  TEMA_REQUEST,
  TOPIK_REQUEST,
  DOSEN_REQUEST,
  RUMPUN_ILMU_REQUEST,
  BIDANG_FOKUS_REQUEST,
  TARGET_AKHIR_REQUEST,
  UPLOAD_DOKUMEN_REQUEST,
  PERAN_PERSONIL_REQUEST,
  TOPIK_UNGGULAN_REQUEST,
  SKEMA_KEGIATAN_REQUEST,
  BIDANG_UNGGULAN_REQUEST,
  JENIS_LUARAN_REQUEST,
  GET_PENELITIAN_REQUEST,
  KATEGORI_LUARAN_REQUEST,
  KATEGORI_PENELITIAN_REQUEST,
  KELOMPOK_MAKRO_RISET_REQUEST,
  KATEGORI_INDIKATOR_TKT_REQUEST,
  INDIKATOR_TKT_BY_KATEGORI_REQUEST,
  KELOMPOK_RAB_BIAYA_REQUEST,
  RAB_KOMPONEN_REQUEST,
  GET_KETERLIBATAN_MAHASISWA_REQUEST,
  CREATE_KETERLIBATAN_MAHASISWA_REQUEST,
  UPDATE_KETERLIBATAN_MAHASISWA_REQUEST,
  DELETE_KETERLIBATAN_MAHASISWA_REQUEST,
  GET_JUMLAH_MAHASISWA_TERLIBAT_REQUEST,
  GET_KATEGORI_SBK_REQUEST,
  GET_PRIORITAS_RISET_REQUEST,
  GET_MAHASISWA_REQUEST,
  GET_RULE_SKEMA_REQUEST,
  GET_ELIGIBILITAS_DOSEN_REQUEST,
  SAVE_IDENTITAS_USULAN_REQUEST,
  GET_AVAILABLE_SKEMA_REQUEST,
  GET_ELIGIBILITY_REQUEST,
  GET_LIST_USULAN_PENELITIAN_REQUEST,
  GET_USULAN_PENELITIAN_BYID_REQUEST,
  SAVE_SUBTANSI_USULAN_REQUEST,
  SAVE_RAB_USULAN_REQUEST,
  GET_LIST_NEGARA_REQUEST,
  UPDATE_IDENTITAS_USULAN_REQUEST,
  SAVE_MITRA_USULAN_REQUEST,
  UPDATE_MITRA_USULAN_REQUEST,
  UPDATE_SUBTANSI_USULAN_REQUEST,
  GET_TEMA_TEMATIK_REQUEST,
  GET_TARGET_CAPAIAN_LUARAN_REQUEST,
  GET_LIST_RIRN_REQUEST,
  POST_STEP_1_IDENTITAS_PENGABDIAN_REQUEST,
  PUT_ALL_STEP_USULAN_PENGABDIAN_REQUEST,
  UPDATE_USULAN_PENELITIAN_REQUEST,
  KELOMPOK_SKEMA_REQUEST,
  VALIDASI_KEANGGOTAAN_DOSEN_REQUEST,
  GET_LIST_KOTA_REQUEST,
  GET_LIST_PROVINSI_REQUEST,
  DEL_PENELITIAN_REQUEST,
  MHS_SET_ITEM,
  GET_OPTION_MITRA_KELOMPOK_REQUEST,
  GET_OPTION_MITRA_JENIS_MITRA_REQUEST,
  UPDATE_RAB_USULAN_REQUEST,
  GET_WHITELIST_USULAN_REQUEST,
  GET_LIST_KERJASAMA_LUAR_NEGERI_REQUEST,
  SAVE_MINAT_KATALIS_REQUEST,
  GET_MINAT_KATALIS_REQUEST,
  GET_DETAILS_MINAT_KATALIS_REQUEST,
  DELETE_MINAT_KATALIS_REQUEST,
  APPROVAL_MEMBER_MINAT_KATALIS_REQUEST,
  APPROVAL_PUSAT_MINAT_KATALIS_REQUEST,
} from "./actionTypes";
// Action
import {
  getSbkFailure,
  getSbkSuccess,
  getTemaFailure,
  getTemaSuccess,
  getTopikFailure,
  getTopikSuccess,
  getDosenSuccess,
  getDosenFailure,
  getRumpunIlmuFailure,
  getRumpunIlmuSuccess,
  getBidangFokusFailure,
  getBidangFokusSuccess,
  uploadDokumenSuccess,
  uploadDokumenFailure,
  getPeranPersonilSuccess,
  getPeranPersonilFailure,
  getSkemaKegiatanSuccess,
  getSkemaKegiatanFailure,
  getTargetAkhirTKTSuccess,
  getTargetAkhirTKTFailure,
  getTopikUnggulanSuccess,
  getTopikUnggulanFailure,
  getBidangUnggulanSuccess,
  getBidangUnggulanFailure,
  getJenisLuaranSuccess,
  getJenisLuaranFailure,
  getPenelitianSuccess,
  getPenelitianFailure,
  getKategoriLuaranSuccess,
  getKategoriLuaranFailure,
  getKelompokMakroRisetSuccess,
  getKelompokMakroRisetFailure,
  getKategoriPenelitianFailure,
  getKategoriPenelitianSuccess,
  getKategoriIndikatorTKTFailure,
  getKategoriIndikatorTKTSuccess,
  getIndikatorTKTByKategoriFailure,
  getIndikatorTKTByKategoriSuccess,
  getKelompokRabBiayaFailure,
  getKelompokRabBiayaSuccess,
  getRABKomponenSuccess,
  getRABKomponenFailure,
  getKeterlibatanMahasiswaSuccess,
  getKeterlibatanMahasiswaFailure,
  createKeterlibatanMahasiswaSuccess,
  createKeterlibatanMahasiswaFailure,
  updateKeterlibatanMahasiswaSuccess,
  updateKeterlibatanMahasiswaFailure,
  deleteKeterlibatanMahasiswaSuccess,
  deleteKeterlibatanMahasiswaFailure,
  getJumlahMahasiswaTerlibatSuccess,
  getJumlahMahasiswaTerlibatFailure,
  getKategoriSBKSuccess,
  getKategoriSBKFailure,
  getPrioritasRisetSuccess,
  getPrioritasRisetFailure,
  getMahasiswaSuccess,
  getMahasiswaFailure,
  getKelompokSkemaFailure,
  getKelompokSkemaSuccess,
  getRuleSkemaSuccess,
  getRuleSkemaFailure,
  getEligibilitasDosenFailure,
  getEligibilitasDosenSuccess,
  saveIdentitasUsulanSuccess,
  saveIdentitasUsulanFailure,
  getEligibilitySuccess,
  getEligibilityFailure,
  getAvailableSkemaSuccess,
  getAvailableSkemaFailure,
  getListUsulanPenelitianSuccess,
  getListUsulanPenelitianFailure,
  saveSubtansiUsulanSuccess,
  saveSubtansiUsulanFailure,
  saveRABUsulanSuccess,
  saveRABUsulanFailure,
  updateRABUsulanFailure,
  updateRABUsulanSuccess,
  getListNegaraFailure,
  getListNegaraSuccess,
  getUsulanPenelitianByIdSuccess,
  getUsulanPenelitianByIdFailure,
  updateIdentitasUsulanSuccess,
  updateIdentitasUsulanFailure,
  saveMitraUsulanSuccess,
  saveMitraUsulanFailure,
  updateMitraUsulanSuccess,
  updateMitraUsulanFailure,
  updateSubtansiUsulanSuccess,
  updateSubtansiUsulanFailure,
  getTargetCapaianLuaranSuccess,
  updateUsulanPenelitanSuccess,
  updateUsulanPenelitanFailure,
  getListTemaPrioritasTematikSuccess,
  getListTemaPrioritasTematikFailure,
  getListBidangFokusRirnSuccess,
  getListBidangFokusRirnFailure,
  postStep1IdentitasPengabdianSuccess,
  postStep1IdentitasPengabdianFailure,
  putAllStepUsulanPengabdianSuccess,
  putAllStepUsulanPengabdianFailure,
  getValidasiKeanggotanDosenSuccess,
  getValidasiKeanggotanDosenFailure,
  getListProvinsiSuccess,
  getListProvinsiFailure,
  getListKotaSuccess,
  getListKotaFailure,
  deletePenelitianSuccess,
  deletePenelitianFailure,
  setMahasiswaItem,
  getListOptionMitraKelompokSuccess,
  getListOptionMitraKelompokFailure,
  getListOptionMitraJenisMitraSuccess,
  getListOptionMitraJenisMitraFailure,
  getWhiteListUsulanFailure,
  getWhiteListUsulanSuccess,
  getListProgramKerjasamaLnSuccess,
  getListProgramKerjasamaLnFailure,
  saveMinatKatalisFailure,
  saveMinatKatalisSuccess,
  getListMinatKatalisSuccess,
  getListMinatKatalisFailure,
  getDetailsMinatKatalisSuccess,
  getDetailsMinatKatalisFailure,
  deleteMinatKatalisSuccess,
  deleteMinatKatalisFailure,
  approvalMemberMinatKatalisSuccess,
  approvalMemberMinatKatalisFailure,
  approvalPusatMinatKatalisSuccess,
  approvalPusatMinatKatalisFailure
} from "./action";

function* deleteMinatKatalis({ id }) {
  try {
    const response = yield call(backend.deleteMinatKatalis, id);
    if (response.data.code == 200) {
      yield put(deleteMinatKatalisSuccess(id));

      toast.success("Berhasil menghapus data!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);

    yield put(deleteMinatKatalisFailure(message, error));
  }
}

function* approvalPusatMinatKatalis({ request, cb = () => { } }) {
  try {
    const response = yield call(backend.approvalPusatKatalis, request);
    if (response.data.code == 200) {
      yield put(approvalPusatMinatKatalisSuccess(response.data.data));
      if (request.approval !== 2) {
        toast.success("Berhasil " + (request.approval === 1 ? "Menyetujui" : "Menolak") + " Program Minat KATALIS");
      }
      cb(response.data.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(approvalPusatMinatKatalisFailure(message, error));
  }
}

function* approvalMemberMinatKatalis({ request, cb = () => { } }) {
  try {
    const response = yield call(backend.approvalMemberKatalis, request);
    if (response.data.code == 200) {
      yield put(approvalMemberMinatKatalisSuccess(response.data.data));
      toast.success("Anda telah " + (request.approval === 1 ? "Menyetujui" : "Menolak") + " Program Minat KATALIS");
      cb(response.data.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(approvalMemberMinatKatalisFailure(message, error));
  }
}

function* getDetailsMinatKatalis({ request, cb }) {
  try {
    const response = yield call(backend.getMinatKatalis, request);
    if (response.data.code == 200) {
      yield put(getDetailsMinatKatalisSuccess(response.data.data));
      cb(response.data.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(getDetailsMinatKatalisFailure(message, error));
  }
}

function* saveMinatKatalis({ request, cb }) {
  try {
    const response = yield call(backend.saveMinatKatalis, request);
    if (response.data.code == 200) {
      yield put(saveMinatKatalisSuccess(response.data));
      cb(response.data.data);
      toast.success("Berhasil menyimpan data!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(saveMinatKatalisFailure(message, error));
  }
}

function* getListMinatKatalis({ request }) {
  try {
    const response = yield call(backend.getMinatKatalis, request);
    if (response.data.code == 200) {
      yield put(getListMinatKatalisSuccess(response.data.data));

    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);

    yield put(getListMinatKatalisFailure(message, error));
  }
}

function* getListProgramKerjasamaLn() {
  try {
    const response = yield call(backend.getProgramKerjasamaLn);
    if (response.data.code == 200) {
      yield put(getListProgramKerjasamaLnSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;

    toast.error(message);
    yield put(getListProgramKerjasamaLnFailure(message, error));
  }
}

function* getListNegara() {
  try {
    const response = yield call(backend.getListNegara);
    if (response.data.code == 200) {
      yield put(getListNegaraSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;

    toast.error(message);
    yield put(getListNegaraFailure(message, error));
  }
}

function* getWhiteListUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.getWhiteListUsulan, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
      yield put(getWhiteListUsulanSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    toast.error(message);
    yield put(getWhiteListUsulanFailure(message, error));
  }
}

function* getListUsulanPenelitian({ cb }) {
  try {
    const response = yield call(backend.getListUsulan);
    if (response.data.code == 200) {
      yield put(getListUsulanPenelitianSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getListUsulanPenelitianFailure(message, error));
  }
}

function* getUsulanPenelitianById({ id, cb }) {

  try {
    const response = yield call(backend.getListUsulan, id);
    if (response.data.code == 200) {
      yield put(getUsulanPenelitianByIdSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getUsulanPenelitianByIdFailure(message, error));
  }
}

function* deleteUsulanPenelitian({ id, cb }) {
  try {
    const response = yield call(backend.deleteUsulanPenelitian, id);
    if (response.data.code == 200) {
      yield put(deletePenelitianSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(deletePenelitianFailure(message, error));
  }
}

function* saveIdentitasUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.saveIdentitasUsulan, payload);
    if (response.data.code == 200) {
      yield put(saveIdentitasUsulanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(saveIdentitasUsulanFailure(message, error));
  }
}

function* updateUsulanPenelitan({ payload, cb }) {
  try {
    const response = yield call(backend.updateUsulanPenelitan, payload);
    if (response.data.code == 200) {
      yield put(updateUsulanPenelitanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(updateUsulanPenelitanFailure(message, error));
  }
}

function* updateIdentitasUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.updateIdentitasUsulan, payload);
    if (response.data.code == 200) {
      yield put(updateIdentitasUsulanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(updateIdentitasUsulanFailure(message, error));
  }
}

function* saveSubtansiUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.saveSubtansiUsulan, payload);
    if (response.data.code == 200) {
      yield put(saveSubtansiUsulanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(saveSubtansiUsulanFailure(message, error));
  }
}

function* updateSubtansiUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.updateSubtansiUsulan, payload);
    if (response.data.code == 200) {
      yield put(updateSubtansiUsulanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(updateSubtansiUsulanFailure(message, error));
  }
}

function* saveMitraUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.saveMitraUsulan, payload);
    if (response.data.code == 200) {
      yield put(saveMitraUsulanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(saveMitraUsulanFailure(message, error));
  }
}

function* updateMitraUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.updateMitraUsulan, payload);
    if (response.data.code == 200) {
      yield put(updateMitraUsulanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(updateMitraUsulanFailure(message, error));
  }
}

function* saveRABUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.saveRABUsulan, payload);
    if (response.data.code == 200) {
      yield put(saveRABUsulanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(saveRABUsulanFailure(message, error));
  }
}

function* updateRABUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.updateRABUsulan, payload);
    if (response.data.code == 200) {
      yield put(updateRABUsulanSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(updateRABUsulanFailure(message, error));
  }
}

function* getEligibility({ payload, cb }) {
  try {
    const response = yield call(backend.getEligibility, payload);
    if (response.data.code == 200) {
      yield put(getEligibilitySuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    // toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getEligibilityFailure(message, error));
  }
}

function* getAvailableSkema({ payload }) {
  try {
    const response = yield call(
      backend.getAvailableSkemaByEligibility,
      payload
    );
    if (response.data.code == 200) {
      yield put(getAvailableSkemaSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;

    // toast.error(message);
    yield put(getAvailableSkemaFailure(message, error));
  }
}

function* getEligibilitasDosen({ payload, cb }) {
  try {
    const response = yield call(backend.getEligibilitasDosen, payload);
    if (response.data.code == 200) {
      yield put(getEligibilitasDosenSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
    cb(response.data);
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    // toast.error(message);
    yield put(getEligibilitasDosenFailure(message, error));
  }
}

function* getRuleSkema({ payload, cb }) {
  try {
    const response = yield call(backend.getRuleSkema, payload);
    if (response.data.code == 200) {
      yield put(getRuleSkemaSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
    cb(response.data);
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getRuleSkemaFailure(message, error));
  }
}

function* getKelompokSkema({ payload, cb }) {
  try {
    const response = yield call(backend.getKelompokSkema, payload);
    if (response.data.code == 200) {
      yield put(getKelompokSkemaSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
    cb(response.data);
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getKelompokSkemaFailure(message, error));
  }
}

function* getMahasiswa({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getMahasiswa, payload);
    if (response.data.code == 200) {
      yield put(getMahasiswaSuccess(response.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const { message } = error;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getMahasiswaFailure(message, error));
  }
}

function* setMahasiswaItemSg({ payload }) {
  try {
    yield put(setMahasiswaItem(payload.data));
  } catch (error) {
    // const { message } = error;
    // yield put(setMahasiswaItemFailure(message, error));
  }
}

function* getKategoriSBK({ payload }) {
  try {
    const response = yield call(backend.getKategoriSBK, payload);
    if (response.data.code == 200) {
      yield put(getKategoriSBKSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getKategoriSBKFailure(message, error));
  }
}

function* getPrioritasRiset({ payload }) {
  try {
    const response = yield call(backend.getPrioritasRiset, payload);
    if (response.data.code == 200) {
      yield put(getPrioritasRisetSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getPrioritasRisetFailure(message, error));
  }
}

function* getKategoriPenelitian({ payload }) {
  try {
    const response = yield call(backend.getKategoriPenelitian, payload);
    if (response.data.code == 200) {
      yield put(getKategoriPenelitianSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getKategoriPenelitianFailure(message, error));
  }
}

function* getKategoriIndikatorTKT({ payload }) {
  try {
    const response = yield call(backend.getKategoriIndikatorTKT, payload);
    if (response.data.code == 200) {
      yield put(getKategoriIndikatorTKTSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getKategoriIndikatorTKTFailure(message, error));
  }
}

function* getIndikatorTKTByKategori({ payload, cb }) {
  try {
    const response = yield call(backend.getIndikatorTKTByKategori, payload);

    if (response.data.code == 200) {
      yield put(
        getIndikatorTKTByKategoriSuccess({
          level: payload.level,
          data: response.data.data,
        })
      );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getIndikatorTKTByKategoriFailure(message, error));
  }
}

function* getTema({ payload }) {
  try {
    const response = yield call(backend.getTema, payload);

    if (response.data.code == 200) {
      yield put(
        getTemaSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getTemaFailure(message, error));
  }
}

function* getTopik({ payload }) {
  try {
    const response = yield call(backend.getTopik, payload);

    if (response.data.code == 200) {
      yield put(
        getTopikSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getTopikFailure(message, error));
  }
}

function* getSbk({ payload }) {
  try {
    const response = yield call(backend.getSbk, payload);

    if (response.data.code == 200) {
      yield put(
        getSbkSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getSbkFailure(message, error));
  }
}

function* getBidangFokus({ payload }) {
  try {
    const response = yield call(backend.getBidangFokus, payload);

    if (response.data.code == 200) {
      yield put(
        getBidangFokusSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getBidangFokusFailure(message, error));
  }
}

function* getRumpunIlmu({ payload, cb }) {
  try {
    const response = yield call(backend.getRumpunIlmu, payload);

    if (response.data.code == 200) {
      yield put(
        getRumpunIlmuSuccess({
          level: payload.level,
          data: response.data.data,
        })
      );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getRumpunIlmuFailure(message, error));
  }
}

function* getTargetAkhirTKT({ payload }) {
  try {
    const response = yield call(backend.getTargetAkhirTKT, payload);

    if (response.data.code == 200) {
      yield put(
        getTargetAkhirTKTSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getTargetAkhirTKTFailure(message, error));
  }
}

function* getSkemaKegiatan({ payload }) {
  try {
    const response = yield call(backend.getSkemaKegiatan, payload);

    if (response.data.code == 200) {
      yield put(
        getSkemaKegiatanSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getSkemaKegiatanFailure(message, error));
  }
}

function* getBidangUnggulan({ payload }) {
  try {
    const response = yield call(backend.getBidangUnggulan, payload);

    if (response.data.code == 200) {
      yield put(
        getBidangUnggulanSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getBidangUnggulanFailure(message, error));
  }
}

function* getTopikUnggulan({ payload }) {
  try {
    const response = yield call(backend.getTopik, payload);

    if (response.data.code == 200) {
      yield put(
        getTopikUnggulanSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getTopikUnggulanFailure(message, error));
  }
}

function* getDosen({ payload, cb }) {
  try {
    const response = yield call(backend.getDosen, payload);

    if (response.data.code == 200) {
      yield put(
        getDosenSuccess({
          data: response.data.data,
        })
      );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb({
      code: 500,
      message: message,
    });
    yield put(getDosenFailure(message, error));
  }
}

function* validasiKeanggotaanDosen({ payload, cb }) {
  try {
    const response = yield call(backend.validasiKeanggotaanDosen, payload);
    if (response.data.code == 200) {
      yield put(
        getValidasiKeanggotanDosenSuccess({
          data: response.data.data,
        })
      );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb({
      code: 500,
      message: message,
    });
    yield put(getValidasiKeanggotanDosenFailure(message, error));
  }
}

function* getPeranPersonil({ payload }) {
  try {
    const response = yield call(backend.getPeranPersonil, payload);

    if (response.data.code == 200) {
      yield put(
        getPeranPersonilSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getPeranPersonilFailure(message, error));
  }
}

function* getKelompokMakroRiset({ payload }) {
  try {
    const response = yield call(backend.getKelompokMakroRiset, payload);

    if (response.data.code == 200) {
      yield put(
        getKelompokMakroRisetSuccess({
          data: response.data.data,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getKelompokMakroRisetFailure(message, error));
  }
}

function* getJenisLuaran({ payload, cb }) {
  try {
    const response = yield call(backend.getJenisLuaran, payload);

    if (response.data.code == 200) {
      yield put(
        getJenisLuaranSuccess({
          data: response.data.data,
        })
      );
      cb({
        success: true,
        data: response.data.data,
      });
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getJenisLuaranFailure(message, error));
  }
}

function* getTargetCapaianLuaran({ payload, cb }) {
  try {
    const response = yield call(backend.getTargetCapaianLuaran, payload);

    if (response.data.code == 200) {
      yield put(
        getTargetCapaianLuaranSuccess({
          data: response.data.data,
        })
      );
      cb({
        success: true,
        data: response.data.data,
      });
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getTargetJenisLuaranFailure(message, error));
  }
}

function* getKategoriLuaran({ payload, cb }) {
  try {
    const response = yield call(backend.getKategoriLuaran, payload);

    if (response.data.code == 200) {
      yield put(
        getKategoriLuaranSuccess({
          data: response.data.data,
        })
      );
      cb({
        success: true,
        data: response.data.data,
      });
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(getKategoriLuaranFailure(message, error));
  }
}

function* uploadDokumen({ payload, cb }) {
  try {
    const response = yield call(backend.uploadDokumen, payload);

    if (response.data.code == 200) {
      yield put(
        uploadDokumenSuccess({
          data: response.data.data,
        })
      );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(uploadDokumenFailure(message, error));
    cb(null);
  }
}

function* getPenelitian({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getPenelitian, payload);
    if (response.data.code == 200) {
      let state = "";
      const isGetById = response.config.url.split("/usulan/").length;
      if (isGetById > 1) {
        state = "detail";
      }
      cb(response.data.data);
      yield put(
        getPenelitianSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getPenelitianFailure(message, error));
  }
}

function* getKelompokRabBiaya({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getKelompokRabBiaya, payload);
    if (response.data.code == 200) {
      let state = "";
      const isGetById = 0;
      if (isGetById > 1) {
        state = "detail";
      }
      cb(response.data.data);
      yield put(
        getKelompokRabBiayaSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(getKelompokRabBiayaFailure(message, error));
  }
}

function* getRABKomponen({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getRABKomponen, payload);
    if (response.data.code == 200) {
      let state = "";
      const isGetById = 0;
      if (isGetById > 1) {
        state = "detail";
      }
      cb(response.data.data);
      yield put(
        getRABKomponenSuccess({
          data: response.data.data,
          state,
        })
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    toast.error(message);
    yield put(getRABKomponenFailure(message, error));
  }
}

function* getKeterlibatanMahasiswa({ payload, cb = () => { } }) {
  try {
    const response = yield call(backend.getKeterlibatanMahasiswa, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
      yield put(getKeterlibatanMahasiswaSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    cb(null);
    const message = error.message;
    toast.error(message);
    yield put(getKeterlibatanMahasiswaFailure(message, error));
  }
}

function* createKeterlibatanMahasiswa({ payload }) {
  try {
    const response = yield call(backend.createKeterlibatanMahasiswa, payload);
    if (response.data.code == 200) {
      yield put(createKeterlibatanMahasiswaSuccess(response.data));
      toast.success("Data Berhasil Disimpan");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(createKeterlibatanMahasiswaFailure(message, error));
  }
}

function* updateKeterlibatanMahasiswa({ payload }) {
  try {
    const response = yield call(backend.updateKeterlibatanMahasiswa, payload);
    if (response.data.code == 200) {
      yield put(updateKeterlibatanMahasiswaSuccess(response.data));
      toast.success("Berhasil Mengubah Data");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(updateKeterlibatanMahasiswaFailure(message, error));
  }
}

function* deleteKeterlibatanMahasiswa({ id }) {
  try {
    const response = yield call(backend.deleteKeterlibatanMahasiswa, id);
    if (response.data.code == 200) {
      yield put(deleteKeterlibatanMahasiswaSuccess(id));
      toast.success("Berhasil Menghapus Data");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(deleteKeterlibatanMahasiswaFailure(message, error));
  }
}

function* getJumlahMahasiswaTerlibat({ idUsulanKegiatan }) {
  try {
    const response = yield call(
      backend.getJumlahMahasiswaTerlibat,
      idUsulanKegiatan
    );
    if (response.data.code == 200) {
      yield put(getJumlahMahasiswaTerlibatSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getJumlahMahasiswaTerlibatFailure(message, error));
  }
}
//pengabdian start
// pengabdian get list tema prioritas
function* getListTemaPrioritasTematik() {
  try {
    const response = yield call(backend.getListTemaPrioritasTematik);
    if (response.data.code == 200) {
      yield put(getListTemaPrioritasTematikSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListTemaPrioritasTematikFailure(message, error));
  }
}
// pengabdian get list rirn
function* getListBidangFokusRirn() {
  try {
    const response = yield call(backend.getListBidangFokusRirn);
    if (response.data.code == 200) {
      yield put(getListBidangFokusRirnSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListBidangFokusRirnFailure(message, error));
  }
}
function* postStep1IdentitasPengabdian({ payload, cb }) {
  try {
    const response = yield call(backend.postStep1IdentitasPengabdian, payload);
    if (response.data.code == 200) {
      yield put(postStep1IdentitasPengabdianSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(postStep1IdentitasPengabdianFailure(message, error));
  }
}

function* putAllStepUsulanPengabdian({ payload, cb }) {
  try {
    const response = yield call(backend.putAllStepUsulanPengabdian, payload);
    if (response.data.code == 200) {
      yield put(putAllStepUsulanPengabdianSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb({
      success: false,
      message,
    });
    toast.error(message);
    yield put(putAllStepUsulanPengabdianFailure(message, error));
  }
}
function* getListProvinsi() {
  try {
    const response = yield call(backend.getListProvinsi);
    if (response.data.code == 200) {
      yield put(getListProvinsiSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;

    toast.error(message);
    yield put(getListProvinsiFailure(message, error));
  }
}
function* getListKota() {
  try {
    const response = yield call(backend.getListKota);
    if (response.data.code == 200) {
      yield put(getListKotaSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;

    toast.error(message);
    yield put(getListKotaFailure(message, error));
  }
}
function* getListOptionMitraKelompok({ payload }) {
  try {
    const response = yield call(backend.getListOptionMitraKelompok, payload);
    if (response.data.code == 200) {
      yield put(getListOptionMitraKelompokSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListOptionMitraKelompokFailure(message, error));
  }
}
function* getListOptionMitraJenisMitra({ payload }) {
  try {
    const response = yield call(backend.getListOptionMitraJenisMitra, payload);
    if (response.data.code == 200) {
      yield put(getListOptionMitraJenisMitraSuccess(response.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListOptionMitraJenisMitraFailure(message, error));
  }
}
function* usulanPenelitian() {
  yield takeEvery(SBK_REQUEST, getSbk);
  yield takeEvery(TEMA_REQUEST, getTema);
  yield takeEvery(DOSEN_REQUEST, getDosen);
  yield takeEvery(TOPIK_REQUEST, getTopik);
  yield takeEvery(RUMPUN_ILMU_REQUEST, getRumpunIlmu);
  yield takeEvery(GET_MAHASISWA_REQUEST, getMahasiswa);
  yield takeEvery(JENIS_LUARAN_REQUEST, getJenisLuaran);
  yield takeEvery(RAB_KOMPONEN_REQUEST, getRABKomponen);
  yield takeEvery(GET_RULE_SKEMA_REQUEST, getRuleSkema);
  yield takeEvery(BIDANG_FOKUS_REQUEST, getBidangFokus);
  yield takeEvery(UPLOAD_DOKUMEN_REQUEST, uploadDokumen);
  yield takeEvery(GET_PENELITIAN_REQUEST, getPenelitian);
  yield takeEvery(GET_LIST_NEGARA_REQUEST, getListNegara);
  yield takeEvery(SAVE_RAB_USULAN_REQUEST, saveRABUsulan);
  yield takeEvery(UPDATE_RAB_USULAN_REQUEST, updateRABUsulan);
  yield takeEvery(GET_ELIGIBILITY_REQUEST, getEligibility);
  yield takeEvery(TARGET_AKHIR_REQUEST, getTargetAkhirTKT);
  yield takeEvery(SKEMA_KEGIATAN_REQUEST, getSkemaKegiatan);
  yield takeEvery(PERAN_PERSONIL_REQUEST, getPeranPersonil);
  yield takeEvery(TOPIK_UNGGULAN_REQUEST, getTopikUnggulan);
  yield takeEvery(GET_KATEGORI_SBK_REQUEST, getKategoriSBK);
  yield takeEvery(KELOMPOK_SKEMA_REQUEST, getKelompokSkema);
  yield takeEvery(SAVE_MITRA_USULAN_REQUEST, saveMitraUsulan);
  yield takeEvery(KATEGORI_LUARAN_REQUEST, getKategoriLuaran);
  yield takeEvery(BIDANG_UNGGULAN_REQUEST, getBidangUnggulan);
  yield takeEvery(DEL_PENELITIAN_REQUEST, deleteUsulanPenelitian);
  yield takeEvery(UPDATE_MITRA_USULAN_REQUEST, updateMitraUsulan);
  yield takeEvery(GET_PRIORITAS_RISET_REQUEST, getPrioritasRiset);
  yield takeEvery(GET_AVAILABLE_SKEMA_REQUEST, getAvailableSkema);
  yield takeEvery(KELOMPOK_RAB_BIAYA_REQUEST, getKelompokRabBiaya);
  yield takeEvery(SAVE_SUBTANSI_USULAN_REQUEST, saveSubtansiUsulan);
  yield takeEvery(SAVE_IDENTITAS_USULAN_REQUEST, saveIdentitasUsulan);
  yield takeEvery(KATEGORI_PENELITIAN_REQUEST, getKategoriPenelitian);
  yield takeEvery(KELOMPOK_MAKRO_RISET_REQUEST, getKelompokMakroRiset);
  yield takeEvery(UPDATE_SUBTANSI_USULAN_REQUEST, updateSubtansiUsulan);
  yield takeEvery(GET_ELIGIBILITAS_DOSEN_REQUEST, getEligibilitasDosen);
  yield takeEvery(UPDATE_IDENTITAS_USULAN_REQUEST, updateIdentitasUsulan);
  yield takeEvery(UPDATE_USULAN_PENELITIAN_REQUEST, updateUsulanPenelitan);
  yield takeEvery(KATEGORI_INDIKATOR_TKT_REQUEST, getKategoriIndikatorTKT);
  yield takeEvery(GET_TARGET_CAPAIAN_LUARAN_REQUEST, getTargetCapaianLuaran);
  yield takeEvery(GET_LIST_USULAN_PENELITIAN_REQUEST, getListUsulanPenelitian);
  yield takeEvery(GET_USULAN_PENELITIAN_BYID_REQUEST, getUsulanPenelitianById);
  yield takeEvery(GET_KETERLIBATAN_MAHASISWA_REQUEST, getKeterlibatanMahasiswa);
  yield takeEvery(INDIKATOR_TKT_BY_KATEGORI_REQUEST, getIndikatorTKTByKategori);
  yield takeEvery(VALIDASI_KEANGGOTAAN_DOSEN_REQUEST, validasiKeanggotaanDosen);
  yield takeEvery(
    GET_JUMLAH_MAHASISWA_TERLIBAT_REQUEST,
    getJumlahMahasiswaTerlibat
  );
  yield takeEvery(
    UPDATE_KETERLIBATAN_MAHASISWA_REQUEST,
    updateKeterlibatanMahasiswa
  );
  yield takeEvery(
    DELETE_KETERLIBATAN_MAHASISWA_REQUEST,
    deleteKeterlibatanMahasiswa
  );
  yield takeEvery(
    CREATE_KETERLIBATAN_MAHASISWA_REQUEST,
    createKeterlibatanMahasiswa
  );
  // pengabdian start
  yield takeEvery(GET_TEMA_TEMATIK_REQUEST, getListTemaPrioritasTematik);
  yield takeEvery(GET_LIST_RIRN_REQUEST, getListBidangFokusRirn);
  yield takeEvery(
    POST_STEP_1_IDENTITAS_PENGABDIAN_REQUEST,
    postStep1IdentitasPengabdian
  );
  yield takeEvery(
    PUT_ALL_STEP_USULAN_PENGABDIAN_REQUEST,
    putAllStepUsulanPengabdian
  );
  yield takeEvery(GET_LIST_PROVINSI_REQUEST, getListProvinsi);
  yield takeEvery(GET_LIST_KOTA_REQUEST, getListKota);
  yield takeEvery(
    GET_OPTION_MITRA_KELOMPOK_REQUEST,
    getListOptionMitraKelompok
  );
  yield takeEvery(
    GET_OPTION_MITRA_JENIS_MITRA_REQUEST,
    getListOptionMitraJenisMitra
  );
  yield takeEvery(MHS_SET_ITEM, setMahasiswaItemSg);
  yield takeEvery(GET_WHITELIST_USULAN_REQUEST, getWhiteListUsulan);
  yield takeEvery(GET_LIST_KERJASAMA_LUAR_NEGERI_REQUEST, getListProgramKerjasamaLn);
  yield takeEvery(SAVE_MINAT_KATALIS_REQUEST, saveMinatKatalis);
  yield takeEvery(GET_MINAT_KATALIS_REQUEST, getListMinatKatalis);
  yield takeEvery(GET_DETAILS_MINAT_KATALIS_REQUEST, getDetailsMinatKatalis);
  yield takeEvery(APPROVAL_MEMBER_MINAT_KATALIS_REQUEST, approvalMemberMinatKatalis);
  yield takeEvery(DELETE_MINAT_KATALIS_REQUEST, deleteMinatKatalis);
  yield takeEvery(APPROVAL_PUSAT_MINAT_KATALIS_REQUEST, approvalPusatMinatKatalis);
}

export default usulanPenelitian;
