export const GET_INSTITUSI_REQUEST = "GET_INSTITUSI_REQUEST";
export const GET_INSTITUSI_SUCCESS = "GET_INSTITUSI_SUCCESS";
export const GET_INSTITUSI_FAILURE = "GET_INSTITUSI_FAILURE";

export const GET_SKEMA_EVALUASI_REQUEST = "GET_SKEMA_EVALUASI_REQUEST";
export const GET_SKEMA_EVALUASI_SUCCESS = "GET_SKEMA_EVALUASI_SUCCESS";
export const GET_SKEMA_EVALUASI_FAILURE = "GET_SKEMA_EVALUASI_FAILURE";
export const SET_CLEAR_SKEMA_EVALUASI = "SET_CLEAR_SKEMA_EVALUASI";

export const GET_LIST_USULAN_EVALUASI_REQUEST =
  "GET_LIST_USULAN_EVALUASI_REQUEST";
export const GET_LIST_USULAN_EVALUASI_SUCCESS =
  "GET_LIST_USULAN_EVALUASI_SUCCESS";
export const GET_LIST_USULAN_EVALUASI_FAILURE =
  "GET_LIST_USULAN_EVALUASI_FAILURE";

export const GET_DETAIL_USULAN_EVALUASI_REQUEST =
  "GET_DETAIL_USULAN_EVALUASI_REQUEST";
export const GET_DETAIL_USULAN_EVALUASI_SUCCESS =
  "GET_DETAIL_USULAN_EVALUASI_SUCCESS";
export const GET_DETAIL_USULAN_EVALUASI_FAILURE =
  "GET_DETAIL_USULAN_EVALUASI_FAILURE";

export const GET_KOMPONEN_NILAI_EVALUASI_REQUEST =
  "GET_KOMPONEN_NILAI_EVALUASI_REQUEST";
export const GET_KOMPONEN_NILAI_EVALUASI_SUCCESS =
  "GET_KOMPONEN_NILAI_EVALUASI_SUCCESS";
export const GET_KOMPONEN_NILAI_EVALUASI_FAILURE =
  "GET_KOMPONEN_NILAI_EVALUASI_FAILURE";

export const SAVE_PENILAIAN_EVALUASI_REQUEST =
  "SAVE_PENILAIAN_EVALUASI_REQUEST";
export const SAVE_PENILAIAN_PERMANEN_EVALUASI_REQUEST =
  "SAVE_PENILAIAN_PERMANEN_EVALUASI_REQUEST";

// Evaluasi prototipe admistrasi
export const GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_REQUEST =
  "GET_LIST_USULAN_EVALUASI_v2_REQUEST";
export const GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_SUCCESS =
  "GET_LIST_USULAN_EVALUASI_v2_SUCCESS";
export const GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_FAILURE =
  "GET_LIST_USULAN_EVALUASI_v2_FAILURE";

export const GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST =
  "GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST";
export const GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS =
  "GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS";
export const GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE =
  "GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE";

export const GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST =
  "GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST";
export const GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS =
  "GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS";
export const GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE =
  "GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE";

export const SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST =
  "SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST";
export const SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS =
  "SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS";
export const SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE =
  "SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE";

export const SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_REQUEST =
  "SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_REQUEST";
export const SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_SUCCESS =
  "SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_SUCCESS";
export const SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_FAILURE =
  "SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_FAILURE";
