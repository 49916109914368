import { instances } from "../api_helper";
import * as url from "../url_helper";


const getSkemaEvaluasiSubtansi = ({ tahun_usulan, tahun_pelaksanaan }) =>
	instances.request(`${url.ENDPOINT_SKEMA_EVALUASI_SUBTANSI}?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}`, {
		method: "GET",
	});

const getListUsulanEvaluasiSubtansi = ({ tahun_usulan, tahun_pelaksanaan, id_skema, id_institusi }) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_EVALUASI_SUBTANSI}?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}&id_skema=${id_skema}&id_institusi=${id_institusi}`, {
		method: "GET",
	});


const getKomponenNilaiEvaluasiSubtansi = ({ id_plotting_reviewer }) =>
	instances.request(`${url.ENDPOINT_KOMPONEN_NILAI_EVALUASI_SUBTANSI}?id_plotting_reviewer=${id_plotting_reviewer}`, {
		method: "GET",
	});

const getDetailUsulanByTransaksiKegiatanIdSubtansi = ({ id_transaksi_kegiatan }) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_EVALUASI_SUBTANSI}/${id_transaksi_kegiatan}`, {
		method: "GET",
	});

const savePenilaianEvaluasiSubtansi = (payload) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_EVALUASI_SUBTANSI}`, {
		method: "POST",
		data: payload
	});

const savePenilaianPermanenEvaluasiSubtansi = (payload) =>
	instances.request(`${url.ENDPOINT_SET_PERMANEN_EVALUASI_SUBTANSI}`, {
		method: "POST",
		data: payload
	});

const getListRabRekomendasi = (payload) => {
	let criteria = '?criteria=';
	if (payload.id_plotting_reviewer) {
		criteria += `id_plotting_reviewer:${payload.id_plotting_reviewer}`;
	}
	criteria += '&page=1:1000'
	return instances.request(`${url.ENDPOINT_RAB_REKOMENDASI}${criteria}`, {
		method: "GET",

	});
}

const saveRabRekomendasi = (payload) =>
	instances.request(`${url.ENDPOINT_RAB_REKOMENDASI}`, {
		method: "POST",
		data: payload
	});

export {
	getDetailUsulanByTransaksiKegiatanIdSubtansi,
	getKomponenNilaiEvaluasiSubtansi,
	getListUsulanEvaluasiSubtansi,
	getSkemaEvaluasiSubtansi,
	savePenilaianEvaluasiSubtansi,
	savePenilaianPermanenEvaluasiSubtansi,
	getListRabRekomendasi,
	saveRabRekomendasi,
};
