import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import { getBidangFokus as endpointBidangFokus } from "../../helpers/backend";
import * as backend from "../../helpers/penugasan";
// Action Types
import {
    CREATE_PENUGASAN_NUSANTARA_REQUEST,
    GET_BIDANG_FOKUS_REQUEST,
    GET_PENUGASAN_NUSANTARA_REQUEST,
    JENIS_BANTUAN_REQUEST,
    PENUGASAN_DOC_REQUEST,
    GET_SBK_UTAMA_REQUEST,
    GET_SKEMA_REQUEST,
    GET_PENUGASAN_NUSANTARA_BYID_REQUEST,
    UPDATE_PENUGASAN_NUSANTARA_REQUEST,
    GET_INSTITUSI_BYID_REQUEST,
} from "./actionTypes";
// Action
import { toast } from "react-toastify";
import {
    createPenugasanNusantaraFailure, createPenugasanNusantaraSuccess,
    getBidangFokusFailure, getBidangFokusSuccess,
    getJenisBantuanFailure,
    getJenisBantuanSuccess,
    getPenugasanNusantaraFailure,
    getPenugasanNusantaraSuccess,
    uploadDokumenPenugasanFailure, uploadDokumenPenugasanSuccess,
    getSBKUtamaSuccess, getSBKUtamaFailure,
    getSkemaKegiatanSuccess, getSkemaKegiatanFailure,
    getPenugasanNusantaraByIdSuccess, getPenugasanNusantaraByIdFailure,
    updatePenugasanNusantaraSuccess, updatePenugasanNusantaraFailure,
    getInstitusiByIdSuccess, getInstitusiByIdFailure,
} from "./action";


function* getJenisBantuan({ payload }) {
    try {
        const res = yield call(backend.getJenisBantuan, payload);
        if (res.data.code === 200) {
            yield put(
                getJenisBantuanSuccess({ data: res.data.data })
            )
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getJenisBantuanFailure(message.error));
    }
}

function* getInstitusiById({ payload, cb }) {
    try {
        const res = yield call(backend.getInstitusiById, payload);
        if (res.data.code === 200) {
            yield put(
                getInstitusiByIdSuccess({ data: res.data.data })
            )
            cb(res.data);
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getInstitusiByIdFailure(message.error));
        cb({
            code : 500,
            message : message.error
        })
    }
}

function* uploadDokumenPenugasan({ payload, cb }) {
    try {
        const res = yield call(backend.uploadDokumenPenugasan, payload);
        if (res.data.code === 200) {
            yield put(
                uploadDokumenPenugasanSuccess({ data: res.data.data, name: payload.name })
            )
            cb(res.data);
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(uploadDokumenPenugasanFailure(message.error));
        cb({
            code : 500,
            message : message
        })
    }
}

function* createPenugasanNusantara({ payload, cb }) {
    try {
        const res = yield call(backend.createPenugasanNusantara, payload);
        if (res.data.code === 200) {
            yield put(
                createPenugasanNusantaraSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan");
            cb(res.data);
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(createPenugasanNusantaraFailure(message.error));
        cb(null)
    }
}

function* updatePenugasanNusantara({ payload, id, cb }) {
    try {
        const res = yield call(backend.updatePenugasanNusantara, { payload, id });
        if (res.data.code === 200) {
            yield put(
                updatePenugasanNusantaraSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan di Draft");
            cb(res.data);
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(updatePenugasanNusantaraFailure(message.error));
        cb(null)

    }
}

function* getPenugasanNusantara({ payload }) {
    try {
        const res = yield call(backend.getPenugasanNusantara, payload);

        if (res.data.code === 200) {
            yield put(
                getPenugasanNusantaraSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanNusantaraFailure(message.error));
    }
}

function* getPenugasanNusantaraById({ payload, cb }) {
    try {

        const res = yield call(backend.getPenugasanNusantaraById, payload.id);
        if (res.data.code === 200) {
            yield put(
                getPenugasanNusantaraByIdSuccess({ data: res.data.data })
            )
            cb(res.data)
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanNusantaraByIdFailure(message.error));
        cb(null)
    }
}

function* getBidangFokus({ payload }) {
    try {

        const res = yield call(endpointBidangFokus, payload);
        if (res.data.code === 200) {
            yield put(
                getBidangFokusSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getBidangFokusFailure(message.error));
    }
}

function* getSBKUtama({ payload }) {
    try {
        const res = yield call(backend.getSBKUtama, payload);
        if (res.data.code === 200) {
            yield put(
                getSBKUtamaSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getSBKUtamaFailure(message.error));
    }
}

function* getSkemaKegiatan({ payload }) {
    try {
        const res = yield call(backend.getSkemaKegiatan, payload);
        if (res.data.code === 200) {
            yield put(
                getSkemaKegiatanSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getSkemaKegiatanFailure(message.error));
    }
}



function* notificationSaga() {
    yield takeEvery(PENUGASAN_DOC_REQUEST, uploadDokumenPenugasan);
    yield takeEvery(JENIS_BANTUAN_REQUEST, getJenisBantuan);
    yield takeEvery(CREATE_PENUGASAN_NUSANTARA_REQUEST, createPenugasanNusantara);
    yield takeEvery(UPDATE_PENUGASAN_NUSANTARA_REQUEST, updatePenugasanNusantara);
    yield takeEvery(GET_PENUGASAN_NUSANTARA_REQUEST, getPenugasanNusantara);
    yield takeEvery(GET_BIDANG_FOKUS_REQUEST, getBidangFokus);
    yield takeEvery(GET_SBK_UTAMA_REQUEST, getSBKUtama);
    yield takeEvery(GET_SKEMA_REQUEST, getSkemaKegiatan);
    yield takeEvery(GET_PENUGASAN_NUSANTARA_BYID_REQUEST, getPenugasanNusantaraById);
    yield takeEvery(GET_INSTITUSI_BYID_REQUEST, getInstitusiById);

}

export default notificationSaga;
