import { getSingleFileFromBucket } from "helpers/getFileFromBucket";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toast } from "react-toastify";
import utilities from "helpers/utilities";
import { PDFDocument } from "pdf-lib";
import { pdfCreate as engine, pdfCreatePersetujuan as engine2, pdfCreatePerbaikanUsulan } from "./partials";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class ProposalPenelitianV2 {
    constructor(data) {
        this.data = data;
        this.indexBlobSkiped = [];
    }

    async generate() {

        return await new Promise((resolve, _reject) => {
            try {
                const pdfDoc = pdfMake.createPdf(engine(this.data));
                pdfDoc.getBlob(async blob => {
                    resolve(blob);
                })
            } catch (error) {
                _reject(error);
            }
        })
    }

    async generatePersetujuan() {

        return await new Promise((resolve, _reject) => {
            try {
                const pdfDoc = pdfMake.createPdf(engine2(this.data));

                pdfDoc.getBlob(async blob => {
                    resolve(blob);
                })
            } catch (error) {
                _reject(error);
            }
        })
    }

    async generatePerbaikanUsulan({ kelompok_biaya, item }) {
        return await new Promise((resolve, _reject) => {
            try {
                const pdfDoc = pdfMake.createPdf(pdfCreatePerbaikanUsulan({
                    item,
                    supportData: {
                        kelompok_biaya
                    }
                }));

                pdfDoc.getBlob(async blob => {
                    resolve(blob);
                })
            } catch (error) {
                _reject(error);
            }
        })
    }

    async merging(docs = [], cb) {

        const pdf = await PDFDocument.create();
        let i = 0;
        try {
            for (const file of docs) {

                const bufferFile = await utilities.blobToUint8Array(file?.fileBlob || file);

                if (!this.indexBlobSkiped.includes(i)) {

                    const doc = await PDFDocument.load(bufferFile, { ignoreEncryption: true, throwOnInvalidObject: true })

                    if (doc) {
                        const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
                        for (const page of contentPage) {
                            pdf.addPage(page);
                        }
                    }
                }
                i++;
            }
            const pdfMerged = await pdf.saveAsBase64()
            const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, 'application/pdf');
            cb({ res: pdfMergedAsBlob, error: false })

        } catch (error) {
            if (error.message.includes('invalid object') || error.message?.toLowerCase()?.includes("failed to parse pdf")) {
                this.indexBlobSkiped.push(i);
                cb({ res: undefined, error: true, indexBlobSkipped: i, errMsg: "Tidak dapat menggabungkan file " + docs[i]?.fileName || "" })
            } else {
                cb({ res: undefined, error: true, errMsg: error.message })
            }
        }

    }

    forceDownload(file, fileName) {
        // * Force Download

        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(file);

        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);

    }

    async getSingleFile(fileUrl = "") {

        const friendlyURL = utilities.getFriendlyURL(fileUrl);
        const response = await getSingleFileFromBucket(friendlyURL);

        if (response.error) {
            toast.error(response.message);
        } else {
            return response.data;
        }
    }

}

export default ProposalPenelitianV2;
