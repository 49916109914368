export const GET_LIST_PROPOSAL_BIMTEK_REQUEST = "GET_LIST_PROPOSAL_BIMTEK_REQUEST";
export const GET_LIST_PROPOSAL_BIMTEK_SUCCESS = "GET_LIST_PROPOSAL_BIMTEK_SUCCESS";
export const GET_LIST_PROPOSAL_BIMTEK_FAILURE = "GET_LIST_PROPOSAL_BIMTEK_FAILURE";
export const CLEAN_LIST_PROPOSAL_BIMTEK = "CLEAN_LIST_PROPOSAL_BIMTEK";

export const GET_PROPOSAL_BIMTEK_REQUEST = "GET_PROPOSAL_BIMTEK_REQUEST";
export const GET_PROPOSAL_BIMTEK_SUCCESS = "GET_PROPOSAL_BIMTEK_SUCCESS";
export const GET_PROPOSAL_BIMTEK_FAILURE = "GET_PROPOSAL_BIMTEK_FAILURE";

export const UPDATE_PROPOSAL_BIMTEK_REQUEST = "UPDATE_PROPOSAL_BIMTEK_REQUEST";
export const UPDATE_PROPOSAL_BIMTEK_SUCCESS = "UPDATE_PROPOSAL_BIMTEK_SUCCESS";
export const UPDATE_PROPOSAL_BIMTEK_FAILURE = "UPDATE_PROPOSAL_BIMTEK_FAILURE";