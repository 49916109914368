/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";

import {
  OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_REQUEST,
  OPERATOR_MONITORING_CATATAN_HARIAN_REQUEST,
  OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_REQUEST,
  OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_REQUEST,
  GET_ALL_INSTITUSI_REQUEST,
  OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_REQUEST,
  OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_REQUEST,
  OPERATOR_MONITORING_LIST_KONTAK_PIC_REQUEST,
  OPERATOR_MONITORING_PUT_KONTAK_PIC_REQUEST,
  OPERATOR_MONITORING_LIST_MONEV_REQUEST,
  OPERATOR_MONITORING_CATATAN_HARIAN_V2_REQUEST,
  OPERATOR_LIST_LAPORAN_KEMAJUAN_V2_REQUEST,
  OPERATOR_SUMMARY_LAPORAN_KEMAJUAN_V2_REQUEST,
} from "./actionTypes";

import {
  getOperatorListUsulanPerbaikanFailure,
  getOperatorListUsulanPerbaikanSuccess,
  getOperatorListCatatanHarianSuccess,
  getOperatorListCatatanHarianFailure,
  getOperatorListLaporanKemajuanSuccess,
  getOperatorListLaporanKemajuanFailure,
  getOperatorListCatatanHarianV2Success,
  getOperatorListCatatanHarianV2Failure,
  getOperatorLaporanKemajuanDetailSuccess,
  getOperatorLaporanKemajuanDetailFailure,
  getAllInstitusiSuccess,
  getAllInstitusiFailure,
  getOperatorListLaporanAkhirSuccess,
  getOperatorListLaporanAkhirFailure,
  getOperatorLaporanAkhirDetailSuccess,
  getOperatorLaporanAkhirDetailFailure,
  getAllKontakPicSuccess,
  getAllKontakPicFailure,
  putKontakPicSuccess,
  putKontakPicFailure,
  listOptMonevSuccess,
  listOptMonevFailure,
  getListOperatorLaporanKemajuanV2Success,
  getListOperatorLaporanKemajuanV2Failure,
  getSummaryOperatorLaporanKemajuanV2Success,
  getSummaryOperatorLaporanKemajuanV2Failure,
} from "./action";

function* getOperatorListUsulanPerbaikan({ payload }) {
  try {
    const params = payload;
    const response = yield call(backend.getOperatorListUsulanPerbaikan, params);
    if (response.data.code == 200) {
      yield put(getOperatorListUsulanPerbaikanSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getOperatorListUsulanPerbaikanFailure(message, error));
  }
}

function* getOperatorListCatatanHarian({ payload }) {
  try {
    const params = payload;
    const response = yield call(backend.getOperatorListCatatanHarian, params);
    if (response.data.code == 200) {
      yield put(
        getOperatorListCatatanHarianSuccess(
          response.data.data.filter(item => item.penelitian !== null)
        )
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getOperatorListCatatanHarianFailure(message, error));
  }
}

function* getOperatorListCatatanHarianV2({ payload }) {
  try {
    const params = payload;
    const response = yield call(backend.getOperatorListCatatanHarianV2, params);
    if (response.data.code == 200) {
      yield put(getOperatorListCatatanHarianV2Success(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getOperatorListCatatanHarianV2Failure(message, error));
  }
}

function* getOperatorListLaporanKemajuan({ payload }) {
  try {
    const params = payload;
    const response = yield call(backend.getOperatorListLaporanKemajuan, params);
    if (response.data.code == 200) {
      yield put(getOperatorListLaporanKemajuanSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getOperatorListLaporanKemajuanFailure(message, error));
  }
}
function* getOperatorLaporanKemajuanDetail({ payload, cb }) {
  try {
    const params = payload;
    const response = yield call(
      backend.getOperatorLaporanKemajuanDetail,
      params
    );
    if (response.data.code == 200) {
      yield put(getOperatorLaporanKemajuanDetailSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getOperatorLaporanKemajuanDetailFailure(message, error));
  }
}
function* getAllInstitusi({ payload, cb }) {
  try {
    const response = yield call(backend.getAllInstitusi, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getAllInstitusiSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getAllInstitusiFailure(message, error));
    cb(null);
  }
}

function* getOperatorListLaporanAkhir({ payload }) {
  try {
    const response = yield call(backend.getOperatorListLaporanAkhir, payload);
    if (response.data.code == 200) {
      yield put(getOperatorListLaporanAkhirSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getOperatorListLaporanAkhirFailure(message, error));
  }
}

function* getOperatorLaporanAkhirDetail({ payload }) {
  console.log("saga", payload);

  try {
    const response = yield call(backend.getOperatorLaporanAkhirDetail, payload);
    if (response.data.code == 200) {
      yield put(getOperatorLaporanAkhirDetailSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
    console.log("response", response);
  } catch (error) {
    const message = error.message;
    yield put(getOperatorLaporanAkhirDetailFailure(message, error));
  }
}

// Opt Pusat Kontak PIC GetAll
function* getAllKontakPic({ payload }) {
  try {
    const response = yield call(backend.getAllKontakPic, payload);
    if (response.data.code == 200) {
      yield put(getAllKontakPicSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
    console.log("response", response);
  } catch (error) {
    const message = error.message;
    yield put(getAllKontakPicFailure(message, error));
  }
}

function* putKontakPic({ payload, cb }) {
  try {
    const response = yield call(backend.putKontakPic, payload);
    if (response.data.code == 200) {
      yield put(putKontakPicSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
    console.log("response", response);
  } catch (error) {
    const message = error.message;
    yield put(putKontakPicFailure(message, error));
  }
}

function* listOptMonev({ payload }) {
  try {
    const response = yield call(backend.listOptMonev, payload);
    if (response.data.data === 200) {
      yield put(listOptMonevSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch {
    const message = error.message;
    yield put(listOptMonevFailure(message, error));
  }
}
function* getListOperatorLaporanKemajuanV2({ payload, cb }) {
  try {
    const res = yield call(backend.getListOperatorLaporanKemajuanV2, payload);
    if (res.data.code === 200) {
      yield put(getListOperatorLaporanKemajuanV2Success(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
  
    cb({
      success: false,
      message,
    });
    yield put(getListOperatorLaporanKemajuanV2Failure(message?.error));
  }
}
function* getSummaryOperatorLaporanKemajuanV2({ payload, cb }) {
  try {
    const res = yield call(
      backend.getSummaryOperatorLaporanKemajuanV2,
      payload
    );
    if (res.data.code === 200) {
      yield put(getSummaryOperatorLaporanKemajuanV2Success(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getSummaryOperatorLaporanKemajuanV2Failure(message?.error));
  }
}
function* operatorMonitoringSaga() {
  yield takeEvery(
    OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_REQUEST,
    getOperatorListUsulanPerbaikan
  );
  yield takeEvery(
    OPERATOR_MONITORING_CATATAN_HARIAN_REQUEST,
    getOperatorListCatatanHarian
  );
  yield takeEvery(
    OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_REQUEST,
    getOperatorListLaporanKemajuan
  );
  yield takeEvery(
    OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_REQUEST,
    getOperatorLaporanKemajuanDetail
  );
  yield takeEvery(GET_ALL_INSTITUSI_REQUEST, getAllInstitusi);
  yield takeEvery(
    OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_REQUEST,
    getOperatorListLaporanAkhir
  );
  yield takeEvery(
    OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_REQUEST,
    getOperatorLaporanAkhirDetail
  );
  yield takeEvery(OPERATOR_MONITORING_LIST_KONTAK_PIC_REQUEST, getAllKontakPic);
  yield takeEvery(OPERATOR_MONITORING_PUT_KONTAK_PIC_REQUEST, putKontakPic);
  yield takeEvery(OPERATOR_MONITORING_LIST_MONEV_REQUEST, listOptMonev);
  yield takeEvery(
    OPERATOR_MONITORING_CATATAN_HARIAN_V2_REQUEST,
    getOperatorListCatatanHarianV2
  );
  yield takeEvery(
    OPERATOR_LIST_LAPORAN_KEMAJUAN_V2_REQUEST,
    getListOperatorLaporanKemajuanV2
  );
  yield takeEvery(
    OPERATOR_SUMMARY_LAPORAN_KEMAJUAN_V2_REQUEST,
    getSummaryOperatorLaporanKemajuanV2
  );
}

export default operatorMonitoringSaga;
