import {
  GET_INSTITUSI_FAILURE,
  GET_INSTITUSI_REQUEST,
  GET_INSTITUSI_SUCCESS,
  GET_SKEMA_EVALUASI_FAILURE,
  GET_SKEMA_EVALUASI_REQUEST,
  GET_SKEMA_EVALUASI_SUCCESS,
  SET_CLEAR_SKEMA_EVALUASI,
  GET_LIST_USULAN_EVALUASI_FAILURE,
  GET_LIST_USULAN_EVALUASI_REQUEST,
  GET_LIST_USULAN_EVALUASI_SUCCESS,
  GET_DETAIL_USULAN_EVALUASI_FAILURE,
  GET_DETAIL_USULAN_EVALUASI_REQUEST,
  GET_DETAIL_USULAN_EVALUASI_SUCCESS,
  GET_KOMPONEN_NILAI_EVALUASI_FAILURE,
  GET_KOMPONEN_NILAI_EVALUASI_REQUEST,
  GET_KOMPONEN_NILAI_EVALUASI_SUCCESS,
  GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_REQUEST,
  GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_SUCCESS,
  GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_FAILURE,
  GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
  GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
  GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
  GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
  SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST,
  SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS,
  SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE,
  SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_REQUEST,
  SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_SUCCESS,
  SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_FAILURE,
} from "./actionTypes";

const initialState = {
  institusi: {
    isFetching: false,
    items: [],
    item: {},
  },
  skema: {
    isFetching: false,
    items: [],
    item: {},
  },
  usulan: {
    isFetching: false,
    items: [],
    item: {},
  },
  komponenNilai: {
    isFetching: false,
    items: [],
    item: {},
  },
  saveEvaluasiAdminPrototipeV2: {
    isFetching: false,
    items: [],
    item: {},
  },
  usulanDetail: {
    isFetching: false,
    items: [],
    item: {},
  },
  savePermanent: { isFetching: false, items: [], item: {} },
};

const evaluasiAdministrasi = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTITUSI_REQUEST:
      return (state = {
        ...state,
        institusi: {
          ...state.institusi,
          isFetching: true,
        },
      });
    case GET_INSTITUSI_SUCCESS:
      if (action.payload.state == "detail") {
        return (state = {
          ...state,
          institusi: {
            ...state.institusi,
            isFetching: false,
            item: action.payload.data ?? {},
          },
        });
      } else {
        return (state = {
          ...state,
          institusi: {
            ...state.institusi,
            isFetching: false,
            items: action.payload.data ?? [],
            item: action.payload.data.length > 0 ? action.payload.data[0] : {},
          },
        });
      }
    case GET_INSTITUSI_FAILURE:
      return (state = {
        ...state,
        institusi: {
          ...state.institusi,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case SET_CLEAR_SKEMA_EVALUASI:
      return (state = {
        ...state,
        skema: {
          ...state.skema,
          items: [],
          item: {},
          isFetching: false,
        },
      });
    case GET_SKEMA_EVALUASI_REQUEST:
      return (state = {
        ...state,
        skema: {
          ...state.skema,
          isFetching: true,
        },
      });
    case GET_SKEMA_EVALUASI_SUCCESS:
      if (action.payload.state == "detail") {
        return (state = {
          ...state,
          skema: {
            ...state.skema,
            isFetching: false,
            item: action.payload.data ?? {},
          },
        });
      } else {
        return (state = {
          ...state,
          skema: {
            ...state.skema,
            isFetching: false,
            items: action.payload.data ?? [],
            item: action.payload.data.length > 0 ? action.payload.data[0] : {},
          },
        });
      }
    case GET_SKEMA_EVALUASI_FAILURE:
      return (state = {
        ...state,
        skema: {
          ...state.skema,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case GET_LIST_USULAN_EVALUASI_REQUEST:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: true,
        },
      });
    case GET_LIST_USULAN_EVALUASI_SUCCESS:
      if (action.payload.state == "detail") {
        return (state = {
          ...state,
          usulan: {
            ...state.usulan,
            isFetching: false,
            item: action.payload.data ?? {},
          },
        });
      } else {
        return (state = {
          ...state,
          usulan: {
            ...state.usulan,
            isFetching: false,
            items: action.payload.data ?? [],
            item: action.payload.data.length > 0 ? action.payload.data[0] : {},
          },
        });
      }
    case GET_LIST_USULAN_EVALUASI_FAILURE:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_DETAIL_USULAN_EVALUASI_REQUEST:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: true,
        },
      });
    case GET_DETAIL_USULAN_EVALUASI_SUCCESS:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case GET_DETAIL_USULAN_EVALUASI_FAILURE:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case GET_KOMPONEN_NILAI_EVALUASI_REQUEST:
      return (state = {
        ...state,
        komponenNilai: {
          ...state.komponenNilai,
          isFetching: true,
        },
      });
    case GET_KOMPONEN_NILAI_EVALUASI_SUCCESS:
      if (action.payload.state == "detail") {
        return (state = {
          ...state,
          komponenNilai: {
            ...state.komponenNilai,
            isFetching: false,
            item: action.payload.data ?? {},
          },
        });
      } else {
        return (state = {
          ...state,
          komponenNilai: {
            ...state.komponenNilai,
            isFetching: false,
            items: action.payload.data ?? [],
            item: action.payload.data.length > 0 ? action.payload.data[0] : {},
          },
        });
      }
    case GET_KOMPONEN_NILAI_EVALUASI_FAILURE:
      return (state = {
        ...state,
        komponenNilai: {
          ...state.komponenNilai,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // evaluasi prototipe administrasi v2
    case GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_REQUEST:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: true,
          items: [],
          item: {},
        },
      });
    case GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_SUCCESS:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: false,
          items: action.payload.data ?? [],
          item: action.payload.data.length > 0 ? action.payload.data[0] : {},
        },
      });
    case GET_LIST_USULAN_EVALUASI_ADMINISTRASI_PROTOTIPE_v2_FAILURE:
      return (state = {
        ...state,
        usulan: {
          ...state.usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    //
    case GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST:
      return (state = {
        ...state,
        komponenNilai: {
          ...state.komponenNilai,
          isFetching: true,
        },
      });
    case GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS:
      return (state = {
        ...state,
        komponenNilai: {
          ...state.komponenNilai,
          isFetching: false,
          items: action.payload.data ?? [],
          item: action.payload.data.length > 0 ? action.payload.data[0] : {},
        },
      });
    case GET_KOMPONEN_NILAI_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE:
      return (state = {
        ...state,
        komponenNilai: {
          ...state.komponenNilai,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    //
    case GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST:
      return (state = {
        ...state,
        usulanDetail: {
          ...state.usulanDetail,
          isFetching: true,
          items: [],
          item: {},
        },
      });
    case GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS:
      return (state = {
        ...state,
        usulanDetail: {
          ...state.usulanDetail,
          isFetching: false,
          items: action.payload.data ?? [],
          item: action.payload.data.length > 0 ? action.payload.data[0] : {},
        },
      });
    case GET_DETAIL_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE:
      return (state = {
        ...state,
        usulanDetail: {
          ...state.usulanDetail,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    //
    case SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_REQUEST:
      return (state = {
        ...state,
        saveEvaluasiAdminPrototipeV2: {
          ...state.saveEvaluasiAdminPrototipeV2,
          isFetching: true,
        },
      });
    case SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_SUCCESS:
      return (state = {
        ...state,
        saveEvaluasiAdminPrototipeV2: {
          ...state.saveEvaluasiAdminPrototipeV2,
          isFetching: false,
          items: action.payload.data ?? [],
          item: action.payload.data.length > 0 ? action.payload.data[0] : {},
        },
      });
    case SAVE_EVALUASI_ADMINISTRASI_PROTOTIPE_V2_FAILURE:
      return (state = {
        ...state,
        saveEvaluasiAdminPrototipeV2: {
          ...state.saveEvaluasiAdminPrototipeV2,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    //
    case SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_REQUEST:
      return (state = {
        ...state,
        savePermanent: {
          ...state.savePermanent,
          isFetching: true,
        },
      });
    case SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_SUCCESS:
      return (state = {
        ...state,
        savePermanent: {
          ...state.savePermanent,
          isFetching: false,
          items: action.payload.data,
          item: action.payload.data,
        },
      });
    case SAVE_PERMANENT_EVALUASI_PROTOTIPE_V2_FAILURE:
      return (state = {
        ...state,
        savePermanent: {
          ...state.savePermanent,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    default:
      return state;
  }
};

export default evaluasiAdministrasi;
