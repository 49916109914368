import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/reviewer";
// Action Types
import {
    GET_PENDAFTARAN_REVIEWER_REQUEST,
    CREATE_PENDAFTARAN_REVIEWER_REQUEST,
    GET_PENDAFTARAN_REVIEWER_PERSONAL_REQUEST,
    VERIFY_PENDAFTARAN_REVIEWER_REQUEST
} from "./actionTypes";
// Action
import { toast } from "react-toastify";
import {
    getPendaftaranReviewerFailure,
    getPendaftaranReviewerSuccess,
    createPendafataranReviewerSuccess,
    createPendafataranReviewerFailure,
    getPendaftaranPersonalReviewerSuccess,
    getPendaftaranPersonalReviewerFailure,
    getVerifyPendaftaranReviwerSuccess,
    getVerifyPendaftaranReviwerFailure
} from "./action";

function* createPendafataranReviewer({ payload, cb }) {
    try {
        const res = yield call(backend.createPendafataranReviewer, payload);
        if (res.data.code === 200 || res.data.code === 417) {
            yield put(
                createPendafataranReviewerSuccess({ data: res.data.data })
            )
        }
        cb(res.data)
    } catch (error) {

        const { message } = error;
        cb({
            success: false,
            message
        })
        yield put(createPendafataranReviewerFailure(message.error));
    }
}

function* getPendaftaranReviewer({ payload}) {
    try {
        const res = yield call(backend.getPendafataranReviewer, payload);
        if (res.data.code === 200) {
            yield put(
                getPendaftaranReviewerSuccess({ data: res.data.data })
            )
           
        }
    } catch (error) {

        const { message } = error;
       
        toast.error(message);
        yield put(getPendaftaranReviewerFailure(message.error));
    }
}

function* getPendaftaranPersonalReviewer({ payload, cb}) {
    try {
        const res = yield call(backend.getPendafataranReviewer, payload);
        
        if (res.data.code === 200) {
            yield put(
                getPendaftaranPersonalReviewerSuccess({ data: res.data.data })
            )
            cb(res.data)
           
        }
    } catch (error) {

        const { message } = error;
        cb({
            success : false,
            message
        })
        yield put(getPendaftaranPersonalReviewerFailure(message.error));
    }
}

function* getVerifyPendaftaranReviwer({ payload, cb}) {
    try {
        const res = yield call(backend.verificationPendafataranReviewer, payload);
        if (res.data.code === 200) {
            yield put(
                getVerifyPendaftaranReviwerSuccess({ data: res.data.data })
            )
            cb(res.data)
           
        }
    } catch (error) {

        const { message } = error;
        cb({
            success : false,
            message
        })
       
        toast.error(message);
        yield put(getVerifyPendaftaranReviwerFailure(message.error));
    }
}


function* notificationSaga() {
    yield takeEvery(CREATE_PENDAFTARAN_REVIEWER_REQUEST, createPendafataranReviewer)
    yield takeEvery(GET_PENDAFTARAN_REVIEWER_REQUEST, getPendaftaranReviewer)
    yield takeEvery(GET_PENDAFTARAN_REVIEWER_PERSONAL_REQUEST, getPendaftaranPersonalReviewer)
    yield takeEvery(VERIFY_PENDAFTARAN_REVIEWER_REQUEST, getVerifyPendaftaranReviwer)

}

export default notificationSaga;
