import axios from "axios";
import { toast } from "react-toastify";

export const getSingleFileFromBucket = async fileUrl => {
  try {

    const res = await axios({
      method: "GET",
      url: fileUrl,
      responseType: "blob",
    }).catch(err => err.response);
    if (res.status === 404) {
      return {
        error: true,
        message: `${res.status} ${res.statusText}`,
        data: null,
      };
    }
    if (res.status > 300) {
      const errResponse = {
        error: true,
        message: res.data.message,
        data: null,
      };
      return errResponse;
    }
    const successRes = {
      error: false,
      message: "success",
      data: res?.data,
    };
    return successRes;
  } catch (error) {
    return {
      error: true,
      message: error.message,
    };
  }
};
export const getFile = async url => {
  return await axios({
    method: "GET",
    url: url,
    responseType: "blob",
  }).catch(err => err.response);
};
export const getMultiFileFromBucket = async fileUrl => {
  const response = await getFile(fileUrl);
  if (response?.status === 404) {
    toast.warning(
      `${fileUrl?.substring(fileUrl?.lastIndexOf("/") + 1)} NOT FOUND`
    );
    return {
      error: true,
      message: `${response.status} ${response.statusText}`,
      data: null,
    };
  }
  if (response?.status > 300) {
    toast.warning(
      `${fileUrl?.substring(fileUrl?.lastIndexOf("/") + 1)} ${response.data.message || ""
      }`
    );
    const errResponse = {
      error: true,
      message: response.data.message,
      data: null,
    };
    return errResponse;
  }
  const successRes = {
    error: false,
    message: "success",
    data: response?.data,
  };
  return successRes;
};
