import {
  PENGUMUMAN_REQUEST,
  PENGUMUMAN_SUCCESS,
  PENGUMUMAN_FAILURE,
  PENGUMUMAN_LANDING_REQUEST,
  PENGUMUMAN_LANDING_SUCCESS,
  PENGUMUMAN_LANDING_FAILURE,
  PENGUMUMAN_BY_ID_REQUEST,
  PENGUMUMAN_BY_ID_SUCCESS,
  PENGUMUMAN_BY_ID_FAILURE,
  DELETE_PENGUMUMAN_REQUEST,
  DELETE_PENGUMUMAN_SUCCESS,
  CREATE_PENGUMUMAN_REQUEST,
  CREATE_PENGUMUMAN_SUCCESS,
  PENGUMUMAN_UPLOAD_REQUEST,
  PENGUMUMAN_UPLOAD_SUCCESS,
  UPDATE_PENGUMUMAN_REQUEST,
  UPDATE_PENGUMUMAN_SUCCESS,
  UPDATE_PENGUMUMAN_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
} from "./actionTypes";

// START: ROOTS
// Get
export const getPengumuman = request => {
  return {
    type: PENGUMUMAN_REQUEST,
    payload: request,
  };
};

export const getPengumumanSuccess = (response, state) => {
  return {
    type: PENGUMUMAN_SUCCESS,
    payload: response,
    state,
  };
};

export const getPengumumanFailure = (response, message) => {
  return {
    type: PENGUMUMAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// END: ROOTS

// GET PENGUMUMAN FOR LANDING PAGES START
export const getPengumumanLanding = request => {
  return {
    type: PENGUMUMAN_LANDING_REQUEST,
    payload: request,
  };
};

export const getPengumumanLandingSuccess = (response, state) => {
  return {
    type: PENGUMUMAN_LANDING_SUCCESS,
    payload: response,
    state,
  };
};

export const getPengumumanLandingFailure = (response, message) => {
  return {
    type: PENGUMUMAN_LANDING_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// GET PENGUMUMAN FOR LANDING PAGES END

// GET PENGUMUMAN BY ID START
export const getPengumumanById = request => {
  return {
    type: PENGUMUMAN_BY_ID_REQUEST,
    payload: request,
  };
};
export const getPengumumanByIdSuccess = (response, state) => {
  return {
    type: PENGUMUMAN_BY_ID_SUCCESS,
    payload: response,
    state,
  };
};
export const getPengumumanByIdFailure = (response, message) => {
  return {
    type: PENGUMUMAN_BY_ID_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// GET PENGUMUMAN BY ID END

// Delete pengumuman
export const deletePengumuman = (request, cb) => {
  return {
    type: DELETE_PENGUMUMAN_REQUEST,
    payload: request,
    cb,
  };
};

export const deletePengumumanSuccess = (response, state) => {
  return {
    type: DELETE_PENGUMUMAN_SUCCESS,
    payload: response,
    state,
  };
};

// Create Start
export const createPengumumanRequest = (request, cb) => {
  return {
    type: CREATE_PENGUMUMAN_REQUEST,
    payload: request,
    cb,
  };
};

export const createPengumumanSuccess = response => {
  return {
    type: CREATE_PENGUMUMAN_SUCCESS,
    payload: response,
  };
};
// Create End

// Upload File Start
export const uploadPengumumanRequest = response => {
  return {
    type: PENGUMUMAN_UPLOAD_REQUEST,
    payload: response,
  };
};

export const uploadPengumumanSuccess = (request, cb) => {
  return {
    type: PENGUMUMAN_UPLOAD_SUCCESS,
    payload: request,
    cb,
  };
};
// Upload File End

// Update Start
export const updatePengumuman = (request, cb) => {
  return {
    type: UPDATE_PENGUMUMAN_REQUEST,
    payload: request,
    cb,
  };
};

export const updatePengumumanSuccess = (response, state) => {
  return {
    type: UPDATE_PENGUMUMAN_SUCCESS,
    payload: response,
    state,
  };
};

export const updatePengumumanFailure = (response, message) => {
  return {
    type: UPDATE_PENGUMUMAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// Update End

// Delete File Start

export const deleteFilePengumuman = (request, cb) => {
  return {
    type: DELETE_FILE_REQUEST,
    payload: request,
    cb,
  };
};

export const deleteFileSuccess = (response, state) => {
  return {
    type: DELETE_FILE_SUCCESS,
    payload: response,
    state,
  };
};

export const deleteFileFailure = (response, message) => {
  return {
    type: DELETE_FILE_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Delete File End
