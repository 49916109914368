/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";
import {
  searchProposalEKatalogSuccess,
  searchProposalEKatalogFailure,
  getDetailProposalEKatalogSuccess,
  getDetailProposalEKatalogFailure,
  getReportProposalEKatalogSuccess,
  getReportProposalEKatalogFailure,
  searchPerguruanTinggiEKatalogFailure,
  searchPerguruanTinggiEKatalogSuccess,
  getAllTotalPpmPerguruanTinggiEKatalogSuccess,
  getAllTotalPpmPerguruanTinggiEKatalogFailure,
  getListKeywordManagementEKatalogSuccess,
  getListKeywordManagementEKatalogFailure,
  createKeywordManagementEKatalogSuccess,
  createKeywordManagementEKatalogFailure,
  getDetailKeywordManagementEKatalogSuccess,
  getDetailKeywordManagementEKatalogFailure,
  deleteKeywordManagementEKatalogSuccess,
  deleteKeywordManagementEKatalogFailure,
  getDanaPpmPerBidangFokusEKatalogSuccess,
  getDanaPpmPerBidangFokusEKatalogFailure,
  getListPpmPerguruanTinggiEKatalogSuccess,
  getListPpmPerguruanTinggiEKatalogFailure,
  getDataPpmPerYearEKatalogSuccess,
  getDataPpmPerYearEKatalogFailure,
  searchSpesifikProposalEKatalogSuccess,
  searchSpesifikProposalEKatalogFailure,
  getReportPpmEKatalogSuccess,
  getReportPpmEKatalogFailure,
  getJabatanDosenEKatalogSuccess,
  getJabatanDosenEKatalogFailure,
  getListDosenEKatalogSuccess,
  getListDosenEKatalogFailure,
  getListJumlahLuaranEKatalogSuccess,
  getListJumlahLuaranEKatalogFailure,
  getReportListDosenEKatalogSuccess,
  getReportListDosenEKatalogFailure,
  getListBidangFokusECatalogSuccess,
  getListBidangFokusECatalogFailure,
  getDanaPpmBidangFokusECatalogSuccess,
  getDanaPpmBidangFokusECatalogFailure,
  getTotalKlasterECatalogSuccess,
  getTotalKlasterECatalogFailure,
  getListPtByKlasterECatalogSuccess,
  getListPtByKlasterECatalogFailure,
  reportListPtByKlasterECatalogSuccess,
  reportListPtByKlasterECatalogFailure,
  getDropdownKeywordManagementEKatalogSuccess,
  getDropdownKeywordManagementEKatalogFailure,
  getListBenchmarkPpmECatalogSuccess,
  getListBenchmarkPpmECatalogFailure,
  getDropdownPerguruanTinggiECatalogSuccess,
  getDropdownPerguruanTinggiECatalogFailure,
  getCompareBenchmarkECatalogSuccess,
  getCompareBenchmarkECatalogFailure,
  getLldiktiMapsECatalogSuccess,
  getLldiktiMapsECatalogFailure,
  exportPerguruanTinggiEKatalogFailure,
  exportPerguruanTinggiEKatalogSuccess,
  getDetailProposalForPdfECatalogFailure,
  getDetailProposalForPdfECatalogSuccess,
} from "./action";

import {
  CREATE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  DELETE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_DETAIL_PROPOSAL_E_CATALOG_REQUEST,
  GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_REPORT_PROPOSAL_E_CATALOG_REQUEST,
  SEARCH_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  SEARCH_PROPOSAL_E_CATALOG_REQUEST,
  GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_DANA_PPM_PER_YEAR_E_CATALOG_REQUEST,
  SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_REQUEST,
  GET_REPORT_PPM_E_CATALOG_REQUEST,
  GET_JABATAN_DOSEN_E_CATALOG_REQUEST,
  GET_LIST_DOSEN_E_CATALOG_REQUEST,
  GET_LIST_JUMLAH_LUARAN_E_CATALOG_REQUEST,
  GET_REPORT_LIST_DOSEN_E_CATALOG_REQUEST,
  GET_LIST_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_TOTAL_KLASTER_E_CATALOG_REQUEST,
  GET_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
  REPORT_TOTAL_KLASTER_E_CATALOG_REQUEST,
  REPORT_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
  GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_LIST_BENCHMARK_PPM_E_CATALOG_REQUEST,
  GET_COMPARE_BENCHMARK_PPM_E_CATALOG_REQUEST,
  GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_COMPARE_BENCHMARK_E_CATALOG_REQUEST,
  GET_LLDIKTI_MAPS_E_CATALOG_REQUEST,
  EXPORT_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_REQUEST,
} from "./actionTypes";

function* searchProposalEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.searchProposalEKatalog, payload);
    if (res.data.code === 200) {
      yield put(searchProposalEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(searchProposalEKatalogFailure(message?.error));
  }
}
function* searchSpesifikProposalEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.searchProposalEKatalog, payload);
    if (res.data.code === 200) {
      yield put(searchSpesifikProposalEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(searchSpesifikProposalEKatalogFailure(message?.error));
  }
}
function* getDetailProposalEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getDetailProposalEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getDetailProposalEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDetailProposalEKatalogFailure(message?.error));
  }
}
function* getReportProposalEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.searchProposalEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getReportProposalEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getReportProposalEKatalogFailure(message?.error));
  }
}
function* searchPerguruanTinggiEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.searchPerguruanTinggiEKatalog, payload);
    if (res.data.code === 200) {
      yield put(searchPerguruanTinggiEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(searchPerguruanTinggiEKatalogFailure(message?.error));
  }
}
function* exportPerguruanTinggiEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.searchPerguruanTinggiEKatalog, payload);
    if (res.data.code === 200) {
      yield put(exportPerguruanTinggiEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(exportPerguruanTinggiEKatalogFailure(message?.error));
  }
}
function* getAllTotalPpmPerguruanTinggiEKatalog({ payload, cb }) {
  try {
    const res = yield call(
      backend.getDetailChartPerguruanTinggiEKatalog,
      payload
    );
    if (
      res.totalPpmPerYear.code === 200 ||
      res.totalPpmPerBidangFokus.code === 200 ||
      res.totalDanaPpmPerYear.code === 200 ||
      res.totalDanaPpmPerBidangFokus.code === 200 ||
      res.dataRiset.code === 200
    ) {
      const allData = {
        totalPpmPerYear: res?.totalPpmPerYear?.data || [],
        totalPpmPerBidangFokus: res?.totalPpmPerBidangFokus?.data || [],
        totalDanaPpmPerYear: res?.totalDanaPpmPerYear?.data || [],
        totalDanaPpmPerBidangFokus: res?.totalDanaPpmPerBidangFokus?.data || [],
        dataRiset: res?.dataRiset?.data || [],
      };
      yield put(getAllTotalPpmPerguruanTinggiEKatalogSuccess(allData));
      cb({ success: true, data: allData });
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getAllTotalPpmPerguruanTinggiEKatalogFailure(message?.error));
  }
}
function* getListKeywordManagementEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.crudKeywordManagementEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getListKeywordManagementEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListKeywordManagementEKatalogFailure(message?.error));
  }
}
function* getDropdownKeywordManagementEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.crudKeywordManagementEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getDropdownKeywordManagementEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDropdownKeywordManagementEKatalogFailure(message?.error));
  }
}
function* createKeywordManagementEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.crudKeywordManagementEKatalog, payload);
    if (res.data.code === 200) {
      yield put(createKeywordManagementEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(createKeywordManagementEKatalogFailure(message?.error));
  }
}
function* getDetailKeywordManagementEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.crudKeywordManagementEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getDetailKeywordManagementEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDetailKeywordManagementEKatalogFailure(message?.error));
  }
}
function* deleteKeywordManagementEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.crudKeywordManagementEKatalog, payload);
    if (res.data.code === 200) {
      yield put(deleteKeywordManagementEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(deleteKeywordManagementEKatalogFailure(message?.error));
  }
}
function* getDanaPpmPerBidangFokusEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getPpmPerBidangFokusEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getDanaPpmPerBidangFokusEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDanaPpmPerBidangFokusEKatalogFailure(message?.error));
  }
}
function* getListPpmPerguruanTinggiEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListPpmPerguruanTinggiEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getListPpmPerguruanTinggiEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListPpmPerguruanTinggiEKatalogFailure(message?.error));
  }
}
function* getDataPpmPerYearEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getPpmPerYearEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getDataPpmPerYearEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDataPpmPerYearEKatalogFailure(message?.error));
  }
}
function* getReportPpmEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListPpmPerguruanTinggiEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getReportPpmEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getReportPpmEKatalogFailure(message?.error));
  }
}
function* getJabatanDosenEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListJabatanDosenEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getJabatanDosenEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getJabatanDosenEKatalogFailure(message?.error));
  }
}
function* getListDosenEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListDosenEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getListDosenEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListDosenEKatalogFailure(message?.error));
  }
}
function* getReportListDosenEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListDosenEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getReportListDosenEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getReportListDosenEKatalogFailure(message?.error));
  }
}
function* getListJumlahLuaranEKatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListJumlahLuaranEKatalog, payload);
    if (res.data.code === 200) {
      yield put(getListJumlahLuaranEKatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListJumlahLuaranEKatalogFailure(message?.error));
  }
}
function* getListBidangFokusECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListBidangFokusECatalog, payload);
    if (res.data.code === 200) {
      yield put(getListBidangFokusECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListBidangFokusECatalogFailure(message?.error));
  }
}
function* getDanaPpmBidangFokusECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getDanaPpmBidangFokusECatalog, payload);
    if (res.data.code === 200) {
      yield put(getDanaPpmBidangFokusECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDanaPpmBidangFokusECatalogFailure(message?.error));
  }
}
function* getTotalKlasterECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getTotalKlasterECatalog, payload);
    if (res.data.code === 200) {
      yield put(getTotalKlasterECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getTotalKlasterECatalogFailure(message?.error));
  }
}

function* getListPtByKlasterECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListPtByKlasterECatalog, payload);
    if (res.data.code === 200) {
      yield put(getListPtByKlasterECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListPtByKlasterECatalogFailure(message?.error));
  }
}
function* reportListPtByKlasterECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListPtByKlasterECatalog, payload);
    if (res.data.code === 200) {
      yield put(reportListPtByKlasterECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(reportListPtByKlasterECatalogFailure(message?.error));
  }
}
function* getListBenchmarkPpmECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getListBenchmarkPpmECatalog, payload);
    if (res.data.code === 200) {
      yield put(getListBenchmarkPpmECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListBenchmarkPpmECatalogFailure(message?.error));
  }
}
function* getCompareBenchmarkECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getCompareBenchmarkPtEcatalog, payload);
    if (res.data.code === 200) {
      yield put(getCompareBenchmarkECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getCompareBenchmarkECatalogFailure(message?.error));
  }
}
function* getDropdownPerguruanTinggiECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getDropdownPerguruanTinggiECatalog, payload);
    if (res.data.code === 200) {
      yield put(getDropdownPerguruanTinggiECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDropdownPerguruanTinggiECatalogFailure(message?.error));
  }
}
function* getLldiktiMapsECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getLldiktiMapsECatalog, payload);
    if (res.data.code === 200) {
      yield put(getLldiktiMapsECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getLldiktiMapsECatalogFailure(message?.error));
  }
}
function* getDetailProposalForPdfECatalog({ payload, cb }) {
  try {
    const res = yield call(backend.getDetailProposalForPdfECatalog, payload);
    if (res.data.code === 200) {
      yield put(getDetailProposalForPdfECatalogSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDetailProposalForPdfECatalogFailure(message?.error));
  }
}
function* eCatalogSaga() {
  yield takeEvery(SEARCH_PROPOSAL_E_CATALOG_REQUEST, searchProposalEKatalog);
  yield takeEvery(
    SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_REQUEST,
    searchSpesifikProposalEKatalog
  );
  yield takeEvery(
    GET_DETAIL_PROPOSAL_E_CATALOG_REQUEST,
    getDetailProposalEKatalog
  );
  yield takeEvery(
    GET_REPORT_PROPOSAL_E_CATALOG_REQUEST,
    getReportProposalEKatalog
  );
  yield takeEvery(
    SEARCH_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    searchPerguruanTinggiEKatalog
  );
  yield takeEvery(
    EXPORT_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    exportPerguruanTinggiEKatalog
  );
  yield takeEvery(
    GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    getAllTotalPpmPerguruanTinggiEKatalog
  );
  yield takeEvery(
    GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    getListKeywordManagementEKatalog
  );
  yield takeEvery(
    GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    getDropdownKeywordManagementEKatalog
  );
  yield takeEvery(
    CREATE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    createKeywordManagementEKatalog
  );
  yield takeEvery(
    GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    getDetailKeywordManagementEKatalog
  );
  yield takeEvery(
    DELETE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    deleteKeywordManagementEKatalog
  );
  yield takeEvery(
    GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_REQUEST,
    getDanaPpmPerBidangFokusEKatalog
  );
  yield takeEvery(
    GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    getListPpmPerguruanTinggiEKatalog
  );
  yield takeEvery(
    GET_DANA_PPM_PER_YEAR_E_CATALOG_REQUEST,
    getDataPpmPerYearEKatalog
  );
  yield takeEvery(GET_REPORT_PPM_E_CATALOG_REQUEST, getReportPpmEKatalog);
  yield takeEvery(GET_JABATAN_DOSEN_E_CATALOG_REQUEST, getJabatanDosenEKatalog);
  yield takeEvery(GET_LIST_DOSEN_E_CATALOG_REQUEST, getListDosenEKatalog);
  yield takeEvery(
    GET_LIST_JUMLAH_LUARAN_E_CATALOG_REQUEST,
    getListJumlahLuaranEKatalog
  );
  yield takeEvery(
    GET_REPORT_LIST_DOSEN_E_CATALOG_REQUEST,
    getReportListDosenEKatalog
  );
  yield takeEvery(
    GET_LIST_BIDANG_FOKUS_E_CATALOG_REQUEST,
    getListBidangFokusECatalog
  );
  yield takeEvery(
    GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_REQUEST,
    getDanaPpmBidangFokusECatalog
  );
  yield takeEvery(GET_TOTAL_KLASTER_E_CATALOG_REQUEST, getTotalKlasterECatalog);
  yield takeEvery(
    GET_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
    getListPtByKlasterECatalog
  );
  yield takeEvery(
    REPORT_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
    reportListPtByKlasterECatalog
  );
  yield takeEvery(
    GET_LIST_BENCHMARK_PPM_E_CATALOG_REQUEST,
    getListBenchmarkPpmECatalog
  );
  yield takeEvery(
    GET_COMPARE_BENCHMARK_E_CATALOG_REQUEST,
    getCompareBenchmarkECatalog
  );
  yield takeEvery(
    GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    getDropdownPerguruanTinggiECatalog
  );
  yield takeEvery(GET_LLDIKTI_MAPS_E_CATALOG_REQUEST, getLldiktiMapsECatalog);
  yield takeEvery(
    GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_REQUEST,
    getDetailProposalForPdfECatalog
  );
}

export default eCatalogSaga;
