import utilities from "helpers/utilities";
import { instances } from "../api_helper";
import * as url from "../url_helper";

const getInstitusiMonev = ({ jenis, thn_usulan, id_tahapan }) => {
  return instances.request(
    `${url.ENDPOINT_INSTITUSI_MONEV}/${jenis}?thn_usulan=${thn_usulan}&id_tahapan=${id_tahapan}`,
    {
      method: "GET",
    }
  );
};

const getSkemaMonev = ({
  jenis,
  thn_usulan,
  thn_pelaksanaan,
  id_tahapan,
  institusi_id,
}) => {
  let params = `?thn_usulan=${thn_usulan}&id_tahapan=${id_tahapan}&institusi_id=${institusi_id}`;
  if (jenis === "penelitian") {
    params = `?thn_usulan=${thn_usulan}&thn_pelaksanaan=${thn_pelaksanaan}`;
  }
  return instances.request(`${url.ENDPOINT_SKEMA_MONEV}/${jenis}${params}`, {
    method: "GET",
  });
};

const getUsulanBySkema = ({
  skemaId,
  jenis,
  thn_usulan,
  id_tahapan,
  thn_pelaksanaan,
  id_institusi,
}) => {
  let params = `&thn_pelaksanaan=${thn_pelaksanaan}&id_institusi=${id_institusi}`;
  if (jenis === "pengabdian") {
    params = `&id_tahapan=${id_tahapan}`;
  }
  return instances.request(
    `${url.ENDPOINT_USULAN_MONEV}/${jenis}?id_skema=${skemaId}&thn_usulan=${thn_usulan}${params}`,
    {
      method: "GET",
    }
  );
};

const getUsulanById = ({ usulanId, jenis }) => {
  return instances.request(
    `${url.ENDPOINT_USULAN_MONEV}/${jenis}/${usulanId}`,
    {
      method: "GET",
    }
  );
};

const getKomponenPenialaian = ({
  jenis,
  id_skema,
  thn_pelaksanaan,
  level_tkt,
  id_usulan_kegiatan,
}) => {
  let params = `?id_skema=${id_skema}`;
  if (jenis === "penelitian") {
    params = `?id_skema=${id_skema}&thn_pelaksanaan=${thn_pelaksanaan}&level_tkt=${level_tkt}&id_usulan_kegiatan=${id_usulan_kegiatan}`;
  }
  return instances.request(
    `${url.ENDPOINT_KOMP_PENILAIAN_MONEV}/${jenis}/${params}`,
    {
      method: "GET",
    }
  );
};

const getHasilReviewerTerakhir = ({ id_usulan_kegiatan, id_usulan }) => {
  return instances.request(
    `${url.ENDPOINT_HASIL_REVIEW_TERAKHIR_MONEV}/${id_usulan_kegiatan}/${id_usulan}`,
    {
      method: "GET",
    }
  );
};

const getLinkBerkasMonev = ({ id_usulan_kegiatan }) => {
  return instances.request(
    `${url.ENDPOINT_LINK_BERKAS_MONEV}/${id_usulan_kegiatan}`,
    {
      method: "GET",
    }
  );
};

const saveMonev = payload => {
  const jenis = payload.jenis;
  delete payload["jenis"];
  return instances.request(`${url.ENDPOINT_USULAN_MONEV}/${jenis}`, {
    method: "POST",
    data: payload,
  });
};

const savepPermanentMonev = payload => {
  const jenis = payload.jenis;
  delete payload["jenis"];
  return instances.request(
    `${url.ENDPOINT_USULAN_MONEV}/${jenis}/set-permanen`,
    {
      method: "POST",
      data: payload,
    }
  );
};

const parsingDataLuaranDetail = data => {
  const result = [];
  Object.entries(data).forEach(([key, value]) => {
    let label = "";
    let type = "text";
    if (value !== null && value !== "") {
      if (
        key !== "tgl_created" &&
        key !== "tgl_updated" &&
        key !== "kd_sts_aktif" &&
        key !== "kd_peran_penulis" &&
        key !== "id_target_luaran" &&
        key !== "id_luaran_publikasi_jurnal"
      ) {
        label = utilities.capitalize(key.split("_").join(" "));
        if (key === "e_issn") {
          label = "ISBN/ISSN";
        }
        if (key === "doi" || key === "url_jurnal" || key === "url_artikel") {
          type = "url";
        }

        result.push({
          label: key === "doi" ? "DOI" : label,
          value,
          type,
        });
      }
    }
  });
  return result;
};
const getPostPutDelMonevInternal = payload => {
  if (payload.method === "GET") {
    if (payload.queryParams) {
      // Untuk Operator PT
      return instances.request(
        `/monev/upload-monev-internal?criteria=id_institusi:${payload.queryParams.id_institusi}&page=1:1000`,
        {
          method: "GET",
        }
      );
    } else {
      // Untuk Operator Pusat
      return instances.request(`/monev/upload-monev-internal?page=1:10000`, {
        method: "GET",
      });
    }
  } else if (payload.method === "POST") {
    return instances.request(`/monev/upload-monev-internal`, {
      method: "POST",
      data: payload.data,
    });
  } else if (payload.method === "PUT") {
    return instances.request(
      `/monev/upload-monev-internal/${payload.queryParams.id}`,
      {
        method: "PUT",
        data: payload.data,
      }
    );
  } else if (payload.method === "DELETE") {
    return instances.request(
      `/monev/upload-monev-internal/${payload.queryParams.id}`,
      {
        method: "DELETE",
      }
    );
  }
};
export {
  getSkemaMonev,
  getUsulanBySkema,
  getUsulanById,
  getInstitusiMonev,
  getKomponenPenialaian,
  saveMonev,
  savepPermanentMonev,
  getHasilReviewerTerakhir,
  getLinkBerkasMonev,
  parsingDataLuaranDetail,
  getPostPutDelMonevInternal,
};
