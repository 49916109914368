import { instances } from "../api_helper";
import * as url from "../url_helper";

// Get All Berita 1:10 for Berita Page
const getBerita = ({ sort, judul, direktorat, count }) => {
  return instances.request(
    `${url.ENDPOINT_PENGUMUMAN}?sort=tgl_created:${sort}${
      judul != "" || direktorat != ""
        ? `&criteria=judul:${judul},direktorat:${direktorat},is_deleted:false,type:berita`
        : `&criteria=is_deleted:false,type:berita`
    }${count != 1 ? `&page=${count}:10` : `&page=1:10`}`,
    {
      method: "GET",
    }
  );
};

// Get All Berita 1:3 for Landing Page
const getBeritaLanding = payload => {
  return instances.request(
    `${url.ENDPOINT_PENGUMUMAN}?sort=tgl_created:desc&page=1:3&criteria=is_deleted:false,type:berita,direktorat:${payload}`,
    // `${url.ENDPOINT_PENGUMUMAN}?sort=tgl_created:desc&criteria=judul:,direktorat:${payload},is_deleted:false,type:berita&page=1:3`,
    {
      methode: "GET",
    }
  );
};

// Get Berita By Id
const getBeritaById = id => {
  return instances.request(`${url.ENDPOINT_PENGUMUMAN}/${id}`, {
    method: "GET",
  });
};

// Create Berita
const createBerita = payload => {
  return instances.request(`${url.ENDPOINT_PENGUMUMAN}`, {
    method: "POST",
    data: payload,
  });
};

// upload image
const uploadBerita = formData => {
  // const formData = new FormData();
  // formData.append("file", file);
  // formData.append("id_pengumuman", id_pengumuman);
  return instances.request(`${url.ENDPOINT_PENGUMUMAN_FILE}`, {
    method: "POST",
    data: formData,
  });
};

// Delete Berita
const deleteBerita = props => {
  return instances.request(`${url.ENDPOINT_PENGUMUMAN}/${props.id}`, {
    method: "DELETE",
  });
};

const updateBerita = payload => {
  return instances.request(`${url.ENDPOINT_PENGUMUMAN}/${payload.id}`, {
    method: "PUT",
    data: payload,
  });
};

const deleteImgBerita = file => {
  return (
    instances.request(`${url.ENDPOINT_PENGUMUMAN_FILE}/${file.id}`, {
      method: "DELETE",
    }),
    console.log("helper file.id", file.id),
    console.log("helper file.id_pengumuman", file.id_pengumuman)
  );
};

export {
  getBerita,
  getBeritaLanding,
  getBeritaById,
  createBerita,
  uploadBerita,
  deleteBerita,
  updateBerita,
  deleteImgBerita,
};
