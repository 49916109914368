
import styles from "../../settings/styles";

const stylePDF = {
    defaultStyle: {
        color: 'black',
        fontSize: 9,
        columnGap: 20
    },
    pageMargins: [71, 32, 71, 45],
    pageSize: 'A4',
    styles: {
        ...styles,
        bgColorHeaderTable: {
            fillColor: "#dedede"
        },
        fillBlack : {
            fillColor : "#000"
        }
    },
    hr: (y = 1, mt = 10) => ({
        marginTop: mt,
        marginBottom: 10,
        canvas: [{ type: 'line', x1: 0, y1: y, x2: 450, y2: y, lineWidth: 1 }]
    }),
}

export default stylePDF;