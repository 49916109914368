export const PT_DOSEN_REQUEST = "PT_DOSEN_REQUEST";
export const PT_DOSEN_SUCCESS = "PT_DOSEN_SUCCESS";
export const PT_DOSEN_FAILURE = "PT_DOSEN_FAILURE";

export const PT_DOSEN_BY_ID_REQUEST = "PT_DOSEN_BY_ID_REQUEST";
export const PT_DOSEN_BY_ID_SUCCESS = "PT_DOSEN_BY_ID_SUCCESS";
export const PT_DOSEN_BY_ID_FAILURE = "PT_DOSEN_BY_ID_FAILURE";

export const PT_NIDN_REQUEST = "PT_NIDN_REQUEST";
export const PT_NIDN_SUCCESS = "PT_NIDN_SUCCESS";
export const PT_NIDN_FAILURE = "PT_NIDN_FAILURE";
export const PT_NIDN_RESTART = "PT_NIDN_RESTART";

export const GET_PERSONAL_SEARCH_REQUEST = "GET_PERSONAL_SEARCH_REQUEST";
export const GET_PERSONAL_SEARCH_SUCCESS = "GET_PERSONAL_SEARCH_SUCCESS";
export const GET_PERSONAL_SEARCH_FAILURE = "GET_PERSONAL_SEARCH_FAILURE";
export const CLEAN_PERSONAL_SEARCH = "CLEAN_PERSONAL_SEARCH";

export const INSTITUSI_REQUEST = "INSTITUSI_REQUEST";
export const INSTITUSI_SUCCESS = "INSTITUSI_SUCCESS";
export const INSTITUSI_FAILURE = "INSTITUSI_FAILURE";

export const PUT_INSTITUSI_REQUEST = "PUT_INSTITUSI_REQUEST";
export const PUT_INSTITUSI_SUCCESS = "PUT_INSTITUSI_SUCCESS";
export const PUT_INSTITUSI_FAILURE = "PUT_INSTITUSI_FAILURE";

export const RESET_PWD_REQUEST = "RESET_PWD_REQUEST";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS";
export const RESET_PWD_FAILURE = "RESET_PWD_FAILURE";

export const SYNC_DATA_DOSEN_REQUEST = "SYNC_DATA_DOSEN_REQUEST";
export const SYNC_DATA_DOSEN_SUCCESS = "SYNC_DATA_DOSEN_SUCCESS";
export const SYNC_DATA_DOSEN_FAILURE = "SYNC_DATA_DOSEN_FAILURE";

export const SEARCH_DOSEN_BY_NIDN_REQUEST = "SEARCH_DOSEN_BY_NIDN_REQUEST";
export const SEARCH_DOSEN_BY_NIDN_SUCCESS = "SEARCH_DOSEN_BY_NIDN_SUCCESS";
export const SEARCH_DOSEN_BY_NIDN_FAILURE = "SEARCH_DOSEN_BY_NIDN_FAILURE";

export const DATA_PERSONAL_DOSEN_REQUEST = "DATA_PERSONAL_DOSEN_REQUEST";
export const DATA_PERSONAL_DOSEN_SUCCESS = "DATA_PERSONAL_DOSEN_SUCCESS";
export const DATA_PERSONAL_DOSEN_FAILURE = "DATA_PERSONAL_DOSEN_FAILURE";

export const DAFTAR_PROGRAM_STUDI_REQUEST = "DAFTAR_PROGRAM_STUDI_REQUEST";
export const DAFTAR_PROGRAM_STUDI_SUCCESS = "DAFTAR_PROGRAM_STUDI_SUCCESS";
export const DAFTAR_PROGRAM_STUDI_FAILURE = "DAFTAR_PROGRAM_STUDI_FAILURE";

export const SEARCH_PRODI_BY_KODE_REQUEST = "SEARCH_PRODI_BY_KODE_REQUEST";
export const SEARCH_PRODI_BY_KODE_SUCCESS = "SEARCH_PRODI_BY_KODE_SUCCESS";
export const SEARCH_PRODI_BY_KODE_FAILURE = "SEARCH_PRODI_BY_KODE_FAILURE";

export const TAMBAH_DATA_DOSEN_REQUEST = "TAMBAH_DATA_DOSEN_REQUEST";
export const TAMBAH_DATA_DOSEN_SUCCESS = "TAMBAH_DATA_DOSEN_SUCCESS";
export const TAMBAH_DATA_DOSEN_FAILURE = "TAMBAH_DATA_DOSEN_FAILURE";

export const KETUA_LPPM_REQUEST = "KETUA_LPLPPM_REQUEST";
export const KETUA_LPPM_SUCCESS = "KETUA_LPLPPM_SUCCESS";
export const KETUA_LPPM_FAILURE = "KETUA_LPLPPM_FAILURE";

export const SYNC_DATA_PRODI_REQUEST = "SYNC_DATA_PRODI_REQUEST";
export const SYNC_DATA_PRODI_SUCCESS = "SYNC_DATA_PRODI_SUCCESS";
export const SYNC_DATA_PRODI_FAILURE = "SYNC_DATA_PRODI_FAILURE";

export const GET_DOSEN_TO_PDDIKTI_REQUEST = "GET_DOSEN_TO_PDDIKTI_REQUEST";
export const GET_DOSEN_TO_PDDIKTI_SUCCESS = "GET_DOSEN_TO_PDDIKTI_SUCCESS";
export const GET_DOSEN_TO_PDDIKTI_FAILURE = "GET_DOSEN_TO_PDDIKTI_FAILURE";

export const GET_STATUS_DOSEN_REQUEST = "GET_STATUS_DOSEN_REQUEST";
export const GET_STATUS_DOSEN_SUCCESS = "GET_STATUS_DOSEN_SUCCESS";
export const GET_STATUS_DOSEN_FAILURE = "GET_STATUS_DOSEN_FAILURE";

export const PUT_STATUS_DOSEN_REQUEST = "PUT_STATUS_DOSEN_REQUEST";
export const PUT_STATUS_DOSEN_SUCCESS = "PUT_STATUS_DOSEN_SUCCESS";
export const PUT_STATUS_DOSEN_FAILURE = "PUT_STATUS_DOSEN_FAILURE";

export const PUT_INSTITUSI_EDIT_NAMA_JABATAN_REQUEST =
  "PUT_INSTITUSI_EDIT_NAMA_JABATAN_REQUEST";
export const PUT_INSTITUSI_EDIT_NAMA_JABATAN_SUCCESS =
  "PUT_INSTITUSI_EDIT_NAMA_JABATAN_SUCCESS";
export const PUT_INSTITUSI_EDIT_NAMA_JABATAN_FAILURE =
  "PUT_INSTITUSI_EDIT_NAMA_JABATAN_FAILURE";
