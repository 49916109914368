import { instances } from "../api_helper";
import * as url from "../url_helper";

//const instances = instance();

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const updateIdentitasDosen = payload => {
  // const formData = new FormData();

  // Object.keys(payload).forEach(key => {
  //   formData.append(key, payload[key]);
  // });

  return instances.request(url.UPDATE_IDENTITAS_DOSEN, {
    method: "PUT",
    ...config,
    data: payload,
  });
};

const getDashboards = _payload =>
  instances.request(url.GET_DASHBOARD_DOSEN, {
    method: "GET",
  });

const getTrackingUsulanPenelitian = _payload =>
  instances.request(url.ENDPOINT_TRACKING_USULAN, {
    method: "GET",
  });
const getListUsulanDashboardDosen = () => {
  return instances.request(url.ENDPOINT_LIST_USULAN, {
    method: "GET",
  });
};
const getRiwayatPenelitianDashboardDosen = () => {
  return instances.request(url.ENDPOINT_RIWAYAT_PENELITIAN, {
    method: "GET",
  });
};
const getRiwayatPengabdianDashboardDosen = () => {
  return instances.request(url.ENDPOINT_RIWAYAT_PENGABDIAN, {
    method: "GET",
  });
};

const getDashboardSinta = () =>
  instances.request(url.GET_DASHBOARD_DOSEN_SINTA, {
    method: "GET",
  });

const syncSinta = (payload = {}) => {
  // const formData = new FormData();

  // Object.keys(payload).forEach(key => {
  //   formData.append(key, payload[key]);
  // });

  return instances.request(url.SYNC_SINTA, {
    method: "POST",
    ...config,
    data: payload,
  });
};

const syncPddikti = (payload = {}) => {
  const formData = new FormData();

  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key]);
  });

  return instances.request(url.SYNC_PDDIKRI, {
    method: "POST",
    ...config,
    data: formData,
  });
};

export {
  syncSinta,
  syncPddikti,
  getDashboards,
  getDashboardSinta,
  updateIdentitasDosen,
  getTrackingUsulanPenelitian,
  getListUsulanDashboardDosen,
  getRiwayatPenelitianDashboardDosen,
  getRiwayatPengabdianDashboardDosen,
};
