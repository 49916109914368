import styles from "../../settings/styles";

const stylePDF = {
  defaultStyle: {
    color: "black",
    fontSize: 10,
    columnGap: 20,
  },
  pageMargins: [71, 75, 71, 45],
  pageSize: "LETTER",
  styles: {
    ...styles,
    bgColorHeaderTable: {
      fillColor: "#dedede",
    },
  },
  hr: (y = 1, mt = 10) => ({
    marginTop: mt,
    marginBottom: 10,
    canvas: [{ type: "line", x1: 0, y1: y, x2: 470, y2: y, lineWidth: 1 }],
  }),
};
export const stylePDFSptbPenelitian = {
  defaultStyle: {
    color: "black",
    fontSize: 10,
    columnGap: 20,
  },
  pageMargins: [52.16, 52.16, 52.16, 52.16],
  pageSize: "A4",
  styles: {
    ...styles,
    bgColorHeaderTable: {
      fillColor: "#dedede",
    },
    fillBlack: {
      fillColor: "#000",
    },
  },
  hr: (y = 1, mt = 10) => ({
    marginTop: mt,
    marginBottom: 10,
    canvas: [{ type: "line", x1: 0, y1: y, x2: 450, y2: y, lineWidth: 1 }],
  }),
};
export default stylePDF;
