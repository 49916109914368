import { instances } from "../api_helper";
import * as url from "../url_helper";

const uploadDokumenPenugasan = (payload) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("path", `penugasan/${payload.type}/${payload.nidn}/`);
    return instances.request(`${url.ENDPOINT_PENUGASAN_FILE}`, {
        method: "POST",
        data: formData,
    });
}

const getJenisBantuan = ({ group }) => instances.request(`${url.ENDPOINT_ENUMERATION}?criteria=group:${group}`, {
    method: "GET",
});

const getInstitusiById = ({ id }) => instances.request(`${url.ENDPOINT_INSTITUSI}/${id}`, {
    method: "GET",
});

const createPenugasanNusantara = (payload) => instances.request(`${url.ENDPOINT_PENUGASAN_NUSANTARA}`, {
    method: "POST",
    data: payload,
})

const updatePenugasanNusantara = ({ payload, id }) => instances.request(`${url.ENDPOINT_PENUGASAN_NUSANTARA}/${id}`, {
    method: "PUT",
    data: payload,
})

const getPenugasanNusantara = ({ nidn = null }) => {
    let param = '';
    if (nidn) {
        param = `?criteria=nidn:${nidn}`;
    }
    return instances.request(`${url.ENDPOINT_PENUGASAN_NUSANTARA}${param}`, {
        method: "GET",
    })
}

const getPenugasanNusantaraById = (id) => instances.request(`${url.ENDPOINT_PENUGASAN_NUSANTARA}/${id}`, {
    method: "GET",
})

const getPenugasanPrototipe = () => instances.request(`${url.ENDPOINT_PENUGASAN_PROTOTIPE}`, {
    method: "GET",
})

const getPenugasanPrototipeById = (id) => instances.request(`${url.ENDPOINT_PENUGASAN_PROTOTIPE}/${id}`, {
    method: "GET",
})

const createPenugasanPrototipe = (payload) => instances.request(`${url.ENDPOINT_PENUGASAN_PROTOTIPE}`, {
    method: "POST",
    data: payload,
})

const updatePenugasanPrototipe = ({ payload, id }) => instances.request(`${url.ENDPOINT_PENUGASAN_PROTOTIPE}/${id}`, {
    method: "PUT",
    data: payload,
})

const getPenugasanGoogle = () => instances.request(`${url.ENDPOINT_PENUGASAN_GOOGLE}`, {
    method: "GET",
})

const getPenugasanGoogleById = (id) => instances.request(`${url.ENDPOINT_PENUGASAN_GOOGLE}/${id}`, {
    method: "GET",
})

const createPenugasanGoogle = (payload) => instances.request(`${url.ENDPOINT_PENUGASAN_GOOGLE}`, {
    method: "POST",
    data: payload,
})

const updatePenugasanGoogle = ({ payload, id }) => instances.request(`${url.ENDPOINT_PENUGASAN_GOOGLE}/${id}`, {
    method: "PUT",
    data: payload,
})

const getPenugasanJFS = () => instances.request(`${url.ENDPOINT_PENUGASAN_JFS}`, {
    method: "GET",
})

const getPenugasanJFSById = (id) => instances.request(`${url.ENDPOINT_PENUGASAN_JFS}/${id}`, {
    method: "GET",
})

const createPenugasanJFS = (payload) => instances.request(`${url.ENDPOINT_PENUGASAN_JFS}`, {
    method: "POST",
    data: payload,
})

const updatePenugasanJFS = ({ payload, id }) => instances.request(`${url.ENDPOINT_PENUGASAN_JFS}/${id}`, {
    method: "PUT",
    data: payload,
})

const getPenugasanPembelajaran = () => instances.request(`${url.ENDPOINT_PENUGASAN_PEMBELAJARAN}`, {
    method: "GET",
})

const getPenugasanPembelajaranById = (id) => instances.request(`${url.ENDPOINT_PENUGASAN_PEMBELAJARAN}/${id}`, {
    method: "GET",
})

const createPenugasanPembelajaran = (payload) => instances.request(`${url.ENDPOINT_PENUGASAN_PEMBELAJARAN}`, {
    method: "POST",
    data: payload,
})

const updatePenugasanPembelajaran = ({ payload, id }) => instances.request(`${url.ENDPOINT_PENUGASAN_PEMBELAJARAN}/${id}`, {
    method: "PUT",
    data: payload,
})

const getPenugasanKosaBangsa = () => instances.request(`${url.ENDPOINT_PENUGASAN_KOSABANGSA}`, {
    method: "GET",
})

const getPenugasanKosaBangsaById = (id) => instances.request(`${url.ENDPOINT_PENUGASAN_KOSABANGSA}/${id}`, {
    method: "GET",
})

const createPenugasanKosaBangsa = (payload) => instances.request(`${url.ENDPOINT_PENUGASAN_KOSABANGSA}`, {
    method: "POST",
    data: payload,
})

const updatePenugasanKosaBangsa = ({ payload, id }) => instances.request(`${url.ENDPOINT_PENUGASAN_KOSABANGSA}/${id}`, {
    method: "PUT",
    data: payload,
})


const getSBKUtama = ({ id_bidang_fokus, id_katagori = 2 }) => instances.request(`${url.ENDPOINT_SBK_UTAMA}?criteria=id_kategori_sbk:${id_katagori},id_bidang_fokus:${id_bidang_fokus}&sort=tahun_implementasi:desc`, {
    method: "GET",
});

const getSkemaKegiatan = ({ id = null }) => {
    let criteria = '';
    if (id) {
        criteria = `?criteria=id:${id}`;
    }
    return instances.request(`${url.ENDPOINT_SKEMA_KEGIATAN}${criteria}`, {
        method: "GET",
    });
}

const validationsPrototipe = () => instances.request(`${url.ENDPOINT_VALIDASI_PROTOTIPE}`, {
    method: "GET",
})

export {
    getJenisBantuan,
    uploadDokumenPenugasan,
    createPenugasanNusantara,
    updatePenugasanNusantara,
    getPenugasanNusantara,
    getPenugasanNusantaraById,
    getSBKUtama,
    getSkemaKegiatan,
    getPenugasanPrototipe,
    getPenugasanPrototipeById,
    createPenugasanPrototipe,
    updatePenugasanPrototipe,
    getPenugasanJFS,
    getPenugasanJFSById,
    updatePenugasanJFS,
    createPenugasanJFS,
    getPenugasanGoogle,
    getPenugasanGoogleById,
    createPenugasanGoogle,
    updatePenugasanGoogle,
    getInstitusiById,
    getPenugasanPembelajaran,
    getPenugasanPembelajaranById,
    createPenugasanPembelajaran,
    updatePenugasanPembelajaran,
    getPenugasanKosaBangsa,
    getPenugasanKosaBangsaById,
    createPenugasanKosaBangsa,
    updatePenugasanKosaBangsa,
    validationsPrototipe,
}