import React from "react";
import notfound from "../../assets/images/notfound.svg";
import PropTypes from "prop-types";
const NoData = ({ message = "Data tidak tersedia" }) => {
  return (
    <div className="text-center">
      <img src={notfound} className="w-25" alt="Not Found" />
      <h5 className="mt-3">{message}</h5>
    </div>
  );
};
NoData.propTypes = {
  message: PropTypes.node,
};
export default NoData;
