import {
   CLEAN_DETAILS_KI,
   CREATE_PENGAJUAN_KI_FAILURE,
   CREATE_PENGAJUAN_KI_REQUEST,
   CREATE_PENGAJUAN_KI_SUCCESS,
   DELETE_PENGAJUAN_KI_FAILURE,
   DELETE_PENGAJUAN_KI_REQUEST,
   DELETE_PENGAJUAN_KI_SUCCESS,
   GET_COUNTING_KI_FAILURE,
   GET_COUNTING_KI_REQUEST,
   GET_COUNTING_KI_SUCCESS,
   GET_COUNTING_PLOT_KI_FAILURE,
   GET_COUNTING_PLOT_KI_REQUEST,
   GET_COUNTING_PLOT_KI_SUCCESS,
   GET_DETAILS_KI_FAILURE,
   GET_DETAILS_KI_REQUEST,
   GET_DETAILS_KI_SUCCESS,
   GET_LIST_KI_FAILURE,
   GET_LIST_KI_PUSAT_FAILURE,
   GET_LIST_KI_PUSAT_REQUEST,
   GET_LIST_KI_PUSAT_SUCCESS,
   GET_LIST_KI_REQUEST,
   GET_LIST_KI_SUCCESS,
   GET_LIST_PENUGASAN_REVIEWER_KI_FAILURE,
   GET_LIST_PENUGASAN_REVIEWER_KI_REQUEST,
   GET_LIST_PENUGASAN_REVIEWER_KI_SUCCESS,
   GET_LIST_PLOT_KI_FAILURE,
   GET_LIST_PLOT_KI_REQUEST,
   GET_LIST_PLOT_KI_SUCCESS,
   UPDATE_PENGAJUAN_KI_FAILURE,
   UPDATE_PENGAJUAN_KI_REQUEST,
   UPDATE_PENGAJUAN_KI_SUCCESS,
   GET_ELIGIBLITY_DOSEN_KI_REQUEST,
   GET_ELIGIBLITY_DOSEN_KI_SUCCESS,
   GET_ELIGIBLITY_DOSEN_KI_FAILURE,
   GET_PLOT_KI_REQUEST,
   GET_PLOT_KI_SUCCESS,
   GET_PLOT_KI_FAILURE,
   SAVE_PENILAIAN_KI_REQUEST,
   SAVE_PENILAIAN_KI_SUCCESS,
   GET_LIST_KEGIATAN_BIMTEK_KI_REQUEST,
   GET_LIST_KEGIATAN_BIMTEK_KI_SUCCESS,
   GET_LIST_KEGIATAN_BIMTEK_KI_FAILURE
} from "./actionTypes";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";

export const getEligibiltyDosenKI = (request = {}, cb = () => { }) => {
   return {
      type: GET_ELIGIBLITY_DOSEN_KI_REQUEST,
      payload: request,
      cb
   };
}
export const getEligibiltyDosenKISuccess = (response = {}) => {
   return {
      type: GET_ELIGIBLITY_DOSEN_KI_SUCCESS,
      payload: response
   };
}
export const getEligibiltyDosenKIFailure = (message, response) => {
   return {
      type: GET_ELIGIBLITY_DOSEN_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const savePenugasanReviewerKI = async (request = {}, cb = () => { }) => {
   try {
      const response = await backend.savePenugasanReviewerKI(request);

      if (response.data.code === 200) {
         cb(true);
         toast.success("Berhasil menambahkan reviewer!");
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      cb(false);
      toast.error(error.message);
   }
}

export const updatePenugasanReviewerKI = async (request = {}, cb = () => { }) => {
   try {
      const response = await backend.updatePenugasanReviewerKI(request);

      if (response.data.code === 200) {
         cb(true);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      cb(false);
      toast.error(error.message);
   }
}

export const getListPenugasanReviewerKIRequest = (request = {}, cb = () => { }) => {
   return {
      type: GET_LIST_PENUGASAN_REVIEWER_KI_REQUEST,
      payload: request,
      cb
   };
}
export const getListPenugasanReviewerKISuccess = (response = {}) => {
   return {
      type: GET_LIST_PENUGASAN_REVIEWER_KI_SUCCESS,
      payload: response
   };
}
export const getListPenugasanReviewerKIFilure = (message, response) => {
   return {
      type: GET_LIST_PENUGASAN_REVIEWER_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const createPlottingProposalKI = async (request = {}, cb = () => { }) => {
   try {
      const response = await backend.createPlottingProposalKI(request);

      if (response.data.code === 200) {
         cb(true);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      cb(false);
      toast.error(error.message);
   }
}

export const updatePlottingProposalKI = async (request = {}, cb = () => { }) => {
   try {
      const response = await backend.updatePlottingProposalKI(request);

      if (response.data.code === 200) {
         cb(true);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      cb(false);
      toast.error(error.message);
   }
}


export const evaluatePenilaianReviewer = async (request = {}, cb = () => { }) => {
   try {
      const response = await backend.evaluatePenilaianReviewer(request);

      if (response.data.code === 200) {
         cb(true);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      cb(false);
      toast.error(error.message);
   }
}

export const getSimilarityTitleKI = async (request = {}, cb = () => { }) => {
   try {
      const response = await backend.getSimilarityTitleKI(request);

      if (response.data.code === 200) {
         cb(response.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      cb(false);
      toast.error(error.message);
   }
}

export const getListReviewerPlotKIRequest = (request = {}) => {
   return {
      type: GET_LIST_PLOT_KI_REQUEST,
      payload: request
   };
}
export const getListReviewerPlotKISuccess = (response = {}) => {
   return {
      type: GET_LIST_PLOT_KI_SUCCESS,
      payload: response
   };
}
export const getListReviewerPlotKIFailure = (message, response) => {
   return {
      type: GET_LIST_PLOT_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const getListKegiatanBimtekKI = (request = {}) => {
   return {
      type: GET_LIST_KEGIATAN_BIMTEK_KI_REQUEST,
      payload: request
   };
}
export const getListKegiatanBimtekKISuccess = (response = {}) => {
   return {
      type: GET_LIST_KEGIATAN_BIMTEK_KI_SUCCESS,
      payload: response
   };
}
export const getListKegiatanBimtekKIFailure = (message, response) => {
   return {
      type: GET_LIST_KEGIATAN_BIMTEK_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const getReviewerPlotKI = (id) => {
   return {
      type: GET_PLOT_KI_REQUEST,
      payload: id
   };
}
export const getReviewerPlotKISuccess = (response = {}) => {
   return {
      type: GET_PLOT_KI_SUCCESS,
      payload: response
   };
}
export const getReviewerPlotKIFailure = (message, response) => {
   return {
      type: GET_PLOT_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}


export const savePenilaianKI = (data, cb) => {
   return {
      type: SAVE_PENILAIAN_KI_REQUEST,
      payload: data,
      cb
   };
}

export const savePenilaianKISuccess = (response = {}) => {
   return {
      type: SAVE_PENILAIAN_KI_SUCCESS,
      payload: response
   };
}

export const getCountingReviewerPlotKIRequest = (request = {}) => {
   return {
      type: GET_COUNTING_PLOT_KI_REQUEST,
      payload: request
   };
}
export const getCountingReviewerPlotKISuccess = (response = {}) => {
   return {
      type: GET_COUNTING_PLOT_KI_SUCCESS,
      payload: response
   };
}
export const getCountingReviewerPlotKIFailure = (message, response) => {
   return {
      type: GET_COUNTING_PLOT_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const getCountingPengajuanKI = (request = {}) => {
   return {
      type: GET_COUNTING_KI_REQUEST,
      payload: request
   };
}
export const getCountingPengajuanKISuccess = (response = {}) => {
   return {
      type: GET_COUNTING_KI_SUCCESS,
      payload: response
   };
}
export const getCountingPengajuanKIFailure = (message, response) => {
   return {
      type: GET_COUNTING_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const downloadExcelPengajuanKI = async (request, cb) => {

   try {
      const response = await backend.downloadExcelPengajuanKI(request);
     
      // if (response.data.code === 200) {
      cb(response);
      // } else {
      //    throw new Error(response.data.message);
      // }
   } catch (error) {
      cb(null);
      toast.error(error.message);
   }
}

export const getListPengajuanKIPusat = (request = {}) => {
   return {
      type: GET_LIST_KI_PUSAT_REQUEST,
      payload: request
   };
}
export const getListPengajuanKIPusatSuccess = (response = {}) => {
   return {
      type: GET_LIST_KI_PUSAT_SUCCESS,
      payload: response
   };
}
export const getListPengajuanKIPusatFailure = (message, response) => {
   return {
      type: GET_LIST_KI_PUSAT_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const getListPengajuanKI = (request = {}) => {
   return {
      type: GET_LIST_KI_REQUEST,
      payload: request
   };
}
export const getListPengajuanKISuccess = (response = {}) => {
   return {
      type: GET_LIST_KI_SUCCESS,
      payload: response
   };
}
export const getListPengajuanKIFailure = (message, response) => {
   return {
      type: GET_LIST_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const cleanDetailsPengajuanKI = () => {
   return {
      type: CLEAN_DETAILS_KI,
   };
}
export const getDetailsPengajuanKI = (request = {}, cb = () => { }) => {
   return {
      type: GET_DETAILS_KI_REQUEST,
      payload: request,
      cb
   };
}
export const getDetailsPengajuanKISuccess = (response = {}, cb = () => { }) => {
   return {
      type: GET_DETAILS_KI_SUCCESS,
      payload: response,
      cb
   };
}
export const getDetailsPengajuanKIFailure = (message, response) => {
   return {
      type: GET_DETAILS_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const createPengajuanKI = (request = {}, cb = () => { }) => {
   return {
      type: CREATE_PENGAJUAN_KI_REQUEST,
      payload: request,
      cb
   };
}
export const createPengajuanKISuccess = (response = {}) => {
   return {
      type: CREATE_PENGAJUAN_KI_SUCCESS,
      payload: response,
   };
}
export const createPengajuanKIFailure = (message, response) => {
   return {
      type: CREATE_PENGAJUAN_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const updatePengajuanKI = (request = {}, cb = () => { }) => {
   return {
      type: UPDATE_PENGAJUAN_KI_REQUEST,
      payload: request,
      cb
   };
}
export const updatePengajuanKISuccess = (response = {},) => {
   return {
      type: UPDATE_PENGAJUAN_KI_SUCCESS,
      payload: response,

   };
}
export const updatePengajuanKIFailure = (message, response) => {
   return {
      type: UPDATE_PENGAJUAN_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const deletePengajuanKI = (request = {}) => {
   return {
      type: DELETE_PENGAJUAN_KI_REQUEST,
      payload: request
   };
}
export const deletePengajuanKISuccess = (response = {}, cb = () => { }) => {
   return {
      type: DELETE_PENGAJUAN_KI_SUCCESS,
      payload: response,
      cb
   };
}
export const deletePengajuanKIFailure = (message, response) => {
   return {
      type: DELETE_PENGAJUAN_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}