import { instances } from "../../api_helper";
import * as url from "../../url_helper";

export const getAllKontakPic = payload => {
  return instances.request(
    `${url.ENDPOINT_PUSAT_KONTAK_PIC_LIST}/${payload.something}`,
    {
      method: "GET",
    }
  );
};

export const putKontakPic = payload => {
  return instances.request(`${url.ENDPOINT_PUSAT_KONTAK_PIC_PUT}`, {
    method: "PUT", // PUT or POST
    data: payload,
  });
};
