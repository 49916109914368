/* eslint-disable no-unused-vars */
import utilities from "helpers/utilities";
import { instances, instancesV2 } from "../api_helper";
import * as url from "../url_helper";
import encStorage from "helpers/encStorage";
const storage = JSON.parse(encStorage.getItem("authUser"));
import { decode } from "helpers/jwt";
import CetakLaporanAkhirV2 from "helpers/workers/pdf/laporanAkhirV2";
const API_V2_URL =
  process.env.REACT_APP_API_V2_URL || "http://localhost:8088/api/v2";
export const getListLaporanAkhir = () => {
  let URL = "";
  const jwtResult = decode(storage?.token);
  if (
    window.location.pathname.includes("/penelitian/laporan-akhir") ||
    window.location.pathname.includes("/penelitian/v2-laporan-akhir")
  ) {
    URL = `${url.ENDPOINT_LIST_LAPORAN_AKHIR}/${jwtResult.id_personal}?jenis-kegiatan=1`;
  } else if (
    window.location.pathname.includes("/pengabdian/laporan-akhir") ||
    window.location.pathname.includes("/pengabdian/v2-laporan-akhir")
  ) {
    URL = `${url.ENDPOINT_LIST_LAPORAN_AKHIR}/${jwtResult.id_personal}?jenis-kegiatan=2`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

export const getDetailLaporanAkhir = async payload => {
  const jenis = payload.jenis;
  let id_usulan_kegiatan = "";
  if (payload.id) {
    id_usulan_kegiatan = payload.id;
  } else if (payload.id_usulan_kegiatan) {
    id_usulan_kegiatan = payload.id_usulan_kegiatan;
  } else {
    id_usulan_kegiatan = window.location.pathname.split("/")[3];
  }
  let URL = "";
  if (
    window.location.pathname.includes("/penelitian/laporan-akhir") ||
    window.location.pathname.includes("/penelitian/v2-laporan-akhir") ||
    jenis === "penelitian"
  ) {
    if (payload.nidn) {
      // view operator
      URL = `${url.ENDPOINT_GET_VIEW_DETAIL_LAPORAN_AKHIR}/by-id-usulan-kegiatan/${id_usulan_kegiatan}/1/${payload.nidn}`;
    } else {
      // view dosen
      URL = `${url.ENDPOINT_LIST_LAPORAN_AKHIR}/by-id-usulan-kegiatan/${id_usulan_kegiatan}/1`;
    }
  } else if (
    window.location.pathname.includes("/pengabdian/laporan-akhir") ||
    window.location.pathname.includes("/pengabdian/v2-laporan-akhir") ||
    jenis === "pengabdian"
  ) {
    if (payload.nidn) {
      // view operator
      URL = `${url.ENDPOINT_GET_VIEW_DETAIL_LAPORAN_AKHIR}/by-id-usulan-kegiatan/${id_usulan_kegiatan}/2/${payload.nidn}`;
    } else {
      // view dosen
      URL = `${url.ENDPOINT_LIST_LAPORAN_AKHIR}/by-id-usulan-kegiatan/${id_usulan_kegiatan}/2`;
    }
  } else {
    URL = `${url.ENDPOINT_LIST_LAPORAN_AKHIR}/by-id-usulan-kegiatan/${id_usulan_kegiatan}/${payload.kd_jenis_kegiatan}`;
    if (payload.nidn) {
      URL = `${url.ENDPOINT_GET_VIEW_DETAIL_LAPORAN_AKHIR}/by-id-usulan-kegiatan/${id_usulan_kegiatan}/${payload.kd_jenis_kegiatan}/${payload.nidn}`;
    }
  }
  return await instances.request(URL, {
    method: "GET",
  });
};

export const getDokumenLuaranLaporanAkhir = (id_target_jenis_luaran, jenis) => {
  let URL = "";
  if (
    window.location.pathname.includes("/penelitian/laporan-akhir") ||
    jenis === "penelitian"
  ) {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000`;
  } else if (
    window.location.pathname.includes("/pengabdian/laporan-akhir") ||
    jenis === "pengabdian"
  ) {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000&jenis=2`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};
export const createLaporanAkhir = data => {
  let URL = "";
  if (window.location.pathname.includes("/penelitian/laporan-akhir")) {
    URL = `${url.ENDPOINT_CREATE_UPDATE_LAPORAN_AKHIR}/1`;
  } else if (window.location.pathname.includes("/pengabdian/laporan-akhir")) {
    URL = `${url.ENDPOINT_CREATE_UPDATE_LAPORAN_AKHIR}/2`;
  }
  return instances.request(URL, {
    method: "POST",
    data: data,
  });
};

export const getSptbLaporanAkhir = async ({ id_usulan_kegiatan }) => {
  return await instances.request(
    `/tanggung-jawab-belanja/usulan-kegiatan/${id_usulan_kegiatan}/3`,
    {
      method: "GET",
    }
  );
};
export const getLaporanAkhirLuaranAktual = async queryParams => {
  const { list_id_luaran_dijanjikan } = queryParams;

  // Membuat objek URLSearchParams baru
  const searchParams = new URLSearchParams();

  // Menambahkan setiap id_luaran_dijanjikan ke dalam URLSearchParams
  list_id_luaran_dijanjikan.forEach(id => {
    searchParams.append("id_luaran_dijanjikan", id);
  });

  // Menambahkan kode_tahapan ke dalam URLSearchParams
  searchParams.append("kode_tahapan", "34");

  // Membuat URL dengan query parameters yang dinamis
  const url = `/proposal/luaran/aktualisasi?${searchParams.toString()}`;
  return await instancesV2.request(url, {
    method: "GET",
  });
};
export const createLaporanAkhirLuaranAktual = async payload => {
  return await instancesV2.request(
    `/proposal/luaran/aktualisasi/${payload.id_luaran_dijanjikan}/34`,
    {
      method: "POST",
      data: payload.data,
    }
  );
};

export const createSptbLaporanAkhir = async data => {
  const res = await instances.request(
    `/tanggung-jawab-belanja/usulan-kegiatan/create`,
    {
      method: "POST",
      data: data,
    }
  );
  return res;
};

export const updateSptbLaporanAkhir = async data => {
  const res = await instances.request(
    `/tanggung-jawab-belanja/usulan-kegiatan/update`,
    {
      method: "PUT",
      data: data,
    }
  );
  return res;
};

export const uploadDokumenLaporanAkhir = payload => {
  const formData = new FormData();
  formData.append("path", payload.path);
  formData.append("file", payload.file);
  return instances.request(url.ENDPOINT_LAPORAN_AKHIR_FILE, {
    method: "POST",
    data: formData,
  });
};

export const uploadDokumenSptbLaporanAkhir = payload => {
  const formData = new FormData();
  if (window.location.pathname.includes("/penelitian/laporan-akhir")) {
    formData.append("path", "document/laporan-akhir-penelitian/sptb/");
  } else if (window.location.pathname.includes("/pengabdian/laporan-akhir")) {
    formData.append(
      "path",
      "document/laporan-akhir-pengabdian/penggunaan-anggaran/"
    );
  }
  formData.append("file", payload.file);
  return instances.request(url.ENDPOINT_LAPORAN_KEMAJUAN_FILE, {
    method: "POST",
    data: formData,
  });
};

export const getFormMitraDetailLaporanAkhirPengabdian = ({
  id_usulan_kegiatan,
}) => {
  return instances.request(
    `${url.ENDPOINT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN}?criteria=id_usulan_kegiatan:${id_usulan_kegiatan}`,
    {
      method: "GET",
    }
  );
};

export const postFormMitraLaporanAkhirPengabdian = data => {
  return instances.request(
    `${url.ENDPOINT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN}`,
    {
      method: "POST",
      data,
    }
  );
};

export const putFormMitraLaporanAkhirPengabdian = values => {
  return instances.request(
    `${url.ENDPOINT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN}/${values.id}`,
    {
      method: "PUT",
      data: values.payload,
    }
  );
};
// * Post Dokumen Presentasi Pengabdian ( No Saga)
export const postDokumenPresentasiLaporanAkhir = async data => {
  data.kode_tahapan_kegiatan = "34";
  const res = await instances.request(`/luaran/presentasi-pelaksanaan`, {
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
};

// * Get Dokumen Presentasi Pengabdian ( No Saga)
export const getDokumentPresentasiLaporanAkhir = async id_usulan_kegiatan => {
  const res = await instances.request(
    `/luaran/presentasi-pelaksanaan?criteria=id_usulan_kegiatan:${
      utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
    },kode_tahapan_kegiatan:34&sort=tgl_created:desc`,
    {
      method: "GET",
    }
  );
  return res;
};
// * Get Rencana Hasil Pelaksaan ( No Saga)
export const getRencanaHasilPelaksanaanPengabdianLaporanAkhir =
  async id_usulan_kegiatan => {
    const res = await instances.request(
      `/luaran/rencana-hasil-pelaksanaan?criteria=id_usulan_kegiatan:${
        utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
      },kode_tahapan_kegiatan:34&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    );
    return res;
  };
// * Get Jenis Luaran Rencana Hasil Pelaksanaan( No Saga)
export const getJenisLuaranRencanaHasilPelaksanaanPengabdianLaporanAkhir =
  async () => {
    const res = await instances.request(
      `/enumeration?criteria=group:rencana_hasil_pelaksanaan`,
      {
        method: "GET",
      }
    );
    return res;
  };
// * POST Rencana Hasil Pelaksanaan ( No Saga)
export const postRencanaHasilPelaksanaanPengabdianLaporanAkhir = async data => {
  data.kode_tahapan_kegiatan = "34";
  const res = await instances.request(`/luaran/rencana-hasil-pelaksanaan`, {
    method: "POST",
    data: data,
  });
  return res;
};
// * PUT Rencana Hasil Pelaksanaan ( No Saga)
export const putRencanaHasilPelaksanaanPengabdianLaporanAkhir = async (
  data,
  id
) => {
  data.kode_tahapan_kegiatan = "34";
  const res = await instances.request(
    `/luaran/rencana-hasil-pelaksanaan/${id}`,
    {
      method: "PUT",
      data: data,
    }
  );
  return res;
};
// * Post Dokumen Poster Pengabdian ( No Saga)
export const postDokumenPosterLaporanAkhir = async data => {
  data.kode_tahapan_kegiatan = "34";
  const res = await instances.request(`/luaran/poster`, {
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
};

// * Get Dokumen Presentasi Pengabdian ( No Saga)
export const getDokumenPosterLaporanAkhir = async id_usulan_kegiatan => {
  const res = await instances.request(
    `/luaran/poster?criteria=id_usulan_kegiatan:${
      utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
    },kode_tahapan_kegiatan:34&sort=tgl_created:desc`,
    {
      method: "GET",
    }
  );
  return res;
};
// * Get Luaran Tambahan Pengabdian ( No Saga)
export const getLuaranTambahanPengabdianLaporanAkhir =
  async id_usulan_kegiatan => {
    const res = await instances.request(
      `/luaran/luaran-pengabdian-tambahan?criteria=id_usulan_kegiatan:${
        utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
      },kode_tahapan_kegiatan:34&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    );
    return res;
  };
// * Get Jenis Luaran Tambahan Pengabdian ( No Saga)
export const getJenisLuaranTambahanPengabdianLaporanAkhir = async (
  group = "luaran_tambahan_pengabdian"
) => {
  const res = await instances.request(
    `/enumeration?criteria=(exact)group:${group}`,
    {
      method: "GET",
    }
  );
  return res;
};
// * POST Luaran Tambahan Pengabdian ( No Saga)
export const postLuaranTambahanPengabdianLaporanAkhir = async data => {
  data.kode_tahapan_kegiatan = "34";
  const res = await instances.request(`/luaran/luaran-pengabdian-tambahan`, {
    method: "POST",
    data: data,
  });
  return res;
};
// * PUT Luaran Tambahan Pengabdian ( No Saga)
export const putLuaranTambahanPengabdianLaporanAkhir = async (data, id) => {
  data.kode_tahapan_kegiatan = "34";
  const res = await instances.request(
    `/luaran/luaran-pengabdian-tambahan/${id}`,
    {
      method: "PUT",
      data: data,
    }
  );
  return res;
};
// for validation
export const getAllDataPenelitianLaporanAkhir = async ({
  id_usulan_kegiatan,
  id_transaksi_kegiatan,
}) => {
  const response = await Promise.all([
    instances.request(
      `${url.ENDPOINT_LIST_LAPORAN_AKHIR}/by-id-usulan-kegiatan/${id_usulan_kegiatan}/1`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/tanggung-jawab-belanja/usulan-kegiatan/${id_usulan_kegiatan}/3`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/dokumen-mitra?criteria=id_transaksi_kegiatan:${id_transaksi_kegiatan}&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/presentasi-pelaksanaan?criteria=id_usulan_kegiatan:${
        utilities.uuidValidateV4(id_usulan_kegiatan) ? id_usulan_kegiatan : null
      },kode_tahapan_kegiatan:34&sort=tgl_created:desc`
    ),
  ]);

  const result = {
    dataLaporanAkhir: response[0].data.data,
    dataSptb: response[1].data.data,
    dataDokumenMitra: response[2].data.data?.[0],
    dataPresentasi: response[3].data.data?.[0],
  };
  return result;
};

export const getAllDataPengabdianLaporanAkhir = async ({
  id_usulan_kegiatan,
}) => {
  const response = await Promise.all([
    instances.request(
      `${url.ENDPOINT_LIST_LAPORAN_AKHIR}/by-id-usulan-kegiatan/${id_usulan_kegiatan}/2`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `${url.ENDPOINT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN}?criteria=id_usulan_kegiatan:${id_usulan_kegiatan}`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/tanggung-jawab-belanja/usulan-kegiatan/${id_usulan_kegiatan}/3`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/rencana-hasil-pelaksanaan?criteria=id_usulan_kegiatan:${id_usulan_kegiatan},kode_tahapan_kegiatan:34&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/presentasi-pelaksanaan?criteria=id_usulan_kegiatan:${id_usulan_kegiatan},kode_tahapan_kegiatan:34&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    ),
    instances.request(
      `/luaran/poster?criteria=id_usulan_kegiatan:${id_usulan_kegiatan},kode_tahapan_kegiatan:34&sort=tgl_created:desc`,
      {
        method: "GET",
      }
    ),
  ]);
  const result = {
    dataLaporanAkhir: response[0].data.data,
    dataFormMitra: response[1].data.data,
    dataSptb: response[2].data.data,
    dataRencanaHasilPelaksana: response[3].data.data[0],
    dataDokumenPresentasi: response[4].data.data[0],
    dataDokumenPoster: response[5].data.data[0],
  };
  return result;
};

//Laporan Akhir V2 by Bagus Fatwan Alfiat
export const getListLaporanAkhirV2 = payload => {
  return instances.request({
    baseURL: API_V2_URL,
    method: "GET",
    url: `/laporan/akhir?${new URLSearchParams(
      utilities.removeNullOrUndefinedProperties(payload || {})
    ).toString()}`,
  });
};
export const getDetailLaporanAkhirV2 = async payload => {
  const id = payload.id_proposal_kegiatan
    ? payload.id_proposal_kegiatan
    : window.location.pathname.split("/")[3];
  return await instances.request({
    baseURL: API_V2_URL,
    method: "GET",
    url: `/laporan/akhir/${id}`,
  });
};
export const getTargetJenisLuaranV2NoSaga = async (
  id_jenis_luaran,
  jenis_program
) => {
  // Jenis program penelitian,pengabdian,prototipe,kosabangsa
  let URL;
  if (
    window.location.pathname.includes("/kosabangsa/laporan-akhir-proposal") ||
    jenis_program === "pengabdian"
  ) {
    URL = `/luaran/target-jenis-luaran/get-by-jenis-luaran/${id_jenis_luaran}?jenis=2`;
  } else {
    URL = `/luaran/target-jenis-luaran/get-by-jenis-luaran/${id_jenis_luaran}`;
  }
  const res = await instances.request(URL, {
    method: "GET",
  });
  return res;
};
export const getDokumenLuaranV2NoSaga = async (
  id_target_jenis_luaran,
  jenis
) => {
  let URL;
  if (
    window.location.pathname.includes("/kosabangsa/laporan-akhir-proposal") ||
    jenis === "kosabangsa" ||
    jenis === "pengabdian"
  ) {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000&jenis=2`;
  } else {
    URL = `/luaran/jenis-dokumen-bukti-luaran?criteria=id_target_jenis_luaran:${id_target_jenis_luaran}&page=1:1000`;
  }
  const res = await instances.request(URL, {
    method: "GET",
  });
  return res;
};
export const updateLaporanAkhirV2 = async payload => {
  if (payload.type === "draft") {
    return instances.request({
      baseURL: API_V2_URL,
      url: "/laporan/akhir/save-draft",
      method: "PUT",
      data: payload.data,
    });
  } else if (payload.type === "submit") {
    return instances.request({
      baseURL: API_V2_URL,
      url: "/laporan/akhir/save-submit",
      method: "PUT",
      data: payload.data,
    });
  }
};
export const downloadLaporanAkhirv2 = async (data, cb, flag = 1) => {
  let {
    raw: { identitas },
  } = data.proposal;
  try {
    const fileName = `${identitas?.judul.replace(/[^a-zA-Z0-9 ]/g, "").trim()}`;
    // * Generate Pdf From Client
    const proposalPDF = new CetakLaporanAkhirV2({
      ...data,
    });
    //parameter 1 generete berisi jenis kegiatan
    //parameter 2 generete berisi flag jenis kegiatan  1 = regular, 2 = kosabangsa, 3 = prototipe;
    const blobFileMainContent = await proposalPDF.generate(
      data.kegiatan,
      data.flag_jenis_kegiatan
    );

    // * Get Pdf File From Server
    const blobFileFromServer = await proposalPDF.getMultiplefile();

    const contentMerge = [blobFileMainContent, ...blobFileFromServer];

    const mergingFun = () => {
      //merging version 1 for merge common files
      //merging version 2 for merge files ignoring encrypted file
      //merging version 3 for merge files ignoring encrypted file & lost merged the file
      proposalPDF.mergingv3(contentMerge, result => {
        const resultMerged = result.res;
        if (result.error === false) {
          if (flag !== 2) {
            proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
          }
          cb({
            success: true,
            data: resultMerged,
          });
        } else {
          mergingFun();
          toast.warn("Beberapa file tidak dapat di gabung!");
          proposalPDF.forceDownload(
            contentMerge[result.indexBlobSkipped],
            `file_${result.indexBlobSkipped - 1}.pdf`
          );
        }
      });
    };

    mergingFun();
  } catch (error) {
    console.log(error);

    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};
export const getAllFilesLuaranLaporanAkhirV1 = async (
  result,
  jenis,
  is_aktual
) => {
  if (jenis === "penelitian") {
    // Code 1 (Rekomendasi)
    const luaran = result.luaran;
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranLaporanAkhir(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res?.data?.data || [];
      const dokumen_bukti_luaran =
        item?.data_luaran?.dokumen_bukti_luaran ||
        []
          .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
          .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    if (is_aktual) {
      // penggabungan file luaran aktual
      const luaran_aktual = result.luaran_aktualisasi.filter(item => item.raw);
      if (luaran_aktual) {
        const document_luaran_exist = luaran_aktual.filter(
          item => item.raw.dokumen_bukti_luaran?.length > 0
        );
        if (document_luaran_exist) {
          document_luaran_exist.forEach(item => {
            files_luaran = files_luaran.concat(
              item.raw.dokumen_bukti_luaran.map(item => item.url_dokumen)
            );
          });
        }
      }
    }
    return files_luaran;
  } else if (jenis === "pengabdian") {
    let files_luaran = [];
    if (is_aktual) {
      const luaran_aktual = result.luaran_aktualisasi.filter(item => item.raw);
      if (luaran_aktual) {
        const document_luaran_exist = luaran_aktual.filter(
          item => item.raw.dokumen_bukti_luaran?.length > 0
        );
        if (document_luaran_exist) {
          document_luaran_exist.forEach(item => {
            files_luaran = files_luaran.concat(
              item.raw.dokumen_bukti_luaran.map(item => item.url_dokumen)
            );
          });
        }
      }
    }
    return files_luaran;
  }
};
export const getAllFilesLuaranLaporanAkhirV2 = async (result, jenis) => {
  if (jenis === "prototipe") {
    const luaran = result.data.luaran;
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranV2NoSaga(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran =
        item?.data_luaran?.dokumen_bukti_luaran ||
        []
          .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
          .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    return files_luaran;
  } else if (jenis === "kosabangsa") {
    const luaran = result.data.luaran.filter(
      item =>
        !["pendampingan_pencarian", "pendampingan_penerapan", ""].includes(
          item.group_jenis_luaran
        )
    );
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranV2NoSaga(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran =
        item?.data_luaran?.dokumen_bukti_luaran ||
        []
          .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
          .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    files_luaran = files_luaran.concat(
      result.data?.raw?.luaran_static_bast?.url_file_bast || "",

      result.data?.raw?.luaran_static_peningkatan_lvl_pemberdayaan_mitra
        ?.url_file_peningkatan_pengetahuan_mitra || "",
      result.data?.raw?.luaran_static_peningkatan_lvl_pemberdayaan_mitra
        ?.url_file_peningkatan_keterampilan_mitra || "",
      result.data?.raw?.luaran_static_peningkatan_lvl_pemberdayaan_mitra
        ?.url_file_peningkatan_aksesibilitas_mitra || "",
      result.data?.raw?.luaran_static_peningkatan_lvl_pemberdayaan_mitra
        ?.url_file_peningkatan_pendapatan_mitra || "",

      result.data?.raw?.luaran_static_dokumentasi_mitra
        ?.url_file_dokumentasi_mitra || "",

      result.data?.raw?.luaran_static_surat_pernyataan_kosabangsa
        ?.url_file_surat_pernyataan_kosabangsa || "",

      result.data?.raw?.rencana_hasil_pelaksana
        ?.url_file_rencana_hasil_pelaksana || "",

      result.data?.raw?.luaran_tambahan?.url_file_tambahan || ""
    );
    return files_luaran;
  } else if (jenis === "penelitian") {
    const luaran = result.data.luaran.filter(
      item =>
        item.data_luaran !== null &&
        ![
          "poster",
          "video_kegiatan",
          "dokumen",
          "feasibility_study",
          "",
        ].includes(item.group_jenis_luaran)
    );
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranV2NoSaga(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran =
        item?.data_luaran?.dokumen_bukti_luaran ||
        []
          .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
          .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    return files_luaran;
  } else if (jenis === "pengabdian") {
    const luaran = result.data.luaran.filter(
      item =>
        !["hak_cipta", ""].includes(item.group_jenis_luaran) &&
        item.urutan_thn_kegiatan === result.data.urutan &&
        item.data_luaran !== null
    );
    let files_luaran = [];
    for (const item of luaran) {
      const res = await getDokumenLuaranV2NoSaga(
        item.id_target_jenis_luaran,
        jenis
      );
      const options_dokumen_luaran = res.data.data;
      const dokumen_bukti_luaran =
        item?.data_luaran?.dokumen_bukti_luaran ||
        []
          .filter(f => f.id_target_luaran === item.data_luaran.id_target_luaran)
          .sort((a, b) => new Date(b.tgl_unggah) - new Date(a.tgl_unggah));
      const filteredFiles = dokumen_bukti_luaran.filter(item1 => {
        return options_dokumen_luaran.some(
          item2 =>
            item1.id_jenis_dokumen_bukti_luaran ===
            item2.id_jenis_dokumen_bukti_luaran
        );
      });
      files_luaran = files_luaran.concat(
        filteredFiles.map(item => item.url_dokumen)
      );
    }
    const { luaran_tambahan, rencana_hasil_pelaksana } = result.data.raw;
    files_luaran = files_luaran.concat([
      luaran_tambahan?.url_file_tambahan || "",
      rencana_hasil_pelaksana.url_file_rencana_hasil_pelaksana,
    ]);
    return files_luaran;
  }
};
