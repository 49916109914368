import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";
// Action Types
import {
  DASHBOARD_REQUEST,
  DASHBOARD_SYNC_SINTA_REQUEST,
  DASHBOARD_SYNC_PDDIKTI_REQUEST,
  DASHBOARD_UPDATE_IDENTITAS_REQUEST,
  TRACKING_USULAN_PENELITIAN_REQUEST,
  LIST_USULAN_REQUEST,
  GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_REQUEST,
  GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_REQUEST,
  DASHBOARD_IDENTITAS_DOSEN_REQUEST,
  DASHBOARD_SINTA_DOSEN_REQUEST,
} from "./actionTypes";
// Action
import {
  syncSintaFailure,
  syncSintaSuccess,
  syncPddiktiSuccess,
  syncPddiktiFailure,
  getDashboardFailure,
  getDashboardSuccess,
  getTrackingUsulanPenelitianSuccess,
  getTrackingUsulanPenelitianFailure,
  getListUsulanSuccess,
  getListUsulanFailure,
  getRiwayatPenelitianDashboardDosenSuccess,
  getRiwayatPenelitianDashboardDosenFailure,
  getRiwayatPengabdianDashboardDosenSuccess,
  getRiwayatPengabdianDashboardDosenFailure,
  getDashboardIdentitasDosenSuccess,
  getDashboardIdentitasDosenFailure,
  getDashboardSintaSuccess,
  getDashboardSintaFailure,
} from "./action";

import { toast } from "react-toastify";

function* getDashboards({ payload }) {
  try {
    const response = yield call(backend.getDashboards, payload);
    if (response.data.code == 200) {
      yield put(getDashboardSuccess(response.data.data, "identitas"));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDashboardFailure(message, error));
  }
}
function* getDashboardIdentitasDosen({ payload }) {
  try {
    const response = yield call(backend.getDashboards, payload);
    if (response.data.code == 200) {
      yield put(
        getDashboardIdentitasDosenSuccess(response.data.data, "identitas")
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDashboardIdentitasDosenFailure(message, error));
  }
}
function* getTrackingUsulanPenelitian({ payload }) {
  try {
    const response = yield call(backend.getTrackingUsulanPenelitian, payload);
    if (response.data.code == 200) {
      yield put(getTrackingUsulanPenelitianSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getTrackingUsulanPenelitianFailure(message, error));
  }
}
function* getListUsulan() {
  try {
    const response = yield call(backend.getListUsulanDashboardDosen);
    if (response.data.code == 200) {
      yield put(getListUsulanSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getListUsulanFailure(message, error));
  }
}
function* getDashboardSinta({ payload }) {
  try {
    const response = yield call(backend.getDashboardSinta, payload);
    if (response.data.code == 200) {
      yield put(getDashboardSintaSuccess(response.data.data, "sinta"));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDashboardSintaFailure(message, error));
  }
}

function* syncSinta({ payload }) {
  try {
    const response = yield call(backend.syncSinta, payload);
    if (response.data.code == 200) {
      yield put(syncSintaSuccess(response?.data?.data));
      toast.success("Berhasil melakukan sinkronisasi data Sinta!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(syncSintaFailure(message, error));
  }
}

function* syncPddikti({ payload }) {
  try {
    const response = yield call(backend.syncPddikti, payload);
    if (response.data.code == 200) {
      yield put(syncPddiktiSuccess(response.data.data));
      toast.success("Berhasil melakukan sinkronisasi data dengan PDDIKTI!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error(error);
    const message = error.message;
    // toast.error(
    //   "Program studi tidak ditemukan, silakan melakukan sinkronisasi prodi di menu operator"
    // );
    toast.error(message);
    yield put(syncPddiktiFailure(message, error));
  }
}

function* updateIdentitasDosen({ payload, history }) {
  try {
    const response = yield call(backend.updateIdentitasDosen, payload);
    if (response.data.code == 200) {
      toast.success("Berhasil mengubah Identitas!");
      history.push("/dashboard");
      yield put(updateIdentitasSuccess(response.data?.data || {}));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDashboardFailure(message, error));
  }
}
function* getRiwayatPenelitianDashboardDosen() {
  try {
    const response = yield call(backend.getRiwayatPenelitianDashboardDosen);
    if (response.data.code == 200) {
      yield put(getRiwayatPenelitianDashboardDosenSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getRiwayatPenelitianDashboardDosenFailure(message, error));
  }
}

function* getRiwayatPengabdianDashboardDosen() {
  try {
    const response = yield call(backend.getRiwayatPengabdianDashboardDosen);
    if (response.data.code == 200) {
      yield put(getRiwayatPengabdianDashboardDosenSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getRiwayatPengabdianDashboardDosenFailure(message, error));
  }
}
function* notificationSaga() {
  yield takeEvery(DASHBOARD_REQUEST, getDashboards);
  yield takeEvery(
    DASHBOARD_IDENTITAS_DOSEN_REQUEST,
    getDashboardIdentitasDosen
  );
  yield takeEvery(
    TRACKING_USULAN_PENELITIAN_REQUEST,
    getTrackingUsulanPenelitian
  );
  yield takeEvery(DASHBOARD_SINTA_DOSEN_REQUEST, getDashboardSinta);
  yield takeEvery(DASHBOARD_SYNC_SINTA_REQUEST, syncSinta);
  yield takeEvery(DASHBOARD_SYNC_PDDIKTI_REQUEST, syncPddikti);
  yield takeEvery(DASHBOARD_UPDATE_IDENTITAS_REQUEST, updateIdentitasDosen);
  yield takeEvery(LIST_USULAN_REQUEST, getListUsulan);
  yield takeEvery(
    GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_REQUEST,
    getRiwayatPenelitianDashboardDosen
  );
  yield takeEvery(
    GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_REQUEST,
    getRiwayatPengabdianDashboardDosen
  );
}

export default notificationSaga;
