export const OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_REQUEST =
  "OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_REQUEST";
export const OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_SUCCESS =
  "OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_SUCCESS";
export const OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_FAILURE =
  "OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_FAILURE";

export const OPERATOR_MONITORING_CATATAN_HARIAN_REQUEST =
  "OPERATOR_MONITORING_CATATAN_HARIAN_REQUEST";
export const OPERATOR_MONITORING_CATATAN_HARIAN_SUCCESS =
  "OPERATOR_MONITORING_CATATAN_HARIAN_SUCCESS";
export const OPERATOR_MONITORING_CATATAN_HARIAN_FAILURE =
  "OPERATOR_MONITORING_CATATAN_HARIAN_FAILURE";

export const OPERATOR_MONITORING_CATATAN_HARIAN_V2_REQUEST =
  "OPERATOR_MONITORING_CATATAN_HARIAN_V2_REQUEST";
export const OPERATOR_MONITORING_CATATAN_HARIAN_V2_SUCCESS =
  "OPERATOR_MONITORING_CATATAN_HARIAN_V2_SUCCESS";
export const OPERATOR_MONITORING_CATATAN_HARIAN_V2_FAILURE =
  "OPERATOR_MONITORING_CATATAN_HARIAN_V2_FAILURE";
export const CLEAN_OPERATOR_MONITORING_CATATAN_HARIAN_V2 =
  "CLEAN_OPERATOR_MONITORING_CATATAN_HARIAN_V2";

export const RESET_CATATAN_HARIAN = "RESET_CATATAN_HARIAN";
export const CATATAN_HARIAN_LAST_DETAIL = "CATATAN_HARIAN_LAST_DETAIL";
export const OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_REQUEST =
  "OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_REQUEST";
export const OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_SUCCESS =
  "OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_SUCCESS";
export const OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_FAILURE =
  "OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_FAILURE";

export const OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_REQUEST =
  "OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_REQUEST";
export const OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_SUCCESS =
  "OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_SUCCESS";
export const OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_FAILURE =
  "OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_FAILURE";

export const GET_ALL_INSTITUSI_REQUEST = "GET_ALL_INSTITUSI_REQUEST";
export const GET_ALL_INSTITUSI_SUCCESS = "GET_ALL_INSTITUSI_SUCCESS";
export const GET_ALL_INSTITUSI_FAILURE = "GET_ALL_INSTITUSI_FAILURE";

// Monitoring Lap Akhir START
export const OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_REQUEST =
  "OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_REQUEST";
export const OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_SUCCESS =
  "OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_SUCCESS";
export const OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_FAILURE =
  "OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_FAILURE";

export const OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_REQUEST =
  "OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_REQUEST";
export const OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_SUCCESS =
  "OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_SUCCESS";
export const OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_FAILURE =
  "OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_FAILURE";
// Monitoring Lap Akhir END

// Kontak PIC START
export const OPERATOR_MONITORING_LIST_KONTAK_PIC_REQUEST =
  "OPERATOR_MONITORING_LIST_KONTAK_PIC_REQUEST";
export const OPERATOR_MONITORING_LIST_KONTAK_PIC_SUCCESS =
  "OPERATOR_MONITORING_LIST_KONTAK_PIC_SUCCESS";
export const OPERATOR_MONITORING_LIST_KONTAK_PIC_FAILURE =
  "OPERATOR_MONITORING_LIST_KONTAK_PIC_FAILURE";
export const OPERATOR_MONITORING_PUT_KONTAK_PIC_REQUEST =
  "OPERATOR_MONITORING_PUT_KONTAK_PIC_REQUEST";
export const OPERATOR_MONITORING_PUT_KONTAK_PIC_SUCCESS =
  "OPERATOR_MONITORING_PUT_KONTAK_PIC_SUCCESS";
export const OPERATOR_MONITORING_PUT_KONTAK_PIC_FAILURE =
  "OPERATOR_MONITORING_PUT_KONTAK_PIC_FAILURE";
// Kontak PIC END

// Monitoring Monev START
export const OPERATOR_MONITORING_LIST_MONEV_REQUEST =
  "OPERATOR_MONITORING_LIST_MONEV_REQUEST";
export const OPERATOR_MONITORING_LIST_MONEV_SUCCESS =
  "OPERATOR_MONITORING_LIST_MONEV_SUCCESS";
export const OPERATOR_MONITORING_LIST_MONEV_FAILURE =
  "OPERATOR_MONITORING_LIST_MONEV_FAILURE";
// Monitoring Monev END

export const OPERATOR_LIST_LAPORAN_KEMAJUAN_V2_REQUEST =
  "OPERATOR_LIST_LAPORAN_KEMAJUAN_V2_REQUEST";
export const OPERATOR_LIST_LAPORAN_KEMAJUAN_V2_SUCCESS =
  "OPERATOR_LIST_LAPORAN_KEMAJUAN_V2_SUCCESS";
export const OPERATOR_LIST_LAPORAN_KEMAJUAN_V2_FAILURE =
  "OPERATOR_LIST_LAPORAN_KEMAJUAN_V2_FAILURE";

export const OPERATOR_SUMMARY_LAPORAN_KEMAJUAN_V2_REQUEST =
  "OPERATOR_SUMMARY_LAPORAN_KEMAJUAN_V2_REQUEST";
export const OPERATOR_SUMMARY_LAPORAN_KEMAJUAN_V2_SUCCESS =
  "OPERATOR_SUMMARY_LAPORAN_KEMAJUAN_V2_SUCCESS";
export const OPERATOR_SUMMARY_LAPORAN_KEMAJUAN_V2_FAILURE =
  "OPERATOR_SUMMARY_LAPORAN_KEMAJUAN_V2_FAILURE";

export const RESET_SUMMARY_LAPORAN_KEMAJUAN_V1 =
  "RESET_SUMMARY_LAPORAN_KEMAJUAN_V1";
export const RESET_SUMMARY_LAPORAN_KEMAJUAN_V2 =
  "RESET_SUMMARY_LAPORAN_KEMAJUAN_V2";
