
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../../helpers/penugasan";
// Action Types
import {
    CREATE_PENUGASAN_KOSABANGSA_REQUEST, GET_PENUGASAN_KOSABANGSA_BYID_REQUEST, GET_PENUGASAN_KOSABANGSA_REQUEST, UPDATE_PENUGASAN_KOSABANGSA_REQUEST
} from "../actionTypes";
// Action
import { toast } from "react-toastify";
import {
    createPenugasanKosaBangsaFailure, createPenugasanKosaBangsaSuccess,
    getPenugasanKosaBangsaByIdFailure, getPenugasanKosaBangsaByIdSuccess,
    getPenugasanKosaBangsaFailure, getPenugasanKosaBangsaSuccess,
    updatePenugasanKosaBangsaFailure, updatePenugasanKosaBangsaSuccess
} from "./action";


function* createPenugasanKosaBangsa({ payload, cb }) {
    try {
        const res = yield call(backend.createPenugasanKosaBangsa, payload);
        if (res.data.code === 200) {
            yield put(
                createPenugasanKosaBangsaSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan");
            cb(res.data);
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(createPenugasanKosaBangsaFailure(message.error));
        cb(null)

    }
}

function* updatePenugasanKosaBangsa({ payload, id, cb }) {
    try {
        const res = yield call(backend.updatePenugasanKosaBangsa, { payload, id });
        if (res.data.code === 200) {
            yield put(
                updatePenugasanKosaBangsaSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan di Draft");
            cb(res.data);
        } else {
            throw new Error(res.data.message);

        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(updatePenugasanKosaBangsaFailure(message.error));
        cb(null)

    }
}

function* getPenugasanKosaBangsa() {
    try {
        const res = yield call(backend.getPenugasanKosaBangsa);
        if (res.data.code === 200) {
            yield put(
                getPenugasanKosaBangsaSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanKosaBangsaFailure(message.error));
    }
}

function* getPenugasanKosaBangsaById({ payload, cb }) {
    try {

        const res = yield call(backend.getPenugasanKosaBangsaById, payload.id);
        if (res.data.code === 200) {
            yield put(
                getPenugasanKosaBangsaByIdSuccess({ data: res.data.data })
            )
            cb(res.data)
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanKosaBangsaByIdFailure(message.error));
        cb(null)
    }
}


function* notificationSaga() {
    yield takeEvery(CREATE_PENUGASAN_KOSABANGSA_REQUEST, createPenugasanKosaBangsa);
    yield takeEvery(GET_PENUGASAN_KOSABANGSA_REQUEST, getPenugasanKosaBangsa);
    yield takeEvery(GET_PENUGASAN_KOSABANGSA_BYID_REQUEST, getPenugasanKosaBangsaById);
    yield takeEvery(UPDATE_PENUGASAN_KOSABANGSA_REQUEST, updatePenugasanKosaBangsa);
}

export default notificationSaga;
