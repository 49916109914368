import {
  SBK_REQUEST,
  SBK_SUCCESS,
  SBK_FAILURE,
  TEMA_REQUEST,
  TEMA_SUCCESS,
  TEMA_FAILURE,
  TOPIK_REQUEST,
  TOPIK_SUCCESS,
  TOPIK_FAILURE,
  DOSEN_REQUEST,
  DOSEN_SUCCESS,
  DOSEN_FAILURE,
  RUMPUN_ILMU_REQUEST,
  RUMPUN_ILMU_SUCCESS,
  RUMPUN_ILMU_FAILURE,
  BIDANG_FOKUS_REQUEST,
  BIDANG_FOKUS_SUCCESS,
  BIDANG_FOKUS_FAILURE,
  TARGET_AKHIR_REQUEST,
  TARGET_AKHIR_SUCCESS,
  TARGET_AKHIR_FAILURE,
  SKEMA_KEGIATAN_REQUEST,
  SKEMA_KEGIATAN_SUCCESS,
  SKEMA_KEGIATAN_FAILURE,
  TOPIK_UNGGULAN_REQUEST,
  TOPIK_UNGGULAN_SUCCESS,
  TOPIK_UNGGULAN_FAILURE,
  BIDANG_UNGGULAN_REQUEST,
  BIDANG_UNGGULAN_SUCCESS,
  BIDANG_UNGGULAN_FAILURE,
  PERAN_PERSONIL_REQUEST,
  PERAN_PERSONIL_SUCCESS,
  PERAN_PERSONIL_FAILURE,
  UPLOAD_DOKUMEN_REQUEST,
  UPLOAD_DOKUMEN_SUCCESS,
  UPLOAD_DOKUMEN_FAILURE,
  JENIS_LUARAN_REQUEST,
  JENIS_LUARAN_SUCCESS,
  JENIS_LUARAN_FAILURE,
  KATEGORI_LUARAN_REQUEST,
  KATEGORI_LUARAN_SUCCESS,
  KATEGORI_LUARAN_FAILURE,
  GET_PENELITIAN_REQUEST,
  GET_PENELITIAN_SUCCESS,
  GET_PENELITIAN_FAILURE,
  RAB_KOMPONEN_FAILURE,
  RAB_KOMPONEN_REQUEST,
  RAB_KOMPONEN_SUCCESS,
  KATEGORI_PENELITIAN_REQUEST,
  KATEGORI_PENELITIAN_SUCCESS,
  KATEGORI_PENELITIAN_FAILURE,
  KELOMPOK_MAKRO_RISET_REQUEST,
  KELOMPOK_MAKRO_RISET_SUCCESS,
  KELOMPOK_MAKRO_RISET_FAILURE,
  KELOMPOK_RAB_BIAYA_REQUEST,
  KELOMPOK_RAB_BIAYA_SUCCESS,
  KELOMPOK_RAB_BIAYA_FAILURE,
  KATEGORI_INDIKATOR_TKT_REQUEST,
  KATEGORI_INDIKATOR_TKT_SUCCESS,
  KATEGORI_INDIKATOR_TKT_FAILURE,
  INDIKATOR_TKT_BY_KATEGORI_REQUEST,
  INDIKATOR_TKT_BY_KATEGORI_SUCCESS,
  INDIKATOR_TKT_BY_KATEGORI_FAILURE,
  GET_KETERLIBATAN_MAHASISWA_REQUEST,
  GET_KETERLIBATAN_MAHASISWA_SUCCESS,
  GET_KETERLIBATAN_MAHASISWA_FAILURE,
  CREATE_KETERLIBATAN_MAHASISWA_REQUEST,
  CREATE_KETERLIBATAN_MAHASISWA_SUCCESS,
  CREATE_KETERLIBATAN_MAHASISWA_FAILURE,
  UPDATE_KETERLIBATAN_MAHASISWA_REQUEST,
  UPDATE_KETERLIBATAN_MAHASISWA_SUCCESS,
  UPDATE_KETERLIBATAN_MAHASISWA_FAILURE,
  DELETE_KETERLIBATAN_MAHASISWA_REQUEST,
  DELETE_KETERLIBATAN_MAHASISWA_SUCCESS,
  DELETE_KETERLIBATAN_MAHASISWA_FAILURE,
  GET_JUMLAH_MAHASISWA_TERLIBAT_REQUEST,
  GET_JUMLAH_MAHASISWA_TERLIBAT_SUCCESS,
  GET_JUMLAH_MAHASISWA_TERLIBAT_FAILURE,
  GET_KATEGORI_SBK_REQUEST,
  GET_KATEGORI_SBK_SUCCES,
  GET_KATEGORI_SBK_FAILURE,
  GET_PRIORITAS_RISET_REQUEST,
  GET_PRIORITAS_RISET_SUCCESS,
  GET_PRIORITAS_RISET_FAILURE,
  GET_MAHASISWA_REQUEST,
  GET_MAHASISWA_SUCCESS,
  GET_MAHASISWA_FAILURE,
  CLEAN_MAHASISWA,
  GET_RULE_SKEMA_REQUEST,
  GET_RULE_SKEMA_SUCCESS,
  GET_RULE_SKEMA_FAILURE,
  GET_ELIGIBILITAS_DOSEN_REQUEST,
  GET_ELIGIBILITAS_DOSEN_SUCCESS,
  GET_ELIGIBILITAS_DOSEN_FAILURE,
  GET_AVAILABLE_SKEMA_REQUEST,
  GET_AVAILABLE_SKEMA_SUCCESS,
  GET_AVAILABLE_SKEMA_FAILURE,
  GET_ELIGIBILITY_REQUEST,
  GET_ELIGIBILITY_SUCCESS,
  GET_ELIGIBILITY_FAILURE,
  SAVE_IDENTITAS_USULAN_REQUEST,
  SAVE_IDENTITAS_USULAN_SUCCESS,
  SAVE_IDENTITAS_USULAN_FAILURE,
  GET_LIST_USULAN_PENELITIAN_REQUEST,
  GET_LIST_USULAN_PENELITIAN_FAILURE,
  GET_LIST_USULAN_PENELITIAN_SUCCESS,
  GET_USULAN_PENELITIAN_BYID_REQUEST,
  GET_USULAN_PENELITIAN_BYID_SUCCESS,
  GET_USULAN_PENELITIAN_BYID_FAILURE,
  SAVE_SUBTANSI_USULAN_REQUEST,
  SAVE_SUBTANSI_USULAN_FAILURE,
  SAVE_RAB_USULAN_REQUEST,
  SAVE_RAB_USULAN_SUCCESS,
  SAVE_RAB_USULAN_FAILURE,
  UPDATE_RAB_USULAN_REQUEST,
  UPDATE_RAB_USULAN_SUCCESS,
  UPDATE_RAB_USULAN_FAILURE,
  GET_LIST_NEGARA_REQUEST,
  GET_LIST_NEGARA_SUCCESS,
  GET_LIST_NEGARA_FAILURE,
  UPDATE_IDENTITAS_USULAN_REQUEST,
  UPDATE_IDENTITAS_USULAN_SUCCESS,
  UPDATE_IDENTITAS_USULAN_FAILURE,
  SAVE_MITRA_USULAN_REQUEST,
  SAVE_MITRA_USULAN_SUCCESS,
  SAVE_MITRA_USULAN_FAILURE,
  UPDATE_MITRA_USULAN_REQUEST,
  UPDATE_MITRA_USULAN_SUCCESS,
  UPDATE_MITRA_USULAN_FAILURE,
  UPDATE_SUBTANSI_USULAN_REQUEST,
  UPDATE_SUBTANSI_USULAN_FAILURE,
  UPDATE_SUBTANSI_USULAN_SUCCESS,
  GET_TEMA_TEMATIK_REQUEST,
  GET_TEMA_TEMATIK_SUCCESS,
  GET_TEMA_TEMATIK_FAILURE,
  GET_TARGET_CAPAIAN_LUARAN_REQUEST,
  GET_TARGET_CAPAIAN_LUARAN_SUCCESS,
  GET_TARGET_CAPAIAN_LUARAN_FAILURE,
  UPDATE_USULAN_PENELITIAN_REQUEST,
  UPDATE_USULAN_PENELITIAN_SUCCESS,
  UPDATE_USULAN_PENELITIAN_FAILURE,
  KELOMPOK_SKEMA_REQUEST,
  KELOMPOK_SKEMA_FAILURE,
  KELOMPOK_SKEMA_SUCCESS,
  GET_LIST_RIRN_SUCCESS,
  GET_LIST_RIRN_FAILURE,
  GET_LIST_RIRN_REQUEST,
  POST_STEP_1_IDENTITAS_PENGABDIAN_REQUEST,
  POST_STEP_1_IDENTITAS_PENGABDIAN_SUCCESS,
  POST_STEP_1_IDENTITAS_PENGABDIAN_FAILURE,
  PUT_ALL_STEP_USULAN_PENGABDIAN_REQUEST,
  PUT_ALL_STEP_USULAN_PENGABDIAN_SUCCESS,
  PUT_ALL_STEP_USULAN_PENGABDIAN_FAILURE,
  VALIDASI_KEANGGOTAAN_DOSEN_REQUEST,
  VALIDASI_KEANGGOTAAN_DOSEN_SUCCESS,
  VALIDASI_KEANGGOTAAN_DOSEN_FAILURE,
  SAVE_SUBTANSI_USULAN_SUCCESS,
  GET_LIST_PROVINSI_REQUEST,
  GET_LIST_PROVINSI_SUCCESS,
  GET_LIST_PROVINSI_FAILURE,
  GET_LIST_KOTA_REQUEST,
  GET_LIST_KOTA_SUCCESS,
  GET_LIST_KOTA_FAILURE,
  DEL_PENELITIAN_REQUEST,
  DEL_PENELITIAN_SUCCESS,
  DEL_PENELITIAN_FAILURE,
  MHS_SET_ITEM,
  GET_OPTION_MITRA_KELOMPOK_REQUEST,
  GET_OPTION_MITRA_KELOMPOK_SUCCESS,
  GET_OPTION_MITRA_KELOMPOK_FAILURE,
  GET_OPTION_MITRA_JENIS_MITRA_REQUEST,
  GET_OPTION_MITRA_JENIS_MITRA_SUCCESS,
  GET_OPTION_MITRA_JENIS_MITRA_FAILURE,
  GET_WHITELIST_USULAN_REQUEST,
  GET_WHITELIST_USULAN_SUCCESS,
  GET_WHITELIST_USULAN_FAILURE,
  GET_LIST_KERJASAMA_LUAR_NEGERI_REQUEST,
  GET_LIST_KERJASAMA_LUAR_NEGERI_SUCCESS,
  GET_LIST_KERJASAMA_LUAR_NEGERI_FAILURE,
  SAVE_MINAT_KATALIS_REQUEST,
  SAVE_MINAT_KATALIS_SUCCESS,
  SAVE_MINAT_KATALIS_FAILURE,
  GET_MINAT_KATALIS_REQUEST,
  GET_MINAT_KATALIS_SUCCESS,
  GET_MINAT_KATALIS_FAILURE,
  GET_DETAILS_MINAT_KATALIS_REQUEST,
  GET_DETAILS_MINAT_KATALIS_SUCCESS,
  GET_DETAILS_MINAT_KATALIS_FAILURE,
  CLEAN_DETAILS_MINAT_KATALIS,
  DELETE_MINAT_KATALIS_REQUEST,
  DELETE_MINAT_KATALIS_SUCCESS,
  DELETE_MINAT_KATALIS_FAILURE,
  APPROVAL_MEMBER_MINAT_KATALIS_REQUEST,
  APPROVAL_MEMBER_MINAT_KATALIS_FAILURE,
  APPROVAL_MEMBER_MINAT_KATALIS_SUCCESS,
  APPROVAL_PUSAT_MINAT_KATALIS_REQUEST,
  APPROVAL_PUSAT_MINAT_KATALIS_SUCCESS,
  APPROVAL_PUSAT_MINAT_KATALIS_FAILURE,
  CLEAN_DOSEN
} from "./actionTypes";

export const approvalPusatMinatKatalis = (request = {}, cb = () => { }) => {
  return {
    type: APPROVAL_PUSAT_MINAT_KATALIS_REQUEST,
    request,
    cb
  }
}

export const approvalPusatMinatKatalisSuccess = (response) => {
  return {
    type: APPROVAL_PUSAT_MINAT_KATALIS_SUCCESS,
    payload: response,
  }
}

export const approvalPusatMinatKatalisFailure = (message, response) => {
  return {
    type: APPROVAL_PUSAT_MINAT_KATALIS_FAILURE,
    message,
    errors: response,
  }
}

export const approvalMemberMinatKatalis = (request = {}, cb = () => { }) => {
  return {
    type: APPROVAL_MEMBER_MINAT_KATALIS_REQUEST,
    request,
    cb
  }
}

export const approvalMemberMinatKatalisSuccess = (response) => {
  return {
    type: APPROVAL_MEMBER_MINAT_KATALIS_SUCCESS,
    payload: response,
  }
}

export const approvalMemberMinatKatalisFailure = (message, response) => {
  return {
    type: APPROVAL_MEMBER_MINAT_KATALIS_FAILURE,
    message,
    errors: response,
  }
}

export const cleanDetailsMinatKatalis = () => {
  return {
    type: CLEAN_DETAILS_MINAT_KATALIS,
  }
}

export const deleteMinatKatalis = (id) => {
  return {
    type: DELETE_MINAT_KATALIS_REQUEST,
    id,

  }
}

export const deleteMinatKatalisSuccess = (id) => {
  return {
    type: DELETE_MINAT_KATALIS_SUCCESS,
    id
  }
}

export const deleteMinatKatalisFailure = (message, response) => {
  return {
    type: DELETE_MINAT_KATALIS_FAILURE,
    message,
    errors: response,
  }
}

export const getDetailsMinatKatalis = (request = {}, cb = () => { }) => {
  return {
    type: GET_DETAILS_MINAT_KATALIS_REQUEST,
    request,
    cb
  }
}

export const getDetailsMinatKatalisSuccess = (response) => {
  return {
    type: GET_DETAILS_MINAT_KATALIS_SUCCESS,
    payload: response,
  }
}

export const getDetailsMinatKatalisFailure = (message, response) => {
  return {
    type: GET_DETAILS_MINAT_KATALIS_FAILURE,
    message,
    errors: response,
  }
}

export const getListMinatKatalis = (request = {}) => {
  return {
    type: GET_MINAT_KATALIS_REQUEST,
    request,
  }
}

export const getListMinatKatalisSuccess = (response) => {
  return {
    type: GET_MINAT_KATALIS_SUCCESS,
    payload: response,
  }
}

export const getListMinatKatalisFailure = (message, response) => {
  return {
    type: GET_MINAT_KATALIS_FAILURE,
    message,
    errors: response,
  }
}

export const saveMinatKatalis = (request = {}, cb = () => { }) => {
  return {
    type: SAVE_MINAT_KATALIS_REQUEST,
    request,
    cb
  }
}

export const saveMinatKatalisSuccess = (response) => {
  return {
    type: SAVE_MINAT_KATALIS_SUCCESS,
    payload: response,
  }
}

export const saveMinatKatalisFailure = (message, response) => {
  return {
    type: SAVE_MINAT_KATALIS_FAILURE,
    message,
    errors: response,
  }
}

export const getListProgramKerjasamaLn = (request = {}, cb = () => { }) => {
  return {
    type: GET_LIST_KERJASAMA_LUAR_NEGERI_REQUEST,
    payload: request,
    cb
  }
}

export const getListProgramKerjasamaLnSuccess = (response) => {
  return {
    type: GET_LIST_KERJASAMA_LUAR_NEGERI_SUCCESS,
    payload: response,
  }
}

export const getListProgramKerjasamaLnFailure = (message, response) => {
  return {
    type: GET_LIST_KERJASAMA_LUAR_NEGERI_FAILURE,
    message,
    errors: response,
  }
}

export const getWhiteListUsulan = (request = {}, cb = () => { }) => {
  return {
    type: GET_WHITELIST_USULAN_REQUEST,
    payload: request,
    cb
  }
}

export const getWhiteListUsulanSuccess = (response) => {
  return {
    type: GET_WHITELIST_USULAN_SUCCESS,
    payload: response,
  }
}

export const getWhiteListUsulanFailure = (message, response) => {
  return {
    type: GET_WHITELIST_USULAN_FAILURE,
    message,
    errors: response,
  }
}

export const getListNegara = () => {
  return {
    type: GET_LIST_NEGARA_REQUEST,
  };
};

export const getListNegaraSuccess = response => {
  return {
    type: GET_LIST_NEGARA_SUCCESS,
    payload: response,
  };
};

export const getListNegaraFailure = (message, response) => {
  return {
    type: GET_LIST_NEGARA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Get Egibility
export const getEligibility = (request = {}, cb = () => { }) => {
  return {
    type: GET_ELIGIBILITY_REQUEST,
    payload: request,
    cb,
  };
};

export const getEligibilitySuccess = response => {
  return {
    type: GET_ELIGIBILITY_SUCCESS,
    payload: response,
  };
};

export const getEligibilityFailure = (message, response) => {
  return {
    type: GET_ELIGIBILITY_FAILURE,

    payload: {
      message,
      errors: response,
    },
  };
};

//Get Egibility
export const getAvailableSkema = (request = {}) => {
  return {
    type: GET_AVAILABLE_SKEMA_REQUEST,
    payload: request,
  };
};

export const getAvailableSkemaSuccess = response => {
  return {
    type: GET_AVAILABLE_SKEMA_SUCCESS,
    payload: response,
  };
};

export const getAvailableSkemaFailure = (message, response) => {
  return {
    type: GET_AVAILABLE_SKEMA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Get List Usulan
export const getListUsulanPenelitian = (cb = () => { }) => {
  return {
    type: GET_LIST_USULAN_PENELITIAN_REQUEST,
    cb,
  };
};

export const getListUsulanPenelitianSuccess = response => {
  return {
    type: GET_LIST_USULAN_PENELITIAN_SUCCESS,
    payload: response,
  };
};

export const getListUsulanPenelitianFailure = (message, response) => {
  return {
    type: GET_LIST_USULAN_PENELITIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Get Usulan Penelitian By Id
export const getUsulanPenelitianById = (id, cb = () => { }) => {

  return {
    type: GET_USULAN_PENELITIAN_BYID_REQUEST,
    id,
    cb,
  };
};

export const getUsulanPenelitianByIdSuccess = response => {
  return {
    type: GET_USULAN_PENELITIAN_BYID_SUCCESS,
    payload: response,
  };
};

export const getUsulanPenelitianByIdFailure = (message, response) => {
  return {
    type: GET_USULAN_PENELITIAN_BYID_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Save identitas Usulan
export const saveIdentitasUsulan = (request = {}, cb = () => { }) => {
  return {
    type: SAVE_IDENTITAS_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

export const saveIdentitasUsulanSuccess = response => {
  return {
    type: SAVE_IDENTITAS_USULAN_SUCCESS,
    payload: response,
  };
};

export const saveIdentitasUsulanFailure = (message, response) => {
  return {
    type: SAVE_IDENTITAS_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const updateUsulanPenelitan = (request = {}, cb = () => { }) => {
  return {
    type: UPDATE_USULAN_PENELITIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const updateUsulanPenelitanSuccess = response => {
  return {
    type: UPDATE_USULAN_PENELITIAN_SUCCESS,
    payload: response,
  };
};

export const updateUsulanPenelitanFailure = (message, response) => {
  return {
    type: UPDATE_USULAN_PENELITIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Update identitas Usulan
export const updateIdentitasUsulan = (request = {}, cb = () => { }) => {
  return {
    type: UPDATE_IDENTITAS_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

export const updateIdentitasUsulanSuccess = response => {
  return {
    type: UPDATE_IDENTITAS_USULAN_SUCCESS,
    payload: response,
  };
};

export const updateIdentitasUsulanFailure = (message, response) => {
  return {
    type: UPDATE_IDENTITAS_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//save mitra Usulan
export const saveMitraUsulan = (request = {}, cb = () => { }) => {
  return {
    type: SAVE_MITRA_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

export const saveMitraUsulanSuccess = response => {
  return {
    type: SAVE_MITRA_USULAN_SUCCESS,
    payload: response,
  };
};

export const saveMitraUsulanFailure = (message, response) => {
  return {
    type: SAVE_MITRA_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//update mitra usulan
export const updateMitraUsulan = (request = {}, cb = () => { }) => {
  return {
    type: UPDATE_MITRA_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

export const updateMitraUsulanSuccess = response => {
  return {
    type: UPDATE_MITRA_USULAN_SUCCESS,
    payload: response,
  };
};

export const updateMitraUsulanFailure = (message, response) => {
  return {
    type: UPDATE_MITRA_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Save RAB Usulan
export const saveRABUsulan = (request = {}, cb = () => { }) => {
  return {
    type: SAVE_RAB_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

export const saveRABUsulanSuccess = response => {
  return {
    type: SAVE_RAB_USULAN_SUCCESS,
    payload: response,
  };
};

export const saveRABUsulanFailure = (message, response) => {
  return {
    type: SAVE_RAB_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const updateRABUsulan = (request = {}, cb = () => { }) => {
  return {
    type: UPDATE_RAB_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

export const updateRABUsulanSuccess = response => {
  return {
    type: UPDATE_RAB_USULAN_SUCCESS,
    payload: response,
  };
};

export const updateRABUsulanFailure = (message, response) => {
  return {
    type: UPDATE_RAB_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//SAVE SUBTANSI USULAN
export const saveSubtansiUsulan = (request = {}, cb = () => { }) => {
  return {
    type: SAVE_SUBTANSI_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

//save Subtansi
export const saveSubtansiUsulanSuccess = response => {
  return {
    type: SAVE_SUBTANSI_USULAN_SUCCESS,
    payload: response,
  };
};

export const saveSubtansiUsulanFailure = (message, response) => {
  return {
    type: SAVE_SUBTANSI_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Update Subtansi Usulan
export const updateSubtansiUsulan = (request = {}, cb = () => { }) => {
  return {
    type: UPDATE_SUBTANSI_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

//save Subtansi
export const updateSubtansiUsulanSuccess = response => {
  return {
    type: UPDATE_SUBTANSI_USULAN_SUCCESS,
    payload: response,
  };
};

export const updateSubtansiUsulanFailure = (message, response) => {
  return {
    type: UPDATE_SUBTANSI_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//ELIGIBILITAS DOSEN
export const getEligibilitasDosen = (request = {}, cb = () => { }) => {
  return {
    type: GET_ELIGIBILITAS_DOSEN_REQUEST,
    payload: request,
    cb,
  };
};

export const getEligibilitasDosenSuccess = response => {
  return {
    type: GET_ELIGIBILITAS_DOSEN_SUCCESS,
    payload: response,
  };
};

export const getEligibilitasDosenFailure = (message, response) => {
  return {
    type: GET_ELIGIBILITAS_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//RULE SKEMA
export const getRuleSkema = (request = {}, cb = () => { }) => {
  return {
    type: GET_RULE_SKEMA_REQUEST,
    payload: request,
    cb,
  };
};

export const getRuleSkemaSuccess = response => {
  return {
    type: GET_RULE_SKEMA_SUCCESS,
    payload: response,
  };
};

export const getRuleSkemaFailure = (message, response) => {
  return {
    type: GET_RULE_SKEMA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//KELOMPOK SKEMA
export const getKelompokSkema = (request = {}, cb = () => { }) => {
  return {
    type: KELOMPOK_SKEMA_REQUEST,
    payload: request,
    cb,
  };
};

export const getKelompokSkemaSuccess = response => {
  return {
    type: KELOMPOK_SKEMA_SUCCESS,
    payload: response,
  };
};

export const getKelompokSkemaFailure = (message, response) => {
  return {
    type: KELOMPOK_SKEMA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Mahasiswa
export const cleanMahasiswa = () => {
  return {
    type: CLEAN_MAHASISWA,
  };
};

export const getMahasiswa = (request = "", cb = () => { }) => {
  return {
    type: GET_MAHASISWA_REQUEST,
    payload: request,
    cb,
  };
};

export const setMahasiswaItem = (request = "", cb = () => { }) => {
  return {
    type: MHS_SET_ITEM,
    payload: request,
    cb,
  };
};

export const getMahasiswaSuccess = response => {
  return {
    type: GET_MAHASISWA_SUCCESS,
    payload: response,
  };
};

export const getMahasiswaFailure = (message, response) => {
  return {
    type: GET_MAHASISWA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Kategori SBK
export const getKategoriSBK = (request = "") => {
  return {
    type: GET_KATEGORI_SBK_REQUEST,
    payload: request,
  };
};

export const getKategoriSBKSuccess = response => {
  return {
    type: GET_KATEGORI_SBK_SUCCES,
    payload: response,
  };
};

export const getKategoriSBKFailure = (message, response) => {
  return {
    type: GET_KATEGORI_SBK_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//Prioritas Riset
export const getPrioritasRiset = (request = "") => {
  return {
    type: GET_PRIORITAS_RISET_REQUEST,
    payload: request,
  };
};

export const getPrioritasRisetSuccess = response => {
  return {
    type: GET_PRIORITAS_RISET_SUCCESS,
    payload: response,
  };
};

export const getPrioritasRisetFailure = (message, response) => {
  return {
    type: GET_PRIORITAS_RISET_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Kategori Penelitian
export const getKategoriPenelitian = (request = "") => {
  return {
    type: KATEGORI_PENELITIAN_REQUEST,
    payload: request,
  };
};

export const getKategoriPenelitianSuccess = response => {
  return {
    type: KATEGORI_PENELITIAN_SUCCESS,
    payload: response,
  };
};

export const getKategoriPenelitianFailure = (message, response) => {
  return {
    type: KATEGORI_PENELITIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Kategori TKT
export const getKategoriIndikatorTKT = (request = "") => {
  return {
    type: KATEGORI_INDIKATOR_TKT_REQUEST,
    payload: request,
  };
};

export const getKategoriIndikatorTKTSuccess = response => {
  return {
    type: KATEGORI_INDIKATOR_TKT_SUCCESS,
    payload: response,
  };
};

export const getKategoriIndikatorTKTFailure = (message, response) => {
  return {
    type: KATEGORI_INDIKATOR_TKT_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Indikator by Kategori
export const getIndikatorTKTByKategori = (request = {}, cb = () => { }) => {
  return {
    type: INDIKATOR_TKT_BY_KATEGORI_REQUEST,
    payload: request,
    cb,
  };
};

export const getIndikatorTKTByKategoriSuccess = response => {
  return {
    type: INDIKATOR_TKT_BY_KATEGORI_SUCCESS,
    payload: response,
  };
};

export const getIndikatorTKTByKategoriFailure = (message, response) => {
  return {
    type: INDIKATOR_TKT_BY_KATEGORI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Topik
export const getTopik = (request = {}) => {
  return {
    type: TOPIK_REQUEST,
    payload: request,
  };
};

export const getTopikSuccess = response => {
  return {
    type: TOPIK_SUCCESS,
    payload: response,
  };
};

export const getTopikFailure = (message, response) => {
  return {
    type: TOPIK_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Tema
export const getTema = (request = {}) => {
  return {
    type: TEMA_REQUEST,
    payload: request,
  };
};

export const getTemaSuccess = response => {
  return {
    type: TEMA_SUCCESS,
    payload: response,
  };
};

export const getTemaFailure = (message, response) => {
  return {
    type: TEMA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// SBK
export const getSbk = (request = {}) => {
  return {
    type: SBK_REQUEST,
    payload: request,
  };
};

export const getSbkSuccess = response => {
  return {
    type: SBK_SUCCESS,
    payload: response,
  };
};

export const getSbkFailure = (message, response) => {
  return {
    type: SBK_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Bidang Fokus
export const getBidangFokus = (request = {}) => {
  return {
    type: BIDANG_FOKUS_REQUEST,
    payload: request,
  };
};

export const getBidangFokusSuccess = response => {
  return {
    type: BIDANG_FOKUS_SUCCESS,
    payload: response,
  };
};

export const getBidangFokusFailure = (message, response) => {
  return {
    type: BIDANG_FOKUS_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Rumpun Ilmu
export const getRumpunIlmu = (request = {}, cb = () => { }) => {
  return {
    type: RUMPUN_ILMU_REQUEST,
    payload: request,
    cb,
  };
};

export const getRumpunIlmuSuccess = response => {
  return {
    type: RUMPUN_ILMU_SUCCESS,
    payload: response,
  };
};

export const getRumpunIlmuFailure = (message, response) => {
  return {
    type: RUMPUN_ILMU_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Target Akhir TKT
export const getTargetAkhirTKT = (request = {}) => {
  return {
    type: TARGET_AKHIR_REQUEST,
    payload: request,
  };
};

export const getTargetAkhirTKTSuccess = response => {
  return {
    type: TARGET_AKHIR_SUCCESS,
    payload: response,
  };
};

export const getTargetAkhirTKTFailure = (message, response) => {
  return {
    type: TARGET_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Skema Kegiatan
export const getSkemaKegiatan = (request = {}) => {
  return {
    type: SKEMA_KEGIATAN_REQUEST,
    payload: request,
  };
};

export const getSkemaKegiatanSuccess = response => {
  return {
    type: SKEMA_KEGIATAN_SUCCESS,
    payload: response,
  };
};

export const getSkemaKegiatanFailure = (message, response) => {
  return {
    type: SKEMA_KEGIATAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Get Bidang Unggulan
export const getBidangUnggulan = (request = {}) => {
  return {
    type: BIDANG_UNGGULAN_REQUEST,
    payload: request,
  };
};

export const getBidangUnggulanSuccess = response => {
  return {
    type: BIDANG_UNGGULAN_SUCCESS,
    payload: response,
  };
};

export const getBidangUnggulanFailure = (message, response) => {
  return {
    type: BIDANG_UNGGULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Get Topik Unggulan
export const getTopikUnggulan = (request = {}) => {
  return {
    type: TOPIK_UNGGULAN_REQUEST,
    payload: request,
  };
};

export const getTopikUnggulanSuccess = response => {
  return {
    type: TOPIK_UNGGULAN_SUCCESS,
    payload: response,
  };
};

export const getTopikUnggulanFailure = (message, response) => {
  return {
    type: TOPIK_UNGGULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Get Dosen
export const getDosen = (request = {}, cb = () => { }) => {
  return {
    type: DOSEN_REQUEST,
    payload: request,
    cb,
  };
};

export const getDosenSuccess = response => {
  return {
    type: DOSEN_SUCCESS,
    payload: response,
  };
};

export const getDosenFailure = (message, response) => {
  return {
    type: DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const cleanDosen = () => {
  return {
    type: CLEAN_DOSEN,
  };
};

// GET VALIDASI Keanggotaan DOSEN SKEMA
export const getValidasiKeanggotanDosen = (request = {}, cb = () => { }) => {
  return {
    type: VALIDASI_KEANGGOTAAN_DOSEN_REQUEST,
    payload: request,
    cb,
  };
};

export const getValidasiKeanggotanDosenSuccess = response => {
  return {
    type: VALIDASI_KEANGGOTAAN_DOSEN_SUCCESS,
    payload: response,
  };
};

export const getValidasiKeanggotanDosenFailure = (message, response) => {
  return {
    type: VALIDASI_KEANGGOTAAN_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Get Peran Personil
export const getPeranPersonil = (request = {}) => {
  return {
    type: PERAN_PERSONIL_REQUEST,
    payload: request,
  };
};

export const getPeranPersonilSuccess = response => {
  return {
    type: PERAN_PERSONIL_SUCCESS,
    payload: response,
  };
};

export const getPeranPersonilFailure = (message, response) => {
  return {
    type: PERAN_PERSONIL_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Get Kelompok Makro Riset
export const getKelompokMakroRiset = (request = {}) => {
  return {
    type: KELOMPOK_MAKRO_RISET_REQUEST,
    payload: request,
  };
};

export const getKelompokMakroRisetSuccess = response => {
  return {
    type: KELOMPOK_MAKRO_RISET_SUCCESS,
    payload: response,
  };
};

export const getKelompokMakroRisetFailure = (message, response) => {
  return {
    type: KELOMPOK_MAKRO_RISET_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Upload Dokumen
export const uploadDokumen = (request = {}, cb = () => { }) => {
  return {
    type: UPLOAD_DOKUMEN_REQUEST,
    payload: request,
    cb,
  };
};

export const uploadDokumenSuccess = response => {
  return {
    type: UPLOAD_DOKUMEN_SUCCESS,
    payload: response,
  };
};

export const uploadDokumenFailure = (message, response) => {
  return {
    type: UPLOAD_DOKUMEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// jenis Luaran
export const getJenisLuaran = (request = "", cb = () => { }) => {
  return {
    type: JENIS_LUARAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getJenisLuaranSuccess = response => {
  return {
    type: JENIS_LUARAN_SUCCESS,
    payload: response,
  };
};

export const getJenisLuaranFailure = (message, response) => {
  return {
    type: JENIS_LUARAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// target capaian Luaran
export const getTargetCapaianLuaran = (request = "", cb = () => { }) => {
  return {
    type: GET_TARGET_CAPAIAN_LUARAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getTargetCapaianLuaranSuccess = response => {
  return {
    type: GET_TARGET_CAPAIAN_LUARAN_SUCCESS,
    payload: response,
  };
};

export const getTargetCapaianLuaranFailure = (message, response) => {
  return {
    type: GET_TARGET_CAPAIAN_LUARAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Kategori Luaran
export const getKategoriLuaran = (request = {}, cb = () => { }) => {
  return {
    type: KATEGORI_LUARAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getKategoriLuaranSuccess = response => {
  return {
    type: KATEGORI_LUARAN_SUCCESS,
    payload: response,
  };
};

export const getKategoriLuaranFailure = (message, response) => {
  return {
    type: KATEGORI_LUARAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Penelitian
export const getPenelitian = (request = {}, cb) => {
  return {
    type: GET_PENELITIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getPenelitianSuccess = response => {
  return {
    type: GET_PENELITIAN_SUCCESS,
    payload: response,
  };
};

export const getPenelitianFailure = (message, response) => {
  return {
    type: GET_PENELITIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const deletePenelitian = (id, cb) => {
  return {
    type: DEL_PENELITIAN_REQUEST,
    id,
    cb,
  };
};

export const deletePenelitianSuccess = response => {
  return {
    type: DEL_PENELITIAN_SUCCESS,
    payload: response,
  };
};

export const deletePenelitianFailure = (message, response) => {
  return {
    type: DEL_PENELITIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Kelompok RAB Biaya
export const getKelompokRabBiaya = (request = null, cb) => {
  return {
    type: KELOMPOK_RAB_BIAYA_REQUEST,
    payload: request,
    cb,
  };
};

export const getKelompokRabBiayaSuccess = response => {
  return {
    type: KELOMPOK_RAB_BIAYA_SUCCESS,
    payload: response,
  };
};

export const getKelompokRabBiayaFailure = (message, response) => {
  return {
    type: KELOMPOK_RAB_BIAYA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getRABKomponen = (request = {}, cb) => {
  return {
    type: RAB_KOMPONEN_REQUEST,
    payload: request,
    cb,
  };
};

export const getRABKomponenSuccess = response => {
  return {
    type: RAB_KOMPONEN_SUCCESS,
    payload: response,
  };
};

export const getRABKomponenFailure = (message, response) => {
  return {
    type: RAB_KOMPONEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Get Keterlibatan Mahasiswa
export const getKeterlibatanMahasiswa = (payload = {}, cb) => {
  return {
    type: GET_KETERLIBATAN_MAHASISWA_REQUEST,
    payload,
    cb
  };
};

export const getKeterlibatanMahasiswaSuccess = response => {
  return {
    type: GET_KETERLIBATAN_MAHASISWA_SUCCESS,
    payload: response,
  };
};

export const getKeterlibatanMahasiswaFailure = (message, response) => {
  return {
    type: GET_KETERLIBATAN_MAHASISWA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Create Keterlibatan Mahasiswa
export const createKeterlibatanMahasiswa = (payload = {}) => {
  return {
    type: CREATE_KETERLIBATAN_MAHASISWA_REQUEST,
    payload,
  };
};

export const createKeterlibatanMahasiswaSuccess = response => {
  return {
    type: CREATE_KETERLIBATAN_MAHASISWA_SUCCESS,
    payload: response,
  };
};

export const createKeterlibatanMahasiswaFailure = (message, response) => {
  return {
    type: CREATE_KETERLIBATAN_MAHASISWA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Update Keterlibatan Mahasiswa
export const updateKeterlibatanMahasiswa = (payload = {}) => {
  return {
    type: UPDATE_KETERLIBATAN_MAHASISWA_REQUEST,
    payload,
  };
};

export const updateKeterlibatanMahasiswaSuccess = response => {
  return {
    type: UPDATE_KETERLIBATAN_MAHASISWA_SUCCESS,
    payload: response,
  };
};

export const updateKeterlibatanMahasiswaFailure = (message, response) => {
  return {
    type: UPDATE_KETERLIBATAN_MAHASISWA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Delete Keterlibatan Mahasiswa
export const deleteKeterlibatanMahasiswa = id => {
  return {
    type: DELETE_KETERLIBATAN_MAHASISWA_REQUEST,
    id,
  };
};

export const deleteKeterlibatanMahasiswaSuccess = id => {
  return {
    type: DELETE_KETERLIBATAN_MAHASISWA_SUCCESS,
    payload: {
      id,
    },
  };
};

export const deleteKeterlibatanMahasiswaFailure = (message, response) => {
  return {
    type: DELETE_KETERLIBATAN_MAHASISWA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Get Jumlah Mahasiswa
export const getJumlahMahasiswaTerlibat = idUsulanKegiatan => {
  return {
    type: GET_JUMLAH_MAHASISWA_TERLIBAT_REQUEST,
    idUsulanKegiatan,
  };
};

export const getJumlahMahasiswaTerlibatSuccess = response => {
  return {
    type: GET_JUMLAH_MAHASISWA_TERLIBAT_SUCCESS,
    payload: response,
  };
};

export const getJumlahMahasiswaTerlibatFailure = (message, response) => {
  return {
    type: GET_JUMLAH_MAHASISWA_TERLIBAT_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// pengabdian start
// get tema tematik (pengabdian)
export const getListTemaPrioritasTematik = idUsulanKegiatan => {
  return {
    type: GET_TEMA_TEMATIK_REQUEST,
    idUsulanKegiatan,
  };
};

export const getListTemaPrioritasTematikSuccess = response => {
  return {
    type: GET_TEMA_TEMATIK_SUCCESS,
    payload: response,
  };
};

export const getListTemaPrioritasTematikFailure = (message, response) => {
  return {
    type: GET_TEMA_TEMATIK_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// get list rirn (pengabdian)
export const getListBidangFokusRirn = idUsulanKegiatan => {
  return {
    type: GET_LIST_RIRN_REQUEST,
    idUsulanKegiatan,
  };
};

export const getListBidangFokusRirnSuccess = response => {
  return {
    type: GET_LIST_RIRN_SUCCESS,
    payload: response,
  };
};

export const getListBidangFokusRirnFailure = (message, response) => {
  return {
    type: GET_LIST_RIRN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// post step 1 pengabdian identtas (pengabdian)
export const postStep1IdentitasPengabdian = (request = {}, cb = () => { }) => {
  return {
    type: POST_STEP_1_IDENTITAS_PENGABDIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const postStep1IdentitasPengabdianSuccess = response => {
  return {
    type: POST_STEP_1_IDENTITAS_PENGABDIAN_SUCCESS,
    payload: response,
  };
};

export const postStep1IdentitasPengabdianFailure = (message, response) => {
  return {
    type: POST_STEP_1_IDENTITAS_PENGABDIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// PUT ALL STEP (pengabdian)
export const putAllStepUsulanPengabdian = (request = {}, cb = () => { }) => {
  return {
    type: PUT_ALL_STEP_USULAN_PENGABDIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const putAllStepUsulanPengabdianSuccess = response => {
  return {
    type: PUT_ALL_STEP_USULAN_PENGABDIAN_SUCCESS,
    payload: response,
  };
};

export const putAllStepUsulanPengabdianFailure = (message, response) => {
  return {
    type: PUT_ALL_STEP_USULAN_PENGABDIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getListProvinsi = () => {
  return {
    type: GET_LIST_PROVINSI_REQUEST,
  };
};

export const getListProvinsiSuccess = response => {
  return {
    type: GET_LIST_PROVINSI_SUCCESS,
    payload: response,
  };
};

export const getListProvinsiFailure = (message, response) => {
  return {
    type: GET_LIST_PROVINSI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const getListKota = () => {
  return {
    type: GET_LIST_KOTA_REQUEST,
  };
};

export const getListKotaSuccess = response => {
  return {
    type: GET_LIST_KOTA_SUCCESS,
    payload: response,
  };
};

export const getListKotaFailure = (message, response) => {
  return {
    type: GET_LIST_KOTA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getListOptionMitraKelompok = (request = {}) => {
  return {
    type: GET_OPTION_MITRA_KELOMPOK_REQUEST,
    payload: request,
  };
};

export const getListOptionMitraKelompokSuccess = response => {
  return {
    type: GET_OPTION_MITRA_KELOMPOK_SUCCESS,
    payload: response,
  };
};

export const getListOptionMitraKelompokFailure = (message, response) => {
  return {
    type: GET_OPTION_MITRA_KELOMPOK_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getListOptionMitraJenisMitra = (request = {}) => {
  return {
    type: GET_OPTION_MITRA_JENIS_MITRA_REQUEST,
    payload: request,
  };
};

export const getListOptionMitraJenisMitraSuccess = response => {
  return {
    type: GET_OPTION_MITRA_JENIS_MITRA_SUCCESS,
    payload: response,
  };
};

export const getListOptionMitraJenisMitraFailure = (message, response) => {
  return {
    type: GET_OPTION_MITRA_JENIS_MITRA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
