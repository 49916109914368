import {
  GET_BYID_PROTOTIPE_FAILURE,
  GET_BYID_PROTOTIPE_REQUEST,
  GET_BYID_PROTOTIPE_SUCCESS,
  GET_LIST_PROTOTIPE_FAILURE,
  GET_LIST_PROTOTIPE_REQUEST,
  GET_LIST_PROTOTIPE_SUCCESS,
  SAVE_PROTOTIPE_FAILURE,
  SAVE_PROTOTIPE_REQUEST,
  SAVE_PROTOTIPE_SUCCESS,
  UPDATE_PROTOTIPE_FAILURE,
  UPDATE_PROTOTIPE_REQUEST,
  UPDATE_PROTOTIPE_SUCCESS,
  DELETE_PROTOTIPE_REQUEST,
  DELETE_PROTOTIPE_SUCCESS,
  DELETE_PROTOTIPE_FAILURE,
  GET_ELIGIBILITY_PROTOTIPE_REQUEST,
  GET_ELIGIBILITY_PROTOTIPE_SUCCESS,
  GET_ELIGIBILITY_PROTOTIPE_FAILURE,
  APPROVAL_LPPM_PROTOTIPE_REQUEST,
  APPROVAL_LPPM_PROTOTIPE_SUCCESS,
  APPROVAL_LPPM_PROTOTIPE_FAILURE,
  GET_COUNTING_USULAN_PROTOTIPE_REQUEST,
  GET_COUNTING_USULAN_PROTOTIPE_SUCCESS,
  GET_COUNTING_USULAN_PROTOTIPE_FAILURE,
  CLEAN_DETAILS_PROPOSAL,
} from "./actionTypes";

const initialState = {
  save_prototipe: {
    isFetching: false,
    item: {},
  },
  count_prototipe: {
    isFetching: false,
    item: {},
  },
  approval_prototipe: {
    isFetching: false,
    item: {},
  },
  delete_prototipe: {
    isFetching: false,
    item: {},
  },
  list_prototipe: {
    isFetching: false,
    items: [],
  },
  prototipe: {
    isFetching: false,
    item: {},
  },
  eligibility_ketua_prototipe: {
    isFetching: false,
    item: {},
  },
};

const Prototipe = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_DETAILS_PROPOSAL:
      return (state = {
        ...state,
        prototipe: {
          ...state.prototipe,
          item: {},
        },
      });
    case GET_COUNTING_USULAN_PROTOTIPE_REQUEST:
      return (state = {
        ...state,
        count_prototipe: {
          ...state.count_prototipe,
          isFetching: true,
        },
      });
    case GET_COUNTING_USULAN_PROTOTIPE_SUCCESS:
      return (state = {
        ...state,
        count_prototipe: {
          ...state.count_prototipe,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case GET_COUNTING_USULAN_PROTOTIPE_FAILURE:
      return (state = {
        ...state,
        count_prototipe: {
          ...state.count_prototipe,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case APPROVAL_LPPM_PROTOTIPE_REQUEST:
      return (state = {
        ...state,
        approval_prototipe: {
          ...state.approval_prototipe,
          isFetching: true,
        },
      });
    case APPROVAL_LPPM_PROTOTIPE_SUCCESS:
      return (state = {
        ...state,
        approval_prototipe: {
          ...state.approval_prototipe,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case APPROVAL_LPPM_PROTOTIPE_FAILURE:
      return (state = {
        ...state,
        approval_prototipe: {
          ...state.approval_prototipe,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_ELIGIBILITY_PROTOTIPE_REQUEST:
      return (state = {
        ...state,
        eligibility_ketua_prototipe: {
          ...state.eligibility_ketua_prototipe,
          isFetching: true,
        },
      });
    case GET_ELIGIBILITY_PROTOTIPE_SUCCESS:
      return (state = {
        ...state,
        eligibility_ketua_prototipe: {
          ...state.eligibility_ketua_prototipe,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case GET_ELIGIBILITY_PROTOTIPE_FAILURE:
      return (state = {
        ...state,
        eligibility_ketua_prototipe: {
          ...state.eligibility_ketua_prototipe,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case DELETE_PROTOTIPE_REQUEST:
      return (state = {
        ...state,
        delete_prototipe: {
          ...state.delete_prototipe,
          isFetching: true,
        },
      });
    case DELETE_PROTOTIPE_SUCCESS:
      return (state = {
        ...state,
        delete_prototipe: {
          ...state.delete_prototipe,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case DELETE_PROTOTIPE_FAILURE:
      return (state = {
        ...state,
        delete_prototipe: {
          ...state.delete_prototipe,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_LIST_PROTOTIPE_REQUEST:
      return (state = {
        ...state,
        list_prototipe: {
          ...state.list_prototipe,
          isFetching: true,
        },
      });
    case GET_LIST_PROTOTIPE_SUCCESS:
      return (state = {
        ...state,
        list_prototipe: {
          ...state.list_prototipe,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_LIST_PROTOTIPE_FAILURE:
      return (state = {
        ...state,
        list_prototipe: {
          ...state.list_prototipe,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_BYID_PROTOTIPE_REQUEST:
      return (state = {
        ...state,
        prototipe: {
          ...state.prototipe,
          isFetching: true,
          item: {},
        },
      });
    case GET_BYID_PROTOTIPE_SUCCESS: {
      const object = {
        ...state.prototipe,
        isFetching: false,
        item: action.payload.data,
      }
      if (action.payload.id_proposal) {
        object[`item_${action.payload.id_proposal}`] = action.payload.data;
      }
      return (state = {
        ...state,
        prototipe: {
          ...object
        },
      });
    }
    case GET_BYID_PROTOTIPE_FAILURE:
      return (state = {
        ...state,
        prototipe: {
          ...state.prototipe,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case SAVE_PROTOTIPE_REQUEST:
    case UPDATE_PROTOTIPE_REQUEST:
      return (state = {
        ...state,

        save_prototipe: {
          ...state.save_prototipe,
          isFetching: true,
        },
      });
    case SAVE_PROTOTIPE_SUCCESS:
    case UPDATE_PROTOTIPE_SUCCESS:
      return (state = {
        ...state,
        save_prototipe: {
          ...state.save_prototipe,
          isFetching: false,
          item: action.payload,
        },
        prototipe: {
          ...state.prototipe,
          item: action.payload.data,
        },
      });
    case SAVE_PROTOTIPE_FAILURE:
    case UPDATE_PROTOTIPE_FAILURE:
      return (state = {
        ...state,
        save_prototipe: {
          ...state.save_prototipe,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    default:
      return state;
  }
};

export default Prototipe;
