/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";
import {
  getListMinatKatalisOptSuccess,
  getListMinatKatalisOptFailure,
  updateStatusMinatKatalisOptSuccess,
  updateStatusMinatKatalisOptFailure,
} from "./action";

import {
  GET_LIST_MINAT_KATALIS_OPT_REQUEST,
  UPDATE_MINAT_KATALIS_OPT_REQUEST,
} from "./actionTypes";

function* getListMinatKatalisOpt({ payload, cb }) {
  try {
    const res = yield call(backend.getListMinatKatalisOpt, payload);
    if (res.data.code === 200) {
      yield put(getListMinatKatalisOptSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListMinatKatalisOptFailure(message?.error));
  }
}
function* updateStatusMinatKatalisOpt({ payload, cb }) {
  try {
    const res = yield call(backend.updateStatusMinatKatalisOpt, payload);
    if (res.data.code === 200) {
      yield put(updateStatusMinatKatalisOptSuccess(res.data.data));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(updateStatusMinatKatalisOptFailure(message?.error));
  }
}
function* operatorCommonSaga() {
  yield takeEvery(GET_LIST_MINAT_KATALIS_OPT_REQUEST, getListMinatKatalisOpt);
  yield takeEvery(
    UPDATE_MINAT_KATALIS_OPT_REQUEST,
    updateStatusMinatKatalisOpt
  );
}

export default operatorCommonSaga;
