//AUTHENTICATE
export const URL_LOGOUT = "/logout";
export const POST_LOGIN = "/auth/login";
export const POST_REGISTER = "/register";
export const POST_PASSWORD_FORGET = "/forgot-password";
export const CHANGE_PASSWORD = "/user/identitas";

// NOTIFICATIONS
export const GET_NOTIFICATIONS = "/notifications";

// DASHBOARD
export const SYNC_SINTA = "/dosen/sinta/sync";
export const GET_DASHBOARD_DOSEN = "/dosen/identitas";
export const GET_DASHBOARD_DOSEN_SINTA = "/dosen/sinta";
export const UPDATE_IDENTITAS_DOSEN = "/dosen/identitas";
export const SYNC_PDDIKRI = "/dosen/pddikti/sync";
export const ENDPOINT_RIWAYAT_PENELITIAN = "/penelitian/penelitian";
export const ENDPOINT_RIWAYAT_PENGABDIAN = "/pengabdian/pengabdian";
// USULAN PENELITIAN
export const ENDPOINT_PENELITIAN = "/penelitian/penelitian";
export const ENDPOINT_DOSEN = "/dosen";
export const ENDPOINT_SBK = "/referensi/sbk";
export const ENDPOINT_TEMA = "/referensi/tema";
export const ENDPOINT_TOPIK = "/referensi/topik";
export const ENDPOINT_JENIS_LUARAN = "/penelitian/jenis-luaran";
export const ENDPOINT_KATEGORI_LUARAN = "/referensi/kategori-jenis-luaran";
export const ENDPOINT_UPLOAD = "/file/document/usulan";
export const GET_KATEGORI_INDIKATOR_TKT = "/tkt/kategori";
export const GET_INDIKATOR_BY_KATEGORI = "/tkt/indikator";
export const ENDPOINT_RUMPUN_ILMU = "/referensi/rumpun-ilmu";
export const ENDPOINT_KATEGORI_RAB = "/referensi/rab-kelompok-biaya";
export const ENDPOINT_BIDANG_FOKUS = "/referensi/bidang-fokus";
export const GET_KATEGORI_PENELITIAN = "/referensi/program-hibah";
export const KATEGORI_PENELITIAN = "/referensi/kategori-penelitian";
export const ENDPOINT_SKEMA_KEGIATAN = "/referensi/skema-kegiatan";
export const ENDPOINT_KELOMPOK_SKEMA = "";
export const ENDPOINT_PERSONAL_PERAN = "/referensi/peran-personil";
export const ENDPOINT_KELOMPOK_MAKRO_RISET = "/referensi/makro-riset";
export const ENDPOINT_TARGET_AKHIR_TKT = "/referensi/target-akhir-tkt";
export const ENDPOINT_BIDANG_UNGGULAN = "/referensi/bidang-unggulan-stranas";
export const ENDPOINT_KATEGORI_SBK = "/referensi/kategori-sbk";
export const ENDPOINT_PRIORITAS_RISET = "/referensi/tema-prioritas";
export const ENDPOINT_MAHASISWA = "/pdpt/list-mahasiswa";
export const ENDPOINT_TARGET_JENIS_LUARAN = "/luaran/target-jenis-luaran";
export const ENDPOINT_TARGET_CAPAIAN_LUARAN =
  "/referensi/target-capaian-luaran";
export const ENDPOINT_RULE_SKEMA = "/referensi/rules-skema-penelitian";
export const ENDPOINT_ELIGIBIILITAS_DOSEN =
  "/dosen/usulan-proposal-eligibility";
export const ENDPOINT_ELIGIBILITY = "/proposal/penelitian/eligibility";
export const ENDPOINT_AVAILABLE_SKEMA =
  "/proposal/penelitian/available-skema-by-eligibility";
export const ENDPOINT_SAVE_IDENTITAS_USULAN = "/proposal/penelitian/identitas";
export const ENDPOINT_PROPOSAL_PENELITIAN = "/proposal/penelitian";
export const ENDPOINT_LIST_USULAN_PENELITIAN = "/proposal/penelitian";
export const ENDPOINT_SAVE_SUBTANSI_USULAN = "/proposal/penelitian/substansi";
export const ENDPOINT_SAVE_RAB_USULAN = "/hibah/rab-item-belanja/bulk";
export const ENDPOINT_SAVE_MITRA_USULAN = "/proposal/penelitian/mitra/bulk";
export const ENDPOINT_GET_LIST_NEGARA = "/pdpt/negara";
export const ENDPOINT_WHITELIST_USULAN = "/proposal/whitelist";

// USULAN PENGABDIAN
export const ENDPOINT_ELIGIBILITY_PENGABDIAN =
  "/proposal/pengabdian/eligibility";
export const ENDPOINT_AVAILABLE_SKEMA_PENGABDIAN =
  "/proposal/pengabdian/available-skema-by-eligibility";
export const ENDPOINT_RULE_SKEMA_PENGABDIAN =
  "/referensi/rules-skema-pengabdian";
export const ENDPOINT_GET_LIST_PROVINSI = "/pdpt/provinsi";
export const ENDPOINT_GET_LIST_KOTA = "/pdpt/kota";
// Catatan Harian
export const ENDPOINT_CATATAN_HARIAN = "/hibah/catatan-harian-pelaksanaan";
export const ENDPOINT_UPLOAD_CATATAN_HARIAN = "/hibah/berkas-catatan-harian";

// Usulan Perbaikan
export const ENDPOINT_USULAN = "/hibah/usulan";
export const ENDPOINT_USULAN_PERBAIKAN = "/perbaikan";
export const ENDPOINT_USULAN_PERBAIKAN_SUBMIT =
  "/penelitian/penelitian/perbaikan";
export const ENDPOINT_KETERLIBATAN_MAHASISWA = "/hibah/keterlibatan-mahasiswa";
export const ENDPOINT_USULAN_PERBAIKAN_V2 = "/proposal/revisi";

//RAB
export const ENDPOINT_RAB_KOMPONEN = "/referensi/rab-komponen-belanja";
export const ENDPOINT_RAB_KELOMPOK = "/referensi/rab-kelompok-biaya";

//USULAN PENGABDIAN
export const ENDPOINT_PENGABDIAN = "/pengabdian/pengabdian";

//Dashboard tracking usulan
export const ENDPOINT_TRACKING_USULAN = "/hibah/usulan/tracking-usulan-current";

//Dashbord list usulan
export const ENDPOINT_LIST_USULAN = "/dashboard/usulan";

//**********LAPORAN KEMAJUAN********

//[GET | PUT | POST | DEL]
export const ENDPOINT_DETAIL_LAPORAN_KEMAJUAN =
  "/penelitian/laporan-kemajuan/by-id-usulan-kegiatan";
export const ENDPOINT_DETAIL_LAPORAN_KEMAJUAN_PENGABDIAN =
  "/pengabdian/laporan-kemajuan/by-id-usulan-kegiatan";
export const ENDPOINT_LIST_LAPORAN_KEMAJUAN = "/penelitian/laporan-kemajuan";
export const ENDPOINT_LAPORAN_KEMAJUAN_FILE = "/file";
export const ENDPOINT_UPLOAD_DOCS = "/file";

// PENGUMUMAN ( BIMA SUPER ADMIN )
export const ENDPOINT_PENGUMUMAN = "/pengumuman";
export const ENDPOINT_PENGUMUMAN_FILE = "/pengumuman/file";
// BERITA ( BIMA SUPER ADMIN )
export const ENDPOINT_BERITA = "/berita";
export const ENDPOINT_BERITA_FILE = "/berita/file";
// DATA PENDUKUNG ( OPERATOR PT )
export const ENDPOINT_PT_DOSEN = "/pt/dosen";
export const ENDPOINT_PT_DOSEN_BY_NIDN =
  "/dashboard/usulan/dashboard-dosen-nidn";
export const ENDPOINT_SYNC_PT_DOSEN = "/dosen/pddikti/sync-by-nidn";
export const ENDPOINT_RESET_PWD = "/pt/dosen/reset-pass-dsn-nidn";
export const ENDPOINT_PERSONAL_DATA_DOSEN = "/personal";
export const ENDPOINT_KETUA_LPPM = "/pengguna/peran/peran-pengguna";
export const ENDPOINT_DAFTAR_PRODI = "/pdpt/prodi";
export const ENDPOINT_SEARCH_PRODI_TO_PDDIKTI = "/dosen/pddikti/get-prodi";
export const ENDPOINT_SINKRONISASI_PRODI = "/dosen/pddikti/sync-prodi";
export const ENDPOINT_TAMBAH_DATA_DOSEN = "/dosen/identitas/by-nidn";
export const ENDPOINT_SEARCH_DOSEN_TO_BIMA = "/dosen/identitas/by-nidn";
export const ENDPOINT_LEMBAGA = "/pdpt/lembaga";
export const ENDPOINT_STATUS_DOSEN = "/dosen/status";
export const ENDPOINT_PUT_STATUS_DOSEN = "/dosen";
export const ENDPOINT_PERSONAL_SEARCH = "/personal/search";

// * Operator Dashboard
export const ENDPOINT_DASHBOARD_OPERATOR_PT_TKT = "/tkt/institusi";
//OPERATOR MONITORING
export const ENDPOINT_OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN =
  "/hibah/usulan/GetListUsulanPerbaikanPT";
export const ENDPOINT_OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_PUSAT =
  "/hibah/usulan/GetListUsulanPerbaikanPTByPT";
export const ENDPOINT_OPERATOR_MONITORING_LIST_CATATAN_HARIAN =
  "/hibah/catatan-harian-pelaksanaan/catatan-harian-pt";
export const ENDPOINT_OPERATOR_MONITORING_LIST_CATATAN_HARIAN_V2 =
  "/catatan-harian/operator";
export const ENDPOINT_OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN =
  "/penelitian/laporan-kemajuan/get-all-skema-pt";
export const ENDPOINT_OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL =
  "/penelitian/laporan-kemajuan/get-all-pt";
export const ENDPOINT_LINK_BERKAS_LAPORAN_KEMAJUAN =
  "/penelitian/laporan-kemajuan/summary-for-monev";
export const ENDPOINT_LIST_ALL_INSTITUSI = "/tkt/institusi/dropdown";
// ENUMERATION
export const ENDPOINT_ENUMERATION = "/enumeration";

// PENUGASAN
export const ENDPOINT_PENUGASAN_NUSANTARA = "/penugasan/nusantara";
export const ENDPOINT_PENUGASAN_FILE = "/file";
export const ENDPOINT_SBK_UTAMA = "/referensi/sbk/sbk-utama";
export const ENDPOINT_PENUGASAN_PROTOTIPE = "/penugasan/prototipe";
export const ENDPOINT_VALIDASI_PROTOTIPE = "/penugasan/prototipe/validations";
export const ENDPOINT_PENUGASAN_JFS = "/penugasan/jfs";
export const ENDPOINT_PENUGASAN_GOOGLE = "/penugasan/google/bangkit";
export const ENDPOINT_PENUGASAN_PEMBELAJARAN = "/penugasan/google/14m";
export const ENDPOINT_PENUGASAN_KOSABANGSA = "/penugasan/kosabangsa";
export const ENDPOINT_INSTITUSI = "/tkt/institusi";

// MONEV
export const ENDPOINT_SKEMA_MONEV = "/monev/skema";
export const ENDPOINT_USULAN_MONEV = "/monev/usulan";
export const ENDPOINT_INSTITUSI_MONEV = "/monev/institusi";
export const ENDPOINT_KOMP_PENILAIAN_MONEV = "/monev/komponen-penilaian";
export const ENDPOINT_HASIL_REVIEW_TERAKHIR_MONEV = "/monev/hasil-review";
export const ENDPOINT_LINK_BERKAS_MONEV =
  "/penelitian/laporan-kemajuan/summary-for-monev";

// * Operator Pengelolaan Reviewer (Daftar Reviewer)
export const ENDPOINT_REVIEWER_PENDAFTARAN = "/hibah/pendaftaran-reviewer";
export const ENDPOINT_LIST_DAFTAR_REVIEWER = "/hibah/reviewer/penelitian";
export const ENDPOINT_DROPDOWN_TAHAPAN_PENGELOLAAN_REVIEWER =
  "/public/config-tahapan-klaster-institusi";
export const ENDPOINT_DROPDOWN_TAHUN_KEGIATAN = "/public/config-tahun-kegiatan";
export const ENDPOINT_GET_SKEMA_REVIEWER = "/hibah/usulan/skema-reviewer";
export const ENDPOINT_GET_SKEMA_REVIEWER_BY_ID = "/hibah/usulan/skema-reviewer";
export const ENDPOINT_SEARCH_REVIEWER_PT =
  "/hibah/reviewer/penelitian/nasional-pt";
export const ENDPOINT_SEARCH_REVIEWER_PUSAT =
  "/hibah/reviewer/penelitian/nasional";
export const ENDPOINT_SEARCH_REVIEWER_PENGABDIAN_PUSAT =
  "/hibah/reviewer/pengabdian/nasional";
export const ENDPOINT_PLOTTING_REVIEWER_PENELITIAN =
  "/hibah/reviewer/penelitian";

// laporan akhir
export const ENDPOINT_CREATE_UPDATE_LAPORAN_AKHIR = "/laporan-akhir";
export const ENDPOINT_LIST_LAPORAN_AKHIR = "/laporan-akhir";
export const ENDPOINT_LAPORAN_AKHIR_FILE = "/file";
export const ENPOINT_CETAK_DOKUMEN_LAPORAN_AKHIR_AND_KEMAJUAN =
  "/laporan/summary-for-monev";
// laporan akhir pengabdian
export const ENDPOINT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN =
  "/laporan-akhir/mitra";

// Opt Persetujuan Usulan
export const ENDPOINT_USULAN_DITINJAU =
  "/hibah/usulan/dashboard-usulan-ditinjau";
export const EDNPOINT_USULAN_BARU_DAN_DIKIRIM =
  "/hibah/usulan/dashboard-usulan";
export const EDNPOINT_USULAN_DISETUJUI_DAN_DITOLAK =
  "/hibah/usulan/dashboard-usulan-status-approval";
export const EDNPOINT_SETUJUI_ATAU_TOLAK_USULAN =
  "/hibah/usulan/hasil-approval";
export const EDNPOINT_BATALKAN_USULAN = "/hibah/usulan/revert";
export const ENDPOINT_ALASAN_PENOLAKAN = "/referensi/alasan-penolakan-approval";
export const ENDPOINT_SUMMARY_USULAN_GORUP_SKEMA = "/proposal/operator-rekap-usulan";

// Opt Monitoring Lap Akhir
export const ENDPOINT_MONITORING_LIST_LAPORAN_AKHIR = "/laporan-akhir/global";
export const ENDPOINT_MONITORING_DETAIL_LAPORAN_AKHIR =
  "/laporan-akhir/global/by-id-usulan-kegiatan";
export const ENDPOINT_GET_VIEW_DETAIL_LAPORAN_AKHIR = "/laporan-akhir/global";

// Opt Pusat Kontak PIC Start
export const ENDPOINT_PUSAT_KONTAK_PIC_LIST = "/pusat-kontak-pic"; // Sementara
export const ENDPOINT_PUSAT_KONTAK_PIC_PUT = "/pusat-kontak-pic"; // Sementara
// Opt Pusat Kontak PIC End

// DOSEN NOTIFICATION
export const ENDPOINT_NOTIFICATION_LIST = "/notification";
// DOSEN PERSONEL INVITATION
export const ENDPOINT_PERSONEL_INVITATION_DETAIL =
  "/proposal/penelitian/by-usulan-kegiatan";
// DOSEN PERSONEL INVITATION PENGABDIAN
export const ENDPOINT_PERSONEL_INVITATION_PENGABDIAN_DETAIL =
  "/proposal/pengabdian/by-usulan-kegiatan";
// DOSEN PATCH NOTIFIKASI
export const ENDPOINT_PATCH_NOTIFICATION = "/notification";
// DOSEN PATCH PERSONEL INVITATION
export const ENDPOINT_PATCH_INVITATION =
  "/proposal/penelitian/invitation-approval";

// Opt Monitoring Monev Start
export const ENDPOINT_OPT_LIST_MONEV = "/DUMMY/FOR-DEVELOPMENT";
// Opt Monitoring Monev End

// USULAN PENGABDIAN
export const ENDPOINT_SAVE_STEP_1_IDENTITAS_PENGABDIAN =
  "/proposal/pengabdian/identitas";
export const ENDPOINT_LIST_USULAN_PENGABDIAN = "/proposal/pengabdian";

// EVALUASI ADMINISTRASI
export const ENDPOINT_INSTITUSI_ADMINISTRASI =
  "/evaluasi/administrasi/institusi";
export const ENDPOINT_SKEMA_EVALUASI = "/evaluasi/administrasi/skema";
export const ENDPOINT_LIST_USULAN_EVALUASI = "/evaluasi/administrasi/usulan";
export const ENDPOINT_KOMPONEN_NILAI_EVALUASI =
  "evaluasi/administrasi/komponen-penilaian";
export const ENDPOINT_SET_PERMANEN_EVALUASI =
  "evaluasi/administrasi/set-permanen";

// EVALUASI SUBTANSI
export const ENDPOINT_SKEMA_EVALUASI_SUBTANSI = "/evaluasi/subtansi/institusi";
export const ENDPOINT_KELOMPOK_EVALUASI_V2 = "/review/kelompok";
export const ENDPOINT_KOMPONEN_EVALUASI_V2 = "/review/penilaian/komponen";
export const ENDPOINT_PROPOSAL_EVALUASI_V2 = "/review";
export const ENDPOINT_PROPOSAL_DETAIL_PENILAIAN_EVALUASI_V2 =
  "/review/penilaian";
export const ENDPOINT_SAVE_PENILAIAN_EVALUASI_V2 = "/review/penilaian";
export const ENDPOINT_SAVE_PERMANEN_EVALUASI_V2 = "/review/permanent";
export const ENDPOINT_INSTITUSI_EVALUASI = "/review/institusi";
export const ENDPOINT_PROPOSAL_DETAIL_PENILAIAN_EVALUASI_LUARAN = "/review/penilaian-luaran/komponen";

export const ENDPOINT_LIST_USULAN_EVALUASI_SUBTANSI =
  "/evaluasi/subtansi/usulan";
export const ENDPOINT_KOMPONEN_NILAI_EVALUASI_SUBTANSI =
  "evaluasi/subtansi/komponen-penilaian";
export const ENDPOINT_SET_PERMANEN_EVALUASI_SUBTANSI =
  "evaluasi/subtansi/set-permanen";
export const ENDPOINT_RAB_REKOMENDASI = "/hibah/rab-rekomendasi";

// EVALUASI SUBTANSI
export const ENDPOINT_SKEMA_SITE_VISIT = "/evaluasi/site-visit/institusi";
export const ENDPOINT_LIST_USULAN_SITE_VISIT = "/evaluasi/site-visit/usulan";
export const ENDPOINT_KOMPONEN_NILAI_SITE_VISIT =
  "evaluasi/site-visit/komponen-penilaian";
export const ENDPOINT_SET_PERMANEN_SITE_VISIT =
  "evaluasi/site-visit/set-permanen";

//BIMTEK PROPOSAL
export const ENDPOINT_BIMTEK_PROPOSAL = "/proposal/bimtek";

//endpoint proposal (api v2)
export const ENDPOINT_PROPOSAL = "/proposal";
export const ELIGIBILITY = "/proposal/eligibility";
export const ENDPOINT_SUMMARY_PROPOSAL = "/proposal/summary-total";
export const ENDPOINT_MINAT_KATALIS = "/minat-katalis";
//Laporan Akhir v2
export const ENDPOINT_LAPORAN_AKHIR = "/laporan/akhir";
//KOMPONEN PENILAIAN LUARAN
export const ENDPOINT_PENILAIAN_LUARAN = "/review/penilaian-luaran"

//KEKAYAAN INTELEKTUAL (KI)
export const ENDPOINT_KI_PENGAJUAN = "/proposal";
export const ENDPOINT_ELIGIBILITY_DOSEN_KI = "/proposal/eligibility";
export const ENDPOINT_SIMILAR_TITLE_KI_REVIEWER = "/proposal/get-similar-titles";
export const ENDPOINT_KI_REVIEWER = "/review/plot";
export const ENDPOINT_EVALUATE_KI_REVIEWER = "/review/evaluate";
export const ENDPOINT_KI_PENUGASAN_REVIEWER = "/review/reviewer";
export const ENDPOINT_KI_KEGIATAN_BIMTEK = "/kegiatan-bimtek";


//KONFIGURASI KI
export const ENDPOINT_BATCH_KI = "/batch";
export const WHITLIST_KI_EP = "/whitelist";

export const PRIOVINSI = "/pdpt/provinsi";
export const KOTA = "/pdpt/kota";
export const KECAMATAN = "/pdpt/kecamatan";
export const KELURAHAN = "/pdpt/kelurahan";