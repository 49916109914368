import {
  PENGUMUMAN_REQUEST,
  PENGUMUMAN_SUCCESS,
  PENGUMUMAN_FAILURE,
  PENGUMUMAN_LANDING_REQUEST,
  PENGUMUMAN_LANDING_SUCCESS,
  PENGUMUMAN_LANDING_FAILURE,
  PENGUMUMAN_BY_ID_REQUEST,
  PENGUMUMAN_BY_ID_SUCCESS,
  PENGUMUMAN_BY_ID_FAILURE,
  DELETE_PENGUMUMAN_REQUEST,
  DELETE_PENGUMUMAN_SUCCESS,
  CREATE_PENGUMUMAN_REQUEST,
  CREATE_PENGUMUMAN_SUCCESS,
  PENGUMUMAN_UPLOAD_REQUEST,
  PENGUMUMAN_UPLOAD_SUCCESS,
  UPDATE_PENGUMUMAN_REQUEST,
  UPDATE_PENGUMUMAN_SUCCESS,
  UPDATE_PENGUMUMAN_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  isLoading: false,
  errorMessage: "",
  errors: null,
  items: [],
  itemSelected: {},
  msg: "",
};

const PengumumanReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET PENGUMUMAN START
    case PENGUMUMAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "PENGUMUMAN_REQUEST",
      };
    case PENGUMUMAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        items: action.payload ?? [],
        msg: "PENGUMUMAN_SUCCESS",
      };
    case PENGUMUMAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "PENGUMUMAN_FAILURE",
      };
    // GET PENGUMUMAN END

    // GET PENGUMUMAN START
    case PENGUMUMAN_LANDING_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "PENGUMUMAN_REQUEST",
      };
    case PENGUMUMAN_LANDING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        items: action.payload ?? [],
        msg: "PENGUMUMAN_SUCCESS",
      };
    case PENGUMUMAN_LANDING_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "PENGUMUMAN_FAILURE",
      };
    // GET PENGUMUMAN END

    // GET PENGUMUMAN BY ID START
    case PENGUMUMAN_BY_ID_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "PENGUMUMAN_BY_ID_REQUES",
      };
    case PENGUMUMAN_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        itemSelected: action.payload ?? {},
        msg: "PENGUMUMAN_BY_ID_SUCCES",
      };
    case PENGUMUMAN_BY_ID_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "PENGUMUMAN_BY_ID_FAILUR",
      };
    // GET PENGUMUMAN BY ID END

    // DELETE PENGUMUMAN START
    case DELETE_PENGUMUMAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case DELETE_PENGUMUMAN_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id
      );
      if (selectedIndex !== -1) items.splice(selectedIndex, 1);
      return {
        ...state,
        isFetching: false,
        items,
      };
    }
    // DELETE PENGUMUMAN END

    // CREATE PENGUMUMAN START
    case CREATE_PENGUMUMAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case CREATE_PENGUMUMAN_SUCCESS: {
      const items = state.items;
      if (action.payload) items.unshift(action.payload);
      return {
        ...state,
        isFetching: false,
        items,
        itemSelected: action.payload,
      };
    }
    // CREATE PENGUMUMAN END

    // UPLOAD PENGUMUMAN START
    case PENGUMUMAN_UPLOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PENGUMUMAN_UPLOAD_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id_catatan_harian
      );
      if (selectedIndex !== -1) {
        if (items[selectedIndex].berkas_catatan_harian) {
          items[selectedIndex].berkas_catatan_harian.unshift(action.payload);
        } else {
          items[selectedIndex].berkas_catatan_harian = [action.payload];
        }
      }
      return {
        ...state,
        isFetching: false,
        items,
      };
    }
    // UPLOAD PENGUMUMAN END

    // EDIT PENGUMUMAN START
    case UPDATE_PENGUMUMAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case UPDATE_PENGUMUMAN_SUCCESS: {
      const items = state.items; // get all items from state and store in items variable to use in the next step
      const selectedIndex = items.findIndex(item =>
        item.id === action.payload.id_pengumuman ? action.payload : {}
      ); // find the index of the item with the id of the payload id_pengumuman and store in selectedIndex variable to use in the next step
      if (selectedIndex !== -1) {
        items[selectedIndex] = action.payload;
      } // if the selectedIndex is not -1, then replace the item with the payload item in the items array at the selectedIndex position and store in items variable to use in the next step to replace the item in the state with the new item from the payload item in the items array at the selectedIndex position
      return {
        ...state,
        isFetching: false,
        items,
        itemSelected: action.payload,
      };
    }
    case UPDATE_PENGUMUMAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // EDIT PENGUMUMAN END

    // DELETE FILE START
    case DELETE_FILE_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case DELETE_FILE_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id
      );
      if (selectedIndex !== -1) items.splice(selectedIndex, 1);
      return {
        ...state,
        isFetching: false,
        items,
      };
    }
    case DELETE_FILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // DELETE FILE END

    // Default
    default:
      return state;
  }
};

export default PengumumanReducer;
