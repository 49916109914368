import {
   CLEAN_TEMPLATES_DOCS,
   GET_TEMPLATES_DOCS_FAILURE,
   GET_TEMPLATES_DOCS_REQUEST,
   GET_TEMPLATES_DOCS_SUCCESS
} from "./actionTypes";

export const getTempalteDocs = (request = {}) => {
   return {
      type: GET_TEMPLATES_DOCS_REQUEST,
      payload: request
   };
}
export const getTempalteDocsSuccess = (response = {}) => {
   return {
      type: GET_TEMPLATES_DOCS_SUCCESS,
      payload: response
   };
}
export const getTempalteDocsFailure = (message, response) => {
   return {
      type: GET_TEMPLATES_DOCS_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}
export const cleanTemplateDocs = () => {
   return {
      type: CLEAN_TEMPLATES_DOCS,
   };
}