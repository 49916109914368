import {
  BERITA_REQUEST,
  BERITA_SUCCESS,
  BERITA_FAILURE,
  BERITA_LANDING_REQUEST,
  BERITA_LANDING_SUCCESS,
  BERITA_LANDING_FAILURE,
  BERITA_BY_ID_REQUEST,
  BERITA_BY_ID_SUCCESS,
  BERITA_BY_ID_FAILURE,
  CREATE_BERITA_REQUEST,
  CREATE_BERITA_SUCCESS,
  CREATE_BERITA_FAILURE,
  DELETE_BERITA_REQUEST,
  DELETE_BERITA_SUCCESS,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPDATE_BERITA_REQUEST,
  UPDATE_BERITA_SUCCESS,
  UPDATE_BERITA_FAILURE,
  DELETE_IMG_BERITA_REQUEST,
  DELETE_IMG_BERITA_SUCCESS,
  DELETE_IMG_BERITA_FAILURE,
} from "./actionType";

// init state
const initialState = {
  isFetching: false,
  isLoading: false,
  errorMessage: "",
  errors: null,
  items: [],
  itemSelected: {},
  msg: "",
};

const beritaReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET BERITA PAGE START
    case BERITA_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "BERITA_REQUEST",
      };
    case BERITA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        items: action.payload ?? [],
        msg: "BERITA_SUCCESS",
      };
    case BERITA_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "BERITA_FAILURE",
      };
    // GET BERITA PAGE END

    // GET BERITA FOR LANDING PAGE START
    case BERITA_LANDING_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "BERITA_LANDING_REQUEST",
      };
    case BERITA_LANDING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        items: action.payload ?? [],
        msg: "BERITA_LANDING_SUCCESS",
      };
    case BERITA_LANDING_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "BERITA_LANDING_FAILURE",
      };
    // GET BERITA FOR LANDING PAGE END

    // GET BERITA BY ID START
    case BERITA_BY_ID_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "BERITA_BY_ID_REQUEST",
      };
    case BERITA_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        itemSelected: action.payload ?? {},
        msg: "BERITA_BY_ID_SUCCESS",
      };
    case BERITA_BY_ID_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "BERITA_BY_ID_FAILURE",
      };
    // GET BERITA BY ID END

    // CREATE BERITA START
    case CREATE_BERITA_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "CREATE_BERITA_REQUEST",
      };
    // CREATE BERITA SUCCESS
    case CREATE_BERITA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        itemSelected: action.payload ?? {},
        msg: "CREATE_BERITA_SUCCESS",
      };
    case CREATE_BERITA_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "CREATE_BERITA_FAILURE",
      };
    // CREATE BERITA END

    // UPLOAD FILE START
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        msg: "UPLOAD_FILE_REQUEST",
      };
    case UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        itemSelected: action.payload ?? {},
        msg: "UPLOAD_FILE_SUCCESS",
      };
    }
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "UPLOAD_FILE_FAILURE",
      };
    // UPLOAD FILE END

    // DELETE BERITA START
    case DELETE_BERITA_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
      };
    case DELETE_BERITA_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id
      );
      if (selectedIndex !== -1) items.splice(selectedIndex, 1);
      return {
        ...state,
        isFetching: false,
        items,
      };
    }
    // DELETE BERITA END

    // UPDATE BERITA START
    case UPDATE_BERITA_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        itemSelected: {},
        msg: "UPDATE_BERITA_REQUEST",
      };
    case UPDATE_BERITA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        itemSelected: action.payload ?? {},
        msg: "UPDATE_BERITA_SUCCESS",
      };

    case UPDATE_BERITA_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "UPDATE_BERITA_FAILURE",
      };
    //UPDATE BERITA END

    // DELETE IMG BERITA START
    case DELETE_IMG_BERITA_REQUEST:
      return {
        ...state,
        isFetching: true,
        itemSelected: {},
        msg: "DELETE_IMG_BERITA_REQUEST",
      };
    // case DELETE_IMG_BERITA_SUCCESS: {
    //   const itemSelected = state.itemSelected;
    //   const selectedIndex = itemSelected.pengumuman.findIndex(
    //     item => item.id === action.payload.id
    //   );
    //   if (selectedIndex !== -1)
    //     itemSelected.pengumuman.splice(selectedIndex, 1);
    //   return {
    //     ...state,
    //     isFetching: false,
    //     itemSelected,
    //     msg: "DELETE_IMG_BERITA_SUCCESS",
    //   };
    // }

    // case DELETE_IMG_BERITA_SUCCESS: {
    //   const items = state.items;
    //   const selectedIndex = items.findIndex(
    //     item => item.id === action.payload.id_pengumuman
    //   );
    //   if (selectedIndex !== -1) {
    //     const selectedIndexBerita = items[selectedIndex].pengumuman.findIndex(
    //       item => item.id === action.payload.id
    //     );
    //     if (selectedIndex !== -1) {
    //       items[selectedIndex].pengumuman.splice(selectedIndexBerita, 1);
    //     }
    //     console.log("pengumuman", selectedIndexBerita);
    //   }
    //   console.log("action", action);
    //   console.log("action.payload", action.payload);
    //   console.log("selectedIndex", selectedIndex);
    //   return {
    //     ...state,
    //     isFetching: false,
    //     items,
    //     msg: "DELETE_IMG_BERITA_SUCCESS",
    //   };
    // }

    case DELETE_IMG_BERITA_SUCCESS: {
      const items = state.items;
      const selectedIndex = items.findIndex(
        item => item.id === action.payload.id
      );
      if (selectedIndex !== -1) items.splice(selectedIndex, 1);
      return {
        ...state,
        isFetching: false,
        items,
      };
    }

    case DELETE_IMG_BERITA_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        msg: "DELETE_IMG_BERITA_FAILURE",
      };
    // DELETE IMG BERITA END

    default:
      return state;
  }
};

export default beritaReducer;
