import utilities from "helpers/utilities";

export const buildTblAnggaran = (data = [], direktorat) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths:
        direktorat === "dikti"
          ? ["20%", "15%", "20%", "10%", "5%", "15%", "15%", "10%"]
          : ["25%", "15%", "25%", "10%", "5%", "15%", "15%"],
      body: [
        [
          {
            text: `Kelompok`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Komponen`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Item`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Satuan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Vol.`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Biaya Satuan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Total`,
            style: ["center", "bgColorHeaderTable"],
          },
        ],
      ],
    },
  };

  if (direktorat === "dikti") {
    //adding new columns
    layout.table.body[0].push({
      text: `URL Hps`,
      style: ["center", "bgColorHeaderTable"],
    });
  }

  data.forEach(item => {
    const volume = parseInt(item.volume);
    const harga_satuan = utilities.unmaskMoney(item.harga_satuan);
    const total = volume * harga_satuan;

    const tblBodyItem = [
      {
        text: `${item.raw.nama_kelompok_rab}`,
      },
      {
        text: `${item.raw.rab_komponen_belanja}`,
        style: ["center"],
      },
      {
        text: `${item?.nama_item}`,
        style: ["center"],
      },
      {
        text: `${item.satuan || "-"}`,
        style: ["center"],
      },
      {
        text: `${volume || "-"}`,
        style: ["center"],
      },
      {
        text: `${utilities.toCurrency(harga_satuan || 0, ".", 0)}`,
        style: ["right"],
      },
      {
        text: `${utilities.toCurrency(total || 0, ".", 0)}`,
        style: ["right"],
      },
    ];

    if (direktorat === "dikti") {
      //adding new columns
      tblBodyItem.push({
        text: `${"Lihat" || "-"}`,
        link: `${item?.raw?.url_hps}`,
        style: ["center", { color: "blue", decoration: "underline" }],
      });
    }

    layout.table.body.push(tblBodyItem);
  });

  return layout;
};

export const tableAnggaranContent = (
  item,
  tileSection,
  direktorat,
  jenis = "normal"
) => {
  let anggaranGrouped = utilities.groupBy(
    jenis === "normal" ? item.rab : item.rab_revisi || [],
    "urutan_tahun_kegiatan"
  );

  const totalAnggaran = (
    jenis === "normal" ? item.rab : item.rab_revisi || []
  ).reduce(
    (a, { harga_satuan, volume }) =>
      a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
    0
  );

  return [
    {
      stack: [
        {
          text: tileSection,
          style: ["bold"],
        },
        {
          text: "Rencana Anggaran Biaya penelitian mengacu pada PMK dan buku Panduan Penelitian dan Pengabdian kepada Masyarakat yang berlaku.",
          style: ["justify", "mb-10"],
        },
        jenis === "normal"
          ? {
              text: `Total RAB ${
                Object.keys(anggaranGrouped)?.length || 0
              } Tahun ${utilities.toCurrency(totalAnggaran, ".", 2, "Rp")}`,
              style: ["bold", "mb-10"],
            }
          : null,
      ],
    },
    {
      stack: [
        ...(Object.entries(anggaranGrouped) || []).map(
          ([key, anggaranItem]) => {
            const totalAnggaranPertahun =
              anggaranItem.reduce(
                (a, b) =>
                  parseInt(a) +
                  utilities.unmaskMoney(b.harga_satuan) * parseInt(b.volume),
                0
              ) || 0;
            return {
              style: ["mb-10"],
              stack: [
                {
                  text: [
                    `Tahun ${key} Total ${utilities.toCurrency(
                      totalAnggaranPertahun,
                      ".",
                      2,
                      "Rp"
                    )}`,
                    `${
                      jenis === "normal"
                        ? ""
                        : ` | Disetujui ${utilities.toCurrency(
                            item[`dana_disetujui_thn_${key}`],
                            ".",
                            2,
                            "Rp"
                          )}`
                    }`,
                  ],
                  style: ["bold"],
                },
                buildTblAnggaran(
                  anggaranItem.filter(item => item.harga_satuan !== 0),
                  direktorat
                ),
              ],
            };
          }
        ),
      ],
    },
  ];
};
