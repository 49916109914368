const direktoratTypes = {
    DIKTI: '1',
    DIKSI: '2'
}
export default direktoratTypes

export function getDirektoratName(direktorat) {
    switch (direktorat) {
        case direktoratTypes.DIKTI:
            return 'Dikti'
        case direktoratTypes.DIKSI:
            return 'Diksi'
        default:
            return '-'
    }
}