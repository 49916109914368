import {
    APPROVAL_LPPM_PROTOTIPE_FAILURE,
    APPROVAL_LPPM_PROTOTIPE_REQUEST,
    APPROVAL_LPPM_PROTOTIPE_SUCCESS,
    DELETE_PROTOTIPE_FAILURE,
    DELETE_PROTOTIPE_REQUEST,
    DELETE_PROTOTIPE_SUCCESS,
    GET_BYID_PROTOTIPE_FAILURE,
    GET_BYID_PROTOTIPE_REQUEST,
    GET_BYID_PROTOTIPE_SUCCESS,
    GET_ELIGIBILITY_PROTOTIPE_FAILURE,
    GET_ELIGIBILITY_PROTOTIPE_REQUEST,
    GET_ELIGIBILITY_PROTOTIPE_SUCCESS,
    GET_LIST_PROTOTIPE_FAILURE,
    GET_LIST_PROTOTIPE_REQUEST,
    GET_LIST_PROTOTIPE_SUCCESS,
    SAVE_PROTOTIPE_FAILURE,
    SAVE_PROTOTIPE_REQUEST,
    SAVE_PROTOTIPE_SUCCESS,
    UPDATE_PROTOTIPE_FAILURE,
    UPDATE_PROTOTIPE_REQUEST,
    UPDATE_PROTOTIPE_SUCCESS,
    GET_COUNTING_USULAN_PROTOTIPE_REQUEST,
    GET_COUNTING_USULAN_PROTOTIPE_FAILURE,
    GET_COUNTING_USULAN_PROTOTIPE_SUCCESS,
    CLEAN_DETAILS_PROPOSAL
} from "./actionTypes";

export const getCountingPrototipe = (request = {}, cb = () => { }) => {

    return {
        type: GET_COUNTING_USULAN_PROTOTIPE_REQUEST,
        payload: { ...request },
        cb,
    };
};

export const getCountingPrototipeSuccess = response => {
    return {
        type: GET_COUNTING_USULAN_PROTOTIPE_SUCCESS,
        payload: response,
    };
};

export const getCountingPrototipeFailure = (message, response) => {
    return {
        type: GET_COUNTING_USULAN_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};

export const approvalPrototipe = (request = {}, cb = () => { }) => {
    return {
        type: APPROVAL_LPPM_PROTOTIPE_REQUEST,
        payload: { ...request },
        cb,
    };
};

export const approvalPrototipeSuccess = response => {
    return {
        type: APPROVAL_LPPM_PROTOTIPE_SUCCESS,
        payload: response,
    };
};

export const approvalPrototipeFailure = (message, response) => {
    return {
        type: APPROVAL_LPPM_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};

export const updateUsulanPrototipe = (request = {}, id, cb = () => { }) => {
    return {
        type: UPDATE_PROTOTIPE_REQUEST,
        payload: { ...request, id },
        cb,
    };
};

export const updateUsulanPrototipeSuccess = response => {
    return {
        type: UPDATE_PROTOTIPE_SUCCESS,
        payload: response,
    };
};

export const updateUsulanPrototipeFailure = (message, response) => {
    return {
        type: UPDATE_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};

export const saveUsulanPrototipe = (request = {}, cb = () => { }) => {
    return {
        type: SAVE_PROTOTIPE_REQUEST,
        payload: request,
        cb,
    };
};

export const saveUsulanPrototipeSuccess = response => {
    return {
        type: SAVE_PROTOTIPE_SUCCESS,
        payload: response,
    };
};

export const saveUsulanPrototipeFailure = (message, response) => {
    return {
        type: SAVE_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};

export const getByIdUsulanPrototipe = (request = {}, cb = () => { }, flag = 1) => {
    return {
        type: GET_BYID_PROTOTIPE_REQUEST,
        payload: request,
        flag,
        cb,
    };
};

export const cleanDetailsProposal = () => {
    return {
        type: CLEAN_DETAILS_PROPOSAL
    }
}

export const getByIdUsulanPrototipeSuccess = response => {
    return {
        type: GET_BYID_PROTOTIPE_SUCCESS,
        payload: response,
    };
};

export const getByIdUsulanPrototipeFailure = (message, response) => {
    return {
        type: GET_BYID_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};

export const getUsulanPrototipe = (request = {}, cb = () => { }) => {
    return {
        type: GET_LIST_PROTOTIPE_REQUEST,
        payload: request,
        cb,
    };
};

export const getUsulanPrototipeSuccess = response => {
    return {
        type: GET_LIST_PROTOTIPE_SUCCESS,
        payload: response,
    };
};

export const getUsulanPrototipeFailure = (message, response) => {
    return {
        type: GET_LIST_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};

export const deleteUsulanPrototipe = (request = {}, cb = () => { }) => {
    return {
        type: DELETE_PROTOTIPE_REQUEST,
        payload: request,
        cb,
    };
};

export const deleteUsulanPrototipeSuccess = response => {
    return {
        type: DELETE_PROTOTIPE_SUCCESS,
        payload: response,
    };
};

export const deleteUsulanPrototipeFailure = (message, response) => {
    return {
        type: DELETE_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};

export const getEligibilityPrototipe = (request = {}, cb = () => { }) => {
    return {
        type: GET_ELIGIBILITY_PROTOTIPE_REQUEST,
        payload: { ...request },
        cb,
    };
};

export const getEligibilityPrototipeSuccess = (response = {}) => {
    return {
        type: GET_ELIGIBILITY_PROTOTIPE_SUCCESS,
        payload: response,
    };
};

export const getEligibilityPrototipeFailure = (message, response) => {
    return {
        type: GET_ELIGIBILITY_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};
