import {
  APPROVAL_USULAN_REQUEST,
  APPROVAL_USULAN_SUCCESS,
  APPROVAL_USULAN_FAILURE,
  BATALKAN_USULAN_REQUEST,
  BATALKAN_USULAN_SUCCESS,
  BATALKAN_USULAN_FAILURE,
  GET_USULAN_DISETUJUI_REQUEST,
  GET_USULAN_DISETUJUI_SUCCESS,
  GET_USULAN_DISETUJUI_FAILURE,
  GET_USULAN_BARU_REQUEST,
  GET_USULAN_BARU_SUCCESS,
  GET_USULAN_BARU_FAILURE,
  GET_USULAN_DIKIRIM_REQUEST,
  GET_USULAN_DIKIRIM_SUCCESS,
  GET_USULAN_DIKIRIM_FAILURE,
  GET_USULAN_DITOLAK_REQUEST,
  GET_USULAN_DITOLAK_SUCCESS,
  GET_USULAN_DITOLAK_FAILURE,
  GET_USULAN_DITINJAU_REQUEST,
  GET_USULAN_DITINJAU_SUCCESS,
  GET_USULAN_DITINJAU_FAILURE,
  GET_ALASAN_PENOLAKAN_REQUEST,
  GET_ALASAN_PENOLAKAN_SUCCESS,
  GET_ALASAN_PENOLAKAN_FAILURE,
  GET_SUMMARY_USULAN_GROUPBY_SKEMA_REQUEST,
  GET_SUMMARY_USULAN_GROUPBY_SKEMA_SUCCESS,
  GET_SUMMARY_USULAN_GROUPBY_SKEMA_FAILURE,
  CLEAN_SUMMARY_USULAN_GROUPBY_SKEMA,
} from "./actionType";

const initialState = {
  isFetching: false,
  isLoading: false,
  error: null,
  items: [],
  itemSelected: {},
  usulanDisetujui: [],
  usulanBaru: [],
  UsulanDikirimReduce: [],
  getUsulanDitolak: [],
  UsulanDitinjauReduce: [],
  batalkanUsulanReduce: {},
  approvalUsulanReduce: {},
  alasanPenolakanReduce: [],
  summaryUsulanGroupSkema : []
};

const persetujuanUsulanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUMMARY_USULAN_GROUPBY_SKEMA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SUMMARY_USULAN_GROUPBY_SKEMA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        summaryUsulanGroupSkema: action.payload ?? [],
      };
    case GET_SUMMARY_USULAN_GROUPBY_SKEMA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        summaryUsulanGroupSkema: action.payload ?? [],
      };
    case CLEAN_SUMMARY_USULAN_GROUPBY_SKEMA:
      return {
        ...state,
        summaryUsulanGroupSkema: [],
      };
   
    case APPROVAL_USULAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        approvalUsulanReduce: action.payload ?? {},
      };
    case APPROVAL_USULAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        approvalUsulanReduce: action.payload ?? {},
      };
    case APPROVAL_USULAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload,
        approvalUsulanReduce: action.payload ?? {},
      };
    case BATALKAN_USULAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        // batalkanUsulanReduce: action.payload ?? {},
      };
    case BATALKAN_USULAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        batalkanUsulanReduce: action.payload ?? {},
      };
    case BATALKAN_USULAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload,
        batalkanUsulanReduce: action.payload ?? {},
      };
    case GET_USULAN_DISETUJUI_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        usulanDisetujui: action.payload ?? [],
      };
    case GET_USULAN_DISETUJUI_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        usulanDisetujui: action.payload ?? [],
      };
    case GET_USULAN_DISETUJUI_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload,
        usulanDisetujui: action.payload ?? [],
      };
    case GET_USULAN_BARU_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        usulanBaru: action.payload ?? [],
      };
    case GET_USULAN_BARU_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        usulanBaru: action.payload ?? [],
      };
    case GET_USULAN_BARU_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload,
        usulanBaru: action.payload ?? [],
      };
    case GET_USULAN_DIKIRIM_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        UsulanDikirimReduce: action.payload ?? [],
      };
    case GET_USULAN_DIKIRIM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        UsulanDikirimReduce: action.payload ?? [],
      };
    case GET_USULAN_DIKIRIM_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload,
        UsulanDikirimReduce: action.payload ?? [],
      };
    case GET_USULAN_DITOLAK_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        getUsulanDitolak: action.payload ?? [],
      };
    case GET_USULAN_DITOLAK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        getUsulanDitolak: action.payload ?? [],
      };
    case GET_USULAN_DITOLAK_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload,
        getUsulanDitolak: action.payload ?? [],
      };
    case GET_USULAN_DITINJAU_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        UsulanDitinjauReduce: action.payload ?? [],
      };
    case GET_USULAN_DITINJAU_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        UsulanDitinjauReduce: action.payload ?? [],
      };
    case GET_USULAN_DITINJAU_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload,
        UsulanDitinjauReduce: action.payload ?? [],
      };
    case GET_ALASAN_PENOLAKAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoading: true,
        alasanPenolakanReduce: action.payload ?? [],
      };
    case GET_ALASAN_PENOLAKAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        alasanPenolakanReduce: action.payload ?? [],
      };
    case GET_ALASAN_PENOLAKAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: action.payload,
        alasanPenolakanReduce: action.payload ?? [],
      };

    default:
      return state;
  }
};

export default persetujuanUsulanReducer;
