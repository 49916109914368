/* eslint-disable no-unused-vars */
import { getSingleFileFromBucket } from "helpers/getFileFromBucket";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import util from "helpers/utilities";
import { PDFDocument } from "pdf-lib";
import {
  PdfCreating as engine,
  pdfCreatePersetujuan as engine2,
} from "./partials";
import utilities from "helpers/utilities";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CetakUsulanBaruPengabdianV2 {
  constructor(data) {
    this.data = data;
    this.indexBlobSkiped = [];
  }
  async generate(direktorat) {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine(this.data, direktorat));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }

  async generatePersetujuan() {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine2(this.data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }

  async getMultiplefile(data, direktorat) {
    let files = [];
    let dataBlob = [];
    if (direktorat === "vokasi") {
      files.push({
        url_dokumen: data.raw.substansi.file_url_substansi,
      });
      const fileMitra =
        data.personil_non_dosen.filter(item => item.kind === "mitra") || [];
      fileMitra.forEach(item => {
        files.push({
          url_dokumen: item.raw.url_surat_kesanggupan_mitra,
        });
      });
      const filePendukungMitra = data.raw.dokumen_pendukung || [];
      filePendukungMitra.forEach(item => {
        files.push({
          url_dokumen: item.url_file,
        });
      });

      if (data?.revisi?.url_file_surat_kesanggupan) {
        files.push({
          url_dokumen: data?.revisi?.url_file_surat_kesanggupan
        });
      }

      for (const file of files) {
        if (file.url_dokumen) {
          const res = await getSingleFileFromBucket(
            util.getFriendlyURL(file.url_dokumen || '')
          );
          if (!res.error) {
            dataBlob.push(res.data);
          }
        }
      }
      return dataBlob;
    } else {
      files.push({
        url_dokumen: data.raw.substansi.file_url_substansi,
      });
      const mitra = data.personil_non_dosen.filter(
        item => item.kind === "mitra"
      );
      mitra.forEach(item => {
        Object.keys(item.raw).forEach(key => {
          if (key.startsWith("url_file")) {
            files.push({
              url_dokumen: item.raw[key],
            });
          }
        });
      });

      files.push({
        url_dokumen: data.raw.dokumen_pendukung.url_surat_pernyataan_orisinil,
      });

      files.push({
        url_dokumen: data.raw.dokumen_pendukung.url_dokumen_dukung_lainnya,
      });

      if (data?.revisi?.url_file_surat_kesanggupan) {
        files.push({
          url_dokumen: data?.revisi?.url_file_surat_kesanggupan
        });
      }

      for (const file of files.filter(
        item =>
          item.url_dokumen !== undefined &&
          item.url_dokumen !== "" &&
          item.url_dokumen !== null
      )) {
        const res = await getSingleFileFromBucket(
          util.getFriendlyURL(file.url_dokumen)
        );
        if (!res.error) {
          dataBlob.push(res.data);
        }
      }
      return dataBlob;
    }
  }
  async mergingv3(docs = [], cb) {
    const pdf = await PDFDocument.create();
    let i = 0;

    try {
      for (const file of docs) {
        const bufferFile = await utilities.blobToUint8Array(file);

        if (!this.indexBlobSkiped.includes(i)) {
          const doc = await PDFDocument.load(bufferFile, {
            ignoreEncryption: true,
            throwOnInvalidObject: true,
          });

          if (doc) {
            const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
            for (const page of contentPage) {
              pdf.addPage(page);
            }
          }
        }
        i++;
      }
      const pdfMerged = await pdf.saveAsBase64();
      const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, "application/pdf");
      cb({ res: pdfMergedAsBlob, error: false });
    } catch (error) {
      if (error.message.includes("invalid object")) {
        this.indexBlobSkiped.push(i);
        cb({ res: undefined, error: true, indexBlobSkipped: i });
      } else {
        cb({ res: undefined, error: true, errMsg: error.message });
      }
    }
  }

  forceDownload(file, fileName) {
    // * Force Download
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", `${fileName}`);
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  }
}

export default CetakUsulanBaruPengabdianV2;
