/* eslint-disable no-unused-vars */
import { buildTblAnggaran } from "../../usulanPenelitian/partials/content";
import headers from "./headers";
import stylePDF from "./styles";
import utilities from "helpers/utilities";
import { tableAnggaranContent } from "./tblContentRAB";


export const Content = item => {
  const data = item.item;
  const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
    let thnAkhirUsulan = parseInt(thnPertamaUsulan);
    for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
      thnAkhirUsulan += tahun;
    }
    return thnAkhirUsulan;
  };

  const { hr } = stylePDF;

  return [
    // * Header
    headers(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI PROPOSAL",
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `PROPOSAL PENGABDIAN ${data?.raw?.identitas?.tahun_pelaksanaan}`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `ID Proposal: ${data?.id}`,
          style: ["fontSizeXs"],
        },
        {
          text: `Rencana Pelaksanaan Pengabdian : tahun ${data?.raw?.identitas?.tahun_pelaksanaan
            } s.d. tahun ${getThnAkhirUsulan(
              data?.raw?.identitas?.lama_kegiatan,
              data?.raw?.identitas?.tahun_pelaksanaan
            )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    ...contentUsulanPengabdian(data),
  ];
};

export const contentUsulanPengabdian = data => {
  const buildTblIdentitas1 = (data = {}) => {
    const layout = {
      style: ["center", "mb-10"],
      layout: {
        hLineWidth: (_i, _node) => 0.5,
        vLineWidth: _i => 0.5,
        padding: () => 0,
      },
      table: {
        widths: ["22.5%", "22.5%", "20.00%", "15.00%", "20.00%"],
        body: [
          [
            {
              text: `Kelompok Skema`,
              style: ["center"],
            },
            {
              text: `Ruang Lingkup`,
              style: ["center"],
            },
            {
              text: `Bidang Fokus`,
              style: ["center"],
            },
            {
              text: `Lama Kegiatan`,
              style: ["center"],
            },
            {
              text: `Tahun Pertama Usulan`,
              style: ["center"],
            },
          ],
        ],
      },
    };

    layout.table.body.push([
      {
        text: data.kategori_program_pengabdian,
      },
      {
        text: `${data.nama_kelompok_skema}`,
      },
      {
        text: `${data.bidang_fokus_type} - ${data.nama_bidang_fokus}`,
      },
      {
        text: data.lama_kegiatan || "-",
        style: ["center"],
      },
      {
        text: data.tahun_pelaksanaan || "-",
        style: ["center"],
      },
    ]);

    return layout;
  };

  const buildTblPengusul = (identitasPengusul = []) => {
    const layout = {
      layout: {
        hLineWidth: (_i, _node) => 0.2,
        vLineWidth: _i => 0.2,
        padding: () => 0,
      },
      table: {
        widths: [
          "17.68%",
          "16.68%",
          "16.68%",
          "16.68%",
          "10.68%",
          "10.66%",
          "10.68%",
        ],
        body: [
          [
            {
              text: `Nama, Peran`,
              style: ["center"],
            },
            {
              text: `Perguruan Tinggi/ Institusi`,
              style: ["center"],
            },
            {
              text: `Program Studi/Bagian `,
              style: ["center"],
            },
            {
              text: `Bidang Tugas`,
              style: ["center"],
            },
            {
              text: `ID Sinta`,
              style: ["center"],
            },
            {
              text: `H-Index`,
              style: ["center"],
            },
            {
              text: `Rumpun Ilmu`,
              style: ["center"],
            },
          ],
        ],
      },
    };
    identitasPengusul.forEach(item => {
      if (item.kode_peran === "A") {
        item.raw.nameRumpunIlmuLevel2 = data.raw.identitas.nama_rumpun_ilmu_2;
        item.raw.uraian_tugas = data.raw.identitas.tugas_ketua;
      }
    });
    identitasPengusul
      .sort((a, b) => {
        if (a.kode_peran === "A") return -1;
        if (b.kode_peran === "A") return 1;
        return 0;
      })
      .forEach(item => {
        layout.table.body.push([
          {
            text: `${item.nama} \n\n ${item.nama_peran}`,
            style: ["center"],
          },
          {
            text: `${item?.raw?.pt || item?.raw?.institusi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.raw?.prodi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.raw?.uraian_tugas || "-"}`,
          },
          {
            text: `${item?.id_sinta || "-"}`,
            link: `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}`,
            style: ["center", { color: "blue", decoration: "underline" }],
          },
          {
            text: `${item?.hindex_scopus || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.raw?.nameRumpunIlmuLevel2 || "-"}`,
            style: ["center"],
          },
        ]);
      });

    return layout;
  };

  // Identitas Anggota MAHASISWA
  const buildTblMahasiswa = (identitasPengusulMhs = []) => {
    const layout = {
      layout: {
        hLineWidth: (_i, _node) => 0.2,
        vLineWidth: _i => 0.2,
        padding: () => 0,
      },
      table: {
        widths: ["18.68%", "18.68%", "19.68%", "19.68%", "20.68%"],
        body: [
          [
            {
              text: `Nama, Peran`,
              style: ["center"],
            },
            {
              text: `NIM `,
              style: ["center"],
            },
            {
              text: `Perguruan Tinggi/ Institusi`,
              style: ["center"],
            },
            {
              text: `Program Studi/Bagian `,
              style: ["center"],
            },
            {
              text: `Bidang Tugas `,
              style: ["center"],
            },
          ],
        ],
      },
    };
    identitasPengusulMhs
      .filter(item => item.kind === "mahasiswa")
      .forEach(item => {
        layout.table.body.push([
          {
            text: `${item.raw.nama_lengkap} \n\n ${item.raw.nama_peran}`,
            style: ["center"],
          },
          {
            text: `${item.raw.no_identitas || "-"}`,
            style: ["center"],
          },
          {
            text: `${item.raw.instansi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item.raw.prodi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item.raw.tugas || "-"}`,
            style: ["center"],
          },
        ]);
      });

    return layout;
  };

  // Luaran
  const buildTblLuaran = (luaran = []) => {
    const layout = {
      style: ["center"],
      layout: {
        hLineWidth: (_i, _node) => 0.5,
        vLineWidth: _i => 0.5,
        padding: () => 1,
      },
      table: {
        widths: ["10%", "30%", "25%", "35%"],
        body: [
          [
            {
              text: "Tahun Luaran",
              style: ["center"],
            },
            {
              text: "Jenis Luaran",
              style: ["center"],
            },
            {
              text: "Status target capaian",
              style: ["center"],
            },
            {
              text: "Keterangan",
            },
          ],
        ],
      },
    };
    luaran
      .sort((a, b) =>
        a.tahun_kegiatan > b.tahun_kegiatan
          ? 1
          : b.tahun_kegiatan > a.tahun_kegiatan
            ? -1
            : 0
      )
      .forEach(item => {
        layout.table.body.push([
          {
            text: item.tahun_kegiatan || "-",
          },
          {
            text: item.raw.jenis_luaran || "-",
          },
          {
            text: item.raw.status_capaian || "-",
          },
          {
            text: item.keterangan || "-",
          },
        ]);
      });
    return layout;
  };

  return [
    {
      stack: [
        {
          text: "1. JUDUL PENGABDIAN",
          style: ["bold", "mb-5"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: _i => 0.5,
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: data.raw.identitas.judul,
                },
              ],
            ],
          },
        },

        buildTblIdentitas1(data.raw.identitas),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "2. IDENTITAS PENGUSUL",
          style: ["bold", "mb-5"],
        },
        buildTblPengusul(data.personil),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "3. IDENTITAS MAHASISWA",
          style: ["bold", "mb-5"],
        },
        buildTblMahasiswa(data.personil_non_dosen),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "4. MITRA KERJASAMA",
          style: ["bold", "mb-5"],
        },
        {
          text: `Pelaksanaan pengabdian kepada masyarakat dapat melibatkan mitra, yaitu mitra sasaran, mitra pemerintah/pemda, mitra DUDI/CSR/LSM atau mitra perguruan tinggi`,
          style: ["fontSizeXs", "mb-5"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
          },
          table: {
            widths: ["30%", "35%", "35%"],
            body: [
              [
                {
                  text: `Jenis Mitra`,
                  style: ["center"],
                },
                {
                  text: `Nama Mitra`,
                  style: ["center"],
                },

                {
                  text: `Dana`,
                  style: ["center"],
                },
              ],
              ...data.personil_non_dosen
                .filter(item => item.kind === "mitra")
                .map(item => {
                  let danas = ``;
                  if (
                    data.id_skema === 109 ||
                    data.id_skema === 111 ||
                    data.id_skema === 112
                  ) {
                    // just for 109, 111, 112 (mono tahun)
                    danas += `Tahun 1 ${utilities.toCurrency(
                      utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
                      ".",
                      2,
                      "Rp. "
                    )}`;
                  } else {
                    danas += `Tahun 1 ${utilities.toCurrency(
                      utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
                      ".",
                      2,
                      "Rp. "
                    )}\n`;
                    danas += `Tahun 2 ${utilities.toCurrency(
                      utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
                      ".",
                      2,
                      "Rp. "
                    )}\n`;
                    danas += `Tahun 3 ${utilities.toCurrency(
                      utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
                      ".",
                      2,
                      "Rp. "
                    )}\n`;
                  }
                  return [
                    {
                      text: `${item.raw.jenis_mitra}`,
                    },
                    {
                      text: `${item.raw.mitra}`,
                    },
                    {
                      text: danas || "-",
                    },
                  ];
                }),
            ],
          },
        },
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "5. LUARAN DIJANJIKAN",
          style: ["bold", "mb-5"],
        },
        buildTblLuaran(data.luaran),
      ],
      style: ["mb-10"],
    },
    ...tableAnggaranContent(data, "6. ANGGARAN", "vokasi"),
    data.rab_revisi ? [...tableAnggaranContent(data, "7. ANGGARAN PERBAIKAN", "vokasi", 'revisi')] : null
  ];
};


export const contentPersetujuan = (data = {}) => {
  let { approval_raw, approval_status } = data;
  approval_raw = approval_raw ?? {};
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*", "*"],
      body: [
        [
          {
            text: `Tanggal Pengiriman`,
            style: ["center"],
          },
          {
            text: `Tanggal Persetujuan`,
            style: ["center"],
          },
          {
            text: `Nama Pimpinan Pemberi Persetujuan`,
            style: ["center"],
          },
          {
            text: `Sebutan Jabatan Unit`,
            style: ["center"],
          },
          {
            text: `Nama Unit Lembaga Pengusul`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: `${data?.tgl_updated
        ? utilities.convertDate(data?.tgl_updated, "DD/MM/YYYY")
        : "-"
        }`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.approval_date
        ? utilities.convertDate(approval_raw?.approval_date, "DD/MM/YYYY")
        : "-"
        }`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.full_name || "-"}`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.nama_jabatan || "-"}`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.nama_lembaga || "-"}`,
      style: ["center"],
    },
  ]);

  return [
    {
      stack: [
        {
          text: "PERSETUJUAN PENGUSUL",
          style: ["bold"],
        },
        {
          ...layout,
        },
      ],
    },
    {
      style: ["mt-10"],
      stack: [
        {
          text: `Komentar : ${approval_status === "approved"
            ? "Disetujui"
            : approval_status === "rejected"
              ? "Ditolak"
              : "-"
            }`,
          style: ["bold", "mb-1"],
        },
        {
          style: ["text"],
          layout: {
            hLineWidth: (_i, _node) => (approval_raw?.comment ? 0.3 : 0),
            vLineWidth: _i => (approval_raw?.comment ? 0.3 : 0),
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: approval_raw?.comment,
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
