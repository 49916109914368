import {
  APPROVAL_USULAN_REQUEST,
  APPROVAL_USULAN_SUCCESS,
  APPROVAL_USULAN_FAILURE,
  BATALKAN_USULAN_REQUEST,
  BATALKAN_USULAN_SUCCESS,
  BATALKAN_USULAN_FAILURE,
  GET_USULAN_DISETUJUI_REQUEST,
  GET_USULAN_DISETUJUI_SUCCESS,
  GET_USULAN_DISETUJUI_FAILURE,
  GET_USULAN_BARU_REQUEST,
  GET_USULAN_BARU_SUCCESS,
  GET_USULAN_BARU_FAILURE,
  GET_USULAN_DIKIRIM_REQUEST,
  GET_USULAN_DIKIRIM_SUCCESS,
  GET_USULAN_DIKIRIM_FAILURE,
  GET_USULAN_DITOLAK_REQUEST,
  GET_USULAN_DITOLAK_SUCCESS,
  GET_USULAN_DITOLAK_FAILURE,
  GET_USULAN_DITINJAU_REQUEST,
  GET_USULAN_DITINJAU_SUCCESS,
  GET_USULAN_DITINJAU_FAILURE,
  GET_ALASAN_PENOLAKAN_REQUEST,
  GET_ALASAN_PENOLAKAN_SUCCESS,
  GET_ALASAN_PENOLAKAN_FAILURE,
  GET_SUMMARY_USULAN_GROUPBY_SKEMA_REQUEST,
  GET_SUMMARY_USULAN_GROUPBY_SKEMA_SUCCESS,
  GET_SUMMARY_USULAN_GROUPBY_SKEMA_FAILURE,
  CLEAN_SUMMARY_USULAN_GROUPBY_SKEMA,
} from "./actionType";

export const getSummaryUsulanGroupBySkema = (request, cb = () => {}) => {
  return {
    type: GET_SUMMARY_USULAN_GROUPBY_SKEMA_REQUEST,
    payload: request,
    cb
  };
};

export const getSummaryUsulanGroupBySkemaSuccess = (response, state) => {
  return {
    type: GET_SUMMARY_USULAN_GROUPBY_SKEMA_SUCCESS,
    payload: response,
    state,
  };
};

export const getSummaryUsulanGroupBySkemaFilure= (response, message) => {
  return {
    type: GET_SUMMARY_USULAN_GROUPBY_SKEMA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const cleanSummaryUsulanGroupBySkema = () => {
  return {
    type: CLEAN_SUMMARY_USULAN_GROUPBY_SKEMA,
  };
};

export const approvalUsulan = (request, cb) => {
  return {
    type: APPROVAL_USULAN_REQUEST,
    payload: request,
    cb
  };
};

export const approvalUsulanSuccess = (response, state) => {
  return {
    type: APPROVAL_USULAN_SUCCESS,
    payload: response,
    state,
  };
};

export const approvalUsulanFailure = (response, message) => {
  return {
    type: APPROVAL_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const batalkanUsulan = (request, cb) => {
  return {
    type: BATALKAN_USULAN_REQUEST,
    payload: request,
    cb,
  };
};

export const batalkanUsulanSuccess = (response, state) => {
  return {
    type: BATALKAN_USULAN_SUCCESS,
    payload: response,
    state,
  };
};

export const batalkanUsulanFailure = (response, message) => {
  return {
    type: BATALKAN_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getUsulanDisetujui = request => {
  return {
    type: GET_USULAN_DISETUJUI_REQUEST,
    payload: request,
  };
};

export const getUsulanDisetujuiSuccess = (response, state) => {
  return {
    type: GET_USULAN_DISETUJUI_SUCCESS,
    payload: response,
    state,
  };
};

export const getUsulanDisetujuiFailure = (response, message) => {
  return {
    type: GET_USULAN_DISETUJUI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getUsulanBaru = request => {
  return {
    type: GET_USULAN_BARU_REQUEST,
    payload: request,
  };
};

export const getUsulanBaruSuccess = (response, state) => {
  return {
    type: GET_USULAN_BARU_SUCCESS,
    payload: response,
    state,
  };
};

export const getUsulanBaruFailure = (response, message) => {
  return {
    type: GET_USULAN_BARU_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getUsulanDikirim = request => {
  return {
    type: GET_USULAN_DIKIRIM_REQUEST,
    payload: request,
  };
};

export const getUsulanDikirimSuccess = (response, state) => {
  return {
    type: GET_USULAN_DIKIRIM_SUCCESS,
    payload: response,
    state,
  };
};

export const getUsulanDikirimFailure = (response, message) => {
  return {
    type: GET_USULAN_DIKIRIM_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const UsulanTidakDisetujui = request => {
  return {
    type: GET_USULAN_DITOLAK_REQUEST,
    payload: request,
  };
};

export const getUsulanDitolakSuccess = (response, state) => {
  return {
    type: GET_USULAN_DITOLAK_SUCCESS,
    payload: response,
    state,
  };
};

export const getUsulanDitolakFailure = (response, message) => {
  return {
    type: GET_USULAN_DITOLAK_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getUsulanDitinjau = request => {
  return {
    type: GET_USULAN_DITINJAU_REQUEST,
    payload: request,
  };
};

export const getUsulanDitinjauSuccess = (response, state) => {
  return {
    type: GET_USULAN_DITINJAU_SUCCESS,
    payload: response,
    state,
  };
};

export const getUsulanDitinjauFailure = (response, message) => {
  return {
    type: GET_USULAN_DITINJAU_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const alasanPenolakan = request => {
  return {
    type: GET_ALASAN_PENOLAKAN_REQUEST,
    payload: request,
  };
};

export const alasanPenolakanSuccess = (response, state) => {
  return {
    type: GET_ALASAN_PENOLAKAN_SUCCESS,
    payload: response,
    state,
  };
};

export const alasanPenolakanFailure = (response, message) => {
  return {
    type: GET_ALASAN_PENOLAKAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
