import stylePDF from "./styles";
import header from "./header";
import utilities from "helpers/utilities";

const buildTblIdentitas = (data = {}) => {
    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: _i => 0.5,
            padding: () => 0,
        },
        table: {
            widths: ["50%", "25%", "25%"],
            body: [
                [
                    {
                        text: `Bidang Fokus Kosabangsa`,
                        style: ["center"],
                    },
                    {
                        text: `Tahun Pelaksanaan`,
                        style: ["center"],
                    },
                    {
                        text: `Lama Kegiatan`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    layout.table.body.push([
        {
            text: data.nama_bidang_fokus,
            style: ["center"],
        },
        {
            text: data.tahun_usulan,
            style: ["center"],
        },
        {
            text: data.lama_kegiatan + "  Tahun",
            style: ["center"],
        },
    ]);

    return layout;
};

const buildTblAnggota = (personil = []) => {
    const layout = {
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["20%", "15%", "40%", "15%", "10%"],
            body: [
                [
                    {
                        text: `Nama, Peran, Institusi`,
                        style: ["center"],
                    },
                    {
                        text: `Program Studi/Bagian `,
                        style: ["center"],
                    },
                    {
                        text: `Bidang Tugas`,
                        style: ["center"],
                    },
                    {
                        text: `Rimpun Ilmu`,
                        style: ["center"],
                    },
                    {
                        text: `ID Sinta`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    personil
        .sort((a, b) => (a.urutan > b.urutan ? 1 : b.urutan > a.urutan ? -1 : 0))
        .forEach(item => {
            layout.table.body.push([
                {
                    text: `${item.nama} \n\n\n ${item.nama_peran} \n\n ${item?.nama_institusi || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.nama_program_studi || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item.tugas || "-"}`,
                },
                {
                    text: `${ item?.kode_peran === "A" ? (item?.rumpun_ilmu_3 || "-") :  (item?.rumpun_ilmu_2 || "-")}`,
                },
                {
                    text: `${item?.id_sinta || "-"}`,
                    link: `${item?.id_sinta ? `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}` : "#"}`,
                    style: ['link', 'center']
                },
            ]);
        });

    return layout;
};

const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
    let thnAkhirUsulan = parseInt(thnPertamaUsulan);
    for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
        thnAkhirUsulan += tahun;
    }
    return thnAkhirUsulan;
};

const buildTblMou = (data = {}) => {
    const layout = {
        style: ["center", "mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: _i => 0.5,
            padding: () => 0,
        },
        table: {
            widths: ["20%", "20%", "20%", "20%", "20%"],
            body: [
                [
                    {
                        text: `Nama calon PT pelaksana`,
                        style: ["center"],
                    },
                    {
                        text: `Nama calon ketua pelaksana`,
                        style: ["center"],
                    },
                    {
                        text: `Pimpinan yang menetapkan`,
                        style: ["center"],
                    },
                    {
                        text: `Tanggal penetapan`,
                        style: ["center"],
                    },
                    {
                        text: `Jangka Waktu`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    if (data.is_mou) {
        layout.table.body.push([
            {
                text: data.pt_pelaksana,
            },
            {
                text: data.ketua_pelaksana,
            },
            {
                text: data.nama_pimpinan_penetap,
            },
            {
                text: data.tgl_penetapan ? utilities.convertDate(data.tgl_penetapan, 'DD/MM/YYYY') : "-",
            },
            {
                text: data.jangka_waktu + "  Tahun",
            },
        ]);
    } else {
        return {}
    }

    return layout;
}

const buildTblDocMou = (data = {}) => {
    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0,
            vLineWidth: _i => 0,
            padding: () => 1,
        },
        table: {
            widths: [48, "*"],
            body: [
                [
                    {
                        text: 'Dokumen :',
                    },
                    {
                        text: data?.url_file_dokumen ? utilities.getFriendlyURL(data?.name_file_dokumen) : "-",
                        link: data?.url_file_dokumen ? utilities.getFriendlyURL(data?.url_file_dokumen) : "#",
                        style: ['link']

                    }
                ],
            ],
        },
    };

    if (!data.is_mou) {
        return {
            text: "Tidak",
            style: ["bold"],
        }
    }
    return layout;
}

const content = (data, _pageBreak = false) => {
    const { hr } = stylePDF;
    const {
        anggota,
        raw: {
            teknologi, mou, lama_kegiatan, pendampingan,
            nama_teknologi, tahun_usulan, is_mou
        }
    } = data;


    return [
        header(),
        hr(5, 0),
        {
            stack: [

                {
                    text: `PENDAFTARAN KOSABANGSA CALON PENDAMPING ${tahun_usulan ?? "2023"
                        }`,
                    color: "#FFFFFF",
                    background: "#000",
                    style: ["center", "bold"],
                },
                {
                    text: `Rencana Pelaksanaan Kosabangsa: tahun ${tahun_usulan ?? "2023"
                        } s.d. tahun ${getThnAkhirUsulan(
                            lama_kegiatan,
                            tahun_usulan
                        )}`,
                    style: ["fontSizeXs"],
                },
            ],
            style: ["center", "mb-5"],
        },
        {
            stack: [
                {
                    text: "1. Nama Teknologi dan Inovasi",
                    style: ["bold"],
                },
                {
                    style: ["text", "mb-10"],
                    layout: {
                        hLineWidth: (_i, _node) => 0.3,
                        vLineWidth: _i => 0.3,
                        padding: () => 1,
                    },
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: nama_teknologi,
                                },
                            ],
                        ],
                    },
                },

                buildTblIdentitas(data.raw),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "2. Identitas Tim Pendamping",
                    style: ["bold"],
                },
                buildTblAnggota(anggota.filter(row => row.kode_program_pendidikan?.toString() !== "2")),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "3. Identitas Anggota Pendamping Tambahan (Vokasi)",
                    style: ["bold"],
                },
                buildTblAnggota(anggota.filter(row => row.kode_program_pendidikan?.toString() === "2")),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "4. Deskripsi Teknologi dan Inovasi",
                    style: ["bold"],
                },
                {
                    style: ["mb-10"],
                    layout: {
                        hLineWidth: (_i, _node) => 0.3,
                        vLineWidth: _i => 0.3,
                        padding: () => 0,
                    },
                    table: {
                        widths: ["65%", "35%"],
                        body: [
                            [
                                {
                                    text: `Uraian`,
                                    style: ["center"],
                                },
                                {
                                    text: `Link Foto Teknologi dan Inovasi`,
                                    style: ["center"],
                                },
                            ],
                            [
                                {
                                    text: `${teknologi?.uraian || "-"}`,
                                },
                                {
                                    text: `${utilities.getFriendlyURL(teknologi?.name_file_foto)}`,
                                    link: `${utilities.getFriendlyURL(teknologi?.url_file_foto)}`,
                                    style: ['link']
                                },

                            ]

                        ],
                    },
                },
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "5. Deskripsi Rencana Pendampingan",
                    style: ["bold"],
                },
                {
                    style: ["mb-10"],
                    layout: {
                        hLineWidth: (_i, _node) => 0.3,
                        vLineWidth: _i => 0.3,
                        padding: () => 0,
                    },
                    table: {
                        widths: ["100%"],
                        body: [
                            [
                                {
                                    text: `${pendampingan?.uraian || "-"}`,
                                },

                            ]

                        ],
                    },
                },
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "6. MoU/Kerja sama dengan calon pelaksana Program Kosabangsa",
                    style: ["bold"],
                },
                buildTblDocMou({ ...mou, is_mou }),
                buildTblMou({ ...mou, is_mou })
            ],
        },
    ];
};


export { content };
