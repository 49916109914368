export const GET_LIST_KI_REQUEST = "GET_LIST_KI_REQUEST";
export const GET_LIST_KI_SUCCESS = "GET_LIST_KI_SUCCESS";
export const GET_LIST_KI_FAILURE = "GET_LIST_KI_FAILURE";

export const GET_LIST_KI_PUSAT_REQUEST = "GET_LIST_KI_PUSAT_REQUEST";
export const GET_LIST_KI_PUSAT_SUCCESS = "GET_LIST_KI_PUSAT_SUCCESS";
export const GET_LIST_KI_PUSAT_FAILURE = "GET_LIST_KI_PUSAT_FAILURE";

export const GET_COUNTING_KI_REQUEST = "GET_COUNTING_KI_REQUEST";
export const GET_COUNTING_KI_SUCCESS = "GET_COUNTING_KI_SUCCESS";
export const GET_COUNTING_KI_FAILURE = "GET_COUNTING_KI_FAILURE";

export const GET_DETAILS_KI_REQUEST = "GET_DETAILS_KI_REQUEST";
export const GET_DETAILS_KI_SUCCESS = "GET_DETAILS_KI_SUCCESS";
export const GET_DETAILS_KI_FAILURE = "GET_DETAILS_KI_FAILURE";
export const CLEAN_DETAILS_KI = "CLEAN_DETAILS_KI";

export const DELETE_PENGAJUAN_KI_REQUEST = "DELETE_PENGAJUAN_KI_REQUEST";
export const DELETE_PENGAJUAN_KI_SUCCESS = "DELETE_PENGAJUAN_KI_SUCCESS";
export const DELETE_PENGAJUAN_KI_FAILURE = "DELETE_PENGAJUAN_KI_FAILURE";

export const CREATE_PENGAJUAN_KI_REQUEST = "CREATE_PENGAJUAN_KI_REQUEST";
export const CREATE_PENGAJUAN_KI_SUCCESS = "CREATE_PENGAJUAN_KI_SUCCESS";
export const CREATE_PENGAJUAN_KI_FAILURE = "CREATE_PENGAJUAN_KI_FAILURE";

export const UPDATE_PENGAJUAN_KI_REQUEST = "UPDATE_PENGAJUAN_KI_REQUEST";
export const UPDATE_PENGAJUAN_KI_SUCCESS = "UPDATE_PENGAJUAN_KI_SUCCESS";
export const UPDATE_PENGAJUAN_KI_FAILURE = "UPDATE_PENGAJUAN_KI_FAILURE";

export const GET_COUNTING_PLOT_KI_REQUEST = 'GET_COUNTING_PLOT_KI_REQUEST';
export const GET_COUNTING_PLOT_KI_SUCCESS = 'GET_COUNTING_PLOT_KI_SUCCESS';
export const GET_COUNTING_PLOT_KI_FAILURE = 'GET_COUNTING_PLOT_KI_FAILURE';

export const GET_LIST_PLOT_KI_REQUEST = 'GET_LIST_PLOT_KI_REQUEST';
export const GET_LIST_PLOT_KI_SUCCESS = 'GET_LIST_PLOT_KI_SUCCESS';
export const GET_LIST_PLOT_KI_FAILURE = 'GET_LIST_PLOT_KI_FAILURE';

export const GET_LIST_KEGIATAN_BIMTEK_KI_REQUEST = 'GET_LIST_KEGIATAN_BIMTEK_KI_REQUEST';
export const GET_LIST_KEGIATAN_BIMTEK_KI_SUCCESS = 'GET_LIST_KEGIATAN_BIMTEK_KI_SUCCESS';
export const GET_LIST_KEGIATAN_BIMTEK_KI_FAILURE = 'GET_LIST_KEGIATAN_BIMTEK_KI_FAILURE';

export const GET_PLOT_KI_REQUEST = 'GET_PLOT_KI_REQUEST';
export const GET_PLOT_KI_SUCCESS = 'GET_PLOT_KI_SUCCESS';
export const GET_PLOT_KI_FAILURE = 'GET_PLOT_KI_FAILURE';

export const SAVE_PENILAIAN_KI_REQUEST = 'SAVE_PENILAIAN_KI_REQUEST';
export const SAVE_PENILAIAN_KI_SUCCESS = 'SAVE_PENILAIAN_KI_SUCCESS';

export const GET_SIMILAR_TITLE_KI_REQUEST = 'GET_SIMILAR_TITLE_KI_REQUEST';
export const GET_SIMILAR_TITLE_KI_SUCCESS = 'GET_SIMILAR_TITLE_KI_SUCCESS';
export const GET_SIMILAR_TITLE_KI_FAILURE = 'GET_SIMILAR_TITLE_KI_FAILURE';

// export const SAVE_PENUGASAN_REVIEWER_KI_REQUEST = "SAVE_PENUGASAN_REVIEWER_KI_REQUEST";
// export const SAVE_PENUGASAN_REVIEWER_KI_SUCCESS = "SAVE_PENUGASAN_REVIEWER_KI_SUCCESS";
// export const SAVE_PENUGASAN_REVIEWER_KI_FAILURE = "SAVE_PENUGASAN_REVIEWER_KI_FAILURE";

// export const UPDATE_PENUGASAN_REVIEWER_KI_REQUEST = "UPDATE_PENUGASAN_REVIEWER_KI_REQUEST";
// export const UPDATE_PENUGASAN_REVIEWER_KI_SUCCESS = "UPDATE_PENUGASAN_REVIEWER_KI_SUCCESS";
// export const UPDATE_PENUGASAN_REVIEWER_KI_FAILURE = "UPDATE_PENUGASAN_REVIEWER_KI_FAILURE";

export const GET_LIST_PENUGASAN_REVIEWER_KI_REQUEST = "GET_LIST_PENUGASAN_REVIEWER_KI_REQUEST";
export const GET_LIST_PENUGASAN_REVIEWER_KI_SUCCESS = "GET_LIST_PENUGASAN_REVIEWER_KI_SUCCESS";
export const GET_LIST_PENUGASAN_REVIEWER_KI_FAILURE = "GET_LIST_PENUGASAN_REVIEWER_KI_FAILURE";

export const GET_ELIGIBLITY_DOSEN_KI_REQUEST = "GET_ELIGIBLITY_DOSEN_KI_REQUEST";
export const GET_ELIGIBLITY_DOSEN_KI_SUCCESS = "GET_ELIGIBLITY_DOSEN_KI_SUCCESS";
export const GET_ELIGIBLITY_DOSEN_KI_FAILURE = "GET_ELIGIBLITY_DOSEN_KI_FAILURE";
