/* eslint-disable no-unused-vars */
import {
  contentAnggaranRevisi,
  contentUsulanPengabdian,
} from "../../usulanBaruPengabdian/partials/content";
import headers from "./headers";
import stylePDF from "./styles";

export const contentPengabdian = data => {
  const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
    let thnAkhirUsulan = parseInt(thnPertamaUsulan);
    for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
      thnAkhirUsulan += tahun;
    }
    return thnAkhirUsulan;
  };

  const { hr } = stylePDF;
  const buildTablePerLuaran = (data = {}) => {
    const data_luaran = data?.data_luaran ?? {};
    const layout = {
      layout: {
        hLineWidth: (_i, _node) => 0.2,
        vLineWidth: _i => 0.2,
        padding: () => 0,
      },
      table: {
        widths: ["*"],
        body: [],
      },
    };
    if (Object.keys(data_luaran || {})?.length > 0) {
      layout.table.body.push([
        { text: data.nama_target_jenis_luaran, style: ["center"] },
      ]);

      // dari GPT
      const excludedKeys = [
        "id_luaran_publikasi_jurnal",
        "id_target_luaran",
        "dokumen_bukti_luaran",
        "id_transaksi_kegiatan",
        "id_luaran_konferensi",
        "tgl_created",
      ]; // keys to be excluded

      const keys = Object.keys(data_luaran || {}).filter(
        key => !excludedKeys.includes(key)
      );
      const values = keys.map(key => data_luaran[key]);
      keys.forEach((key, index) => {
        layout.table.body.push([
          { text: `${key} : ${values[index]}`, style: ["left"] },
        ]);
      });
      return layout;
    } else {
      layout.table.body.push([{ text: `Data Not Found`, style: ["left"] }]);
      return layout;
    }
  };

  const buildTableLuaranDinamis = (data = []) => {
    const luaran = data.filter(
      item =>
        ["publikasi_jurnal", "konferensi", "media_massa"].includes(
          item.group_jenis_luaran
        ) && item.data_luaran !== null
    );
    const stacks = luaran.map(item => ({
      stack: [
        {
          text: `${item.nama_jenis_luaran}`,
          style: ["bold", "mt-10"],
        },
        {
          ...buildTablePerLuaran(item),
        },
      ],
    }));
    return stacks;
  };

  return [
    // * Header
    headers(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI LAPORAN KEMAJUAN PENGABDIAN",
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `LAPORAN KEMAJUAN ${data.identitas?.thn_pertama_usulan} ${data.identitas?.lama_kegiatan > 1 ? "MULTI TAHUN" : "MONO TAHUN"}`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `ID Proposal: ${data.identitas?.id_usulan}`,
          style: ["fontSizeXs"],
        },
        {
          text: `Laporan Kemajuan Pengabdian : tahun ${data.identitas?.thn_pertama_usulan ?? ""
            } s.d. tahun ${getThnAkhirUsulan(
              data.identitas.lama_kegiatan,
              data.identitas.thn_pertama_usulan
            )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    ...contentUsulanPengabdian(data),
    {
      style: ["mt-10"],
      stack: contentAnggaranRevisi(data?.perbaikan_usulan, data?.support_data),
    },
    ...buildTableLuaranDinamis(data.luaran),
    {
      marginTop: 5,
      text: ["8. KEMAJUAN PENELITIAN"],
      style: ["bold"],
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t A. RINGKASAN",
        preserveLeadingSpaces: true,
      },
      style: ["bold"],
    },
    {
      marginTop: 5,
      text: `\u200B\t\t ${data.laporan_kemajuan.ringkasan}`,
      style: ["text", "fontSizeM"],
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t B. KATA KUNCI",
        preserveLeadingSpaces: true,
      },
      style: ["bold", "text"],
    },
    {
      marginTop: 5,
      text: `\u200B\t\t ${data.laporan_kemajuan.keyword}`,
      style: ["text"],
    },
  ];
};
