import PrototipeProposalPDF from "helpers/workers/pdf/prototipe";
import { instances, instancesV2 } from "../api_helper";
import * as url from "../url_helper";
import utilities from "helpers/utilities";
import { toast } from "react-toastify";

const API_V2_URL = process.env.REACT_APP_API_V2_URL || "http://localhost:8088/api/v2";

export const getCountingProposal = (params = {}) => {
    let endpoint = url.ENDPOINT_SUMMARY_PROPOSAL;
    if (JSON.stringify(params) !== "{}") {
        endpoint += "?";
        Object.entries(params).forEach(([key, value], i) => {
            if (value) {
                endpoint += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
            }
        })
    }
    return instancesV2.request({
        url: `${endpoint}`,
        method: "GET",
    });
};

export const getEligibilityPrototipe = (params = {}) => {
    let endpoint = url.ENDPOINT_PROPOSAL + "/eligibility";

    if (JSON.stringify(params) !== "{}") {
        endpoint += "?";
        Object.entries(params).forEach(([key, value], i) => {
            endpoint += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
        })
    }

    return instancesV2.request({
        url: `${endpoint}`,
        method: "GET",
    });
};

export const uploadDokumenPrototipe = async payload => {
    const formData = new FormData();
    const path = payload.path;
    formData.append("file", payload.file);
    formData.append("path", path);
    const res = await instances.request(`/file`, {
        data: formData,
        method: "POST",
    });
    return res;
};

export const getUsulanPrototipeLPPM = params => {
    let endpoint = url.ENDPOINT_PROPOSAL + "/search";
    delete params['search'];
    if (JSON.stringify(params) !== "{}") {
        endpoint += "?";
        Object.entries(params).forEach(([key, value], i) => {
            endpoint += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
        })
    }

    return instancesV2.request({
        url: `${endpoint}`,
        method: "GET",
    });
};

export const approvalPrototipeLPPM = params => {
    return instances({
        baseURL: API_V2_URL,
        url: `/proposal/operator-approval/${params.id}`,
        method: "PATCH",
        data: {
            ...params.data
        }
    })
}

export const getUsulanPrototipe = payload => {
    let endpoint = url.ENDPOINT_PROPOSAL;

    let criteria = ``;
    if (payload.criteria) {
        Object.entries(payload.criteria).forEach(([key, value], i) => {
            criteria += `${key}:${value}${i === Object.entries(payload.criteria).length - 1 ? '' : ','}`;
        })
    }
    if (criteria) {
        endpoint += `?criteria=${criteria}`;
    }
    
    let params = Object.entries(payload).length > 0 ? (criteria ? '&' : '?') : '';

    Object.entries(payload).forEach(([key, val], i) => {
        if (key !== 'criteria' && key !== 'id') {
            params += `${key}=${val}${i === Object.entries(payload).length - 1 ? '' : '&'}`;
        }
    });

    endpoint += params;

    if (payload.id) {
        endpoint = `${url.ENDPOINT_PROPOSAL}/${payload.id}`
    }

    return instancesV2.request({
        url: `${endpoint}`,
        method: "GET",
    });
};

export const saveUsulanPrototipe = payload => {
    return instancesV2.request({
        url: `${url.ENDPOINT_PROPOSAL}`,
        method: "POST",
        data: payload
    });
};

export const updateUsulanPrototipe = payload => {
    return instancesV2.request({
        url: `${url.ENDPOINT_PROPOSAL}/${payload.id}`,
        method: "PUT",
        data: payload
    });
};

export const deleteUsulanPrototipe = payload => {
    const endpoint = `${url.ENDPOINT_PROPOSAL}/${payload.id}`;

    return instancesV2.request({
        url: endpoint,
        method: "DELETE",
    });
};

export const getEligibilitypPrototipe = payload => {
    const type = payload.type;
    let params = "";
    if (payload.anggota) {
        params += `anggota=${payload.anggota}`;
    }
    if (payload.id_personal) {
        params += `&id_personal_anggota=${payload.id_personal}`;
    }
    return instances.request(
        `/kosabangsa/${type}/eligibility${params ? "?" + params : ""}`,
        {
            method: "GET",
        }
    );
};

export const downloadPrototipeProposal = async (data, cb, flag = 1) => {
    let { raw: {
        dokumen_pendukung,
        identitas,
        substansi
    } } = data;
    try {
        const fileName = `${identitas?.judul.replace(/[^a-zA-Z0-9 ]/g, "").trim()}`;
        // * Generate Pdf From Client
        const proposalPDF = new PrototipeProposalPDF({
            ...data,
        });
        const blobFileMainContent = await proposalPDF.generate();
        const blobFilePersetContent = await proposalPDF.generatePersetujuan();
        // * Get Pdf File From Server
        let blobFileSubstansi = "";
        if (substansi?.file_url_substansi) {
            blobFileSubstansi = await proposalPDF.getSingleFile(
                utilities.getFriendlyURL(substansi?.file_url_substansi)
            );
        }

        const contentMerge = [blobFileMainContent, blobFileSubstansi];

        if (blobFileSubstansi === "") {
            contentMerge.splice(1, 1);
        }

        for (const pendukung of Object.entries(dokumen_pendukung)) {
            if (pendukung[1] && pendukung[0].includes("url")) {
                let blobFilePendukung = await proposalPDF.getSingleFile(
                    utilities.getFriendlyURL(pendukung[1])
                );
                contentMerge.push(blobFilePendukung);
            }
        }

        if (data?.revisi) {
            if (data.revisi?.url_file_surat_kesanggupan) {
                let blobFileKesanggupan = await proposalPDF.getSingleFile(
                    utilities.getFriendlyURL(data.revisi?.url_file_surat_kesanggupan)
                );
                contentMerge.push(blobFileKesanggupan);
            }
        }

        contentMerge.push(blobFilePersetContent);

        const mergingFun = () => {
            //merging version 1 for merge common files
            //merging version 2 for merge files ignoring encrypted file
            //merging version 3 for merge files ignoring encrypted file & lost merged the file
            proposalPDF.mergingv3(contentMerge, (result) => {
                const resultMerged = result.res;
                
                if (result.error === false) {
                    if (flag !== 2) {
                        proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
                    }
                    cb({
                        success: true,
                        data: resultMerged
                    });
                } else {
                    mergingFun();
                    toast.warn("Beberapa file tidak dapat di gabung!");
                    toast.error(result.errMsg);
                    proposalPDF.forceDownload(contentMerge[result.indexBlobSkipped], `file_${result.indexBlobSkipped - 1}.pdf`);
                }
            });
        }

        mergingFun();

    } catch (error) {
        console.log(error)

        cb({
            success: false,
            errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
        });
    }
};



