import {
	GET_SKEMA_EVALUASI_SUBTANSI_FAILURE,
	GET_SKEMA_EVALUASI_SUBTANSI_REQUEST,
	GET_SKEMA_EVALUASI_SUBTANSI_SUCCESS,
	SET_CLEAR_SKEMA_EVALUASI_SUBTANSI,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_FAILURE,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_REQUEST,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_SUCCESS,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_FAILURE,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_REQUEST,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_SUCCESS,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_FAILURE,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
	GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS,
	GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_FAILURE,
	GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_REQUEST,
	GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_SUCCESS,
	GET_RAB_EVALUASI_SUBTANSI_FAILURE,
	GET_RAB_EVALUASI_SUBTANSI_REQUEST,
	GET_RAB_EVALUASI_SUBTANSI_SUCCESS,
	SAVE_PENILAIAN_EVALUASI_SUBTANSI_REQUEST,
	SAVE_PENILAIAN_PERMANEN_EVALUASI_SUBTANSI_REQUEST,
	GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE,
	GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS,
	GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
	SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
	SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS,
	SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE,
	GET_KELOMPOK_EVALUASI_SUBTANSI_V2_REQUEST,
	GET_KELOMPOK_EVALUASI_SUBTANSI_V2_SUCCESS,
	GET_KELOMPOK_EVALUASI_SUBTANSI_V2_FAILURE,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS,
	GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_FAILURE,
	GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_REQUEST,
	GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_SUCCESS,
	GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_FAILURE,
} from "./actionTypes";

// SkemaEvaluasi
export const setClearSkemaEvaluasiSubtansi = (request = {}, cb) => {
	return {
		type: SET_CLEAR_SKEMA_EVALUASI_SUBTANSI,
		payload: request,
		cb
	};
};

export const getSkemaEvaluasiSubtansi = (request = {}, cb) => {
	return {
		type: GET_SKEMA_EVALUASI_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};

export const getSkemaEvaluasiSubtansiSuccess = response => {
	return {
		type: GET_SKEMA_EVALUASI_SUBTANSI_SUCCESS,
		payload: response,
	};
};

export const getSkemaEvaluasiSubtansiFailure = (message, response) => {
	return {
		type: GET_SKEMA_EVALUASI_SUBTANSI_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

export const getKelompokEvaluasiSubtansi = (request = {}, cb) => {
	return {
		type: GET_KELOMPOK_EVALUASI_SUBTANSI_V2_REQUEST,
		payload: request,
		cb
	};
};

export const getKelompokEvaluasiSubtansiSuccess = response => {
	return {
		type: GET_KELOMPOK_EVALUASI_SUBTANSI_V2_SUCCESS,
		payload: response,
	};
};

export const getKelompokEvaluasiSubtansiFailure = (message, response) => {
	return {
		type: GET_KELOMPOK_EVALUASI_SUBTANSI_V2_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

//list usulan
export const getListUsulanEvaluasiSubtansi = (request = {}, cb) => {
	return {
		type: GET_LIST_USULAN_EVALUASI_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};

export const getListUsulanEvaluasiSubtansiSuccess = response => {
	return {
		type: GET_LIST_USULAN_EVALUASI_SUBTANSI_SUCCESS,
		payload: response,
	};
};

export const getListUsulanEvaluasiSubtansiFailure = (message, response) => {
	return {
		type: GET_LIST_USULAN_EVALUASI_SUBTANSI_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

export const getListUsulanEvaluasiSubtansiv2 = (request = {}, cb) => {
	return {
		type: GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
		payload: request,
		cb
	};
};

export const getListUsulanEvaluasiSubtansiv2Success = response => {
	return {
		type: GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS,
		payload: response,
	};
};

export const getListUsulanEvaluasiSubtansiv2Failure = (message, response) => {
	return {
		type: GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

//list RAB
export const getRABEvaluasiSubtansi = (request = {}, cb) => {
	return {
		type: GET_RAB_EVALUASI_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};

export const getRABEvaluasiSubtansiSuccess = response => {
	return {
		type: GET_RAB_EVALUASI_SUBTANSI_SUCCESS,
		payload: response,
	};
};

export const getRABEvaluasiSubtansiFailure = (message, response) => {
	return {
		type: GET_RAB_EVALUASI_SUBTANSI_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

//detail usulan
export const getDetailUsulanSubtansiEvaluasiv2 = (request = {}, cb) => {
	return {
		type: GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_REQUEST,
		payload: request,
		cb
	};
};

export const getDetailUsulanSubtansiEvaluasiv2Success = response => {
	return {
		type: GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS,
		payload: response,
	};
};

export const getDetailUsulanSubtansiEvaluasiv2Failure = (message, response) => {
	return {
		type: GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

export const getDetailUsulanSubtansiEvaluasi = (request = {}, cb) => {
	return {
		type: GET_DETAIL_USULAN_EVALUASI_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};

export const getDetailUsulanEvaluasiSubtansiSuccess = response => {
	return {
		type: GET_DETAIL_USULAN_EVALUASI_SUBTANSI_SUCCESS,
		payload: response,
	};
};

export const getDetailUsulanEvaluasiSubtansiFailure = (message, response) => {
	return {
		type: GET_DETAIL_USULAN_EVALUASI_SUBTANSI_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

// Komponen NIlai
export const getKomponenNilaiSubtansiEvaluasi = (request = {}, cb) => {
	return {
		type: GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};

export const getKomponenNilaiEvaluasiSubtansiSuccess = response => {
	return {
		type: GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_SUCCESS,
		payload: response,
	};
};

export const getKomponenNilaiEvaluasiSubtansiFailure = (message, response) => {
	return {
		type: GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

export const getKomponenNilaiSubtansiEvaluasiv2 = (request = {}, cb) => {
	return {
		type: GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};

export const getKomponenNilaiSubtansiEvaluasiv2Success = response => {
	return {
		type: GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_SUCCESS,
		payload: response,
	};
};

export const getKomponenNilaiSubtansiEvaluasiv2Failure = (message, response) => {
	return {
		type: GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

export const getListRABRekomenEvaluasiSubtansi = (request, cb) => {
	return {
		type: GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};

export const getListRABRekomenEvaluasiSubtansiSuccess = (response) => {
	return {
		type: GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS,
		payload: response
	};
};

export const getListRABRekomenEvaluasiSubtansiFailure = (message, response) => {
	return {
		type: GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE,
		payload: {
			message,
			errors: response,
		},
	};
};

export const saveRABRekomendasiEvaluasiSubtansi = (payload, cb) => {
	return {
		type: SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST,
		payload,
		cb
	};
};

export const saveRABRekomendasiEvaluasiSubtansiSuccess = (response, cb) => {
	return {
		type: SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS,
		payload: response,
		cb
	};
};

export const saveRABRekomendasiEvaluasiSubtansiFailure = (message, response) => {
	return {
		type: SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE,
		payload: {
			message,
			errors: response,
		},

	};
};

export const savePenilaianEvaluasiSubtansi = (request = {}, cb) => {
	return {
		type: SAVE_PENILAIAN_EVALUASI_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};

export const savePenilaianPermanenEvaluasiSubtansi = (request = {}, cb) => {
	return {
		type: SAVE_PENILAIAN_PERMANEN_EVALUASI_SUBTANSI_REQUEST,
		payload: request,
		cb
	};
};