
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
// import * as backend from "../../helpers/penugasan";
import * as backend from "../../../helpers/penugasan"
// Action Types
import {
    CREATE_PENUGASAN_JFS_REQUEST,
    UPDATE_PENUGASAN_JFS_REQUEST,
    GET_PENUGASAN_JFS_REQUEST,
    GET_PENUGASAN_JFS_BYID_REQUEST,
} from "../actionTypes";
// Action
import { toast } from "react-toastify";
import {
    getPenugasanJFSByIdSuccess,
    getPenugasanJFSByIdFailure,
    createPenugasanJFSSuccess,
    createPenugasanJFSFailure,
    updatePenugasanJFSSuccess,
    updatePenugasanJFSFailure,
    getPenugasanJFSSuccess,
    getPenugasanJFSFailure,
} from "./action";


function* createPenugasanJFS({ payload, cb }) {
    try {
        const res = yield call(backend.createPenugasanJFS, payload);
        if (res.data.code === 200) {
            yield put(
                createPenugasanJFSSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan");
            cb(res.data);
        } else {
            throw new Error(res.data.message);

        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(createPenugasanJFSFailure(message.error));
        cb(null)

    }
}

function* updatePenugasanJFS({ payload, id, cb }) {
    try {
        const res = yield call(backend.updatePenugasanJFS, { payload, id });
        if (res.data.code === 200) {
            yield put(
                updatePenugasanJFSSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan di Draft");
            cb(res.data);
        } else {
            throw new Error(res.data.message);

        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(updatePenugasanJFSFailure(message.error));
        cb(null)

    }
}

function* getPenugasanJFS() {
    try {
        const res = yield call(backend.getPenugasanJFS);
        if (res.data.code === 200) {
            yield put(
                getPenugasanJFSSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanJFSFailure(message.error));
    }
}

function* getPenugasanJFSById({ payload, cb }) {
    try {

        const res = yield call(backend.getPenugasanJFSById, payload.id);
        if (res.data.code === 200) {
            yield put(
                getPenugasanJFSByIdSuccess({ data: res.data.data })
            )
            cb(res.data)
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanJFSByIdFailure(message.error));
        cb(null)
    }
}

function* notificationSaga() {
    yield takeEvery(CREATE_PENUGASAN_JFS_REQUEST, createPenugasanJFS);
    yield takeEvery(GET_PENUGASAN_JFS_REQUEST, getPenugasanJFS);
    yield takeEvery(GET_PENUGASAN_JFS_BYID_REQUEST, getPenugasanJFSById);
    yield takeEvery(UPDATE_PENUGASAN_JFS_REQUEST, updatePenugasanJFS);
}

export default notificationSaga;
