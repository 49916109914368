import { call, put, takeEvery } from "redux-saga/effects";
// Backend
import * as backend from "../../helpers/backend";
import {
  approvalPrototipeFailure,
  approvalPrototipeSuccess,
  getUsulanPrototipeSuccess,
  getUsulanPrototipeFailure,
  saveUsulanPrototipeFailure,
  saveUsulanPrototipeSuccess,
  getCountingPrototipeSuccess,
  getCountingPrototipeFailure,
  deleteUsulanPrototipeSuccess,
  deleteUsulanPrototipeFailure,
  updateUsulanPrototipeFailure,
  updateUsulanPrototipeSuccess,
  getByIdUsulanPrototipeFailure,
  getByIdUsulanPrototipeSuccess,
  getEligibilityPrototipeSuccess,
  getEligibilityPrototipeFailure,
} from "./action";
import { toast } from "react-toastify";
import {
  SAVE_PROTOTIPE_REQUEST,
  DELETE_PROTOTIPE_REQUEST,
  UPDATE_PROTOTIPE_REQUEST,
  GET_BYID_PROTOTIPE_REQUEST,
  GET_LIST_PROTOTIPE_REQUEST,
  APPROVAL_LPPM_PROTOTIPE_REQUEST,
  GET_ELIGIBILITY_PROTOTIPE_REQUEST,
  GET_COUNTING_USULAN_PROTOTIPE_REQUEST,
} from "./actionTypes";

function* getCountingPrototipe({ cb, payload }) {
  try {
    const res = yield call(backend.getCountingProposal, payload);
    if (res.data.code === 200) {
      yield put(getCountingPrototipeSuccess({ data: res.data.data }));
    }
    cb(res.data);
  } catch (error) {
    const { message } = error;
    console.log("error", message);
    cb(null);
    toast.error("Something went wrong, please try again later!");
    yield put(getCountingPrototipeFailure(message.error));
  }
}

function* approvalPrototipe({ cb, payload }) {
  try {
    const res = yield call(backend.approvalPrototipeLPPM, payload);
    if (res.data.code === 200) {
      yield put(approvalPrototipeSuccess({ data: res.data.data }));
    }
    cb(res.data);
  } catch (error) {
    const { message } = error;
    console.log("error", message);
    cb(null);
    toast.error("Something went wrong, please try again later!");
    yield put(approvalPrototipeFailure(message?.error));
  }
}

function* getEligibilityPrototipe({ cb, payload }) {
  try {
    const res = yield call(backend.getEligibilityPrototipe, payload);

    if (res.data.code === 200) {
      yield put(getEligibilityPrototipeSuccess({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    console.log("error", message);
    cb(null);
    toast.error(message);
    yield put(getEligibilityPrototipeFailure(message.error));
  }
}

function* deleteUsulanPrototipe({ payload, cb }) {
  try {
    const res = yield call(backend.deleteUsulanPrototipe, payload);
    if (res.data.code === 200) {
      yield put(deleteUsulanPrototipeSuccess({ data: res.data.data }));
    }
    cb(res.data);
  } catch (error) {
    const { message } = error;
    cb(null);
    console.log(message);
    toast.error("Something went wrong, please try again later!");
    yield put(deleteUsulanPrototipeFailure(message.error));
  }
}

function* saveUsulanPrototipe({ payload, cb }) {
  try {
    const res = yield call(backend.saveUsulanPrototipe, payload);
    if (res.data.code === 200) {
      const id_proposal = res.data.data.id;
      yield put(saveUsulanPrototipeSuccess({ data: res.data.data }));
      yield put(
        getByIdUsulanPrototipeSuccess({ data: res.data.data, id_proposal })
      );
    }
    cb(res.data);
  } catch (error) {
    const { message } = error;
    cb(null);
    console.log(message);
    toast.error("Something went wrong, please try again later!");
    yield put(saveUsulanPrototipeFailure(message.error));
  }
}

function* updateUsulanPrototipe({ payload, cb }) {
  try {
    const res = yield call(backend.updateUsulanPrototipe, payload);
    if (res.data.code === 200) {
      yield put(updateUsulanPrototipeSuccess({ data: res.data.data }));
      const id_proposal = res.data.data.id;
      yield put(
        getByIdUsulanPrototipeSuccess({ data: res.data.data, id_proposal })
      );
    }
    cb(res.data);
  } catch (error) {
    const { message } = error;
    cb(null);
    console.log(message);
    toast.error("Something went wrong, please try again later!");
    yield put(updateUsulanPrototipeFailure(message.error));
  }
}

function* getByIdUsulanPrototipe({ payload, cb, flag = 1 }) {
  try {
    const res = yield call(backend.getUsulanPrototipe, payload);
    if (res.data.code === 200) {
      yield put(
        getByIdUsulanPrototipeSuccess({
          data: res.data.data,
          id_proposal: flag === 2 ? res.data.data.id : null,
        })
      );
    } else {
      throw new Error(res.data.message);
    }
    cb(res.data);
  } catch (error) {
    const { message } = error;
    console.log("error", message);
    cb(null);
    toast.error("Something went wrong, please try again later!");
    yield put(getByIdUsulanPrototipeFailure(message.error));
  }
}

function* getListUsulanPrototipe({ payload, cb }) {
  try {
    const res = yield call(
      payload.search
        ? backend.getUsulanPrototipeLPPM
        : backend.getUsulanPrototipe,
      payload
    );
    if (res.data.code === 200) {
      yield put(getUsulanPrototipeSuccess({ data: res.data.data }));
      cb(res.data.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    cb(null);
    console.log(message);
    toast.error("Something went wrong, please try again later!");
    yield put(getUsulanPrototipeFailure(message.error));
  }
}

function* notificationSaga() {
  yield takeEvery(SAVE_PROTOTIPE_REQUEST, saveUsulanPrototipe);
  yield takeEvery(UPDATE_PROTOTIPE_REQUEST, updateUsulanPrototipe);
  yield takeEvery(DELETE_PROTOTIPE_REQUEST, deleteUsulanPrototipe);
  yield takeEvery(GET_LIST_PROTOTIPE_REQUEST, getListUsulanPrototipe);
  yield takeEvery(GET_BYID_PROTOTIPE_REQUEST, getByIdUsulanPrototipe);
  yield takeEvery(APPROVAL_LPPM_PROTOTIPE_REQUEST, approvalPrototipe);
  yield takeEvery(GET_ELIGIBILITY_PROTOTIPE_REQUEST, getEligibilityPrototipe);
  yield takeEvery(GET_COUNTING_USULAN_PROTOTIPE_REQUEST, getCountingPrototipe);
}

export default notificationSaga;
