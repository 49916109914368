export const LIST_DAFTAR_REVIEWER_REQUEST = "LIST_DAFTAR_REVIEWER_REQUEST";
export const LIST_DAFTAR_REVIEWER_SUCCESS = "LIST_DAFTAR_REVIEWER_SUCCESS";
export const LIST_DAFTAR_REVIEWER_FAILURE = "LIST_DAFTAR_REVIEWER_FAILURE";

export const PUT_STATUS_DAFTAR_REVIEWER_REQUEST =
  "PUT_STATUS_DAFTAR_REVIEWER_REQUEST";
export const PUT_STATUS_DAFTAR_REVIEWER_SUCCESS =
  "PUT_STATUS_DAFTAR_REVIEWER_SUCCESS";
export const PUT_STATUS_DAFTAR_REVIEWER_FAILURE =
  "PUT_STATUS_DAFTAR_REVIEWER_FAILURE";

export const GET_REVIEWER_BY_NAME_REQUEST = "GET_REVIEWER_BY_NAME_REQUEST";
export const GET_REVIEWER_BY_NAME_SUCCESS = "GET_REVIEWER_BY_NAME_SUCCESS";
export const GET_REVIEWER_BY_NAME_FAILURE = "GET_REVIEWER_BY_NAME_FAILURE";

export const POST_DAFTAR_REVIEWER_REQUEST = "POST_DAFTAR_REVIEWER_REQUEST";
export const POST_DAFTAR_REVIEWER_SUCCESS = "POST_DAFTAR_REVIEWER_SUCCESS";
export const POST_DAFTAR_REVIEWER_FAILURE = "POST_DAFTAR_REVIEWER_FAILURE";

export const GET_DROPDOWN_TAHAPAN_REQUEST = "GET_DROPDOWN_TAHAPAN_REQUEST";
export const GET_DROPDOWN_TAHAPAN_SUCCESS = "GET_DROPDOWN_TAHAPAN_SUCCESS";
export const GET_DROPDOWN_TAHAPAN_FAILURE = "GET_DROPDOWN_TAHAPAN_FAILURE";

export const GET_DROPDOWN_TAHUN_KEGIATAN_REQUEST =
  "GET_DROPDOWN_TAHUN_KEGIATAN_REQUEST";
export const GET_DROPDOWN_TAHUN_KEGIATAN_SUCCESS =
  "GET_DROPDOWN_TAHUN_KEGIATAN_SUCCESS";
export const GET_DROPDOWN_TAHUN_KEGIATAN_FAILURE =
  "GET_DROPDOWN_TAHUN_KEGIATAN_FAILURE";

export const GET_SKEMA_REVIEWER_REQUEST = "GET_SKEMA_REVIEWER_REQUEST";
export const GET_SKEMA_REVIEWER_SUCCESS = "GET_SKEMA_REVIEWER_SUCCESS";
export const GET_SKEMA_REVIEWER_FAILURE = "GET_SKEMA_REVIEWER_FAILURE";

export const GET_SKEMA_REVIEWER_BY_ID_REQUEST =
  "GET_SKEMA_REVIEWER_BY_ID_REQUEST";
export const GET_SKEMA_REVIEWER_BY_ID_SUCCESS =
  "GET_SKEMA_REVIEWER_BY_ID_SUCCESS";
export const GET_SKEMA_REVIEWER_BY_ID_FAILURE =
  "GET_SKEMA_REVIEWER_BY_ID_FAILURE";

export const SEARCH_REVIEWER_PT_REQUEST = "SEARCH_REVIEWER_PT_REQUEST";
export const SEARCH_REVIEWER_PT_SUCCESS = "SEARCH_REVIEWER_PT_SUCCESS";
export const SEARCH_REVIEWER_PT_FAILURE = "SEARCH_REVIEWER_PT_FAILURE";

export const SEARCH_REVIEWER_PT2_REQUEST = "SEARCH_REVIEWER_PT2_REQUEST";
export const SEARCH_REVIEWER_PT2_SUCCESS = "SEARCH_REVIEWER_PT2_SUCCESS";
export const SEARCH_REVIEWER_PT2_FAILURE = "SEARCH_REVIEWER_PT2_FAILURE";

export const SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_REQUEST =
  "SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_REQUEST";
export const SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_SUCCESS =
  "SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_SUCCESS";
export const SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_FAILURE =
  "SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_FAILURE";

export const SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_REQUEST =
  "SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_REQUEST";
export const SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_SUCCESS =
  "SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_SUCCESS";
export const SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_FAILURE =
  "SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_FAILURE";

export const SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_REQUEST =
  "SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_REQUEST";
export const SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_SUCCESS =
  "SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_SUCCESS";
export const SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_FAILURE =
  "SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_FAILURE";

export const SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_REQUEST =
  "SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_REQUEST";
export const SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_SUCCESS =
  "SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_SUCCESS";
export const SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_FAILURE =
  "SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_FAILURE";

export const POST_PLOTTING_REVIEWER_PENELITIAN_REQUEST =
  "POST_PLOTTING_REVIEWER_PENELITIAN_REQUEST";
export const POST_PLOTTING_REVIEWER_PENELITIAN_SUCCESS =
  "POST_PLOTTING_REVIEWER_PENELITIAN_SUCCESS";
export const POST_PLOTTING_REVIEWER_PENELITIAN_FAILURE =
  "POST_PLOTTING_REVIEWER_PENELITIAN_FAILURE";

export const GET_LIST_REKAP_PLOTTING_REVIEWER_V2_REQUEST =
  "GET_LIST_REKAP_PLOTTING_REVIEWER_V2_REQUEST";
export const GET_LIST_REKAP_PLOTTING_REVIEWER_V2_SUCCESS =
  "GET_LIST_REKAP_PLOTTING_REVIEWER_V2_SUCCESS";
export const GET_LIST_REKAP_PLOTTING_REVIEWER_V2_FAILURE =
  "GET_LIST_REKAP_PLOTTING_REVIEWER_V2_FAILURE";
export const RESET_LIST_REKAP_PLOTTING_REVIEWER_V2 =
  "RESET_LIST_REKAP_PLOTTING_REVIEWER_V2";

export const GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_REQUEST =
  "GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_REQUEST";
export const GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS =
  "GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS";
export const GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_FAILURE =
  "GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_FAILURE";

export const GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_REQUEST =
  "GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_REQUEST";
export const GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_SUCCESS =
  "GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_SUCCESS";
export const GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_FAILURE =
  "GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_FAILURE";

export const CREATE_PLOTTING_PLOTTING_REVIEWER_V2_REQUEST =
  "CREATE_PLOTTING_PLOTTING_REVIEWER_V2_REQUEST";
export const CREATE_PLOTTING_PLOTTING_REVIEWER_V2_SUCCESS =
  "CREATE_PLOTTING_PLOTTING_REVIEWER_V2_SUCCESS";
export const CREATE_PLOTTING_PLOTTING_REVIEWER_V2_FAILURE =
  "CREATE_PLOTTING_PLOTTING_REVIEWER_V2_FAILURE";

export const GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_REQUEST =
  "GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_REQUEST";
export const GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_SUCCESS =
  "GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_SUCCESS";
export const GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_FAILURE =
  "GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_FAILURE";

export const GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_REQUEST =
  "GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_REQUEST";
export const GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS =
  "GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS";
export const GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_FAILURE =
  "GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_FAILURE";
