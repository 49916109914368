import {
  GET_PENGABDIAN_REQUEST,
  GET_PENGABDIAN_SUCCESS,
  GET_PENGABDIAN_FAILURE,
} from "./actionTypes";

const initialState = {
  pengabdian: {
    isFetching: false,
    items: [],
    item: {},
  },
};

const usulanPengabdian = (state = initialState, action) => {
  switch (action.type) {
    case GET_PENGABDIAN_REQUEST:
      return (state = {
        ...state,
        pengabdian: {
          ...state.pengabdian,
          isFetching: true,
        },
      });
    case GET_PENGABDIAN_SUCCESS:
      if (action.payload.state == "detail") {
        return (state = {
          ...state,
          pengabdian: {
            ...state.pengabdian,
            isFetching: false,
            item: action.payload.data ?? {},
          },
        });
      } else {
        return (state = {
          ...state,
          pengabdian: {
            ...state.pengabdian,
            isFetching: false,
            items: action.payload.data ?? [],
          },
        });
      }
    case GET_PENGABDIAN_FAILURE:
      return (state = {
        ...state,
        pengabdian: {
          ...state.pengabdian,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    default:
      return state;
  }
};

export default usulanPengabdian;
