
import {
    CREATE_PENUGASAN_KOSABANGSA_REQUEST,
    CREATE_PENUGASAN_KOSABANGSA_SUCCESS,
    CREATE_PENUGASAN_KOSABANGSA_FAILURE,
    UPDATE_PENUGASAN_KOSABANGSA_REQUEST,
    UPDATE_PENUGASAN_KOSABANGSA_SUCCESS,
    UPDATE_PENUGASAN_KOSABANGSA_FAILURE,
    GET_PENUGASAN_KOSABANGSA_REQUEST,
    GET_PENUGASAN_KOSABANGSA_SUCCESS,
    GET_PENUGASAN_KOSABANGSA_FAILURE,
    GET_PENUGASAN_KOSABANGSA_BYID_REQUEST,
    GET_PENUGASAN_KOSABANGSA_BYID_SUCCESS,
    GET_PENUGASAN_KOSABANGSA_BYID_FAILURE,
} from "../actionTypes";

const initialState = {
    submit: {
        isFetching: false,
        item: {},
        errorMessage: "",
        errors: null,
    },
    draft: {
        isFetching: false,
        item: {},
        errorMessage: "",
        errors: null,
    },
    listPenugasanKosaBangsa : {
        isFetching: false,
        items: [],
        errorMessage: "",
        errors: null
    },
    penugasanKosaBangsa: {
        isFetching: false,
        item: {},
        errorMessage: "",
    },
   
};

const penugasanKosaBangsa = (state = initialState, action) => {
    switch (action.type) {
       
        case CREATE_PENUGASAN_KOSABANGSA_REQUEST:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: true,
                }
            })
        case CREATE_PENUGASAN_KOSABANGSA_SUCCESS:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: false,
                    item: action.payload.data,
                }
            })
        case CREATE_PENUGASAN_KOSABANGSA_FAILURE:
            return (state = {
                ...state,
                submit: {
                    ...state.submit,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            })
        case UPDATE_PENUGASAN_KOSABANGSA_REQUEST:
            return (state = {
                ...state,
                draft: {
                    ...state.draft,
                    isFetching: true,

                }
            })
        case UPDATE_PENUGASAN_KOSABANGSA_SUCCESS:
            return (state = {
                ...state,
                draft: {
                    ...state.draft,
                    isFetching: false,
                    item: action.payload.data,

                }
            })
        case UPDATE_PENUGASAN_KOSABANGSA_FAILURE:
            return (state = {
                ...state,
                draft: {
                    ...state.draft,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors,
                }
            })
        case GET_PENUGASAN_KOSABANGSA_REQUEST:
            return (state = {
                ...state,
                listPenugasanKosaBangsa: {
                    ...state.listPenugasanKosaBangsa,
                    isFetching: true
                }
            })
        case GET_PENUGASAN_KOSABANGSA_SUCCESS:
            return (state = {
                ...state,
                listPenugasanKosaBangsa: {
                    ...state.listPenugasanKosaBangsa,
                    isFetching: false,
                    items: action.payload.data
                }
            })
        case GET_PENUGASAN_KOSABANGSA_FAILURE:
            return (state = {
                ...state,
                listPenugasanKosaBangsa: {
                    ...state.listPenugasanKosaBangsa,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors
                }
            })
        case GET_PENUGASAN_KOSABANGSA_BYID_REQUEST:
            return (state = {
                ...state,
                penugasanKosaBangsa: {
                    ...state.penugasanKosaBangsa,
                    isFetching: true
                }

            })
        case GET_PENUGASAN_KOSABANGSA_BYID_SUCCESS:
            return (state = {
                ...state,
                penugasanKosaBangsa: {
                    ...state.penugasanKosaBangsa,
                    isFetching: false,
                    item: action.payload.data
                }
            })
        case GET_PENUGASAN_KOSABANGSA_BYID_FAILURE:
            return (state = {
                ...state,
                penugasanKosaBangsa: {
                    ...state.penugasanKosaBangsa,
                    isFetching: false,
                    errorMessage: action.payload.message,
                    errors: action.payload.errors
                }
            })
        default:
            return state;
    }
};

export default penugasanKosaBangsa;
