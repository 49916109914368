import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
  TRACKING_USULAN_PENELITIAN_REQUEST,
  TRACKING_USULAN_PENELITIAN_SUCCESS,
  TRACKING_USULAN_PENELITIAN_FAILURE,
  DASHBOARD_SYNC_PDDIKTI_REQUEST,
  DASHBOARD_SYNC_PDDIKTI_SUCCESS,
  DASHBOARD_SYNC_PDDIKTI_FAILURE,
  DASHBOARD_SYNC_SINTA_REQUEST,
  DASHBOARD_SYNC_SINTA_SUCCESS,
  DASHBOARD_SYNC_SINTA_FAILURE,
  DASHBOARD_UPDATE_IDENTITAS,
  DASHBOARD_UPDATE_IDENTITAS_REQUEST,
  ARTICLE_REQUEST,
  ARTICLE_SUCCESS,
  ARTICLE_FAILURE,
  ARTICLE_SAVE,
  LIST_USULAN_FAILURE,
  LIST_USULAN_SUCCESS,
  LIST_USULAN_REQUEST,
  GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_FAILURE,
  GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_REQUEST,
  GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_SUCCESS,
  GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_REQUEST,
  GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_SUCCESS,
  GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_FAILURE,
  DASHBOARD_IDENTITAS_DOSEN_REQUEST,
  DASHBOARD_IDENTITAS_DOSEN_SUCCESS,
  DASHBOARD_IDENTITAS_DOSEN_FAILURE,
  DASHBOARD_SINTA_DOSEN_SUCCESS,
  DASHBOARD_SINTA_DOSEN_REQUEST,
  DASHBOARD_SINTA_DOSEN_FAILURE,
} from "./actionTypes";

// Dashboard
export const getDashboards = (request = "") => {
  return {
    type: DASHBOARD_REQUEST,
    payload: request,
  };
};

export const getDashboardSuccess = (response, tab) => {
  return {
    type: DASHBOARD_SUCCESS,
    payload: { response, tab },
  };
};

export const getDashboardFailure = (message, response) => {
  return {
    type: DASHBOARD_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// Dashboard Identisa
export const getDashboardIdentitasDosen = (request = "") => {
  return {
    type: DASHBOARD_IDENTITAS_DOSEN_REQUEST,
    payload: request,
  };
};

export const getDashboardIdentitasDosenSuccess = (response, tab) => {
  return {
    type: DASHBOARD_IDENTITAS_DOSEN_SUCCESS,
    payload: { response, tab },
  };
};

export const getDashboardIdentitasDosenFailure = (message, response) => {
  return {
    type: DASHBOARD_IDENTITAS_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// Dashboard Sinta
export const getDashboardSinta = (request = "") => {
  return {
    type: DASHBOARD_SINTA_DOSEN_REQUEST,
    payload: request,
  };
};

export const getDashboardSintaSuccess = (response, tab) => {
  return {
    type: DASHBOARD_SINTA_DOSEN_SUCCESS,
    payload: { response, tab },
  };
};

export const getDashboardSintaFailure = (message, response) => {
  return {
    type: DASHBOARD_SINTA_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Tracking usulan penelitian
export const getTrackingUsulanPenelitian = (request = "") => {
  return {
    type: TRACKING_USULAN_PENELITIAN_REQUEST,
    payload: request,
  };
};

export const getTrackingUsulanPenelitianSuccess = response => {
  return {
    type: TRACKING_USULAN_PENELITIAN_SUCCESS,
    payload: response,
  };
};

export const getTrackingUsulanPenelitianFailure = (message, response) => {
  return {
    type: TRACKING_USULAN_PENELITIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//get list usulan
export const getListUsulan = (request = "") => {
  return {
    type: LIST_USULAN_REQUEST,
    payload: request,
  };
};

export const getListUsulanSuccess = response => {
  return {
    type: LIST_USULAN_SUCCESS,
    payload: response,
  };
};

export const getListUsulanFailure = (message, response) => {
  return {
    type: LIST_USULAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Sync PDDIKTI
export const syncPddikti = (request = "") => {
  return {
    type: DASHBOARD_SYNC_PDDIKTI_REQUEST,
    payload: request,
  };
};

export const syncPddiktiSuccess = response => {
  return {
    type: DASHBOARD_SYNC_PDDIKTI_SUCCESS,
    payload: response,
  };
};

export const syncPddiktiFailure = (message, response) => {
  return {
    type: DASHBOARD_SYNC_PDDIKTI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Sync Sinta
export const syncSinta = (request = "") => {
  return {
    type: DASHBOARD_SYNC_SINTA_REQUEST,
    payload: request,
  };
};

export const syncSintaSuccess = response => {
  return {
    type: DASHBOARD_SYNC_SINTA_SUCCESS,
    payload: response,
  };
};

export const syncSintaFailure = (message, response) => {
  return {
    type: DASHBOARD_SYNC_SINTA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Update Identitas
export const updateIdentitasSuccess = response => {
  return {
    type: DASHBOARD_UPDATE_IDENTITAS,
    payload: response,
  };
};

export const updateIdentitas = (response, history) => {
  return {
    type: DASHBOARD_UPDATE_IDENTITAS_REQUEST,
    payload: response,
    history,
  };
};

// Artikel
export const articleRequest = (request = "") => {
  return {
    type: ARTICLE_REQUEST,
    payload: request,
  };
};

export const articleSuccess = response => {
  return {
    type: ARTICLE_SUCCESS,
    payload: response,
  };
};

export const articleFailure = (message, response) => {
  return {
    type: ARTICLE_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const articleSave = request => {
  return {
    type: ARTICLE_SAVE,
    payload: request,
  };
};
// riwayat penelitian
export const getRiwayatPenelitianDashboardDosen = (request = "") => {
  return {
    type: GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_REQUEST,
    payload: request,
  };
};

export const getRiwayatPenelitianDashboardDosenSuccess = response => {
  return {
    type: GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_SUCCESS,
    payload: response,
  };
};

export const getRiwayatPenelitianDashboardDosenFailure = (
  message,
  response
) => {
  return {
    type: GET_LIST_RIWAYAT_PENELITIAN_DASHBOARD_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getRiwayatPengabdianDashboardDosen = (request = "") => {
  return {
    type: GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_REQUEST,
    payload: request,
  };
};

export const getRiwayatPengabdianDashboardDosenSuccess = response => {
  return {
    type: GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_SUCCESS,
    payload: response,
  };
};

export const getRiwayatPengabdianDashboardDosenFailure = (
  message,
  response
) => {
  return {
    type: GET_LIST_RIWAYAT_PENGABDIAN_DASHBOARD_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
