import { instancesKI } from "../api_helper";
import * as url from "../url_helper";

export const getBatchKI = ({
   id,
   params = {}
}) => {
   let URL = url.ENDPOINT_BATCH_KI;
   if (!id) {
      URL += '/search';
   }
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
      URL += queryParams;
   }
   
   if (id) {
      URL += `/${id}`;
   }

   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const getWhitlistUsulanKI = ({
   id,
   params = {}
}) => {
   let URL = url.WHITLIST_KI_EP;
 
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
      URL += queryParams;
   }
   
   if (id) {
      URL += `/${id}`;
   }

   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const createWhitelistKI = (payload) => {
   let URL = url.WHITLIST_KI_EP;
   return instancesKI.request({
      url: URL,
      data: payload,
      method: "POST"
   });
}

export const updateWhitelistKI = (data) => {
   let URL = `${url.WHITLIST_KI_EP}/${data.id}`;
   const payload = data.payload;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "PUT"
   });
}

export const deleteWhitelistKI = (id) => {
   let URL = `${url.WHITLIST_KI_EP}/${id}`;
   const payload = data.payload;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "DELETE"
   });
}

export const createBatchKI = (payload) => {
   let URL = url.ENDPOINT_BATCH_KI;
   return instancesKI.request({
      url: URL,
      data: payload,
      method: "POST"
   });
}

export const updateBatchKI = (data) => {
   let URL = `${url.ENDPOINT_BATCH_KI}/${data.id}`;
   const payload = data.payload;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "PUT"
   });
}

export const actionBatchKI = (data) => {
   let URL = `${url.ENDPOINT_BATCH_KI}/${data.action}/${data.id}`;
   const payload = data.payload;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "PATCH"
   });
}

export const deleteBatchKI = (data) => {
   let URL = `${url.ENDPOINT_BATCH_KI}/${data.id}`;

   return instancesKI.request({
      url: URL,
      method: "DELETE"
   });
}
