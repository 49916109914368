export const GET_SKEMA_SITE_VISIT_REQUEST = "GET_SKEMA_SITE_VISIT_REQUEST";
export const GET_SKEMA_SITE_VISIT_SUCCESS = "GET_SKEMA_SITE_VISIT_SUCCESS";
export const GET_SKEMA_SITE_VISIT_FAILURE = "GET_SKEMA_SITE_VISIT_FAILURE";
export const SET_CLEAR_SKEMA_SITE_VISIT = "SET_CLEAR_SKEMA_SITE_VISIT";

export const GET_LIST_USULAN_SITE_VISIT_REQUEST = "GET_LIST_USULAN_SITE_VISIT_REQUEST";
export const GET_LIST_USULAN_SITE_VISIT_SUCCESS = "GET_LIST_USULAN_SITE_VISIT_SUCCESS";
export const GET_LIST_USULAN_SITE_VISIT_FAILURE = "GET_LIST_USULAN_SITE_VISIT_FAILURE";

export const GET_DETAIL_USULAN_SITE_VISIT_REQUEST = "GET_DETAIL_USULAN_SITE_VISIT_REQUEST";
export const GET_DETAIL_USULAN_SITE_VISIT_SUCCESS = "GET_DETAIL_USULAN_SITE_VISIT_SUCCESS";
export const GET_DETAIL_USULAN_SITE_VISIT_FAILURE = "GET_DETAIL_USULAN_SITE_VISIT_FAILURE";

export const GET_KOMPONEN_NILAI_SITE_VISIT_REQUEST = "GET_KOMPONEN_NILAI_SITE_VISIT_REQUEST";
export const GET_KOMPONEN_NILAI_SITE_VISIT_SUCCESS = "GET_KOMPONEN_NILAI_SITE_VISIT_SUCCESS";
export const GET_KOMPONEN_NILAI_SITE_VISIT_FAILURE = "GET_KOMPONEN_NILAI_SITE_VISIT_FAILURE";

export const SAVE_PENILAIAN_SITE_VISIT_REQUEST = "SAVE_PENILAIAN_SITE_VISIT_REQUEST";
export const SAVE_PENILAIAN_PERMANEN_SITE_VISIT_REQUEST = "SAVE_PENILAIAN_PERMANEN_SITE_VISIT_REQUEST";