
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
// import * as backend from "../../helpers/penugasan";
import * as backend from "../../../helpers/penugasan"
// Action Types
import {
    CREATE_PENUGASAN_PROTOTIPE_REQUEST,
    UPDATE_PENUGASAN_PROTOTIPE_REQUEST,
    GET_PENUGASAN_PROTOTIPE_REQUEST,
    GET_PENUGASAN_PROTOTIPE_BYID_REQUEST,
    VALIDATION_PENUGASAN_PROTOTIPE_REQUEST
} from "../actionTypes";
// Action
import { toast } from "react-toastify";
import {
    getPenugasanPrototipeByIdSuccess,
    getPenugasanPrototipeByIdFailure,
    createPenugasanPrototipeSuccess,
    createPenugasanPrototipeFailure,
    updatePenugasanPrototipeSuccess,
    updatePenugasanPrototipeFailure,
    getPenugasanPrototipeSuccess,
    getPenugasanPrototipeFailure,
    validationsPrototipeFailure,
    validationsPrototipeSuccess
} from "./action";


function* createPenugasanPrototipe({ payload, cb }) {
    try {
        const res = yield call(backend.createPenugasanPrototipe, payload);
        if (res.data.code === 200) {
            yield put(
                createPenugasanPrototipeSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan");
            cb(res.data);
        } else {
            throw new Error(res.data.message);

        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(createPenugasanPrototipeFailure(message.error));
        cb(null)

    }
}

function* updatePenugasanPrototipe({ payload, id, cb }) {
    try {
        const res = yield call(backend.updatePenugasanPrototipe, { payload, id });
        if (res.data.code === 200) {
            yield put(
                updatePenugasanPrototipeSuccess({ data: res.data.data })
            )
            toast.success("Data Berhasil Disimpan");
            cb(res.data);
        } else {
            throw new Error(res.data.message);

        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(updatePenugasanPrototipeFailure(message.error));
        cb(null)

    }
}

function* getPenugasanPrototipe() {
    try {
        const res = yield call(backend.getPenugasanPrototipe);
        if (res.data.code === 200) {
            yield put(
                getPenugasanPrototipeSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanPrototipeFailure(message.error));
    }
}

function* getPenugasanPrototipeById({ payload, cb }) {
    try {

        const res = yield call(backend.getPenugasanPrototipeById, payload.id);
        if (res.data.code === 200) {
            yield put(
                getPenugasanPrototipeByIdSuccess({ data: res.data.data })
            )
            cb(res.data)
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(getPenugasanPrototipeByIdFailure(message.error));
        cb(null)
    }
}

function* validationsPrototipe() {
    try {

        const res = yield call(backend.validationsPrototipe);
        if (res.data.code === 200) {
            yield put(
                validationsPrototipeSuccess({ data: res.data.data })
            )
        } else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        const { message } = error;
        toast.error(message);
        yield put(validationsPrototipeFailure(message.error));
    }
}


function* notificationSaga() {
    yield takeEvery(CREATE_PENUGASAN_PROTOTIPE_REQUEST, createPenugasanPrototipe);
    yield takeEvery(GET_PENUGASAN_PROTOTIPE_REQUEST, getPenugasanPrototipe);
    yield takeEvery(GET_PENUGASAN_PROTOTIPE_BYID_REQUEST, getPenugasanPrototipeById);
    yield takeEvery(UPDATE_PENUGASAN_PROTOTIPE_REQUEST, updatePenugasanPrototipe);
    yield takeEvery(VALIDATION_PENUGASAN_PROTOTIPE_REQUEST, validationsPrototipe);
}

export default notificationSaga;
