import { instancesKI, instancesReportKI } from "../api_helper";
import * as url from "../url_helper";

export const checkSubmissionKI = (payload) => {
   let URL = "/jenis/is-open-submission";
   if (payload?.id_jenis_ki) {
      URL += `/${payload.id_jenis_ki}`;
   }

   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const getSimilarityTitleKI = ({ title }) => {

   let URL = url.ENDPOINT_SIMILAR_TITLE_KI_REVIEWER;

   return instancesKI.request({
      url: URL,
      data: {
         title
      },
      method: "POST"
   });
}

export const evaluatePenilaianReviewer = (payload) => {
   const idPlotting = payload.id_plotting;
   const data = payload.data;
   let URL = url.ENDPOINT_EVALUATE_KI_REVIEWER + "/" + idPlotting;

   return instancesKI.request({
      url: URL,
      data,
      method: "PATCH"
   });
}

export const getPengajuanKI = ({
   id,
   params = {}
}) => {
   let URL = url.ENDPOINT_KI_PENGAJUAN;
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
      URL += queryParams;
   }

   if (id) {
      URL += `/${id}`;
   }

   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const getPengajuanKIPusat = ({
   id,
   params = {},
   filter = []
}) => {
   let URL = url.ENDPOINT_KI_PENGAJUAN + "/search";
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value && key) {
            queryParams += `${key}=${value}${i === (Object.entries(params).length - 1) ? '' : '&'}`;
         }
      });
   }

   if (filter.length > 0) {
      if (queryParams) {
         queryParams += "&";
      } else {
         queryParams += "?";
      }
      filter.forEach((fil, i) => {
         if (fil.value) {
            queryParams += `${fil.field}=${fil.value}${i === filter.length - 1 ? '' : '&'}`;
         }
      });
   }
   URL += queryParams;

   if (id) {
      URL += `/${id}`;
   }

   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const getCountingPengajuanKI = (params = {}) => {
   let URL = url.ENDPOINT_KI_PENGAJUAN + "/summary";
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
   }

   URL += queryParams;

   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const getListReviewerPlottingKI = ({
   id,
   params = {},
   filter = [],
   is_opt = false
}) => {
   let URL = url.ENDPOINT_KI_REVIEWER + (is_opt ? "/search" : "");
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
      if (filter.length > 0) {
         if (queryParams) {
            queryParams += "&";
         } else {
            queryParams += "?";
         }
         filter.forEach((fil, i) => {
            if (fil.value) {
               queryParams += `${fil.field}=${fil.value}${i === filter.length - 1 ? '' : '&'}`;
            }
         });
      }
      URL += queryParams;
   }

   if (id) {
      URL += `/${id}`;
   }

   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const getCountingReviewerPlotKI = (params = {}) => {
   let URL = url.ENDPOINT_KI_REVIEWER + "/summary";
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
   }

   URL += queryParams;

   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const getEligibiltyDosenKI = (params = {}) => {
   let URL = url.ENDPOINT_ELIGIBILITY_DOSEN_KI;
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
   }
   URL += queryParams;
   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const createPengajuanKI = (payload) => {
   let URL = url.ENDPOINT_KI_PENGAJUAN;


   return instancesKI.request({
      url: URL,
      data: payload,
      method: "POST"
   });
}

export const savePenilaianEvaluasiKI = (data) => {
   const { payload, id_plotting } = data;
   let URL = url.ENDPOINT_EVALUATE_KI_REVIEWER + "/" + id_plotting;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "PATCH"
   });
}

export const updatePengajuanKI = (data) => {
   let URL = `${url.ENDPOINT_KI_PENGAJUAN}/${data.id}`;
   const payload = data.payload;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "PUT"
   });
}

export const deletePengajuanKI = (data) => {
   let URL = `${url.ENDPOINT_KI_PENGAJUAN}/${data.id}`;

   return instancesKI.request({
      url: URL,
      method: "DELETE"
   });
}

export const savePenugasanReviewerKI = (payload) => {
   let URL = `${url.ENDPOINT_KI_PENUGASAN_REVIEWER}`;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "POST"
   });
}

export const updatePenugasanReviewerKI = (data) => {
   let URL = `${url.ENDPOINT_KI_PENUGASAN_REVIEWER}/${data.id}`;

   return instancesKI.request({
      url: URL,
      data: data.payload,
      method: "PUT"
   });
}

export const getListPenugasanReviewerKI = (
   {
      params = {},
      filter = []
   }
) => {
   let URL = `${url.ENDPOINT_KI_PENUGASAN_REVIEWER}`;
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
   }

   if (filter.length > 0) {
      if (queryParams) {
         queryParams += "&";
      } else {
         queryParams += "?";
      }
      filter.forEach((fil, i) => {
         if (fil.value) {
            queryParams += `${fil.field}=${fil.value}${i === filter.length - 1 ? '' : '&'}`;
         }
      });
   }
   URL += queryParams;


   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const getListKegiatanBimtekKI = (
  params = {}
) => {
   let URL = `${url.ENDPOINT_KI_KEGIATAN_BIMTEK}`;
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
   }
   URL += queryParams;


   return instancesKI.request({
      url: URL,
      method: "GET"
   });
}

export const createPlottingProposalKI = (payload) => {
   let URL = `${url.ENDPOINT_KI_REVIEWER}`;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "POST"
   });
}

export const updatePlottingProposalKI = (data) => {
   let URL = `${url.ENDPOINT_KI_REVIEWER}/${data.id}`;
   const payload = data.payload;

   return instancesKI.request({
      url: URL,
      data: payload,
      method: "PUT"
   });
}


export const downloadExcelPengajuanKI = ({params = {}, filter}) => {
   let URL = `${url.ENDPOINT_KI_PENGAJUAN}/download`;
   let queryParams = '';
   if (JSON.stringify(params) !== "{}") {
      queryParams += "?";
      Object.entries(params).forEach(([key, value], i) => {
         if (value) {
            queryParams += `${key}=${value}${i === Object.entries(params).length - 1 ? '' : '&'}`;
         }
      });
      if (filter.length > 0) {
         if (queryParams) {
            queryParams += "&";
         } else {
            queryParams += "?";
         }
         filter.forEach((fil, i) => {
            if (fil.value) {
               queryParams += `${fil.field}=${fil.value}${i === filter.length - 1 ? '' : '&'}`;
            }
         });
      }
      
      URL += queryParams;
   }
   
   return instancesReportKI.request({
      url: URL,
      method: "GET",
      headers: {
         "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
   });
}
