import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';

function ModalMain(props) {
    const { className, isModalOpen,
        toggle = () => { }, backdrop = true, title, children, shownBtnOk = false,
        shownBtnDeny = true,
        name,
        handleSubmit,
        labelOk = "",
        labelDeny = "",
        size = "lg", //sm, lg , xl
        unmountOnClose = true } = props;

    return (
        <Modal
            isOpen={isModalOpen}
            toggle={toggle}
            className={className}
            size={size}
            backdrop={backdrop}
            unmountOnClose={unmountOnClose}
        >
            <ModalHeader toggle={() => toggle(name)}>{title}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {(shownBtnOk || shownBtnDeny) && (
                <ModalFooter>
                    {shownBtnOk && (
                        <Button color="primary" onClick={handleSubmit}>
                            {labelOk || "OK"}
                        </Button>
                    )}
                    {shownBtnDeny && (
                        <Button color="secondary" onClick={() => toggle(name)}>
                            {labelDeny || "Kembali"}
                        </Button>
                    )}
                </ModalFooter>
            )}
        </Modal>

    );
}

ModalMain.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    isModalOpen: PropTypes.bool,
    toggle: PropTypes.func,
    title: PropTypes.string,
    shownBtnOk: PropTypes.bool,
    shownBtnDeny: PropTypes.bool,
    backdrop: PropTypes.bool,
    handleSubmit: PropTypes.func,
    labelOk: PropTypes.string,
    labelDeny: PropTypes.string,
    size: PropTypes.string,
    unmountOnClose: PropTypes.bool,
    name: PropTypes.string
};

export default ModalMain;