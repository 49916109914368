export const TITLE = "Usulan Kosabangsa";

export const DEFAULT_ACTIVE_TAB = 0;

export const TABS = [
  {
    key: 1,
    title: "Identitas Usulan",
  },
  {
    key: 2,
    title: "Teknologi dan Mitra",
  },
  {
    key: 3,
    title: "Subtansi Usulan",
  },
  {
    key: 4,
    title: "RAB",
  },
  {
    key: 5,
    title: "Konfirmasi Usulan",
  },
];

export const PROGRAM_OPTIONS = [
  {
    label: "Kosabangsa",
    value: "kosabangsa",
  },
];

export const DURATION_OPTIONS = [
  {
    label: "1 Tahun",
    value: 1,
  },
];

export const YEAR_OF_IMPLEMENT_OPTIONS = [
  {
    label: "2024",
    value: "2024",
  },
];

export const PROPOSAL_FOCUS_OPTIONS = [
  {
    label: "Bidang Fokus Utama Kosabangsa",
    value: "utama",
    ids: "23|24|25|26|27",
  },
  {
    label: "Bidang Fokus Riset dan Inovasi",
    value: "riset",
    ids: "3|7|6|2|9|1|11|5|8|4",
  },
];

export const KELOMPOK_RAB_KOSABANGSA = [
  {
    name: "Teknologi dan Inovasi",
    requirement: "minimal",
    percentage: 50,
    id: 22,
  },
  {
    name: "Biaya Upah dan Jasa",
    requirement: "maksimal",
    percentage: 5,
    id: 21,
  },
  {
    name: "Biaya Pelatihan",
    requirement: "maksimal",
    percentage: 10,
    id: 23,
  },
  {
    name: "Biaya Perjalanan",
    requirement: "maksimal",
    percentage: 20,
    id: 24,
  },
  {
    name: "Biaya Pendampingan",
    requirement: "maksimal",
    percentage: 10,
    id: 25,
  },
  {
    name: "Biaya Lainnya",
    requirement: "maksimal",
    percentage: 5,
    id: 26,
  },
];

export const GENERAL_LUARAN_KOSABANGSA_ID = [60, 60, 62, 63, 64, 61];
export const LUARAN_PENDAMPING_KOSABANGSA_ID = [71, 71, 72, 73];

export const KETUA_RULES = [
  {
    klaster: ["F", "G", "I"], // madya, pratama, binaan
    homebase_prodi: [1],
    min_sinta_scrore: 50,
    jafung: [1, 2, 3, 4, 5],
  },
  {
    klaster: ["F", "G", "I"], // madya, pratama, binaan
    homebase_prodi: [2],
    min_sinta_scrore: 25,
    jafung: [1, 2, 3, 4, 5],
  },
];
export const KETUA_PENDAMPING_RULES = [
  {
    klaster: ["D", "E"], // mandiri, utama
    homebase_prodi: [1],
    min_sinta_scrore: 150,
    pendidikan: [7, 10, 13],
    jafung: [2, 3, 4, 5],
    min_hki: 2, //for real is 2
    com_service: 2, //for real is 2
  },
  {
    klaster: ["D", "E"], // mandiri, utama
    homebase_prodi: [2],
    min_sinta_scrore: 100,
    pendidikan: [7, 10, 13],
    jafung: [2, 3, 4, 5],
    min_hki: 2, //for real is 2
    com_service: 2, //for real is 2
  },
];
export const ANGGOTA_PENDAMPING_RULES = [
  {
    klaster: ["D", "E"], // mandiri, utama
    homebase_prodi: [1],
    min_sinta_scrore: 150,
    pendidikan: [6, 7, 9, 10, 12, 13],
    jafung: [2, 3, 4, 5],
	com_service: 2, //for real is 2
  },
  {
    klaster: ["D", "E"], // mandiri, utama
    homebase_prodi: [2],
    min_sinta_scrore: 100,
    pendidikan: [6, 7, 9, 10, 12, 13],
    jafung: [2, 3, 4, 5],
	com_service: 2, //for real is 2
  },
];

export const DOCS_TEMPLATES = {
  TIM_PENDAMPING:
    "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Template%20Surat%20Pembentukan%20Tim%20Pendamping%20pada%20BIMA.docx",
  KEPEMILIKAN_KI:
    "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Contoh%20Tangkapan%20Layar%20DJKI%20atau%20Sertifikat%20Kepemilikan%20Teknologi%20dan%20Inovasi.docx",
  JARAK_LOKASI_MITRA:
    "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Contoh%20Tangkapan%20Layar%20Google%20Maps%20yang%20Menggambarkan%20Jarak%20Perguruan%20Tinggi%20Pelaksana%20ke%20Lokasi%20Mitra.docx",
  SURAT_PERNYATAAN_MITRA_SASARAN:
    "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Template%20Surat%20Pernyataan%20Kerja%20Sama%20Mitra%20Sasaran.docx",
  SHARING_DANA:
    "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Template%20Surat%20Pernyataan%20Kerja%20Sama%20Mitra%20Kerjasama%20dan%20Kesediaan%20Sharing%20Dana.docx",
  SURAT_PERNYATAAN_MITRA_PEMERINTAH:
    "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Template%20Surat%20Pernyataan%20Kerja%20Sama%20Mitra%20Pemerintah%20dan%20kesesuaian%20bidang%20permasalahan.docx",
  MOU: "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Contoh%20MOU%20Tim%20Pelaksana%20dan%20Tim%20Pendamping.docx",
  ORISINIL:
    "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Template%20Surat%20Pernyataan%20Orisinalitas%20Usulan.docx",
  SUBTANSI:
    "https://storage.googleapis.com/sipp-be-files/templates/kosabangsa-2024/Template%20Isian%20Substansi%20Usulan.docx",
};
