import { instances } from "../api_helper";
import * as url from "../url_helper";

const createPendafataranReviewer = (payload) => {
    return instances.request(`${url.ENDPOINT_REVIEWER_PENDAFTARAN}`, {
        method: "POST",
        data: payload,
    });
}

const getPendafataranReviewer = (payload = {}) => {

    const endpoint = payload?.personal_id ? `${url.ENDPOINT_REVIEWER_PENDAFTARAN}/personal/${payload.personal_id}` : url.ENDPOINT_REVIEWER_PENDAFTARAN 
    return instances.request(endpoint, {
        method: "GET",
        data: payload,
    });
}

const verificationPendafataranReviewer = (payload = {}) => {

    const endpoint =  `${url.ENDPOINT_REVIEWER_PENDAFTARAN}/${payload.personal_id}/${payload.type_reviewer}`
    return instances.request(endpoint, {
        method: "GET",
        data: payload,
    });
}


export {
    createPendafataranReviewer,
    getPendafataranReviewer,
    verificationPendafataranReviewer
}