import {
   CREATE_BATCH_KI_REQUEST,
   UPDATE_BATCH_KI_REQUEST,
   ACTION_BATCH_KI_REQUEST,
   ACTION_BATCH_KI_FAILURE,
   UPDATE_BATCH_KI_FAILURE,
   CREATE_BATCH_KI_FAILURE,
   UPDATE_BATCH_KI_SUCCESS,
   CREATE_BATCH_KI_SUCCESS,
   ACTION_BATCH_KI_SUCCESS,
   GET_LIST_BATCH_KI_REQUEST,
   GET_LIST_BATCH_KI_SUCCESS,
   GET_LIST_BATCH_KI_FAILURE,
   GET_DETAILS_BATCH_KI_REQUEST,
   GET_DETAILS_BATCH_KI_SUCCESS,
   GET_DETAILS_BATCH_KI_FAILURE,
   CLEAN_DETAILS_BATCH_KI,
   DELETE_BATCH_KI_REQUEST,
   DELETE_BATCH_KI_SUCCESS,
   DELETE_BATCH_KI_FAILURE,
   GET_LIST_WHITELIST_KI_REQUEST,
   GET_LIST_WHITELIST_KI_SUCCESS,
   GET_LIST_WHITELIST_KI_FAILURE,
   GET_DETAILS_WHITELIST_KI,
   CLEAN_DETAILS_WHITELIST_KI
} from "./actionTypes";

const initialState = {
   list_batch_ki: {
      items: [],
      isFetching: false,
      metaData: {
         "total_data": 0,
         "page": 0,
         "limit": 0,
         "prev_page": null,
         "next_page": 0,
         "last_page": 0
      }
   },
   list_whitelist_ki: {
      items: [],
      isFetching: false,
   },
   details_batch_ki: {
      item: null,
      isFetching: false,
   },
   details_whitelist_ki: {
      item: null,
   },
   isProcessAction: false
}

const konfigurasi = (state = initialState, action) => {

   switch (action.type) {
      case CLEAN_DETAILS_WHITELIST_KI:
         return (state = {
            ...state,
            details_whitelist_ki: {
               item: null
            },
         });
      case GET_DETAILS_WHITELIST_KI:
         return (state = {
            ...state,
            details_whitelist_ki: {
               item: state.list_whitelist_ki.items.find(item => item.id === action.payload) ?? null
            },
         });
      case GET_LIST_WHITELIST_KI_REQUEST:
         return (state = {
            ...state,
            list_whitelist_ki: {
               ...state.list_whitelist_ki,
               isFetching: true,
            },
         });
      case GET_LIST_WHITELIST_KI_SUCCESS:
         return (state = {
            ...state,
            list_whitelist_ki: {
               isFetching: false,
               items: action.payload.data,

            },
         });
      case GET_LIST_WHITELIST_KI_FAILURE:
         return (state = {
            ...state,
            list_whitelist_ki: {
               ...state.list_whitelist_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case CREATE_BATCH_KI_REQUEST:
      case UPDATE_BATCH_KI_REQUEST:
      case ACTION_BATCH_KI_REQUEST:
         return (state = {
            ...state,
            isProcessAction: true,
         });
      case CREATE_BATCH_KI_FAILURE:
      case UPDATE_BATCH_KI_FAILURE:
      case ACTION_BATCH_KI_FAILURE:
         return (state = {
            ...state,
            isProcessAction: false,
         });
      case UPDATE_BATCH_KI_SUCCESS:
      case CREATE_BATCH_KI_SUCCESS:
      case ACTION_BATCH_KI_SUCCESS:
         return (state = {
            ...state,
            details_batch_ki: {
               ...state.details_batch_ki,
               item: action.payload
            },
            isProcessAction: false,
         });
      case GET_LIST_BATCH_KI_REQUEST:
         return (state = {
            ...state,
            list_batch_ki: {
               ...state.list_batch_ki,
               isFetching: true,
            },
         });
      case GET_LIST_BATCH_KI_SUCCESS:
         return (state = {
            ...state,
            list_batch_ki: {
               isFetching: false,
               items: action.payload.data,
               metaData: action.payload.meta
            },
         });
      case GET_LIST_BATCH_KI_FAILURE:
         return (state = {
            ...state,
            list_batch_ki: {
               ...state.list_batch_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case GET_DETAILS_BATCH_KI_REQUEST:
         return (state = {
            ...state,
            details_batch_ki: {
               ...state.details_batch_ki,
               isFetching: true,
            },
         });
      case GET_DETAILS_BATCH_KI_SUCCESS:
         return (state = {
            ...state,
            details_batch_ki: {
               isFetching: false,
               item: action.payload
            },
         });
      case GET_DETAILS_BATCH_KI_FAILURE:
         return (state = {
            ...state,
            details_batch_ki: {
               ...state.details_batch_ki,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case CLEAN_DETAILS_BATCH_KI:
         return (state = {
            ...state,
            details_batch_ki: {
               isFetching: false,
               item: null
            },
         });

      case DELETE_BATCH_KI_REQUEST:
         return (state = {
            ...state,
            list_batch_ki: {
               ...state.list_batch_ki,
               isFetching: true,
            },
         });
      case DELETE_BATCH_KI_SUCCESS: {
         const id = action.payload.id;
         const copyListBatch = [...state.list_batch_ki.items];
         const index = copyListBatch.findIndex(row => row.id === id);
         if (index > -1) {
            copyListBatch.splice(index, 1);
         }
         return (state = {
            ...state,
            list_batch_ki: {
               isFetching: false,
               items: [...copyListBatch],
               metaData: {
                  ...state.list_batch_ki.metaData,
                  total_data: state.list_batch_ki.metaData.total_data - 1,
               }
            },
         });
      }
      case DELETE_BATCH_KI_FAILURE:
         return (state = {
            ...state,
            list_batch_ki: {
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      default:
         return state;
   }
}

export default konfigurasi;