import {
    CREATE_PENUGASAN_PROTOTIPE_FAILURE, CREATE_PENUGASAN_PROTOTIPE_REQUEST,
    CREATE_PENUGASAN_PROTOTIPE_SUCCESS, GET_BIDANG_FOKUS_REQUEST,
    GET_BIDANG_FOKUS_SUCCESS, GET_PENUGASAN_PROTOTIPE_BYID_FAILURE, GET_PENUGASAN_PROTOTIPE_BYID_REQUEST,
    GET_PENUGASAN_PROTOTIPE_BYID_SUCCESS, GET_PENUGASAN_PROTOTIPE_FAILURE, GET_PENUGASAN_PROTOTIPE_REQUEST,
    GET_PENUGASAN_PROTOTIPE_SUCCESS, UPDATE_PENUGASAN_PROTOTIPE_FAILURE, UPDATE_PENUGASAN_PROTOTIPE_REQUEST,
    UPDATE_PENUGASAN_PROTOTIPE_SUCCESS,
    VALIDATION_PENUGASAN_PROTOTIPE_FAILURE,
    VALIDATION_PENUGASAN_PROTOTIPE_REQUEST,
    VALIDATION_PENUGASAN_PROTOTIPE_SUCCESS
} from "../actionTypes";


export const createPenugasanPrototipe = (request = {}, cb = () => { }) => {
    return {
        type: CREATE_PENUGASAN_PROTOTIPE_REQUEST,
        payload: request,
        cb
    };
}

export const createPenugasanPrototipeSuccess = response => {
    return {
        type: CREATE_PENUGASAN_PROTOTIPE_SUCCESS,
        payload: response,
    };
}

export const createPenugasanPrototipeFailure = (message, response) => {
    return {
        type: CREATE_PENUGASAN_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanPrototipe = (request = {}) => {
    return {
        type: GET_PENUGASAN_PROTOTIPE_REQUEST,
        payload: request,
    };
}

export const getPenugasanPrototipeSuccess = response => {
    return {
        type: GET_PENUGASAN_PROTOTIPE_SUCCESS,
        payload: response,
    };
}

export const getPenugasanPrototipeFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanPrototipeById = (id, cb = () => { }) => {
    return {
        type: GET_PENUGASAN_PROTOTIPE_BYID_REQUEST,
        payload: { id },

        cb
    };
}

export const getPenugasanPrototipeByIdSuccess = response => {
    return {
        type: GET_PENUGASAN_PROTOTIPE_BYID_SUCCESS,
        payload: response,
    };
}

export const getPenugasanPrototipeByIdFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_PROTOTIPE_BYID_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getBidangFokus = (request = {}) => {
    return {
        type: GET_BIDANG_FOKUS_REQUEST,
        payload: request,
    };
}

export const getBidangFokusSuccess = response => {
    return {
        type: GET_BIDANG_FOKUS_SUCCESS,
        payload: response,
    };
}

export const updatePenugasanPrototipe = (request = {}, id, cb = () => { }) => {
    return {
        type: UPDATE_PENUGASAN_PROTOTIPE_REQUEST,
        payload: request,
        cb,
        id
    };
}

export const updatePenugasanPrototipeSuccess = response => {
    return {
        type: UPDATE_PENUGASAN_PROTOTIPE_SUCCESS,
        payload: response,
    };
}

export const updatePenugasanPrototipeFailure = (message, response) => {
    return {
        type: UPDATE_PENUGASAN_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const validationsPrototipeSuccess = (response) => {
    return {
        type: VALIDATION_PENUGASAN_PROTOTIPE_SUCCESS,
        payload: response,
    };
}

export const validationsPrototipeFailure = (message, response) => {
    return {
        type: VALIDATION_PENUGASAN_PROTOTIPE_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}
export const validationsPrototipe = (request = {}) => {
    return {
        type: VALIDATION_PENUGASAN_PROTOTIPE_REQUEST,
        payload: request,
    };
}