export const SEARCH_PROPOSAL_E_CATALOG_REQUEST =
  "SEARCH_PROPOSAL_E_CATALOG_REQUEST";
export const SEARCH_PROPOSAL_E_CATALOG_SUCCESS =
  "SEARCH_PROPOSAL_E_CATALOG_SUCCESS";
export const SEARCH_PROPOSAL_E_CATALOG_FAILURE =
  "SEARCH_PROPOSAL_E_CATALOG_FAILURE";

export const SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_REQUEST =
  "SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_REQUEST";
export const SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_SUCCESS =
  "SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_SUCCESS";
export const SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_FAILURE =
  "SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_FAILURE";

export const RESET_PROPOSAL_E_CATALOG = "RESET_PROPOSAL_E_CATALOG";
export const RESET_PROPOSAL_SPESIFIK_E_CATALOG =
  "RESET_PROPOSAL_SPESIFIK_E_CATALOG";

export const GET_REPORT_PROPOSAL_E_CATALOG_REQUEST =
  "GET_REPORT_PROPOSAL_E_CATALOG_REQUEST";
export const GET_REPORT_PROPOSAL_E_CATALOG_SUCCESS =
  "GET_REPORT_PROPOSAL_E_CATALOG_SUCCESS";
export const GET_REPORT_PROPOSAL_E_CATALOG_FAILURE =
  "GET_REPORT_PROPOSAL_E_CATALOG_FAILURE";

export const GET_DETAIL_PROPOSAL_E_CATALOG_REQUEST =
  "GET_DETAIL_PROPOSAL_E_CATALOG_REQUEST";
export const GET_DETAIL_PROPOSAL_E_CATALOG_SUCCESS =
  "GET_DETAIL_PROPOSAL_E_CATALOG_SUCCESS";
export const GET_DETAIL_PROPOSAL_E_CATALOG_FAILURE =
  "GET_DETAIL_PROPOSAL_E_CATALOG_FAILURE";

export const RESET_REPORT_PROPOSAL_E_CATALOG =
  "RESET_REPORT_PROPOSAL_E_CATALOG";

export const SEARCH_PERGURUAN_TINGGI_E_CATALOG_REQUEST =
  "SEARCH_PERGURUAN_TINGGI_E_CATALOG_REQUEST";
export const SEARCH_PERGURUAN_TINGGI_E_CATALOG_SUCCESS =
  "SEARCH_PERGURUAN_TINGGI_E_CATALOG_SUCCESS";
export const SEARCH_PERGURUAN_TINGGI_E_CATALOG_FAILURE =
  "SEARCH_PERGURUAN_TINGGI_E_CATALOG_FAILURE";

export const EXPORT_PERGURUAN_TINGGI_E_CATALOG_REQUEST =
  "EXPORT_PERGURUAN_TINGGI_E_CATALOG_REQUEST";
export const EXPORT_PERGURUAN_TINGGI_E_CATALOG_SUCCESS =
  "EXPORT_PERGURUAN_TINGGI_E_CATALOG_SUCCESS";
export const EXPORT_PERGURUAN_TINGGI_E_CATALOG_FAILURE =
  "EXPORT_PERGURUAN_TINGGI_E_CATALOG_FAILURE";

export const GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST =
  "GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST";
export const GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS =
  "GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS";
export const GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE =
  "GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE";

export const GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST =
  "GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST";
export const GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS =
  "GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS";
export const GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE =
  "GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE";

export const GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST =
  "GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST";
export const GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS =
  "GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS";
export const GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE =
  "GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE";

export const CREATE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST =
  "CREATE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST";
export const CREATE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS =
  "CREATE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS";
export const CREATE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE =
  "CREATE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE";

export const GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST =
  "GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST";
export const GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS =
  "GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS";
export const GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE =
  "GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE";

export const DELETE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST =
  "DELETE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST";
export const DELETE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS =
  "DELETE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS";
export const DELETE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE =
  "DELETE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE";

export const GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_REQUEST =
  "GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_REQUEST";
export const GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_SUCCESS =
  "GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_SUCCESS";
export const GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_FAILURE =
  "GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_FAILURE";

export const GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST =
  "GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST";
export const GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS =
  "GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS";
export const GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE =
  "GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE";

export const GET_DANA_PPM_PER_YEAR_E_CATALOG_REQUEST =
  "GET_DANA_PPM_PER_YEAR_E_CATALOG_REQUEST";
export const GET_DANA_PPM_PER_YEAR_E_CATALOG_SUCCESS =
  "GET_DANA_PPM_PER_YEAR_E_CATALOG_SUCCESS";
export const GET_DANA_PPM_PER_YEAR_E_CATALOG_FAILURE =
  "GET_DANA_PPM_PER_YEAR_E_CATALOG_FAILURE";

export const GET_REPORT_PPM_E_CATALOG_REQUEST =
  "GET_REPORT_PPM_E_CATALOG_REQUEST";
export const GET_REPORT_PPM_E_CATALOG_SUCCESS =
  "GET_REPORT_PPM_E_CATALOG_SUCCESS";
export const GET_REPORT_PPM_E_CATALOG_FAILURE =
  "GET_REPORT_PPM_E_CATALOG_FAILURE";

export const GET_JABATAN_DOSEN_E_CATALOG_REQUEST =
  "GET_JABATAN_DOSEN_E_CATALOG_REQUEST";
export const GET_JABATAN_DOSEN_E_CATALOG_SUCCESS =
  "GET_JABATAN_DOSEN_E_CATALOG_SUCCESS";
export const GET_JABATAN_DOSEN_E_CATALOG_FAILURE =
  "GET_JABATAN_DOSEN_E_CATALOG_FAILURE";

export const GET_LIST_DOSEN_E_CATALOG_REQUEST =
  "GET_LIST_DOSEN_E_CATALOG_REQUEST";
export const GET_LIST_DOSEN_E_CATALOG_SUCCESS =
  "GET_LIST_DOSEN_E_CATALOG_SUCCESS";
export const GET_LIST_DOSEN_E_CATALOG_FAILURE =
  "GET_LIST_DOSEN_E_CATALOG_FAILURE";

export const GET_REPORT_LIST_DOSEN_E_CATALOG_REQUEST =
  "GET_REPORT_LIST_DOSEN_E_CATALOG_REQUEST";
export const GET_REPORT_LIST_DOSEN_E_CATALOG_SUCCESS =
  "GET_REPORT_LIST_DOSEN_E_CATALOG_SUCCESS";
export const GET_REPORT_LIST_DOSEN_E_CATALOG_FAILURE =
  "GET_REPORT_LIST_DOSEN_E_CATALOG_FAILURE";

export const GET_LIST_JUMLAH_LUARAN_E_CATALOG_REQUEST =
  "GET_LIST_JUMLAH_LUARAN_E_CATALOG_REQUEST";
export const GET_LIST_JUMLAH_LUARAN_E_CATALOG_SUCCESS =
  "GET_LIST_JUMLAH_LUARAN_E_CATALOG_SUCCESS";
export const GET_LIST_JUMLAH_LUARAN_E_CATALOG_FAILURE =
  "GET_LIST_JUMLAH_LUARAN_E_CATALOG_FAILURE";

export const GET_LIST_BIDANG_FOKUS_E_CATALOG_REQUEST =
  "GET_LIST_BIDANG_FOKUS_E_CATALOG_REQUEST";
export const GET_LIST_BIDANG_FOKUS_E_CATALOG_SUCCESS =
  "GET_LIST_BIDANG_FOKUS_E_CATALOG_SUCCESS";
export const GET_LIST_BIDANG_FOKUS_E_CATALOG_FAILURE =
  "GET_LIST_BIDANG_FOKUS_E_CATALOG_FAILURE";

export const GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_REQUEST =
  "GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_REQUEST";
export const GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_SUCCESS =
  "GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_SUCCESS";
export const GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_FAILURE =
  "GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_FAILURE";

export const GET_TOTAL_KLASTER_E_CATALOG_REQUEST =
  "GET_TOTAL_KLASTER_E_CATALOG_REQUEST";
export const GET_TOTAL_KLASTER_E_CATALOG_SUCCESS =
  "GET_TOTAL_KLASTER_E_CATALOG_SUCCESS";
export const GET_TOTAL_KLASTER_E_CATALOG_FAILURE =
  "GET_TOTAL_KLASTER_E_CATALOG_FAILURE";

export const GET_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST =
  "GET_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST";
export const GET_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS =
  "GET_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS";
export const GET_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE =
  "GET_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE";

export const REPORT_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST =
  "REPORT_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST";
export const REPORT_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS =
  "REPORT_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS";
export const REPORT_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE =
  "REPORT_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE";

export const GET_LIST_BENCHMARK_PPM_E_CATALOG_REQUEST =
  "GET_LIST_BENCHMARK_PPM_E_CATALOG_REQUEST";
export const GET_LIST_BENCHMARK_PPM_E_CATALOG_SUCCESS =
  "GET_LIST_BENCHMARK_PPM_E_CATALOG_SUCCESS";
export const GET_LIST_BENCHMARK_PPM_E_CATALOG_FAILURE =
  "GET_LIST_BENCHMARK_PPM_E_CATALOG_FAILURE";

export const GET_COMPARE_BENCHMARK_E_CATALOG_REQUEST =
  "GET_COMPARE_BENCHMARK_E_CATALOG_REQUEST";
export const GET_COMPARE_BENCHMARK_E_CATALOG_SUCCESS =
  "GET_COMPARE_BENCHMARK_E_CATALOG_SUCCESS";
export const GET_COMPARE_BENCHMARK_E_CATALOG_FAILURE =
  "GET_COMPARE_BENCHMARK_E_CATALOG_FAILURE";

export const GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_REQUEST =
  "GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_REQUEST";
export const GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_SUCCESS =
  "GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_SUCCESS";
export const GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_FAILURE =
  "GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_FAILURE";

export const GET_LLDIKTI_MAPS_E_CATALOG_REQUEST =
  "GET_LLDIKTI_MAPS_E_CATALOG_REQUEST";
export const GET_LLDIKTI_MAPS_E_CATALOG_SUCCESS =
  "GET_LLDIKTI_MAPS_E_CATALOG_SUCCESS";
export const GET_LLDIKTI_MAPS_E_CATALOG_FAILURE =
  "GET_LLDIKTI_MAPS_E_CATALOG_FAILURE";

export const GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_REQUEST =
  "GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_REQUEST";
export const GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_SUCCESS =
  "GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_SUCCESS";
export const GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_FAILURE =
  "GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_FAILURE";
