import {
  CLEAN_USULAN_PERBAIKAN_V2,
  GET_DETAIL_USULAN_PERBAIKAN_V2_FAILURE,
  GET_DETAIL_USULAN_PERBAIKAN_V2_REQUEST,
  GET_DETAIL_USULAN_PERBAIKAN_V2_SUCCESS,
  GET_USULAN_PERBAIKAN_V2_FAILURE,
  GET_USULAN_PERBAIKAN_V2_REQUEST,
  GET_USULAN_PERBAIKAN_V2_SUCCESS,
  MERGING_INTO_USULAN_PERBAIKAN_V2_FAILURE,
  MERGING_INTO_USULAN_PERBAIKAN_V2_REQUEST,
  MERGING_INTO_USULAN_PERBAIKAN_V2_SUCCESS,
  UPDATE_ISIAN_ITEM_USULAN_PERBAIKAN,
  UPDATE_USULAN_PERBAIKAN_V2_FAILURE,
  UPDATE_USULAN_PERBAIKAN_V2_REQUEST,
  UPDATE_USULAN_PERBAIKAN_V2_SUCCESS,
  USULAN_PERBAIKAN_BYID_REQUEST,
  USULAN_PERBAIKAN_BYID_SUCCESS,
  USULAN_PERBAIKAN_FAILURE,
  USULAN_PERBAIKAN_REQUEST,
  USULAN_PERBAIKAN_SUBMIT_FAILURE,
  USULAN_PERBAIKAN_SUBMIT_REQUEST,
  USULAN_PERBAIKAN_SUBMIT_SUCCESS,
  USULAN_PERBAIKAN_SUCCESS
} from "./actionTypes";

export const getUsulanPerbaikanDetailV2 = (request, cb = () => { }) => {
  return {
    type: GET_DETAIL_USULAN_PERBAIKAN_V2_REQUEST,
    payload: request,
    cb
  };
};

export const getUsulanPerbaikanDetailV2Success = (response, item) => {
  return {
    type: GET_DETAIL_USULAN_PERBAIKAN_V2_SUCCESS,
    payload: { response, item },
  };
};

export const getUsulanPerbaikanDetailV2Failure = (message, response) => {
  return {
    type: GET_DETAIL_USULAN_PERBAIKAN_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const mergingIntoUsulanPerbaikanV2 = (items = []) => {
  return {
    type: MERGING_INTO_USULAN_PERBAIKAN_V2_REQUEST,
    payload: items
  };
};

export const mergingIntoUsulanSuccess = (items) => {
  return {
    type: MERGING_INTO_USULAN_PERBAIKAN_V2_SUCCESS,
    payload: { items },
  };
};

export const mergingIntoUsulanFailure = (message, response) => {
  return {
    type: MERGING_INTO_USULAN_PERBAIKAN_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getUsulanPerbaikanV2 = (request, cb = () => { }) => {
  return {
    type: GET_USULAN_PERBAIKAN_V2_REQUEST,
    payload: request,
    cb
  };
};

export const getUsulanPerbaikanV2Success = (response, items) => {
  return {
    type: GET_USULAN_PERBAIKAN_V2_SUCCESS,
    payload: { response, items },
  };
};

export const getUsulanPerbaikanV2Failure = (message, response) => {
  return {
    type: GET_USULAN_PERBAIKAN_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const cleanUsulanPerbaikanV2 = () => {
  return {
    type: CLEAN_USULAN_PERBAIKAN_V2,
  };
};

export const updateUsulanPerbaikanV2 = (request, cb = () => { }) => {
  return {
    type: UPDATE_USULAN_PERBAIKAN_V2_REQUEST,
    payload: request,
    cb
  };
}

export const updateUsulanPerbaikanV2Success = (response, item) => {
  return {
    type: UPDATE_USULAN_PERBAIKAN_V2_SUCCESS,
    payload: response,
    item
  };
}

export const updateUsulanPerbaikanV2Failure = (message, response) => {
  return {
    type: UPDATE_USULAN_PERBAIKAN_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
}

// Get
export const getUsulanPerbaikan = (request, cb = () => { }) => {
  return {
    type: request.id ? USULAN_PERBAIKAN_BYID_REQUEST : USULAN_PERBAIKAN_REQUEST,
    payload: request,
    cb
  };
};

export const getUsulanPerbaikanSuccess = (response, item) => {
  return {
    type: item ? USULAN_PERBAIKAN_BYID_SUCCESS : USULAN_PERBAIKAN_SUCCESS,
    payload: response,
    item
  };
};

export const getUsulanPerbaikanFailure = (message, response, id = null) => {
  return {
    type: id ? USULAN_PERBAIKAN_REQUEST : USULAN_PERBAIKAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Post
export const submitUsulanPerbaikan = (request, history) => {
  return {
    type: USULAN_PERBAIKAN_SUBMIT_REQUEST,
    payload: request,
    history
  };
};

export const submitUsulanPerbaikanSuccess = (response, item) => {
  return {
    type: USULAN_PERBAIKAN_SUBMIT_SUCCESS,
    payload: response,
    item
  };
};

export const submitUsulanPerbaikanFailure = (message, response) => {
  return {
    type: USULAN_PERBAIKAN_SUBMIT_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const updateIsianItem = (response) => {
  return {
    type: UPDATE_ISIAN_ITEM_USULAN_PERBAIKAN,
    payload: response
  };
};