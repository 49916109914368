// /* eslint-disable no-unused-vars */
import headers from "./headers";
import stylePDF from "./styles";
import utilities from "helpers/utilities";
const buildTblPengusul = (identitasPengusul = []) => {
  const layout = {
    layout: {
      hLineWidth: (_i, _node) => 0.2,
      vLineWidth: _i => 0.2,
      padding: () => 0,
    },
    table: {
      widths: ["15%", "15%", "15%", "25%", "15%", "15%"],
      body: [
        [
          {
            text: `Nama, Peran`,
            style: ["center"],
          },
          {
            text: `Perguruan Tinggi`,
            style: ["center"],
          },
          {
            text: `Program Studi `,
            style: ["center"],
          },
          {
            text: `Bidang Tugas`,
            style: ["center"],
          },
          {
            text: `Rumpun Ilmu lv 2`,
            style: ["center"],
          },
          {
            text: `ID Sinta`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  identitasPengusul
    .sort((a, b) => {
      if (a.kode_peran === "A") return -1;
      if (b.kode_peran === "A") return 1;
      return 0;
    })
    .forEach(item => {
      layout.table.body.push([
        {
          text: `${item.nama} \n\n ${item.nama_peran}`,
          style: ["center"],
        },
        {
          text: `${item?.nama_institusi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.nama_program_studi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.tugas || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.rumpun_ilmu_2 || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.id_sinta || "-"}`,
          link: `${
            item?.id_sinta
              ? `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}`
              : "#"
          }`,
          style: ["link", "center"],
        },
      ]);
    });

  return layout;
};
const buildTblDaftarCalonPendamping = (data = []) => {
  const layout = {
    layout: {
      hLineWidth: (_i, _node) => 0.2,
      vLineWidth: _i => 0.2,
      padding: () => 0,
    },
    table: {
      widths: ["50%", "50%"],
      body: [
        [
          {
            text: `Nama Teknologi dan Inovasi`,
            style: ["center"],
          },
          {
            text: `Perguruan Tinggi`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  data.forEach(item => {
    layout.table.body.push([
      {
        text: `${item.raw.nama_teknologi}`,
        style: ["center"],
      },
      {
        text: `${
          item?.anggota?.find(item => item.kode_peran === "A")
            ?.nama_institusi || "-"
        }`,
        style: ["center"],
      },
    ]);
  });

  return layout;
};
const buildTblIdentitas = (data = {}) => {
  const layout = {
    style: ["center", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["50%", "25%", "25%"],
      body: [
        [
          {
            text: `Bidang Fokus`,
            style: ["center"],
          },
          {
            text: `Lama Kegiatan`,
            style: ["center"],
          },
          {
            text: `Tahun Usulan`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: data.nama_bidang_fokus || "-",
      style: ["center"],
    },
    {
      text: data.lama_kegiatan || "-",
      style: ["center"],
    },
    {
      text: data.tahun_usulan || "-",
      style: ["center"],
    },
  ]);

  return layout;
};
const buildTblMou = (data = {}) => {
  const layout = {
    style: ["center", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["20%", "20%", "20%", "20%", "20%"],
      body: [
        [
          {
            text: `Nama calon PT pendamping`,
            style: ["center"],
          },
          {
            text: `Nama calon ketua pendamping`,
            style: ["center"],
          },
          {
            text: `Pimpinan yang menetapkan`,
            style: ["center"],
          },
          {
            text: `Tanggal penetapan`,
            style: ["center"],
          },
          {
            text: `Jangka Waktu`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  if (data.is_mou) {
    layout.table.body.push([
      {
        text: data.pt_pendamping,
      },
      {
        text: data.ketua_pendamping,
      },
      {
        text: data.nama_pimpinan_penetap,
      },
      {
        text: data.tgl_penetapan
          ? utilities.convertDate(data.tgl_penetapan, "DD/MM/YYYY")
          : "-",
      },
      {
        text: data.jangka_waktu + "  Tahun",
      },
    ]);
  } else {
    return {};
  }

  return layout;
};
const buildTblDocMou = (data = {}) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0,
      vLineWidth: _i => 0,
      padding: () => 1,
    },
    table: {
      widths: [48, "*"],
      body: [
        [
          {
            text: "Dokumen :",
          },
          {
            text: data?.url_file_dokumen
              ? utilities.getFriendlyURL(data?.name_file_dokumen)
              : "-",
            link: data?.url_file_dokumen
              ? utilities.getFriendlyURL(data?.url_file_dokumen)
              : "#",
            style: ["link"],
          },
        ],
      ],
    },
  };

  if (!data.is_mou) {
    return {
      text: "Tidak",
      style: ["bold"],
    };
  }
  return layout;
};
const buildTblDeskripsiLokasiSasaranProgram = (data = {}) => {
  const layout = {
    style: ["center", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["100%"],
      body: [
        [
          {
            text: `Uraian Singkat`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: data.raw.program_sasaran.uraian || "-",
      style: ["left"],
    },
  ]);

  return layout;
};
const buildTblDeskripsiMiraSasaran = (data = []) => {
  const layout = {
    style: ["center", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["20%", "20%", "20%", "20%", "20%"],
      body: [
        [
          {
            text: `Nama Mitra`,
            style: ["center"],
          },
          {
            text: `Pimpinan Mitra`,
            style: ["center"],
          },
          {
            text: `Alamat`,
            style: ["center"],
          },
          {
            text: `Provinsi`,
            style: ["center"],
          },
          {
            text: `Kabupaten`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  data.forEach(item => {
    layout.table.body.push([
      {
        text: item.nama_mitra || "-",
        style: ["center"],
      },
      {
        text: item.pimpinan_mitra || "-",
        style: ["center"],
      },
      {
        text: item.alamat_mitra || "-",
        style: ["center"],
      },
      {
        text: item.nama_provinsi || "-",
        style: ["center"],
      },
      {
        text: item.nama_kabupaten || "-",
        style: ["center"],
      },
    ]);
  });

  return layout;
};
const buildTblDeskripsiSolusiYangDitawarkan = (data = {}) => {
  const layout = {
    style: ["center", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["100%"],
      body: [
        [
          {
            text: `Uraian Singkat`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: data.raw.solusi_ditawarkan.uraian || "-",
      style: ["left"],
    },
  ]);

  return layout;
};
export const Content = data => {
  const { hr } = stylePDF;
  const {
    raw: { mou, is_mou },
  } = data;
  return [
    headers(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI PROPOSAL",
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi kosabangsa pelaksana",
          style: ["fontSizeXs"],
        },
        {
          text: `KOSABANGSA PELAKSANA ${data.raw?.tahun_usulan}`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `ID Proposal: ${data.id}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    {
      stack: [
        {
          text: "1. Nama Teknologi dan Inovasi ",
          style: ["bold", "mb-5"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: _i => 0.5,
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: data.raw.nama_teknologi,
                },
              ],
            ],
          },
        },

        buildTblIdentitas(data.raw),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "2. Identitas tim pelaksana",
          style: ["bold", "mb-5"],
        },
        buildTblPengusul(
          data.anggota.filter(
            row => row.kode_program_pendidikan?.toString() !== "2"
          )
        ),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "3. Identitas tim pelaksana tambahan (vokasi)",
          style: ["bold", "mb-5"],
        },
        buildTblPengusul(
          data.anggota.filter(
            row => row.kode_program_pendidikan?.toString() === "2"
          )
        ),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "4. Deskripsi Lokasi Sasaran Program",
          style: ["bold", "mb-5"],
        },
        buildTblDeskripsiLokasiSasaranProgram(data),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "5. Deskripsi Mitra Sasaran",
          style: ["bold", "mb-5"],
        },
        buildTblDeskripsiMiraSasaran(data?.raw?.mitra_sasaran || []),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "6. Deskripsi solusi yang ditawarkan",
          style: ["bold", "mb-5"],
        },
        buildTblDeskripsiSolusiYangDitawarkan(data),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "7. MoU/Kerja sama dengan calon pendamping Program Kosabangsa",
          style: ["bold"],
        },
        buildTblDocMou({ ...mou, is_mou }),
        buildTblMou({ ...mou, is_mou }),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "8. Daftar calon pendamping yang dipilih",
          style: ["bold", "mb-5"],
        },
        buildTblDaftarCalonPendamping(data.raw.list_pendamping_terpilih),
      ],
      style: ["mb-10"],
    },
  ];
};
export const contentPersetujuan = (data = {}) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*", "*"],
      body: [
        [
          {
            text: `Tanggal Pengiriman`,
            style: ["center"],
          },
          {
            text: `Tanggal Persetujuan`,
            style: ["center"],
          },
          {
            text: `Nama Pimpinan Pemberi Persetujuan`,
            style: ["center"],
          },
          {
            text: `Sebutan Jabatan Unit`,
            style: ["center"],
          },
          {
            text: `Nama Unit Lembaga Pengusul`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  layout.table.body.push([
    {
      text: `${
        data?.tanggal_pengiriman
          ? utilities.convertDate(data?.tanggal_pengiriman, "DD/MM/YYYY")
          : "-"
      }`,
      style: ["center"],
    },
    {
      text: `${
        data?.tgl_created
          ? utilities.convertDate(data?.tgl_created, "DD/MM/YYYY")
          : "-"
      }`,
      style: ["center"],
    },
    {
      text: `${data?.approval_by || "-"}`,
      style: ["center"],
    },
    {
      text: `${data?.approval_peran || "-"}`,
      style: ["center"],
    },
    {
      text: `${data?.lembaga?.nama_lembaga || "-"}`,
      style: ["center"],
    },
  ]);

  return [
    {
      stack: [
        {
          text: "PERSETUJUAN PENGUSUL",
          style: ["bold"],
        },
        {
          ...layout,
        },
      ],
    },
    {
      style: ["mt-10"],
      stack: [
        {
          text: `Komentar : ${
            data?.kd_sts_approvel?.toString() === "0"
              ? "Tidak Disetujui"
              : data?.kd_sts_approvel?.toString() === "1"
              ? "Disetujui"
              : "-"
          } `,
          style: ["bold", "mb-1"],
        },
        {
          style: ["text"],
          layout: {
            hLineWidth: (_i, _node) => (data?.komentar ? 0.3 : 0),
            vLineWidth: _i => (data?.komentar ? 0.3 : 0),
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: data?.komentar,
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
