import { toast } from "react-toastify";
const handleToast = (idToast, err, message = "") => {
  if (!err) {
    toast.update(idToast, {
      render: message,
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
  } else {
    toast.update(idToast, {
      render: message,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};
export default handleToast;
