import {
  KOSABANGSA_DOSEN_REQUEST,
  KOSABANGSA_DOSEN_SUCCESS,
  KOSABANGSA_DOSEN_FAILURE,
  KOSABANGSA_BIDANG_FOKUS_PRIORITAS_SUCCESS,
  KOSABANGSA_BIDANG_FOKUS_PRIORITAS_FAILURE,
  KOSABANGSA_BIDANG_FOKUS_PRIORITAS_REQUEST,
  KOSABANGSA_BIDANG_FOKUS_LAINNYA_REQUEST,
  KOSABANGSA_BIDANG_FOKUS_LAINNYA_SUCCESS,
  KOSABANGSA_BIDANG_FOKUS_LAINNYA_FAILURE,
  KOSABANGSA_GET_PROVINSI_REQUEST,
  KOSABANGSA_GET_PROVINSI_SUCCESS,
  KOSABANGSA_GET_PROVINSI_FAILURE,
  KOSABANGSA_KABUPATEN_KOTA_REQUEST,
  KOSABANGSA_KABUPATEN_KOTA_SUCCESS,
  KOSABANGSA_KABUPATEN_KOTA_FAILURE,
  KOSABANGSA_KELOMPOK_MITRA_REQUEST,
  KOSABANGSA_KELOMPOK_MITRA_SUCCESS,
  KOSABANGSA_KELOMPOK_MITRA_FAILURE,
  KOSABANGSA_JENIS_PIMPINAN_PENETAP_REQUEST,
  KOSABANGSA_JENIS_PIMPINAN_PENETAP_SUCCESS,
  KOSABANGSA_JENIS_PIMPINAN_PENETAP_FAILURE,
  KOSABANGSA_UPLOAD_FILE_REQUEST,
  KOSABANGSA_UPLOAD_FILE_FAILURE,
  KOSABANGSA_UPLOAD_FILE_SUCCESS,
  KOSABANGSA_SAVE_REQUEST,
  KOSABANGSA_SAVE_SUCCESS,
  KOSABANGSA_SAVE_FAILURE,
  KOSABANGSA_PENDAMPING_BYID_REQUEST,
  KOSABANGSA_PENDAMPING_BYID_SUCCESS,
  KOSABANGSA_PENDAMPING_BYID_FAILURE,
  KOSABANGSA_LIST_PELAKSANA_FAILURE,
  KOSABANGSA_LIST_PELAKSANA_SUCCESS,
  KOSABANGSA_LIST_PELAKSANA_REQUEST,
  KOSABANGSA_PELAKSANA_BYID_REQUEST,
  KOSABANGSA_PELAKSANA_BYID_SUCCESS,
  KOSABANGSA_PELAKSANA_BYID_FAILURE,
  KOSABANGSA_PUT_REQUEST,
  KOSABANGSA_PUT_SUCCESS,
  KOSABANGSA_PUT_FAILURE,
  KOSABANGSA_DELETE_REQUEST,
  KOSABANGSA_DELETE_SUCCESS,
  KOSABANGSA_DELETE_FAILURE,
  RESET_KOSABANGSA_PELAKSANA_BY_ID,
  KOSABANGSA_PELAKSANA_LIST_PENDAMPING_REQUEST,
  KOSABANGSA_PELAKSANA_LIST_PENDAMPING_SUCCESS,
  KOSABANGSA_PELAKSANA_LIST_PENDAMPING_FAILURE,
  KOSABANGSA_LIST_PENDAMPING_REQUEST,
  KOSABANGSA_LIST_PENDAMPING_SUCCESS,
  KOSABANGSA_LIST_PENDAMPING_FAILURE,
  RESET_KOSABANGSA_PELAKSANA_GET_PENDAMPING_BY_ID,
  GET_ELIGIBILITY_KOSABANGSA_REQUEST,
  GET_ELIGIBILITY_KOSABANGSA_SUCCESS,
  GET_ELIGIBILITY_KOSABANGSA_FAILURE,
  GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_REQUEST,
  GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_SUCCESS,
  GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_FAILURE,
  CLEAN_ELIGIBILITY_ANGGOTA_KOSABANGSA,
  APPROVAL_OP_PUSAT_KOSBANGSA_REQUEST,
  APPROVAL_OP_PUSAT_KOSBANGSA_FAILURE,
  APPROVAL_OP_PUSAT_KOSBANGSA_SUCCESS,
  GET_KOSABANGSA_MATCHED_REQUEST,
  GET_KOSABANGSA_MATCHED_FAILURE,
  GET_KOSABANGSA_MATCHED_SUCCESS,
} from "./actionTypes";

export const getKosabangsaMatched = (cb = () => { }) => {
  return {
    type: GET_KOSABANGSA_MATCHED_REQUEST,
    cb,
  };
};

export const getKosabangsaMatchedSuccess = (response = {}) => {
  return {
    type: GET_KOSABANGSA_MATCHED_SUCCESS,
    payload: response,
  };
};

export const getKosabangsaMatchedFailure = (message, response) => {
  return {
    type: GET_KOSABANGSA_MATCHED_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const cleanEligibilityKosabangsa = () => {
  return {
    type: CLEAN_ELIGIBILITY_ANGGOTA_KOSABANGSA,
  };
};

export const getEligibilityKosabangsa = (request = {}, cb = () => { }) => {
  return {
    type: request.anggota
      ? GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_REQUEST
      : GET_ELIGIBILITY_KOSABANGSA_REQUEST,
    payload: { ...request },
    cb,
  };
};

export const getEligibilityKosabangsaSuccess = (response = {}) => {
  return {
    type: response.is_anggota
      ? GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_SUCCESS
      : GET_ELIGIBILITY_KOSABANGSA_SUCCESS,
    payload: response, 
  };
};

export const getEligibilityKosabangsaFailure = (message, response) => {
  return {
    type: response.is_anggota
      ? GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_FAILURE
      : GET_ELIGIBILITY_KOSABANGSA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getKosabangsaPendampingById = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_PENDAMPING_BYID_REQUEST,
    payload: { ...request },
    cb,
  };
};

export const getKosabangsaPendampingByIdSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_PENDAMPING_BYID_SUCCESS,
    payload: response,
  };
};

export const getKosabangsaPendampingByIdFailure = (message, response) => {
  return {
    type: KOSABANGSA_PENDAMPING_BYID_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const saveKosabangsa = (request = {}, type, cb = () => { }) => {
  return {
    type: KOSABANGSA_SAVE_REQUEST,
    payload: { ...request, type },
    cb,
  };
};

export const saveKosabangsaSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_SAVE_SUCCESS,
    payload: response,
  };
};

export const saveKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_SAVE_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const putKosabangsa = (request = {}, type, id, cb = () => { }) => {
  return {
    type: KOSABANGSA_PUT_REQUEST,
    payload: { ...request, type, id },
    cb,
  };
};

export const putKosabangsaSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_PUT_SUCCESS,
    payload: response,
  };
};

export const putKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_PUT_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const deleteKosabangsa = (request = {}, type, id, cb = () => { }) => {
  return {
    type: KOSABANGSA_DELETE_REQUEST,
    payload: { ...request, type, id },
    cb,
  };
};

export const deleteKosabangsaSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_DELETE_SUCCESS,
    payload: response,
  };
};

export const deleteKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_DELETE_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const uploadDokumenKosabangsa = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_UPLOAD_FILE_REQUEST,
    payload: request,
    cb,
  };
};

export const uploadDokumenKosabangsaSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_UPLOAD_FILE_SUCCESS,
    payload: response,
  };
};

export const uploadDokumenKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_UPLOAD_FILE_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getJenisPimpinanPenetap = (request = {}) => {
  return {
    type: KOSABANGSA_JENIS_PIMPINAN_PENETAP_REQUEST,
    payload: request,
  };
};

export const getJenisPimpinanPenetapSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_JENIS_PIMPINAN_PENETAP_SUCCESS,
    payload: response,
  };
};

export const getJenisPimpinanPenetapFailure = (message, response) => {
  return {
    type: KOSABANGSA_JENIS_PIMPINAN_PENETAP_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Get Dosen
export const getDosenKosabangsa = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_DOSEN_REQUEST,
    payload: request,
    cb,
  };
};

export const getDosenKosabangsaSuccess = response => {
  return {
    type: KOSABANGSA_DOSEN_SUCCESS,
    payload: response,
  };
};

export const getDosenKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_DOSEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getBidangFokusPrioritasKosabangsa = (
  request = {},
  cb = () => { }
) => {
  return {
    type: KOSABANGSA_BIDANG_FOKUS_PRIORITAS_REQUEST,
    payload: request,
    cb,
  };
};

export const getBidangFokusPrioritasKosabangsaSuccess = response => {
  return {
    type: KOSABANGSA_BIDANG_FOKUS_PRIORITAS_SUCCESS,
    payload: response,
  };
};

export const getBidangFokusPrioritasKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_BIDANG_FOKUS_PRIORITAS_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getBidangFokusLainnyaKosabangsa = (
  request = {},
  cb = () => { }
) => {
  return {
    type: KOSABANGSA_BIDANG_FOKUS_LAINNYA_REQUEST,
    payload: request,
    cb,
  };
};

export const getBidangFokusLainnyaKosabangsaSuccess = response => {
  return {
    type: KOSABANGSA_BIDANG_FOKUS_LAINNYA_SUCCESS,
    payload: response,
  };
};

export const getBidangFokusLainnyaKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_BIDANG_FOKUS_LAINNYA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getProvinsiKosabangsa = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_GET_PROVINSI_REQUEST,
    payload: request,
    cb,
  };
};

export const getProvinsiKosabangsaSuccess = response => {
  return {
    type: KOSABANGSA_GET_PROVINSI_SUCCESS,
    payload: response,
  };
};

export const getProvinsiKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_GET_PROVINSI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getKabupatenKotaKosabangsa = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_KABUPATEN_KOTA_REQUEST,
    payload: request,
    cb,
  };
};

export const getKabupatenKotaKosabangsaSuccess = response => {
  return {
    type: KOSABANGSA_KABUPATEN_KOTA_SUCCESS,
    payload: response,
  };
};

export const getKabupatenKotaKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_KABUPATEN_KOTA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getKelompokMitraKosabangsa = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_KELOMPOK_MITRA_REQUEST,
    payload: request,
    cb,
  };
};

export const getKelompokMitraKosabangsaSuccess = response => {
  return {
    type: KOSABANGSA_KELOMPOK_MITRA_SUCCESS,
    payload: response,
  };
};

export const getKelompokMitraKosabangsaFailure = (message, response) => {
  return {
    type: KOSABANGSA_KELOMPOK_MITRA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getKosabangsaListPendamping = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_LIST_PENDAMPING_REQUEST,
    payload: { ...request, type: "pendamping" },
    cb,
  };
};

export const getKosabangsaListPendampingSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_LIST_PENDAMPING_SUCCESS,
    payload: response,
  };
};

export const getKosabangsaListPendampingFailure = (message, response) => {
  return {
    type: KOSABANGSA_LIST_PENDAMPING_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getKosabangsaListPelaksana = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_LIST_PELAKSANA_REQUEST,
    payload: { ...request },
    cb,
  };
};

export const getKosabangsaListPelaksanaSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_LIST_PELAKSANA_SUCCESS,
    payload: response,
  };
};

export const getKosabangsaListPelaksanaFailure = (message, response) => {
  return {
    type: KOSABANGSA_LIST_PELAKSANA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const getKosabangsaPelaksanaById = (request = {}, cb = () => { }) => {
  return {
    type: KOSABANGSA_PELAKSANA_BYID_REQUEST,
    payload: { ...request },
    cb,
  };
};
export const getKosabangsaPelaksanaByIdSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_PELAKSANA_BYID_SUCCESS,
    payload: response,
  };
};

export const getKosabangsaPelaksanaByIdFailure = (message, response) => {
  return {
    type: KOSABANGSA_PELAKSANA_BYID_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const getKosabangsaPelaksanaListPendamping = (
  request = {},
  cb = () => { }
) => {
  return {
    type: KOSABANGSA_PELAKSANA_LIST_PENDAMPING_REQUEST,
    payload: request,
    cb,
  };
};
export const getKosabangsaPelaksanaListPendampingSuccess = (response = {}) => {
  return {
    type: KOSABANGSA_PELAKSANA_LIST_PENDAMPING_SUCCESS,
    payload: response,
  };
};

export const getKosabangsaPelaksanaListPendampingFailure = (
  message,
  response
) => {
  return {
    type: KOSABANGSA_PELAKSANA_LIST_PENDAMPING_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const resetKosabangsaPelaksanaById = () => {
  return {
    type: RESET_KOSABANGSA_PELAKSANA_BY_ID,
  };
};

export const resetKosabangsaPelaksanaGetPendampingById = () => {
  return {
    type: RESET_KOSABANGSA_PELAKSANA_GET_PENDAMPING_BY_ID,
  };
};

export const approvalOPPusatKosabangsa = (request = {}, cb = () => { }) => {
  return {
    type: APPROVAL_OP_PUSAT_KOSBANGSA_REQUEST,
    payload: { ...request },
    cb,
  };
};

export const approvalOPPusatKosabangsaSuccess = (response = {}) => {
  return {
    type: APPROVAL_OP_PUSAT_KOSBANGSA_SUCCESS,
    payload: { ...response },
  };
};

export const approvalOPPusatKosabangsaFailure = (response = {}) => {
  return {
    type: APPROVAL_OP_PUSAT_KOSBANGSA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
