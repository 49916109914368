import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import {
   getKomponenPenilaianLuaranFailure,
   getKomponenPenilaianLuaranSuccess,
   saveKomponenPenilaianLuaranFailure,
   saveKomponenPenilaianLuaranSuccess
} from "./action";
import { GET_KOMPONEN_PENILAIAN_LUARAN_REQUEST, SAVE_KOMPONEN_PENILAIAN_LUARAN_REQUEST } from "./actionTypes";

function* getKomponenPenilaiLuaran({ payload }) {
   try {
      const response = yield call(backend.getKomponenPenilaianLuaran, payload);
      if (response.data.code == 200) {


         yield put(
            getKomponenPenilaianLuaranSuccess({
               data: response.data.data,
            })
         );
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      toast.error(message);
      yield put(getKomponenPenilaianLuaranFailure(message, error));
   }
}

function* saveKomponenPenilaiLuaran({ payload, cb }) {
   try {
      const response = yield call(backend.saveKomponenPenilaianLuaran, payload);
      if (response.data.code == 200) {
         yield put(
            saveKomponenPenilaianLuaranSuccess({
               data: response.data.data,
            })
         );
         cb(response.data.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      cb(null);
      toast.error(message);
      yield put(saveKomponenPenilaianLuaranFailure(message, error));
   }
}

function* evaluasi() {
   yield takeEvery(
      GET_KOMPONEN_PENILAIAN_LUARAN_REQUEST,
      getKomponenPenilaiLuaran
   );
   yield takeEvery(
      SAVE_KOMPONEN_PENILAIAN_LUARAN_REQUEST,
      saveKomponenPenilaiLuaran
   );
}

export default evaluasi;