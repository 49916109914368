/* eslint-disable no-unused-vars */
import handleToast from "components/handleToast/handleToast";
import { instances } from "../api_helper";
import LaporanAkhirPDF from "helpers/workers/pdf/laporanAkhir";
import {
  getAllFilesLuaranLaporanAkhirV1,
  getDetailLaporanAkhir,
  getLaporanAkhirLuaranAktual,
} from "helpers/laporanAkhir";
import { toast } from "react-toastify";
import handleToastWarning from "components/handleToast/handleToastWarning";
export const getListInstitusiPenilaianLuaran = ({
  _tahun_usulan,
  tahun_pelaksanaan,
}) => {
  return instances.request(
    `/evaluasi/penilaian-luaran/institusi?thn_pelaksanaan=${tahun_pelaksanaan}`,
    {
      method: "GET",
    }
  );
};
export const getListInstitusiPenilaianLuaranDetail1 = ({
  _tahun_usulan,
  tahun_pelaksanaan,
  id_skema,
  id_institusi,
}) => {
  return instances.request(
    `/evaluasi/penilaian-luaran/usulan?thn_pelaksanaan=${tahun_pelaksanaan}&id_skema=${id_skema}&id_institusi=${id_institusi}`,
    {
      method: "GET",
    }
  );
};
export const getListInstitusiPenilaianLuaranDetail2 = ({
  id_usulan_kegiatan,
}) => {
  return instances.request(
    `/evaluasi/penilaian-luaran/komponen?id_usulan_kegiatan=${id_usulan_kegiatan}`,
    {
      method: "GET",
    }
  );
};

export const savePenilaianLuaranEvaluasi = payload => {
  return instances.request(`/evaluasi/penilaian-luaran/usulan`, {
    method: "POST",
    data: payload,
  });
};
export const savePenilaianLuaranToPermanen = payload => {
  return instances.request(`/evaluasi/penilaian-luaran/set-permanen`, {
    method: "POST",
    data: payload,
  });
};
export const downloadPDFPenilaianLuaranPenelitian = async (
  data,
  blobUsulanPerbaikan,
  idToast,
  jenisUsulan
) => {
  const id = data.id_proposal;
  const resLapAkhir = await getDetailLaporanAkhir({
    id_usulan_kegiatan: id,
    jenis: jenisUsulan,
  });
  if (resLapAkhir.data.code === 200) {
    const isAktual = true;
    const list_id_luaran_dijanjikan = resLapAkhir.data.data.luaran.map(
      item => item.id_luaran_dijanjikan
    );
    const resLuaranAktual = await getLaporanAkhirLuaranAktual({
      list_id_luaran_dijanjikan: list_id_luaran_dijanjikan,
    });
    const newitems = {
      data: resLapAkhir.data.data,
      data_aktualisasi: resLuaranAktual?.data?.data || [],
    };
    const filesLuaran = await getAllFilesLuaranLaporanAkhirV1(
      {
        luaran: newitems.data.luaran,
        luaran_aktualisasi: newitems.data_aktualisasi || [],
      },
      jenisUsulan,
      isAktual
    );

    const laporanAkhirPdf = new LaporanAkhirPDF(data);
    const blobFileFromClient = await laporanAkhirPdf.generate();
    const blobFileFromServer = await laporanAkhirPdf.getMultiplefile(
      {
        data1: {
          url_file_substansi: data.url_file_substansi,
        },
        data2: {
          id_usulan_kegiatan: id,
        },
      },
      filesLuaran
    );
    const contentMerge = [
      blobUsulanPerbaikan,
      blobFileFromClient,
      ...blobFileFromServer,
    ];
    try {
      // * Start Merger
      const mergingFun = () => {
        laporanAkhirPdf.mergingv3(contentMerge, result => {
          const resultMerged = result.res;
          if (result.error === false) {
            handleToast(idToast, false, "Success");
            laporanAkhirPdf.forceDownload(resultMerged, data.title + ".pdf");
          } else {
            mergingFun();
            handleToastWarning(idToast, "Beberapa file tidak dapat di gabung!");
            laporanAkhirPdf.forceDownload(
              contentMerge[result.indexBlobSkipped],
              `file_${result.indexBlobSkipped - 1}.pdf`
            );
          }
        });
      };
      mergingFun();
      // const mergedPdf = await laporanAkhirPdf.merging([
      //   blobUsulanPerbaikan,
      //   blobFileFromClient,
      //   ...blobFileFromServer,
      // ]);
      // // * Force Download
      // laporanAkhirPdf.forceDownload(mergedPdf, data.title);
      // handleToast(idToast, false, "Success");
    } catch (err) {
      handleToast(idToast, true, "Failed");
    }
  } else {
    handleToast(idToast, true, "Failed");
  }
};
