import {
	GET_SKEMA_SITE_VISIT_FAILURE,
	GET_SKEMA_SITE_VISIT_REQUEST,
	GET_SKEMA_SITE_VISIT_SUCCESS,
	SET_CLEAR_SKEMA_SITE_VISIT,
	GET_LIST_USULAN_SITE_VISIT_FAILURE,
	GET_LIST_USULAN_SITE_VISIT_REQUEST,
	GET_LIST_USULAN_SITE_VISIT_SUCCESS,
	GET_DETAIL_USULAN_SITE_VISIT_FAILURE,
	GET_DETAIL_USULAN_SITE_VISIT_REQUEST,
	GET_DETAIL_USULAN_SITE_VISIT_SUCCESS,
	GET_KOMPONEN_NILAI_SITE_VISIT_FAILURE,
	GET_KOMPONEN_NILAI_SITE_VISIT_REQUEST,
	GET_KOMPONEN_NILAI_SITE_VISIT_SUCCESS,
} from "./actionTypes";

const initialState = {
	skema: {
		isFetching: false,
		items: [],
		item: {}
	},
	usulan: {
		isFetching: false,
		items: [],
		item: {}
	},
	komponenNilai: {
		isFetching: false,
		items: {},
		item: {}
	}
};

const siteVisit = (state = initialState, action) => {
	switch (action.type) {
		case SET_CLEAR_SKEMA_SITE_VISIT:
			return (state = {
				...state,
				skema: {
					...state.skema,
					items: [],
					item: {},
					isFetching: false,
				},
			});
		case GET_SKEMA_SITE_VISIT_REQUEST:
			return (state = {
				...state,
				skema: {
					...state.skema,
					isFetching: true,
				},
			});
		case GET_SKEMA_SITE_VISIT_SUCCESS:
			if (action.payload.state == 'detail') {
				return (state = {
					...state,
					skema: {
						...state.skema,
						isFetching: false,
						item: action.payload.data ?? {},
					},
				});
			} else {
				return (state = {
					...state,
					skema: {
						...state.skema,
						isFetching: false,
						items: action.payload.data ?? [],
						item: action.payload.data.length > 0 ? action.payload.data[0] : {},
					},
				});
			}
		case GET_SKEMA_SITE_VISIT_FAILURE:
			return (state = {
				...state,
				skema: {
					...state.skema,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		case GET_LIST_USULAN_SITE_VISIT_REQUEST:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: true,
				},
			});
		case GET_LIST_USULAN_SITE_VISIT_SUCCESS:
			if (action.payload.state == 'detail') {
				return (state = {
					...state,
					usulan: {
						...state.usulan,
						isFetching: false,
						item: action.payload.data ?? {},
					},
				});
			} else {
				return (state = {
					...state,
					usulan: {
						...state.usulan,
						isFetching: false,
						items: action.payload.data ?? [],
						item: action.payload.data.length > 0 ? action.payload.data[0] : {},
					},
				});
			}
		case GET_LIST_USULAN_SITE_VISIT_FAILURE:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		case GET_DETAIL_USULAN_SITE_VISIT_REQUEST:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: true,
				},
			});
		case GET_DETAIL_USULAN_SITE_VISIT_SUCCESS:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: false,
					item: action.payload.data,
				},
			});
		case GET_DETAIL_USULAN_SITE_VISIT_FAILURE:
			return (state = {
				...state,
				usulan: {
					...state.usulan,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});

		case GET_KOMPONEN_NILAI_SITE_VISIT_REQUEST:
			return (state = {
				...state,
				komponenNilai: {
					...state.komponenNilai,
					isFetching: true,
				},
			});
		case GET_KOMPONEN_NILAI_SITE_VISIT_SUCCESS:
			if (action.payload.state == 'detail') {
				return (state = {
					...state,
					komponenNilai: {
						...state.komponenNilai,
						isFetching: false,
						item: action.payload.data ?? {},
					},
				});
			} else {
				return (state = {
					...state,
					komponenNilai: {
						...state.komponenNilai,
						isFetching: false,
						items: action.payload.data ?? [],
						item: action.payload.data.length > 0 ? action.payload.data[0] : {},
					},
				});
			}
		case GET_KOMPONEN_NILAI_SITE_VISIT_FAILURE:
			return (state = {
				...state,
				komponenNilai: {
					...state.komponenNilai,
					isFetching: false,
					errors: action.payload.errors,
					errorMessage: action.payload.message,
				},
			});
		default:
			return state;
	}
};

export default siteVisit;
