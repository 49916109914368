import { instances } from "../api_helper";
import * as url from "../url_helper";

const changePassword = payload => {
  return instances.request(`${url.CHANGE_PASSWORD}`, {
    method: "PUT",
    data: payload,
  });
};

export { changePassword };
