import {
  LIST_SKEMA_FAILURE,
  LIST_SKEMA_SUCCESS,
  LIST_SKEMA_REQUEST,
  GET_USULAN_BY_SKEMA_REQUEST,
  GET_USULAN_BY_SKEMA_SUCCESS,
  GET_USULAN_BY_SKEMA_FAILURE,
  GET_USULAN_BYID_REQUEST,
  GET_USULAN_BYID_SUCCESS,
  GET_USULAN_BYID_FAILURE,
  LIST_INSTITUSI_REQUEST,
  LIST_INSTITUSI_FAILURE,
  LIST_INSTITUSI_SUCCESS,
  GET_KOMPONEN_PENILIAN_REQUEST,
  GET_KOMPONEN_PENILIAN_SUCCESS,
  GET_KOMPONEN_PENILIAN_FAILURE,
  GET_HASIL_REVIEWER_TERAKHIR_REQUEST,
  GET_HASIL_REVIEWER_TERAKHIR_SUCCESS,
  GET_HASIL_REVIEWER_TERAKHIR_FAILURE,
  SAVE_MONEV_REQUEST,
  SAVE_MONEV_FAILURE,
  SAVE_MONEV_SUCCESS,
  PERMANENT_MONEV_PENGABDIAN_REQUEST,
  PERMANENT_MONEV_PENGABDIAN_SUCCESS,
  PERMANENT_MONEV_PENGABDIAN_FAILURE,
  GET_LINK_BERKAS_MONEV_REQUEST,
  GET_LINK_BERKAS_MONEV_FAILURE,
  GET_LINK_BERKAS_MONEV_SUCCESS,
  GET_POST_PUT_DEL_MONEV_INTERNAL_REQUEST,
  GET_POST_PUT_DEL_MONEV_INTERNAL_SUCCESS,
  GET_POST_PUT_DEL_MONEV_INTERNAL_FAILURE,
  GET_KELOMPOK_PROPOSAL_BY_SKEMA_REQUEST,
  GET_KELOMPOK_PROPOSAL_BY_SKEMA_SUCCESS,
  GET_KELOMPOK_PROPOSAL_BY_SKEMA_FAILURE,
  GET_LIST_INSTITUSI_REVIEWER_REQUEST,
  GET_LIST_INSTITUSI_REVIEWER_SUCCESS,
  GET_LIST_INSTITUSI_REVIEWER_FAILURE,
  CLEAN_KELOMPOK_PROPOSAL_BY_SKEMA
} from "./actionTypes";

export const getListInstitusiReviewer = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_INSTITUSI_REVIEWER_REQUEST,
    payload: request,
    cb
  };
};

export const getListInstitusiReviewerSuccess = response => {
  return {
    type: GET_LIST_INSTITUSI_REVIEWER_SUCCESS,
    payload: response,
  };
};

export const getListInstitusiReviewerFailure = (message, response) => {
  return {
    type: GET_LIST_INSTITUSI_REVIEWER_FAILURE,
    payload: { errors: response, message },
  };
};

export const getKelompokProposalBySkema = (request = {}) => {
  return {
    type: GET_KELOMPOK_PROPOSAL_BY_SKEMA_REQUEST,
    payload: request,
  };
};

export const cleanKelompokProposalBySkema = () => {
  return {
    type: CLEAN_KELOMPOK_PROPOSAL_BY_SKEMA,
  };
};

export const getKelompokProposalBySkemaSuccess = response => {
  return {
    type: GET_KELOMPOK_PROPOSAL_BY_SKEMA_SUCCESS,
    payload: response,
  };
};

export const getKelompokProposalBySkemaFailure = (message, response) => {
  return {
    type: GET_KELOMPOK_PROPOSAL_BY_SKEMA_FAILURE,
    payload: { errors: response, message },
  };
};

export const getLinkBerkasMonev = (request = {}, cb = () => { }) => {
  return {
    type: GET_LINK_BERKAS_MONEV_REQUEST,
    payload: request,
    cb,
  };
};

export const getLinkBerkasMonevSuccess = response => {
  return {
    type: GET_LINK_BERKAS_MONEV_SUCCESS,
    payload: response,
  };
};

export const getLinkBerkasMonevFailure = (message, response) => {
  return {
    type: GET_LINK_BERKAS_MONEV_FAILURE,
    payload: { errors: response, message },
  };
};

export const getHasilReviewerTerakhir = (request = {}) => {
  return {
    type: GET_HASIL_REVIEWER_TERAKHIR_REQUEST,
    payload: request,
  };
};

export const getHasilReviewerTerakhirSuccess = response => {
  return {
    type: GET_HASIL_REVIEWER_TERAKHIR_SUCCESS,
    payload: response,
  };
};

export const getHasilReviewerTerakhirFail = (message, response) => {
  return {
    type: GET_HASIL_REVIEWER_TERAKHIR_FAILURE,
    payload: { errors: response, message },
  };
};

export const getSkemaMonev = (request = {}) => {
  return {
    type: LIST_SKEMA_REQUEST,
    payload: request,
  };
};

export const getSkemaMonevSuccess = response => {
  return {
    type: LIST_SKEMA_SUCCESS,
    payload: response,
  };
};

export const getSkemaMonevFailure = (message, response) => {
  return {
    type: LIST_SKEMA_FAILURE,
    payload: { errors: response, message },
  };
};

export const getUsulanBySkema = (request, cb = () => { }) => {
  return {
    type: GET_USULAN_BY_SKEMA_REQUEST,
    payload: request,
    cb,
  };
};

export const getUsulanBySkemaSuccess = response => {
  return {
    type: GET_USULAN_BY_SKEMA_SUCCESS,
    payload: response,
  };
};

export const getUsulanBySkemaFailure = (message, response) => {
  return {
    type: GET_USULAN_BY_SKEMA_FAILURE,
    payload: { errors: response, message },
  };
};

export const getUsulanById = ({ usulanId, jenis }, cb = () => { }) => {
  return {
    type: GET_USULAN_BYID_REQUEST,
    payload: {
      jenis,
      usulanId,
    },
    cb,
  };
};

export const getUsulanByIdSuccess = response => {
  return {
    type: GET_USULAN_BYID_SUCCESS,
    payload: response,
  };
};

export const getUsulanByIdFailure = (message, response) => {
  return {
    type: GET_USULAN_BYID_FAILURE,
    payload: { errors: response, message },
  };
};

export const getInstitusiMonev = (request = {}, cb = () => { }) => {
  return {
    type: LIST_INSTITUSI_REQUEST,
    payload: request,
    cb,
  };
};

export const getInstitusiMonevSuccess = response => {
  return {
    type: LIST_INSTITUSI_SUCCESS,
    payload: response,
  };
};

export const getInstitusiMonevFailure = (message, response) => {
  return {
    type: LIST_INSTITUSI_FAILURE,
    payload: { errors: response, message },
  };
};

export const getKompPenilaianMonev = (request = {}, cb = () => { }) => {
  return {
    type: GET_KOMPONEN_PENILIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getKompPenilaianMonevSuccess = response => {
  return {
    type: GET_KOMPONEN_PENILIAN_SUCCESS,
    payload: response,
  };
};

export const getKompPenilaianMonevFailure = (message, response) => {
  return {
    type: GET_KOMPONEN_PENILIAN_FAILURE,
    payload: { errors: response, message },
  };
};

export const saveMonev = (request = {}, cb = () => { }) => {
  // console.log("request", request);
  return {
    type: SAVE_MONEV_REQUEST,
    payload: request,
    cb,
  };
};

export const saveMonevSuccess = response => {
  return {
    type: SAVE_MONEV_SUCCESS,
    payload: response,
  };
};

export const saveMonevFailure = (message, response) => {
  return {
    type: SAVE_MONEV_FAILURE,
    payload: { errors: response, message },
  };
};

export const savePermanentMonev = (request = {}, cb = () => { }) => {
  return {
    type: PERMANENT_MONEV_PENGABDIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const savePermanentSuccess = response => {
  return {
    type: PERMANENT_MONEV_PENGABDIAN_SUCCESS,
    payload: response,
  };
};

export const savePermanentFailure = (message, response) => {
  return {
    type: PERMANENT_MONEV_PENGABDIAN_FAILURE,
    payload: { errors: response, message },
  };
};
// Monev Internal
export const getPostPutDelMonevInternal = (request = {}, cb = () => { }) => {
  return {
    type: GET_POST_PUT_DEL_MONEV_INTERNAL_REQUEST,
    payload: request,
    cb,
  };
};

export const getPostPutDelMonevInternalSuccess = response => {
  return {
    type: GET_POST_PUT_DEL_MONEV_INTERNAL_SUCCESS,
    payload: response,
  };
};

export const getPostPutDelMonevInternalFailure = (message, response) => {
  return {
    type: GET_POST_PUT_DEL_MONEV_INTERNAL_FAILURE,
    payload: { errors: response, message },
  };
};
