export const GET_SKEMA_EVALUASI_SUBTANSI_REQUEST = "GET_SKEMA_EVALUASI_SUBTANSI_REQUEST";
export const GET_SKEMA_EVALUASI_SUBTANSI_SUCCESS = "GET_SKEMA_EVALUASI_SUBTANSI_SUCCESS";
export const GET_SKEMA_EVALUASI_SUBTANSI_FAILURE = "GET_SKEMA_EVALUASI_SUBTANSI_FAILURE";
export const SET_CLEAR_SKEMA_EVALUASI_SUBTANSI = "SET_CLEAR_SKEMA_EVALUASI_SUBTANSI";

export const GET_KELOMPOK_EVALUASI_SUBTANSI_V2_REQUEST = "GET_KELOMPOK_EVALUASI_SUBTANSI_V2_REQUEST";
export const GET_KELOMPOK_EVALUASI_SUBTANSI_V2_SUCCESS = "GET_KELOMPOK_EVALUASI_SUBTANSI_V2_SUCCESS";
export const GET_KELOMPOK_EVALUASI_SUBTANSI_V2_FAILURE = "GET_KELOMPOK_EVALUASI_SUBTANSI_V2_FAILURE";
export const CLEAR_KELOMPOK_EVALUASI_SUBTANSI = "CLEAR_KELOMPOK_EVALUASI_SUBTANSI";

export const GET_LIST_USULAN_EVALUASI_SUBTANSI_REQUEST = "GET_LIST_USULAN_EVALUASI_SUBTANSI_REQUEST";
export const GET_LIST_USULAN_EVALUASI_SUBTANSI_SUCCESS = "GET_LIST_USULAN_EVALUASI_SUBTANSI_SUCCESS";
export const GET_LIST_USULAN_EVALUASI_SUBTANSI_FAILURE = "GET_LIST_USULAN_EVALUASI_SUBTANSI_FAILURE";

export const GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_REQUEST = "GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_REQUEST";
export const GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS = "GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS";
export const GET_LIST_USULAN_EVALUASI_SUBTANSI_v2_FAILURE = "GET_LIST_USULAN_EVALUASI_V2_SUBTANSI_FAILURE";

export const GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_REQUEST = "GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_REQUEST";
export const GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS = "GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_SUCCESS";
export const GET_DETAIL_USULAN_EVALUASI_SUBTANSI_v2_FAILURE = "GET_DETAIL_USULAN_EVALUASI_V2_SUBTANSI_FAILURE";

export const GET_DETAIL_USULAN_EVALUASI_SUBTANSI_REQUEST = "GET_DETAIL_USULAN_EVALUASI_SUBTANSI_REQUEST";
export const GET_DETAIL_USULAN_EVALUASI_SUBTANSI_SUCCESS = "GET_DETAIL_USULAN_EVALUASI_SUBTANSI_SUCCESS";
export const GET_DETAIL_USULAN_EVALUASI_SUBTANSI_FAILURE = "GET_DETAIL_USULAN_EVALUASI_V2_SUBTANSI_FAILURE";

export const GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_REQUEST = "GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_REQUEST";
export const GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_SUCCESS = "GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_SUCCESS";
export const GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_FAILURE = "GET_KOMPONEN_NILAI_EVALUASI_SUBTANSI_FAILURE";


export const GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_REQUEST = "GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_REQUEST";
export const GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_SUCCESS = "GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_SUCCESS";
export const GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_FAILURE = "GET_KOMPONEN_NILAI_EVALUASI_V2_SUBTANSI_FAILURE";

export const GET_RAB_EVALUASI_SUBTANSI_REQUEST = "GET_RAB_EVALUASI_SUBTANSI_REQUEST";
export const GET_RAB_EVALUASI_SUBTANSI_SUCCESS = "GET_RAB_EVALUASI_SUBTANSI_SUCCESS";
export const GET_RAB_EVALUASI_SUBTANSI_FAILURE = "GET_RAB_EVALUASI_SUBTANSI_FAILURE";

export const GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST = "GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST";
export const GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS = "GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS";
export const GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE = "GET_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE";

export const SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST = "SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_REQUEST";
export const SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS = "SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_SUCCESS";
export const SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE = "SAVE_RAB_REKOMENDASI_EVALUASI_SUBTANSI_FAILURE";

export const SAVE_PENILAIAN_EVALUASI_SUBTANSI_REQUEST = "SAVE_PENILAIAN_EVALUASI_SUBTANSI_REQUEST";
export const SAVE_PENILAIAN_PERMANEN_EVALUASI_SUBTANSI_REQUEST = "SAVE_PENILAIAN_PERMANEN_EVALUASI_SUBTANSI_REQUEST";

export const SAVE_PENILAIAN_EVALUASI_SUBTANSI_V2_REQUEST = "SAVE_PENILAIAN_EVALUASI_SUBTANSI_V2_REQUEST";
export const SAVE_PENILAIAN_PERMANEN_EVALUASI_V2_SUBTANSI_REQUEST = "SAVE_PENILAIAN_PERMANEN_EVALUASI_V2_SUBTANSI_REQUEST";