import {
  CLEAN_LIST_PENILAIAN_LUARAN,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_FAILURE,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_REQUEST,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_SUCCESS,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_FAILURE,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_REQUEST,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_SUCCESS,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_FAILURE,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_REQUEST,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_SUCCESS,
  SAVE_PENILAIAN_LUARAN_EVALUASI_FAILURE,
  SAVE_PENILAIAN_LUARAN_EVALUASI_REQUEST,
  SAVE_PENILAIAN_LUARAN_EVALUASI_SUCCESS,
  SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_FAILURE,
  SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_REQUEST,
  SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_SUCCESS,
} from "./actionTypes";

const initialState = {
  list_institusi: {
    isFetching: false,
    items: [],
  },
  list_institusi_detail_1: {
    isFetching: false,
    items: [],
  },
  list_institusi_detail_2: {
    isFetching: false,
    items: [],
  },
  save_penilaian_luaran_evaluasi: {
    isFetching: false,
    items: [],
  },
  save_penilaian_luaran_evaluasi_to_permanen: {
    isFetching: false,
    items: [],
  },
};

const PenilaianLuaran = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_LIST_PENILAIAN_LUARAN:
      return (state = {
        ...state,
        list_institusi: {
          ...state.list_institusi,
          items: [],
        },
      });
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_REQUEST:
      return (state = {
        ...state,
        list_institusi: {
          ...state.list_institusi,
          isFetching: true,
          items: [],
        },
      });
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_SUCCESS:
      return (state = {
        ...state,
        list_institusi: {
          ...state.list_institusi,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_FAILURE:
      return (state = {
        ...state,
        list_institusi: {
          ...state.list_institusi,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    //
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_REQUEST:
      return (state = {
        ...state,
        list_institusi_detail_1: {
          ...state.list_institusi_detail_1,
          isFetching: true,
          items: [],
        },
      });
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_SUCCESS:
      return (state = {
        ...state,
        list_institusi_detail_1: {
          ...state.list_institusi_detail_1,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_FAILURE:
      return (state = {
        ...state,
        list_institusi_detail_1: {
          ...state.list_institusi_detail_1,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    //
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_REQUEST:
      return (state = {
        ...state,
        list_institusi_detail_2: {
          ...state.list_institusi_detail_2,
          isFetching: true,
          items: [],
        },
      });
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_SUCCESS:
      return (state = {
        ...state,
        list_institusi_detail_2: {
          ...state.list_institusi_detail_2,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_FAILURE:
      return (state = {
        ...state,
        list_institusi_detail_2: {
          ...state.list_institusi_detail_2,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    //
    case SAVE_PENILAIAN_LUARAN_EVALUASI_REQUEST:
      return (state = {
        ...state,
        save_penilaian_luaran_evaluasi: {
          ...state.save_penilaian_luaran_evaluasi,
          isFetching: true,
          items: [],
        },
      });
    case SAVE_PENILAIAN_LUARAN_EVALUASI_SUCCESS:
      return (state = {
        ...state,
        save_penilaian_luaran_evaluasi: {
          ...state.save_penilaian_luaran_evaluasi,
          isFetching: false,
          items: action.payload,
        },
      });
    case SAVE_PENILAIAN_LUARAN_EVALUASI_FAILURE:
      return (state = {
        ...state,
        save_penilaian_luaran_evaluasi: {
          ...state.save_penilaian_luaran_evaluasi,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    //
    case SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_REQUEST:
      return (state = {
        ...state,
        save_penilaian_luaran_evaluasi_to_permanen: {
          ...state.save_penilaian_luaran_evaluasi_to_permanen,
          isFetching: true,
          items: [],
        },
      });
    case SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_SUCCESS:
      return (state = {
        ...state,
        save_penilaian_luaran_evaluasi_to_permanen: {
          ...state.save_penilaian_luaran_evaluasi_to_permanen,
          isFetching: false,
          items: action.payload,
        },
      });
    case SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_FAILURE:
      return (state = {
        ...state,
        save_penilaian_luaran_evaluasi_to_permanen: {
          ...state.save_penilaian_luaran_evaluasi_to_permanen,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    default:
      return state;
  }
};

export default PenilaianLuaran;
