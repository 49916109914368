/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";
import {
  getListDaftarReviewerSuccess,
  getListDaftarReviewerFailure,
  putStatusDaftarReviewerSuccess,
  putStatusDaftarReviewerFailure,
  getDaftarReviewerByNameSuccess,
  getDaftarReviewerByNameFailure,
  postDaftarReviewerFailure,
  postDaftarReviewerSuccess,
  getDropdownTahapanFailure,
  getDropdownTahapanSuccess,
  getDropdownTahunKegiatanSuccess,
  getDropdownTahunKegiatanFailure,
  getSkemaReviewerSuccess,
  getSkemaReviewerFailure,
  getSkemaReviewerByIdSuccess,
  getSkemaReviewerByIdFailure,
  searchReviewerPT1Success,
  searchReviewerPT1Failure,
  searchReviewerPenelitianSatuSuccess,
  searchReviewerPenelitianSatuFailure,
  searchReviewerPenelitianPusatDuaSuccess,
  searchReviewerPenelitianPusatDuaFailure,
  searchReviewerPengabdianSatuSuccess,
  searchReviewerPengabdianSatuFailure,
  searchReviewerPengabdianPusatDuaSuccess,
  searchReviewerPengabdianPusatDuaFailure,
  postPlottingReviewerSuccess,
  postPlottingReviewerFailure,
  searchReviewerPT2Success,
  searchReviewerPT2Failure,
  getListRekapPlottingReviewerV2Success,
  getListRekapPlottingReviewerV2Failure,
  getPlottingDetailPlottingReviewerV2Success,
  getPlottingDetailPlottingReviewerV2Failure,
  getDetailReviewerPlottingReviewerV2Success,
  getDetailReviewerPlottingReviewerV2Failure,
  createPlottingPlottingReviewerV2Success,
  createPlottingPlottingReviewerV2Failure,
  getMonitoringPenilaianPlottingReviewerV2Success,
  getMonitoringPenilaianPlottingReviewerV2Failure,
  getMonitoringPenilaianDetailPlottingReviewerV2Success,
  getMonitoringPenilaianDetailPlottingReviewerV2Failure,
} from "./action";

import {
  LIST_DAFTAR_REVIEWER_REQUEST,
  PUT_STATUS_DAFTAR_REVIEWER_REQUEST,
  GET_REVIEWER_BY_NAME_REQUEST,
  POST_DAFTAR_REVIEWER_REQUEST,
  GET_DROPDOWN_TAHAPAN_REQUEST,
  GET_DROPDOWN_TAHUN_KEGIATAN_REQUEST,
  GET_SKEMA_REVIEWER_REQUEST,
  GET_SKEMA_REVIEWER_BY_ID_REQUEST,
  SEARCH_REVIEWER_PT_REQUEST,
  SEARCH_REVIEWER_PT2_REQUEST,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_REQUEST,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_REQUEST,
  POST_PLOTTING_REVIEWER_PENELITIAN_REQUEST,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_REQUEST,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_REQUEST,
  GET_LIST_REKAP_PLOTTING_REVIEWER_V2_REQUEST,
  GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
  GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_REQUEST,
  CREATE_PLOTTING_PLOTTING_REVIEWER_V2_REQUEST,
  GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_REQUEST,
  GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
} from "./actionTypes";

function* getListDaftarReviewer({ payload, cb }) {
  try {
    const response = yield call(backend.getListDaftarReviewer, payload);
    if (response.data.code == 200) {
      yield put(getListDaftarReviewerSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    cb(null);
    const message = error.message;
    yield put(getListDaftarReviewerFailure(message, error));
  }
}

function* getDaftarReviewerByName({ payload }) {
  try {
    const response = yield call(backend.getDaftarReviewerByName, payload);
    if (response.data.code == 200) {
      yield put(
        getDaftarReviewerByNameSuccess(Object.values(response.data.data))
      );
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDaftarReviewerByNameFailure(message, error));
  }
}

function* putStatusDaftarReviewer({ payload, cb }) {
  try {
    const response = yield call(backend.putStatusDaftarReviewer, payload);
    if (response.data.code == 200) {
      yield put(putStatusDaftarReviewerSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(putStatusDaftarReviewerFailure(message, error));
    cb(null);
  }
}

function* postDaftarReviewer({ payload, cb }) {
  try {
    const response = yield call(backend.postDaftarReviewer, payload);
    if (response.data.code == 200) {
      yield put(postDaftarReviewerSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(postDaftarReviewerFailure(message, error));
    cb(null);
  }
}

function* getDropdownTahapan({ payload, cb }) {
  try {
    const response = yield call(backend.getDropdownTahapan, payload);
    if (response.data.code == 200) {
      yield put(getDropdownTahapanSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getDropdownTahapanFailure(message, error));
    cb(null);
  }
}

function* getDropdownTahunKegiatan({ payload, cb }) {
  try {
    const response = yield call(backend.getDropdownTahunKegiatan, payload);
    if (response.data.code == 200) {
      yield put(getDropdownTahunKegiatanSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getDropdownTahunKegiatanFailure(message, error));
    cb(null);
  }
}

function* getSkemaReviewer({ payload }) {
  try {
    const response = yield call(backend.getSkemaReviewer, payload);
    if (response.data.code == 200) {
      yield put(getSkemaReviewerSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getSkemaReviewerFailure(message, error));
  }
}

function* getSkemaReviewerById({ payload }) {
  try {
    const response = yield call(backend.getSkemaReviewerById, payload);
    if (response.data.code == 200) {
      yield put(getSkemaReviewerByIdSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getSkemaReviewerByIdFailure(message, error));
  }
}

function* searchReviewerPT1({ payload }) {
  try {
    const response = yield call(backend.searchReviewerPT1, payload);
    if (response.data.code == 200) {
      yield put(searchReviewerPT1Success(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch {
    const message = error.message;
    yield put(searchReviewerPT1Failure(message, error));
  }
}

function* searchReviewerPT2({ payload }) {
  try {
    const response = yield call(backend.searchReviewerPT1, payload);
    if (response.data.code == 200) {
      yield put(searchReviewerPT2Success(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch {
    const message = error.message;
    yield put(searchReviewerPT2Failure(message, error));
  }
}

function* searchReviewerPenelitianPusatSatu({ payload }) {
  try {
    const response = yield call(backend.searchReviewerPenelitianPusat, payload);
    if (response.data.code == 200) {
      yield put(searchReviewerPenelitianSatuSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(searchReviewerPenelitianSatuFailure(message, error));
  }
}

function* searchReviewerPenelitianPusatDua({ payload }) {
  try {
    const response = yield call(backend.searchReviewerPenelitianPusat, payload);
    if (response.data.code == 200) {
      yield put(searchReviewerPenelitianPusatDuaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(searchReviewerPenelitianPusatDuaFailure(message, error));
  }
}

function* searchReviewerPeengabdianusatSatu({ payload }) {
  try {
    const response = yield call(backend.searchReviewerPengabdianPusat, payload);
    if (response.data.code == 200) {
      yield put(searchReviewerPengabdianSatuSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(searchReviewerPengabdianSatuFailure(message, error));
  }
}

function* searchReviewerPengabdianPusatDua({ payload }) {
  try {
    const response = yield call(backend.searchReviewerPengabdianPusat, payload);
    if (response.data.code == 200) {
      yield put(searchReviewerPengabdianPusatDuaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(searchReviewerPengabdianPusatDuaFailure(message, error));
  }
}

function* postPlottingReviewer({ payload, cb }) {
  try {
    const response = yield call(backend.postPlottingReviewer, payload);
    if (response.data.code == 200) {
      yield put(postPlottingReviewerSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(postPlottingReviewerFailure(message, error));
    cb(message);
  }
}
//plotting reviwer v2
function* getListRekapPlottingReviewerV2({ payload, cb }) {
  try {
    const res = yield call(backend.crudPlottingReviewerV2, payload);
    if (res.data.code === 200) {
      yield put(getListRekapPlottingReviewerV2Success({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getListRekapPlottingReviewerV2Failure(message?.error));
  }
}
function* getPlottingDetailPlottingReviewerV2({ payload, cb }) {
  try {
    const res = yield call(backend.crudPlottingReviewerV2, payload);
    if (res.data.code === 200) {
      yield put(
        getPlottingDetailPlottingReviewerV2Success({ data: res.data.data })
      );
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getPlottingDetailPlottingReviewerV2Failure(message?.error));
  }
}
function* getDetailReviewerPlottingReviewerV2({ payload, cb }) {
  try {
    const res = yield call(backend.crudPlottingReviewerV2, payload);
    if (res.data.code === 200) {
      yield put(
        getDetailReviewerPlottingReviewerV2Success({ data: res.data.data })
      );
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getDetailReviewerPlottingReviewerV2Failure(message?.error));
  }
}
function* createPlottingPlottingReviewerV2({ payload, cb }) {
  try {
    const res = yield call(backend.crudPlottingReviewerV2, payload);
    if (res.data.code === 200) {
      yield put(
        createPlottingPlottingReviewerV2Success({ data: res.data.data })
      );
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(createPlottingPlottingReviewerV2Failure(message?.error));
  }
}
function* getMonitoringPenilaianPlottingReviewerV2({ payload, cb }) {
  try {
    const res = yield call(backend.crudPlottingReviewerV2, payload);
    if (res.data.code === 200) {
      yield put(
        getMonitoringPenilaianPlottingReviewerV2Success({ data: res.data.data })
      );
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(getMonitoringPenilaianPlottingReviewerV2Failure(message?.error));
  }
}
function* getMonitoringPenilaianDetailPlottingReviewerV2({ payload, cb }) {
  try {
    const res = yield call(backend.crudPlottingReviewerV2, payload);
    if (res.data.code === 200) {
      yield put(
        getMonitoringPenilaianDetailPlottingReviewerV2Success({
          data: res.data.data,
        })
      );
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(
      getMonitoringPenilaianDetailPlottingReviewerV2Failure(message?.error)
    );
  }
}
function* operatorPengelolaanReviewerSaga() {
  yield takeEvery(LIST_DAFTAR_REVIEWER_REQUEST, getListDaftarReviewer);
  yield takeEvery(GET_REVIEWER_BY_NAME_REQUEST, getDaftarReviewerByName);
  yield takeEvery(PUT_STATUS_DAFTAR_REVIEWER_REQUEST, putStatusDaftarReviewer);
  yield takeEvery(POST_DAFTAR_REVIEWER_REQUEST, postDaftarReviewer);
  yield takeEvery(GET_DROPDOWN_TAHAPAN_REQUEST, getDropdownTahapan);
  yield takeEvery(
    GET_DROPDOWN_TAHUN_KEGIATAN_REQUEST,
    getDropdownTahunKegiatan
  );
  yield takeEvery(GET_SKEMA_REVIEWER_REQUEST, getSkemaReviewer);
  yield takeEvery(GET_SKEMA_REVIEWER_BY_ID_REQUEST, getSkemaReviewerById);
  yield takeEvery(SEARCH_REVIEWER_PT_REQUEST, searchReviewerPT1);
  yield takeEvery(SEARCH_REVIEWER_PT2_REQUEST, searchReviewerPT2);
  yield takeEvery(
    SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_REQUEST,
    searchReviewerPenelitianPusatSatu
  );
  yield takeEvery(
    SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_REQUEST,
    searchReviewerPenelitianPusatDua
  );

  yield takeEvery(
    SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_REQUEST,
    searchReviewerPeengabdianusatSatu
  );
  yield takeEvery(
    SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_REQUEST,
    searchReviewerPengabdianPusatDua
  );

  yield takeEvery(
    POST_PLOTTING_REVIEWER_PENELITIAN_REQUEST,
    postPlottingReviewer
  );

  yield takeEvery(
    GET_LIST_REKAP_PLOTTING_REVIEWER_V2_REQUEST,
    getListRekapPlottingReviewerV2
  );
  yield takeEvery(
    GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
    getPlottingDetailPlottingReviewerV2
  );
  yield takeEvery(
    GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_REQUEST,
    getDetailReviewerPlottingReviewerV2
  );
  yield takeEvery(
    CREATE_PLOTTING_PLOTTING_REVIEWER_V2_REQUEST,
    createPlottingPlottingReviewerV2
  );
  yield takeEvery(
    GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_REQUEST,
    getMonitoringPenilaianPlottingReviewerV2
  );
  yield takeEvery(
    GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
    getMonitoringPenilaianDetailPlottingReviewerV2
  );
}

export default operatorPengelolaanReviewerSaga;
