/* eslint-disable no-unused-vars */
import stylePDF from "./styles";
import header from "./header";
import {
  contentAnggaranRevisi,
  contentPrototipe,
} from "../../prototipe/partials/content";
import utilities from "helpers/utilities";
import { contentKosabangsa as contentKosabangsa2023 } from "../../kosabangsa/proposal/partials/content";
import { contentKosabangsa as contentKosabangsa2024 } from "../../kosabangsa2024/partials/content";
import { contentProposalForLaporanKemajuanPenelitianV2 } from "../../usulanPenelitanV2/partials/content";
import { contentProposalForLaporanKemajuanPengabdianV2 } from "../../usulanBaruPengabdianV2/partials/contentDikti";
const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
  let thnAkhirUsulan = parseInt(thnPertamaUsulan);
  for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
    thnAkhirUsulan += tahun;
  }
  return thnAkhirUsulan;
};

const content = (data, flagJenisKegiatan, jenisKegiatan) => {
  const { hr } = stylePDF;

  const {
    raw: { identitas },
  } = data.proposal;

  const { nama_skema, keyword, ringkasan } = data.laporan_akhir;
  let mainContent;
  if (flagJenisKegiatan === 1 && jenisKegiatan === "penelitian") {
    // Penelitian
    mainContent = [
      ...contentProposalForLaporanKemajuanPenelitianV2(
        data.proposal,
        data?.revisi_proposal || null
      ),
    ];
  } else if (flagJenisKegiatan === 2 && jenisKegiatan === "pengabdian") {
    // Pengabdian
    mainContent = [
      ...contentProposalForLaporanKemajuanPengabdianV2(data.proposal),
    ];
  } else if (flagJenisKegiatan === 1 && jenisKegiatan === "prototipe") {
    mainContent = [
      ...contentPrototipe(data.proposal),
      {
        style: ["mt-10"],
        stack: contentAnggaranRevisi(data.revisi_proposal),
      },
    ];
  } else if (flagJenisKegiatan === 2 && jenisKegiatan === "kosabangsa") {
    const date = new Date(data.proposal.tgl_created);
    const year = date.getFullYear();
    if (year === 2024) {
      mainContent = [
        ...contentKosabangsa2024(data.proposal, data?.revisi_proposal || null),
      ];
    } else if (year === 2023) {
      mainContent = [
        ...contentKosabangsa2023(data.proposal, data?.revisi_proposal || null),
      ];
    }
  }
  return [
    header(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI LAPORAN AKHIR " + nama_skema?.toUpperCase(),
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `LAPORAN AKHIR ${identitas?.tahun_pelaksanaan ?? ""}`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `Rencana Pelaksanaan ${nama_skema}: tahun ${
            identitas?.tahun_pelaksanaan ?? ""
          } s.d. tahun ${getThnAkhirUsulan(
            identitas?.lama_kegiatan,
            identitas.tahun_pelaksanaan
          )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    ...mainContent,
    {
      marginTop: 5,
      text: ["*. KEMAJUAN PENELITIAN"],
      style: ["bold"],
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t A. RINGKASAN",
        preserveLeadingSpaces: true,
      },
      style: ["bold"],
    },
    {
      marginTop: 5,
      text: `\u200B\t\t ${ringkasan}`,
      style: ["text", "fontSizeM"],
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t B. KATA KUNCI",
        preserveLeadingSpaces: true,
      },
      style: ["bold", "text"],
    },
    {
      marginTop: 5,
      text: `\u200B\t\t ${keyword}`,
      style: ["text"],
    },
  ];
};

const contentSptb = (data = {}) => {
  const sumNilai = (data = [], property) => {
    return data.reduce((accumulator, currentExpense) => {
      return accumulator + currentExpense[property];
    }, 0);
  };
  const id_rab_kelompok_biaya_list =
    data.type === "kosabangsa"
      ? [21, 22, 23, 24, 25, 26]
      : data.type === "prototipe"
      ? [16, 17, 18, 19, 20]
      : data.type === "pengabdian"
      ? [28, 29, 30, 31, 32]
      : [];
  const sumNilaiRab = (data = [], property) => {
    return data
      .filter(item =>
        id_rab_kelompok_biaya_list.includes(item.id_rab_kelompok_biaya)
      )
      .reduce((accumulator, currentExpense) => {
        return accumulator + currentExpense[property] * currentExpense.volume;
      }, 0);
  };
  const catatanHarian = data.dataCatatanHarian;
  const laporanAkhir = data.dataLaporanAkhir;
  const reMapped = catatanHarian.map(item => ({
    ...item,
    kelompok_biaya: item?.nama_rab_kelompok_biaya,
  }));
  const grouppedCatatanHarian = utilities.groupBy(reMapped, "kelompok_biaya");
  const grouppedRab = utilities.groupBy(
    catatanHarian[0]?.rab_komponen_belanja_revisi || [],
    "id_rab_kelompok_biaya"
  );
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["5%", "23.75%", "23.75%", "23.75%", "23.75%"],
      body: [
        [
          {
            text: `No`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Komponen Biaya`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `No. Berkas`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `RAB`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Jumlah Terealisasi`,
            style: ["center", "bgColorHeaderTable"],
          },
        ],
      ],
    },
  };
  Object.keys(grouppedCatatanHarian).forEach((property, indexProperty) => {
    grouppedCatatanHarian[property].forEach((item, indexItem) => {
      if (indexItem === 0) {
        layout.table.body.push([
          {
            text: `${indexProperty + 1}`,
            style: ["center", "bold"],
          },
          {
            text: `${property}`,
            style: ["center", "bold"],
          },
          {
            text: ``,
            style: ["center"],
          },
          {
            text: `${utilities.toCurrency(
              sumNilaiRab(
                grouppedRab[item.id_rab_kelompok_biaya],
                "harga_satuan"
              ),
              ".",
              0,
              "Rp. "
            )}`,
            style: ["center", "bold"],
          },
          {
            text: `${utilities.toCurrency(
              sumNilai(grouppedCatatanHarian[property], "biaya_terpakai"),
              ".",
              0,
              "Rp. "
            )}`,
            style: ["center", "bold"],
          },
        ]);
      }
      layout.table.body.push([
        {
          text: ``,
          style: ["center"],
        },
        {
          text: `${item.kegiatan}`,
          style: ["center"],
        },
        {
          text: `${item.no_berkas}`,
          style: ["center"],
        },
        {
          text: ``,
          style: ["center"],
        },
        {
          text: `${utilities.toCurrency(item.biaya_terpakai, ".", 0, "Rp. ")}`,
          style: ["center"],
        },
      ]);
    });
  });
  layout.table.body.push([
    {
      text: `Total`,
      style: ["center", "bold"],
      colSpan: 3,
    },
    {
      text: ``,
      style: ["center"],
    },
    {
      text: ``,
      style: ["center"],
    },
    {
      text: `${utilities.toCurrency(
        sumNilaiRab(
          catatanHarian[0]?.rab_komponen_belanja_revisi || [],
          "harga_satuan"
        ),
        ".",
        0,
        "Rp. "
      )}`,
      style: ["center", "bold"],
    },
    {
      text: `${utilities.toCurrency(
        sumNilai(catatanHarian, "biaya_terpakai"),
        ".",
        0,
        "Rp. "
      )}`,
      style: ["center", "bold"],
    },
  ]);
  const nameKetuaLembaga =
    data.type === "prototipe"
      ? laporanAkhir.dosen_ketua.institusi?.additional?.find(
          item => item.peran.id === 9
        )?.personal?.nama
      : data.type === "kosabangsa"
      ? laporanAkhir.dosen_ketua.institusi?.additional?.find(
          item => item.peran.id === 10
        )?.personal?.nama
      : "-";
  const nidnKetuaLembaga =
    data.type === "prototipe"
      ? laporanAkhir.dosen_ketua.institusi?.additional?.find(
          item => item.peran.id === 9
        )?.nidn
      : data.type === "kosabangsa"
      ? laporanAkhir.dosen_ketua.institusi?.additional?.find(
          item => item.peran.id === 10
        )?.nidn
      : "-";
  return [
    {
      stack: [
        {
          text: "Penggunaan Anggaran",
          style: ["bold", "center", "mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: `Nama Ketua : ${laporanAkhir.dosen_ketua.nama}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Judul : ${laporanAkhir.judul}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Tahun Pelaksanaan : ${laporanAkhir.thn_pelaksanaan}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Dana Disetujui : ${utilities.toCurrency(
            laporanAkhir.dana_disetujui,
            ".",
            0,
            "Rp. "
          )}`,
          style: ["bold", "mb-5"],
        },
      ],
    },
    {
      stack: [
        {
          ...layout,
        },
      ],
    },
    {
      stack: [
        {
          text: `Sisa Dana : ${utilities.toCurrency(
            sumNilaiRab(
              catatanHarian[0]?.rab_komponen_belanja_revisi || [],
              "harga_satuan"
            ) - sumNilai(catatanHarian, "biaya_terpakai"),
            ".",
            0,
            "Rp. "
          )}`,
          style: ["mt-10", "bold"],
        },
        {
          text: `Dana yang sudah digunakan : ${(
            (sumNilai(catatanHarian, "biaya_terpakai") /
              sumNilaiRab(
                catatanHarian[0]?.rab_komponen_belanja_revisi || [],
                "harga_satuan"
              )) *
            100
          ).toFixed()} %
           `,
          style: ["mt-5", "bold"],
        },
      ],
    },
    {
      style: ["mt-20"],
      stack: [
        {
          style: ["text"],
          layout: "noBorders", // Menghilangkan border tabel,
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "Mengetahui, Ketua LPPM",
                  style: ["left"],
                },
                {
                  text: "Ketua Pelaksana",
                  style: ["right"],
                },
              ],
              [
                {
                  text: `\n${nameKetuaLembaga}`,
                  style: ["left", "mt-20"],
                },
                {
                  text: `\n${laporanAkhir.dosen_ketua.nama}`,
                  style: ["right", "mt-20"],
                },
              ],
              [
                {
                  text: `${nidnKetuaLembaga}`,
                  style: ["left", "mt-2"],
                },
                {
                  text: `${laporanAkhir.dosen_ketua.nidn}`,
                  style: ["right", "mt-2"],
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
const contentSptbPenelitian = (data = {}) => {
  const { sptbItems, data_dosen, dataForm, rab_revisi } = data.data;
  const { dosen_ketua } = data_dosen;
  const sumNilai = (data = [], property) => {
    return data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.realisasi;
    }, 0);
  };
  const sumRabByKelompok = data => {
    const persentase = 100;
    const nilai_awal = data?.reduce(
      (a, { harga_satuan, volume }) =>
        a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
      0
    );
    const hasil = (persentase / 100) * nilai_awal;
    return hasil;
  };
  const tableSptb = () => {
    const layout = {
      style: ["mb-10"],
      layout: {
        hLineWidth: (_i, _node) => 0.3,
        vLineWidth: _i => 0.3,
        padding: () => 0,
      },
      table: {
        headerRows: 1,
        widths: ["5%", "59%", "18%", "18%"],
        body: [
          [
            {
              text: `No`,
              style: ["center", "bgColorHeaderTable"],
            },
            {
              text: `Uraian`,
              style: ["center", "bgColorHeaderTable"],
            },
            {
              text: `RAB 100%`,
              style: ["center", "bgColorHeaderTable"],
            },
            {
              text: `Realisasi`,
              style: ["center", "bgColorHeaderTable"],
            },
          ],
        ],
      },
    };
    sptbItems.forEach(item => {
      layout.table.body.push([
        {
          text: `${item.no}`,
          style: ["center"],
        },
        {
          text: [
            { text: `${item.nama_kelompok_rab}\n`, bold: true },
            `${item.uraian}`,
          ],
          style: ["left"],
        },
        {
          text: `${utilities.toCurrency(
            sumRabByKelompok(
              rab_revisi.filter(
                x => x.raw.id_kelompok_rab === item.id_kelompok_rab
              )
            ),
            ".",
            0,
            "Rp."
          )}`,
          style: ["left"],
        },
        {
          text: `${utilities.toCurrency(item.realisasi, ".", 0, "Rp.")}`,
          style: ["left"],
        },
      ]);
    });
    layout.table.body.push([
      {
        text: `Realisasi ( 100 % )`,
        style: ["center", "bold"],
        colSpan: 3,
      },
      {
        text: ``,
      },
      {
        text: ``,
      },
      {
        text: `${utilities.toCurrency(sumNilai(sptbItems), ".", 0, "Rp.")}`,
      },
    ]);
    return layout;
  };
  return [
    {
      stack: [
        {
          text: "SURAT PERNYATAAN TANGGUNG JAWAB BELANJA",
          style: ["bold", "center", "mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: "Yang bertanda tangan di bawah ini :",
          style: ["mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: `Nama   : ${dosen_ketua.gelar_akademik_depan} ${dosen_ketua.nama} ${dosen_ketua.gelar_akademik_belakang}`,
          style: ["mb-10"],
        },
        {
          text: `Alamat : ${dosen_ketua.alamat}`,
          style: ["mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: `berdasarkan Surat Keputusan Nomor ${
            dataForm.nomor_surat_keputusan
          } dan Perjanjian / Kontrak Nomor ${
            dataForm.nomor_perjanjian_kontrak
          } mendapatkan Anggaran Penelitian ${
            data_dosen.judul
          } Sebesar ${utilities.toCurrency(
            data_dosen.dana_disetujui,
            ".",
            0,
            "Rp."
          )}`,
          style: ["mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: "Dengan ini menyatakan bahwa :",
          style: ["mb-5"],
        },
      ],
    },
    {
      stack: [
        {
          text: " 1. Biaya kegiatan Penelitian di bawah ini meliputi :",
          style: ["mb-10"],
        },
      ],
    },
    {
      stack: [tableSptb()],
    },
    {
      stack: [
        {
          text: " 2. Jumlah uang tersebut pada angka 1, benar-benar dikeluarkan untuk pelaksanaan kegiatan Penelitian dimaksud.",
          style: ["mb-10"],
        },
        {
          text: "Demikian surat pernyataan ini dibuat dengan sebenarnya.",
          style: ["mb-10"],
          // pageBreak: "after",
        },
      ],
    },
    {
      style: ["mt-20"],
      stack: [
        {
          style: ["text"],
          layout: "noBorders", // Menghilangkan border tabel,
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "",
                  style: ["left"],
                },
                {
                  text: `${dataForm.tempat_tanggal}, Ketua\n\n`,
                  style: ["right"],
                },
              ],
              [
                {
                  text: ``,
                  style: ["left", "mt-20"],
                },
                {
                  text: `${dosen_ketua.gelar_akademik_depan} ${dosen_ketua.nama} ${dosen_ketua.gelar_akademik_belakang}`,
                  style: ["right", "mt-20"],
                },
              ],
              [
                {
                  text: ``,
                  style: ["left", "mt-2"],
                },
                {
                  text: `NIP/NIPK ${dataForm.nip_nipk}`,
                  style: ["right", "mt-2"],
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
export { content, contentSptb, contentSptbPenelitian };
