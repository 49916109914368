import encStorage from "helpers/encStorage";
import {
  CLEAN_USULAN_PERBAIKAN_V2,
  GET_DETAIL_USULAN_PERBAIKAN_V2_FAILURE,
  GET_DETAIL_USULAN_PERBAIKAN_V2_REQUEST,
  GET_DETAIL_USULAN_PERBAIKAN_V2_SUCCESS,
  GET_USULAN_PERBAIKAN_V2_FAILURE,
  GET_USULAN_PERBAIKAN_V2_REQUEST,
  GET_USULAN_PERBAIKAN_V2_SUCCESS,
  MERGING_INTO_USULAN_PERBAIKAN_V2_FAILURE,
  MERGING_INTO_USULAN_PERBAIKAN_V2_REQUEST,
  MERGING_INTO_USULAN_PERBAIKAN_V2_SUCCESS,
  UPDATE_ISIAN_ITEM_USULAN_PERBAIKAN,
  UPDATE_USULAN_PERBAIKAN_V2_FAILURE,
  UPDATE_USULAN_PERBAIKAN_V2_REQUEST,
  UPDATE_USULAN_PERBAIKAN_V2_SUCCESS,
  USULAN_PERBAIKAN_BYID_FAILURE,
  USULAN_PERBAIKAN_BYID_REQUEST,
  USULAN_PERBAIKAN_BYID_SUCCESS,
  USULAN_PERBAIKAN_FAILURE,
  USULAN_PERBAIKAN_REQUEST,
  USULAN_PERBAIKAN_SUBMIT_FAILURE,
  USULAN_PERBAIKAN_SUBMIT_REQUEST,
  USULAN_PERBAIKAN_SUBMIT_SUCCESS,
  USULAN_PERBAIKAN_SUCCESS,
} from "./actionTypes";

let username = "";
if (encStorage.getItem("authUser")) {
  const obj = JSON.parse(encStorage.getItem("authUser"));
  username = obj.username;
}

const initialState = {
  username,
  isFetching: false,
  isFetchingByid: false,
  errorMessage: "",
  errors: null,
  items: [],
  isianItem: {},
  itemSelected: {},
  submit: {
    item: {},
    isFetching: false,
  },
  v2: {
    items: [],
    itemSelected: {},
    submit: {},
  },
};

const usulanPerbaikan = (state = initialState, action) => {
  switch (action.type) {
    case MERGING_INTO_USULAN_PERBAIKAN_V2_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case MERGING_INTO_USULAN_PERBAIKAN_V2_SUCCESS:
      return {
        ...state,
        isFetching: false,
        v2: {
          ...state.v2,
          items: [...state.v2.items, ...(action.payload.items ?? [])],
        },
      };
    case MERGING_INTO_USULAN_PERBAIKAN_V2_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };

    case UPDATE_USULAN_PERBAIKAN_V2_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_USULAN_PERBAIKAN_V2_SUCCESS:
      return {
        ...state,
        isFetching: false,

        v2: {
          ...state.v2,
          submit: {
            ...state.v2.submit,
            item: action.item,
          },
        },
      };
    case UPDATE_USULAN_PERBAIKAN_V2_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case GET_DETAIL_USULAN_PERBAIKAN_V2_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_DETAIL_USULAN_PERBAIKAN_V2_SUCCESS:
      return {
        ...state,
        isFetching: false,
        v2: {
          ...state.v2,
          itemSelected: action.payload.item ?? {},
        },
      };
    case GET_DETAIL_USULAN_PERBAIKAN_V2_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case GET_USULAN_PERBAIKAN_V2_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USULAN_PERBAIKAN_V2_SUCCESS:
      return {
        ...state,
        isFetching: false,
        v2: {
          ...state.v2,
          items: action.payload.items ?? [],
        },
      };
    case CLEAN_USULAN_PERBAIKAN_V2:
      return {
        ...state,
        isFetching: false,
        v2: {
          ...state.v2,
          items: [],
        },
      };
    case GET_USULAN_PERBAIKAN_V2_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case USULAN_PERBAIKAN_BYID_REQUEST:
      return {
        ...state,
        isFetchingByid: true,
      };
    case USULAN_PERBAIKAN_BYID_SUCCESS: {
      return {
        ...state,
        isFetchingByid: false,
        itemSelected: action.item ?? {},
      };
    }
    case USULAN_PERBAIKAN_BYID_FAILURE:
      return {
        ...state,
        isFetchingByid: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case USULAN_PERBAIKAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case USULAN_PERBAIKAN_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        items: action.payload ?? [],
      };
    }
    case USULAN_PERBAIKAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    case USULAN_PERBAIKAN_SUBMIT_REQUEST:
      return {
        ...state,
        submit: {
          ...state.submit,
          isFetching: true,
        },
      };
    case USULAN_PERBAIKAN_SUBMIT_SUCCESS: {
      return {
        ...state,
        submit: {
          ...state.submit,
          item: action.payload ?? {},
          isFetching: false,
        },
      };
    }
    case USULAN_PERBAIKAN_SUBMIT_FAILURE:
      return {
        ...state,
        submit: {
          ...state.submit,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
          isFetching: false,
        },
      };
    case UPDATE_ISIAN_ITEM_USULAN_PERBAIKAN:
      return {
        ...state,
        isianItem: action.payload.data,
      };
    default:
      return state;
  }
};

export default usulanPerbaikan;
