import {
    CREATE_PENUGASAN_NUSANTARA_FAILURE, CREATE_PENUGASAN_NUSANTARA_REQUEST,
    CREATE_PENUGASAN_NUSANTARA_SUCCESS, GET_PENUGASAN_NUSANTARA_BYID_FAILURE,
    GET_PENUGASAN_NUSANTARA_BYID_REQUEST, GET_PENUGASAN_NUSANTARA_BYID_SUCCESS, GET_PENUGASAN_NUSANTARA_FAILURE, GET_PENUGASAN_NUSANTARA_REQUEST,
    GET_PENUGASAN_NUSANTARA_SUCCESS, UPDATE_PENUGASAN_NUSANTARA_FAILURE, UPDATE_PENUGASAN_NUSANTARA_REQUEST,
    UPDATE_PENUGASAN_NUSANTARA_SUCCESS
} from "./actionTypes";

export const createPenugasanNusantara = (request = {}, cb = () => { }) => {
    return {
        type: CREATE_PENUGASAN_NUSANTARA_REQUEST,
        payload: request,
        cb
    };
}

export const createPenugasanNusantaraSuccess = response => {
    return {
        type: CREATE_PENUGASAN_NUSANTARA_SUCCESS,
        payload: response,
    };
}

export const createPenugasanNusantaraFailure = (message, response) => {
    return {
        type: CREATE_PENUGASAN_NUSANTARA_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanNusantara = (request = {}) => {
    return {
        type: GET_PENUGASAN_NUSANTARA_REQUEST,
        payload: request,
    };
}

export const getPenugasanNusantaraSuccess = response => {
    return {
        type: GET_PENUGASAN_NUSANTARA_SUCCESS,
        payload: response,
    };
}

export const getPenugasanNusantaraFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_NUSANTARA_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getPenugasanNusantaraById = (id, cb = () => { }) => {

    return {
        type: GET_PENUGASAN_NUSANTARA_BYID_REQUEST,
        payload: { id },
        cb
    };
}

export const getPenugasanNusantaraByIdSuccess = response => {
    return {
        type: GET_PENUGASAN_NUSANTARA_BYID_SUCCESS,
        payload: response,
    };
}

export const getPenugasanNusantaraByIdFailure = (message, response) => {
    return {
        type: GET_PENUGASAN_NUSANTARA_BYID_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const updatePenugasanNusantara = (request = {}, id, cb = () => { }) => {
    return {
        type: UPDATE_PENUGASAN_NUSANTARA_REQUEST,
        payload: request,
        cb,
        id
    };
}

export const updatePenugasanNusantaraSuccess = response => {
    return {
        type: UPDATE_PENUGASAN_NUSANTARA_SUCCESS,
        payload: response,
    };
}

export const updatePenugasanNusantaraFailure = (message, response) => {
    return {
        type: UPDATE_PENUGASAN_NUSANTARA_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}