import {
  GET_KOMPONEN_PENILAIAN_LUARAN_FAILURE,
  GET_KOMPONEN_PENILAIAN_LUARAN_REQUEST,
  GET_KOMPONEN_PENILAIAN_LUARAN_SUCCESS,
  SAVE_KOMPONEN_PENILAIAN_LUARAN_FAILURE,
  SAVE_KOMPONEN_PENILAIAN_LUARAN_REQUEST,
  SAVE_KOMPONEN_PENILAIAN_LUARAN_SUCCESS
} from "./actionTypes";


export const saveKomponenPenilaianLuaran = (request = {}, cb = () => {}) => {
  return {
    type: SAVE_KOMPONEN_PENILAIAN_LUARAN_REQUEST,
    payload: request,
    cb
  };
};

export const saveKomponenPenilaianLuaranSuccess = response => {
  return {
    type: SAVE_KOMPONEN_PENILAIAN_LUARAN_SUCCESS,
    payload: response,
  };
};

export const saveKomponenPenilaianLuaranFailure = (message, response) => {
  return {
    type: SAVE_KOMPONEN_PENILAIAN_LUARAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getKomponenPenilaianLuaran = (request = {},) => {
  return {
    type: GET_KOMPONEN_PENILAIAN_LUARAN_REQUEST,
    payload: request,

  };
};

export const getKomponenPenilaianLuaranSuccess = response => {
  return {
    type: GET_KOMPONEN_PENILAIAN_LUARAN_SUCCESS,
    payload: response,
  };
};

export const getKomponenPenilaianLuaranFailure = (message, response) => {
  return {
    type: GET_KOMPONEN_PENILAIAN_LUARAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
