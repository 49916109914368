import {
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_FAILURE,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_REQUEST,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_SUCCESS,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_FAILURE,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_REQUEST,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_SUCCESS,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_FAILURE,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_REQUEST,
  GET_LIST_INSTITUSI_PENILAIAN_LUARAN_SUCCESS,
  SAVE_PENILAIAN_LUARAN_EVALUASI_FAILURE,
  SAVE_PENILAIAN_LUARAN_EVALUASI_REQUEST,
  SAVE_PENILAIAN_LUARAN_EVALUASI_SUCCESS,
  SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_REQUEST,
  SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_SUCCESS,
  SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_FAILURE,
  CLEAN_LIST_PENILAIAN_LUARAN,
} from "./actionTypes";

export const cleanListInstitusPenilaianLuaran = () => {
  return {
    type: CLEAN_LIST_PENILAIAN_LUARAN,
  };
};

export const getListInstitusiPenilaianLuaran = (request = "") => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_REQUEST,
    payload: request,
  };
};

export const getListInstitusiPenilaianLuaranSuccess = response => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_SUCCESS,
    payload: response,
  };
};

export const getListInstitusiPenilaianLuaranFailure = (message, response) => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const getListInstitusiPenilaianLuaranDetail1 = (request = "") => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_REQUEST,
    payload: request,
  };
};

export const getListInstitusiPenilaianLuaranDetail1Success = response => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_SUCCESS,
    payload: response,
  };
};

export const getListInstitusiPenilaianLuaranDetail1Failure = (
  message,
  response
) => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_1_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const getListInstitusiPenilaianLuaranDetail2 = (request = "") => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_REQUEST,
    payload: request,
  };
};

export const getListInstitusiPenilaianLuaranDetail2Success = response => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_SUCCESS,
    payload: response,
  };
};

export const getListInstitusiPenilaianLuaranDetail2Failure = (
  message,
  response
) => {
  return {
    type: GET_LIST_INSTITUSI_PENILAIAN_LUARAN_DETAIL_2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const savePenilaianLuaranEvaluasi = (request = {}, cb = () => {}) => {
  return {
    type: SAVE_PENILAIAN_LUARAN_EVALUASI_REQUEST,
    payload: request,
    cb,
  };
};

export const savePenilaianLuaranEvaluasiSuccess = response => {
  return {
    type: SAVE_PENILAIAN_LUARAN_EVALUASI_SUCCESS,
    payload: response,
  };
};

export const savePenilaianLuaranEvaluasiFailure = (message, response) => {
  return {
    type: SAVE_PENILAIAN_LUARAN_EVALUASI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const savePenilaianLuaranToPermanen = (request = {}, cb = () => {}) => {
  return {
    type: SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_REQUEST,
    payload: request,
    cb,
  };
};

export const savePenilaianLuaranToPermanenSuccess = response => {
  return {
    type: SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_SUCCESS,
    payload: response,
  };
};

export const savePenilaianLuaranToPermanenFailure = (message, response) => {
  return {
    type: SAVE_PENILAIAN_LUARAN_EVALUASI_TO_PERMANEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
