import {
  GET_LIST_MINAT_KATALIS_OPT_REQUEST,
  GET_LIST_MINAT_KATALIS_OPT_SUCCESS,
  GET_LIST_MINAT_KATALIS_OPT_FAILURE,
  UPDATE_MINAT_KATALIS_OPT_REQUEST,
  UPDATE_MINAT_KATALIS_OPT_SUCCESS,
  UPDATE_MINAT_KATALIS_OPT_FAILURE,
} from "./actionTypes";

const initialState = {
  minatKatalis: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  updateMinatKatalis: {
    item: {},
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
};

const operatorCommon = (state = initialState, action) => {
  switch (action.type) {
    // Minat Katalis
    case GET_LIST_MINAT_KATALIS_OPT_REQUEST:
      return (state = {
        ...state,
        minatKatalis: {
          ...state.minatKatalis,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case GET_LIST_MINAT_KATALIS_OPT_SUCCESS:
      return (state = {
        ...state,
        minatKatalis: {
          ...state.minatKatalis,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_LIST_MINAT_KATALIS_OPT_FAILURE:
      return (state = {
        ...state,
        minatKatalis: {
          ...state.minatKatalis,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    //
    case UPDATE_MINAT_KATALIS_OPT_REQUEST:
      return (state = {
        ...state,
        updateMinatKatalis: {
          ...state.updateMinatKatalis,
          isFetching: true,
          item: {},
          items: [],
        },
      });
    case UPDATE_MINAT_KATALIS_OPT_SUCCESS:
      return (state = {
        ...state,
        updateMinatKatalis: {
          ...state.updateMinatKatalis,
          isFetching: false,
          item: action.payload,
        },
      });
    case UPDATE_MINAT_KATALIS_OPT_FAILURE:
      return (state = {
        ...state,
        updateMinatKatalis: {
          ...state.updateMinatKatalis,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    default:
      return state;
  }
};

export default operatorCommon;
