import React from "react";
import { Badge, Button, CardHeader } from "reactstrap";
import propTypes from "prop-types";

const CardHeaderListUsulan = ({
  shownDownloadPenilaian = true,
  skema,
  thnPelaksanaan,
  permanent = false,
  onClickPermanent,
  loading,
  isFetching = false,
  downloadnPenilaian = () => { },
  jumlahProposal = null,
  jumlahSudahDinilai = null,
  jenisKegiatan
}) => {
  return (
    <CardHeader
      style={{
        background: "#E0E6F5",
        borderTopRightRadius: "3.25px",
        borderTopLeftRadius: "3.25px",
      }}
    >
      {isFetching && (
        <div>
          <h5 className="text-primary mt-1">Loading...</h5>
        </div>
      )}
      {!isFetching && (
        <div className="d-flex justify-content-between">
          <div className="">
            <h5 className="text-primary m-0 mb-2">{skema}</h5>
            <p className="mb-0" >
              Status Permanen {permanent ? <Badge color="danger" ><span className="fa fa-lock" />{" "}Permanen</Badge> : <Badge color="success" ><span className="fa fa-unlock" />{" "}Terbuka</Badge>} |  Jenis Kegiatan : <b>{jenisKegiatan}</b>
            </p>
          
            <h6 className="mt-2">
              {`Tahun Pelaksanaan ${thnPelaksanaan}`}
            </h6>
            {jumlahProposal !== null && jumlahSudahDinilai !== null && (

              <h6 className="mt-2 font-size-14">
                {`Jumlah proposal yang sudah dinilai `}{" "}
                <b>{jumlahSudahDinilai} </b> dari <b>{jumlahProposal}</b>
              </h6>
            )}
          </div>
          <div className="d-flex justify-content-end flex-column ">
            <div>
              {shownDownloadPenilaian && (
                <Button
                  onClick={downloadnPenilaian}
                  size="sm"
                  className="btn btn-sm btn-danger"
                  disabled={loading?.btnDownloadNilai}
                >
                  {" "}
                  {loading?.btnDownloadNilai ? (
                    <span className="fa fa-spinner fa-spin" />
                  ) : (
                    <i className="fas fa-file-pdf"></i>
                  )}{" "}
                  Unduh Hasil Penilaian
                </Button>
              )}
              <Button
                size="sm"
                onClick={onClickPermanent}
                className="btn btn-sm ms-1"
                disabled={loading?.btnPermanent || permanent}
                color={
                  loading?.btnPermanent || permanent ? "secondary" : "primary"
                }
              >
                {loading?.btnPermanent ? (
                  <span className="fa fa-spinner fa-spin" />
                ) : (
                  <i className="fas fa-lock"></i>
                )}{" "}
                {loading?.btnPermanent || permanent ? "" : "Simpan"} Permanen
              </Button>
            </div>
          </div>
        </div>
      )}
    </CardHeader>
  );
};

export default CardHeaderListUsulan;

CardHeaderListUsulan.propTypes = {
  shownDownloadPenilaian: propTypes.bool,
  tahapan: propTypes.string,
  skema: propTypes.string,
  jenisKegiatan: propTypes.string,
  thnUsulan: propTypes.any,
  thnPelaksanaan: propTypes.any,
  status: propTypes.element,
  permanent: propTypes.any,
  isFetching: propTypes.bool,
  onClickPermanent: propTypes.func,
  downloadnPenilaian: propTypes.func,
  loading: propTypes.object,
  jumlahProposal: propTypes.number,
  jumlahSudahDinilai: propTypes.number,
};
