import { instances } from "../api_helper";
import * as url from "../url_helper";
import KosabangsaPelaksanaPdf from "helpers/workers/pdf/kosabangsaPelaksana";
import KosabangsaPendampingPdf from "helpers/workers/pdf/kosabangsa/pendamping";
import KosabangsaProposalPdf from "helpers/workers/pdf/kosabangsa/proposal";
import KosabangsaProposal2024Pdf from "helpers/workers/pdf/kosabangsa2024";
import { toast } from "react-toastify";

export const getDosenKosabangsa = ({ nidn = null }) => {
  const params = nidn ? `?criteria=nidn:${nidn}` : "";
  return instances.request(`${url.ENDPOINT_DOSEN}${params}`, {
    method: "GET",
  });
};

export const getBidangFokusPrioritasKosabangsa = () => {
  return instances.request(
    `/enumeration?criteria=group(exact):kosabangsa-bidang-fokus-prioritas`,
    {
      method: "GET",
    }
  );
};

export const getBidangFokusLainnyaKosabangsa = () => {
  return instances.request(
    `/enumeration?criteria=group(exact):kosabangsa-bidang-fokus-lainnya`,
    {
      method: "GET",
    }
  );
};

export const getProvinsiKosabangsa = () => {
  return instances.request(`/pdpt/provinsi?page=1:1000`, {
    method: "GET",
  });
};

export const getKabupatenKotaKosabangsa = ({ kode_provinsi }) => {
  return instances.request(
    `/pdpt/kota?criteria=kode_provinsi:${kode_provinsi}&page=1:1000`,
    {
      method: "GET",
    }
  );
};

export const getKelompokMitraKosabangsa = () => {
  return instances.request(
    `/enumeration?criteria=group:kosabangsa-kelompok-mitra`,
    {
      method: "GET",
    }
  );
};

export const uploadDokumenKosabangsa = async payload => {
  const formData = new FormData();
  const path = payload.path;
  formData.append("file", payload.file);
  formData.append("path", path);
  const res = await instances.request(`/file`, {
    data: formData,
    method: "POST",
  });
  return res;
};

export const getJenisPiminanPenetap = _payload => {
  return instances.request(
    `/enumeration?criteria=group(exact):kosabangsa-jenis-pimpinan-penetap&page=1:1000`,
    {
      method: "GET",
    }
  );
};

export const saveKosabangsa = payload => {
  const type = payload.type;
  delete payload["type"];

  return instances.request(`/kosabangsa/${type}`, {
    data: payload,
    method: "POST",
  });
};

export const getEligibilityKosabangsa = payload => {
  const type = payload.type;
  let params = "";
  if (payload.anggota) {
    params += `anggota=${payload.anggota}`;
  }
  if (payload.id_personal) {
    params += `&id_personal_anggota=${payload.id_personal}`;
  }
  return instances.request(
    `/kosabangsa/${type}/eligibility${params ? "?" + params : ""}`,
    {
      method: "GET",
    }
  );
};

export const putKosabangsa = payload => {
  const type = payload.type;
  const id = payload.id;
  delete payload["type"];
  delete payload["id"];
  return instances.request(`/kosabangsa/${type}/${id}`, {
    data: payload,
    method: "PUT",
  });
};
export const deleteKosabangsa = payload => {
  const type = payload.type;
  const id = payload.id;
  return instances.request(`/kosabangsa/${type}/${id}`, {
    method: "DELETE",
  });
};

export const getKosabangsa = payload => {
  const id = payload?.id;
  const type = payload?.type;
  const search = payload?.search;
  const criteria = payload?.criteria;

  let endpoint = `/kosabangsa/${type}`;
  if (search) {
    endpoint += `/search`;
  }

  if (id) {
    endpoint += `/${id}`;
  }

  if (criteria) {
    endpoint += `?criteria=`;
    if (criteria?.status) endpoint += `status:${criteria?.status}`;
    if (criteria?.kd_approval && criteria?.kd_approval !== "all")
      endpoint += `,kd_approval:${criteria?.kd_approval}`;
    endpoint += `&page=1:1000`;
  }

  return instances.request(endpoint, {
    method: "GET",
  });
};

export const getKosabangsaPelaksanaListPendamping = ({
  id_bidang_fokus,
  bidang_fokus_type,
  page = 1,
  limit = 10,
  nama_teknologi = "",
}) => {
  const endpoint = `/kosabangsa/pendamping/search?criteria=kd_approval:1,raw(exact):id_bidang_fokus->${id_bidang_fokus},raw(exact):bidang_fokus_type->${bidang_fokus_type},raw:nama_teknologi->${nama_teknologi}&page=${page}:${limit}`;

  return instances.request(endpoint, {
    method: "GET",
  });
};

export const approvalOPPusatKosabangsa = ({ type, id, approval }) => {
  const endpoint = `/kosabangsa/${type}/operator-approval/${id}`;

  return instances.request(endpoint, {
    method: "PATCH",
    data: {
      approval,
    },
  });
};

export const getKosabangsaMatched = () => {
  const endpoint = `/kosabangsa/pelaksana/matched`;

  return instances.request(endpoint, {
    method: "GET",
  });
};

export const downloadPendaftaranPendamping = async (
  item,
  cb = () => {},
  flag = 1
) => {
  try {
    const fileName = `${item?.raw?.nama_teknologi
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .trim()}`;
    //generate pdf
    const kosabangsaPendampingPdf = new KosabangsaPendampingPdf(item);
    const fileBlobPdfGen = await kosabangsaPendampingPdf.generate();
    const fileBlobPdfFromUrl = await kosabangsaPendampingPdf.getMultiplefile();

    const mergedPdf = await kosabangsaPendampingPdf.merging([
      fileBlobPdfGen,
      ...fileBlobPdfFromUrl,
    ]);

    if (flag !== 2) {
      kosabangsaPendampingPdf.forceDownload(mergedPdf, fileName + ".pdf");
    }

    cb({
      success: true,
      data: mergedPdf,
    });
  } catch (error) {
    console.log(error);
    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};

export const handleDownloadProposalKosabangsaPelaksana = async (
  item,
  isForceDowload = true,
  cb = () => {}
) => {
  try {
    const fileName = `${item?.raw?.nama_teknologi
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .trim()}`;
    const proposalPDF = new KosabangsaPelaksanaPdf(item);
    const blobFileFromClient = await proposalPDF.generate();
    const blobFileFromServer = await proposalPDF.getMultiplefile(item);
    const mergedPdf = await proposalPDF.mergingv2([
      blobFileFromClient,
      ...blobFileFromServer,
    ]);
    if (isForceDowload) {
      proposalPDF.forceDownload(mergedPdf, fileName + ".pdf");
    }
    cb({
      success: true,
      data: mergedPdf,
    });
  } catch (err) {
    console.log(err);
    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};

export const downloadKosabangsaProposal = async (data, cb, flag = 1) => {
  let {
    raw: { identitas },
  } = data;
  try {
    const fileName = `${identitas?.judul.replace(/[^a-zA-Z0-9 ]/g, "").trim()}`;
    // * Generate Pdf From Client
    const proposalPDF = new KosabangsaProposalPdf({
      ...data,
    });
    const blobFileMainContent = await proposalPDF.generate();
    const blobFilePersetContent = await proposalPDF.generatePersetujuan();
    // * Get Pdf File From Server
    const blobFileFromServer = await proposalPDF.getMultiplefile();

    const contentMerge = [
      blobFileMainContent,
      blobFilePersetContent,
      ...blobFileFromServer,
    ];

    const mergingFun = () => {
      //merging version 1 for merge common files
      //merging version 2 for merge files ignoring encrypted file
      //merging version 3 for merge files ignoring encrypted file & lost merged the file
      proposalPDF.mergingv3(contentMerge, result => {
        const resultMerged = result.res;
        if (result.error === false) {
          if (flag !== 2) {
            proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
          }
          cb({
            success: true,
            data: resultMerged,
          });
        } else {
          mergingFun();
          toast.warn("Beberapa file tidak dapat di gabung!");
          proposalPDF.forceDownload(
            contentMerge[result.indexBlobSkipped],
            `file_${result.indexBlobSkipped - 1}.pdf`
          );
        }
      });
    };

    mergingFun();
  } catch (error) {
    console.log(error);

    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};

export const downloadKosabangsaProposal2024 = async (data, cb, flag = 1) => {
  let {
    raw: { identitas },
  } = data;
  try {
    const fileName = `${identitas?.judul.replace(/[^a-zA-Z0-9 ]/g, "").trim()}`;
    // * Generate Pdf From Client
    const proposalPDF = new KosabangsaProposal2024Pdf({
      ...data,
    });
    const blobFileMainContent = await proposalPDF.generate();
    const blobFilePersetContent = await proposalPDF.generatePersetujuan();
    // * Get Pdf File From Server
    const blobFileFromServer = await proposalPDF.getMultiplefileKosbang2024();

    const contentMerge = [
      blobFileMainContent,
      ...blobFileFromServer,
      blobFilePersetContent,
    ];

    const mergingFun = () => {
      //merging version 1 for merge common files
      //merging version 2 for merge files ignoring encrypted file
      //merging version 3 for merge files ignoring encrypted file & lost merged the file
      proposalPDF.mergingv3(contentMerge, result => {
        const resultMerged = result.res;
        if (result.error === false) {
          if (flag !== 2) {
            proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
          }
          cb({
            success: true,
            data: resultMerged,
          });
        } else {
          mergingFun();
          toast.warn("Beberapa file tidak dapat di gabung!");
          proposalPDF.forceDownload(
            contentMerge[result.indexBlobSkipped],
            `file_${result.indexBlobSkipped - 1}.pdf`
          );
        }
      });
    };

    mergingFun();
  } catch (error) {
    console.log(error);

    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};

export { downloadKosabangsaProposal as downloadKosabangsaProposal2023 };
