import stylePDF from "./styles";
import header from "./header";
import utilities from "helpers/utilities";
import { getBase64ImageFromURL } from ".";

const buildTblIdentitas = (data = {}) => {
    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: _i => 0.5,
            padding: () => 0,
        },
        table: {
            widths: ["14%", "32%", "13%", "13%", "13%", "15%"],
            body: [
                [
                    {
                        text: `Bidang Prototipe`,
                        style: ["center"],
                    },
                    {
                        text: `Bidang Fokus Prototipe`,
                        style: ["center"],
                    },
                    {
                        text: `Tahun Pelaksanaan`,
                        style: ["center"],
                    },
                    {
                        text: `Lama Kegiatan`,
                        style: ["center"],
                    },
                    {
                        text: `TKT Saat Ini`,
                        style: ["center"],
                    },
                    {
                        text: `Target Akhir TKT`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    layout.table.body.push([
        {
            text: data.nama_bidang_prototipe,
            style: ["center"],
        },
        {
            text: data.nama_bidang_fokus,
            style: ["center"],
        },
        {
            text: data.tahun_pelaksanaan,
            style: ["center"],
        },
        {
            text: data.lama_kegiatan + "  Tahun",
            style: ["center"],
        },
        {
            text: data?.tkt?.tkt_current,
            style: ["center"],
        },
        {
            text: data?.tkt?.tkt_target,
            style: ["center"],
        },
    ]);

    return layout;
};

const buildTblAnggota = (personil = []) => {
    const layout = {
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["22%", "13%", "25%", "30%", "10%"],
            body: [
                [
                    {
                        text: `Nama, Peran, Institusi`,
                        style: ["center"],
                    },
                    {
                        text: `Jenis Keanggotaan`,
                        style: ["center"],
                    },
                    {
                        text: `Program Studi/Bidan Keahlian`,
                        style: ["center"],
                    },
                    {
                        text: `Bidang Tugas`,
                        style: ["center"],
                    },
                    {
                        text: `ID Sinta`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    personil
        .sort((a, b) => (a.urutan > b.urutan ? 1 : b.urutan > a.urutan ? -1 : 0))
        .forEach(item => {
            layout.table.body.push([
                {
                    text: `${item.nama || item?.raw?.nama} \n\n\n ${item.nama_peran || "Anggota"} \n\n ${item?.raw?.institusi || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.nidn ? "Dosen" : "Umum"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.raw?.prodi || item?.raw?.bidang_keahlian || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.uraian_tugas || item?.raw?.uraian_tugas || "-"}`,
                },
                {
                    text: `${item?.id_sinta || ""}`,
                    link: `${item?.id_sinta ? `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}` : "#"}`,
                    style: ['link', 'center']
                },
            ]);
        });

    return layout;
};

const buildTblKetua = (personil = [], rumpun_ilmu = "") => {
    const layout = {
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["22%", "13%", "25%", "30%", "10%"],
            body: [
                [
                    {
                        text: `Nama, Institusi`,
                        style: ["center"],
                    },
                    {
                        text: `Program Studi`,
                        style: ["center"],
                    },
                    {
                        text: `Bidang Tugas`,
                        style: ["center"],
                    },
                    {
                        text: `Rumpun Ilmu`,
                        style: ["center"],
                    },
                    {
                        text: `ID Sinta`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    personil
        .forEach(item => {
            layout.table.body.push([
                {
                    text: `${item.nama || item?.raw?.nama} \n\n\n ${item?.raw?.institusi || "-"}`,
                    style: ["center"],
                },

                {
                    text: `${item?.raw?.prodi || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.uraian_tugas || item?.raw?.uraian_tugas || "-"}`,
                },
                {
                    text: `${rumpun_ilmu}`,
                },
                {
                    text: `${item?.id_sinta || ""}`,
                    link: `${item?.id_sinta ? `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}` : "#"}`,
                    style: ['link', 'center']
                },
            ]);
        });

    return layout;
};

const buildTblAnggotaMhs = (personil = []) => {
    const layout = {
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["15%", "20%", '20%', "20%", "25%"],
            body: [
                [
                    {
                        text: 'NIM',
                        style: ["center"],
                    },
                    {
                        text: `Nama`,
                        style: ["center"],
                    },

                    {
                        text: `Institusi`,
                        style: ["center"],
                    },
                    {
                        text: `Program Studi`,
                        style: ["center"],
                    },
                    {
                        text: `Bidang Tugas`,
                        style: ["center"],
                    },

                ],
            ],
        },
    };

    personil
        .sort((a, b) => (a.urutan > b.urutan ? 1 : b.urutan > a.urutan ? -1 : 0))
        .forEach(item => {
            layout.table.body.push([
                {
                    text: item.raw?.nim,
                    style: ["center"],
                },
                {
                    text: `${item?.raw?.nama}`,
                    style: ["center"],
                },
                {
                    text: item?.raw?.institusi || "-",
                    style: ["center"],
                },
                {
                    text: `${item?.raw?.prodi || item?.raw?.bidang_keahlian || "-"}`,
                    style: ["center"],
                },
                {
                    text: `${item?.raw?.uraian_tugas || "-"}`,
                },

            ]);
        });

    return layout;
};

const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
    let thnAkhirUsulan = parseInt(thnPertamaUsulan);
    for (let tahun = 1; tahun <= parseInt(lamaKegiatan); tahun++) {
        thnAkhirUsulan += tahun;
    }
    return thnAkhirUsulan;
};

const buildTblLuaran = (luaran = []) => {
    const layout = {
        style: ["center"],
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 1,
        },
        table: {
            widths: ["5%", "24%", "28%", "18%", "25%"],
            body: [
                [
                    {
                        text: "No.",
                        style: ["center"],
                    },
                    {
                        text: "Kategori Luaran",
                        style: ["center"],
                    },
                    {
                        text: "Jenis Luaran",
                        style: ["center"],
                    },
                    {
                        text: "Status target capaian",
                        style: ["center"],
                    },
                    {
                        text: "Keterangan",
                    },
                ],
            ],
        },
    };
    luaran.forEach((item, i) => {
        layout.table.body.push([
            {
                text: i + 1,
            },
            {
                text: item.raw?.kategori_luaran,
            },
            {
                text: item.raw?.jenis_luaran,
            },
            {
                text: item.raw?.status_capaian,
            },
            {
                text: item?.keterangan || "-",
            },
        ]);
    });
    return layout;
};

const buildTblAnggaran = (data = []) => {
    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["23%", "15%", "20%", "10%", "5%", "12%", "15%"],
            body: [
                [
                    {
                        text: `Jenis Pembelanjaan`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Komponen`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Item`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Satuan`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Vol.`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Biaya Satuan`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                    {
                        text: `Total`,
                        style: ["center", "bgColorHeaderTable"],
                    },
                ],
            ],
        },
    };

    data.forEach(item => {
        const volume = parseInt(item.volume);
        const harga_satuan = utilities.unmaskMoney(item.harga_satuan);
        const total = volume * harga_satuan;

        layout.table.body.push([
            {
                text: `${item?.raw?.nama_kelompok_rab}`,
            },
            {
                text: `${item?.raw?.rab_komponen_belanja}`,
                style: ["center"],
            },
            {
                text: `${item.nama_item}`,
                style: ["center"],
            },
            {
                text: `${item.satuan || "-"}`,
                style: ["center"],
            },
            {
                text: `${volume || "-"}`,
                style: ["center"],
            },
            {
                text: `${utilities.toCurrency(harga_satuan || 0, ".", 0)}`,
                style: ["right"],
            },
            {
                text: `${utilities.toCurrency(total || 0, ".", 0)}`,
                style: ["right"],
            },
        ]);
    });

    return layout;
};

const content = (data, _pageBreak = false) => {
    const { hr } = stylePDF;
    const {
        raw: {
            identitas,
        }
    } = data;

    return [
        header(),
        hr(5, 0),
        {
            stack: [
                {
                    text: "PROTEKSI ISI PROPOSAL",
                    style: ["bold"],
                },
                {
                    text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
                    style: ["fontSizeXs"],
                },
                {
                    text: `PROPOSAL PROTOTIPE ${identitas?.tahun_pelaksanaan ?? ""
                        }`,
                    color: "#FFFFFF",
                    background: "#000",
                    style: ["center", "bold"],
                },
                {
                    text: `Rencana Pelaksanaan Prototipe: tahun ${identitas?.tahun_pelaksanaan ?? ""
                        } s.d. tahun ${getThnAkhirUsulan(
                            identitas.lama_kegiatan,
                            identitas.tahun_pelaksanaan
                        )}`,
                    style: ["fontSizeXs"],
                },
            ],
            style: ["center", "mb-5"],
        },
        ...contentPrototipe(data),
        {

            style: ["mt-10"],
            stack: contentAnggaranRevisi(data)
        }
    ];
};

export const contentPrototipe = (data) => {
    const {
        personil,
        personil_non_dosen,
        luaran,
        rab,
        raw: {
            identitas,
        }
    } = data;

    const anggota1 = (personil || []).filter(row => row.kode_program_pendidikan?.toString() !== "2" && row?.raw?.anggota === 1 && row.kode_peran !== "A");
    const ketuaTim = (personil || []).filter(row => row.kode_peran === "A");
    const anggota2 = (personil_non_dosen || []).filter(item => item.kind === "umum").concat((personil || []).filter(row => row?.raw?.anggota === 2));

    const totalAnggaran = (rab || []).reduce(
        (a, { harga_satuan, volume }) =>
            a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
        0
    );

    return [
        {
            stack: [
                {
                    text: "1. JUDUL PROTOTIPE",
                    style: ["bold"],
                },
                {
                    style: ["text", "mb-10"],
                    layout: {
                        hLineWidth: (_i, _node) => 0.3,
                        vLineWidth: _i => 0.3,
                        padding: () => 1,
                    },
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: identitas?.judul,
                                },
                            ],
                        ],
                    },
                },

                buildTblIdentitas(identitas),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "2. IDENTITAS KETUA",
                    style: ["bold"],
                },
                buildTblKetua(ketuaTim,  identitas?.rumpun_ilmu_lvl_2),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "3. IDENTITAS TIM ANGGOTA",
                    style: ["bold"],
                },
                buildTblAnggota(anggota1),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "4. IDENTITAS TIM ANGGOTA 2",
                    style: ["bold"],
                },
                buildTblAnggota(anggota2),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "5. IDENTITAS ANGGOTA MAHASISWA",
                    style: ["bold"],
                },
                buildTblAnggotaMhs((personil_non_dosen || []).filter(item => item.kind === "mahasiswa")),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "6. LUARAN DAN TARGET CAPAIAN",
                    style: ["bold"],
                },
                {
                    text: "Luaran Wajib",
                    style: ["bold", "mt-10"],
                },
                buildTblLuaran(luaran || []),
            ],
            style: ["mb-10"],
        },
        {
            stack: [
                {
                    text: "7. ANGGARAN",
                    style: ["bold", "mb-10"],
                },
                {
                    text: `Total RAB 1 Tahun ${utilities.toCurrency(totalAnggaran, ".", 2, "Rp. ")}`,
                    style: ["bold"],
                },
                buildTblAnggaran(rab || []),
            ],
            style: ["mb-10"],
        },
    ]
}

export const contentAnggaranRevisi = (data = {}) => {
    const rab_revisi = data?.rab_revisi;

    if (JSON.stringify(data) === "{}" || rab_revisi === null || !rab_revisi || rab_revisi === undefined || rab_revisi?.length === 0) {
        return [];
    } else {


        const getTotalRab = () => {
            const totalAnggaran = rab_revisi?.reduce((x, y) => x + (y.harga_satuan * y.volume), 0);
            return totalAnggaran;
        }

        return [
            {
                text: "6. ANGGARAN PERBAIKAN",
                style: ["bold", "mb-10"],

            },

            {

                text: [
                    `Dana Tahun ke-1 : Direncanakan `,
                    {
                        text: `${utilities.toCurrency(getTotalRab(), '.', 2, 'Rp')}`,
                        style: ["bold"],
                    },
                    ' | Disetujui ',
                    {
                        text: `${utilities.toCurrency(data?.dana_disetujui_thn_1, ".", 2, 'Rp')}`,
                        style: ["bold"],
                    },

                ],
            },
            {
                ...buildTblAnggaran(rab_revisi.filter(row => row.harga_satuan !== 0))
            }
        ];
    }

}

const contentPrototipeImage = async (urlImage) => {
    return {
        stack: [
            {
                text: "Foto/Design Prototipe",
                style: ["bold", "fontSizeXl"],
            },
            {
                image: await getBase64ImageFromURL(
                    urlImage
                )
            }

        ],
    }
}

const contentPersetujuan = (data = {}) => {
    let { approval_raw, approval_status } = data;
    approval_raw = approval_raw ?? {};

    const layout = {
        style: ["mb-10"],
        layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
        },
        table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*"],
            body: [
                [
                    {
                        text: `Tanggal Pengiriman`,
                        style: ["center"],
                    },
                    {
                        text: `Tanggal Persetujuan`,
                        style: ["center"],
                    },
                    {
                        text: `Nama Pimpinan Pemberi Persetujuan`,
                        style: ["center"],
                    },
                    {
                        text: `Sebutan Jabatan Unit`,
                        style: ["center"],
                    },
                    {
                        text: `Nama Unit Lembaga Pengusul`,
                        style: ["center"],
                    },
                ],
            ],
        },
    };

    layout.table.body.push([
        {
            text: `${data?.tgl_updated
                ? utilities.convertDate(data?.tgl_updated, "DD/MM/YYYY")
                : "-"
                }`,
            style: ["center"],
        },
        {
            text: `${approval_raw?.approval_date
                ? utilities.convertDate(approval_raw?.approval_date, "DD/MM/YYYY")
                : "-"
                }`,
            style: ["center"],
        },
        {
            text: `${approval_raw?.full_name || "-"}`,
            style: ["center"],
        },
        {
            text: `${approval_raw?.nama_jabatan || "-"}`,
            style: ["center"],
        },
        {
            text: `${approval_raw?.nama_lembaga || "-"}`,
            style: ["center"],
        },
    ]);

    return [
        {
            stack: [
                {
                    text: "PERSETUJUAN PENGUSUL",
                    style: ["bold"],
                },
                {
                    ...layout,
                },
            ],
        },
        {
            style: ["mt-10"],
            stack: [
                {
                    text: `Komentar : ${approval_status === "approved" ? "Disetujui" : approval_status === "rejected" ? "Ditolak" : "-"}`,
                    style: ["bold", "mb-1"],
                },
                {
                    style: ["text"],
                    layout: {
                        hLineWidth: (_i, _node) => (approval_raw?.comment ? 0.3 : 0),
                        vLineWidth: _i => (approval_raw?.comment ? 0.3 : 0),
                        padding: () => 1,
                    },
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                {
                                    text: approval_raw?.comment,
                                },
                            ],
                        ],
                    },
                },
            ],
        },
    ];
};

export { content, contentPrototipeImage, contentPersetujuan };
