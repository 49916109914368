/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from "redux-saga/effects";

// Backend
import * as backend from "../../helpers/backend";
// Action Types
import {
  CATATAN_HARIAN_CREATE_REQUEST,
  CATATAN_HARIAN_DELETE_BERKAS_REQUEST,
  CATATAN_HARIAN_DELETE_REQUEST,
  CATATAN_HARIAN_EDIT_REQUEST,
  CATATAN_HARIAN_REQUEST,
  CATATAN_HARIAN_UPLOAD_REQUEST,
  CRUD_CATATAN_HARIAN_V2_REQUEST,
} from "./actionTypes";
// Action
import {
  getCatatanHarianSuccess,
  getCatatanHarianFailure,
  editCatatanHarianSuccess,
  createCatatanHarianSuccess,
  deleteCatatanHarianSuccess,
  uploadCatatanHarianSuccess,
  deleteBerkasCatatanHarianSuccess,
  crudCatatanHarianV2Success,
  crudCatatanHarianV2Failure,
} from "./action";
import { toast } from "react-toastify";

function* getCatatanHarian({ payload, cb }) {
  try {
    const response = yield call(backend.getCatatanHarian, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
      yield put(getCatatanHarianSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    yield put(getCatatanHarianFailure(message, error));
  }
}

function* createCatatanHarian({ payload, cb }) {
  try {
    const response = yield call(backend.createCatatanHarian, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
      yield put(createCatatanHarianSuccess(response.data.data));
      toast.success("Berhasil membuat catatan harian!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    cb(null);
    yield put(getCatatanHarianFailure(message, error));
  }
}

function* deleteCatatanHarian({ payload }) {
  try {
    const response = yield call(backend.deleteCatatanHarian, payload);
    if (response.data.code == 200) {
      yield put(deleteCatatanHarianSuccess(payload));
      toast.success("Berhasil menghapus catatan harian!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getCatatanHarianFailure(message, error));
  }
}

function* editCatatanHarian({ payload, cb }) {
  try {
    const response = yield call(backend.editCatatanHarian, payload);
    if (response.data.code == 200) {
      cb(response.data.data);
      yield put(editCatatanHarianSuccess(response.data.data));
      toast.success("Berhasil mengubah catatan harian!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    toast.error(message);
    yield put(getCatatanHarianFailure(message, error));
  }
}

function* uploadCatatanHarian({ payload }) {
  try {
    const response = yield call(backend.uploadCatatanHarian, payload);
    if (response.data.code == 200) {
      yield put(uploadCatatanHarianSuccess(response.data.data));
      toast.success("Berhasil mengunggah berkas catatan harian!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getCatatanHarianFailure(message, error));
  }
}

function* deleteBerkasCatatanHarian({ payload }) {
  try {
    const response = yield call(backend.deleteBerkasCatatanHarian, payload);
    if (response.data.code == 200) {
      yield put(deleteBerkasCatatanHarianSuccess(payload));
      toast.success("Berhasil menghapus berkas catatan harian!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getCatatanHarianFailure(message, error));
  }
}
// catatan harian v2
function* crudCatatanHarianV2({ payload, cb }) {
  try {
    const res = yield call(backend.crudCatatanHarianV2, payload);
    if (res.data.code === 200) {
      yield put(crudCatatanHarianV2Success({ data: res.data.data }));
      cb(res.data);
    } else {
      throw new Error(res.data.message);
    }
  } catch (error) {
    const { message } = error;
    toast.error(message);
    cb({
      success: false,
      message,
    });
    yield put(crudCatatanHarianV2Failure(message.error));
  }
}
function* notificationSaga() {
  yield takeEvery(CATATAN_HARIAN_REQUEST, getCatatanHarian);
  yield takeEvery(CATATAN_HARIAN_CREATE_REQUEST, createCatatanHarian);
  yield takeEvery(CATATAN_HARIAN_DELETE_REQUEST, deleteCatatanHarian);
  yield takeEvery(CATATAN_HARIAN_EDIT_REQUEST, editCatatanHarian);
  yield takeEvery(CATATAN_HARIAN_UPLOAD_REQUEST, uploadCatatanHarian);
  yield takeEvery(
    CATATAN_HARIAN_DELETE_BERKAS_REQUEST,
    deleteBerkasCatatanHarian
  );
  yield takeEvery(CRUD_CATATAN_HARIAN_V2_REQUEST, crudCatatanHarianV2);
}

export default notificationSaga;
