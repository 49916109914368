import {
    GET_BIDANG_FOKUS_FAILURE,
    GET_BIDANG_FOKUS_REQUEST,
    GET_BIDANG_FOKUS_SUCCESS, 
    GET_INSTITUSI_BYID_REQUEST,
    GET_INSTITUSI_BYID_SUCCESS,
    GET_INSTITUSI_BYID_FAILURE,
    GET_SBK_UTAMA_FAILURE, GET_SBK_UTAMA_REQUEST,
    GET_SBK_UTAMA_SUCCESS, GET_SKEMA_FAILURE, GET_SKEMA_REQUEST,
    GET_SKEMA_SUCCESS, JENIS_BANTUAN_FAILURE, JENIS_BANTUAN_REQUEST,
    JENIS_BANTUAN_SUCCESS, PENUGASAN_DOC_FAILURE, PENUGASAN_DOC_REQUEST,
    PENUGASAN_DOC_SUCCESS
} from "./actionTypes";


export const getJenisBantuan = (request = {}) => {
    return {
        type: JENIS_BANTUAN_REQUEST,
        payload: request,
    };
}

export const getJenisBantuanSuccess = response => {
    return {
        type: JENIS_BANTUAN_SUCCESS,
        payload: response,
    };
}

export const getJenisBantuanFailure = (message, response) => {
    return {
        type: JENIS_BANTUAN_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getInstitusiById = (id, cb = () => {} ) => {
    return {
        type: GET_INSTITUSI_BYID_REQUEST,
        payload: { id },
        cb
    }
}

export const getInstitusiByIdSuccess = response => {
    return {
        type: GET_INSTITUSI_BYID_SUCCESS,
        payload: response,
    };
}

export const getInstitusiByIdFailure = (message, response) => {
    return {
        type: GET_INSTITUSI_BYID_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}


// Upload Dokumen
export const uploadDokumenPenugasan = (request = {}, cb = () => { }) => {
    return {
        type: PENUGASAN_DOC_REQUEST,
        payload: request,
        cb
    };
};

export const uploadDokumenPenugasanSuccess = response => {
    return {
        type: PENUGASAN_DOC_SUCCESS,
        payload: response,
    };
};

export const uploadDokumenPenugasanFailure = (message, response) => {
    return {
        type: PENUGASAN_DOC_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
};


export const getBidangFokus = (request = {}) => {
    return {
        type: GET_BIDANG_FOKUS_REQUEST,
        payload: request,
    };
}

export const getBidangFokusSuccess = response => {
    return {
        type: GET_BIDANG_FOKUS_SUCCESS,
        payload: response,
    };
}

export const getBidangFokusFailure = (message, response) => {
    return {
        type: GET_BIDANG_FOKUS_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getSBKUtama = (request = {}) => {
    return {
        type: GET_SBK_UTAMA_REQUEST,
        payload: request,
    };
}

export const getSBKUtamaSuccess = response => {
    return {
        type: GET_SBK_UTAMA_SUCCESS,
        payload: response,
    };
}

export const getSBKUtamaFailure = (message, response) => {
    return {
        type: GET_SBK_UTAMA_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export const getSkemaKegiatan = (request = {}) => {
    return {
        type: GET_SKEMA_REQUEST,
        payload: request,
    };
}

export const getSkemaKegiatanSuccess = response => {
    return {
        type: GET_SKEMA_SUCCESS,
        payload: response,
    };
}

export const getSkemaKegiatanFailure = (message, response) => {
    return {
        type: GET_SKEMA_FAILURE,
        payload: {
            message,
            errors: response,
        },
    };
}

export * from './actionNusantara';



