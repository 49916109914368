import {
   GET_KOMPONEN_PENILAIAN_LUARAN_REQUEST,
   GET_KOMPONEN_PENILAIAN_LUARAN_SUCCESS,
   GET_KOMPONEN_PENILAIAN_LUARAN_FAILURE,
   SAVE_KOMPONEN_PENILAIAN_LUARAN_REQUEST,
   SAVE_KOMPONEN_PENILAIAN_LUARAN_SUCCESS,
   SAVE_KOMPONEN_PENILAIAN_LUARAN_FAILURE
} from "./actionTypes";

const initialState = {
   komponenPenilaianLuaran: {
      isFetching: false,
      items: [],
   },
   submitKomponenPenilaianLuaran: {
      isFetching : false,
   }
}

const evaluasi = (state = initialState, action) => {
   switch (action.type) {
      case SAVE_KOMPONEN_PENILAIAN_LUARAN_REQUEST:
         return (state = {
            ...state,
            submitKomponenPenilaianLuaran: {
               ...state.submitKomponenPenilaianLuaran,
               isFetching: true,
            },
         });
      case SAVE_KOMPONEN_PENILAIAN_LUARAN_SUCCESS:
         return (state = {
            ...state,
            submitKomponenPenilaianLuaran: {
               ...state.submitKomponenPenilaianLuaran,
               isFetching: false,
              
            },
         });
      case SAVE_KOMPONEN_PENILAIAN_LUARAN_FAILURE:
         return (state = {
            ...state,
            submitKomponenPenilaianLuaran: {
               ...state.submitKomponenPenilaianLuaran,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case GET_KOMPONEN_PENILAIAN_LUARAN_REQUEST:
         return (state = {
            ...state,
            komponenPenilaianLuaran: {
               ...state.komponenPenilaianLuaran,
               isFetching: true,
            },
         });
      case GET_KOMPONEN_PENILAIAN_LUARAN_FAILURE:
         return (state = {
            ...state,
            komponenPenilaianLuaran: {
               ...state.komponenPenilaianLuaran,
               isFetching: false,
               errors: action.payload.errors,
               errorMessage: action.payload.message,
            },
         });
      case GET_KOMPONEN_PENILAIAN_LUARAN_SUCCESS:
         return (state = {
            ...state,
            komponenPenilaianLuaran: {
               ...state.komponenPenilaianLuaran,
               isFetching: false,
               items: action.payload.data
            },
         });

      default:
         return state;
   }
}

export default evaluasi;