import {
  LIST_DAFTAR_REVIEWER_FAILURE,
  LIST_DAFTAR_REVIEWER_REQUEST,
  LIST_DAFTAR_REVIEWER_SUCCESS,
  PUT_STATUS_DAFTAR_REVIEWER_FAILURE,
  PUT_STATUS_DAFTAR_REVIEWER_REQUEST,
  PUT_STATUS_DAFTAR_REVIEWER_SUCCESS,
  GET_REVIEWER_BY_NAME_FAILURE,
  GET_REVIEWER_BY_NAME_REQUEST,
  GET_REVIEWER_BY_NAME_SUCCESS,
  POST_DAFTAR_REVIEWER_FAILURE,
  POST_DAFTAR_REVIEWER_REQUEST,
  POST_DAFTAR_REVIEWER_SUCCESS,
  GET_DROPDOWN_TAHAPAN_REQUEST,
  GET_DROPDOWN_TAHAPAN_FAILURE,
  GET_DROPDOWN_TAHAPAN_SUCCESS,
  GET_DROPDOWN_TAHUN_KEGIATAN_FAILURE,
  GET_DROPDOWN_TAHUN_KEGIATAN_REQUEST,
  GET_DROPDOWN_TAHUN_KEGIATAN_SUCCESS,
  GET_SKEMA_REVIEWER_REQUEST,
  GET_SKEMA_REVIEWER_SUCCESS,
  GET_SKEMA_REVIEWER_FAILURE,
  GET_SKEMA_REVIEWER_BY_ID_REQUEST,
  GET_SKEMA_REVIEWER_BY_ID_SUCCESS,
  GET_SKEMA_REVIEWER_BY_ID_FAILURE,
  SEARCH_REVIEWER_PT_REQUEST,
  SEARCH_REVIEWER_PT_SUCCESS,
  SEARCH_REVIEWER_PT_FAILURE,
  SEARCH_REVIEWER_PT2_REQUEST,
  SEARCH_REVIEWER_PT2_SUCCESS,
  SEARCH_REVIEWER_PT2_FAILURE,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_REQUEST,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_SUCCESS,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_FAILURE,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_REQUEST,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_SUCCESS,
  SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_FAILURE,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_REQUEST,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_SUCCESS,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_FAILURE,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_REQUEST,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_SUCCESS,
  SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_FAILURE,
  POST_PLOTTING_REVIEWER_PENELITIAN_REQUEST,
  POST_PLOTTING_REVIEWER_PENELITIAN_SUCCESS,
  POST_PLOTTING_REVIEWER_PENELITIAN_FAILURE,
  GET_LIST_REKAP_PLOTTING_REVIEWER_V2_REQUEST,
  GET_LIST_REKAP_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_LIST_REKAP_PLOTTING_REVIEWER_V2_FAILURE,
  GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
  GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_FAILURE,
  RESET_LIST_REKAP_PLOTTING_REVIEWER_V2,
  GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_REQUEST,
  GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_FAILURE,
  CREATE_PLOTTING_PLOTTING_REVIEWER_V2_REQUEST,
  CREATE_PLOTTING_PLOTTING_REVIEWER_V2_SUCCESS,
  CREATE_PLOTTING_PLOTTING_REVIEWER_V2_FAILURE,
  GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_REQUEST,
  GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_FAILURE,
  GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
  GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS,
  GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_FAILURE,
} from "./actionTypes";

export const getListDaftarReviewer = (request = "", cb = () => {}) => {
  return {
    type: LIST_DAFTAR_REVIEWER_REQUEST,
    payload: request,
    cb,
  };
};

export const getListDaftarReviewerSuccess = response => {
  return {
    type: LIST_DAFTAR_REVIEWER_SUCCESS,
    payload: response,
  };
};

export const getListDaftarReviewerFailure = (message, response) => {
  return {
    type: LIST_DAFTAR_REVIEWER_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const getDaftarReviewerByName = (request = "") => {
  return {
    type: GET_REVIEWER_BY_NAME_REQUEST,
    payload: request,
  };
};

export const getDaftarReviewerByNameSuccess = response => {
  return {
    type: GET_REVIEWER_BY_NAME_SUCCESS,
    payload: response,
  };
};

export const getDaftarReviewerByNameFailure = (message, response) => {
  return {
    type: GET_REVIEWER_BY_NAME_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const putStatusDaftarReviewer = (request = {}, cb = () => {}) => {
  return {
    type: PUT_STATUS_DAFTAR_REVIEWER_REQUEST,
    payload: request,
    cb,
  };
};
export const putStatusDaftarReviewerSuccess = response => {
  return {
    type: PUT_STATUS_DAFTAR_REVIEWER_SUCCESS,
    payload: response,
  };
};

export const putStatusDaftarReviewerFailure = (message, response) => {
  return {
    type: PUT_STATUS_DAFTAR_REVIEWER_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const postDaftarReviewer = (request = {}, cb = () => {}) => {
  return {
    type: POST_DAFTAR_REVIEWER_REQUEST,
    payload: request,
    cb,
  };
};
export const postDaftarReviewerSuccess = response => {
  return {
    type: POST_DAFTAR_REVIEWER_SUCCESS,
    payload: response,
  };
};

export const postDaftarReviewerFailure = (message, response) => {
  return {
    type: POST_DAFTAR_REVIEWER_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// dropdown tahapan
export const getDropdownTahapan = (request = {}, cb = () => {}) => {
  return {
    type: GET_DROPDOWN_TAHAPAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getDropdownTahapanSuccess = response => {
  return {
    type: GET_DROPDOWN_TAHAPAN_SUCCESS,
    payload: response,
  };
};

export const getDropdownTahapanFailure = (message, response) => {
  return {
    type: GET_DROPDOWN_TAHAPAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// dropdown tahun kegiatan
export const getDropdownTahunKegiatan = (request = {}, cb = () => {}) => {
  return {
    type: GET_DROPDOWN_TAHUN_KEGIATAN_REQUEST,
    payload: request,
    cb,
  };
};
export const getDropdownTahunKegiatanSuccess = response => {
  return {
    type: GET_DROPDOWN_TAHUN_KEGIATAN_SUCCESS,
    payload: response,
  };
};
export const getDropdownTahunKegiatanFailure = (message, response) => {
  return {
    type: GET_DROPDOWN_TAHUN_KEGIATAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getSkemaReviewer = request => {
  return {
    type: GET_SKEMA_REVIEWER_REQUEST,
    payload: request,
  };
};

export const getSkemaReviewerSuccess = response => {
  return {
    type: GET_SKEMA_REVIEWER_SUCCESS,
    payload: response,
  };
};

export const getSkemaReviewerFailure = (message, response) => {
  return {
    type: GET_SKEMA_REVIEWER_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getSkemaReviewerById = request => {
  return {
    type: GET_SKEMA_REVIEWER_BY_ID_REQUEST,
    payload: request,
  };
};

export const getSkemaReviewerByIdSuccess = response => {
  return {
    type: GET_SKEMA_REVIEWER_BY_ID_SUCCESS,
    payload: response,
  };
};

export const getSkemaReviewerByIdFailure = (message, response) => {
  return {
    type: GET_SKEMA_REVIEWER_BY_ID_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const searchReviewerPT1 = request => {
  return {
    type: SEARCH_REVIEWER_PT_REQUEST,
    payload: request,
  };
};

export const searchReviewerPT1Success = response => {
  return {
    type: SEARCH_REVIEWER_PT_SUCCESS,
    payload: response,
  };
};

export const searchReviewerPT1Failure = (message, response) => {
  return {
    type: SEARCH_REVIEWER_PT_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const searchReviewerPT2 = request => {
  return {
    type: SEARCH_REVIEWER_PT2_REQUEST,
    payload: request,
  };
};

export const searchReviewerPT2Success = response => {
  return {
    type: SEARCH_REVIEWER_PT2_SUCCESS,
    payload: response,
  };
};

export const searchReviewerPT2Failure = (message, response) => {
  return {
    type: SEARCH_REVIEWER_PT2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const searchReviewerPenelitianPusatSatu = request => {
  return {
    type: SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_REQUEST,
    payload: request,
  };
};

export const searchReviewerPenelitianSatuSuccess = response => {
  return {
    type: SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_SUCCESS,
    payload: response,
  };
};

export const searchReviewerPenelitianSatuFailure = (message, response) => {
  return {
    type: SEARCH_REVIEWER_PENELITIAN_PUSAT_SATU_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const searchReviewerPenelitianPusatDua = request => {
  return {
    type: SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_REQUEST,
    payload: request,
  };
};

export const searchReviewerPenelitianPusatDuaSuccess = response => {
  return {
    type: SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_SUCCESS,
    payload: response,
  };
};

export const searchReviewerPenelitianPusatDuaFailure = (message, response) => {
  return {
    type: SEARCH_REVIEWER_PENELITIAN_PUSAT_DUA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const searchReviewerPengabdianPusatSatu = request => {
  return {
    type: SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_REQUEST,
    payload: request,
  };
};

export const searchReviewerPengabdianSatuSuccess = response => {
  return {
    type: SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_SUCCESS,
    payload: response,
  };
};

export const searchReviewerPengabdianSatuFailure = (message, response) => {
  return {
    type: SEARCH_REVIEWER_PENGABDIAN_PUSAT_SATU_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const searchReviewerPengabdianPusatDua = request => {
  return {
    type: SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_REQUEST,
    payload: request,
  };
};

export const searchReviewerPengabdianPusatDuaSuccess = response => {
  return {
    type: SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_SUCCESS,
    payload: response,
  };
};

export const searchReviewerPengabdianPusatDuaFailure = (message, response) => {
  return {
    type: SEARCH_REVIEWER_PENGABDIAN_PUSAT_DUA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const postPlottingReviewer = (request = {}, cb = () => {}) => {
  return {
    type: POST_PLOTTING_REVIEWER_PENELITIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const postPlottingReviewerSuccess = response => {
  return {
    type: POST_PLOTTING_REVIEWER_PENELITIAN_SUCCESS,
    payload: response,
  };
};

export const postPlottingReviewerFailure = (message, response) => {
  return {
    type: POST_PLOTTING_REVIEWER_PENELITIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const getListRekapPlottingReviewerV2 = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_REKAP_PLOTTING_REVIEWER_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getListRekapPlottingReviewerV2Success = response => {
  return {
    type: GET_LIST_REKAP_PLOTTING_REVIEWER_V2_SUCCESS,
    payload: response,
  };
};

export const resetListRekapPlottingReviewerV2 = () => {
  return {
    type: RESET_LIST_REKAP_PLOTTING_REVIEWER_V2,
  };
};

export const getListRekapPlottingReviewerV2Failure = (message, response) => {
  return {
    type: GET_LIST_REKAP_PLOTTING_REVIEWER_V2_FAILURE,
    payload: { errors: response, message },
  };
};
export const getPlottingDetailPlottingReviewerV2 = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getPlottingDetailPlottingReviewerV2Success = response => {
  return {
    type: GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS,
    payload: response,
  };
};

export const getPlottingDetailPlottingReviewerV2Failure = (
  message,
  response
) => {
  return {
    type: GET_PLOTTING_DETAIL_PLOTTING_REVIEWER_V2_FAILURE,
    payload: { errors: response, message },
  };
};

export const getDetailReviewerPlottingReviewerV2 = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getDetailReviewerPlottingReviewerV2Success = response => {
  return {
    type: GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_SUCCESS,
    payload: response,
  };
};

export const getDetailReviewerPlottingReviewerV2Failure = (
  message,
  response
) => {
  return {
    type: GET_DETAIL_REVIEWER_PLOTTING_REVIEWER_V2_FAILURE,
    payload: { errors: response, message },
  };
};

export const createPlottingPlottingReviewerV2 = (
  request = {},
  cb = () => {}
) => {
  return {
    type: CREATE_PLOTTING_PLOTTING_REVIEWER_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const createPlottingPlottingReviewerV2Success = response => {
  return {
    type: CREATE_PLOTTING_PLOTTING_REVIEWER_V2_SUCCESS,
    payload: response,
  };
};

export const createPlottingPlottingReviewerV2Failure = (message, response) => {
  return {
    type: CREATE_PLOTTING_PLOTTING_REVIEWER_V2_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getMonitoringPenilaianPlottingReviewerV2 = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getMonitoringPenilaianPlottingReviewerV2Success = response => {
  return {
    type: GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_SUCCESS,
    payload: response,
  };
};

export const getMonitoringPenilaianPlottingReviewerV2Failure = (
  message,
  response
) => {
  return {
    type: GET_MONITORING_PENILAIAN_PLOTTING_REVIEWER_V2_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getMonitoringPenilaianDetailPlottingReviewerV2 = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getMonitoringPenilaianDetailPlottingReviewerV2Success =
  response => {
    return {
      type: GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_SUCCESS,
      payload: response,
    };
  };

export const getMonitoringPenilaianDetailPlottingReviewerV2Failure = (
  message,
  response
) => {
  return {
    type: GET_MONITORING_PENILAIAN_DETAIL_PLOTTING_REVIEWER_V2_FAILURE,
    payload: { errors: response, message },
  };
};
