import { instances } from "../api_helper";
import * as url from "../url_helper";

const getInstitusiByYear = ({ tahun_usulan, tahun_pelaksanaan, _id }) =>
	instances.request(`${url.ENDPOINT_INSTITUSI_ADMINISTRASI}?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}`, {
		method: "GET",
	});

const getSkemaEvaluasi = ({ tahun_usulan, tahun_pelaksanaan, institusi_penugasan }) =>
	instances.request(`${url.ENDPOINT_SKEMA_EVALUASI}?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}&institusi_id=${institusi_penugasan}`, {
		method: "GET",
	});

const getListUsulanEvaluasi = ({ tahun_usulan, tahun_pelaksanaan, id_skema }) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_EVALUASI}?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}&id_skema=${id_skema}`, {
		method: "GET",
	});

const getKomponenNilaiEvaluasi = ({ id_plotting_reviewer }) =>
	instances.request(`${url.ENDPOINT_KOMPONEN_NILAI_EVALUASI}?id_plotting_reviewer=${id_plotting_reviewer}`, {
		method: "GET",
	});

const getDetailUsulanByTransaksiKegiatanId = ({ id_transaksi_kegiatan }) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_EVALUASI}/${id_transaksi_kegiatan}`, {
		method: "GET",
	});

const savePenilaianEvaluasi = (payload) =>
	instances.request(`${url.ENDPOINT_LIST_USULAN_EVALUASI}`, {
		method: "POST",
		data: payload
	});

const savePenilaianPermanenEvaluasi = (payload) =>
	instances.request(`${url.ENDPOINT_SET_PERMANEN_EVALUASI}`, {
		method: "POST",
		data: payload
	});

export {
	getDetailUsulanByTransaksiKegiatanId,
	getKomponenNilaiEvaluasi,
	getListUsulanEvaluasi,
	getInstitusiByYear,
	getSkemaEvaluasi,
	savePenilaianEvaluasi,
	savePenilaianPermanenEvaluasi
};
