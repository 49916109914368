import { takeEvery, call, put } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import {
  GET_USULAN_BARU_REQUEST,
  GET_USULAN_DISETUJUI_REQUEST,
  APPROVAL_USULAN_REQUEST,
  BATALKAN_USULAN_REQUEST,
  GET_USULAN_DIKIRIM_REQUEST,
  GET_USULAN_DITOLAK_REQUEST,
  GET_USULAN_DITINJAU_REQUEST,
  GET_ALASAN_PENOLAKAN_REQUEST,
  GET_SUMMARY_USULAN_GROUPBY_SKEMA_REQUEST,
} from "./actionType";

import {
  approvalUsulanSuccess,
  approvalUsulanFailure,
  batalkanUsulanSuccess,
  batalkanUsulanFailure,
  getUsulanDisetujuiSuccess,
  getUsulanDisetujuiFailure,
  getUsulanBaruSuccess,
  getUsulanBaruFailure,
  getUsulanDikirimSuccess,
  getUsulanDikirimFailure,
  getUsulanDitolakSuccess,
  getUsulanDitolakFailure,
  getUsulanDitinjauSuccess,
  getUsulanDitinjauFailure,
  alasanPenolakanSuccess,
  alasanPenolakanFailure,
  getSummaryUsulanGroupBySkemaSuccess,
  getSummaryUsulanGroupBySkemaFilure,
} from "./action";
import { toast } from "react-toastify";

function* getSummaryUsulanGroupBySkema({ payload, cb }) {
  try {
    const response = yield call(backend.getSummaryUsulanGroupBySkema, payload);
    if (response.data.code == 200) {
      yield put(getSummaryUsulanGroupBySkemaSuccess(response.data.data));
      cb(response.data);
     
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getSummaryUsulanGroupBySkemaFilure(message, error));
  }
}

function* approvalUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.approvalUsulan, payload);
    if (response.data.code == 200) {
      yield put(approvalUsulanSuccess(response.data.data));
      cb(response.data);
      if (payload.kd_sts_approvel === 0 || payload.kd_sts_approvel === "0") {
        toast.success("Usulan berhasil ditolak");
      } else {
        toast.success("Usulan berhasil disetujui");
      }
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(approvalUsulanFailure(message, error));
  }
}

function* batalkanUsulan({ payload, cb }) {
  try {
    const response = yield call(backend.batalkanUsulan, payload);
    if (response.data.code == 200) {
      yield put(batalkanUsulanSuccess(response.data.data));
      cb(response.data);
      toast.success("Usulan berhasil dibatalkan");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(batalkanUsulanFailure(message, error));
  }
}

function* getUsulanDisetujui({ payload }) {
  try {
    const response = yield call(backend.getUsulanDisetujui, payload);
    if (response.data.code == 200) {
      yield put(getUsulanDisetujuiSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getUsulanDisetujuiFailure(message, error));
  }
}

function* UsulanTidakDisetujui({ payload }) {
  try {
    const response = yield call(backend.UsulanTidakDisetujui, payload);
    if (response.data.code == 200) {
      yield put(getUsulanDitolakSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getUsulanDitolakFailure(message, error));
  }
}

function* getUsulanBaru({ payload }) {
  try {
    const response = yield call(backend.getUsulanBaru, payload);
    if (response.data.code == 200) {
      yield put(getUsulanBaruSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getUsulanBaruFailure(message, error));
  }
}

function* getUsulanDikirim({ payload }) {
  try {
    const response = yield call(backend.getUsulanDikirim, payload);
    if (response.data.code == 200) {
      yield put(getUsulanDikirimSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getUsulanDikirimFailure(message, error));
  }
}

function* getUsulanDitinjau({ payload }) {
  try {
    const response = yield call(backend.getUsulanDitinjau, payload);
    if (response.data.code == 200) {
      yield put(getUsulanDitinjauSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getUsulanDitinjauFailure(message, error));
  }
}

function* alasanPenolakan({ payload }) {
  try {
    const response = yield call(backend.alasanPenolakan, payload);
    if (response.data.code == 200) {
      yield put(alasanPenolakanSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(alasanPenolakanFailure(message, error));
  }
}

function* notificationSaga() {
  yield takeEvery(GET_USULAN_BARU_REQUEST, getUsulanBaru);
  yield takeEvery(GET_USULAN_DISETUJUI_REQUEST, getUsulanDisetujui);
  yield takeEvery(APPROVAL_USULAN_REQUEST, approvalUsulan);
  yield takeEvery(BATALKAN_USULAN_REQUEST, batalkanUsulan);
  yield takeEvery(GET_USULAN_DIKIRIM_REQUEST, getUsulanDikirim);
  yield takeEvery(GET_USULAN_DITOLAK_REQUEST, UsulanTidakDisetujui);
  yield takeEvery(GET_USULAN_DITINJAU_REQUEST, getUsulanDitinjau);
  yield takeEvery(GET_ALASAN_PENOLAKAN_REQUEST, alasanPenolakan);
  yield takeEvery(GET_SUMMARY_USULAN_GROUPBY_SKEMA_REQUEST, getSummaryUsulanGroupBySkema);
}

export default notificationSaga;
