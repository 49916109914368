import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAILURE,
  NOTIFICATION_COMPLETE_LIST_FAILURE,
  NOTIFICATION_COMPLETE_LIST_REQUEST,
  NOTIFICATION_COMPLETE_LIST_SUCCESS,
  NOTIFICATION_TYPE_FAILURE,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_REQUEST,
  PERSONEL_INVITATION_DETAIL_REQUEST,
  PERSONEL_INVITATION_DETAIL_SUCCESS,
  PERSONEL_INVITATION_DETAIL_FAILURE,
  PATCH_NOTIFICATION_REQUEST,
  PATCH_NOTIFICATION_SUCCESS,
  PATCH_NOTIFICATION_FAILURE,
  PATCH_PERSONEL_INVITATION_REQUEST,
  PATCH_PERSONEL_INVITATION_SUCCESS,
  PATCH_PERSONEL_INVITATION_FAILURE,
  PATCH_PERSONEL_INVITATION_V2_REQUEST,
  PATCH_PERSONEL_INVITATION_V2_SUCCESS,
  PATCH_PERSONEL_INVITATION_V2_FAILURE,
} from "./actionTypes";

export const getNotifications = (request = "") => {
  return {
    type: NOTIFICATION_REQUEST,
    payload: request,
  };
};

export const getNotificationSuccess = response => {
  return {
    type: NOTIFICATION_SUCCESS,
    payload: response,
  };
};

export const getNotificationFailure = (message, response) => {
  return {
    type: NOTIFICATION_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getNotificationCompleteList = (request = "") => {
  return {
    type: NOTIFICATION_COMPLETE_LIST_REQUEST,
    payload: request,
  };
};

export const getNotificationCompleteListSuccess = response => {
  return {
    type: NOTIFICATION_COMPLETE_LIST_SUCCESS,
    payload: response,
  };
};

export const getNotificationCompleteListFailure = (message, response) => {
  return {
    type: NOTIFICATION_COMPLETE_LIST_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getNotificationType = (request = "", cb = () => {}) => {
  return {
    type: NOTIFICATION_TYPE_REQUEST,
    payload: request,
    cb,
  };
};

export const getNotificationTypeSuccess = response => {
  return {
    type: NOTIFICATION_TYPE_SUCCESS,
    payload: response,
  };
};

export const getNotificationTypeFailure = (message, response) => {
  return {
    type: NOTIFICATION_TYPE_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getPersonelInvitationDetail = (request = "", cb = () => {}) => {
  return {
    type: PERSONEL_INVITATION_DETAIL_REQUEST,
    payload: request,
    cb,
  };
};

export const getPersonelInvitationDetailSuccess = response => {
  return {
    type: PERSONEL_INVITATION_DETAIL_SUCCESS,
    payload: response,
  };
};

export const getPersonelInvitationDetailFailure = (message, response) => {
  return {
    type: PERSONEL_INVITATION_DETAIL_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const patchNotification = (request = "", cb = () => {}) => {
  return {
    type: PATCH_NOTIFICATION_REQUEST,
    payload: request,
    cb,
  };
};

export const patchNotificationSuccess = response => {
  return {
    type: PATCH_NOTIFICATION_SUCCESS,
    payload: response,
  };
};

export const patchNotificationFailure = (message, response) => {
  return {
    type: PATCH_NOTIFICATION_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const patchPersonelInvitation = (request = "", cb = () => {}) => {
  return {
    type: PATCH_PERSONEL_INVITATION_REQUEST,
    payload: request,
    cb,
  };
};

export const patchPersonelInvitationSuccess = response => {
  return {
    type: PATCH_PERSONEL_INVITATION_SUCCESS,
    payload: response,
  };
};

export const patchPersonelInvitationFailure = (message, response) => {
  return {
    type: PATCH_PERSONEL_INVITATION_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const patchPersonelInvitationV2 = (request = "", cb = () => {}) => {
  return {
    type: PATCH_PERSONEL_INVITATION_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const patchPersonelInvitationV2Success = response => {
  return {
    type: PATCH_PERSONEL_INVITATION_V2_SUCCESS,
    payload: response,
  };
};

export const patchPersonelInvitationV2Failure = (message, response) => {
  return {
    type: PATCH_PERSONEL_INVITATION_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
