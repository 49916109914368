export const KOSABANGSA_DOSEN_REQUEST = "KOSABANGSA_DOSEN_REQUEST";
export const KOSABANGSA_DOSEN_SUCCESS = "KOSABANGSA_DOSEN_SUCCESS";
export const KOSABANGSA_DOSEN_FAILURE = "KOSABANGSA_DOSEN_FAILURE";

export const KOSABANGSA_BIDANG_FOKUS_PRIORITAS_SUCCESS =
  "KOSABANGSA_BIDANG_FOKUS_PRIORITAS_SUCCESS";
export const KOSABANGSA_BIDANG_FOKUS_PRIORITAS_FAILURE =
  "KOSABANGSA_BIDANG_FOKUS_PRIORITAS_FAILURE";
export const KOSABANGSA_BIDANG_FOKUS_PRIORITAS_REQUEST =
  "KOSABANGSA_BIDANG_FOKUS_PRIORITAS_REQUEST";

export const KOSABANGSA_BIDANG_FOKUS_LAINNYA_REQUEST =
  "KOSABANGSA_BIDANG_FOKUS_LAINNYA_REQUEST";
export const KOSABANGSA_BIDANG_FOKUS_LAINNYA_SUCCESS =
  "KOSABANGSA_BIDANG_FOKUS_LAINNYA_SUCCESS";
export const KOSABANGSA_BIDANG_FOKUS_LAINNYA_FAILURE =
  "KOSABANGSA_BIDANG_FOKUS_LAINNYA_FAILURE";

export const KOSABANGSA_GET_PROVINSI_REQUEST =
  "KOSABANGSA_GET_PROVINSI_REQUEST";
export const KOSABANGSA_GET_PROVINSI_SUCCESS =
  "KOSABANGSA_GET_PROVINSI_SUCCESS";
export const KOSABANGSA_GET_PROVINSI_FAILURE =
  "KOSABANGSA_GET_PROVINSI_FAILURE";

export const KOSABANGSA_KABUPATEN_KOTA_REQUEST =
  "KOSABANGSA_KABUPATEN_KOTA_REQUEST";
export const KOSABANGSA_KABUPATEN_KOTA_SUCCESS =
  "KOSABANGSA_KABUPATEN_KOTA_SUCCESS";
export const KOSABANGSA_KABUPATEN_KOTA_FAILURE =
  "KOSABANGSA_KABUPATEN_KOTA_FAILURE";

export const KOSABANGSA_KELOMPOK_MITRA_REQUEST =
  "KOSABANGSA_KELOMPOK_MITRA_REQUEST";
export const KOSABANGSA_KELOMPOK_MITRA_SUCCESS =
  "KOSABANGSA_KELOMPOK_MITRA_SUCCESS";
export const KOSABANGSA_KELOMPOK_MITRA_FAILURE =
  "KOSABANGSA_KELOMPOK_MITRA_FAILURE";

export const KOSABANGSA_JENIS_PIMPINAN_PENETAP_REQUEST =
  "KOSABANGSA_JENIS_PIMPINAN_PENETAP_REQUEST";
export const KOSABANGSA_JENIS_PIMPINAN_PENETAP_FAILURE =
  "KOSABANGSA_JENIS_PIMPINAN_PENETAP_FAILURE";
export const KOSABANGSA_JENIS_PIMPINAN_PENETAP_SUCCESS =
  "KOSABANGSA_JENIS_PIMPINAN_PENETAP_SUCCESS";

export const KOSABANGSA_UPLOAD_FILE_REQUEST = "KOSABANGSA_UPLOAD_FILE_REQUEST";
export const KOSABANGSA_UPLOAD_FILE_SUCCESS = "KOSABANGSA_UPLOAD_FILE_SUCCESS";
export const KOSABANGSA_UPLOAD_FILE_FAILURE = "KOSABANGSA_UPLOAD_FILE_FAILURE";

export const KOSABANGSA_SAVE_REQUEST = "KOSABANGSA_SAVE_REQUEST";
export const KOSABANGSA_SAVE_SUCCESS = "KOSABANGSA_SAVE_SUCCESS";
export const KOSABANGSA_SAVE_FAILURE = "KOSABANGSA_SAVE_FAILURE";

export const KOSABANGSA_PUT_REQUEST = "KOSABANGSA_PUT_REQUEST";
export const KOSABANGSA_PUT_SUCCESS = "KOSABANGSA_PUT_SUCCESS";
export const KOSABANGSA_PUT_FAILURE = "KOSABANGSA_PUT_FAILURE";

export const KOSABANGSA_DELETE_REQUEST = "KOSABANGSA_DELETE_REQUEST";
export const KOSABANGSA_DELETE_SUCCESS = "KOSABANGSA_DELETE_SUCCESS";
export const KOSABANGSA_DELETE_FAILURE = "KOSABANGSA_DELETE_FAILURE";

export const KOSABANGSA_PENDAMPING_BYID_REQUEST =
  "KOSABANGSA_PENDAMPING_BYID_REQUEST";
export const KOSABANGSA_PENDAMPING_BYID_SUCCESS =
  "KOSABANGSA_PENDAMPING_BYID_SUCCESS";
export const KOSABANGSA_PENDAMPING_BYID_FAILURE =
  "KOSABANGSA_PENDAMPING_BYID_FAILURE";

export const KOSABANGSA_LIST_PENDAMPING_REQUEST =
  "KOSABANGSA_LIST_PENDAMPING_REQUEST";
export const KOSABANGSA_LIST_PENDAMPING_SUCCESS =
  "KOSABANGSA_LIST_PENDAMPING_SUCCESS";
export const KOSABANGSA_LIST_PENDAMPING_FAILURE =
  "KOSABANGSA_LIST_PENDAMPING_FAILURE";

export const KOSABANGSA_PELAKSANA_BYID_REQUEST =
  "KOSABANGSA_PELAKSANA_BYID_REQUEST";
export const KOSABANGSA_PELAKSANA_BYID_SUCCESS =
  "KOSABANGSA_PELAKSANA_BYID_SUCCESS";
export const KOSABANGSA_PELAKSANA_BYID_FAILURE =
  "KOSABANGSA_PELAKSANA_BYID_FAILURE";

export const KOSABANGSA_LIST_PELAKSANA_REQUEST =
  "KOSABANGSA_LIST_PELAKSANA_REQUEST";
export const KOSABANGSA_LIST_PELAKSANA_SUCCESS =
  "KOSABANGSA_LIST_PELAKSANA_SUCCESS";
export const KOSABANGSA_LIST_PELAKSANA_FAILURE =
  "KOSABANGSA_LIST_PELAKSANA_FAILURE";

export const KOSABANGSA_PELAKSANA_LIST_PENDAMPING_REQUEST =
  "KOSABANGSA_PELAKSANA_LIST_PENDAMPING_REQUEST";
export const KOSABANGSA_PELAKSANA_LIST_PENDAMPING_SUCCESS =
  "KOSABANGSA_PELAKSANA_LIST_PENDAMPING_SUCCESS";
export const KOSABANGSA_PELAKSANA_LIST_PENDAMPING_FAILURE =
  "KOSABANGSA_PELAKSANA_LIST_PENDAMPING_FAILURE";

export const RESET_KOSABANGSA_PELAKSANA_BY_ID =
  "RESET_KOSABANGSA_PELAKSANA_BY_ID";

export const RESET_KOSABANGSA_PELAKSANA_GET_PENDAMPING_BY_ID =
  "RESET_KOSABANGSA_PELAKSANA_GET_PENDAMPING_BY_ID";

export const GET_KOSABANGSA_MATCHED_REQUEST = "GET_KOSABANGSA_MATCHED_REQUEST";
export const GET_KOSABANGSA_MATCHED_SUCCESS = "GET_KOSABANGSA_MATCHED_SUCCESS";
export const GET_KOSABANGSA_MATCHED_FAILURE = "GET_KOSABANGSA_MATCHED_FAILURE";


export const GET_ELIGIBILITY_KOSABANGSA_REQUEST = "GET_ELIGIBILITY_KOSABANGSA_REQUEST";
export const GET_ELIGIBILITY_KOSABANGSA_SUCCESS = "GET_ELIGIBILITY_KOSABANGSA_SUCCESS";
export const GET_ELIGIBILITY_KOSABANGSA_FAILURE = "GET_ELIGIBILITY_KOSABANGSA_FAILURE";

export const GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_REQUEST = "GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_REQUEST";
export const GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_SUCCESS = "GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_SUCCESS";
export const GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_FAILURE = "GET_ELIGIBILITY_ANGGOTA_KOSABANGSA_FAILURE";
export const CLEAN_ELIGIBILITY_ANGGOTA_KOSABANGSA = "CLEAN_ELIGIBILITY_ANGGOTA_KOSABANGSA";

export const APPROVAL_OP_PUSAT_KOSBANGSA_REQUEST = "APPROVAL_OP_PUSAT_KOSBANGSA_REQUEST";
export const APPROVAL_OP_PUSAT_KOSBANGSA_SUCCESS = "APPROVAL_OP_PUSAT_KOSBANGSA_SUCCESS";
export const APPROVAL_OP_PUSAT_KOSBANGSA_FAILURE = "APPROVAL_OP_PUSAT_KOSBANGSA_FAILURE";

