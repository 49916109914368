// Import Saga
import { takeEvery, call, put } from "redux-saga/effects";

// Backend Helper
import * as backend from "../../helpers/backend";

// Action Types = constan
import {
  PENGUMUMAN_REQUEST,
  PENGUMUMAN_LANDING_REQUEST,
  PENGUMUMAN_BY_ID_REQUEST,
  CREATE_PENGUMUMAN_REQUEST,
  PENGUMUMAN_UPLOAD_REQUEST,
  DELETE_PENGUMUMAN_REQUEST,
  UPDATE_PENGUMUMAN_REQUEST,
  DELETE_FILE_REQUEST,
} from "./actionTypes";

// action = Function
import {
  getPengumumanSuccess,
  getPengumumanFailure,
  getPengumumanLandingSuccess,
  getPengumumanLandingFailure,
  getPengumumanByIdSuccess,
  getPengumumanByIdFailure,
  deletePengumumanSuccess,
  createPengumumanSuccess,
  uploadPengumumanRequest,
  updatePengumumanSuccess,
  updatePengumumanFailure,
  deleteFileSuccess,
  deleteFileFailure,
} from "./action";
import { toast } from "react-toastify";

// Function Saga
// Get Pengumuman
function* getPengumuman({ payload }) {
  try {
    const response = yield call(backend.getPengumuman, payload);
    if (response.data.code == 200) {
      yield put(getPengumumanSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getPengumumanFailure(message, error));
  }
}

// Get Pengumuman For Landing Page
function* getPengumumanLanding({ payload }) {
  try {
    const response = yield call(backend.getPengumumanLanding, payload);
    if (response.data.code == 200) {
      yield put(getPengumumanLandingSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getPengumumanLandingFailure(message, error));
  }
}

// GET Pengumuman By Id
function* getPengumumanById({ payload }) {
  try {
    const response = yield call(backend.getPengumumanById, payload);
    if (response.data.code == 200) {
      yield put(getPengumumanByIdSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getPengumumanByIdFailure(message, error));
  }
}

// Delete Pengumuman
function* deletePengumuman({ payload }) {
  try {
    const response = yield call(backend.deletePengumuman, payload);
    if (response.data.code == 200) {
      yield put(deletePengumumanSuccess(payload));
      toast.success("Berhasil menghapus Pengumuman!");
      window.location.href = "/operator-pengumuman";
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getPengumumanFailure(message, error));
  }
}

// Create Pengumuman
function* createPengumuman({ payload }) {
  try {
    const response = yield call(backend.createPengumuman, payload);
    if (response.data.code == 200) {
      yield put(createPengumumanSuccess(response.data.data));
      toast.success("Berhasil membuat pengumuman!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getPengumumanFailure(message, error));
  }
}

function* uploadPengumuman({ payload }) {
  try {
    const response = yield call(backend.uploadPengumuman, payload);
    if (response.data.code == 200) {
      yield put(uploadPengumumanRequest(response.data.data));
      toast.success("Berhasil mengunggah berkas Pengumuman!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getPengumumanFailure(message, error));
  }
}

function* updatePengumuman({ payload }) {
  try {
    const response = yield call(backend.updatePengumuman, payload);
    if (response.status == 200) {
      yield put(updatePengumumanSuccess(response.data.data));
      toast.success("Berhasil mengubah Pengumuman!");
      window.location.href = "/operator-pengumuman";
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(updatePengumumanFailure(message, error));
  }
}

// DELETE FILE PENGUMUMAN
function* deleteFilePengumuman({ payload }) {
  try {
    const response = yield call(backend.deleteFilePengumuman, payload);
    if (response.status == 200) {
      yield put(deleteFileSuccess(response.data.data));
      toast.success("Berhasil menghapus file!");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(deleteFileFailure(message, error));
  }
}

function* notificationSaga() {
  yield takeEvery(PENGUMUMAN_REQUEST, getPengumuman);
  yield takeEvery(PENGUMUMAN_LANDING_REQUEST, getPengumumanLanding);
  yield takeEvery(PENGUMUMAN_BY_ID_REQUEST, getPengumumanById);
  yield takeEvery(DELETE_PENGUMUMAN_REQUEST, deletePengumuman);
  yield takeEvery(CREATE_PENGUMUMAN_REQUEST, createPengumuman);
  yield takeEvery(PENGUMUMAN_UPLOAD_REQUEST, uploadPengumuman);
  yield takeEvery(UPDATE_PENGUMUMAN_REQUEST, updatePengumuman);
  yield takeEvery(DELETE_FILE_REQUEST, deleteFilePengumuman);
}

export default notificationSaga;
