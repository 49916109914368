import {
  CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE,
  CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
  CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS,
  CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE,
  CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
  CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS,
  GET_LIST_KLASTER_PT_FAILURE,
  GET_LIST_KLASTER_PT_REQUEST,
  GET_LIST_KLASTER_PT_SUCCESS,
  OPERATOR_PT_DASHBOARD_TKT_FAILURE,
  OPERATOR_PT_DASHBOARD_TKT_REQUEST,
  OPERATOR_PT_DASHBOARD_TKT_SUCCESS,
  PROCESS_PENDAFTARAN_AKUN_PT_FAILURE,
  PROCESS_PENDAFTARAN_AKUN_PT_REQUEST,
  PROCESS_PENDAFTARAN_AKUN_PT_SUCCESS,
} from "./actionTypes";
export const getOperatorPtDashboardTkt = (request = "") => {
  return {
    type: OPERATOR_PT_DASHBOARD_TKT_REQUEST,
    payload: request,
  };
};

export const getOperatorPtDashboardTktSuccess = response => {
  return {
    type: OPERATOR_PT_DASHBOARD_TKT_SUCCESS,
    payload: response,
  };
};

export const getOperatorPtDashboardTktFailure = (message, response) => {
  return {
    type: OPERATOR_PT_DASHBOARD_TKT_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const crudPendaftaranAkunOperatorPt = (request = {}, cb = () => {}) => {
  return {
    type: CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
    payload: request,
    cb,
  };
};

export const crudPendaftaranAkunOperatorPtSuccess = response => {
  return {
    type: CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS,
    payload: response,
  };
};

export const crudPendaftaranAkunOperatorPtFailure = (message, response) => {
  return {
    type: CRUD_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE,
    payload: { errors: response, message },
  };
};

export const checkAkunPendaftaranAkunOperatorPt = (
  request = {},
  cb = () => {}
) => {
  return {
    type: CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_REQUEST,
    payload: request,
    cb,
  };
};

export const checkAkunPendaftaranAkunOperatorPtSuccess = response => {
  return {
    type: CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_SUCCESS,
    payload: response,
  };
};

export const checkAkunPendaftaranAkunOperatorPtFailure = (
  message,
  response
) => {
  return {
    type: CHECK_AKUN_PENDAFTARAN_AKUN_OPERATOR_PT_FAILURE,
    payload: { errors: response, message },
  };
};

export const getListKlasterPt = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_KLASTER_PT_REQUEST,
    payload: request,
    cb,
  };
};

export const getListKlasterPtSuccess = response => {
  return {
    type: GET_LIST_KLASTER_PT_SUCCESS,
    payload: response,
  };
};

export const getListKlasterPtFailure = (message, response) => {
  return {
    type: GET_LIST_KLASTER_PT_FAILURE,
    payload: { errors: response, message },
  };
};

export const processPendaftaranAkunPt = (request = {}, cb = () => {}) => {
  return {
    type: PROCESS_PENDAFTARAN_AKUN_PT_REQUEST,
    payload: request,
    cb,
  };
};

export const processPendaftaranAkunPtSuccess = response => {
  return {
    type: PROCESS_PENDAFTARAN_AKUN_PT_SUCCESS,
    payload: response,
  };
};

export const processPendaftaranAkunPtFailure = (message, response) => {
  return {
    type: PROCESS_PENDAFTARAN_AKUN_PT_FAILURE,
    payload: { errors: response, message },
  };
};
