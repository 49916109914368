import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Front
import Layout from "./layout/reducer";

// Authentication
import ForgetPassword from "./auth/forgetpwd/reducer";
import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";
import Account from "./auth/register/reducer";
import CatatanHarian from "./catatanHarian/reducer";
import Dashboard from "./dashboard/reducer";
import LaporanKemajuan from "./laporanKemajuan/reducer";
import LaporanAkhir from "./laporanAkhir/reducer";
import Notification from "./notification/reducer";
import Pengumuman from "./pengumuman/reducer";
import Penugasan from "./penugasan/reducer";
import PenugasanPrototipe from "./penugasan/prototipe/reducer";
import PenugasanJFS from "./penugasan/jfs/reducer";
import PenugasanGoogle from "./penugasan/googleBangkit/reducer";
import PenugasanPembelajaran from "./penugasan/modulPembelajaran/reducer";
import PenugasanKosaBangsa from "./penugasan/kosabangsa/reducer";
import UsulanPenelitian from "./usulanPenelitian/reducer";
import UsulanPengabdian from "./usulanPengabdian/reducer";
import OperatorMonitoring from "./operatorMonitoring/reducer";
import OperatorPtDashboard from "./operatorPtDashboard/reducer";
import OperatorPengelolaanReviewer from "./operatorPengelolaanReviewer/reducer";
import UsulanPerbaikan from "./usulanPerbaikan/reducer";
import PtDosen from "./dataPendukung/reducer";
import Berita from "./berita/reducer";
import ChangePasswordReducer from "./auth/changepwd/reducer";
import Monev from "./monev/reducer";
import Reviewer from "./reviewer/reducer";
import PersetujuanUsulan from "./persetujuanUsulan/reducer";
import EvaluasiAdministrasi from "./evaluasiAdministrasi/reducer";
import EvaluasiSubtansi from "./evaluasiSubtansi/reducer";
import Evaluasi from "./evaluasi/reducer";
import Kosabangsa from "./kosabangsa/reducer";
import SiteVisit from "./siteVisit/reducer";
import PenilaianLuaran from "./penilaianLuaran/reducer";
import PrototipeUsulan from "./prototipe/reducer";
import BimtekProposal from "./bimtek/reducer";
import Enumerations from "./enumerations/reducer";
import KekayaanIntelektual from "./kekayaanIntelektual/reducer";
import Konfigurasi from "./konfigurasi/reducer";
import OperatorCommon from "./operatorCommon/reducer";
import ECatalog from "./eCatalog/reducer";

export const usulanPerbaikanPersistConfig = {
  key: "usulan_perbaikan",
  storage,
};
// export const laporanKemajuanPersistConfig = {
//   key: "laporan_kemajuan",
//   storage,
// };

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  Notification,
  CatatanHarian,
  UsulanPerbaikan: persistReducer(
    usulanPerbaikanPersistConfig,
    UsulanPerbaikan
  ),
  UsulanPenelitian,
  UsulanPengabdian,
  // LaporanKemajuan: persistReducer(
  //   laporanKemajuanPersistConfig,
  //   LaporanKemajuan
  // ),
  LaporanKemajuan,
  LaporanAkhir,
  // LandingPage,
  Pengumuman,
  //* Operator PT
  OperatorMonitoring,
  OperatorPtDashboard,
  OperatorPengelolaanReviewer,
  Penugasan,
  PenugasanPrototipe,
  PenugasanJFS,
  PenugasanGoogle,
  PenugasanPembelajaran,
  PenugasanKosaBangsa,
  PtDosen,
  Berita,
  ChangePasswordReducer,
  Monev,
  Reviewer,
  PersetujuanUsulan,
  EvaluasiAdministrasi,
  EvaluasiSubtansi,
  Evaluasi,
  Kosabangsa,
  SiteVisit,
  PenilaianLuaran,
  PrototipeUsulan,
  Enumerations,
  KekayaanIntelektual,
  Konfigurasi,
  OperatorCommon,
  ECatalog,
  BimtekProposal
});

export default rootReducer;
