import axios from "axios";
import { toast } from "react-toastify";
import encStorage from "./encStorage";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8088/api/v1";
const API_V2_URL =
  process.env.REACT_APP_API_V2_URL || "http://localhost:8088/api/v2";
const API_KI_URL = process.env.REACT_APP_API_KI_URL;
// const API_URL = "https://e1000a67-6696-40ca-985d-3bb4cd47f751.mock.pstmn.io/api/v1/";

const storage = JSON.parse(encStorage.getItem("authUser"));

const headers = {
  Authorization: ` Bearer ${storage?.token}`,
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers,
});

const axiosInstanceV2 = axios.create({
  baseURL: API_V2_URL,
  headers,
});

const axiosInstanceKI = axios.create({
  baseURL: API_KI_URL,
  headers,
});

const axiosInstanceReport = axios.create({
  baseURL: API_URL,
  headers,
  responseType: "arraybuffer",
});
const axiosInstanceReportV2 = axios.create({
  baseURL: API_V2_URL,
  headers,
  responseType: "arraybuffer",
});

const axiosInstanceReportKI = axios.create({
  baseURL: API_KI_URL,
  headers,
  responseType: "arraybuffer",
});

const setupInterceptorsReport = axiosInstance => {
  axiosInstance.interceptors.response.use(response => {
    const url = response.config.url;
    if (url.includes("login")) {
      axiosInstance.defaults.headers["Authorization"] = `Bearer ${data.data}`;
    }
    return response;
  });
  return axiosInstance;
};
const setupInterceptorsTo = axiosInstance => {
  axiosInstance.interceptors.response.use(
    response => {
      const url = response.config.url;
      const data = response.data;
      if (url.includes("login")) {
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${data.data}`;
      }

      //code 417 for validation invalid
      if (data.code != 200 && data.code != 417) {
        toast.error(data.message);
        if (error.code === 401) {
          encStorage.removeItem("authUser");
          // window.location.replace("/login");
          return false;
        }
      }

      return response;
    },
    error => {
      let message =
        error.response?.data?.message ||
        error.response?.message ||
        error.message;

      if (error.response?.status === 401) {
        encStorage.removeItem("authUser");
        // window.location.replace("/login");

        return false;
      }

      if (message === "Network Error")
        message = "Something went wrong, please try again later!";

      toast.error(message);

      return Promise.reject(message);
    }
  );
  return axiosInstance;
};

const instances = setupInterceptorsTo(axiosInstance);
const instancesReport = setupInterceptorsReport(axiosInstanceReport);
const instancesReportV2 = setupInterceptorsReport(axiosInstanceReportV2);
const instancesReportKI = setupInterceptorsReport(axiosInstanceReportKI);
const instancesV2 = setupInterceptorsTo(axiosInstanceV2);
const instancesKI = setupInterceptorsTo(axiosInstanceKI);

export {
  instances,
  instancesReport,
  instancesV2,
  instancesKI,
  instancesReportKI,
  instancesReportV2,
};
